import * as BoardActions from './actions';
import * as _ from 'lodash-es';
import * as moment from 'moment';

import { Action, createReducer, on } from '@ngrx/store';
import { State, initialState } from './state';

import { BoardCardModel } from '@bsuccess/models/board/board-card.model';
import { BoardService } from '@bsuccess/services/board.service';
import { FuseUtils } from '@fuse/utils';
import { StudioProjectStoreActions } from '../project-store';
import { StudioProjectsStoreActions } from '../projects-store';

const boardReducer = createReducer(
    initialState,
    on(BoardActions.addBoardSuccess, (state, { board }) => ({
        ...state,
        current: board,
    })),
    on(
        StudioProjectStoreActions.showAddBoardDialog,
        StudioProjectStoreActions.loadCurrent,
        (state) => ({
            ...state,
            current: null,
        })
    ),
    on(BoardActions.loadProjectsListSuccess, (state, { projectLists }) => {
        return {
            ...state,
            projectsList: projectLists.projects
        }
    }),
    on(StudioProjectStoreActions.addBoardByIdSuccess, (state, { board, projectId }) => {
        return {
            ...state,
            projectsList: [
                ...state.projectsList.map(project => {
                    if (project._id == projectId) {
                        project.boards.push(board)
                    }
                    return project;
                }),
            ],
        }
    }),
    on(StudioProjectStoreActions.addBoardSuccess, (state, { board, users }) => ({
        ...state,
        current: {
            ...state.current,
            _id: board.id,
            users: users,
        },
    })),
    on(BoardActions.getChartsSuccess, (state, { widgetCharts }) => ({
        ...state,
        current: {
            ...state.current,
            widgetCharts
        },
    })),
    on(BoardActions.removeWidgetSuccess, (state, { widgetId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                widgets: [
                    ...state.current.widgets.filter((widget) => widget._id !== widgetId),
                ],
                numberWidgets: [
                    ...state.current.numberWidgets.filter((widget) => widget._id !== widgetId),
                ],
            },
        };
    }),
    on(BoardActions.createWidgetSuccess, (state, { boardId, widget }) => {
        return {
            ...state,
            current: {
                ...state.current,
                widgets: widget.widgetType != 'number' ? [...state.current.widgets, widget] : [...state.current.widgets],
                numberWidgets: widget.widgetType == 'number' ? [...state.current.numberWidgets, widget] : [...state.current.numberWidgets],
            },
        };
    }),
    on(BoardActions.updateWidgetSuccess, (state, { boardId, previousType, response }) => {
        return {
            ...state,
            current: {
                ...state.current,
                widgets: previousType == response.widgetType ? [
                    ...state.current.widgets.map(
                        (currentWidget) => {
                            if (currentWidget._id == response._id) {
                                currentWidget.name = response.name;
                                currentWidget.widgetType = response.widgetType;
                                currentWidget.dataAbscissa = response.dataAbscissa;
                                currentWidget.widgetFilters = response.widgetFilters;
                                currentWidget.widgetType != 'number' ? currentWidget.chart = response.chart
                                    : currentWidget.number = response.number;
                            }
                            return currentWidget;
                        }),
                ] : response.widgetType != 'number' ? [...state.current.widgets, response] :
                    [...state.current.widgets.filter((widget) => widget._id !== response._id),],

                numberWidgets: previousType == response.widgetType ? [
                    ...state.current.numberWidgets.map(
                        (currentWidget) => {
                            if (currentWidget._id == response._id) {
                                currentWidget.name = response.name;
                                currentWidget.widgetType = response.widgetType;
                                currentWidget.dataAbscissa = response.dataAbscissa;
                                currentWidget.widgetFilters = response.widgetFilters;
                                currentWidget.widgetType != 'number' ? currentWidget.chart = response.chart
                                    : currentWidget.number = response.number;
                            }
                            return currentWidget;
                        }),
                ] : response.widgetType == 'number' ? [...state.current.numberWidgets, response] :
                    [...state.current.numberWidgets.filter((widget) => widget._id !== response._id),],
            },
        };
    }),
    on(BoardActions.linkCardToBoardSuccess, (state, { boardId, cardDetails }) => ({
        ...state,
        current: {
            ...state.current,
            cards: [
                ...state.current.cards.map(_ => {
                    if (_.id === cardDetails.cardId) {
                        _.link = {
                            ...cardDetails
                        }
                    }
                    return _;
                })
            ]
        }
    })),
    on(BoardActions.unlinkCardFromBoardSuccess, (state, { cardId }) => ({
        ...state,
        current: {
            ...state.current,
            cards: [
                ...state.current.cards.map(_ => {
                    if (_.id === cardId) {
                        delete _.link;
                    }
                    return _;
                })
            ]
        }
    })),
    on(StudioProjectStoreActions.projectAddBoardTemplateSuccess, (state, { board, users }) => ({
        ...state,
        current: {
            ...state.current,
            _id: board._id,
            id: board._id,
            users,
        },
    })),
    on(BoardActions.loadCurrentSuccess, (state, { board }) => ({
        ...state,
        current: {
            ...board,
            lists: [
                ...board.lists.map((list) => {
                    return {
                        ...list,
                        idCards: _.uniq(list.idCards),
                    };
                }),
            ],
            cards: [
                ...board.cards.map((card) => {
                    delete card.activities;
                    return {
                        ...card,
                    };
                }),
            ],
        },
    })),
    on(StudioProjectStoreActions.projectSaveBoardAsTemplateSuccess, (state, { boardId, projectTemplate }) => ({
        ...state,
        current: {
            ...state.current,
            template: {
                projectTemplate
            }
        },
    })),
    on(BoardActions.loadCurrent, (state, { boardId }) => ({
        ...state,
        current: null,
    })),
    on(BoardActions.rename, (state, { newName }) => ({
        ...state,
        current: {
            ...state.current,
            name: newName,
        },
    })),
    on(BoardActions.updateBackgroundSuccess, (state, { background }) => ({
        ...state,
        current: {
            ...state.current,
            background: background,
        },
    })),
    on(BoardActions.addCardSuccess, (state, { listId, card }) => {
        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === listId) {
                            list.idCards.push(card.id);
                        }
                        return list;
                    }),
                ],
                cards: [card, ...state.current.cards],
            },
        };
    }),
    on(BoardActions.addCardViaSocket, (state, { listId, card }) => {
        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === listId) {
                            list.idCards.push(card.id);
                        }
                        return list;
                    }),
                ],
                cards: [card, ...state.current.cards],
            },
        };
    }),
    on(BoardActions.removeCardSuccess, (state, { listId, cardId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === listId) {
                            list.idCards.splice(list.idCards.indexOf(cardId), 1);
                        }
                        return list;
                    }),
                ],
                cards: [...state.current.cards.filter((_card) => _card.id !== cardId)],
            },
        };
    }),
    on(BoardActions.copyCardToBoardSuccess, (state, { boardId, listId, cardDetails }) => {
        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === listId) {
                            list.idCards.splice(list.idCards.indexOf(cardDetails.cardId), 1);
                        }
                        return list;
                    }),
                ],
                cards: [...state.current.cards.filter((_card) => _card.id !== cardDetails.cardId)],
            },
        };
    }),
    on(BoardActions.addCardToBoardSuccess, (state, { boardId, listId, cardDetails }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === cardDetails.cardId) {
                            card.sharedInBoards.push({
                                boardName: cardDetails.boardName,
                                boardId: cardDetails.boardToShareWith,
                            });
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.removeCardFromBoardSuccess, (state, { cardId, boardIdToRemove, sharedBoard, listId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                sharedInBoards: sharedBoard?.filter((board) => board.boardId !== boardIdToRemove)
                            }
                        } else {
                            return card
                        }
                    }),
                ],
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === listId && state.current.id === boardIdToRemove) {
                            list.idCards.splice(list.idCards.indexOf(cardId), 1);
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.removeCardViaSocket, (state, { listId, cardId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === listId) {
                            list.idCards.splice(list.idCards.indexOf(cardId), 1);
                        }
                        return list;
                    }),
                ],
                cards: [...state.current.cards.filter((_card) => _card.id !== cardId)],
            },
        };
    }),
    on(BoardActions.moveCardSuccess, (state, { lists, move }) => {
        return {
            ...state,
            current: {
                ...state.current,
                searchIsOn: true,
            },
        }
    }),
    on(BoardActions.moveCardSuccess, (state, { lists, move }) => {
        state.current.lists.map((list) => {
            list.idCards = list.idCards.filter((card) => card !== move.cardId);
            return list;
        });
        return {
            ...state,
            currentBoard: {
                ...state.current,
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === move.listId) {
                            list.idCards.splice(move.dropIndex, 0, move.cardId);
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.moveCardViaSocket, (state, { lists, move }) => {
        state.current.lists.map((list) => {
            list.idCards = list.idCards.filter((card) => card !== move.cardId);
            return list;
        });
        return {
            ...state,
            currentBoard: {
                ...state.current,
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === move.listId) {
                            list.idCards.splice(move.dropIndex, 0, move.cardId);
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.addListSuccess, (state, { list }) => {
        return {
            ...state,
            current: {
                ...state.current,
                lists: [...state.current.lists, list],
            },
        };
    }),
    on(BoardActions.addListViaSocket, (state, { list }) => {
        return {
            ...state,
            current: {
                ...state.current,
                lists: [...state.current.lists, list],
            },
        };
    }),
    on(BoardActions.removeListSuccess, (state, { listId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.filter(
                        (card) =>
                            !state.current.lists
                                .find((_list) => {
                                    return _list.id === listId;
                                })
                                .idCards.includes(card.id)
                    ),
                ],
                lists: [
                    ...state.current.lists.filter((listState) => listState.id !== listId),
                ],
            },
        };
    }),
    on(BoardActions.removeListViaSocket, (state, { listId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.filter(
                        (card) =>
                            !state.current.lists
                                .find((_list) => {
                                    return _list.id === listId;
                                })
                                .idCards.includes(card.id)
                    ),
                ],
                lists: [
                    ...state.current.lists.filter((listState) => listState.id !== listId),
                ],
            },
        };
    }),
    on(BoardActions.moveListSuccess, (state, { lists, move }) => {
        const indexOfMoveArray = state.current.lists.findIndex(
            (list) => list.id === move.listId
        );
        state.current.lists.splice(
            move.dropIndex,
            0,
            state.current.lists.splice(indexOfMoveArray, 1)[0]
        );
        return {
            ...state,
            currentBoard: {
                ...state.current,
            },
        };
    }),
    on(BoardActions.moveListViaSocket, (state, { lists, move }) => {
        const indexOfMoveArray = state.current.lists.findIndex(
            (list) => list.id === move.listId
        );
        state.current.lists.splice(
            move.dropIndex,
            0,
            state.current.lists.splice(indexOfMoveArray, 1)[0]
        );
        return {
            ...state,
            currentBoard: {
                ...state.current,
            },
        };
    }),
    on(BoardActions.renameListSuccess, (state, { listId, name }) => {
        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === listId) {
                            list.name = name;
                            list.idCards.map((idCard) => {
                                return idCard;
                            });
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.renameListViaSocket, (state, { listId, name }) => {
        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === listId) {
                            list.name = name;
                            list.idCards.map((idCard) => {
                                return idCard;
                            });
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.updateCardNameSuccess, (state, { cardId, name }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === name) {
                            card.name = name;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.updateCardNameViaSocket, (state, { cardId, name }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === cardId) {
                            card.name = name;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    // on(
    //     BoardActions.updateCardDescriptionSuccess,
    //     (state, { cardId, description }) => {
    //         return {
    //             ...state,
    //             current: {
    //                 ...state.current,
    //                 cards: [
    //                     ...state.current.cards.map((_card) => {
    //                         if (_card.id === cardId) {
    //                             _card.description = description;
    //                         }
    //                         return _card;
    //                     }),
    //                 ],
    //             },
    //         };
    //     }
    // ),
    // on(
    //     BoardActions.updateCardDescriptionViaSocket,
    //     (state, { cardId, description }) => {
    //         return {
    //             ...state,
    //             current: {
    //                 ...state.current,
    //                 cards: [
    //                     ...state.current.cards.map((_card) => {
    //                         if (_card.id === cardId) {
    //                             _card.description = description;
    //                         }
    //                         return _card;
    //                     }),
    //                 ],
    //             },
    //         };
    //     }
    // ),
    on(
        BoardActions.updateCardStartDateViaSocket,
        (state, { cardId, startDate }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((_card) => {
                            if (_card.id === cardId) {
                                _card.startDate = startDate;
                            }
                            return _card;
                        }),
                    ],
                },
            };
        }
    ),
    on(BoardActions.updateCardEndDateViaSocket, (state, { cardId, endDate }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((_card) => {
                        if (_card.id === cardId) {
                            _card.endDate = endDate;
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(
        BoardActions.updateCardResponsibleSuccess,
        (state, { cardId, idResponsible }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((_card) => {
                            if (_card.id === cardId) {
                                _card.idResponsible = idResponsible;
                            }
                            return _card;
                        }),
                    ],
                },
            };
        }
    ),
    on(
        BoardActions.displayCardActivitiesSuccess,
        (state, { cardId, activities }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((_card) => {
                            if (_card.id === cardId) {
                                _card.activities = activities;
                            }
                            return _card;
                        }),
                    ],
                },
            };
        }
    ),
    on(
        BoardActions.hideCardActivities,
        (state, { cardId }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((_card) => {
                            if (_card.id === cardId) {
                                _card.activities = null;
                            }
                            return _card;
                        }),
                    ],
                },
            };
        }
    ),
    on(BoardActions.updateCardPrioritySuccess, (state, { cardId, priority }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((_card) => {
                        if (_card.id === cardId) {
                            _card.priority = priority;
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(
        BoardActions.updateCardResponsibleViaSocket,
        (state, { cardId, idResponsible }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((_card) => {
                            if (_card.id === cardId) {
                                _card.idResponsible = idResponsible;
                            }
                            return _card;
                        }),
                    ],
                },
            };
        }
    ),
    on(BoardActions.updateBoardDetailsSuccess, (state, boardInfo) => {
        return {
            ...state,
            current: {
                ...state.current,
                name: boardInfo.name,
                description: boardInfo.description,
                settings: {
                    color: state.current.settings.color,
                    subscribed: state.current.settings.subscribed,
                    cardCoverImages: state.current.settings.cardCoverImages,
                    enableBoardPrivacy: boardInfo.enableBoardPrivacy,
                },
            },
        };
    }),
    on(BoardActions.updateBoardDetailsViaSocket, (state, boardInfo) => {
        return {
            ...state,
            current: {
                ...state.current,
                name: boardInfo.name,
                description: boardInfo.description,
            },
        };
    }),
    on(BoardActions.addCardCommentSuccess, (state, { cardId, comment }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((_card: BoardCardModel) => {
                        if (_card.id === cardId) {
                            _card.comments.unshift(comment);
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.addCardCommentViaSocket, (state, { cardId, comment }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((_card: BoardCardModel) => {
                        if (_card.id === cardId) {
                            _card.comments.unshift(comment);
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.deleteCommentSuccess, (state, { cardId, commentId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === cardId) {
                            card.comments = [
                                ...card.comments.filter(
                                    (_comment) => _comment.id !== commentId
                                ),
                            ];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.deleteCommentViaSocket, (state, { cardId, commentId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === cardId) {
                            card.comments = [
                                ...card.comments.filter(
                                    (_comment) => _comment.id !== commentId
                                ),
                            ];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.addLabelSuccess, (state, { cardId, boardId, name, color, id }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        card.labels.push({
                            id: id,
                            name: name,
                            color: color,
                            boardId: boardId,
                        });
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.addLabelViaSocket, (state, { name, color, id }) => {
        return {
            ...state,
            current: {
                ...state.current,
                labels: [...state.current.labels, { name, color, id }],
            },
        };
    }),
    on(BoardActions.duplicateCardSuccess, (state, { duplicate, card }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [...state.current.cards, card],
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === duplicate.listId) {
                            list.idCards.push(card.id);
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.duplicateCardViaSocket, (state, { duplicate, card }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [...state.current.cards, card],
                lists: [
                    ...state.current.lists.map((list) => {
                        if (list.id === duplicate.listId) {
                            list.idCards.push(card.id);
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.removeLabelSuccess, (state, { labelId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                labels: [
                    ...state.current.labels.filter((label) => label.id !== labelId),
                ],
                cards: [
                    ...state.current.cards.map((card) => {
                        card.labels = card.labels.filter((label) => label.id !== labelId);
                        card.idLabels = card.idLabels.filter((label) => label !== labelId);
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.removeLabelViaSocket, (state, { labelId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                labels: [
                    ...state.current.labels.filter((label) => label.id !== labelId),
                ],
                cards: [
                    ...state.current.cards.map((card) => {
                        card.idLabels = card.idLabels.filter((label) => label !== labelId);
                        return card;
                    }),
                ],
            },
        };
    }),
    on(
        StudioProjectStoreActions.updateBoardMembersSuccess,
        (state, { users }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    users,
                },
            };
        }
    ),
    on(
        StudioProjectStoreActions.updateBoardMembersViaSocket,
        (state, { users }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    users,
                },
            };
        }
    ),
    on(StudioProjectsStoreActions.loadSuccess, (state) => ({
        ...initialState,
    })),
    on(BoardActions.addAttachmentSuccess, (state, { card, attachment }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((_card: BoardCardModel) => {
                        if (_card.id === card.id) {
                            _card.attachments = [..._card.attachments, attachment];
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.addAttachmentViaSocket, (state, { card, attachment }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((_card: BoardCardModel) => {
                        if (_card.id === card.id) {
                            _card.attachments = [..._card.attachments, attachment];
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.uploadAttachment, (state, { card }) => {
        return {
            ...state,
            pendingUpload: true,
        };
    }),
    on(BoardActions.uploadAttachmentSuccess, (state, { card }) => {
        return {
            ...state,
            pendingUpload: false,
        };
    }),
    on(BoardActions.imageRendered, (state, { attachment, cardId, content }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((_card: BoardCardModel) => {
                        if (_card.id === cardId) {
                            _card.attachments.map((_attachment) => {
                                if (_attachment._id === attachment._id) {
                                    _attachment.logoContent = content;
                                }
                                return _attachment;
                            });
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.clearImages, (state, { card }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((_card: BoardCardModel) => {
                        if (card) {
                            if (_card.id === card.id) {
                                _card.attachments.map((_attachment) => {
                                    _attachment.logoContent = null;
                                    return _attachment;
                                });
                            }
                            return _card;
                        } else {
                            return _card;
                        }
                    }),
                ],
            },
        };
    }),
    on(BoardActions.renderCurrentImage, (state, { content }) => {
        return {
            ...state,
            currentImage: content,
        };
    }),
    on(
        BoardActions.removeAttachmentSuccess,
        (state, { cardId, attachmentId }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((card) => {
                            if (card.id === cardId) {
                                card.attachments = [
                                    ...card.attachments.filter(
                                        (_attachment) => _attachment._id !== attachmentId
                                    ),
                                ];
                            }
                            return card;
                        }),
                    ],
                },
            };
        }
    ),
    on(
        BoardActions.updateAsCoverSuccess,
        (state, { cardId, attachmentId }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((card) => {
                            if (card.id === cardId) {
                                card.cover = attachmentId;
                            }
                            return card;
                        }),
                    ],
                },
            };
        }
    ),
    on(
        BoardActions.removeAttachmentViaSocket,
        (state, { cardId, attachmentId }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((card) => {
                            if (card.id === cardId) {
                                card.attachments = [
                                    ...card.attachments.filter(
                                        (_attachment) => _attachment._id !== attachmentId
                                    ),
                                ];
                            }
                            return card;
                        }),
                    ],
                },
            };
        }
    ),
    // on(BoardActions.renderPreviewImage, (state, { cardId, content }) => {
    //     return {
    //         ...state,
    //         current: {
    //             ...state.current,
    //             cards: [
    //                 ...state.current.cards.map(card => {
    //                     if (card.id === cardId) {
    //                         card.previewImage = content;
    //                     }
    //                     return card;
    //                 }),
    //             ],
    //         },
    //     };
    // }),
    on(BoardActions.addChecklistSuccess, (state, { checklist, cardId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === cardId) {
                            card.checklists.push(checklist);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.searchCard, (state, { text }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.name.toLowerCase().includes(text.toLowerCase())) {
                            card.isVisible = true ; 
                        } else {
                            card.isVisible = false ;
                        }
                        return card;
                    }),
                ],
                searchIsOn: text.toLowerCase() == '' ? false : true,
                filterIsOn: false,
            },
        }
    }),
    on(
        BoardActions.filterBoardCards,
        (
            state,
            { idMembers, idCategories, selectedDate, selectedPriority, idResponsable, resetAll, currentFilters }
        ) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: BoardService.filterBoardCards(
                        state.current.cards,
                        idMembers,
                        idCategories,
                        selectedDate,
                        selectedPriority,
                        idResponsable,
                        resetAll
                    ),
                    filterIsOn: BoardService.filterBoardCardsIsOn(resetAll),
                },
                filterState: BoardService.filterBoardState(
                    idMembers,
                    selectedDate,
                    idResponsable,
                    idCategories,
                    selectedPriority,
                    currentFilters
                ),
                visibility: 'filter',
            };
        }
    ),
    on(BoardActions.addChecklistViaSocket, (state, { checklist, cardId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === cardId) {
                            card.checklists.push(checklist);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.addcheckItemSuccess, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === checkItemForm.cardId) {
                            card.checklists.forEach((checklist) => {
                                if (checklist.id === checkItemForm.checklistId) {
                                    checklist.checkItems.push({
                                        id: checkItemForm.id,
                                        name: checkItemForm.name,
                                        checked: checkItemForm.checked,
                                    });
                                }
                            });
                            const checkItems = card.checklists.find(
                                (_checklist) => _checklist.id === checkItemForm.checklistId
                            ).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(
                                (_checklist) => _checklist.id === checkItemForm.checklistId
                            ).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.addcheckItemViaSocket, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === checkItemForm.cardId) {
                            card.checklists.forEach((checklist) => {
                                if (checklist.id === checkItemForm.checklistId) {
                                    checklist.checkItems.push({
                                        id: checkItemForm.id,
                                        name: checkItemForm.name,
                                        checked: checkItemForm.checked,
                                    });
                                }
                            });
                            const checkItems = card.checklists.find(
                                (_checklist) => _checklist.id === checkItemForm.checklistId
                            ).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(
                                (_checklist) => _checklist.id === checkItemForm.checklistId
                            ).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.removeCheckListSuccess, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists = [
                                ...card.checklists.filter(
                                    (_checklist) => _checklist.id !== checkListForm.checklistId
                                ),
                            ];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.removeCheckListViaSocket, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists = [
                                ...card.checklists.filter(
                                    (_checklist) => _checklist.id !== checkListForm.checklistId
                                ),
                            ];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.removeCheckItemSuccess, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists.find(
                                (_checklist) => _checklist.id === checkListForm.checklistId
                            ).checkItems = [
                                    ...card.checklists
                                        .find(
                                            (_checklist) => _checklist.id === checkListForm.checklistId
                                        )
                                        .checkItems.filter(
                                            (_checkitem) => _checkitem.id !== checkListForm.checkItemId
                                        ),
                                ];
                            const checkItems = card.checklists.find(
                                (_checklist) => _checklist.id === checkListForm.checklistId
                            ).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(
                                (_checklist) => _checklist.id === checkListForm.checklistId
                            ).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.removeCheckItemViaSocket, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists.find(
                                (_checklist) => _checklist.id === checkListForm.checklistId
                            ).checkItems = [
                                    ...card.checklists
                                        .find(
                                            (_checklist) => _checklist.id === checkListForm.checklistId
                                        )
                                        .checkItems.filter(
                                            (_checkitem) => _checkitem.id !== checkListForm.checkItemId
                                        ),
                                ];
                            const checkItems = card.checklists.find(
                                (_checklist) => _checklist.id === checkListForm.checklistId
                            ).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(
                                (_checklist) => _checklist.id === checkListForm.checklistId
                            ).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.updateCheckItemSuccess, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === checkItemForm.cardId) {
                            const checkItemToEdit = card.checklists
                                .find(
                                    (_checklist) => _checklist.id === checkItemForm.checklistId
                                )
                                .checkItems.find(
                                    (_checkItem) => _checkItem.id === checkItemForm.checkItemId
                                );

                            checkItemToEdit.name = checkItemForm.name;
                            checkItemToEdit.checked = checkItemForm.checked;
                            const checkItems = card.checklists.find(
                                (_checklist) => _checklist.id === checkItemForm.checklistId
                            ).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(
                                (_checklist) => _checklist.id === checkItemForm.checklistId
                            ).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.updateCheckItemViaSocket, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === checkItemForm.cardId) {
                            const checkItemToEdit = card.checklists
                                .find(
                                    (_checklist) => _checklist.id === checkItemForm.checklistId
                                )
                                .checkItems.find(
                                    (_checkItem) => _checkItem.id === checkItemForm.checkItemId
                                );

                            checkItemToEdit.name = checkItemForm.name;
                            checkItemToEdit.checked = checkItemForm.checked;
                            const checkItems = card.checklists.find(
                                (_checklist) => _checklist.id === checkItemForm.checklistId
                            ).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(
                                (_checklist) => _checklist.id === checkItemForm.checklistId
                            ).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.toggleMemberSuccess, (state, { toggleMemberForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === toggleMemberForm.cardId) {
                            FuseUtils.toggleInArray(
                                toggleMemberForm.memberId,
                                card.idMembers
                            );
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.toggleMemberViaSocket, (state, { toggleMemberForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === toggleMemberForm.cardId) {
                            FuseUtils.toggleInArray(
                                toggleMemberForm.memberId,
                                card.idMembers
                            );
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.toggleLabelSuccess, (state, { toggleForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === toggleForm.cardId) {
                            FuseUtils.toggleInArray(toggleForm.labelId, card.idLabels);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(
        BoardActions.updateCardIsCompletedSuccess,
        (state, { cardId, completed }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((card) => {
                            if (card.id === cardId) {
                                card.completed = completed;
                            }
                            return card;
                        }),
                    ],
                },
            };
        }
    ),
    on(
        BoardActions.unsetCardResponsibleSuccess,
        (state, { cardId }) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    cards: [
                        ...state.current.cards.map((card) => {
                            if (card.id === cardId) {
                                card.idResponsible = null;
                            }
                            return card;
                        }),
                    ],
                },
            };
        }
    ),
    on(BoardActions.toggleLabelViaSocket, (state, { toggleForm }) => {
        return {
            ...state,
            current: {
                ...state.current,
                cards: [
                    ...state.current.cards.map((card) => {
                        if (card.id === toggleForm.cardId) {
                            FuseUtils.toggleInArray(toggleForm.labelId, card.idLabels);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.updateLabelSuccess, (state, { id, name, color }) => {
        return {
            ...state,
            current: {
                ...state.current,
                labels: [
                    ...state.current.labels.map((_label) => {
                        if (_label.id === id) {
                            _label.name = name;
                            _label.color = color;
                        }
                        return _label;
                    }),
                ],
                cards: [
                    ...state.current.cards.map((card) => {
                        card.labels.map((label) => {
                            if (label.id === id) {
                                label.name = name;
                                label.color = color;
                            }
                        });
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.updateLabelViaSocket, (state, { id, name, color }) => {
        return {
            ...state,
            current: {
                ...state.current,
                labels: [
                    ...state.current.labels.map((_label) => {
                        if (_label.id === id) {
                            _label.name = name;
                            _label.color = color;
                        }
                        return _label;
                    }),
                ],
                cards: [
                    ...state.current.cards.map((card) => {
                        card.labels.map((label) => {
                            if (label.id === id) {
                                label.name = name;
                                label.color = color;
                            }
                        });
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BoardActions.toggleBackgroundPanel, (state) => ({
        ...state,
        backgroundPanelOpened: !state.backgroundPanelOpened,
    })),
    on(BoardActions.toggleListViewOn, (state, { viewType }) => ({
        ...state,
        viewType,
    })),
    on(BoardActions.sortBoardByOwner, (state) => {
        let result = [];
        let listWithRealCards = [];
        state.current.lists.forEach(list => {
            let listCards = [];
            list.idCards.forEach(card => {
                listCards.push({
                    ...state.current.cards.filter(_ => _.id === card)[0],
                    responsible: state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] ? state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] : { firstName: 'Not', lastName: 'Assigned' }
                })
            })
            listWithRealCards.push({
                id: list.id,
                cards: listCards
            })
        })
        listWithRealCards.forEach(_ => {
            _.cards = _.cards.sort((a, b) => (a.responsible.firstName > b.responsible.firstName) ? 1 : -1)
        })
        result = listWithRealCards.map(_ => {
            return {
                id: _.id,
                idCards: _.cards.map(__ => __.id)
            }
        })

        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map(_ => {
                        _.idCards = result.filter(__ => __.id === _.id)[0].idCards;
                        return _;
                    })
                ]
            },
        }
    }),
    on(BoardActions.unsortBoardbyOwner, (state) => {
        let result = [];
        let listWithRealCards = [];
        state.current.lists.forEach(list => {
            let listCards = [];
            list.idCards.forEach(card => {
                listCards.push({
                    ...state.current.cards.filter(_ => _.id === card)[0],
                    responsible: state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] ? state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] : { firstName: 'Not', lastName: 'Assigned' }
                })
            })
            listWithRealCards.push({
                id: list.id,
                cards: listCards
            })
        })
        listWithRealCards.forEach(_ => {
            _.cards = _.cards.sort((a, b) => (a.responsible.firstName > b.responsible.firstName) ? 1 : -1).reverse()
        })
        result = listWithRealCards.map(_ => {
            return {
                id: _.id,
                idCards: _.cards.map(__ => __.id)
            }
        })

        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map(_ => {
                        _.idCards = result.filter(__ => __.id === _.id)[0].idCards;
                        return _;
                    })
                ]
            },
        }
    }),
    on(BoardActions.sortBoardbyPriority, (state) => {
        let result = [];
        let listWithRealCards = [];
        state.current.lists.forEach(list => {
            let listCards = [];
            list.idCards.forEach(card => {
                listCards.push({
                    ...state.current.cards.filter(_ => _.id === card)[0],
                    responsible: state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] ? state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] : { firstName: 'Not', lastName: 'Assigned' }
                })
            })
            listWithRealCards.push({
                id: list.id,
                cards: listCards
            })
        })
        const priorities = ['low', 'medium', 'high', 'NA'];
        listWithRealCards.forEach(_ => {
            _.cards = _.cards.sort((a, b) => priorities.indexOf(a.priority) - priorities.indexOf(b.priority))
        })
        result = listWithRealCards.map(_ => {
            return {
                id: _.id,
                idCards: _.cards.map(__ => __.id)
            }
        })

        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map(_ => {
                        _.idCards = result.filter(__ => __.id === _.id)[0].idCards;
                        return _;
                    })
                ]
            },
        }
    }),
    on(BoardActions.unsortBoardbyPriority, (state) => {
        let result = [];
        let listWithRealCards = [];
        state.current.lists.forEach(list => {
            let listCards = [];
            list.idCards.forEach(card => {
                listCards.push({
                    ...state.current.cards.filter(_ => _.id === card)[0],
                    responsible: state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] ? state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] : { firstName: 'Not', lastName: 'Assigned' }
                })
            })
            listWithRealCards.push({
                id: list.id,
                cards: listCards
            })
        })
        const priorities = ['low', 'medium', 'high', 'NA'];
        if (priorities) {
            listWithRealCards.forEach(_ => {
                _.cards = _.cards.sort((a, b) => priorities.indexOf(a.priority) - priorities.indexOf(b.priority)).reverse()
            })
        }
        result = listWithRealCards.map(_ => {
            return {
                id: _.id,
                idCards: _.cards.map(__ => __.id)
            }
        })

        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map(_ => {
                        _.idCards = result.filter(__ => __.id === _.id)[0].idCards;
                        return _;
                    })
                ]
            },
        }
    }),
    on(BoardActions.sortBoardbyDate, (state) => {
        let result = [];
        let listWithRealCards = [];
        state.current.lists.forEach(list => {
            let listCards = [];
            list.idCards.forEach(card => {
                listCards.push({
                    ...state.current.cards.filter(_ => _.id === card)[0],
                    responsible: state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] ? state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] : { firstName: 'Not', lastName: 'Assigned' }
                })
            })
            listWithRealCards.push({
                id: list.id,
                cards: listCards
            })
        })
        listWithRealCards.forEach(_ => {
            _.cards = _.cards.sort((a, b) => moment(a.endDate).unix() > moment(b.endDate).unix() ? 1 : -1)
        })
        result = listWithRealCards.map(_ => {
            return {
                id: _.id,
                idCards: _.cards.map(__ => __.id)
            }
        })

        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map(_ => {
                        _.idCards = result.filter(__ => __.id === _.id)[0].idCards;
                        return _;
                    })
                ]
            },
        }
    }),
    on(BoardActions.unsortBoardbyDate, (state) => {
        let result = [];
        let listWithRealCards = [];
        state.current.lists.forEach(list => {
            let listCards = [];
            list.idCards.forEach(card => {
                listCards.push({
                    ...state.current.cards.filter(_ => _.id === card)[0],
                    responsible: state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] ? state.current.users.filter(_ => _._id === state.current.cards.filter(_ => _.id === card)[0].idResponsible)[0] : { firstName: 'Not', lastName: 'Assigned' }
                })
            })
            listWithRealCards.push({
                id: list.id,
                cards: listCards
            })
        })
        listWithRealCards.forEach(_ => {
            _.cards = _.cards.sort((a, b) => moment(a.endDate).unix() > moment(b.endDate).unix() ? 1 : -1).reverse()
        })
        result = listWithRealCards.map(_ => {
            return {
                id: _.id,
                idCards: _.cards.map(__ => __.id)
            }
        })

        return {
            ...state,
            current: {
                ...state.current,
                lists: [
                    ...state.current.lists.map(_ => {
                        _.idCards = result.filter(__ => __.id === _.id)[0].idCards;
                        return _;
                    })
                ]
            },
        }
    }),
);

export function reducer(state: State | undefined, action: Action): any {
    return boardReducer(state, action);
}
