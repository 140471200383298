export const locale = {
        lang: 'ar',
        data: {
                WRITE: ' كتابة عنوان المهمة',
                PLACEHOLDER_COMMENT:' اترك تعليقا...  ',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_ITEM: 'اضافة عنصر',
                PROJECTS_BOARD_DIALOGS_CARD_REMOVE_DATE: 'قم بإزالة تاريخ الاستحقاق',
                PROJECTS_BOARD_DIALOGS_CARD_CHECKLIST_TITLE: 'عنوان قائمة مرجعية',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_CHECKLIST: 'إضافة قائمة مرجعية',
                PROJECTS_BOARD_DIALOGS_CARD_UNSUBSCRIBE: 'إلغاء الاشتراك',
                PROJECTS_BOARD_DIALOGS_CARD_DATE: 'التواريخ',
                PROJECTS_BOARD_DIALOGS_CARD_SUBSCRIBE: 'الإشتراك',
                PROJECTS_BOARD_DIALOGS_CARD_DELETE_CARD: 'احذف المهمة',
                PROJECTS_BOARD_DIALOGS_CARD_DUPLICATE_CARD: 'كرّر المهمة',
                PROJECTS_BOARD_DIALOGS_CARD_TITLE: 'عنوان',
                PROJECTS_BOARD_DIALOGS_CARD_DESCRIPTION: 'الوصف',
                PROJECTS_BOARD_DIALOGS_CARD_DUE_DATE: 'تاريخ الاستحقاق',
                PROJECTS_BOARD_DIALOGS_CARD_RESPONSIBLE: 'المالك',
                PROJECTS_BOARD_DIALOGS_CARD_CATEGORIES: 'الألوان',
                PROJECTS_BOARD_DIALOGS_CARD_MEMBERS: 'الأعضاء',
                PROJECTS_BOARD_DIALOGS_CARD_ATTACHMENTS: 'المرفقات',
                PROJECTS_BOARD_DIALOGS_CARD_DOWNLOAD_ATTACHMENT: 'تحميل المرفق',
                PROJECTS_BOARD_DIALOGS_CARD_REMOVE_ATTACHMENT: 'إزالة المرفق',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_ATTACHMENT: 'رفع ملف ',
                PROJECTS_BOARD_DIALOGS_CARD_REMOVE_CHECKLIST: 'إزالة قائمة مرجعية',
                PROJECTS_BOARD_DIALOGS_CARD_CHANGE_NAME_CHECKLIST: 'تغيير الإسم',
                PROJECTS_BOARD_DIALOGS_CARD_COMMENTS: 'تعليقات',
                PROJECTS_BOARD_DIALOGS_CARD_CLOSE: ' أغلق',
                PROJECTS_BOARD_DIALOGS_CARD_DELETE: 'إحذف',
                PROJECTS_BOARD_DIALOGS_CARD_CONFIRM_DELETE: 'هل أنت متأكد أنك تريد حذف هذه المهمة',
                PROJECTS_BOARD_DIALOGS_CARD_CONFIRM_REMOVE_ATTACHMENT:'هل أنت متأكد أنك تريد حذف هذا المرفق؟',
                PROJECTS_BOARD_DIALOGS_CARD_CONFIRM_DELETE_FROM_BOARD: 'هل أنت متأكد من أنك تريد حذف المهمة من هذه اللوحة؟',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_TO_CARD_LABEL: 'اضف الى المهمة',
                PROJECTS_BOARD_DIALOGS_CARD_LABEL_LABEL: 'العلامات',
                PROJECTS_BOARD_DIALOGS_CARD_MEMBERS_LABEL: 'الأعضاء',
                PROJECTS_BOARD_DIALOGS_CARD_DUPLICATE_CARD_LABEL: 'تكرار المهمة',
                PROJECTS_BOARD_DIALOGS_CARD_DELETE_CARD_LABEL: 'احذف المهمة',
                PROJECTS_BOARD_DIALOGS_CARD_ATTACHMENT_LABEL: 'المرفق',
                PROJECTS_BOARD_DIALOGS_CARD_CARD_TITLE_LABEL: 'عرض المهمة',
                PROJECTS_BOARD_DIALOGS_CARD_CARD_BUTTON_MARK_COMPLETE_TEXT:'وضع علامة  مكتملة',
                PROJECTS_BOARD_DIALOGS_CARD_CARD_BUTTON_COMPLETED_TEXT:'مكتملة',
                PROJECTS_BOARD_DIALOGS_CARD_CHECKLIST: 'قائمة مرجعية',
                PROJECTS_BOARD_DIALOGS_START_DATE: 'تاريخ البدء',
                VALIDATORS_END_DATE_BEFORE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء' ,
                PROJECTS_BOARD_DIALOGS_END_DATE: 'تاريخ الاستحقاق',
                PROJECTS_BOARD_DIALOGS_PRIORITY: 'أفضلية',
                PROJECTS_BOARD_DIALOGS_SECTION: 'المادة',
                PROJECTS_BOARD_DIALOGS_ACTIVITIES: 'التاريخ',
                PROJECTS_BOARD_DIALOGS_CARD_CHOOSE_COVER: 'اختر الصورة كغلاف',
                PROJECTS_BOARD_LINK_BOARD_BUTTON: 'ربط لوحة',
                PROJECTS_BOARD_LINK: 'ربط',
                PROJECTS_BOARD_LINK_REMOVE_BOARD_BUTTON: 'حذف الارتباط',
                PROJECTS_BOARD_LINK_INFO_BAR: ' هذه المهمة مرتبطة باللوحة',
                PROJECTS_BOARD_MOVE_BOARD_BUTTON: 'إرسال إلى اللوحة',
                PROJECTS_BOARD_MOVE: 'إرسال',
                PROJECTS_BOARD_LINK_BOARD_SUBMIT_BUTTON: 'اربط بهذه اللوحة',
                PROJECTS_BOARD_INCLUDE_BOARD_CHECK_BUTTON: 'تضمين لوحات من مشاريع مساحة العمل الأخرى',
                PROJECTS_BOARD_DIALOGS_BOARDS: 'اللوحات',
                PROJECTS_BOARD_DIALOGS_ADD_BOARD: 'أضف لوحة',
                PROJECTS_BOARD_DIALOGS_ADD_OPTION: 'إضافة خيار',
                PROJECTS_BOARD_DIALOGS_CARD_SHOW_ACTIVITIES: 'عرض التاريخ',
                PROJECTS_BOARD_DIALOGS_CARD_HIDE_ACTIVITIES: 'إخفاء التاريخ',
                PROJECTS_BOARD_DIALOGS_ADD: 'أضف',
                PROJECTS_BOARD_DIALOGS_NAME_BOARD: 'إسم اللوحة',
                PROJECTS_BOARD_DIALOGS_CANCEL: 'إلغاء',
        }
};
