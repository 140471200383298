export const locale = {
    lang: 'ar',
    data: {
        NOTES_PANEL_NOTES:'الملاحظات', 
        NOTES_PANEL_TOTAL:'المجموع',
        NOTES_PANEL_ADD_NOTE:'أضف ملاحظة',
        NOTES_PANEL_EDIT:'تعديل',
        NOTES_PANEL_DELETE: 'حذف',
        NOTES_PANEL_NOTES_TITILE:'عنوان ملاحظاتك',
    }
};
