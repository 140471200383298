import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { Board } from '@bsuccess/models/brainstorming/board.model';
import { Card } from '@bsuccess/models/brainstorming/card.model';
import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { BoardCardModel } from '@bsuccess/models/board/board-card.model';

export const selectBrainstormingState = createFeatureSelector<State>(
  'workshop-brainstorming'
);

export const selectBrainstormingBoards = createSelector(
  selectBrainstormingState,
  (state: State): ProjectBoardModel[] => state.boards
);

export const selectBrainstormingCurrentBoard = createSelector(
  selectBrainstormingState,
  (state: State): Board => state.currentBoard
);

export const selectBrainstormingPending = createSelector(
  selectBrainstormingState,
  (state: State): boolean => state.pending
);

export const selectCardsToColumns = createSelector(
  selectBrainstormingState,
  (state: State): string[] => state.cardsToColumns
);

export const selectVisibility = createSelector(
  selectBrainstormingState,
  (state: State): string => state.visibility
);

export const selectExportCards = createSelector(
  selectBrainstormingState,
  (state: State): Card[] => state.exportCards
);

export const selectImportCards = createSelector(
  selectBrainstormingState,
  (state: State): BoardCardModel[] => state.importCards
);

export const selectAllCategoriesChecked = createSelector(
  selectBrainstormingState,
  (state: State): boolean => state.allCategoriesChecked
);

export const selectImportBoard = createSelector(
  selectBrainstormingState,
  (state: State): BoardModel => state.selectedImportBoard
);

export const selectShowMembersNames = createSelector(
  selectBrainstormingState,
  (state: State): boolean => state.currentBoard ? state.currentBoard.settings.showAuthors : false
);

export const selectShowAllCards = createSelector(
  selectBrainstormingState,
  (state: State): boolean => state.currentBoard ? state.currentBoard.settings.hideAllCards : false
);

export const selectPoints = createSelector(
  selectBrainstormingState,
  (state: State): number => state.points
);

export const selectUserRates = createSelector(
  selectBrainstormingState,
  (state: State): any[] => {
    const users = [];
    if (state.currentBoard) {
      state.currentBoard.cards.forEach((card) => {
        card.rates.forEach((rate) => {
          if (!users.map((user) => user.email).includes(rate.user.email)) {
            users.push(rate.user);
          }
        });
      });
    }
    return users;
  }
);

export const checkDisplayLabels = createSelector(
  selectBrainstormingState,
  (state: State): boolean => state.currentBoard ? state.currentBoard.settings.showLabels : false
);
