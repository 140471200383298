import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';

export interface State {
    filtredSessions: ProjectSessionModel[];
    sessions: ProjectSessionModel[];
    projects: number;
    boards: number;
}

export const initialState: State = {
    filtredSessions: [],
    sessions: [],
    projects: 0,
    boards: 0
};
