export const locale = {
    lang: 'fr',
    data: {
        ADD_ONS_DIALOG_PURCHASE_ADD: 'Acheter des modules complémentaires' ,
        ADD_ONS_DIALOG_CHOOSE_ADD: 'Choisissez votre module complémentaire' ,
        ADD_ONS_DIALOG_CHOOSE_TITLE: '100 participants supplémentaires',
        ADD_ONS_DIALOG_CHOOSE_DESCRIPTION:'Invitez jusqu\'à 100 participants à un atelier public.',
        ADD_ONS_DIALOG_CHOOSE_YEAR: 'an',
        ADD_ONS_DIALOG_CHOOSE_MONTH: 'mois' ,
        ADD_ONS_DIALOG_CHOOSE_BUY: 'ACHETER' ,
        ADD_ONS_DIALOG_CHOOSE_CHECKOUT: 'Vérifier' ,
        ADD_ONS_DIALOG_CHOOSE_QUANTITY: 'Quantité' ,
        ADD_ONS_DIALOG_CHOOSE_METHODS: 'méthodes de payement' ,
        ADD_ONS_DIALOG_CHOOSE_ORDER_SUMMARY: 'Récapitulatif de la commande' ,
        ADD_ONS_DIALOG_CHOOSE_TOTAL_AMOUNT: 'Montant total' ,
        ADD_ONS_DIALOG_CHOOSE_START_IMMEDIATELY: 'Commencer immédiatement' ,
        ADD_ONS_DIALOG_CHOOSE_RENEWS_ON: 'Renouvelle le:' ,
        ADD_ONS_DIALOG_CHOOSE_ACCEPT_CREDIT: 'Nous acceptons la plupart des cartes de crédit et de débit. Tous les paiements sont sécurisés et traités par Stripe.' ,
        ADD_ONS_DIALOG_CHOOSE_MORE_INFO:'Plus d\'informations à ce sujet.',
        ADD_ONS_DIALOG_CHOOSE_PURCHASE:'Acheter',
    }
};
