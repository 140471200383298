import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { SessionModel } from '@bsuccess/models/session/session.model';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';
import { SessionDetailModel } from '@bsuccess/models/session/session-detail.model';
import { ServiceVersionModel } from '@bsuccess/models/service/service-version.model';
import { ProjectModel } from '@bsuccess/models/project/project.model';

export const selectSessionState = createFeatureSelector<State>('studio-session');

export const selectStepperPosition = createSelector(
    selectSessionState,
    (state: State): string => state.stepperPosition
);

export const selectCurrent = createSelector(
    selectSessionState,
    (state: State): SessionModel => state.current
);

export const selectSessionId = createSelector(
    selectCurrent,
    (state: SessionModel): string => state._id
);

export const selectVersionId = createSelector(
    selectCurrent,
    (state: SessionModel): string => state.versionId
);

export const selectSessionUsers = createSelector(
    selectCurrent,
    (session: SessionModel): ProjectUserModel[] => session.users
);

export const selectSessionParticipants = createSelector(
    selectCurrent,
    (session: SessionModel): ProjectUserModel[] =>
        session.participants as ProjectUserModel[]
);

export const selectSessionGuests = createSelector(
    selectCurrent,
    (session: SessionModel): { email: string, invited?: boolean }[] => session.guests
);
export const selectSessionTasks = createSelector(
    selectCurrent,
    (session: SessionModel): SessionTaskModel[] => session.tasks
);

export const selectCurrentStatus = createSelector(
    selectSessionState,
    (state: State): string => state.current.status
);

export const selectSessionToken = createSelector(
    selectCurrent,
    (session: SessionModel): string => session.token
);

export const selectSessionName = createSelector(
    selectCurrent,
    (session: SessionModel): string => session.name
);

export const selectSessionDetail = createSelector(
    selectCurrent,
    (session: SessionModel): SessionDetailModel => session.detail
);

export const selectSessionStartDateEndDate = createSelector(
    selectCurrent,
    (session: SessionModel): [string, string] => [
        session.startDate,
        session.endDate,
    ]
);

// TODO MOVE TO SEPERATE STORE
export const selectVersions = createSelector(
    selectSessionState,
    (state: State): ServiceVersionModel[] => state.versions
);

export const selectSelectedProject = createSelector(
    selectSessionState,
    (state: State): ProjectModel => state.selectedProject
);

export const selectSessionAnimators = createSelector(
    selectSessionState,
    (state: State): ProjectModel[] => state.current ? state.current.users.filter(_ => _.position === 'animator') : []
);

