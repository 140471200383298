export const locale = {
    lang: 'ar',
    data: {
        MANAGE_MEMBERS: 'دعوة الأعضاء',
        INVITAION_ONE: 'قم بدعوة الأعضاء للتعاون في المنتدى',
        INVITAION_TWO: 'إذا لم يكن لديهم حساب إكْسِلْوايْ ، فسوف يتلقون دعوة لإنشائه.',
        MEMBERS: 'الأعضاء',
        THE_EMAIL: 'البريد الإلكتروني',
        POSITION: 'موضع',
        SPONSOR: 'الكفيل', 
        ADMIN: 'مشرف',
        ADD_USER_BUTTON_LABEL: 'إضافة مستخدم',
        RESPONSIBLE: 'المالِك',
        ROLE: 'دَوْر',
        READING: 'مُشاهِد',
        MODIFICATION: 'مُحَرّر',
        REMOVE: 'إزالة',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
        ADMIN_DESCIPTION:'.يمكن تكوين اللوحة بشكل كامل ',
        MODIFICATION_DESCIPTION:'يمكن إدارة المهام والأعضاء',
        READING_DESCIPTION:'.لا يمكن تحرير المهام.',
    }
};
