export const locale = {
    lang: 'ar',
    data: {
        EDIT: 'تعديل المعلومات',
        LAST: 'اسم العائلي',
        FIRST: 'الاسم الشخصي',
        ABOUT: 'حول',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
    }
};
