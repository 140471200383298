import { Injectable } from '@angular/core';
import { NoteModel } from '@bsuccess/models/project/note.model';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class NotesService {

    constructor(private _httpClient: HttpClient) { }

    addNote(sessionToken: string, note: NoteModel): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectSessionNote}/${sessionToken}`, note
        );
    }

    updateNote(note: NoteModel, sessionKey: string): Observable<any> {
        return this._httpClient.put(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}/note/${note._id}`, note
        );
    }

    removeNote(noteId: string, sessionKey: string): Observable<any> {
        return this._httpClient.delete(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}/note/${noteId}`
        );
    }
}
