export const locale = {
    lang: 'en',
    data: {
        EDIT: 'Edit information',
        ADDRESS: 'Address',
        PHONE: 'Phone.',
        WEBSITE: 'Website',
        SAVE: 'Save',
        CANCEL: 'Cancel',
    }
};
