export const locale = {
    lang: 'en',
    data: {
        PROJECTS_DIALOGS_MEMBERS_DIALOG_MANAGE_MEMBERS: 'Manage members',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_INVITAION: 'Invite members to collaborate on the project. If they do not have an Excelway account, they will receive an invitation to create one.',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_MEMBERS: 'Member',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_THE_EMAIL: 'Email',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_POSITION: 'Position',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_SPONSOR: 'Sponsor',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_ADMIN: 'Admin',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_ADD_USER_BUTTON_LABEL: 'Add user',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_RESPONSIBLE: 'Owner',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_ROLE: 'Role',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_READING: 'Viewer',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_MODIFICATION: 'Editor',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_REMOVE: 'Remove',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_SAVE: 'Save',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_CANCEL: 'Cancel',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_APPLY_CHANGES_ON_CHILDREN: 'Apply these changes to all Workshops, Boards and Sub-Projects of this Project.'
    }
};
