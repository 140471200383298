import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewEncapsulation,
} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { AnimatorTimerService } from './animator-timer.service';

@Component({
    selector: 'animator-timer',
    templateUrl: './animator-timer.component.html',
    styleUrls: ['./animator-timer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class AnimatorTimerComponent implements OnInit {
    @Input() minHighLight: string;
    @Input() secHighLight: string;
    @Input() isActive = false;
    @Input() value = 0;
    @Output() start = new EventEmitter<number>();
    @Output() pause = new EventEmitter<any>();
    @Output() stop = new EventEmitter<any>();
    @Input() theme = '2';
    countDownSubscription: Subscription;
    countDownObservable: Observable<any>;
    countdown: any;
    timeLeft: any;
    started = false;
    showPause = false;
    actions = true;

    constructor(public timerService: AnimatorTimerService) {
        this.countdown = {
            minutes: '',
            seconds: '',
        };
    }

    ngOnInit(): void {
        this.countdown = this.timerService.initTimer(this.value);
    }

    ngOnChanges(changes): void {
        if (!changes.secHighLight) {
            if (changes.value.currentValue > 0) {
                if (!this.started) {
                    this.startEvent();
                }
            } else {
                if (this.started) {
                    this.stopEvent();
                }
            }
        }
    }

    startEvent(): void {
        this.actions = false;
        this.started = true;
        this.showPause = true;
        this.countDownObservable = this.timerService.startTimer(this.value);
        this.countDownSubscription = this.countDownObservable.subscribe(
            value => {
                this.countdown = value;
                if (value.minutes === 0 && value.seconds === '00') {
                    this.stopEvent();
                }
            }
        );
        this.start.emit(this.timerService.getSeconds(this.countdown));
    }

    pauseEvent(): void {
        this.showPause = false;
        this.started = false;
        this.countDownSubscription.unsubscribe();
        this.timerService.pauseTimer();
        this.pause.emit();
    }

    stopEvent(): void {
        this.actions = true;
        this.started = false;
        this.showPause = false;
        if (!this.countDownSubscription.closed) {
            this.countDownSubscription.unsubscribe();
        }
        this.countDownObservable = this.timerService.stopTimer();
        this.countDownSubscription = this.countDownObservable.subscribe(
            value => {
                this.countdown = value;
            }
        );
        this.stop.emit();
    }

    increaseTime(): void {
        if (this.value < 1800) {
            this.value += 60;
            this.countdown = this.timerService.initTimer(this.value);
        }
    }

    decreaseTime(): void {
        if (this.value > 0) {
            this.value -= 60;
            this.countdown = this.timerService.initTimer(this.value);
        }
    }

    toMinutes(): void {
        return this.timerService.getMinutes(this.value);
    }
}
