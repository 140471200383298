import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as BoardsActions from './actions';

const boardsReducer = createReducer(
  initialState,
  on(BoardsActions.loadSuccess, (state, { response }) => ({
    ...state,
    filtredFavorites: response.favorites,
    filtredOthers: response.others,
    favorites: response.favorites,
    others: response.others,
  })),
  on(BoardsActions.addFavoriteSuccess, (state, { id }) => {
    const favorite = state.others.find(board => board.id === id);
    return {
      ...state,
      filtredFavorites: [favorite, ...state.filtredFavorites],
      filtredOthers: state.filtredOthers.filter(board => board.id !== id),
      favorites: [favorite, ...state.favorites],
      others: state.others.filter(board => board.id !== id),
    };
  }),
  on(BoardsActions.removeFavoriteSuccess, (state, { id }) => {
    const other = state.favorites.find(board => board.id === id);
    return {
      ...state,
      filtredOthers: [other, ...state.filtredOthers],
      filtredFavorites: state.filtredFavorites.filter(board => board.id !== id),
      others: [other, ...state.others],
      favorites: state.favorites.filter(board => board.id !== id),
    };
  }),
  on(BoardsActions.searchBoard, (state, { text }) => {
    return {
      ...state,
      filtredFavorites:
        text.toLowerCase() === ''
          ? state.favorites
          : state.favorites.filter(board => {
              if (board.name.toLowerCase().includes(text.toLowerCase())) {
                return board;
              }
            }),
      filtredOthers:
        text.toLowerCase() === ''
          ? state.others
          : state.others.filter(board => {
              if (board.name.toLowerCase().includes(text.toLowerCase())) {
                return board;
              }
            }),
    };
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return boardsReducer(state, action);
}
