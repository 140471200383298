import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActionPlanComponent } from '../action-plan/action-plan.component';
import { locale as english } from 'app/i18n/@bsuccess/en';
import { locale as frensh } from 'app/i18n/@bsuccess/fr';
import { locale as arabic } from 'app/i18n/@bsuccess/ar';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CardComponent implements OnInit {
    constructor(
        public matDialogRef: MatDialogRef<ActionPlanComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public dialog: MatDialog,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void { }
}
