import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as SessionsActions from './actions';
import { StudioSessionStoreActions } from '../session-store';

const sessionsReducer = createReducer(
    initialState,
    on(SessionsActions.loadSuccess, (state, { response }) => ({
        ...state,
        sessions: response.sessions,
        filtredSessions: response.sessions,
    })),
    on(SessionsActions.searchSession, (state, { text }) => {
        return {
            ...state,
            filtredSessions:
                text.toLowerCase() === ''
                    ? state.sessions
                    : state.sessions.filter(session => {
                        if (session.name.toLowerCase().includes(text.toLowerCase())) {
                            return session;
                        }
                    }),
        };
    }),
    on(SessionsActions.searchSessionByStatus, (state, { status }) => {
        return {
            ...state,
            filtredSessions:
                status === 'all'
                    ? state.sessions
                    : state.sessions.filter(session => {
                        if (session.status === status) {
                            return session;
                        }
                    }),
        };
    }),
    on(SessionsActions.getGlobalReport, (state, { token }) => {
        return {
            ...state,
            pendingReportToken: token
        };
    }),
    on(SessionsActions.getGlobalReportSuccess, (state, { report }) => {
        return {
            ...state,
            pendingReportToken: null
        };
    }),
    on(StudioSessionStoreActions.archiveSessionSuccess, (state, { id }) => ({
        ...state,
        sessions: state.sessions.filter(session => session._id !== id),
        filtredSessions: state.filtredSessions.filter(session => session._id !== id)
    })),
    on(StudioSessionStoreActions.closeSessionByStatusSuccess, (state, { id }) => ({
        ...state,
        sessions: state.sessions.length > 0 ?  state.sessions.map(session => {
            if (session._id === id) {
                session.status = 'closed';
            }
            return session;
        }) : [],
        filtredSessions: state.filtredSessions.length > 0 ? state.filtredSessions.map(session => {
            if (session._id === id) {
                session.status = 'closed';
            }
            return session;
        }) : [],
    })),
    on(StudioSessionStoreActions.openSessionByStatusSuccess, (state, { id }) => ({
        ...state,
        sessions: state.sessions.length > 0 ?  state.sessions.map(session => {
            if (session._id === id) {
                session.status = 'declared';
            }
            return session;
        }) : [],
        filtredSessions: state.filtredSessions.length > 0 ? state.filtredSessions.map(session => {
            if (session._id === id) {
                session.status = 'declared';
            }
            return session;
        }) : [],
    })),
);

export function reducer(state: State | undefined, action: Action): any {
    return sessionsReducer(state, action);
}
