import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as NavBarActions from './actions';
import { LoginStoreActions } from '../login-store';
import { StudioProjectStoreActions } from '../studio-store/project-store';

const navbarReducer = createReducer(
  initialState,
  on(LoginStoreActions.loadCacheSuccess, (state, { cache }) => ({
    ...state,
    product: cache.product,
  })),
  on(NavBarActions.toggleTasksPanel, (state) => ({
    ...state,
    tasksPanelOpened: !state.tasksPanelOpened,
  })),
  on(NavBarActions.toggleNotesPanel, (state) => ({
    ...state,
    notesPanelOpened: !state.notesPanelOpened,
  })),
  on(LoginStoreActions.loadSessionSuccess, (state) => ({
    ...state,
    product: 'workshop',
  })),
  on(NavBarActions.updateProductSuccess, (state, { product }) => ({
    ...state,
    product,
  })),
  on(NavBarActions.updateNavigationSuccess, (state, { navigation }) => ({
    ...state,
    navigation: [...navigation],
  })),
  on(StudioProjectStoreActions.loadCurrentSuccess, (state, { project }) => ({
    ...state,
    navigation: [...state.navigation.map(app => {
        return {
            ...app,
            children: [...app.children.map(child => {
                return {
                    ...child,
                    url: child.baseUrl + project._id
                }
            })]
        }
    })],
  }))
  // on(StudioHomeStoreActions.loadSuccess,
  //     StudioProjectsStoreActions.loadSuccess,
  //     StudioSessionsStoreActions.loadSuccess,
  //     StudioBoardsStoreActions.loadSuccess,
  //     StudioSessionStoreActions.loadCurrentSuccess, state => ({
  //         ...state,
  //         product: 'studio',
  //     })),
);

export function reducer(state: State | undefined, action: Action): any {
  return navbarReducer(state, action);
}
