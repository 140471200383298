import { GlobalErrorHandler } from './../@bsuccess/services/error-interceptor.service';
import { NgModule, ErrorHandler, Injectable, Inject, InjectionToken } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TagifyModule } from 'ngx-tagify';


import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';
import { NgxStripeModule } from 'ngx-stripe';

import { fuseConfig } from 'app/fuse-config';

import { HighlightModule } from 'ngx-highlightjs';

import { AuthInterceptor } from '@bsuccess/services/auth-interceptor.service';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { RootStoreModule } from './root-store/root-store.module';
import { environment } from 'environments/environment';
import { WorkshopGuard } from '@bsuccess/guards/workshop.guard';
import { LoginGuard } from '@bsuccess/guards/login.guard';
import { StudioGuard } from '@bsuccess/guards/studio.guard';
import { ProfileGuard } from '@bsuccess/guards/profile.guard';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import * as Rollbar from 'rollbar';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

const appRoutes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        canLoad: [LoginGuard],
    },
    {
        path: 'studio',
        loadChildren: () =>
            import('./studio/studio.module').then(m => m.StudioModule),
        canLoad: [StudioGuard]
    },
    {
        path: 'workshop',
        loadChildren: () =>
            import('./workshop/workshop.module').then(m => m.WorkshopModule),
        canLoad: [WorkshopGuard],
    },
    {
        path: 'access',
        loadChildren: () =>
            import('./access/access.module').then(m => m.AccessModule)
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./profile/profile.module').then(m => m.ProfileModule),
        canLoad: [ProfileGuard]
    },
    {
        path: '**',
        redirectTo: 'studio/projects'
    }
];

const appSocket: SocketIoConfig = {
    url: `${environment.socket.url}?apikey=${environment.socket.apikey}`,
    options: {
        autoConnect: true,
        path: '/',
        transports : ['websocket'],
        withCredentials: true
    },
};

const rollbarConfig = {
    accessToken: 'b8b85170af0b4c0fab3c8a835a570117',
    captureUncaught: true,
    captureUnhandledRejections: true,
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

    handleError(err: any): void {
        console.error(err.originalError || err);
        this.rollbar.error(err.originalError || err);
    }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
        TranslateModule.forRoot(),
        TagifyModule.forRoot(),
        CKEditorModule,

        SortablejsModule.forRoot({ animation: 150 }),

        SocketIoModule.forRoot(appSocket),
        // Ngx time picker library
        NgxMaterialTimepickerModule,
        NgxStripeModule.forRoot('pk_test_51HMC2aIASQYBUqwRN1ay7HfZ442mV0ZaP4wTu3KnsWCLTBt8yxnd2Vd1Z4FUyaaoNFpAbUeZcY6QJr0Y8sk2OlEv00N2KtvJmP'),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatSidenavModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        RootStoreModule,

        // Metronic modules
        HighlightModule,
        NgxDnDModule.forRoot()
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        environment.production
            ? [{
                provide: ErrorHandler, useClass: RollbarErrorHandler
            },
            { provide: RollbarService, useFactory: rollbarFactory }]
            : []
    ],
})
export class AppModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        matIconRegistry.addSvgIconSet(
            domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/mdi.svg')
        );
    }
}
