import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../i18n/login/dialogs/workspace-dialog/en';
import { locale as frensh } from '../../../i18n/login/dialogs/workspace-dialog/fr';
import { locale as arabic } from '../../../i18n/login/dialogs/workspace-dialog/ar';
import { Observable } from 'rxjs';
import { LoginStoreSelectors, LoginStoreActions } from 'app/root-store/login-store';
import { RootStoreState } from 'app/root-store';
import { Store, select } from '@ngrx/store';

@Component({
    selector: 'login-workspace-dialog',
    templateUrl: 'workspace-dialog.component.html',
    styleUrls: ['workspace-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginWorkspaceDialogComponent implements OnInit {
    loginForm: UntypedFormGroup;
    workspace$: Observable<string>;
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        public dialogRef: MatDialogRef<LoginWorkspaceDialogComponent>,
        private _store: Store<RootStoreState.State>,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            tenant: ['', Validators.required],
        });

        this.workspace$ = this._store.pipe(select(LoginStoreSelectors.selectLoginTenant));
        this.workspace$.subscribe(workspace => {
            this.loginForm.controls.tenant.setValue(workspace);
        });
    }
    
    submit(): void {
        this._store.dispatch(
            LoginStoreActions.updateTenant({
                tenant: this.loginForm.controls.tenant.value.trim().toLowerCase()
            })
        );
    }
}
