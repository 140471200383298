import { Injectable, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';

import { environment } from 'environments/environment';
import * as uuid from 'uuid';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

import { Board } from '@bsuccess/models/brainstorming/board.model';
import { Card } from '@bsuccess/models/brainstorming/card.model';
import { List } from '@bsuccess/models/brainstorming/list.model';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';
import { BoardCardModel } from '@bsuccess/models/board/board-card.model';
import { SessionTaskModel } from './../models/session/session-task.model';
import html2canvas from 'html2canvas';

@Injectable({
    providedIn: 'root',
})
export class BrainstormingService {
    remoteCalls: string[] = [];
    routeParams: any;
    board: any;

    onBoardChanged: BehaviorSubject<any>;

    static avg(card: Card): number {
        if (card.rates.length > 0) {
            let sum = 0;
            card.rates.forEach(element => {
                sum += element.rate;
            });
            return Math.round((sum / card.rates.length) * 100) / 100;
        } else {
            return 0;
        }
    }

    static ecartType(card: Card): number {
        let sum = 0;
        let s2 = 0;
        let m = 0;
        let v = 0;
        card.rates.forEach(element => {
            sum += element.rate;
            s2 += element.rate * element.rate;
        });
        m = sum / card.rates.length;
        v = s2 / card.rates.length - m * m;
        return Math.sqrt(v);
    }

    static consensus(card: Card): string {
        let consensus: string;
        if (card.rates) {
            if (card.rates.length > 1) {
                if (this.ecartType(card) > this.avg(card) / 2) {
                    consensus = 'Faible';
                } else {
                    consensus = 'Fort';
                }
            } else {
                consensus = 'Fort';
            }
        }
        return consensus;
    }
    //  * find functions
    static findByIdCards(cards: Card[], idCards: string[]): Card[] {
        return cards.filter(card => idCards.includes(card.id));
    }

    static findByIdLists(
        cards: Card[],
        lists: List[],
        idLists: string[]
    ): Card[] {
        return cards.filter(card =>
            lists
                .filter(list => idLists.includes(list.id))
                .reduce((acc, list) => acc.concat(list.idCards), [])
                .includes(card.id)
        );
    }

    static findByIdCategories(cards: Card[], idCategories: string[]): Card[] {
        return cards.filter(card =>
            idCategories.includes(card.typeCard)
        );
    }

    static findByScore(cards: Card[], score: number, operator: string): Card[] {
        const operators = ['$lte', '$gte'];
        return cards.filter(card =>
            operators.includes(operator) && operator === '$lte'
                ? card.sum <= score
                : card.sum >= score
        );
    }
    // * filter functions
    static showAll(cards: Card[]): Card[] {
        return cards.map(card => {
            return { ...card, isVisible: true };
        });
    }

    static filterByIdCards(cards: Card[], idCards: string[]): Card[] {
        return cards.map(card => {
            if (idCards.includes(card.id)) {
                return { ...card, isVisible: true };
            }
            return {
                ...card,
                isVisible: false,
            };
        });
    }

    static findByIdBoardCards(cards: BoardCardModel[], idCards: string[]): BoardCardModel[] {
        return cards.filter(card => idCards.includes(card.id));
    }

    static findByIdBoardLists(
        cards: BoardCardModel[],
        lists: List[],
        idLists: string[]
    ): BoardCardModel[] {
        return cards.filter(card =>
            lists
                .filter(list => idLists.includes(list.id))
                .reduce((acc, list) => acc.concat(list.idCards), [])
                .includes(card.id)
        );
    }

    static filterByIdLists(
        cards: Card[],
        lists: List[],
        idLists: string[]
    ): Card[] {
        return cards.map(card => {
            if (
                lists
                    .filter(list => idLists.includes(list.id))
                    .reduce((acc, list) => acc.concat(list.idCards), [])
                    .includes(card.id)
            ) {
                return { ...card, isVisible: true };
            }
            return {
                ...card,
                isVisible: false,
            };
        });
    }

    static filterByIdCategories(cards: Card[], idCategories: string[]): Card[] {
        return cards.map(card => {
            if (idCategories.includes(card.typeCard)) {
                return { ...card, isVisible: true };
            }

            return { ...card, isVisible: false };
        });
    }

    static filterByIdTags(cards: Card[], idTags: string[]): Card[] {
        return cards.map(card => {
            if (card.idLabels.some(label => idTags.includes(label) ? true : false )) {
                return { ...card, isVisible: true };
            }

            return { ...card, isVisible: false };
        });
    }

    static filterByScore(
        cards: Card[],
        score: number,
        operator: string
    ): Card[] {
        const operators = ['$lte', '$gte'];
        return cards.map(card => {
            if (
                operators.includes(operator) && operator === '$lte'
                    ? card.sum <= score
                    : card.sum >= score
            ) {
                return { ...card, isVisible: true };
            }

            return { ...card, isVisible: false };
        });
    }
    // * cards to culumns functions
    static allCardsToColumns(cards: Card[]): string[] {
        return cards
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => card.name);
    }

    static cardsToColumnsByIdCards(cards: Card[], idCards: string[]): string[] {
        return this.findByIdCards(cards, idCards)
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => card.name);
    }

    static cardsToColumnsByIdLists(
        cards: Card[],
        lists: List[],
        idLists: string[]
    ): string[] {
        return this.findByIdLists(cards, lists, idLists)
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => card.name);
    }

    static cardsToColumnsByIdCategories(
        cards: Card[],
        idCategories: string[]
    ): string[] {
        return this.findByIdCategories(cards, idCategories)
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => card.name);
    }

    static cardsToColumnsByScore(
        cards: Card[],
        score: number,
        operator: string
    ): string[] {
        return this.findByScore(cards, score, operator)
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => card.name);
    }

    //  * export cards functions

    static exportAllCards(cards: Card[]): Card[] {
        return cards.filter(
            card => card.isVisible === undefined || card.isVisible === true
        );
    }

    static exportCardsByIdCards(cards: Card[], idCards: string[]): Card[] {
        return this.findByIdCards(cards, idCards).filter(
            card => card.isVisible === undefined || card.isVisible === true
        );
    }

    static exportCardsByIdLists(
        cards: Card[],
        lists: List[],
        idLists: string[]
    ): Card[] {
        return this.findByIdLists(cards, lists, idLists).filter(
            card => card.isVisible === undefined || card.isVisible === true
        );
    }

    static exportCardsByIdLabels(cards: Card[], idLabels: string[]): Card[] {
        return this.findByIdCategories(cards, idLabels).filter(
            card => card.isVisible === undefined || card.isVisible === true
        );
    }

    static exportCardsByScore(
        cards: Card[],
        score: number,
        operator: string
    ): Card[] {
        return this.findByScore(cards, score, operator).filter(
            card => card.isVisible === undefined || card.isVisible === true
        );
    }
    // * cards to actionplan functions

    // ! function that converts card to SessionTask

    private static cardToSessionTask(card: Card): SessionTaskModel {
        return {
            _id: uuid.v4(),
            name: card.name,
            description: card.description,
            comment: card.description,
            checkItems: card.checkItems,
            checkItemsChecked: card.checkItemsChecked,
            checklists: card.checklists,
            status: 'open'
            // due date of the card is not persisted
        };
    }

    //  !
    static allCardsToActionplans(cards: Card[]): SessionTaskModel[] {
        return cards
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => {
                return this.cardToSessionTask(card);
            });
    }

    static cardsToActionplansByIdCards(
        cards: Card[],
        idCards: string[]
    ): SessionTaskModel[] {
        return this.findByIdCards(cards, idCards)
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => {
                return this.cardToSessionTask(card);
            });
    }

    static cardsToActionplansByIdLists(
        cards: Card[],
        lists: List[],
        idLists: string[]
    ): SessionTaskModel[] {
        return this.findByIdLists(cards, lists, idLists)
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => {
                return this.cardToSessionTask(card);
            });
    }

    static cardsToActionplansByIdLabels(
        cards: Card[],
        idLabels: string[]
    ): SessionTaskModel[] {
        return this.findByIdCategories(cards, idLabels)
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => {
                return this.cardToSessionTask(card);
            });
    }

    static cardsToActionplansByScore(
        cards: Card[],
        score: number,
        operator: string
    ): SessionTaskModel[] {
        return this.findByScore(cards, score, operator)
            .filter(
                card => card.isVisible === undefined || card.isVisible === true
            )
            .map(card => {
                return this.cardToSessionTask(card);
            });
    }

    constructor(private _httpClient: HttpClient) {
        this.onBoardChanged = new BehaviorSubject([]);
    }

    getBoards(sessionKey: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectBoards}`
        );
    }

    getBoard(boardId: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.brainstormingBoard}/${boardId}`
        );
    }

    saveBoard(board: Board): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.brainstormingBoard}`,
            board
        );
    }

    importCardsToBoard(brainstormingId: string, boardId: string, listId: string, cardsId: string[]): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoardImportCards}/${brainstormingId}`,
            {
                boardId,
                listId,
                cardsId
            }
        );
    }

    deleteBoard(id: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoard}` +
            '/archive/' +
            id,
            {}
        );
    }

    updateTimer(
        brainstormingId: string,
        participative: { timer: boolean, categories: SessionCategoryModel[], balance: number, maxPerCard: number, timerType: string }
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingUpdateTimer}/${brainstormingId}`,
            participative
        );
    }

    cardsRates(brainstormingId: string, participantId: string, rates: { cardId: string, rate: number }[]): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingCardsRates}/` +
            brainstormingId,
            {
                participantId,
                cardsRating: rates
            }
        );
    }

    cardRate(brainstormingId: string, userId: string, rate: { cardId: string, rate: number }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingCardsRates}/` +
            brainstormingId,
            {
                userId,
                cardRating: rate
            }
        );
    }

    sort(brainstormingId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingSort}/` +
            brainstormingId,
            {}
        );
    }

    addCard(listId: string, newCard: any): Promise<any> {
        this.board.lists.map((list: any) => {
            if (list.id === listId) {
                return list.idCards.push(newCard.id);
            }
        });

        this.board.cards.push(newCard);

        return this.updateBoard();
    }

    addList(newList: string): Promise<any> {
        this.board.lists.push(newList);

        return this.updateBoard();
    }

    removeList(listId: string): Promise<any> {
        const list = this.board.lists.find((_list: any) => {
            return _list.id === listId;
        });

        for (const cardId of list.idCards) {
            this.removeCard(cardId);
        }

        const index = this.board.lists.indexOf(list);

        this.board.lists.splice(index, 1);

        return this.updateBoard();
    }

    removeCard(cardId: string, listId?: string): void {
        const card = this.board.cards.find((_card: any) => {
            return _card.id === cardId;
        });

        if (listId) {
            const list = this.board.lists.find((_list: any) => {
                return listId === _list.id;
            });
            list.idCards.splice(list.idCards.indexOf(cardId), 1);
        }

        this.board.cards.splice(this.board.cards.indexOf(card), 1);

        this.updateBoard();
    }

    updateBoard(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post('api/scrumboard-boards/' + this.board.id, this.board)
                .subscribe(response => {
                    this.onBoardChanged.next(this.board);
                    resolve(this.board);
                }, reject);
        });
    }

    updateCard(newCard: any): void {
        this.board.cards.map((_card: any) => {
            if (_card.id === newCard.id) {
                return newCard;
            }
        });

        this.updateBoard();
    }

    createNewBoard(board: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post('api/scrumboard-boards/' + board.id, board)
                .subscribe(response => {
                    resolve(board);
                }, reject);
        });
    }

    saveCardstoColumns(boardId: string, cards: string[]): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.brainstormingColumns}/${boardId}`,
            cards
        );
    }

    saveCategories(categories: SessionCategoryModel[], token: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectSession}/${token}/category`,
            categories
        );
    }

    saveExportCards(
        boardId: string,
        listId: string,
        cards: Card[],
    ): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.exportCards}/${boardId}/${listId}`,
            cards
        );
    }

    getDateNow(): string {
        return (
            new Date()
                .toISOString()
                .slice(0, 10)
                .replace('-', '_')
                .replace('-', '_') +
            '_' +
            new Date(Date.now()).toLocaleTimeString()
        );
    }

    getCards(board: Board, categories: SessionCategoryModel[], actionPlans: SessionTaskModel[]): any {
        const cardsList = [];
        board.lists.map((list, i) => {
            cardsList.push({
                text: 'Colonne ' + (i + 1) + ': ' + list.name,
                note: 'Note',
                category: 'Catégorie'
            });
            list.idCards.map(idCard => {
                if (
                    board.cards.filter(_ => _.id === idCard)[0].isVisible ===
                    undefined ||
                    board.cards.filter(_ => _.id === idCard)[0].isVisible
                ) {
                    cardsList.push({
                        text: board.cards.filter(_ => _.id === idCard)[0].name,
                        note: board.cards.filter(_ => _.id === idCard)[0].sum,
                        category: categories.find(_ => _.id === (board.cards.filter(_ => _.id === idCard)[0].typeCard)) ? categories.find(_ => _.id === (board.cards.filter(_ => _.id === idCard)[0].typeCard)).name : '',
                    });
                }
            });
            cardsList.push({
                text: '',
            });
        });
        cardsList.push({
            text: '',
        });
        cardsList.push({
            actionPlan: 'Plans d action'
        });
        cardsList.push({
            text: '',
        });
        cardsList.push({
            actionPlanName: 'Nom',
            actionPlanDescr: 'Description',
            actionPlanDue: 'Due au:',
        });
        actionPlans.map(action => {
            cardsList.push({
                actionPlanName: action.name,
                actionPlanDescr: action.description,
                actionPlanDue: action.dueDate,
            });
        });
        return cardsList;
    }

    downloadImage(board: Board): void {
        document.querySelector('.board-content-wrapper');
        html2canvas(document.querySelector('.board-content-wrapper'), {
            windowWidth: 3000,
            windowHeight: 5000,
            scrollX: 0,
            scrollY: -window.scrollY,
        }).then(canvas => {
            this.saveAs(
                canvas.toDataURL('image/jpg'),
                board.name + '_' + this.getDateNow() + '.jpg'
            );
        });
    }

    saveAs(uri, filename): void {
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = uri;
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(uri);
        }
    }

    downloadSVG(cards: any, board: Board): any {
        return {
            brainstorm: new AngularCsv(
                cards,
                board.name.toUpperCase() + '_' + this.getDateNow(),
                {
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalseparator: '.',
                    showLabels: true,
                    showTitle: true,
                    title: board.name,
                    useBom: true,
                    headers: [],
                    nullToEmptyString: true,
                }
            ),
        };
    }

    getRemoteCalls(): Observable<any> {
        return of(this.remoteCalls);
    }

    // Brainstormin WSs

    addNewCard(brainstormingId: string, listId: string, card: Card): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingAddCard}/${brainstormingId}`,
            {
                listId,
                card
            }
        );
    }

    removeCardById(brainstormingId: string, listId: string, cardId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingRemoveCard}/${brainstormingId}`,
            {
                cardId,
                listId
            }
        );
    }

    moveCard(brainstormingId: string, move: { cardId: string; listId: string; dropIndex: number }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingMoveCard}/${brainstormingId}`,
            {
                ...move
            }
        );
    }

    addNewList(brainstormingId: string, list: List): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingAddList}/${brainstormingId}`,
            {
                list
            }
        );
    }

    removeListById(brainstormingId: string, listId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingRemoveList}/${brainstormingId}`,
            {
                listId
            }
        );
    }

    moveList(brainstormingId: string, lists: List[], move: { listId: string; dropIndex: number }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingMoveList}/${brainstormingId}`,
            {
                ...move
            }
        );
    }

    renameList(brainstormingId: string, listId: string, name: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingRenameList}${brainstormingId}`,
            {
                listId,
                name
            }
        );
    }

    renameListSubtitle(brainstormingId: string, listId: string, description: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingRenameListSubtitle}${brainstormingId}`,
            {
                listId,
                description
            }
        );
    }

    removeListSubtitle(brainstormingId: string, listId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingRemoveListSubtitle}${brainstormingId}`,
            {
                listId,
            }
        );
    }

    updateCardDescription(brainstormingId: string, card: { id: string, description: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingCardDescription}/${brainstormingId}`,
            {
                id: card.id,
                description: card.description
            }
        );
    }


    addComment(
        comment: {
            idMember: string;
            message: any;
            time: Date;
        },
        cardId
    ): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.brainstormingAddCardComment}/${cardId}`,
            comment
        );
    }

    addCheckList(brainstormingId: string, cardId: string, checklist: { id: string, name: string, checkItemsChecked: number, checkItems: { id: string, name: string, checked: boolean }[] }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoard}/${brainstormingId}/card/${cardId}${environment.http.brainstormingAddCheckList}`,
            {
                id: checklist.id,
                name: checklist.name,
                checkItemsChecked: checklist.checkItemsChecked,
                checkItems: checklist.checkItems
            }
        );
    }

    addcheckItem(brainstormingId: string, checkItemForm: { cardId: string, checklistId: string, id: string, name: string, checked: boolean }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoard}/${brainstormingId}${environment.http.brainstormingAddcheckItem}`,
            {
                cardId: checkItemForm.cardId,
                checklistId: checkItemForm.checklistId,
                id: checkItemForm.id,
                name: checkItemForm.name,
                checked: checkItemForm.checked
            }
        );
    }

    removeCheckList(brainstormingId: string, checkListForm: { cardId: string, checklistId: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoard}/${brainstormingId}${environment.http.brainstormingRemoveCheckList}`,
            {
                cardId: checkListForm.cardId,
                checklistId: checkListForm.checklistId
            }
        );
    }

    removeCheckItem(brainstormingId: string, checkListForm: { checklistId: string, checkItemId: string, cardId: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoard}/${brainstormingId}${environment.http.brainstormingRemoveCheckItem}`,
            {
                cardId: checkListForm.cardId,
                checklistId: checkListForm.checklistId,
                checkItemId: checkListForm.checkItemId
            }
        );
    }

    updateCheckItem(brainstormingId: string, checkItemForm: { cardId: string, checklistId: string, checkItemId: string, name: string, checked: boolean }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoard}/${brainstormingId}${environment.http.brainstormingCheckItemUpdate}`,
            {
                cardId: checkItemForm.cardId,
                checklistId: checkItemForm.checklistId,
                checkItemId: checkItemForm.checkItemId,
                name: checkItemForm.name,
                checked: checkItemForm.checked
            }
        );
    }

    updateLabels(brainstormingId: string, labels: { id: string, name: string, color: string, }[]): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingLabelsUpdate}/${brainstormingId}`,
            labels
        );
    }

    updateCardName(brainstormingId: string, payload: any): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingUpdateCard}/${brainstormingId}`,
            payload
        );
    }

    updateCardNote(brainstormingId: string, payload: any): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingNoteCard}/${brainstormingId}`,
            payload
        );
    }

    updateCardCategory(brainstormingId: string, payload: any): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingCardColor}/${brainstormingId}`,
            payload
        );
    }

    toggleLabel(brainstormingId: string, toggleForm: { cardId: string, labelId: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoard}/${brainstormingId}${environment.http.brainstormingToggleLabel}`,
            toggleForm
        );
    }

    addLabel(brainstormingId: string, label: { name: string, color: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoard}/${brainstormingId}${environment.http.brainstormingAddLabel}`,
            label
        );
    }

    updateLabel(brainstormingId: string, label: { id: string, name: string, color: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingUpdateLabel}/${brainstormingId}`,
            label
        );
    }

    duplicateCard(brainstormingId: string, duplicate: { cardId: string, listId: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingDuplicateCard}/${brainstormingId}`,
            duplicate
        );
    }

    deleteComment(cardId: string, commentId: string, brainstormingId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingCardComment}/${brainstormingId}`,
            {
                cardId,
                commentId
            }
        );
    }

    removeLabel(brainstormingId: string, labelId: string, label: {}): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingBoard}/${brainstormingId}/${environment.http.brainstormingRemoveLabel}/${labelId}`,
            label
        );
    }

    updateWorkflowStep(brainstormingId: string, workflowStep: number): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingWorkflowStep}/${brainstormingId}`,
            {
                workflowStep
            }
        );
    }

    updateBoardSettings(brainstormingId: string, settings: any): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.brainstormingSettings}/${brainstormingId}`,
            {
                ...settings
            }
        );
    }

}
