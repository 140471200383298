import { Component, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    RootStoreState
} from 'app/root-store';
import { WorkshopActivitiesStoreSelectors } from 'app/root-store/workshop-store/activities-store';
import { Activity } from '@bsuccess/models/activities/activity.model';
import { locale as english } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/cards-to-columns-dialog/tables-step/en';
import { locale as frensh } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/cards-to-columns-dialog/tables-step/fr';
import { locale as arabic } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/cards-to-columns-dialog/tables-step/ar';
import { WorkshopCanvasStoreActions } from 'app/root-store/workshop-store/canvas-store';

@Component({
    selector: 'cards-to-columns-dialog-tables-step',
    templateUrl: './tables-step.component.html',
    styleUrls: ['./tables-step.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CardsToColumnsDialogTablesStepComponent {
    activities: any[];
    activities$: Observable<Activity[]>;
    activity: Activity;
    constructor(
        public matDialogRef: MatDialogRef<
            CardsToColumnsDialogTablesStepComponent
        >,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.activities$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectBrainstormActivities)
        );
    }

    save(): void {
        this._store.dispatch(
            WorkshopCanvasStoreActions.saveCardsToColumns({
                boardId: this.activity.contentId,
                activity: this.activity
            })
        );
    }
}
