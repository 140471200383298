export const locale = {
    lang: 'ar',
    data: {
        PROJECTS_BOARD_NEW_MEMBERS_INVITAION: '.قم بدعوة الأعضاء للتعاون في اللوحة. إذا لم يكن لديهم حساب إكْسِلْوايْ ، فسوف يتلقون دعوة لإنشاء حساب',
        PROJECTS_BOARD_NEW_MEMBERS_MEMBERS: 'الأعضاء',
        PROJECTS_BOARD_NEW_MEMBERS_THE_EMAIL: 'البريد الإلكتروني',
        PROJECTS_BOARD_NEW_MEMBERS_POSITION: 'موضع',
        PROJECTS_BOARD_NEW_MEMBERS_SPONSOR: 'الكفيل', 
        PROJECTS_BOARD_NEW_MEMBERS_MODIFICATION: 'محرّر',
        PROJECTS_BOARD_NEW_MEMBERS_ADMIN: 'مشرف',
        PROJECTS_BOARD_NEW_MEMBERS_ADD_USER_BUTTON_LABEL: 'إضافة مستخدم',
        PROJECTS_BOARD_NEW_MEMBERS_RESPONSIBLE: 'المالِك',
        PROJECTS_BOARD_NEW_MEMBERS_ROLE: 'دَوْر',
        PROJECTS_BOARD_NEW_MEMBERS_READING: 'مشاهد',    
        PROJECTS_BOARD_NEW_MEMBERS_REMOVE: 'إزالة',
        PROJECTS_BOARD_NEW_MEMBERS_SAVE: 'حفظ',
        PROJECTS_BOARD_NEW_MEMBERS_CANCEL: 'إلغاء',
    }
};
