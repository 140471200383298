import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    RootStoreState
} from 'app/root-store';
import { WorkshopBrainstormingStoreSelectors, WorkshopBrainstormingStoreActions } from 'app/root-store/workshop-store/brainstorming-store';
import { locale as english } from '../../../../../../i18n/studio/projects/session/dialogs/task-content-dialog/label-selector/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/session/dialogs/task-content-dialog/label-selector/fr';
import { locale as arabic } from '../../../../../../i18n/studio/projects/session/dialogs/task-content-dialog/label-selector/ar';

@Component({
    selector: 'session-task-label-selector',
    templateUrl: './label-selector.component.html',
    styleUrls: ['./label-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class SessionTaskLabelSelectorComponent implements OnInit, OnDestroy {
    @Input('card')
    card: any;

    @Output()
    cardLabelsChanged: EventEmitter<any>;

    board$: Observable<any>;
    board: any;
    labelsMenuView: string;
    selectedLabel: any;
    newLabel: any;
    toggleInArray: (item: any, array: any) => any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _store: Store<RootStoreState.State>, 
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.board$ = this._store.pipe(
            select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard)
        );
        this.cardLabelsChanged = new EventEmitter();
        this.labelsMenuView = 'labels';
        this.newLabel = {
            id: '',
            name: '',
            color: 'blue-400',
        };
        this.toggleInArray = FuseUtils.toggleInArray;

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.board$.subscribe(board => {
            this.board = board;
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    onCardLabelsChanged(): void {
        this.cardLabelsChanged.next(true);
    }

    onLabelChange(): void {
        // this._scrumboardService.updateBoard();
    }

    addNewLabel(): void {
        this.newLabel.id = FuseUtils.generateGUID();
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.addNewLabel({
                newLabel: Object.assign({}, this.newLabel),
            })
        );
        this.newLabel.name = '';
        this.labelsMenuView = 'labels';
    }

    deleteLabel(label): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.removeLabel({
                label: label,
                labelId: label.id
            })
        );

        this.labelsMenuView = 'labels';
    }
}
