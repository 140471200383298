import { createAction, props } from '@ngrx/store';

import { HomeModel } from '@bsuccess/models/home/home.model';

export const load = createAction('[Studio][Home] Load');

export const loadSuccess = createAction(
    '[Studio][Home] Load Success',
    props<{ home: HomeModel }>()
);

export const loadFailure = createAction(
    '[Studio][Home] Load Failure',
    props<{ error: string }>()
);

export const searchSessionByStatus = createAction(
    '[Studio][Home Page] Search Session By Status',
    props<{ status: string }>()
);
