import { PermissionRole } from './permission.model';

export enum ActionMethod {
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATH',
}

export enum ActionName {

    project_add_child = 'project_add_child',
    project_edit_details = 'project_edit_details',
    project_update_services = 'project_update_services',
    project_update_level = 'project_update_level',
    project_archive_project = 'project_archive_project',
    project_manage_members = 'project_manage_members',
    session_add_session = 'session_add_session',
    session_edit_session = 'session_edit_session',
    session_edit_session_detail = 'session_edit_session_detail',
    session_update_content = 'session_update_content',
    session_manage_participants = 'session_manage_participants',
    session_manage_guests = 'session_manage_guests',
    session_manage_participants_invitation = 'session_manage_participants_invitation',
    session_manage_guests_invitation = 'session_manage_guests_invitation',
    session_manage_members = 'session_manage_members',
    session_archive_session = 'session_archive_session',
    session_send_invitation = 'session_send_invitation',
    board_add_board = 'board_add_board',
    board_edit_board = 'board_edit_board',
    board_edit_info_board = 'board_edit_info_board',
    board_archive_board = 'board_archive_board',
    board_manage_members = 'board_manage_members',
    document_add_document = 'document_add_document',
    document_update_document = 'document_update_document',
    document_archive_document = 'document_archive_document',
    project_add_detail = 'project_add_detail',
    project_update_detail = 'project_update_detail',
    project_delete_detail = 'project_delete_detail'
}

export interface ActionPermissionModel {
    name: ActionName;
    method: ActionMethod;
    path: string;
    roles: PermissionRole[];
}
