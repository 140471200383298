export const locale = {
    lang: 'fr',
    data: {
        PARTICIPANTS_INVITATIONS_TITLE: 'Inviter par email',
        PARTICIPANTS_INVITATIONS_TITLE_PUBLIC: 'Inviter avec un lien',
        PARTICIPANTS_INVITATIONS_SUBTITLE_1: 'Cet atelier a  ',
        PARTICIPANTS_INVITATIONS_SUBTITLE_2: ' participants et ',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3_1:
            "Ajouter des personnes leur enverra un email d'invitation et leur donnera accès à cet atelier.",
        PARTICIPANTS_INVITATIONS_SUBTITLE_3_2: ' animateur(s). ',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3: 'animateur(s).',
        PARTICIPANTS_INVITATIONS_SUBTITLE_4:
            "Ajouter des personnes leur enverra un email d'invitation et leur donnera des permissions d'animateur à cet atelier.",
        PARTICIPANTS_INVITATIONS_INPUT_EMAIL: "Saisir l'email du participant et appuyer sur entrée",
        PARTICIPANTS_INVITATIONS_USE_LINK:
            'Partagez ce lien avec vos participants. Pas de compte Excelway requis.',
        PARTICIPANTS_INVITATIONS_COPY: 'Copier le lien',
        PARTICIPANTS_INVITATIONS_LINK: 'Lien',
        PARTICIPANTS_INVITATIONS_SEND: 'Envoyer',
        PARTICIPANTS_INVITATIONS_PARTICIPANT: 'Participant',
        PARTICIPANTS_INVITATIONS_ANIMATOR: 'Animateur',
        PARTICIPANTS_INVITATIONS_ANIMATORS: 'Animateurs',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS: 'Participants',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIST: 'Participants de cet atelier:',
        PARTICIPANTS_INVITATIONS_PARTICIPANT_SEARCH: 'Rechercher un participant',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_NOT_SHOWEN_1: 'Cet atelier a également ',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_NOT_SHOWEN_2: ' autres participants non représentés dessus.',
        PARTICIPANTS_INVITATIONS_CANCEL: 'Annuler',
        PARTICIPANTS_INVITATIONS_CLOSE: 'Fermer',
        PARTICIPANTS_INVITATIONS_ANIMATOR_SELECTED:
            'Un animateur peut configurer l\'atelier et ses activités.',
        PARTICIPANTS_INVITATIONS_PARTICIPANT_SELECTED:
            'Un participant peut contribuer en envoyant des cartes, votant et commentant.',
        // ----------------------------------------------------
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_DESCRIPTION_1_PART_1: 'Partagez ce lien avec',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_DESCRIPTION_1_PART_2: 'participants. Pas de compte Excelway requis.',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_DESCRIPTION_2_PART_1: 'Vous avez atteint la limite de',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_DESCRIPTION_2_PART_2: ' participants gratuits pour ce workshop.',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_DESCRIPTION_3_PART_1: 'Vous avez atteint la limite de',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_DESCRIPTION_3_PART_2: ' participants gratuits pour ce workshop.',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_DESCRIPTION_4_PART_1: 'Partagez ce lien avec',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_DESCRIPTION_4_PART_2: 'participants. Pas de compte Excelway requis.',

        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_LINK_1: 'Besoin de plus? Contactez votre administrateur de compte pour inviter jusqu\'à 100 participants avec l\'option Grands Ateliers. ',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_LINK_2: 'Besoin de plus? Contactez votre administrateur de compte pour inviter jusqu\'à 100 participants avec l\'option Grands Ateliers. ',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_LINK_3: 'Besoin de plus? Invitez jusqu\'à 100 participants avec l\'option Grands Ateliers.',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIMIT_LINK_4: 'Besoin de plus? Invitez jusqu\'à 100 participants avec l\'option Grands Ateliers.',
    },
};
