import { ActionName } from '@bsuccess/models/action-permission.model';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import {
    StudioProjectStoreActions,
    StudioProjectStoreSelectors,
} from 'app/root-store/studio-store/project-store';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { NewParentModel } from '@bsuccess/models/project/new-parent.model';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/project-update-general-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/project-update-general-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/project-update-general-dialog/ar';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { checkDate, checkDateAndTime } from '../session-new-dialog/session-new-general-tab/checkDate';

@Component({
    selector: 'project-update-general-dialog',
    templateUrl: './project-update-general-dialog.component.html',
    styleUrls: ['./project-update-general-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe],
})
export class ProjectUpdateGeneralDialogComponent implements OnInit, OnDestroy {
    imagesUrl = '';
    project: NewParentModel;
    currentProject$: Observable<ProjectModel>;
    logo$: Observable<string>;
    composeForm: UntypedFormGroup;
    logo: string;
    ActionName = ActionName;
    private _unsubscribeAll: Subject<any>;


    constructor(
        public dialogRef: MatDialogRef<ProjectUpdateGeneralDialogComponent>,
        private _store: Store<RootStoreState.State>,
        private _datePipe: DatePipe,
        private dateAdapter: DateAdapter<Date>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this._unsubscribeAll = new Subject();
        this.translateService.currentLang.toString() === 'fr' ?
            this.dateAdapter.setLocale('fr') :
            this.translateService.currentLang.toString() === 'en' ?
                this.dateAdapter.setLocale('en') :
                this.dateAdapter.setLocale('ar-MA');
        this.dialogRef.disableClose = true;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.imagesUrl = environment.minio.url + '/storage/o/public/';
        this.currentProject$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),
            select(StudioProjectStoreSelectors.selectCurrent)
        );
        this.logo$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),
            select(StudioProjectStoreSelectors.selectProjectLogo)
        );

        this.logo$.subscribe(logo => {
            this.logo = logo;
        });
        this._store
            .pipe(
                takeUntil(this._unsubscribeAll),
                select(StudioProjectStoreSelectors.selectCurrent))
            .subscribe((currentProject: ProjectModel) => {
                this.project = {
                    name: currentProject.name,
                    description: currentProject.description,
                    confidentiality: currentProject.confidentiality,
                    startDate: currentProject.startDate,
                    endDate: currentProject.endDate,
                };
            });

        this.composeForm = this.createComposeForm();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    createComposeForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            name: new UntypedFormControl(this.project.name),
            description: new UntypedFormControl(this.project.description),
            startDate: new UntypedFormControl(this.project.startDate , Validators.compose([Validators.required , checkDate, checkDateAndTime])),
            endDate: new UntypedFormControl(this.project.endDate , Validators.compose([Validators.required , checkDate, checkDateAndTime])),
        });
    }

    fileChange(event: any): void {
        const file = event.target.files[0];
        if (file.size > 1048576) {
            alert('Image is too big!');
        } else {
            const eventObj = event;
            const target: HTMLInputElement = eventObj.target as HTMLInputElement;
            const files: FileList = target.files;

            const formData: FormData = new FormData();

            formData.append('file', files[0], files[0].name);

            this._store.dispatch(
                StudioProjectStoreActions.uploadImage({
                    content: formData,
                    name: files[0].name
                })
            );
        }
    }

    save(): void {
        this._store.dispatch(
            StudioProjectStoreActions.updateProjectGeneral({
                project: {
                    name: this.composeForm.value.name,
                    description: this.composeForm.value.description,
                    confidentiality: this.project.confidentiality,
                    startDate: this._datePipe.transform(
                        this.composeForm.value.startDate,
                        'yyyy-MM-dd'
                    ),
                    endDate: this._datePipe.transform(
                        this.composeForm.value.endDate,
                        'yyyy-MM-dd'
                    ),
                    logo: this.logo,
                },
            })
        );
    }

    archiveProject(): void {
        this._store.dispatch(StudioProjectStoreActions.archiveProject());
    }
}
