import { Component, ViewEncapsulation, OnDestroy, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { RootStoreState } from 'app/root-store';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { StudioBoardStoreSelectors, StudioBoardStoreActions } from 'app/root-store/studio-store/board-store';
import { locale as english } from 'app/i18n/studio/projects/board/dialogs/card/attachment-display/en';
import { locale as frensh } from 'app/i18n/studio/projects/board/dialogs/card/attachment-display/fr';
import { locale as arabic } from 'app/i18n/studio/projects/board/dialogs/card/attachment-display/ar';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'project-board-attachment-display-dialog',
    templateUrl: 'attachment-display.component.html',
    styleUrls: ['attachment-display.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectBoardAttachmentDisplayComponent implements OnInit, OnDestroy {
    selectCurrentImage$: Observable<any>;
    private _unsubscribeAll: Subject<any>;
    constructor(
        public matDialogRef: MatDialogRef<
            ProjectBoardAttachmentDisplayComponent
        >,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this._unsubscribeAll = new Subject();
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {

        this._store.dispatch(
            StudioBoardStoreActions.loadImage({
                attachment: this._data
            })
        );
        this.selectCurrentImage$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),
            select(StudioBoardStoreSelectors.selectCurrentImage)
        );
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

}
