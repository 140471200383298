export const locale = {
    lang: 'en',
    data: {
        EXPORT_ACTIONS: 'Export tasks to another project',
        APPROVED_ACTIONS: 'Tasks must be approved by project administrators to be exported to the selected board.',
        EXPORT_ACTIONS_BOARD: 'Export action to another board',
        APPROVED_ACTIONS_BOARD: 'Actions must be approved by board administrators to be exported to the selected board.',
        ADD_COMMENT: 'Add a comment to support this request',
        COMMENT: 'Comment',
        EXPORT: 'Export',
        CANCEL: 'Cancel',
        ADD_ACTIONS: 'Add tasks to another project',
        ADDED_ACTIONS: 'Tasks must be approved by project administrators to be added to the selected board.',
        ADD_ACTIONS_BOARD: 'Add action to another board',
        ADDED_ACTIONS_BOARD: 'Actions must be approved by board administrators to be added to the selected board.',
        ADD: 'Add',
    }
};
