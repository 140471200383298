import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { exhaustMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

import { RootStoreState } from '../..';
import * as DocumentActions from './actions';
import { AttachmentService } from '@bsuccess/services/attachment.service';
import { ProjectService } from '@bsuccess/services/project.service';
import { DocumentNewDialogComponent } from 'app/studio/projects/project/dialogs/document-new-dialog/document-new-dialog.component';
import { StudioProjectStoreSelectors } from '../project-store';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { StudioDocumentStoreSelectors } from '.';
import { DocumentUpdateDialogComponent } from 'app/studio/projects/project/dialogs/document-update-dialog/document-update-dialog.component';
import { BsAttachmentDialogComponent } from '@bsuccess/components/attachment-dialog/attachment-dialog.component';
import { locale as english } from '../../../i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../i18n/@fuse/components/confirm-dialog/fr';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import * as moment from 'moment';



@Injectable()
export class StudioDocumentStoreEffects {
    confirmDelete: string;
    constructor(
        private _store: Store<RootStoreState.State>,
        private _attachmentService: AttachmentService,
        private _actions$: Actions,
        private _projectService: ProjectService,
        private _matDialog: MatDialog,
        private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh);

        this.translate.stream('CONFIRM_DELETE_BOARD').subscribe(
            value => { this.confirmDelete = value; });
    }

    openAttachment$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(DocumentActions.readDocument),
                map(_ => {
                    if (
                        _.document.contentType !== 'application/xls' &&
                        _.document.contentType !== 'application/doc'
                    ) {
                        this._matDialog.open(BsAttachmentDialogComponent, {
                            panelClass: 'attachment-dialog',
                            data: {
                                attachment: _.document,
                            },
                        });
                    } else {
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    addDocument$ = createEffect(() =>
        this._actions$.pipe(
            ofType(DocumentActions.addDocument),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    DocumentNewDialogComponent,
                    {
                        panelClass: 'document-new-dialog',
                    }
                );

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return DocumentActions.addDocumentConfirmed({
                                document: confirmed,
                            });
                        } else {
                            return DocumentActions.addDocumentCancelled();
                        }
                    })
                );
            })
        )
    );

    addDocumentConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(DocumentActions.addDocumentConfirmed),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, project]) => {
                return this._projectService
                    .addDocument(project._id, action.document)
                    .pipe(
                        map(response =>
                            DocumentActions.addDocumentSuccess({
                                document: {
                                    ...action.document,
                                    _id: response._id,
                                },
                            })
                        ),
                        catchError(error =>
                            of(
                                DocumentActions.addDocumentFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    uploadDocument$ = createEffect(() =>
        this._actions$.pipe(
            ofType(DocumentActions.uploadDocument),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
            ),
            exhaustMap(([action, user]) => {
                return this._attachmentService.minioUpload(action.content, user._id + '/' + action.name).pipe(
                    map(response =>
                        DocumentActions.uploadDocumentSuccess({
                            response: {
                                _id: response.Metadata.token,
                                filename: user._id + '/' + action.name,
                                contentType: response.ContentType,
                                uploadDate: moment(response.LastModified).unix(),
                                size: response.ContentLength,
                            },
                            name: user._id + '/' + action.name
                        })
                    ),
                    catchError(error =>
                        of(
                            DocumentActions.uploadDocumentFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    deleteAttachment$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    DocumentActions.uploadDocument,
                    DocumentActions.addDocumentCancelled
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(StudioDocumentStoreSelectors.selectLastUpload)
                    )
                ),
                map(([action, lastUpload]) => {
                    if (lastUpload) {
                        this._store.dispatch(
                            DocumentActions.deleteAttachment({
                                id: lastUpload,
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    updateDocument$ = createEffect(() =>
        this._actions$.pipe(
            ofType(DocumentActions.updateDocument),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    DocumentUpdateDialogComponent,
                    {
                        panelClass: 'document-update-dialog',
                        data: action.document,
                    }
                );

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return DocumentActions.updateDocumentConfirmed({
                                document: confirmed,
                            });
                        } else {
                            return DocumentActions.updateDocumentCancelled();
                        }
                    })
                );
            })
        )
    );

    updateDocumentConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(DocumentActions.updateDocumentConfirmed),
            withLatestFrom(this._store.select(StudioProjectStoreSelectors.selectCurrent)),
            exhaustMap(([action, project]) => {
                return this._projectService
                    .updateDocument(action.document._id, {
                        name: action.document.name,
                        attachmentId: action.document.attachmentId,
                        documentType: action.document.documentType,
                        filename: action.document.filename
                    }, project._id)
                    .pipe(
                        map(response =>
                            DocumentActions.updateDocumentSuccess({
                                document: action.document,
                            })
                        ),
                        catchError(error =>
                            of(
                                DocumentActions.updateDocumentFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    deleteDocument$ = createEffect(() =>
        this._actions$.pipe(
            ofType(DocumentActions.deleteDocument),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        panelClass: 'fuse-confirm-dialog',
                        disableClose: false,
                    }
                );

                matDialogRef.componentInstance.confirmMessage = this.confirmDelete;

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return DocumentActions.deleteDocumentConfirmed({
                                id: action.id,
                                attachmentId: action.attachmentId,
                            });
                        } else {
                            return DocumentActions.deleteDocumentCancelled();
                        }
                    })
                );
            })
        )
    );

    deleteDocumentConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(DocumentActions.deleteDocumentConfirmed),

            withLatestFrom(this._store.select(StudioProjectStoreSelectors.selectCurrent)),
            exhaustMap(([action, project]) => {
                return this._projectService.deleteDocument(action.id, project._id).pipe(
                    map(response =>
                        DocumentActions.deleteDocumentSuccess({
                            id: response._id,
                            attachmentId: action.attachmentId,
                        })
                    ),
                    catchError(error =>
                        of(
                            DocumentActions.deleteDocumentFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    deleteDocumentSuccess$ = createEffect(() =>
        this._actions$.pipe(
            ofType(DocumentActions.deleteDocumentSuccess),
            exhaustMap(action => {
                return this._attachmentService.delete(action.attachmentId).pipe(
                    map(response =>
                        DocumentActions.deleteAttachmentSuccess({
                            response,
                        })
                    ),
                    catchError(error =>
                        of(
                            DocumentActions.deleteAttachmentFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    downloadDocument$ = createEffect(() =>
        this._actions$.pipe(
            ofType(DocumentActions.downloadDocument),
            exhaustMap(action => {
                return this._projectService
                    .downloadDocument(action.document)
                    .pipe(
                        map(response =>
                            DocumentActions.downloadDocumentSuccess({
                                response,
                            })
                        ),
                        catchError(error =>
                            of(
                                DocumentActions.downloadDocumentFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );
}
