import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    exhaustMap,
    map,
    catchError,
    withLatestFrom,
    tap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { saveAs as importedSaveAs } from 'file-saver-es';
import { format } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import * as NotesActions from './actions';
import { NotesService } from '@bsuccess/services/notes.service';
import { NoteNewDialogComponent } from 'app/workshop/notes/dialogs/note-new-dialog/note-new-dialog.component';
import { SessionService } from '@bsuccess/services/session.service';
import { LoginStoreSelectors } from '../../login-store';
import { RootStoreState } from '../..';
import { NoteUpdateDialogComponent } from 'app/workshop/notes/dialogs/note-update-dialog/note-update-dialog.component';
import { ReportService } from '@bsuccess/services/report.service';
import { locale as english } from '../../../i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../i18n/@fuse/components/confirm-dialog/fr';



@Injectable()
export class WorkshopNotesStoreEffects {
    confirmDeleteNote: string;
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _notesService: NotesService,
        private _matDialog: MatDialog,
        private _sessionService: SessionService,
        private _reportService: ReportService,
        private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,


    ) {
        this.translationLoaderService.loadTranslations(english, frensh);

        this.translate.stream('CONFIRM_DELETE_NOTE').subscribe(
            value => { this.confirmDeleteNote = value; });
    }

    addNote$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NotesActions.addNote),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(NoteNewDialogComponent, {
                    panelClass: 'note-new-dialog',
                });
                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return NotesActions.addNoteConfirmed({
                                note: confirmed,
                            });
                        } else {
                            return NotesActions.addNoteCanceled();
                        }
                    })
                );
            })
        )
    );

    addNoteConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NotesActions.addNoteConfirmed),
            withLatestFrom(this._store.select(LoginStoreSelectors.selectLoggedUser)),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([[action, user], sessionKey]) => {
                const time = new Date().toString();
                return this._notesService
                    .addNote(sessionKey, {
                        ...action.note,
                        time,
                        userId: user._id,
                    })
                    .pipe(
                        map((response: any) => {
                            return NotesActions.addNoteSuccess({
                                note: {
                                    ...action.note,
                                    time,
                                    userId: user._id,
                                    _id: response._id,
                                    authorName: response.authorName
                                },
                            })
                        }),
                        catchError(error =>
                            of(
                                NotesActions.addNoteFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    loadNotes$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NotesActions.loadNotes),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginSessionKey))
            ),
            exhaustMap(([_, sessionKey]) => {
                return this._sessionService.getSessionNotes(sessionKey).pipe(
                    map(response => {
                        return NotesActions.loadNotesSuccess({
                            notes: response.notes,
                        });
                    }),
                    catchError(() =>
                        of(
                            NotesActions.loadNotesFailure({
                                error: this.translate.currentLang.toString() === 'fr' ?
                                    'Erreur chargement de notes.' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'Error loading notes.' : '.خطأ في تحميل الملاحظات',
                            })
                        )
                    )
                );
            })
        )
    );

    updateDetail$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NotesActions.updateNote),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(NoteUpdateDialogComponent, {
                    panelClass: 'note-update-dialog',
                    data: action.note,
                });
                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return NotesActions.updateNoteConfirmed({
                                note: confirmed,
                            });
                        } else {
                            return NotesActions.updateNoteCanceled();
                        }
                    })
                );
            })
        )
    );

    updateNoteConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NotesActions.updateNoteConfirmed),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginSessionKey))
            ),
            exhaustMap(([_, sessionkey]) =>
                this._notesService.updateNote(_.note, sessionkey).pipe(
                    map((response: any) =>
                        NotesActions.updateNoteSuccess({
                            note: {
                                ..._.note,
                                time: new Date().toString(),
                            },
                        })
                    ),
                    catchError(error =>
                        of(
                            NotesActions.updateNoteFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteNote$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NotesActions.deleteNote),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                    panelClass: 'fuse-confirm-dialog',
                    disableClose: false,
                });

                matDialogRef.componentInstance.confirmMessage = this.confirmDeleteNote;

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return NotesActions.deleteNoteConfirmed({
                                id: action.id,
                            });
                        } else {
                            return NotesActions.deleteNoteCanceled();
                        }
                    })
                );
            })
        )
    );

    deleteNoteConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NotesActions.deleteNoteConfirmed),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginSessionKey))
            ),
            exhaustMap(([_, sessionKey]) => {
                return this._notesService.removeNote(_.id, sessionKey).pipe(
                    map((response: any) =>
                        NotesActions.deleteNoteSuccess({
                            id: _.id,
                        })
                    ),
                    catchError(error =>
                        of(
                            NotesActions.deleteNoteFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    downloadReport$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NotesActions.downloadReport),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginSessionKey))
            ),
            exhaustMap(([_, sessionKey]) => {
                return this._reportService.getNotesReport(sessionKey).pipe(
                    map(response => {
                        return NotesActions.downloadReportSuccess({
                            report: response,
                        });
                    }),
                    catchError(error =>
                        of(
                            NotesActions.downloadReportFailure({
                                error,
                            })
                        )
                    )
                );
            })
        )
    );

    downloadReportSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(NotesActions.downloadReportSuccess),
                tap(_ => {
                    importedSaveAs(_.report, 'RAPPORT_' + format(new Date(), 'MMddyyyy_HH:mm:ss'));
                })
            ),
        { dispatch: false }
    );
}
