import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as ProfileActions from './actions';
import { LoginStoreActions } from '../login-store';
import { AccessStoreActions } from '../access-store';

const accessReducer = createReducer(
    initialState,
    on(LoginStoreActions.whoamiSuccess, (state, { user }) => ({
        ...state,
        user,
    })),
    on(ProfileActions.updateProfileGeneralSuccess, (state, { user }) => ({
        ...state,
        user: {
            ...state.user,
            firstName: user.firstName,
            lastName: user.lastName,
            about: user.timeZone
        }
    })),
    on(ProfileActions.updateUserTimeZoneSuccess, (state, { timeZone }) => ({
        ...state,
        user: {
            ...state.user,
            info:{
                ...state.user.info,
                timeZone
            }
        }
    })),
    on(ProfileActions.updateProfileOccupationSuccess, (state, { user }) => ({
        ...state,
        user: {
            ...state.user,
            occupation: user.occupation,
            team: user.team,
            enterprise: user.enterprise,
        }
    })),
    on(ProfileActions.updateProfileContactSuccess, (state, { user }) => ({
        ...state,
        user: {
            ...state.user,
            address: user.address,
            phone: user.phone,
            website: user.website,
        }
    })),
    on(
        ProfileActions.updateUserPasswordFailure, (state, { error }) => ({
            ...state,
            error,
        })),
        on(
            ProfileActions.updateUserPasswordSuccess, (state, { success }) => ({
                ...state,
                success: success,
            })),
    on(ProfileActions.addProfilePictureSuccess, (state, { profilePicture }) => ({
        ...state,
        user: {
            ...state.user,
            info: {
                ...state.user.info,
                profilePic: profilePicture
            }
        }
    })),
    on(AccessStoreActions.cancelSubscriptionSuccess, (state, { response }) => ({
        ...state,
        user: {
            ...state.user,
            subscription: response
        }
    })),
    on(AccessStoreActions.subscribeSuccess, (state, { response }) => ({
        ...state,
        user: {
            ...state.user,
            subscription: response
        }
    })),
);

export function reducer(state: State | undefined, action: Action): any {
    return accessReducer(state, action);
}
