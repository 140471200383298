import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AttachmentService {
    constructor(private _httpClient: HttpClient) { }

    get(id: string): Observable<any> {
        return this._httpClient.get(
            `${environment.minio.url}/storage/o/public/` + id,
            { responseType: 'blob' }
        );
    }

    delete(id: string): Observable<any> {
        return this._httpClient.delete(
            `${environment.http.url}${environment.http.attachment}/${id}`
        );
    }

    getFileStream(id: string): Observable<any> {
        return this._httpClient.get(
            `${environment.minio.url}/storage/o/public/` + id,
            { responseType: 'blob' }
        );
    }

    upload(file: any): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.attachment}`,
            file
        );
    }

    minioUpload(file: any, name): Observable<any> {
        return this._httpClient.post(
            `${environment.minio.url}/storage/o/public/` + name,
            file
        );
    }

    createFromBlob(blob: Blob): Observable<any> {
        return new Observable((sub: Subscriber<any>): void => {
            const reader = new FileReader();

            reader.onloadend = (ev: ProgressEvent) => {
                sub.next(reader.result);
                sub.complete();
            };

            reader.onerror = (ev): void => {
                sub.error(ev);
            };

            if (blob) {
                reader.readAsDataURL(blob);
            }
        });
    }
}
