import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { DetailModel } from '@bsuccess/models/project/detail.model';
import { DocumentModel } from '@bsuccess/models/project/document.model';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';
import { LevelModel } from '@bsuccess/models/project/level.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';
import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';

export const selectProjectState = createFeatureSelector<State>('studio-project');

export const selectCurrent = createSelector(
    selectProjectState,
    (state: State): ProjectModel => state.current
);

export const selectProjectDetails = createSelector(
    selectProjectState,
    (state: State): DetailModel[] => state.current.details
);

export const selectProjectDescription = createSelector(
    selectProjectState,
    (state: State): string => state.current.description
);

export const selectProjectDocuments = createSelector(
    selectProjectState,
    (state: State): DocumentModel[] => state.current.documents
);

export const selectProjectSessions = createSelector(
    selectProjectState,
    (state: State): ProjectSessionModel[] => state.current.sessions
);

export const selectProjectBoards = createSelector(
    selectProjectState,
    (state: State): ProjectBoardModel[] => state.current.boards
);

export const selectProjectFilteredBoards = createSelector(
    selectProjectState,
    (state: State): ProjectBoardModel[] => state.filteredBoards
);

export const selectFilteredSubProjects = createSelector(
    selectProjectState,
    (state: State): SubProjectModel[] => state.filteredSubProjects
);

export const selectFilteredSessions = createSelector(
    selectProjectState,
    (state: State): ProjectSessionModel[] => state.filteredSessions
);

export const selectProjectFilteredDocuments = createSelector(
    selectProjectState,
    (state: State): DocumentModel[] => state.filteredDocuments
);

export const selectSubProjectFavorites = createSelector(
    selectProjectState,
    (state: State): SubProjectModel[] => state.current.projects
);

export const selectSubProjectOthers = createSelector(
    selectProjectState,
    (state: State): SubProjectModel[] => state.current.projects
);

export const selectAllSubProjects = createSelector(
    selectProjectState,
    (state: State): SubProjectModel[] => [...state.current.projects]
);

export const selectProjectLevels = createSelector(
    selectProjectState,
    (state: State): LevelModel[] => state.current.levels
);

export const selectProjectUsers = createSelector(
    selectProjectState,
    (state: State): ProjectUserModel[] => state.current.users
);

export const selectProjectMembers = createSelector(
    selectProjectState,
    (state: State): ProjectUserModel[] =>
        state.current.users.filter(user => user.position === 'member')
);

export const selectProjectSponsor = createSelector(
    selectProjectState,
    (state: State): ProjectUserModel =>
        state.current.users.find(user => user.position === 'sponsor')
);

export const selectProjectId = createSelector(
    selectProjectState,
    (state: State): string => state.current._id
);

export const selectProjectLogo = createSelector(
    selectProjectState,
    (state: State): string => state.current.logo
);

export const selectLevelProjectFound = createSelector(
    selectProjectState,
    (state: State): { id: string; exists: boolean } => state.level
);

export const selectError = createSelector(
    selectProjectState,
    (state: State): string => state.error
);

export const selectPendingReportToken = createSelector(
    selectProjectState,
    (state: State): string => state.pendingReportToken
);

export const selectLastCreatedSubProjectTree = createSelector(
    selectProjectState,
    (state: State): any => state.lastCreatedSubProjectTree
);

export const selectFiltredTemplates = createSelector(
    selectProjectState,
    (state: State): any[] => state.filteredCanvas
);

export const selectSelectedCategory = createSelector(
    selectProjectState,
    (state: State): any => state.selectedCategory
);