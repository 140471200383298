import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    RootStoreState
} from 'app/root-store';
import { WorkshopBrainstormingStoreActions, WorkshopBrainstormingStoreSelectors } from 'app/root-store/workshop-store/brainstorming-store';
import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';
import { locale as english } from '../../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/tables-step/en';
import { locale as frensh } from '../../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/tables-step/fr';
import { locale as arabic } from '../../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/tables-step/ar';

@Component({
    selector: 'import-cards-dialog-tables-step',
    templateUrl: './tables-step.component.html',
    styleUrls: ['./tables-step.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ImportCardsDialogTablesStepComponent implements OnDestroy {
    listId: any;
    selectedBoard: any;
    boards$: Observable<ProjectBoardModel[]>;

    private _unsubscribeAll: Subject<any>;
    constructor(
        public matDialogRef: MatDialogRef<ImportCardsDialogTablesStepComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this._store.dispatch(
            WorkshopBrainstormingStoreActions.loadBoards()
        );
        this.boards$ = this._store.pipe(
            select(WorkshopBrainstormingStoreSelectors.selectBrainstormingBoards)
        );
        
        this._unsubscribeAll = new Subject();
    }

    save(): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.loadImportBoard({
                boardId: this.selectedBoard.id
            })
        );
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }
}
