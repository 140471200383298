import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { exhaustMap, map, catchError, withLatestFrom, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { saveAs as importedSaveAs } from 'file-saver-es';
import { format } from 'date-fns';
import * as mixpanel from 'mixpanel-browser';

import { RootStoreState } from '../..';
import { WorkshopCardboardStoreActions, WorkshopCardboardStoreSelectors } from '.';
import { BsAttachmentDialogComponent } from '@bsuccess/components/attachment-dialog/attachment-dialog.component';
import { CardBoardService } from '@bsuccess/services/cardboard.service';
import { LoginStoreSelectors } from '../../login-store';
import { SessionItemModel } from '@bsuccess/models/session/session-item.model';
import { NotificationService } from '@bsuccess/services/notification.service';
import { CardComponent } from '@bsuccess/components/card/card.component';
import { ActionPlanComponent } from '@bsuccess/components/action-plan/action-plan.component';
import { ActionPlanService } from '@bsuccess/services/actions-plan.service';
import { ReportService } from '@bsuccess/services/report.service';
import { CardboardStateModel } from '@bsuccess/models/socket-io/cardboard-state.model';
import { WorkshopActivityStoreSelectors } from '../activity-store';

@Injectable()
export class WorkshopCardboardStoreEffects {
    constructor(
        private _actions$: Actions,
        private _store: Store<RootStoreState.State>,
        private _matDialog: MatDialog,
        private _cardboardService: CardBoardService,
        private _notificationService: NotificationService,
        private _actionPlanService: ActionPlanService,
        private _reportService: ReportService,
        private translate: TranslateService,
    ) { }

    openAttachment$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardboardStoreActions.openAttachment),
                map(_ => {
                    if (
                        _.attachment.contentType !== 'application/xls' &&
                        _.attachment.contentType !== 'application/doc'
                    ) {
                        this._matDialog.open(BsAttachmentDialogComponent, {
                            panelClass: 'attachment-dialog',
                            data: {
                                attachment: _.attachment,
                            },
                        });
                    } else {
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    loadCardboard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardboardStoreActions.loadCardboard),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopActivityStoreSelectors.selectCurrentActivity)
                )
            ),
            exhaustMap(([[_, sessionKey], activity]) => {
                return this._cardboardService
                    .getSessionResults(sessionKey, activity._id)
                    .pipe(
                        map(response =>
                            WorkshopCardboardStoreActions.loadCardboardSuccess({
                                dimensions: response,
                            })
                        ),
                        catchError(error => {
                            return of(
                                WorkshopCardboardStoreActions.loadCardboardFailure({
                                    error: error,
                                })
                            );
                        })
                    );
            })
        )
    );

    loadCardboardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardboardStoreActions.loadCardboardSuccess),
                map(action => {
                    const newState: CardboardStateModel = this._cardboardService.getItemWithoutRates(
                        action.dimensions
                    );
                    if (newState) {
                        this._store.dispatch(
                            WorkshopCardboardStoreActions.updateState({
                                newState,
                            })
                        );
                    }
                })
            ),
        {
            dispatch: false,
        }
    );
    addActionplan$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardboardStoreActions.addActionPlan),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(ActionPlanComponent, {
                    panelClass: 'action-plan',
                    data: {},
                });

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return WorkshopCardboardStoreActions.addActionPlanConfirmed(
                                {
                                    actionPlan: confirmed,
                                }
                            );
                        } else {
                            return WorkshopCardboardStoreActions.addActionPlanCancelled();
                        }
                    })
                );
            })
        )
    );

    addActionplanConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardboardStoreActions.addActionPlanConfirmed),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            exhaustMap(([action, [role, key]]) => {
                return this._actionPlanService.add(action.actionPlan, key).pipe(
                    map(response => {
                        this._notificationService.showSuccess(
                            this.translate.currentLang.toString() === 'fr' ?
                                'L\'action a été créé avec succès' :
                                this.translate.currentLang.toString() === 'en' ?
                                'The task was successfully created.' : 
                                'تم إنشاء خطة العمل بنجاح'
                        );
                        return WorkshopCardboardStoreActions.addActionPlanSuccess({
                            actionPlan: {
                                _id: response._id,
                                ...action.actionPlan
                            }
                        });
                    }),
                    catchError(error =>
                        of(
                            WorkshopCardboardStoreActions.addActionPlanFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    sendComment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardboardStoreActions.receivedComment),
            map(payload =>
                WorkshopCardboardStoreActions.addComment({
                    comment: {
                        firstName: payload.user.firstName,
                        lastName: payload.user.lastName,
                        email: payload.user.email,
                        value: payload.comment,
                    },
                })
            )
        )
    );

    loadCardboardChart$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardboardStoreActions.loadCardboardChart),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopActivityStoreSelectors.selectCurrentActivity)
                )
            ),
            exhaustMap(([[action, [role, token]], activity]) => {
                return this._cardboardService
                    .getSessionResultsChart(token, activity._id)
                    .pipe(
                        map(response =>
                            WorkshopCardboardStoreActions.loadCardboardChartSuccess({
                                results: response,
                            })
                        ),
                        catchError(error =>
                            of(
                                WorkshopCardboardStoreActions.loadCardboardChartFailure(
                                    {
                                        error: error,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    receivedRate$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardboardStoreActions.receivedRate),
            withLatestFrom(
                this._store.pipe(select(WorkshopCardboardStoreSelectors.selectRates))
            ),
            map(([_, rates]) =>
                WorkshopCardboardStoreActions.updateRate({
                    rates: this._cardboardService.addRate(
                        {
                            email: _.user.email,
                            firstName: _.user.firstName,
                            lastName: _.user.lastName,
                            value: _.rate,
                        },
                        rates
                    ),
                })
            )
        )
    );

    saveSessionItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                WorkshopCardboardStoreActions.addComment,
                WorkshopCardboardStoreActions.updateRate
            ),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(select(WorkshopCardboardStoreSelectors.selectComments))
            ),
            withLatestFrom(
                this._store.pipe(select(WorkshopCardboardStoreSelectors.selectAvg))
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopCardboardStoreSelectors.selectCurrentItemId)
                )
            ),
            withLatestFrom(
                this._store.pipe(select(WorkshopCardboardStoreSelectors.selectRates))
            ),
            withLatestFrom(
                this._store.pipe(select(WorkshopActivityStoreSelectors.selectCurrentActivity))
            ),
            map(
                ([
                    [[[[[action, [role, token]], comments], avg], itemId],
                        rates,
                    ], activity]) => {
                    const sessionItem: SessionItemModel = {
                        itemId,
                        round: {
                            comments,
                            isFinal: true,
                            rates,
                            value: avg,
                        },
                        token,
                        activityId: activity._id
                    };
                    return WorkshopCardboardStoreActions.saveSessionItem({
                        sessionItem,
                    });
                }
            )
        )
    );

    updateSessionItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardboardStoreActions.saveSessionItem),
            exhaustMap(_ => {
                return this._cardboardService
                    .saveSessionItem(_.sessionItem)
                    .pipe(
                        map(response =>
                            WorkshopCardboardStoreActions.saveSessionItemSuccess()
                        ),
                        catchError(error =>
                            of(
                                WorkshopCardboardStoreActions.saveSessionItemFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    selectArticle$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardboardStoreActions.selectArticle),
            map(_ =>
                WorkshopCardboardStoreActions.sendRate({
                    value: _.rank,
                })
            )
        )
    );

    openCard$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardboardStoreActions.openCard),
                map(_ => {
                    this._matDialog.open(CardComponent, {
                        panelClass: 'app-card',
                        data: _.description,
                    });
                })
            ),
        {
            dispatch: false,
        }
    );


    downloadReport$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardboardStoreActions.downloadReport),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginSessionKey))
            ),
            withLatestFrom(
                this._store.pipe(select(WorkshopActivityStoreSelectors.selectCurrentActivity))
            ),
            exhaustMap(([[_, sessionKey], activity]) => {
                return this._reportService.getReport(sessionKey, activity._id).pipe(
                    map(response => {
                        return WorkshopCardboardStoreActions.downloadReportSuccess({
                            report: response,
                        });
                    }),
                    catchError(error =>
                        of(
                            WorkshopCardboardStoreActions.downloadReportFailure({
                                error,
                            })
                        )
                    )
                );
            })
        )
    );

    downloadReportSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardboardStoreActions.downloadReportSuccess),
                tap(_ => {
                    importedSaveAs(_.report, 'RAPPORT_' + format(new Date(), 'MMddyyyy_HH:mm:ss'));
                })
            ),
        { dispatch: false }
    );

    addActionPlanSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCardboardStoreActions.addActionPlanSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    mixpanel.track(this.translate.currentLang.toString() === 'fr' ?
                        'Action' : 
                        this.translate.currentLang.toString() === 'en' ?
                        'Task' : 'خطة العمل'
                        , { ..._.actionPlan, tenant });
                })
            ),
        {
            dispatch: false,
        }
    );
}
