import { SessionTaskModel } from './../../../@bsuccess/models/session/session-task.model';
import { UserModel } from '@bsuccess/models/user.model';
import { ActionPermissionModel } from '@bsuccess/models/action-permission.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';

export interface State {
    user: UserModel;
    token: string;
    tenant: string;
    sessionRole: string;
    sessionKey: string;
    sessionName: string;
    sessionId: string;
    isUserReporter: boolean;
    pending?: boolean;
    error?: string;
    form: string;
    actionPermissions: ActionPermissionModel[];
    users?: ProjectUserModel[];
    guests?: { email: string, invited?: boolean }[];
    participants?:
    | {
        _id: string;
        invited: boolean;
    }[]
    | ProjectUserModel[];
    filtredParticipants?:
    | {
        _id: string;
        invited: boolean;
    }[]
    | ProjectUserModel[];
    isSessionPrivate?: boolean;
    categories?: SessionCategoryModel[];
    tasks?: SessionTaskModel[];
    inAppNotifications: any[];
    participantsLimit?: number;
    ownerId?: string;

}

export const initialState: State = {
    user: null,
    token: null,
    tenant: 'beta',
    sessionRole: null,
    sessionKey: null,
    sessionName: null,
    sessionId: null,
    isUserReporter: false,
    pending: false,
    error: null,
    form: 'login-form',
    actionPermissions: [],
    users: [],
    guests: [],
    participants: [],
    categories: [],
    tasks: [],
    inAppNotifications: []
};
