export const locale = {
    lang: 'fr',
    data: {
        CHANGE_WORKSPACE_MAIN: 'Changer l\'espace de travail',
        CLOSE: 'Fermer',
        WORKSPACE: 'Espace de Travail',
        WORKSPACE_REQUIRED: 'L\'espace de travail est nécessaire.',
        CHANGE: 'Changer',
    }
};
