export const locale = {
    lang: 'en',
    data: {
        BOARD_CREATE_WIDGET_TITLE: 'Create a widget',
        BOARD_CREATE_WIDGET_INFORMATIONS: 'Widget information',
        BOARD_CREATE_WIDGET_TEXT_TITLE: 'Widget title',
        BOARD_CREATE_WIDGET_TYPE: 'Widget type',
        BOARD_CREATE_WIDGET_NUMBER: 'Number',
        BOARD_CREATE_WIDGET_BAR_CHART: 'Bar chart',
        BOARD_CREATE_WIDGET_DONUT_CHART: 'Donut chart',
        BOARD_CREATE_WIDGET_BAR_CHART_TITLE: 'Abscissa',
        BOARD_CREATE_WIDGET_BAR_CHART_SECTION: 'Section',
        BOARD_CREATE_WIDGET_BAR_CHART_OWNER: 'Owner',
        BOARD_CREATE_WIDGET_BAR_CHART_MEMBER: 'Member',
        BOARD_CREATE_WIDGET_BAR_CHART_STATUS: 'Status',
        BOARD_CREATE_WIDGET_BAR_CHART_PRIORITY: 'Priority',
        BOARD_CREATE_WIDGET_BAR_CHART_TAG: 'Tag',
        BOARD_CREATE_WIDGET_BAR_CHART_DATE: 'Due date',
        BOARD_CREATE_WIDGET_DONUT_CHART_TITLE: 'Data series',
        BOARD_CREATE_WIDGET_FILTERS: 'Filters',
        BOARD_CREATE_WIDGET_WHERE: 'Where',
        BOARD_CREATE_WIDGET_IS:'is',
        BOARD_CREATE_WIDGET_MEMBER:'Member',
        BOARD_CREATE_WIDGET_RESPONSABLE:'Owner',
        BOARD_CREATE_WIDGET_DATE_LIMITE:'Due date',
        BOARD_CREATE_WIDGET_PRIORITY:'Priority',
        BOARD_CREATE_WIDGET_CATEGORIES:'Tag',
        BOARD_CREATE_WIDGET_ADD_FILTER:' + Add filter',
        BOARD_CREATE_WIDGET_SAVE:'Save',
        BOARD_CREATE_WIDGET_CANCEL:'Cancel',
        BOARD_CREATE_WIDGET_CH_ONE : 'Today',
        BOARD_CREATE_WIDGET_CH_TWO : 'Tomorrow',
        BOARD_CREATE_WIDGET_CH_THREE : 'Before today',
        BOARD_CREATE_WIDGET_CH_FOUR : 'In the next 7 days',
        BOARD_CREATE_WIDGET_CH_FIVE : 'In the last 7 days',
        BOARD_CREATE_WIDGET_CH_SIX : 'Yesterday',
        BOARD_CREATE_WIDGET_PR_HIGH : 'High Priority',
        BOARD_CREATE_WIDGET_PR_MID : 'Medium Priority',
        BOARD_CREATE_WIDGET_PR_LOW : 'Low Priority',
        BOARD_CREATE_WIDGET_COMPLETED : 'Completed',
        BOARD_CREATE_WIDGET_INCOMPLETED : 'Incompleted',
        BOARD_FILTER_RESET : 'Reset',
        BOARD_UPDATE_TITLE : 'Edit widget',
        BOARD_UPDATE_EDIT : 'Edit',
    }
};
