export const locale = {
    lang: 'fr',
    data: {
        PROJECTS_BOARD_NEW_MEMBERS_INVITAION: 'Invitez des membres à collaborer àu tableau. S\'ils ne disposent pas de compte Excelway, ils recevront une invitation à créer un compte.',
        PROJECTS_BOARD_NEW_MEMBERS_MEMBERS: 'Membre',
        PROJECTS_BOARD_NEW_MEMBERS_THE_EMAIL: 'Email',
        PROJECTS_BOARD_NEW_MEMBERS_POSITION: 'Position',
        PROJECTS_BOARD_NEW_MEMBERS_SPONSOR: 'Sponsor',
        PROJECTS_BOARD_NEW_MEMBERS_ADMIN: 'Admin',
        PROJECTS_BOARD_NEW_MEMBERS_ADD_USER_BUTTON_LABEL: 'Ajouter un utilisateur',
        PROJECTS_BOARD_NEW_MEMBERS_RESPONSIBLE: 'Responsable',
        PROJECTS_BOARD_NEW_MEMBERS_ROLE: 'Rôle',
        PROJECTS_BOARD_NEW_MEMBERS_READING: 'Lecture',
        PROJECTS_BOARD_NEW_MEMBERS_MODIFICATION: 'Modification',
        PROJECTS_BOARD_NEW_MEMBERS_REMOVE: 'Retirer',
        PROJECTS_BOARD_NEW_MEMBERS_SAVE: 'Enregistrer',
        PROJECTS_BOARD_NEW_MEMBERS_CANCEL: 'Annuler',
    }
};
