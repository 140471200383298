import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    exhaustMap,
    map,
    catchError
} from 'rxjs/operators';

import * as HomeActions from './actions';
import { ProjectService } from '@bsuccess/services/project.service';

@Injectable()
export class StudioHomeStoreEffects {
    constructor(
        private _actions$: Actions,
        private _projectService: ProjectService
    ) { }

    load$ = createEffect(() =>
        this._actions$.pipe(
            ofType(HomeActions.load),
            exhaustMap(_ => {
                return this._projectService.loadHome().pipe(
                    map(response =>
                        HomeActions.loadSuccess({
                            home: response,
                        })
                    ),
                    catchError(error => {
                        return of(
                            HomeActions.loadFailure({
                                error: error,
                            })
                        );
                    })
                );
            })
        )
    );
}
