export const locale = {
    lang: 'en',
    data: {
        COMPONENTS_PROJECTS_PANEL_MY_PROJECTS:'MY PROJECTS',
        COMPONENTS_PROJECTS_PANEL_ADD_PROJECT:'New project',
        COMPONENTS_PROJECTS_PANEL_SEE_ALL:'See all my projects',
        COMPONENTS_PROJECTS_PANEL_ADD_SUB_PROJECTS:'Add new sub-project',
        COMPONENTS_PROJECTS_PANEL_NOT_AUTHORIZED:'You don\'t have permission to access this project',
    }
};
