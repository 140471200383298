export const locale = {
    lang: 'en',
    data: {
        CATEGORIES: 'Colors',
        ADD: 'Add',
        EDIT: 'Edit color',
        ADD_CATEGORIE: 'Add color',
        ADD_U: 'ADD',
        NAME: 'Name',
    }
};
