export const locale = {
    lang: 'ar',
    data: {
        PLACE: 'أين تريد وضع هذه البطاقات؟',
        ACTIVITY: 'نشاط',
        LIST: 'قائمة',
        CANCEL: 'إلغاء',
        SAVE: 'حِفْظْ',
    }
};
