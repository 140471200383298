export const locale = {
    lang: 'fr',
    data: {
        ALL_CARDS: 'Toute les cartes',
        CARDS_LIST: 'Cartes des listes',
        LIST_WHERE_TO_PLACE_TWO:'Quelles cartes souhaitez-vous importer?',
        CHOOSE_CARDS: 'Choisir les cartes',
        CANCEL: 'Annuler',
        SAVE: 'Enregistrer',
    }
};
