export const locale = {
    lang: 'fr',
    data: {
        PROJECTS_DIALOGS_PROJECT_SUMMARY_YOUR: 'Votre',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_SUMMARY: 'Résumé',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DAILY: 'Quotidien',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_WEEKLY: 'Hebdomadaire',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_COMPLETED_TASKS: 'Tâches terminées',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_IN_PROGRESS: 'Tâches en cours',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_LATE: 'Tâches en retard',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DUE_SOON: 'Tâches à venir',
        PROJECTS_DIALOGS_FILTER:'Filtrer',
        PROJECTS_DIALOGS_CANCEL:'Annuler',
        PROJECTS_DIALOGS_CH_ONE : 'Aujourd\'hui',
        PROJECTS_DIALOGS_CH_TWO : 'Demain',
        PROJECTS_DIALOGS_CH_THREE : 'Hier',
        PROJECTS_DIALOGS_CH_FOUR : 'Dans les 7 prochains jours',
        PROJECTS_DIALOGS_CH_FIVE : 'Dans les 7 derniers jours',
        PROJECTS_DIALOGS_CH_SIX : 'Hier',
        PROJECTS_DIALOGS_RESET : 'Réinitialiser',
        PROJECTS_DIALOGS_RESPONSABLE:'Responsable',
        PROJECTS_DIALOGS_DATE_LIMITE:'Date d\'échéance',
        PROJECTS_DIALOGS_PROJECTS:'Projets',
    }
};
