import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';

import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/sub-project-new-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/sub-project-new-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/sub-project-new-dialog/ar';

@Component({
    selector: 'sub-project-new-dialog',
    templateUrl: './sub-project-new-dialog.component.html',
    styleUrls: ['./sub-project-new-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubProjectNewDialogComponent implements OnInit {
    error$: Observable<string>;

    constructor(
        public dialogRef: MatDialogRef<SubProjectNewDialogComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.dialogRef.disableClose = true;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void { }
}
