export const locale = {
    lang: 'fr',
    data: {
        MANAGE: 'Gérer les Participants',
        ADD_TO_SESSION: 'Ajouter des participants à l\'atelier',
        EMAIL: 'Saisir l’email du participant et appuyer sur la touche Entrée',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
    }
};
