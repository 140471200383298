import { NoteModel } from '@bsuccess/models/project/note.model';

export interface State {
    currentNote: NoteModel;
    notes: NoteModel[];
    error?: string;
    pending?: boolean;
}

export const initialState: State = {
    currentNote: null,
    notes: [],
    pending: false
};
