export const locale = {
        lang: 'en',
        data: {
                WRITE: 'Write a task name', 
                PLACEHOLDER_COMMENT:' Leave a comment...',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_ITEM: 'Add item',
                PROJECTS_BOARD_DIALOGS_CARD_REMOVE_DATE: 'Remove the Due Date',
                PROJECTS_BOARD_DIALOGS_CARD_CHECKLIST_TITLE: 'Checklist title',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_CHECKLIST: 'Add Checklist',
                PROJECTS_BOARD_DIALOGS_CARD_UNSUBSCRIBE: 'Unsubscribe',
                PROJECTS_BOARD_DIALOGS_CARD_SUBSCRIBE: 'Subscribe',
                PROJECTS_BOARD_DIALOGS_CARD_DATE: 'Dates',
                PROJECTS_BOARD_DIALOGS_CARD_DELETE_CARD: 'Delete task',
                PROJECTS_BOARD_DIALOGS_CARD_DUPLICATE_CARD: 'Duplicate task',
                PROJECTS_BOARD_DIALOGS_CARD_TITLE: 'Title',
                PROJECTS_BOARD_DIALOGS_CARD_DESCRIPTION: 'Description',
                PROJECTS_BOARD_DIALOGS_CARD_DUE_DATE: 'Due date',
                PROJECTS_BOARD_DIALOGS_CARD_RESPONSIBLE: 'Owner',
                PROJECTS_BOARD_DIALOGS_CARD_CATEGORIES: 'Tags',
                PROJECTS_BOARD_DIALOGS_CARD_MEMBERS: 'Members',
                PROJECTS_BOARD_DIALOGS_CARD_ATTACHMENTS: 'Attachments',
                PROJECTS_BOARD_DIALOGS_CARD_REMOVE_ATTACHMENT: 'Remove attachment',
                PROJECTS_BOARD_DIALOGS_CARD_DOWNLOAD_ATTACHMENT: 'Download attachment',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_ATTACHMENT: 'Upload file',
                PROJECTS_BOARD_DIALOGS_CARD_REMOVE_CHECKLIST: 'Remove Checklist',
                PROJECTS_BOARD_DIALOGS_CARD_CHANGE_NAME_CHECKLIST: 'Edit name',
                PROJECTS_BOARD_DIALOGS_CARD_COMMENTS: 'Comments',
                PROJECTS_BOARD_DIALOGS_CARD_CLOSE: ' Close',
                PROJECTS_BOARD_DIALOGS_CARD_DELETE: 'Delete',
                PROJECTS_BOARD_DIALOGS_CARD_CONFIRM_DELETE: 'Are you sure you want to delete this task?',
                PROJECTS_BOARD_DIALOGS_CARD_CONFIRM_REMOVE_ATTACHMENT:'Are you sure you want to delete this attachment ?',
                PROJECTS_BOARD_DIALOGS_CARD_CONFIRM_DELETE_FROM_BOARD: 'Are you sure you want to remove the task from this board?',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_TO_CARD_LABEL: 'Add to task',
                PROJECTS_BOARD_DIALOGS_CARD_LABEL_LABEL: 'Tags',
                PROJECTS_BOARD_DIALOGS_CARD_MEMBERS_LABEL: 'Members',
                PROJECTS_BOARD_DIALOGS_CARD_DUPLICATE_CARD_LABEL: 'Duplicate task',
                PROJECTS_BOARD_DIALOGS_CARD_DELETE_CARD_LABEL: 'Delete task',
                PROJECTS_BOARD_DIALOGS_CARD_ATTACHMENT_LABEL: 'Attachment',
                PROJECTS_BOARD_DIALOGS_CARD_CARD_TITLE_LABEL: 'View task',
                PROJECTS_BOARD_DIALOGS_CARD_CARD_BUTTON_MARK_COMPLETE_TEXT:'Mark complete',
                PROJECTS_BOARD_DIALOGS_CARD_CARD_BUTTON_COMPLETED_TEXT:'Completed',
                PROJECTS_BOARD_DIALOGS_CARD_CHECKLIST: 'Checklist',
                PROJECTS_BOARD_DIALOGS_START_DATE: 'Start date',
                PROJECTS_BOARD_DIALOGS_END_DATE: 'Due date',
                VALIDATORS_END_DATE_BEFORE: 'Start date is greater than end date' ,
                PROJECTS_BOARD_DIALOGS_PRIORITY: 'Priority',
                PROJECTS_BOARD_DIALOGS_SECTION: 'Section',
                PROJECTS_BOARD_DIALOGS_ACTIVITIES: 'History',
                PROJECTS_BOARD_DIALOGS_CARD_CHOOSE_COVER: 'Choose picture as cover',
                PROJECTS_BOARD_LINK_BOARD_BUTTON: 'Link to a board',
                PROJECTS_BOARD_LINK: 'Link',
                PROJECTS_BOARD_LINK_REMOVE_BOARD_BUTTON: 'Remove link',
                PROJECTS_BOARD_MOVE_BOARD_BUTTON: 'Move to a board',
                PROJECTS_BOARD_MOVE: 'Move',
                PROJECTS_BOARD_LINK_INFO_BAR: 'This task is linked to the board ',
                PROJECTS_BOARD_LINK_BOARD_SUBMIT_BUTTON: 'Link to board',
                PROJECTS_BOARD_INCLUDE_BOARD_CHECK_BUTTON: 'Include boards from other projects',
                PROJECTS_BOARD_DIALOGS_BOARDS: 'Boards',
                PROJECTS_BOARD_DIALOGS_ADD_BOARD: 'Add a board',
                PROJECTS_BOARD_DIALOGS_NAME_BOARD: 'Board Name',
                PROJECTS_BOARD_DIALOGS_ADD_OPTION: '+ Add options',
                PROJECTS_BOARD_DIALOGS_CARD_SHOW_ACTIVITIES: 'Show history',
                PROJECTS_BOARD_DIALOGS_CARD_HIDE_ACTIVITIES: 'Hide history',
                PROJECTS_BOARD_DIALOGS_ADD: 'Add',
                PROJECTS_BOARD_DIALOGS_CANCEL: 'Cancel',
        }
};
