import { ProjectsNewParentComponent } from './../../studio/projects/new-parent/new-parent.component';
import { showAddOnsDialog } from './actions';
import { AccessPaymentComponent } from './../../access/dialogs/payment/payment-dialog.component';
import { AccessSignUpPolicyDialogComponent } from './../../access/dialogs/services-dialog/policy-dialog.component';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    exhaustMap,
    map,
    catchError,
    withLatestFrom,
    tap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import * as mixpanel from 'mixpanel-browser';

import { FuseUtils } from '@fuse/utils';

import { RootStoreState } from '..';
import { AccessStoreActions, AccessStoreSelectors } from '.';
import { LoginStoreActions, LoginStoreSelectors } from '../login-store';
import { AccessService } from '@bsuccess/services/access.service';
import { AuthService } from '@bsuccess/services/auth.service';
import { LocalStorageService } from '@bsuccess/services/local-storage.service';
import { UserModel } from '@bsuccess/models/user.model';
import { NavbarStoreActions } from '../navbar-store';
import { AccessSignUpTermsDialogComponent } from 'app/access/dialogs/terms-dialog/terms-dialog.component';
import { HelpHeroService } from '@bsuccess/services/helphero.service';
import { PricingPlansComponent } from 'app/access/dialogs/pricing-plans/pricing-plans.component';
import { AccessAddOnsDialogComponent } from 'app/access/dialogs/add-ons-dialog/add-ons-dialog.component';
import { StudioProjectStoreActions } from '../studio-store/project-store';
import { MatDialog } from '@angular/material/dialog';
import { FuseUpdateSubscriptionDialogComponent } from '@fuse/components/update-subscription-dialog/update-subscription-dialog.component';

@Injectable()
export class StripeEffectsEffects {
    projects = 3;
    maxprojects = 3;
    members = 1;
    maxmembers = 10;
    constructor(
        public dialog: MatDialog,
        private _actions$: Actions,
        private _store: Store<RootStoreState.State>,
        private _accessService: AccessService,
        private _authService: AuthService,
        private _localStorageService: LocalStorageService,
        private _router: Router,
        private _matDialog: MatDialog,
        private translate: TranslateService,
    ) { }


    whoamiSuccess$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LoginStoreActions.whoamiSuccess),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginTenant))
            ),
            exhaustMap(([action, tenant]) =>
                this._accessService
                    .getUserById(tenant, action.user._id)
                    .pipe(
                        map(_ => {
                            return AccessStoreActions.getUserInfoSuccess({
                                user: _,
                            });
                        }),
                        catchError(error =>
                            of(
                                AccessStoreActions.getUserInfoFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getUserInfo$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.getUserInfo),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginTenant))
            ),
            exhaustMap(([_, tenant]) =>
                this._accessService
                    .getUserById(tenant, _.id)
                    .pipe(
                        map(_ => {
                            return AccessStoreActions.getUserInfoSuccess({
                                user: _,
                            });
                        }),
                        catchError(error =>
                            of(
                                AccessStoreActions.getUserInfoFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );


    getTaxes$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.getTaxes),
            exhaustMap(_ =>
                this._accessService
                    .getTaxes()
                    .pipe(
                        map(_ => {
                            return AccessStoreActions.getTaxesSuccess({
                                taxes: _.data,
                            });
                        }),
                        catchError(error =>
                            of(
                                AccessStoreActions.getTaxesFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    canCreateNewProject$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.canCreateNewProject),
                tap(_ => {
                    this._store.dispatch(StudioProjectStoreActions.showNewProjectDialog());
                })
            ),
        {
            dispatch: false,
        }
    );

    openNewProjectDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.openNewProjectDialog),
                withLatestFrom(
                    this._store.pipe(select(AccessStoreSelectors.selectUserInfo))
                ),
                withLatestFrom(
                    this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
                ), 
                tap(([[_, userInfo], user]) => {
                    if(user.stripeInfo?.sumoLing){
                        if (userInfo.projectsCount === userInfo.projectsLimit) {
                            this._store.dispatch(AccessStoreActions.projectLimitReached());
                        } else {
                            if (userInfo.projectsCount < userInfo.projectsLimit) {
                                this._store.dispatch(AccessStoreActions.canCreateNewProject());
                            } else {
                                this._store.dispatch(AccessStoreActions.projectLimitReached());
                            }
                        }
                    }else{
                        this._store.dispatch(AccessStoreActions.canCreateNewProject());
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    limitReached$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.projectLimitReached),
                tap(_ => {
                    this.dialog.open(FuseUpdateSubscriptionDialogComponent, {
                        panelClass: 'fuse-update-subscription-dialog',
                        disableClose: false,
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    updateUsers$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(StudioProjectStoreActions.updateUsers),
                tap(_ => {

                })
            ),
        {
            dispatch: false,
        }
    );

    updateUserInfo$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(StudioProjectStoreActions.addParentSuccess),
                withLatestFrom(
                    this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
                ),
                tap(([_, user]) => {
                    this._store.dispatch(AccessStoreActions.getUserInfo({
                        id: user._id
                    }));
                })
            ),
        {
            dispatch: false,
        }
    );
}
