import { NotesPanelComponent, SafeHtmlPipe } from './notes-panel.component';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SortablejsModule } from 'ngx-sortablejs';
import { BsuccessModule } from '@bsuccess/bsuccess.module';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { TruncateModule } from 'ng2-truncate';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  FuseConfirmDialogModule,
  FuseMaterialColorPickerModule,
} from '@fuse/components';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [SafeHtmlPipe, NotesPanelComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatToolbarModule,
    MatStepperModule,
    MatDatepickerModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatCardModule,
    MatTooltipModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    SortablejsModule,
    BsuccessModule,
    TranslateModule,
    EditorModule,

    NgxDnDModule,
    TruncateModule,

    NgxMaterialTimepickerModule,

    FuseConfirmDialogModule,
    FuseMaterialColorPickerModule,
  ],
  exports: [NotesPanelComponent, SafeHtmlPipe],
})
export class NotesPanelModule {}
