import { NgModule } from '@angular/core';

import { StudioHomeStoreModule } from './home-store/home-store.module';
import { StudioProjectsStoreModule } from './projects-store/projects-store.module';
import { StudioProjectStoreModule } from './project-store/project-store.module';
import { StudioSessionsStoreModule } from './sessions-store/sessions-store.module';
import { StudioSessionStoreModule } from './session-store/session-store.module';
import { StudioNotificationsStoreModule } from './notifications-store/notifications-store.module';
import { StudioServicesStoreModule } from './services-store/services-store.module';
import { StudioBoardStoreModule } from './board-store/board-store.module';
import { StudioBoardsStoreModule } from './boards-store/boards-store.module';
import { StudioDetailStoreModule } from './detail-store/detail-store.module';
import { StudioDocumentStoreModule } from './document-store/document-store.module';
import { StudioUsersStoreModule } from './users-store/users-store.module';



@NgModule({
    imports: [
        StudioHomeStoreModule,
        StudioNotificationsStoreModule,
        StudioProjectsStoreModule,
        StudioProjectStoreModule,
        StudioSessionsStoreModule,
        StudioSessionStoreModule,
        StudioBoardStoreModule,
        StudioBoardsStoreModule,
        StudioServicesStoreModule,
        StudioDetailStoreModule,
        StudioDocumentStoreModule,
        StudioUsersStoreModule
    ],
})
export class StudioStoreModule { }
