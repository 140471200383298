import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    exhaustMap,
    map,
    catchError,
    withLatestFrom,
    tap,
    mergeMap,
    switchMap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { ProjectService } from '@bsuccess/services/project.service';
import * as BoardStoreActions from './actions';
import { RootStoreState } from '../..';
import { BoardService } from '@bsuccess/services/board.service';
import { StudioBoardStoreSelectors } from '.';
import { ProjectBoardDetailUpdateDialogComponent } from 'app/studio/projects/board/dialogs/update-details-dialog/board-update-details-dialog.component';
import { locale as english } from '../../../i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../i18n/@fuse/components/confirm-dialog/fr';
import { locale as arabic } from '../../../i18n/@fuse/components/confirm-dialog/ar';
import { AttachmentService } from '@bsuccess/services/attachment.service';
import { ProjectBoardAttachmentDisplayComponent } from 'app/studio/projects/board/dialogs/card/attachment-display/attachment-display.component';
import { BoardSubscribeDialogComponent } from 'app/studio/projects/board/dialogs/board-subscribe-dialog/board-subscribe-dialog.component';
import { BoardFilterCardsDialogComponent } from 'app/studio/projects/project/dialogs/board-filter-cards-dialog/board-filter-cards-dialog.component';
import { NotificationService } from '@bsuccess/services/notification.service';
import * as moment from 'moment';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import * as _ from 'lodash';
import { BoardCreateWidgetDialogComponent } from 'app/studio/projects/project/dialogs/board-create-widget-dialog/board-create-widget-dialog.component';
import { BoardUpdateWidgetDialogComponent } from 'app/studio/projects/project/dialogs/board-update-widget-dialog/board-update-widget-dialog.component';
import { CommentDialogComponent } from 'app/studio/projects/board/dialogs/card/comment-dialog/comment-dialog.component';
import { ProjectBoardLabelSelectorComponent } from 'app/studio/projects/board/dialogs/card/label-selector/label-selector.component';
import { AddBoardModalComponent } from 'app/studio/projects/board/dialogs/card/add-board-modal/add-board-modal.component';

@Injectable()
export class StudioBoardStoreEffects {
    confirmArchive: string;
    constructor(
        private _actions$: Actions,
        private _projectService: ProjectService,
        private _store: Store<RootStoreState.State>,
        private _matDialog: MatDialog,
        private _boardService: BoardService,
        private _notificationService: NotificationService,
        private _location: Location,
        private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private _attachmentService: AttachmentService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.translate.stream('CONFIRM_ARCHIVE_BOARD').subscribe((value) => {
            this.confirmArchive = value;
        });
    }

    loadBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.loadCurrent),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
            ),
            exhaustMap(([action, user]) =>
                this._boardService.getBoard(action.boardId).pipe(
                    map((board) => {
                        let allCards = [];
                        if (board.users.filter(_ => _.role === 'admin').map(_ => _._id).includes(user._id)) {
                            allCards = board.cards;
                        } else {
                            if (board.settings.enableBoardPrivacy) {
                                allCards = board.cards.filter(_ => _.idMembers.includes(user._id) || _.idResponsible === user._id);
                            } else {
                                allCards = board.cards;
                            }
                        }
                        return BoardStoreActions.loadCurrentSuccess({
                            board: {
                                ...board,
                                cards: allCards,
                                lists: [...board.lists.map(list => {
                                    return {
                                        ...list,
                                        idCards: [...list.idCards.map(idCard => {
                                            if (allCards.map(_ => _.id).includes(idCard)) {
                                                return idCard;
                                            }
                                        }).filter(card => card)]
                                    };
                                })]
                            }
                        })
                    }),
                    catchError(() =>
                        of(
                            BoardStoreActions.loadCurrentFailure({
                                error:
                                    this.translate.currentLang.toString() === 'fr'
                                        ? 'Erreur de chargement du tableau'
                                        : this.translate.currentLang.toString() === 'en'
                                            ? 'Error loading table'
                                            : 'خطأ في تحميل اللوحة',
                            })
                        )
                    )
                )
            )
        )
    );

    loadProjectsList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.loadCurrentSuccess),
            exhaustMap((_) => {
             return this._boardService.getProjectsList().pipe(
                map((response) => BoardStoreActions.loadProjectsListSuccess({ projectLists: response })),
                catchError((error) =>
                of(
                    BoardStoreActions.loadProjectsListFailure({
                        error: error,
                    })
                 )
                )
            ); 
        })
        )
    );

    archiveBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.archiveBoard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, board]) => {
                const matDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                    panelClass: 'fuse-confirm-dialog',
                    disableClose: false,
                });

                matDialogRef.componentInstance.confirmMessage = this.confirmArchive;

                return matDialogRef.afterClosed().pipe(
                    map((confirmed) => {
                        if (confirmed) {
                            return BoardStoreActions.archiveBoardConfirmed({
                                id: board.id,
                            });
                        } else {
                            return BoardStoreActions.archiveBoardCancelled();
                        }
                    })
                );
            })
        )
    );

    archiveBoardConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.archiveBoardConfirmed),
            exhaustMap((action) => {
                return this._projectService.archiveBoard(action.id).pipe(
                    map((response) =>
                        BoardStoreActions.archiveBoardSuccess({
                            id: response._id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.archiveBoardFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    archiveBoardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.archiveBoardSuccess),
                tap((_) => this._location.back())
            ),
        { dispatch: false }
    );

    showBoardDetailsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.showBoardDetailsDialog),
                map((_) => {
                    this._matDialog.open(ProjectBoardDetailUpdateDialogComponent, {
                        panelClass: 'board-update-details-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    onFilterChange$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.filterBoardCards),
                tap(() => {
                    this._notificationService.showSuccess(
                        this.translate.currentLang.toString() === 'fr'
                            ? 'Les actions ont été filtrées avec succès'
                            : this.translate.currentLang.toString() === 'en'
                                ? 'Tasks have been successfully filtered'
                                : 'تمت تصفية المهام بنجاح'
                    );
                })
            ),
        { dispatch: false }
    );

    showFilterBoardCardsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.showFilterBoardCardsDialog),
                map((_) => {
                    this._matDialog.open(BoardFilterCardsDialogComponent, {
                        panelClass: 'board-filter-cards-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showEditTagsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.showEditTagsDialog),
                map((_) => {
                    this._matDialog.open(ProjectBoardLabelSelectorComponent, {
                        panelClass: 'project-board-label-selector',
                        data: _.cardId,
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showCardAddBoardDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.showCardAddBoardDialog),
                map((_) => {
                    this._matDialog.open(AddBoardModalComponent, {
                        panelClass: 'add-board-modal',
                        data: _.projectName,
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    updateBoardDetails$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateBoardDetails),
            exhaustMap((action) =>
                this._boardService
                    .updateBoardDetails(action.boardId, {
                        name: action.name,
                        description: action.description,
                        enableBoardPrivacy: action.enableBoardPrivacy
                    })
                    .pipe(
                        map((_) =>
                            BoardStoreActions.updateBoardDetailsSuccess({
                                boardId: action.boardId,
                                name: action.name,
                                description: action.description,
                                enableBoardPrivacy: action.enableBoardPrivacy,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateBoardDetailsFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    addCardComment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addCardComment),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, board]) =>
                this._boardService.addComment(action.comment, board.id).pipe(
                    map((_) =>
                        BoardStoreActions.addCardCommentSuccess({
                            comment: {
                                ...action.comment,
                                id: _.comment,
                            },
                            cardId: action.comment.cardId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.addCardCommentFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    rename$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.rename),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.rename(boardId, action.newName).pipe(
                    map(() => BoardStoreActions.renameSuccess()),
                    catchError((error) =>
                        of(
                            BoardStoreActions.renameFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeCardFromBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.removeCardFromBoard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .removeCardFromBoard(boardId, action.cardId ,action.boardIdToRemove)
                    .pipe(
                        map(() =>
                            BoardStoreActions.removeCardFromBoardSuccess({
                                cardId: action.cardId,
                                boardIdToRemove: action.boardIdToRemove,
                                sharedBoard: action.sharedBoard,
                                listId: action.listId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.removeCardFromBoardFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    removeCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.removeCard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .removeCard(boardId, action.listId, action.cardId)
                    .pipe(
                        map(() =>
                            BoardStoreActions.removeCardSuccess({
                                listId: action.listId,
                                cardId: action.cardId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.removeCardFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    addCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addCard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.addCard(boardId, action.listId, action.card).pipe(
                    map((response) =>
                        BoardStoreActions.addCardSuccess({ 
                            card: response.card,
                            listId: action.listId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.addCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateCardName$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateCardName),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .updateCard(boardId, { id: action.cardId, name: action.name })
                    .pipe(
                        map(() =>
                            BoardStoreActions.updateCardNameSuccess({
                                cardId: action.cardId,
                                name: action.name,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateCardNameFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateCardStartDate$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateCardStartDate),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .updateCard(boardId, {
                        id: action.cardId,
                        startDate: moment(action.startDate).format('YYYY-MM-DD'),
                    })
                    .pipe(
                        map(() =>
                            BoardStoreActions.updateCardStartDateSuccess({
                                cardId: action.cardId,
                                startDate: action.startDate,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateCardStartDateFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteCardDate$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.deleteCardDate),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .deleteCardDate(boardId, {
                        id: action.cardId,
                        startDate: action.startDate,
                        endDate: action.endDate,
                    })
                    .pipe(
                        map(() =>
                            BoardStoreActions.deleteCardDateSuccess({
                                cardId: action.cardId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.deleteCardDateFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateAsCover$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateAsCover),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([_, boardId]) => {
                return this._boardService
                    .updateAsCover(_.cardId, _.attachmentId, boardId)
                    .pipe(
                        map((response) =>
                            BoardStoreActions.updateAsCoverSuccess({
                                attachmentId: _.attachmentId,
                                cardId: _.cardId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateAsCoverFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );


    updateCardEndDate$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateCardEndDate),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .updateCard(boardId, {
                        id: action.cardId,
                        endDate: moment(action.endDate).format('YYYY-MM-DD'),
                    })
                    .pipe(
                        map(() =>
                            BoardStoreActions.updateCardEndDateSuccess({
                                cardId: action.cardId,
                                endDate: action.endDate,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateCardEndDateFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateCardResponsible$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateCardResponsible),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .updateCard(boardId, {
                        id: action.cardId,
                        idResponsible: action.idResponsible,
                    })
                    .pipe(
                        map(() =>
                            BoardStoreActions.updateCardResponsibleSuccess({
                                cardId: action.cardId,
                                idResponsible: action.idResponsible,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateCardResponsibleFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    unsetCardResponsible$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.unsetCardResponsible),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .unsetCardResponsible(boardId, {
                        cardId: action.cardId,
                    })
                    .pipe(
                        map(() =>
                            BoardStoreActions.unsetCardResponsibleSuccess({
                                cardId: action.cardId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.unsetCardResponsibleFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    displayCardActivities$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.displayCardActivities),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .displayCardActivities(boardId, {
                        cardId: action.cardId,
                    })
                    .pipe(
                        map((response) =>
                            BoardStoreActions.displayCardActivitiesSuccess({
                                cardId: action.cardId,
                                activities : response,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.displayCardActivitiesFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateLabel),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, board]) =>
                this._boardService
                    .updateLabel(action.boardId, {
                        id: action.id,
                        name: action.name,
                        color: action.color,
                    })
                    .pipe(
                        map((_) =>
                            BoardStoreActions.updateLabelSuccess({
                                id: _.label.id,
                                name: _.label.name,
                                color: _.label.color,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateLabelFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    moveLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.moveLabel),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, board]) =>
                this._boardService
                    .moveLabel(board.id, {
                        cardId: action.cardId,
                        labelId: action.labelId,
                        dropIndex: action.dropIndex,
                    })
                    .pipe(
                        map((_) =>
                            BoardStoreActions.moveLabelSuccess({
                                cardId: _.cardId,
                                labelId: _.labelId,
                                dropIndex: _.dropIndex,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.moveLabelFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    addLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addLabel),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, board]) =>
                this._boardService
                    .addLabel(board.id, {
                        name: action.name,
                        color: action.color,
                    })
                    .pipe(
                        map((_) =>
                            BoardStoreActions.addLabelSuccess({
                                cardId: action.cardId,
                                boardId: board.id,
                                id: _.label.id,
                                name: _.label.name,
                                color: _.label.color,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.addLabelFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateCardDescription$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateCardDescription),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            switchMap(([action, boardId]) =>
                this._boardService
                    .updateCardDescription(boardId, {
                        id: action.cardId,
                        description: action.description,
                    })
                    .pipe(
                        map(() =>
                            BoardStoreActions.updateCardDescriptionSuccess({
                                cardId: action.cardId,
                                description: action.description,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateCardDescriptionFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    moveCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.moveCard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.moveCard(boardId, action.move).pipe(
                    map(() =>
                        BoardStoreActions.moveCardSuccess({
                            lists: action.lists,
                            move: action.move,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.moveCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addList),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.addList(boardId, action.list).pipe(
                    map(() =>
                        BoardStoreActions.addListSuccess({
                            list: action.list,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.addListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.removeList),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.removeList(boardId, action.listId).pipe(
                    map(() =>
                        BoardStoreActions.removeListSuccess({
                            listId: action.listId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.removeListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    moveList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.moveList),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.moveList(boardId, action.lists, action.move).pipe(
                    map(() =>
                        BoardStoreActions.moveListSuccess({
                            lists: action.lists,
                            move: action.move,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.moveListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    renameList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.renameList),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.renameList(boardId, action.listId, action.name).pipe(
                    map(() =>
                        BoardStoreActions.renameListSuccess({
                            listId: action.listId,
                            name: action.name,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.renameListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    toggleMember$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.toggleMember),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.toggleMember(boardId, action.toggleMemberForm).pipe(
                    map(() =>
                        BoardStoreActions.toggleMemberSuccess({
                            boardId,
                            toggleMemberForm: action.toggleMemberForm,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.toggleMemberFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addChecklist$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addChecklist),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .addCheckList(boardId, action.cardId, action.checklist)
                    .pipe(
                        map(() =>
                            BoardStoreActions.addChecklistSuccess({
                                cardId: action.cardId,
                                checklist: action.checklist,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.addChecklistFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    addcheckItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addcheckItem),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.addcheckItem(boardId, action.checkItemForm).pipe(
                    map(() =>
                        BoardStoreActions.addcheckItemSuccess({
                            checkItemForm: action.checkItemForm,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.addcheckItemFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateCheckListName$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateCheckListName),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.updateChecklistName(boardId, action.checkListForm).pipe(
                    map(() =>
                        BoardStoreActions.updateCheckListNameSuccess({
                            checkListForm: action.checkListForm,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.updateCheckListNameFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeCheckList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.removeCheckList),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.removeCheckList(boardId, action.checkListForm).pipe(
                    map(() =>
                        BoardStoreActions.removeCheckListSuccess({
                            checkListForm: action.checkListForm,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.removeCheckListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeCheckItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.removeCheckItem),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.removeCheckItem(boardId, action.checkListForm).pipe(
                    map(() =>
                        BoardStoreActions.removeCheckItemSuccess({
                            checkListForm: action.checkListForm,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.removeCheckItemFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateCheckItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateCheckItem),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            switchMap(([action, boardId]) =>
                this._boardService.updateCheckItem(boardId, action.checkItemForm).pipe(
                    map(() =>
                        BoardStoreActions.updateCheckItemSuccess({
                            checkItemForm: action.checkItemForm,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.updateCheckItemFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateLbels$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateLbels),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardLabels))
            ),
            exhaustMap(([[action, boardId], labels]) =>
                this._boardService.updateLabels(boardId, labels).pipe(
                    map(() =>
                        BoardStoreActions.updateLbelsSuccess({
                            labels,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.updateLbelsFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    toggleLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.toggleLabel),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService.toggleLabel(action.boardId, action.toggleForm).pipe(
                    map(() =>
                        BoardStoreActions.toggleLabelSuccess({
                            toggleForm: action.toggleForm,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.toggleLabelFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    duplicateCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.duplicateCard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([_, boardId]) =>
                this._boardService.duplicateCard(boardId, _.duplicate).pipe(
                    map((response) =>
                        BoardStoreActions.duplicateCardSuccess({
                            duplicate: _.duplicate,
                            card: response,
                        }),
                        this._notificationService.showSuccess(
                            this.translate.currentLang.toString() === 'fr' ?
                                'L\'a carte a été dupliquée avec succès.' :
                                this.translate.currentLang.toString() === 'en' ?
                                    'The task was duplicated successfully' : 'تم تكرار البطاقة بنجاح'
                        )
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.duplicateCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteComment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.deleteComment),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([_, boardId]) =>
                this._boardService.deleteComment(_.cardId, _.commentId, boardId).pipe(
                    map((response) =>
                        BoardStoreActions.deleteCommentSuccess({
                            id: response._id,
                            cardId: _.cardId,
                            commentId: _.commentId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.deleteCommentFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    uploadAttachmentMinIo$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.uploadAttachment),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
            ),
            exhaustMap(([[action, boardId], user]) => {
                return this._boardService
                    .minioUpload(action.content, user._id + '/' + action.name)
                    .pipe(
                        map((response) =>
                            BoardStoreActions.uploadAttachmentSuccess({
                                boardId: boardId,
                                response: {
                                    _id: response.Metadata.token,
                                    filename: user._id + '/' + action.name,
                                    contentType: response.ContentType,
                                    uploadDate: moment(response.LastModified).unix(),
                                    size: response.ContentLength,
                                },
                                card: action.card,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.uploadAttachmentFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    uploadAttachmentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.uploadAttachmentSuccess),
                tap((_) => {
                    this._store.dispatch(
                        BoardStoreActions.addAttachment({
                            boardId: _.boardId,
                            card: _.card,
                            attachment: _.response,
                        })
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    addAttachment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addAttachment),
            exhaustMap((action) =>
                this._boardService
                    .addAttachment(action.boardId, {
                        cardId: action.card.id,
                        attachment: action.attachment,
                    })
                    .pipe(
                        map((_) =>
                            BoardStoreActions.addAttachmentSuccess({
                                boardId: action.boardId,
                                card: action.card,
                                attachment: action.attachment,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.addAttachmentFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    //   addAttachmentSuccess$ = createEffect(
    //     () =>
    //       this._actions$.pipe(
    //         ofType(
    //           BoardStoreActions.addAttachmentSuccess,
    //           BoardStoreActions.addAttachmentViaSocket
    //         ),
    //         tap((_) => {
    //           this._store.dispatch(
    //             BoardStoreActions.LoadImages({
    //               card: _.card,
    //             })
    //           );
    //         })
    //       ),
    //     {
    //       dispatch: false,
    //     }
    //   );

    //   LoadImages$ = createEffect(
    //     () =>
    //       this._actions$.pipe(
    //         ofType(BoardStoreActions.LoadImages),
    //         tap((action) => {
    //           action.card.attachments.map((attachment) => {
    //             if (
    //               [
    //                 'image/png',
    //                 'image/gif',
    //                 'image/x-icon',
    //                 'image/jpeg',
    //                 'image/svg+xml',
    //                 'image/webp',
    //                 'image/tiff',
    //               ].includes(attachment.contentType)
    //             ) {
    //               this._store.dispatch(
    //                 BoardStoreActions.updateImage({
    //                   cardId: action.card.id,
    //                   attachment,
    //                 })
    //               );
    //             }
    //           });
    //         })
    //       ),
    //     { dispatch: false }
    //   );

    //   updateImage$ = createEffect(() =>
    //     this._actions$.pipe(
    //       ofType(BoardStoreActions.updateImage),
    //       mergeMap((action) => {
    //         return this._attachmentService.get(action.attachment._id).pipe(
    //           map((response) =>
    //             BoardStoreActions.updateImageSuccess({
    //               cardId: action.cardId,
    //               content: response,
    //               attachment: action.attachment,
    //             })
    //           ),
    //           catchError((error) =>
    //             of(
    //               BoardStoreActions.updateImageFailure({
    //                 error: error,
    //               })
    //             )
    //           )
    //         );
    //       })
    //     )
    //   );

    //   updateProjectImageSuccess$ = createEffect(() =>
    //     this._actions$.pipe(
    //       ofType(BoardStoreActions.updateImageSuccess),
    //       mergeMap((action) => {
    //         return this._attachmentService.createFromBlob(action.content).pipe(
    //           map((response) =>
    //             BoardStoreActions.imageRendered({
    //               cardId: action.cardId,
    //               attachment: action.attachment,
    //               content: response,
    //             })
    //           ),
    //           catchError((error) =>
    //             of(
    //               BoardStoreActions.updateImageFailure({
    //                 error: error,
    //               })
    //             )
    //           )
    //         );
    //       })
    //     )
    //   );

    showFullScreenDisplayDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.showFullScreenDisplayDialog),
                map((_) => {
                    this._matDialog.open(ProjectBoardAttachmentDisplayComponent, {
                        panelClass: 'project-board-attachment-display-dialog',
                        data: _.attachment,
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    loadImage$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.loadImage),
            mergeMap((action) => {
                return this._attachmentService.get(action.attachment.filename).pipe(
                    map((response) =>
                        BoardStoreActions.loadImageSuccess({
                            content: response,
                            attachment: action.attachment,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.loadImageFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    loadImageSuccess$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.loadImageSuccess),
            mergeMap((action) => {
                return this._attachmentService.createFromBlob(action.content).pipe(
                    map((response) =>
                        BoardStoreActions.renderCurrentImage({
                            attachment: action.attachment,
                            content: response,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.loadImageFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    downloadAttachment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.downloadAttachment),
            exhaustMap((_) => {
                return this._boardService.downloadDocument(_.attachment).pipe(
                    map((response) =>
                        BoardStoreActions.downloadAttachmentSuccess({
                            response,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.downloadAttachmentFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    removeAttachment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.removeAttachment),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([_, boardId]) => {
                return this._boardService
                    .removeAttachment(_.cardId, _.attachmentId, boardId)
                    .pipe(
                        map((response) =>
                            BoardStoreActions.removeAttachmentSuccess({
                                attachmentId: _.attachmentId,
                                cardId: _.cardId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.removeAttachmentFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    loadPreviewImage$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.loadPreviewImage),
            mergeMap((action) => {
                return this._attachmentService.get(action.attachment._id).pipe(
                    map((response) =>
                        BoardStoreActions.loadPreviewImageSuccess({
                            content: response,
                            attachment: action.attachment,
                            cardId: action.cardId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.loadPreviewImageFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    loadPreviewImageSuccess$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.loadPreviewImageSuccess),
            mergeMap((action) => {
                return this._attachmentService.createFromBlob(action.content).pipe(
                    map((response) =>
                        BoardStoreActions.renderPreviewImage({
                            attachment: action.attachment,
                            content: response,
                            cardId: action.cardId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.loadPreviewImageFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    removeLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.removeLabel),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([_, boardId]) => {
                return this._boardService.removeLabel(_.boardId, _.labelId, _.label).pipe(
                    map((response) =>
                        BoardStoreActions.removeLabelSuccess({
                            labelId: _.labelId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.removeLabelFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    updateBackground$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateBackground),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([_, boardId]) => {
                return this._boardService
                    .updateBoardBackground(boardId, _.background)
                    .pipe(
                        map((response) =>
                            BoardStoreActions.updateBackgroundSuccess({
                                background: _.background,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateBackgroundFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateCardIsCompleted$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateCardIsCompleted),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) => {
                const markedDate = moment(Date.now()).format('YYYY-MM-DD');
                return this._boardService
                    .updateCard(boardId, {
                        id: action.cardId,
                        completed: {
                            isCompleted: action.isCompleted,
                            completedDate: markedDate,
                        },
                    })
                    .pipe(
                        map(() =>
                            BoardStoreActions.updateCardIsCompletedSuccess({
                                cardId: action.cardId,
                                completed: {
                                    isCompleted: action.isCompleted,
                                    completedDate: markedDate,
                                },
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateCardIsCompletedFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateCardPriority$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.updateCardPriority),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .updateCard(boardId, { id: action.cardId, priority: action.priority })
                    .pipe(
                        map(() =>
                            BoardStoreActions.updateCardPrioritySuccess({
                                cardId: action.cardId,
                                priority: action.priority,
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.updateCardPriorityFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    showSubscribedDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.showSubscribedDialog),
                map((_) => {
                    this._matDialog.open(BoardSubscribeDialogComponent, {
                        panelClass: 'board-subscribed-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    watchBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.watchBoard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
            ),
            exhaustMap(([[_, boardId], user]) =>
                this._boardService
                    .watchBoard(user._id, {
                        _id: boardId,
                        enableEmailNotifications: _.enableEmailNotifications,
                        daily: _.daily,
                        weekly: _.weekly,
                    })
                    .pipe(
                        map(
                            () =>
                                BoardStoreActions.watchBoardSuccess({
                                    _id: boardId,
                                    enableEmailNotifications: _.enableEmailNotifications,
                                    daily: _.daily,
                                    weekly: _.weekly,
                                }),
                            catchError((error) =>
                                of(
                                    BoardStoreActions.watchBoardFailure({
                                        error: error,
                                    })
                                )
                            )
                        )
                    )
            )
        )
    );


    linkCardToBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.linkCardToBoard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .linkCardToBoard(boardId, {
                        cardId: action.cardDetails.cardId,
                        linkedBoardId: action.cardDetails.linkedBoardId
                    })
                    .pipe(
                        map(_ =>
                            BoardStoreActions.linkCardToBoardSuccess({
                                boardId: action.boardId,
                                cardDetails: {
                                    ...action.cardDetails,
                                    ..._
                                }
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.linkCardToBoardFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    addCardToBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addCardToBoard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) => {
                return this._boardService
                    .addCardToBoard(boardId, {
                        cardId: action.cardDetails.cardId,
                        boardToShareWith: action.cardDetails.boardToShareWith,
                        comment : '',
                        inListId : "0",
                        shouldShowModal : true,
                        createdAt: new Date().toString(),
                    })
                    .pipe(
                        map((_: any) => {
                            if(_.message == 'Card_already_shared_with_the_selected_board'){
                                this._notificationService.showError(
                                    this.translate.currentLang.toString() === 'fr' ?
                                        'Carte déjà partagée avec le tableau sélectionné' :
                                        this.translate.currentLang.toString() === 'en' ?
                                            'Card already shared with the selected board' :
                                            'البطاقة مشتركة مع اللوحة المحددة'
                                );
                            }
                            if(_.message == 'shared_successfully'){
                                return BoardStoreActions.addCardToBoardSuccess({                                   
                                    boardId: boardId,
                                    listId: action.listId,
                                    cardDetails: {
                                        cardId: action.cardDetails.cardId,
                                        boardToShareWith: action.cardDetails.boardToShareWith,
                                        boardName: action.cardDetails.boardName,
                                    },
                                })
                            }
                            if(_.message == 'require_admin'){
                                return BoardStoreActions.addCardstoBoardWithComment({                                   
                                    boardId: boardId,
                                    cardDetails: {
                                        cardId: action.cardDetails.cardId,
                                        boardToShareWith: action.cardDetails.boardToShareWith,
                                    },
                                })
                            }
                        }),
                        catchError(error =>
                            of(
                                BoardStoreActions.addCardToBoardFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                })
        ),
    );

    addCardstoBoardWithComment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addCardstoBoardWithComment),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    CommentDialogComponent,
                    {
                        panelClass: 'comment-dialog',
                        data: {
                            type: 'addActionToBoard',
                        },
                    }
                );
                return matDialogRef.afterClosed().pipe(
                    map(data => {
                        if (data) {
                            return BoardStoreActions.addCardstoBoardWithCommentConfirmed({
                                boardId: action.boardId,
                                cardDetails: {
                                    cardId: action.cardDetails.cardId,
                                    boardToShareWith: action.cardDetails.boardToShareWith,
                                },
                                comment: data,
                            });
                        } else {
                            return BoardStoreActions.addCardstoBoardWithCommentCanceled();
                        }
                    })
                );
            })
        )
    );

    addCardstoBoardWithCommentConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.addCardstoBoardWithCommentConfirmed),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) => {
                return this._boardService
                    .addCardToBoard(boardId, {
                        cardId: action.cardDetails.cardId,
                        boardToShareWith: action.cardDetails.boardToShareWith,
                        comment : action.comment,
                        inListId : "0",
                        shouldShowModal : false,
                        createdAt: new Date().toString(),
                    })
                    .pipe(
                        map((_: any) => {
                            return BoardStoreActions.addCardstoBoardWithCommentSuccess({                                    
                                    boardId: _.boardId,
                                    cardDetails: {
                                        cardId: _.cardId,
                                        boardToShareWith: _.boardToShareWith,
                                    },
                                    comment: action.comment,
                            })
                        }),
                        catchError(error =>
                            of(
                                BoardStoreActions.addCardstoBoardWithCommentFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                })
        )
    );

    addCardstoBoardWithCommentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.addCardstoBoardWithCommentSuccess),
                tap(() => {
                    this._notificationService.showSuccess(
                        this.translate.currentLang.toString() === 'fr' ?
                            'L\'action est en attente d\'approbation par les administrateurs du tableau' :
                            this.translate.currentLang.toString() === 'en' ?
                                'The action is waiting for approval by board administrators' : 'الإجراء في طور الموافقة من قبل مسؤولي اللوحة'
                    );
                })
            ),
        { dispatch: false }
    );

    copyCardToBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.copyCardToBoard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) => {
                return this._boardService
                    .copyCardToBoard(boardId, {
                        cardId: action.cardDetails.cardId,
                        boardToMoveTo: action.cardDetails.linkedBoardId,
                        comment : '',
                        shouldShowModal : true,
                        createdAt: new Date().toString(),
                    })
                    .pipe(
                        map((_: any) => {
                            if(_.message == 'exported_successfully'){
                                this._notificationService.showSuccess(
                                    this.translate.currentLang.toString() === 'fr' ?
                                        'La carte a été envoyée avec succès' :
                                        this.translate.currentLang.toString() === 'en' ?
                                            'The card has been moved successfully' : 'تم إرسال البطاقة بنجاح'
                                );
                                this._matDialog.closeAll();
                                return BoardStoreActions.copyCardToBoardSuccess({                                   
                                    boardId: boardId,
                                    listId: action.listId,
                                    cardDetails: {
                                        cardId: action.cardDetails.cardId,
                                        linkedBoardId: action.cardDetails.linkedBoardId,
                                    },
                                })
                            }
                            if(_.message == 'require_admin'){
                                return BoardStoreActions.copyCardstoBoardWithComment({                                   
                                    boardId: boardId,
                                    cardDetails: {
                                        cardId: action.cardDetails.cardId,
                                        linkedBoardId: action.cardDetails.linkedBoardId,
                                    },
                                })
                            }
                        }),
                        catchError(error =>
                            of(
                                BoardStoreActions.copyCardToBoardFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                })
        )
    );

    copyCardstoBoardWithComment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.copyCardstoBoardWithComment),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    CommentDialogComponent,
                    {
                        panelClass: 'comment-dialog'
                    }
                );
                return matDialogRef.afterClosed().pipe(
                    map(data => {
                        if (data) {
                            return BoardStoreActions.copyCardstoBoardWithCommentConfirmed({
                                boardId: action.boardId,
                                cardDetails: {
                                    cardId: action.cardDetails.cardId,
                                    linkedBoardId: action.cardDetails.linkedBoardId,
                                },
                                comment: data,
                            });
                        } else {
                            return BoardStoreActions.copyCardstoBoardWithCommentCanceled();
                        }
                    })
                );
            })
        )
    );

    copyCardstoBoardWithCommentConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.copyCardstoBoardWithCommentConfirmed),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) => {
                return this._boardService
                    .copyCardToBoard(boardId, {
                        cardId: action.cardDetails.cardId,
                        boardToMoveTo: action.cardDetails.linkedBoardId,
                        comment : action.comment,
                        shouldShowModal : false,
                        createdAt: new Date().toString(),
                    })
                    .pipe(
                        map((_: any) => {
                            return BoardStoreActions.copyCardstoBoardWithCommentSuccess({                                    
                                    boardId: _.boardId,
                                    cardDetails: {
                                        cardId: _.cardId,
                                        linkedBoardId: _.linkedBoardId,
                                    },
                                    comment: action.comment,
                            })
                        }),
                        catchError(error =>
                            of(
                                BoardStoreActions.copyCardstoBoardWithCommentFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                })
        )
    );

    copyCardstoBoardWithCommentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.copyCardstoBoardWithCommentSuccess),
                tap(() => {
                    this._notificationService.showSuccess(
                        this.translate.currentLang.toString() === 'fr' ?
                            'L\'action est en attente d\'approbation par les administrateurs du tableau' :
                            this.translate.currentLang.toString() === 'en' ?
                                'The action is waiting for approval by board administrators' : 'الإجراء في طور الموافقة من قبل مسؤولي اللوحة'
                    );
                })
            ),
        { dispatch: false }
    );

    unlinkCardFromBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.unlinkCardFromBoard),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .unlinkCardFromBoard(boardId, action.cardId)
                    .pipe(
                        map(() =>
                            BoardStoreActions.unlinkCardFromBoardSuccess({
                                boardId: action.boardId,
                                cardId: action.cardId
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.unlinkCardFromBoardFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );


    getCharts$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BoardStoreActions.getCharts),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
            ),
            exhaustMap(([action, boardId]) =>
                this._boardService
                    .getProjectBoardCharts(boardId)
                    .pipe(
                        map(_ =>
                            BoardStoreActions.getChartsSuccess({
                                boardId: action.boardId,
                                widgetCharts: _
                            })
                        ),
                        catchError((error) =>
                            of(
                                BoardStoreActions.getChartsFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    createWidgetDialogCall$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.createWidgetDialogCall),
                map((_) => {
                    this._matDialog.open(BoardCreateWidgetDialogComponent, {
                        panelClass: 'board-create-widget-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showUpdateWidgetDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BoardStoreActions.showUpdateWidgetDialog),
                map((action) => {
                    this._matDialog.open(BoardUpdateWidgetDialogComponent, {
                        panelClass: 'board-update-widget-dialog',
                        data: action.widget,
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    moveWidget$ = createEffect(() =>
    this._actions$.pipe(
        ofType(BoardStoreActions.moveWidget),
        withLatestFrom(
            this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
        ),
        exhaustMap(([action, boardId]) =>
            this._boardService
                .moveWidget(boardId , action.widgetId, action.dropIndex , action.widgetNumber)
                .pipe(
                    map(_ =>
                        BoardStoreActions.moveWidgetSuccess({
                            widgetId : action.widgetId,
                            dropIndex : action.dropIndex
                        }),
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.moveWidgetFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeWidget$ = createEffect(() =>
    this._actions$.pipe(
        ofType(BoardStoreActions.removeWidget),
        withLatestFrom(
            this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
        ),
        exhaustMap(([action, boardId]) =>
            this._boardService
                .removeWidget(boardId, action.widgetId ,action.widgetNumber)
                .pipe(
                    map(_ =>
                        BoardStoreActions.removeWidgetSuccess({
                            widgetId : action.widgetId
                        }),
                        this._notificationService.showSuccess(
                            this.translate.currentLang.toString() === 'fr' ?
                                'Le widget a été supprimé avec succès.' :
                                this.translate.currentLang.toString() === 'en' ?
                                'The widget was successfully deleted.' : 
                                'تم حذف الواجهة بنجاح.'
                        )
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.removeWidgetFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateWidget$ = createEffect(() =>
    this._actions$.pipe(
        ofType(BoardStoreActions.updateWidget),
        withLatestFrom(
            this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
        ),
        exhaustMap(([action, boardId]) =>
            this._boardService
                .updateWidget(boardId, 
                    {   _id: action.widget._id,
                        name: action.widget.name, 
                        widgetType: action.widget.widgetType, 
                        dataAbscissa: action.widget.dataAbscissa, 
                        widgetFilters: action.widget.widgetFilters
                    })
                .pipe(
                    map(response =>
                        BoardStoreActions.updateWidgetSuccess({
                            boardId: action.boardId,
                            previousType: action.widget.previousType,
                            response: response,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.updateWidgetFailure({
                                error: error,
                            })
                        )
                    )
                )
        )
    )
);
    

    createWidget$ = createEffect(() =>
    this._actions$.pipe(
        ofType(BoardStoreActions.createWidget),
        withLatestFrom(
            this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
        ),
        exhaustMap(([action, boardId]) =>
            this._boardService
                .createWidget(boardId, 
                    {   name: action.widget.name, 
                        widgetType: action.widget.widgetType, 
                        dataAbscissa: action.widget.dataAbscissa, 
                        widgetFilters: action.widget.widgetFilters
                    })
                .pipe(
                    map(response =>
                        BoardStoreActions.createWidgetSuccess({
                            boardId: action.boardId,
                            widget: response,
                        })
                    ),
                    catchError((error) =>
                        of(
                            BoardStoreActions.createWidgetFailure({
                                error: error,
                            })
                        )
                    )
                )
        )
    )
);

}
