export const locale = {
    lang: 'fr',
    data: {
        PLACE: 'Où souhaitez-vous placer ces cartes?',
        ACTIVITY: 'Activité',
        LIST: 'Liste',
        CANCEL: 'Annuler',
        SAVE: 'Enregistrer',
    }
};
