import {
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { Store, select } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
  RootStoreState
} from 'app/root-store';
import { Board } from '@bsuccess/models/canvas/board.model';
import { WorkshopCanvasStoreSelectors, WorkshopCanvasStoreActions } from 'app/root-store/workshop-store/canvas-store';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { locale as english } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/export-cards-dialog/selection-step/en';
import { locale as frensh } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/export-cards-dialog/selection-step/fr';
import { locale as arabic } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/export-cards-dialog/selection-step/ar';

export class TodoItemFlatNode {
  item: string;
  level: number;
  id: string;
  expandable: boolean;
}
@Component({
  selector: 'export-cards-dialog-selection-step',
  templateUrl: './selection-step.component.html',
  styleUrls: ['./selection-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportCardsDialogSelectionStepComponent implements OnInit {
  categories$: Observable<SessionCategoryModel[]>;
  board$: Observable<Board>;
  lists = [];
  selectedLists = [];
  categories = [];
  selectedCategories = [];
  cards = [];
  selectionType = 'all';
  type = 'plus';
  points = 0;
  listsWithCards = [];

  private _unsubscribeAll: Subject<any>;

  flatNodeMap = new Map<TodoItemFlatNode, any>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<any, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<any, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<any, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(
      true /* multiple */
  );

  getLevel = (node: TodoItemFlatNode) => node.level;
  // tree

  isExpandable = (node: any) => node.expandable;

  getChildren = (node: any): any[] => node.children;

  hasChild = (_: number, _nodeData: any) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: any) => _nodeData.text === '';

  constructor(
      public matDialogRef: MatDialogRef<
          ExportCardsDialogSelectionStepComponent
      >,
      @Inject(MAT_DIALOG_DATA) private _data: any,
      private _store: Store<RootStoreState.State>,
      private translationLoaderService: FuseTranslationLoaderService,
      public translateService: TranslateService,
  ) {
      this.translationLoaderService.loadTranslations(english, frensh, arabic);

      this.board$ = this._store.pipe(
          select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard)
      );

      this.categories$ = this._store.pipe(
          select(LoginStoreSelectors.selectSessionCategories)
      );

      this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
      this.board$.pipe(takeUntil(this._unsubscribeAll)).subscribe(board => {
          this.lists = board.lists.map(list => {
              this.listsWithCards.push({
                  value: list.id,
                  text: list.name,
                  children: list.idCards.map(idCard => {
                      return {
                          value: board.cards.filter(
                              card => card.id === idCard
                          )[0].id,
                          text: board.cards.filter(
                              card => card.id === idCard
                          )[0].name,
                      };
                  }),
              });
              return {
                  ...list,
                  checked: false,
              };
          });

          this.treeFlattener = new MatTreeFlattener(
              this.transformer,
              this.getLevel,
              this.isExpandable,
              this.getChildren
          );
          this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
              this.getLevel,
              this.isExpandable
          );
          this.dataSource = new MatTreeFlatDataSource(
              this.treeControl,
              this.treeFlattener
          );

          this.dataSource.data = this.listsWithCards;
          this.cards = board.cards.map(card => {
              return {
                  ...card,
                  checked: false,
              };
          });
      });

      this.categories$.subscribe(_ => {
          this.categories = _.map(category => {
              return {
                  ...category,
                  checked: false,
              };
          });
      });
  }

  ngOnDestroy(): void {
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
  }

  transformer = (node: any, level: number) => {
      const existingNode = this.nestedNodeMap.get(node);
      const flatNode =
          existingNode && existingNode.item === node.text
              ? existingNode
              : new TodoItemFlatNode();
      flatNode.item = node.text;
      flatNode.level = level;
      flatNode.id = node.value;
      flatNode.expandable = !!node.children;
      this.flatNodeMap.set(flatNode, node);
      this.nestedNodeMap.set(node, flatNode);
      return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
      const descendants = this.treeControl.getDescendants(node);
      const descAllSelected = descendants.every(child =>
          this.checklistSelection.isSelected(child)
      );
      return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
      const descendants = this.treeControl.getDescendants(node);
      const result = descendants.some(child =>
          this.checklistSelection.isSelected(child)
      );
      return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
      this.checklistSelection.toggle(node);
      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelection.isSelected(node)
          ? this.checklistSelection.select(...descendants)
          : this.checklistSelection.deselect(...descendants);

      // Force update for the parent
      descendants.every(child => this.checklistSelection.isSelected(child));
      this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
      this.checklistSelection.toggle(node);
      this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
      let parent: TodoItemFlatNode | null = this.getParentNode(node);
      while (parent) {
          this.checkRootNodeSelection(parent);
          parent = this.getParentNode(parent);
      }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
      const nodeSelected = this.checklistSelection.isSelected(node);
      const descendants = this.treeControl.getDescendants(node);
      const descAllSelected = descendants.every(child =>
          this.checklistSelection.isSelected(child)
      );
      if (nodeSelected && !descAllSelected) {
          this.checklistSelection.deselect(node);
      } else if (!nodeSelected && descAllSelected) {
          this.checklistSelection.select(node);
      }
  }

  /* Get the parent node of a node */
  getParentNode(node: any): TodoItemFlatNode | null {
      const currentLevel = this.getLevel(node);

      if (currentLevel < 1) {
          return null;
      }

      const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

      for (let i = startIndex; i >= 0; i--) {
          const currentNode = this.treeControl.dataNodes[i];

          if (this.getLevel(currentNode) < currentLevel) {
              return currentNode;
          }
      }
      return null;
  }

  updateCheck(array, item: any, object: any): void {
      if (item.checked) {
          array.push(object);
      } else if (!item.checked) {
          array.pop();
      }
  }

  save(): void {
      switch (this.selectionType) {
          case 'all':
              this._store.dispatch(
                  WorkshopCanvasStoreActions.exportAllCards()
              );
              break;
          case 'category':
              this._store.dispatch(
                  WorkshopCanvasStoreActions.exportCardsbyIdCategories({
                      idCategories: this.selectedCategories.map(
                          category => category.id
                      ),
                  })
              );
              break;
          case 'columns':
              this._store.dispatch(
                  WorkshopCanvasStoreActions.exportCardsbyIdLists({
                      idLists: this.selectedLists.map(label => label.id),
                  })
              );
              break;
          case 'valueRate':
              this._store.dispatch(
                  WorkshopCanvasStoreActions.exportCardsbyScore({
                      operator:
                          this.type === 'plus'
                              ? '$gte'
                              : this.type === 'moins'
                                  ? '$lte'
                                  : '',
                      score: this.points,
                  })
              );
              break;
          case 'select':
              this._store.dispatch(
                  WorkshopCanvasStoreActions.exportCardsbyId({
                      idCards: this.checklistSelection.selected
                          .filter(selected => selected.level !== 0)
                          .map(card => card.id),
                  })
              );
              break;
      }
  }
}
