export const locale = {
    lang: 'ar',
    data: {
        PROJECTS_DIALOGS_UPDATE_EDIT_DETAILS: 'تحرير تفاصيل المشروع',
        PROJECTS_DIALOGS_UPDATE_PROJECT_NAME: ' عنوان المشروع',
        PROJECTS_DIALOGS_UPDATE_START_DATE: 'تاريخ البدء',
        PROJECTS_DIALOGS_UPDATE_END_DATE: 'تاريخ الانتهاء',
        PROJECTS_DIALOGS_UPDATE_ADD_IMAGE: 'أضِفْ صورة',
        PROJECTS_DIALOGS_UPDATE_ARCHIVE: 'حذف',
        PROJECTS_DIALOGS_UPDATE_SAVE: 'حفظ',
        PROJECTS_DIALOGS_UPDATE_CANCEL: 'إلغاء',
        VALIDATORS_END_DATE_REQUIRED: 'تاريخ الانتهاء واجب.' ,  
        VALIDATORS_END_DATE_BEFORE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء' ,
        VALIDATORS_INVALID_START_TIME: 'وقت البدء غير صحيح' ,
        VALIDATORS_INVALID_END_TIME: 'وقت الانتهاء غير صحيح' ,  
        VALIDATORS_END_TIME_BEFORE: 'يجب أن يكون وقت الانتهاء أكبر من وقت البدء' ,
    }
};
