import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../i18n/studio/projects/new-parent/en';
import { locale as frensh } from '../../../i18n/studio/projects/new-parent/fr';
import { locale as arabic } from '../../../i18n/studio/projects/new-parent/ar';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'projects-new-parent',
    templateUrl: './new-parent.component.html',
    styleUrls: ['./new-parent.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ProjectsNewParentComponent implements OnInit {
    isLinear = false;

    constructor(
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<ProjectsNewParentComponent>,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void { 
        
    }
}
