import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './reducer';
import { WorkshopActivitiesStoreEffects } from './effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('workshop-activities', reducer),
        EffectsModule.forFeature([WorkshopActivitiesStoreEffects]),
    ],
})
export class WorkshopActivitiesStoreModule { }
