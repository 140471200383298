import { SessionModel } from '@bsuccess/models/session/session.model';
import { ServiceVersionModel } from '@bsuccess/models/service/service-version.model';
import { ProjectModel } from '@bsuccess/models/project/project.model';

export interface State {
    current: SessionModel;
    error: string;
    stepperPosition: string;
    versions: ServiceVersionModel[];
    selectedProject: ProjectModel;
}

export const initialState: State = {
    current: {
        users: [],
        participants: [],
        guests: [],
        isPrivate: null,
    },
    error: null,
    stepperPosition: null,
    versions: [],
    selectedProject: null,
};
