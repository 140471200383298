import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { WorkshopCanvasStoreEffects } from './effects';
import { BsuccessModule } from '@bsuccess/bsuccess.module';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('workshop-canvas', reducer),
        EffectsModule.forFeature([WorkshopCanvasStoreEffects]),

        BsuccessModule
    ],
    providers: [],
})
export class WorkshopCanvasStoreModule { }
