export const locale = {
    lang: 'fr',
    data: {
        EDIT_SERVICES: 'Modifier les détails',
        FILTER: 'Filtrer',
        TYPE: 'Type',
        SERVICE_NAME: 'Nom du service',
        OWNER: 'Responsable',
        CREADTION_DATE: 'Date de création',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
    }
};
