export const locale = {
    lang: 'ar',
    data: {
        PROJECT_DIALOGS_DOCUMENT_EDIT_EDIT_DOCUMENT: 'تحرير وتيقة',
        PROJECT_DIALOGS_DOCUMENT_EDIT_TITLE_DOCUMENT: 'عنوان الوتيقة',
        PROJECT_DIALOGS_DOCUMENT_EDIT_REPORT: 'تقْرير',
        PROJECT_DIALOGS_DOCUMENT_EDIT_TYPE: 'النوع',
        PROJECT_DIALOGS_DOCUMENT_EDIT_PRESENTATION: 'عرْض',
        PROJECT_DIALOGS_DOCUMENT_EDIT_OTHER: 'آخر',
        PROJECT_DIALOGS_DOCUMENT_EDIT_SAVE: 'حفظ',
        PROJECT_DIALOGS_DOCUMENT_EDIT_CANCEL: 'إلغاء',
    }
};
