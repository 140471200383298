import { createAction, props } from '@ngrx/store';

import { ResultCommentModel } from '@bsuccess/models/cardboard/result-comment.model';
import { AttachmentModel } from '@bsuccess/models/attachment.model';
import { DimensionModel } from '@bsuccess/models/cardboard/dimension.model';
import { CardboardStateModel } from '@bsuccess/models/socket-io/cardboard-state.model';
import { UserModel } from '@bsuccess/models/user.model';
import { ResultRateModel } from '@bsuccess/models/cardboard/result-rate.model';
import { ResultGlobalChartModel } from '@bsuccess/models/cardboard/result-global-chart.model';
import { SessionItemModel } from '@bsuccess/models/session/session-item.model';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';
import { ItemArticleModel } from '@bsuccess/models/cardboard/item-article.model';

export const shuffle = createAction('[Workshop][Cardboard] Shuffle');

export const flipAll = createAction('[Workshop][Cardboard] Flip All');

export const flipBackAll = createAction('[Workshop][Cardboard] Flip Back All');

export const hideAll = createAction('[Workshop][Cardboard] Hide All');

export const showAll = createAction('[Workshop][Cardboard] Show All');

export const next = createAction('[Workshop][Cardboard] Next');

export const previous = createAction('[Workshop][Cardboard] Previous');

export const updateDimension = createAction(
    '[Workshop][Cardboard] Update Dimension',
    props<{ id: string }>()
);

export const selectArticle = createAction(
    '[Workshop][Cardboard] Select Article',
    props<{ id: string; rank: number }>()
);

export const updateChapter = createAction(
    '[Workshop][Cardboard] Update Chapter',
    props<{ id: string }>()
);

export const updateItem = createAction(
    '[Workshop][Cardboard] Update Item',
    props<{ id: string }>()
);

export const addComment = createAction(
    '[Workshop][Cardboard] Add Comment',
    props<{ comment: ResultCommentModel }>()
);

export const updateRate = createAction(
    '[Workshop][Cardboard] Update Rate',
    props<{ rates: ResultRateModel[] }>()
);

export const openAttachment = createAction(
    '[Workshop][Cardboard] Open Attachment',
    props<{ attachment: AttachmentModel }>()
);

export const loadCardboard = createAction('[Workshop][Cardboard Page] Load Cardboard');

export const loadCardboardSuccess = createAction(
    '[Workshop][Session API] Load Cardboard Success',
    props<{
        dimensions: DimensionModel[];
    }>()
);

export const loadCardboardFailure = createAction(
    '[Workshop][Session API] Load Cardboard Failure',
    props<{ error: string }>()
);

export const loadCardboardChart = createAction(
    '[Workshop][Cardboard Page] Load Cardboard Chart'
);

export const loadCardboardChartSuccess = createAction(
    '[Workshop][Session API] Load Cardboard Chart Success',
    props<{
        results: ResultGlobalChartModel;
    }>()
);

export const loadCardboardChartFailure = createAction(
    '[Workshop][Session API] Load Cardboard Chart Failure',
    props<{ error: string }>()
);

export const sendRate = createAction(
    '[Workshop][Cardboard] Send Rate',
    props<{ value: number }>()
);

export const receivedRate = createAction(
    '[Workshop][Cardboard] Received Rate',
    props<{ rate: number; user: UserModel }>()
);

export const sendComment = createAction(
    '[Workshop][Cardboard] Send Comment',
    props<{ value: string }>()
);

export const receivedComment = createAction(
    '[Workshop][Cardboard] Received Comment',
    props<{ comment: string; user: UserModel }>()
);

export const updateState = createAction(
    '[Workshop][Cardboard Page] Update State',
    props<{ newState: CardboardStateModel }>()
);

export const saveSessionItem = createAction(
    '[Workshop][Cardboard Page] Save Session Item',
    props<{ sessionItem: SessionItemModel }>()
);

export const saveSessionItemSuccess = createAction(
    '[Workshop][Session API] Save Session Item Success'
);

export const saveSessionItemFailure = createAction(
    '[Workshop][Session API] Save Session Item Failure',
    props<{ error: string }>()
);

export const openCard = createAction(
    '[Workshop][Cardboard Page] Open Card',
    props<{ description: string }>()
);

export const addActionPlan = createAction('[Workshop][Cardboard Page] Add Action Plan');

export const addActionPlanConfirmed = createAction(
    '[Workshop][Cardboard Page] Add Action Plan Confirmed',
    props<{ actionPlan: SessionTaskModel }>()
);

export const addActionPlanCancelled = createAction(
    '[Workshop][Cardboard Page] Add Action Plan Cancelled'
);

export const addActionPlanSuccess = createAction(
    '[Workshop][Cardboard API] Add Action Plan Success',
    props<{ actionPlan: SessionTaskModel }>()
);

export const addActionPlanFailure = createAction(
    '[Workshop][Cardboard API] Add Action Plan Failure',
    props<{ error: string }>()
);

export const flipCard = createAction(
    '[Workshop][Cardboard Page] Flip card',
    props<{ value: boolean; card: ItemArticleModel }>()
);

export const drop = createAction(
    '[Workshop][Cardboard Page] Drop card',
    props<{ event: any }>()
);

export const downloadReport = createAction(
    '[Workshop][Cardboard Page] Download Report'
);

export const downloadReportSuccess = createAction(
    '[Workshop][Cardboard Page] Download Report Success',
    props<{
        report: Blob;
    }>()
);

export const downloadReportFailure = createAction(
    '[Workshop][Cardboard Page] Download Report Failure',
    props<{ error: string }>()
);
