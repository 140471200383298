import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'participant-timer',
    templateUrl: './participant-timer.component.html',
    styleUrls: ['./participant-timer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ParticipantTimerComponent implements OnInit {
    @Input() time = 30;
    @Input() isActive = false;
    @Input() status = 'Timer stopped';
    @Output() finishTimer = new EventEmitter<any>();
    @Output() notifyTimer = new EventEmitter<any>();
    constructor() {}

    ngOnInit(): void {}

    rouNumber(time: number): number {
        return Math.round(time);
    }
}
