import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../i18n/access/dialogs/services-dialog/en';
import { locale as frensh } from '../../../i18n/access/dialogs/services-dialog/fr';
import { locale as arabic } from '../../../i18n/access/dialogs/services-dialog/ar';

@Component({
    selector: 'access-sign-up-policy-dialog',
    templateUrl: 'policy-dialog.component.html',
    styleUrls: ['policy-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccessSignUpPolicyDialogComponent {
    constructor(
        public translateService: TranslateService,
        public dialogRef: MatDialogRef<AccessSignUpPolicyDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }
}
