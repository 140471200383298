import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as NotesStoreActions from './actions';

const notesReducer = createReducer(
  initialState,
  on(NotesStoreActions.loadNotesSuccess, (state, { notes }) => ({
    ...state,
    notes,
    pending: false
  })),
  on(NotesStoreActions.addNoteSuccess, (state, { note }) => ({
    ...state,
    notes: [...state.notes, note],
    pending: false
  })),
  on(NotesStoreActions.updateNoteUser, (state, { noteId, user }) => ({
    ...state,
    notes: [
      ...state.notes.map(_ => {
        if (_._id === noteId) {
          _.user = user;
        }
        return _;
      }),
    ],
  })),
  on(NotesStoreActions.updateNoteSuccess, (state, { note }) => ({
    ...state,
    notes: [
      ...state.notes.map(_ => {
        if (_._id === note._id) {
          _ = {
            ..._,
            title: note.title,
            description: note.description,
            time: note.time,
          };
        }
        return _;
      }),
    ],
    pending: false
  })),
  on(NotesStoreActions.deleteNoteSuccess, (state, { id }) => ({
    ...state,
    notes: state.notes.filter(_ => _._id !== id),
    pending: false
  })),
  on(NotesStoreActions.downloadReportSuccess, state => ({
    ...state,
    pending: false
  })),
  on(
    NotesStoreActions.loadNotes,
    NotesStoreActions.deleteNoteConfirmed,
    NotesStoreActions.updateNoteConfirmed,
    NotesStoreActions.downloadReport,
    NotesStoreActions.addNoteConfirmed,
    state => ({
      ...state,
      pending: true
    })
  )
);

export function reducer(state: State | undefined, action: Action): any {
  return notesReducer(state, action);
}
