import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from 'environments/environment';

import { UserModel } from '@bsuccess/models/user.model';
import { ActionPermissionModel } from '@bsuccess/models/action-permission.model';
import { LocalStorageService } from './local-storage.service';
import { SessionModel } from '@bsuccess/models/session/session.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    jwtHelper: JwtHelperService;

    constructor(
        private _http: HttpClient,
        private _localStorageService: LocalStorageService
    ) {
        this.jwtHelper = new JwtHelperService();
    }

    login(credentials: { email: string; password: string; rememberMe: boolean; }, tenant: string): Observable<any> {
        // Set cache for the interceptor
        this._localStorageService.setCache({
            ...this._localStorageService.getCache(),
            login: {
                user: {
                    email: credentials.email,
                },
                tenant,
                token: null,
            },
        });
        return this._http.post(
            `${environment.http.url}${environment.http.login}`,
            {
                ...credentials,
                tenant
            }
        );
    }

    whoami(): Observable<UserModel> {
        return this._http.get<UserModel>(
            `${environment.http.url}${environment.http.whoami}`
        );
    }

    getAuthorizations(): Observable<ActionPermissionModel[]> {
        return this._http.get<ActionPermissionModel[]>(
            `${environment.http.url}${environment.http.authorizations}`
        );
    }

    isTokenExpired(token: string): boolean {
        try {
            return this.jwtHelper.isTokenExpired(token);
        } catch {
            return true;
        }
    }

    isUserReporter(session: SessionModel, user: UserModel): boolean {
        if (session.users.map(_ => _._id).includes(user._id)) {
            return true;
        }
        return false;
    }

    sendPasswordResetEmail(email: string): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.userResetPassword}/${email}`,
            {}
        );
    }
}
