export const locale = {
    lang: 'en',
    data: {
        ADD_ONS_DIALOG_PURCHASE_ADD: 'Purchase Add Ons' ,
        ADD_ONS_DIALOG_CHOOSE_ADD: 'Choose your Add On' ,
        ADD_ONS_DIALOG_CHOOSE_TITLE: '100 extra participants',
        ADD_ONS_DIALOG_CHOOSE_DESCRIPTION:'Invite up to 100 participants in a public workshop.',
        ADD_ONS_DIALOG_CHOOSE_YEAR: 'year' ,
        ADD_ONS_DIALOG_CHOOSE_MONTH: 'month' ,
        ADD_ONS_DIALOG_CHOOSE_BUY: 'BUY' ,
        ADD_ONS_DIALOG_CHOOSE_CHECKOUT: 'Checkout' ,
        ADD_ONS_DIALOG_CHOOSE_QUANTITY: 'Quantity' ,
        ADD_ONS_DIALOG_CHOOSE_METHODS: 'Payment Mehtods' ,
        ADD_ONS_DIALOG_CHOOSE_ORDER_SUMMARY: 'Order Summary' ,
        ADD_ONS_DIALOG_CHOOSE_TOTAL_AMOUNT: 'Total amount' ,
        ADD_ONS_DIALOG_CHOOSE_START_IMMEDIATELY: 'Starting immediately' ,
        ADD_ONS_DIALOG_CHOOSE_RENEWS_ON: 'Renews on:' ,
        ADD_ONS_DIALOG_CHOOSE_ACCEPT_CREDIT: 'We accept most credit and debit cards. All payments are secured and handled by Stripe.' ,
        ADD_ONS_DIALOG_CHOOSE_MORE_INFO:'More info about this.',
        ADD_ONS_DIALOG_CHOOSE_PURCHASE:'Purchase',
    }
};
