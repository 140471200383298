export const locale = {
    lang: 'ar',
    data: {
        COMPONENTS_TOOLBAR_HI: 'مرحبا،',
        COMPONENTS_TOOLBAR_ONLINE: 'متصل',
        COMPONENTS_TOOLBAR_PROFILE: 'الملف الشخصي',
        COMPONENTS_TOOLBAR_LANGUAGE: 'اللغة',
        COMPONENTS_TOOLBAR_MEMBERS: 'أفراد',
        COMPONENTS_TOOLBAR_LOGOUT: 'تسجيل خروج',
        COMPONENTS_TOOLBAR_PROJECTS: 'المشاريع',
        COMPONENTS_TOOLBAR_INVITE: 'دَعْوة',
        COMPONENTS_TOOLBAR_ADD_WIDGET: 'أضف ودجات',
        COMPONENTS_TOOLBAR_BOARDS: 'اللوحات',
        COMPONENTS_TOOLBAR_SESSIONS: 'الورشات',
        COMPONENTS_TOOLBAR_EDIT_PROJECT: 'تحرير تفاصيل المشروع',
        COMPONENTS_TOOLBAR_MANAGE: 'إدارة الأعضاء',
        COMPONENTS_TOOLBAR_EDIT_PROJECT_STRUCTURE: 'تحرير هيكل المشروع',
        COMPONENTS_TOOLBAR_ARCHIVE: 'حذف',
        COMPONENTS_TOOLBAR_EDIT_BOARD: 'إعدادات اللوحة',
        COMPONENTS_TOOLBAR_EDIT_BOARD_BACKGROUND: 'غير الخلفية',
        COMPONENTS_TOOLBAR_EDIT_SESSION: 'تحرير تفاصيل الورشة',
        COMPONENTS_TOOLBAR_MANAGE_PARTICIPANTS: 'إدارة المشاركين',
        COMPONENTS_TOOLBAR_TUTORIALS: 'دروس',
        COMPONENTS_TOOLBAR_CREATE_PROJECT_ONE: 'إنشاء مشروع',
        COMPONENTS_TOOLBAR_NAVIGATE_THE_PROJECT: 'تصفح المشروع',
        COMPONENTS_TOOLBAR_CREATE_SESSION_ONE: 'إنشاء ورشة',
        COMPONENTS_TOOLBAR_MANAGE_ACTIONS: 'إدارة الإجراءات بعد الورشة',
        COMPONENTS_TOOLBAR_MANAGE_WORK: 'إدارة عملك في اللوحة',
        COMPONENTS_TOOLBAR_CHAT_SUPPORT: 'الدردشة مع الدعم',
        COMPONENTS_TOOLBAR_ADMIN: 'المالِك',
        COMPONENTS_TOOLBAR_EDITOR: 'محرّر',
        COMPONENTS_TOOLBAR_VIEWER: 'مشاهد',
        COMPONENTS_TOOLBAR_HELP_CENTER: 'مركز المساعدة',
        COMPONENTS_TOOLBAR_TASK_TITLE: 'عنوان المهمة',
        COMPONENTS_TOOLBAR_PRIORITY: 'أفضلية',
        COMPONENTS_TOOLBAR_DATE: 'تاريخ استحقاق',
        COMPONENTS_TOOLBAR_KANBAN: 'كانبان',
        COMPONENTS_TOOLBAR_DASHBOARD: 'لوحة متابعة',
        COMPONENTS_TOOLBAR_LIST: 'قائمة',
        COMPONENTS_TOOLBAR_SAVE_AS_TEMPLATE: 'حفظ كنموذج',
        COMPONENTS_TOOLBAR_REMOVE_AS_TEMPLATE: 'إزالة من النماذج',
        COMPONENTS_TOOLBAR_BOARD_KEYWORD: '...أدخل كلمة رئيسية',
    }
};
