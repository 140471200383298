export const locale = {
    lang: 'en',
    data: {
        PRICING_DIALOG_UPDATE_ACCOUNT: 'Upgrade your account',
        PRICING_DIALOG_PAY_MONTHLY: 'Pay monthly',
        PRICING_DIALOG_PAY_YEARLY: 'Pay yearly',
        PRICING_DIALOG_SAVE_20:'Save 20%',
        PRICING_DIALOG_TEAM_HEADING:'Team',
        PRICING_DIALOG_TEAM_SUBTITLE:'For small teams that need to boost collaboration',
        PRICING_DIALOG_TEAM_PRICING_VALUE:'5',
        PRICING_DIALOG_TEAM_PRICING_CURRENCY:'€',
        PRICING_DIALOG_TEAM_PRICING_MONTH:'/mo',
        PRICING_DIALOG_TEAM_LIST_ITEM_ONE:'1 Project',
        PRICING_DIALOG_TEAM_LIST_ITEM_TWO:'10 members',
        PRICING_DIALOG_TEAM_LIST_ITEM_THREE:'Unlimited Workshops',
        PRICING_DIALOG_TEAM_LIST_ITEM_FOUR:'Unlimited Boards',
        PRICING_DIALOG_TEAM_LIST_ITEM_FIVE:'Library Templates',
        PRICING_DIALOG_TEAM_LIST_ITEM_SIX:'Large Meetings add-on (additional 50€ for 300 participants)',
        PRICING_DIALOG_TEAM_LIST_ITEM_SEVEN:'2 GB Storage',
        PRICING_DIALOG_TEAM_LIST_ITEM_EIGHT:'10 Participant in public workshop',
        PRICING_DIALOG_TEAM_BUTTON:'Upgrade',
        PRICING_DIALOG_BUSINESS_HEADING:'Business',
        PRICING_DIALOG_BUSINESS_SUBTITLE:'For companies that need to manage collaboration across teams',
        PRICING_DIALOG_BUSINESS_PRICING_VALUE:'50',
        PRICING_DIALOG_BUSINESS_PRICING_CURRENCY:'€',
        PRICING_DIALOG_BUSINESS_PRICING_MONTH:'/mo',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_ONE:'3 Project',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_TWO:'Unlimited members',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_THREE:'Unlimited Workshops',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_FOUR:'Unlimited Boards',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_FIVE:'Library Templates',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_SIX:'Large Meetings add-on (additional 50€ for 300 participants)',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_SEVEN:'10 GB Storage',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_EIGHT:'10 Participant in public workshop',
        PRICING_DIALOG_BUSINESS_BUTTON:'Upgrade',
        PRICING_DIALOG_ENTREPRISE_HEADING:'Entreprise',
        PRICING_DIALOG_ENTREPRISE_SUBTITLE:'For organizations that need to transform at scale and have special requirements',
        PRICING_DIALOG_ENTREPRISE_CUSTOM_PRICING:'Custom pricing',
        PRICING_DIALOG_ENTREPRISE_PRICING_CURRENCY:'€',
        PRICING_DIALOG_ENTREPRISE_PRICING_MONTH:'/mo',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_ONE:'Unlimited access to all features',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_TWO:'Unique Single Sign-On (SAML / oAuth2)',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_THREE:'Dedicated manager ',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_FOUR:'Advanced security',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_FIVE:'Managed domain',
        PRICING_DIALOG_ENTREPRISE_BUTTON:'Contact Sales',
        PRICING_DIALOG_NOTICE:'Prices shown are exclusive of any applicable sales taxes such as VAT.',
        PRICING_DIALOG_TEAM_BUTTON_SELECTED: 'Current',
        PRICING_DIALOG_TEAM_BUTTON_CHOOSE: 'Choose',
        PRICING_DIALOG_PLAN_METADATA_ONE_NAME : 'Team',
        PRICING_DIALOG_PLAN_METADATA_ONE_PLAN_DESCRIPTION : 'For small teams that need to boost collaboration',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_ONE : '1 project',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_TWO : '10 members',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_THREE : '25 GB Storage',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_FOUR : 'Unlimited Workshops',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_FIVE : 'Unlimited Boards',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_SIX : 'Library Templates',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_SEVEN : 'Large Meetings add-on (additional 50€ for 100 participants)',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_EIGHT : 'Up to 10 free participants in public workshops',
        PRICING_DIALOG_PLAN_METADATA_TWO_NAME : 'Business',
        PRICING_DIALOG_PLAN_METADATA_TWO_PLAN_DESCRIPTION : 'For companies that need to manage collaboration across teams',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_ONE : '3 projects',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_TWO : 'Unlimited members',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_THREE : '25 GB Storage',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_FOUR : 'Unlimited Workshops',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_FIVE : 'Unlimited Boards',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_SIX : 'Library Templates',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_SEVEN : 'Large Meetings add-on (additional 50€ for 100 participants)',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_EIGHT : 'Up to 10 free participants in public workshops',
    }
};
