import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import { StudioNotificationsStoreActions } from '.';

const notificationsReducer = createReducer(
  initialState,
  on(StudioNotificationsStoreActions.loadSuccess, (state, { notifications }) => ({
    ...state,
    notifications: notifications,
    notificationsCount: notifications.length,
  })),
  on(StudioNotificationsStoreActions.updateIsReadSuccess, (state, { _id }) => ({
    ...state,
    current: {
      ...state,
      isRead: true,
    },
    notifications: [
        ...state.notifications.map(notification => {
            if (notification._id === _id) {
                notification.isRead = true;
            }
            return notification;
        }),
    ]
  })),
  on(StudioNotificationsStoreActions.updateStatusSuccess, (state, { id , status }) => ({
    ...state,
    current: {
      ...state,
      status: status,
    },
    notifications: [
        ...state.notifications.filter(
            _ => _._id !== id
        ),
    ],
    notificationsCount: state.notificationsCount - 1
  }))
);

export function reducer(state: State | undefined, action: Action): any {
  return notificationsReducer(state, action);
}
