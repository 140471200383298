export const locale = {
    lang: 'ar',
    data: {
        PROJECTS_DIALOGS_PROJECT_SUMMARY_YOUR: '',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_SUMMARY: '',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DAILY: 'الملخص اليومي',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_WEEKLY: 'الملخص الأسبوعي',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_COMPLETED_TASKS: 'المهام المكتملة',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_IN_PROGRESS: 'المهام قيد الانجاز',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_LATE: 'المهام المتأخرة',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DUE_SOON: 'المهام المستحقة قريبًا',
        PROJECTS_DIALOGS_FILTER:'فَلْتَرَة',
        PROJECTS_DIALOGS_CH_ONE : 'اليوم',
        PROJECTS_DIALOGS_CH_TWO : 'غدا',
        PROJECTS_DIALOGS_CH_THREE : 'الأمس',
        PROJECTS_DIALOGS_CH_FOUR : 'السبعة أيام القادمة',
        PROJECTS_DIALOGS_CH_FIVE : 'السبعة أيام الماضية',
        PROJECTS_DIALOGS_CH_SIX : 'البارحة',
        PROJECTS_DIALOGS_RESET : 'فلترة جديدة',
        PROJECTS_DIALOGS_RESPONSABLE:'المسؤول',
        PROJECTS_DIALOGS_DATE_LIMITE:'تاريخ الاستحقاق',
        PROJECTS_DIALOGS_PROJECTS:'المشاريع',
    }
};
