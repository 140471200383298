import { Injectable } from '@angular/core';
import { NoteModel } from '@bsuccess/models/project/note.model';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import initHelpHero, { HelpHero } from 'helphero';

@Injectable({
    providedIn: 'root',
})
export class HelpHeroService {
    public static helphero: HelpHero;
    public static lang: string;
    constructor() { }

    public static init(user: any): void {
        this.helphero = initHelpHero('eqFQG7EgmXZ');
        this.helphero.identify(user._id, {
            language: user.lang
        });
    }

    public static open(): void {
        this.helphero.startTour('675TZV61fqt');
    }

    public static close(): void {
        this.helphero.openChecklist();
    }

    public static startTourById(tourid): void {
        this.helphero.startTour(tourid, { redirectIfNeeded: true });
    }
}
