export const locale = {
    lang: 'en',
    data: {
        NOTES_PANEL_NOTES:'Notes', 
        NOTES_PANEL_TOTAL:'in total',
        NOTES_PANEL_ADD_NOTE:'Add a note',
        NOTES_PANEL_EDIT:'Edit',
        NOTES_PANEL_DELETE: 'Delete',
        NOTES_PANEL_NOTES_TITILE:'Your note\'s title',
    }
};
