export const locale = {
    lang: 'ar',
    data: {
        ADD_ONS_DIALOG_PURCHASE_ADD: 'شراء إضافات' ,
        ADD_ONS_DIALOG_CHOOSE_ADD: 'اختر إضافتك' ,
        ADD_ONS_DIALOG_CHOOSE_TITLE: '100 مشارك إضافي',
        ADD_ONS_DIALOG_CHOOSE_DESCRIPTION:'استضف حتى 100 مشارك في ورشة عمل عامة.',
        ADD_ONS_DIALOG_CHOOSE_YEAR: 'عام',
        ADD_ONS_DIALOG_CHOOSE_MONTH: 'شهر' ,
        ADD_ONS_DIALOG_CHOOSE_BUY: 'شراء' ,
        ADD_ONS_DIALOG_CHOOSE_CHECKOUT: 'الدفع' ,
        ADD_ONS_DIALOG_CHOOSE_QUANTITY: 'الكمية' ,
        ADD_ONS_DIALOG_CHOOSE_METHODS: 'طرق الدفع' ,
        ADD_ONS_DIALOG_CHOOSE_ORDER_SUMMARY: 'ملخص الطلب' ,
        ADD_ONS_DIALOG_CHOOSE_TOTAL_AMOUNT: 'المبلغ الإجمالي' ,
        ADD_ONS_DIALOG_CHOOSE_START_IMMEDIATELY: 'تبدأ على الفور' ,
        ADD_ONS_DIALOG_CHOOSE_RENEWS_ON: 'يتجدد في:' ,
        ADD_ONS_DIALOG_CHOOSE_ACCEPT_CREDIT: 'نحن نقبل معظم بطاقات الائتمان والخصم. يتم تأمين جميع المدفوعات ومعالجتها بواسطة Stripe.' ,
        ADD_ONS_DIALOG_CHOOSE_MORE_INFO:'مزيد من المعلومات حول هذا.',
        ADD_ONS_DIALOG_CHOOSE_PURCHASE:'شراء',
    }
};
