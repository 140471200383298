import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../../../i18n/studio/projects/session/dialogs/comment-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/session/dialogs/comment-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/session/dialogs/comment-dialog/ar';

@Component({
    selector: 'comment-dialog',
    templateUrl: './comment-dialog.component.html',
    styleUrls: ['./comment-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SessionCommentDialogComponent implements OnInit {
    description = '';
    constructor(
        public matDialogRef: MatDialogRef<SessionCommentDialogComponent>, 
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) { 
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        this.matDialogRef.disableClose = true;
    }

    ngOnInit(): void { }
}
