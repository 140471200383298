import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { exhaustMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import * as ProjectActions from './actions';
import { StudioDetailStoreActions } from '.';
import { ProjectService } from '@bsuccess/services/project.service';
import { DetailNewDialogComponent } from 'app/studio/projects/project/dialogs/detail-new-dialog/detail-new-dialog.component';
import { DetailUpdateDialogComponent } from 'app/studio/projects/project/dialogs/detail-update-dialog/detail-update-dialog.component';
import { StudioProjectStoreSelectors } from '../project-store';
import { locale as english } from '../../../i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../i18n/@fuse/components/confirm-dialog/fr';

@Injectable()
export class StudioDetailStoreEffects {
    confirmDelete: string;
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _projectService: ProjectService,
        private _matDialog: MatDialog,
        private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh);

        this.translate.stream('CONFIRM_DELETE_DETAIL').subscribe(
            value => { this.confirmDelete = value; });
    }

    addDetail$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.addDetail),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    DetailNewDialogComponent,
                    {
                        panelClass: 'detail-new-dialog',
                    }
                );
                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return ProjectActions.confirmAddDetail({
                                detail: confirmed,
                            });
                        } else {
                            return ProjectActions.cancelAddDetail();
                        }
                    })
                );
            })
        )
    );

    confirmAddDetail$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.confirmAddDetail),
            withLatestFrom(
                this._store.select(StudioProjectStoreSelectors.selectCurrent)
            ),
            exhaustMap(([action, project]) =>
                this._projectService.addDetail(project._id, action.detail).pipe(
                    map((response: any) =>
                        ProjectActions.addDetailSuccess({
                            detail: {
                                ...action.detail,
                                _id: response._id
                            },
                        })
                    ),
                    catchError(error =>
                        of(
                            ProjectActions.addDetailFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateDetail$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.updateDetail),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    DetailUpdateDialogComponent,
                    {
                        panelClass: 'detail-update-dialog',
                        data: action.detail,
                    }
                );
                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return ProjectActions.confirmUpdateDetail({
                                detail: confirmed,
                            });
                        } else {
                            return ProjectActions.cancelUpdateDetail();
                        }
                    })
                );
            })
        )
    );
    confirmUpdateDetail$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.confirmUpdateDetail),
            withLatestFrom(
                this._store.select(StudioProjectStoreSelectors.selectCurrent)
            ),
            exhaustMap(([action, project]) =>
                this._projectService
                    .updateDetail(action.detail._id, action.detail, project._id)
                    .pipe(
                        map((response: any) =>
                            ProjectActions.updateDetailSuccess({
                                detail: action.detail,
                            })
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.updateDetailFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteDetail$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StudioDetailStoreActions.deleteDetail),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        panelClass: 'fuse-confirm-dialog',
                        disableClose: false,
                    }
                );

                matDialogRef.componentInstance.confirmMessage = this.confirmDelete;

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return StudioDetailStoreActions.confirmDeleteDetail({
                                id: action.id,
                            });
                        } else {
                            return StudioDetailStoreActions.cancelDeleteDetail();
                        }
                    })
                );
            })
        )
    );

    confirmDeleteDetail$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StudioDetailStoreActions.confirmDeleteDetail),
            withLatestFrom(this._store.select(StudioProjectStoreSelectors.selectCurrent)),
            exhaustMap(([action, project]) => {
                return this._projectService.removeDetail(action.id, project._id).pipe(
                    map(response =>
                        StudioDetailStoreActions.deleteDetailSuccess({
                            id: response._id,
                        })
                    ),
                    catchError(error =>
                        of(
                            StudioDetailStoreActions.deleteDetailFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );
}
