export const locale = {
    lang: 'en',
    data: {
        ASSESSEMENT: 'Start voting',
        ALL_CATEGORIES: 'All colors',
        CARD_CATEGORIES: 'Colors',
        BUDGET_POINTS: 'Votes per participant',
        MAX: 'Max votes per card',
        START: 'Start voting',
        CANCEL: 'Cancel',
    }
};
