export const locale = {
        lang: 'fr',
        data: {
                WRITE: 'Saisir un titre d\'action . ', 
                PLACEHOLDER_COMMENT:' Laisser un commentaire...',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_ITEM: 'Ajouter un élément',
                PROJECTS_BOARD_DIALOGS_CARD_REMOVE_DATE: 'Retirer la Date d\'Echéance',
                PROJECTS_BOARD_DIALOGS_CARD_CHECKLIST_TITLE: 'Titre de checklist',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_CHECKLIST: 'Ajouter une checklist',
                PROJECTS_BOARD_DIALOGS_CARD_UNSUBSCRIBE: 'Se désabonner',
                PROJECTS_BOARD_DIALOGS_CARD_SUBSCRIBE: 'S\'abonner',
                PROJECTS_BOARD_DIALOGS_CARD_DATE: 'Dates',
                PROJECTS_BOARD_DIALOGS_CARD_DELETE_CARD: 'Supprimer l\'action',
                PROJECTS_BOARD_DIALOGS_CARD_DUPLICATE_CARD: 'Dupliquer l\'action',
                PROJECTS_BOARD_DIALOGS_CARD_TITLE: 'Titre',
                PROJECTS_BOARD_DIALOGS_CARD_DESCRIPTION: 'Description',
                PROJECTS_BOARD_DIALOGS_CARD_DUE_DATE: 'Date d\'Echéance',
                PROJECTS_BOARD_DIALOGS_CARD_RESPONSIBLE: 'Responsable',
                PROJECTS_BOARD_DIALOGS_CARD_CATEGORIES: 'Étiquettes',
                PROJECTS_BOARD_DIALOGS_CARD_MEMBERS: 'Membres',
                PROJECTS_BOARD_DIALOGS_CARD_ATTACHMENTS: 'Pièces Jointes',
                PROJECTS_BOARD_DIALOGS_CARD_REMOVE_ATTACHMENT: 'Retirer la pièce jointe',
                PROJECTS_BOARD_DIALOGS_CARD_DOWNLOAD_ATTACHMENT: 'Télécharger la pièce jointe',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_ATTACHMENT: 'Charger un fichier',
                PROJECTS_BOARD_DIALOGS_CARD_REMOVE_CHECKLIST: 'Retirer la checklist',
                PROJECTS_BOARD_DIALOGS_CARD_CHANGE_NAME_CHECKLIST: 'Modifier le nom',
                PROJECTS_BOARD_DIALOGS_CARD_COMMENTS: 'Commentaires',
                PROJECTS_BOARD_DIALOGS_CARD_CLOSE: ' Fermer',
                PROJECTS_BOARD_DIALOGS_SECTION: 'Section',
                PROJECTS_BOARD_DIALOGS_CARD_DELETE: 'Supprimer',
                PROJECTS_BOARD_DIALOGS_CARD_CONFIRM_DELETE: 'Êtes-vous surs de vouloir supprimer cette action?',
                PROJECTS_BOARD_DIALOGS_CARD_CONFIRM_REMOVE_ATTACHMENT: 'Etes-vous sur de vouloir supprimer cette pièce-jointe? ',
                PROJECTS_BOARD_DIALOGS_CARD_CONFIRM_DELETE_FROM_BOARD: 'Etes vous surs de vouloir supprimer l\'action de cet tableau?',
                PROJECTS_BOARD_DIALOGS_CARD_ADD_TO_CARD_LABEL: 'Ajouter à l\'action',
                PROJECTS_BOARD_DIALOGS_CARD_LABEL_LABEL: 'Étiquettes',
                PROJECTS_BOARD_DIALOGS_CARD_MEMBERS_LABEL: 'Membres',
                PROJECTS_BOARD_DIALOGS_CARD_DUPLICATE_CARD_LABEL: 'Dupliquer l\'action',
                PROJECTS_BOARD_DIALOGS_CARD_DELETE_CARD_LABEL: 'Supprimer l\'action',
                PROJECTS_BOARD_DIALOGS_CARD_ATTACHMENT_LABEL: 'Pièces jointes',
                PROJECTS_BOARD_DIALOGS_CARD_CARD_TITLE_LABEL: 'Consulter l\'action',
                PROJECTS_BOARD_DIALOGS_CARD_CARD_BUTTON_MARK_COMPLETE_TEXT:'Marquer terminée',
                PROJECTS_BOARD_DIALOGS_CARD_CARD_BUTTON_COMPLETED_TEXT:'Terminée',
                PROJECTS_BOARD_DIALOGS_CARD_CHECKLIST: 'Checkliste',
                PROJECTS_BOARD_DIALOGS_START_DATE: 'Date de début',
                VALIDATORS_END_DATE_BEFORE: 'La date de début est postérieure à la date de fin' ,
                PROJECTS_BOARD_DIALOGS_END_DATE: 'Date d\'échéance',
                PROJECTS_BOARD_DIALOGS_PRIORITY: 'Priorité',
                PROJECTS_BOARD_DIALOGS_ACTIVITIES: 'Historique',
                PROJECTS_BOARD_DIALOGS_CARD_CHOOSE_COVER: 'Choisissez l\'image comme couverture',
                PROJECTS_BOARD_LINK_BOARD_BUTTON: 'Lier à un tableau',
                PROJECTS_BOARD_LINK: 'Lier',
                PROJECTS_BOARD_LINK_REMOVE_BOARD_BUTTON: 'Retirer le lien',
                PROJECTS_BOARD_MOVE_BOARD_BUTTON: 'Déplacer vers tableau',
                PROJECTS_BOARD_MOVE: 'Déplacer',
                PROJECTS_BOARD_LINK_INFO_BAR: 'Cette action est liée au tableau ',
                PROJECTS_BOARD_LINK_BOARD_SUBMIT_BUTTON: 'Lier au tableau',
                PROJECTS_BOARD_INCLUDE_BOARD_CHECK_BUTTON: 'Inclure les tableaux des autres projets',
                PROJECTS_BOARD_DIALOGS_BOARDS: 'Tableaux',
                PROJECTS_BOARD_DIALOGS_ADD_BOARD: 'Ajouter un tableau',
                PROJECTS_BOARD_DIALOGS_ADD_OPTION: '+ Ajouter une option',
                PROJECTS_BOARD_DIALOGS_CARD_SHOW_ACTIVITIES: 'Afficher l\'historique',
                PROJECTS_BOARD_DIALOGS_CARD_HIDE_ACTIVITIES: 'Cacher l\'historique',
                PROJECTS_BOARD_DIALOGS_ADD: 'Ajouter',
                PROJECTS_BOARD_DIALOGS_NAME_BOARD: 'Nom du tableau',
                PROJECTS_BOARD_DIALOGS_CANCEL: 'Annuler',
        }
};
