import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Socket } from 'ngx-socket-io';
import { withLatestFrom, map } from 'rxjs/operators';

import * as SocketIOActions from '../actions';
import { Card } from '@bsuccess/models/brainstorming/card.model';
import { UserModel } from '@bsuccess/models/user.model';
import { RootStoreState } from 'app/root-store';
import { WorkshopBrainstormingStoreActions, WorkshopBrainstormingStoreSelectors } from 'app/root-store/workshop-store/brainstorming-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { WorkshopCardRatingStoreActions } from 'app/root-store/workshop-store/cardrating-store';
import { WorkshopTimerStoreSelectors } from '../../timer-store';
import { WorkshopSocketIOStoreSelectors } from '..';
import { StudioSessionStoreActions } from 'app/root-store/studio-store/session-store';

@Injectable()
export class WorkshopSocketIOStoreBrainstormingEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _socket: Socket
    ) { }

    // // Catch all animator brainstorming success actions and emit them
    // animatorBrainstorming$ = createEffect(
    //     () =>
    //         this._actions$.pipe(
    //             ofType(
    //                 WorkshopBrainstormingStoreActions.loadBoardSuccess,
    //                 WorkshopBrainstormingStoreActions.renameBoard,
    //                 WorkshopBrainstormingStoreActions.removeCardSuccess,
    //                 WorkshopBrainstormingStoreActions.moveCardSuccess,
    //                 WorkshopBrainstormingStoreActions.addListSuccess,
    //                 WorkshopBrainstormingStoreActions.removeListSuccess,
    //                 WorkshopBrainstormingStoreActions.moveListSuccess,
    //                 WorkshopBrainstormingStoreActions.cardColorChangeSuccess,
    //                 WorkshopBrainstormingStoreActions.cardNameChangeSuccess,
    //                 WorkshopBrainstormingStoreActions.listNameChangeSuccess,
    //                 WorkshopBrainstormingStoreActions.duplicateCardSuccess,
    //                 WorkshopBrainstormingStoreActions.updateLbelsSuccess,
    //                 WorkshopBrainstormingStoreActions.toggleLabelSuccess,
    //                 WorkshopBrainstormingStoreActions.refreshBoard,
    //             ),
    //             withLatestFrom(
    //                 this._store.pipe(
    //                     select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
    //                 )
    //             ),
    //             withLatestFrom(
    //                 this._store.pipe(
    //                     select(
    //                         WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
    //                     )
    //                 )
    //             ),
    //             withLatestFrom(
    //                 this._store.pipe(
    //                     select(
    //                         WorkshopTimerStoreSelectors.selectTimerState
    //                     )
    //                 )
    //             ),
    //             withLatestFrom(
    //                 this._store.pipe(
    //                     select(
    //                         LoginStoreSelectors.selectSessionCategories
    //                     )
    //                 )
    //             ),
    //             map(([[[[_, [sessionRole, sessionKey]], board], timer], categories]) => {
    //                 if (sessionRole === 'animator') {
    //                     this._store.dispatch(
    //                         SocketIOActions.updateRoom({
    //                             payload: {
    //                                 sessionKey,
    //                                 route: '/workshop/brainstorming' + '/' + board.id,
    //                                 state: board,
    //                                 contentId: board.id,
    //                                 categories
    //                             },
    //                         })
    //                     );
    //                 }
    //             })
    //         ),
    //     { dispatch: false }
    // );

    addCard$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.addCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopTimerStoreSelectors.selectTimerState
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopSocketIOStoreSelectors.selectContentId)
                    )
                ),
                map(([[[[_, [sessionRole, sessionKey]], board], timer], contentId]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.addCard({
                                payload: {
                                    contentId,
                                    sessionKey,
                                    id: board.id,
                                    card: _.card,
                                    listId: _.listId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorReceiveBrainstormingRates$ = this._socket
        .fromEvent<{ sessionKey: string; cards: Card[]; user: UserModel }>(
            SocketIOActions.sendBrainstormingRates.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([payload, role]: [any, string]) => {
            if (role === 'animator') {
                this._store.dispatch(
                    WorkshopCardRatingStoreActions.receiveAllBrainstormingRates({
                        payload,
                    })
                );
            }
        });

    animatorReceiveBrainstormingCard$ = this._socket
        .fromEvent<{ card: Card; listId: string }>(
            SocketIOActions.sendBrainstormingCard.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([payload, sessionRole]) => {
            if (sessionRole === 'animator') {
                this._store.dispatch(
                    WorkshopBrainstormingStoreActions.addCard({
                        listId: payload.listId,
                        card: payload.card,
                    })
                );
            }
        });

    animatorUpdateBalance$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.updateBalance),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopSocketIOStoreSelectors.selectContentId)
                    )
                ),
                map(([[action, [sessionRole, sessionKey]], contentId]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateBrainstormingBalance({
                                payload: {
                                    sessionKey,
                                    balance: action.balance,
                                    contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorUpdateMaxPerCard$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.updateMaxPerCard),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopSocketIOStoreSelectors.selectContentId)
                    )
                ),
                map(([[action, [sessionRole, sessionKey]], contentId]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateBrainstormingMaxPerCard({
                                payload: {
                                    sessionKey,
                                    max: action.max,
                                    contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );


    participantUpdateBalance$ = this._socket
        .fromEvent<{ sessionKey: string; balance: number }>(
            SocketIOActions.updateBrainstormingBalance.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([cardrating, sessionRole]) => {
            if (sessionRole === 'participant') {
                this._store.dispatch(
                    WorkshopCardRatingStoreActions.updateBalance({
                        balance: cardrating.balance,
                    })
                );
            }
        });

    participantUpdateMaxPerCard$ = this._socket
        .fromEvent<{ sessionKey: string; max: number }>(
            SocketIOActions.updateBrainstormingMaxPerCard.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([cardrating, sessionRole]) => {
            if (sessionRole === 'participant') {
                this._store.dispatch(
                    WorkshopCardRatingStoreActions.updateMaxPerCard({
                        max: cardrating.max,
                    })
                );
            }
        });

    emit$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    SocketIOActions.sendBrainstormingCard,
                    SocketIOActions.sendBrainstormingRates,
                    SocketIOActions.updateBrainstormingBalance,
                    SocketIOActions.updateBrainstormingMaxPerCard,
                ),
                map(action => {
                    this._socket.emit(action.type, action.payload);
                })
            ),
        { dispatch: false }
    );

    // Add Card
    addCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.addCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.addCardSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    card: _.card,
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.addCardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.addCardViaSocket({
                    card: _.card,
                    listId: _.listId
                })
            );
        });

    // Remove Card
    removeCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.removeCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.removeCardSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.removeCardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketRemoveCard({
                    listId: _.listId,
                    cardId: _.cardId
                })
            );
        });

    // Move Card
    moveCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.moveCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.moveCardSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    move: _.move,
                                    lists: _.lists
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    moveCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.moveCardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketMoveCard({
                    lists: _.lists,
                    move: _.move
                })
            );
        });

    // -------------------------------------------
    // Add List
    addListSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.addListSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.addListSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    list: _.list
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addListSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.addListSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketAddList({
                    list: _.list,
                })
            );
        });

    // Remove List
    removeListSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.removeListSuccess
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.removeListSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeListSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.removeListSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketRemoveList({
                    listId: _.listId
                })
            );
        });

    // Move List
    moveListSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.moveListSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.moveListSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    lists: _.lists,
                                    move: _.move
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    moveListSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.moveListSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketMoveList({
                    lists: _.lists,
                    move: _.move
                })
            );
        });

    // List Name Change
    listNameChangeSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.listNameChangeSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.listNameChangeSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    listId: _.listId,
                                    name: _.name
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    listNameChangeSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.listNameChangeSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketListNameChange({
                    listId: _.listId,
                    name: _.name
                })
            );
        });

    // Card Name Change
    cardNameChangeSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.cardNameChangeSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.cardNameChangeSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    name: _.name
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    cardNameChangeSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.cardNameChangeSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketCardNameChange({
                    name: _.name,
                    cardId: _.cardId
                })
            );
        });

    // Card Name Change
    cardColorChangeSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.cardColorChangeSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.cardColorChangeSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    color: _.color,
                                    typeCard: _.typeCard
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    cardColorChangeSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.cardColorChangeSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketCardColorChange({
                    color: _.color,
                    typeCard: _.typeCard,
                    cardId: _.cardId
                })
            );
        });

    // Card Description Change
    updateCardDescriptionSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.updateCardDescriptionSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.updateCardDescriptionSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    description: _.description
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateCardDescriptionSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.updateCardDescriptionSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketUpdateCardDescription({
                    description: _.description,
                    cardId: _.cardId
                })
            );
        });

    // Card Date Change
    updateCardDateSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.updateCardDateSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.updateCardDateSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    due: _.due
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateCardDateSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.updateCardDateSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketUpdateCardDate({
                    due: _.due,
                    cardId: _.cardId
                })
            );
        });

    // Card Add Comment
    addCardCommentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.addCardCommentSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.addCardCommentSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    comment: _.comment
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addCardCommentSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.addCardCommentSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketAddCardComment({
                    cardId: _.cardId,
                    comment: _.comment
                })
            );
        });

    // Card Add Comment
    deleteCommentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.deleteCommentSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.deleteCommentSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    commentId: _.commentId,
                                    id: _.id
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    deleteCommentSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.deleteCommentSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketDeleteComment({
                    cardId: _.cardId,
                    commentId: _.commentId,
                    id: _.id
                })
            );
        });

    // Card Add CheckList
    addChecklistSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.addChecklistSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.addChecklistSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    checklist: _.checklist
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addChecklistSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.addChecklistSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketAddChecklist({
                    cardId: _.cardId,
                    checklist: _.checklist
                })
            );
        });

    // Card Add CheckItem
    addcheckItemSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.addcheckItemSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.addcheckItemSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    checkItemForm: _.checkItemForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addcheckItemSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.addcheckItemSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketAddcheckItem({
                    checkItemForm: _.checkItemForm
                })
            );
        });

    // Card Remove CheckList
    removeCheckListSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.removeCheckListSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.removeCheckListSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    checkListForm: _.checkListForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeCheckListSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.removeCheckListSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketRemoveCheckList({
                    checkListForm: _.checkListForm
                })
            );
        });

    // Card Remove CheckItem
    removeCheckItemSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.removeCheckItemSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.removeCheckItemSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    checkListForm: _.checkListForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeCheckItemSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.removeCheckItemSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketRemoveCheckItem({
                    checkListForm: _.checkListForm
                })
            );
        });

    // Card Update CheckItem
    updateCheckItemSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.updateCheckItemSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.updateCheckItemSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    checkItemForm: _.checkItemForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateCheckItemSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.updateCheckItemSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketUpdateCheckItem({
                    checkItemForm: _.checkItemForm
                })
            );
        });

    // Card Toggle Label
    toggleLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.toggleLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.toggleLabelSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    toggleForm: _.toggleForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    toggleLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.toggleLabelSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketToggleLabel({
                    toggleForm: _.toggleForm
                })
            );
        });


    // Card Remove Label
    removeLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.removeLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.removeLabelSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    labelId: _.labelId,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.removeLabelSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketRemoveLabel({
                    labelId: _.labelId,
                })
            );
        });

    // Card Add Label
    addLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.addLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.addLabelSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    id: _.id,
                                    name: _.name,
                                    color: _.color,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.addLabelSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketAddLabel({
                    color: _.color,
                    name: _.name,
                    id: _.id
                })
            );
        });

    // Card Update Label
    updateLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.updateLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.updateLabelSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    color: _.color,
                                    name: _.name,
                                    id: _.id
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.updateLabelSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketUpdateLabel({
                    color: _.color,
                    name: _.name,
                    id: _.id
                })
            );
        });

    // Card Rating 
    cardRatingSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.cardRatingSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.cardRatingSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    rate: _.rate,
                                    user: _.user,
                                    cardId: _.cardId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    cardRatingSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.cardRatingSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketcardRating({
                    rate: _.rate,
                    user: _.user,
                    cardId: _.cardId
                })
            );
        });

    // Update Timer 
    updateTimerSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.updateTimerSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.updateTimerSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    timer: _.timer
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateTimerSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.updateTimerSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketUpdateTimer({
                    timer: _.timer
                })
            );
        });

    // Update Timer 
    duplicateCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.duplicateCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.duplicateCardSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    duplicate: _.duplicate,
                                    card: _.card
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    duplicateCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.duplicateCardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketDuplicateCard({
                    card: _.card,
                    duplicate: _.duplicate
                })
            );
        });

    // Sort Board
    sortSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.sortSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.sortSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    lists: _.lists
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    sortSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.sortSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketSort({
                    lists: _.lists
                })
            );
        });

    // Cards to column
    saveCardsToColumnsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.saveCardsToColumnsSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.cardsToColumnSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    lists: _.lists,
                                    boardId: _.boardId,
                                    activity: _.activity
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    cardsToColumnSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.cardsToColumnSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
        )
        .subscribe(([[_, sessionRole], board]) => {
            if (board.id === _.boardId) {
                this._store.dispatch(
                    WorkshopBrainstormingStoreActions.saveCardsToColumnsViaSocket({
                        lists: _.lists,
                        boardId: _.boardId,
                        activity: _.activity
                    })
                );
            }

        });

    // Update WorkFlowStep
    updateWorkFlowStepSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.updateWorkFlowStepSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.updateWorkFlowStepSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    step: _.step,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateWorkFlowStepSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.updateWorkFlowStepSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
        )
        .subscribe(([[_, sessionRole], board]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.socketUpdateWorkFlowStep({
                    step: _.step,
                })
            );
        });

    // Add Action Plan
    addActionPlanSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.addActionPlanSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    this._store.dispatch(
                        SocketIOActions.addActionPlanSuccess({
                            payload: {
                                sessionKey,
                                data: {
                                    actionPlan: _.actionPlan,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addActionPlanSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.addActionPlanSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
        )
        .subscribe(([[_, sessionRole], board]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.addActionPlanSuccessViaSocket({
                    actionPlan: _.actionPlan,
                })
            );
        });

    // Update Action Plan
    updateSessionActionPlanSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioSessionStoreActions.updateSessionActionPlanSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    this._store.dispatch(
                        SocketIOActions.updateActionPlanSuccess({
                            payload: {
                                sessionKey,
                                data: {
                                    actionPlan: _.actionPlan,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateActionPlanSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.updateActionPlanSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                StudioSessionStoreActions.updateSessionActionPlanSuccessViaSocket({
                    actionPlan: _.actionPlan,
                })
            );
        });

    // Remove Action Plan
    updateRemoveSessionTaskSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioSessionStoreActions.updateRemoveSessionTaskSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    this._store.dispatch(
                        SocketIOActions.removeActionPlanSuccess({
                            payload: {
                                sessionKey,
                                data: {
                                    taskId: _.taskId,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeActionPlanSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.removeActionPlanSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                StudioSessionStoreActions.updateRemoveSessionTaskSuccessViaSocket({
                    taskId: _.taskId,
                })
            );
        });

    // Remove Action Plan
    importCardsToBoardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.importCardsToBoardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.importCardsToBoardSuccess({
                            payload: {
                                sessionKey,
                                contentId: board.id,
                                data: {
                                    response: _.response,
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    importCardsToBoardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.importCardsToBoardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.importCardsToBoardViaSocket({
                    response: _.response,
                    listId: _.listId
                })
            );
        });


    updateDisplayLabels$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.updateDisplayLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.showLabelsSuccess({
                            payload: {
                                sessionKey,
                                contentId: board.id,
                                data: {
                                    enabled: _.label
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    showLabelsSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.showLabelsSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.updateDisplayLabelViaSocket({
                    label: _.enabled
                })
            );
        });


    toggleShowMembersName$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopBrainstormingStoreActions.toggleShowMembersNameSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.showMembersSuccess({
                            payload: {
                                sessionKey,
                                contentId: board.id,
                                data: {
                                    enabled: _.enabled
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    showMembersSuccess$ = this._socket
        .fromEvent<any>(SocketIOActions.showMembersSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopBrainstormingStoreActions.toggleShowMembersNameViaSocket({
                    enabled: _.enabled
                })
            );
        });

        toggleShowAllCards$ = createEffect(
            () =>
                this._actions$.pipe(
                    ofType(
                        WorkshopBrainstormingStoreActions.toggleShowAllCardsSuccess,
                    ),
                    withLatestFrom(
                        this._store.pipe(
                            select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                        )
                    ),
                    withLatestFrom(
                        this._store.pipe(
                            select(
                                WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                            )
                        )
                    ),
                    map(([[_, [sessionRole, sessionKey]], board]) => {
                        this._store.dispatch(
                            SocketIOActions.showAllCardsSuccess({
                                payload: {
                                    sessionKey,
                                    contentId: board.id,
                                    data: {
                                        enabled: _.enabled
                                    }
                                },
                            })
                        );
                    })
                ),
            { dispatch: false }
        );
    
        ShowAllCardsSuccess$ = this._socket
            .fromEvent<any>(SocketIOActions.showAllCardsSuccess.type)
            .pipe(
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRole)
                    )
                ),
            )
            .subscribe(([_, sessionRole]) => {
                this._store.dispatch(
                    WorkshopBrainstormingStoreActions.toggleShowAllCardsViaSocket({
                        enabled: _.enabled
                    })
                );
            });
}


