import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    map,
    withLatestFrom,
    tap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

import { RootStoreState } from '../..';
import { WorkshopCardRatingStoreActions, WorkshopCardRatingStoreSelectors } from '.';
import { WorkshopSocketIOStoreActions, WorkshopSocketIOStoreSelectors } from '../socket-io-store';
import { WorkshopBrainstormingStoreActions } from '../brainstorming-store';
import { WorkshopTimerStoreActions, WorkshopTimerStoreSelectors } from '../timer-store';
import { BoardEvaluationPointsComponent } from 'app/workshop/brainstorming/animator/board/dialogs/evaluation-points/evaluation-points.component';
import { LoginStoreSelectors } from '../../login-store';
import { NotificationService } from '@bsuccess/services/notification.service';
import { BoardCanvasEvaluationPointsComponent } from 'app/workshop/canvas/animator/board/dialogs/evaluation-points/evaluation-points.component';
import { WorkshopCanvasStoreActions } from '../canvas-store';

@Injectable()
export class WorkshopCardRatingStoreEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _notificationService: NotificationService,
        private _matDialog: MatDialog,
        private translate: TranslateService,
    ) { }

    sendRates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopCardRatingStoreActions.sendAllRates),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginState))
            ),
            withLatestFrom(
                this._store.pipe(select(WorkshopCardRatingStoreSelectors.selectCards))
            ),
            withLatestFrom(
                this._store.pipe(select(WorkshopTimerStoreSelectors.selectExercise))
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopSocketIOStoreSelectors.selectContentId)
                )
            ),
            map(([[[[action, loginState], cards], exercise], contentId]) => {
                this._notificationService.showSuccess(
                    this.translate.currentLang.toString() === 'fr' ?
                        'Votre évaluation a bien été prise en compte.' :
                        this.translate.currentLang.toString() === 'en' ?
                        'Your vote has been taken into account.' :
                        '.تم أخذ تقييمك بالإعتبار'
                );
                if (exercise === 'evaluation') {
                    return WorkshopSocketIOStoreActions.sendBrainstormingRates({
                        payload: {
                            sessionKey: loginState.sessionKey,
                            user: loginState.user,
                            cards,
                            contentId
                        },
                    });
                } else {
                    return WorkshopSocketIOStoreActions.sendCanvasRates({
                        payload: {
                            sessionKey: loginState.sessionKey,
                            user: loginState.user,
                            cards,
                            contentId
                        },
                    });
                }
            })
        )
    );

    start$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopTimerStoreActions.start),
                withLatestFrom(
                    this._store.pipe(select(WorkshopTimerStoreSelectors.selectExercise))
                ),
                tap(([_, exercise]) => {
                    if (exercise === 'evaluation' || exercise === 'evaluation/canvas') {
                        this._store.dispatch(
                            WorkshopCardRatingStoreActions.updatePoints()
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    receiveAllBrainstormingRates$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.receiveAllBrainstormingRates),
                withLatestFrom(
                    this._store.pipe(select(WorkshopTimerStoreSelectors.selectExercise))
                ),
                tap(([_, exercise]) => {
                    if (exercise === 'evaluation') {
                        this._store.dispatch(
                            WorkshopBrainstormingStoreActions.sortCardsBySum()
                        );
                    } else {
                        this._store.dispatch(
                            WorkshopCanvasStoreActions.sortCardsBySum()
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    receiveAllCanvasRates$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.receiveAllCanvasRates),
                withLatestFrom(
                    this._store.pipe(select(WorkshopTimerStoreSelectors.selectExercise))
                ),
                tap(([_, exercise]) => {
                    if (exercise === 'evaluation') {
                        this._store.dispatch(
                            WorkshopBrainstormingStoreActions.sortCardsBySum()
                        );
                    } else {
                        this._store.dispatch(
                            WorkshopCanvasStoreActions.sortCardsBySum()
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    showPointsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.showPointsDialog),
                map(_ => {
                    this._matDialog.open(BoardEvaluationPointsComponent, {
                        panelClass: 'board-evaluation-points',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showCanvasPointsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.showCanvasPointsDialog),
                map(_ => {
                    this._matDialog.open(BoardCanvasEvaluationPointsComponent, {
                        panelClass: 'board-canvas-evaluation-points',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    rateChanged$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCardRatingStoreActions.increaseRate,
                    WorkshopCardRatingStoreActions.decreaseRate
                ),
                withLatestFrom(
                    this._store.pipe(select(WorkshopCardRatingStoreSelectors.selectPoints))
                ),
                map(([_, points]) => {
                    if (points === 0) {
                        this._store.dispatch(
                            WorkshopBrainstormingStoreActions.showSendVotesDialog()
                        );
                    }
                })
            ),
        {
            dispatch: false,
        }
    );
}
