export const locale = {
    lang: 'en',
    data: {
        EDIT_DETAILS: 'Edit workshop details',
        SESSION_NAME: 'Workshop name',
        DESCRIPTION: 'Description',
        OBLIGATORY: 'Private Workshop : participants need to sign in',
        WITHOUT: 'Public Workshop : participants don\'t need to sign in',
        START_DATE: 'Start date',
        END_DATE: 'End date',
        SAVE: 'Save',
        CANCEL: 'Cancel',
    }
};
