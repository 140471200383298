import { createAction, props } from '@ngrx/store';
import { Card } from '@bsuccess/models/brainstorming/card.model';
import { UserModel } from '@bsuccess/models/user.model';

export const sendAllRates = createAction('[Workshop][Cardrating] Send All Rate');

export const increaseRate = createAction(
    '[Workshop][Cardrating] Increase Rate',
    props<{ cardId: string }>()
);

export const updatePoints = createAction('[Workshop][Cardrating] Update Points');

export const decreaseRate = createAction(
    '[Workshop][Cardrating] Decrease Rate',
    props<{ cardId: string }>()
);

export const receiveAllCanvasRates = createAction(
    '[Workshop][Cardrating] Receive All Canvas Rate',
    props<{ payload: { cards: Card[]; user: UserModel } }>()
);

export const receiveAllBrainstormingRates = createAction(
    '[Workshop][Cardrating] Receive All Brainstorming Rate',
    props<{ payload: { cards: Card[]; user: UserModel } }>()
);

export const showPointsDialog = createAction('[Workshop][Cardrating] Show Points Dialog');
export const showCanvasPointsDialog = createAction('[Workshop][Cardrating] Show Canvas Points Dialog');

export const updateBalance = createAction(
    '[Workshop][Cardrating] Update Balance',
    props<{ balance: number }>()
);

export const updateMaxPerCard = createAction(
    '[Workshop][Cardrating] Update Max Points Per Card',
    props<{ max: number }>()
);

