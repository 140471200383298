import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { BoardListItemModel } from '@bsuccess/models/brainstorming/board-list-item.model';
import { Activity } from '@bsuccess/models/activities/activity.model';
import { locale as english } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/cards-to-columns-dialog/en';
import { locale as frensh } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/cards-to-columns-dialog/fr';
import { locale as arabic } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/cards-to-columns-dialog/ar';

@Component({
    selector: 'board-cards-to-columns-dialog',
    templateUrl: './cards-to-columns-dialog.component.html',
    styleUrls: ['./cards-to-columns-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BoardCardsToColumnsDialogComponent {
    boards$: Observable<BoardListItemModel[]>;
    activities$: Observable<Activity[]>;
    constructor(
        public matDialogRef: MatDialogRef<BoardCardsToColumnsDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

    }
}
