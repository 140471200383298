import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { exhaustMap, map, catchError, withLatestFrom } from 'rxjs/operators';

import { UsersService } from '@bsuccess/services/users.service';
import * as UsersActions from './actions';
import { AccessStoreSelectors } from 'app/root-store/access-store';
import { select, Store } from '@ngrx/store';
import { RootStoreState } from 'app/root-store';

@Injectable()
export class StudioUsersStoreEffects {
    constructor(
        private _actions$: Actions,
        private _usersService: UsersService,
        private _store: Store<RootStoreState.State>,
    ) { }

    loadUsers$ = createEffect(() =>
        this._actions$.pipe(
            ofType(UsersActions.loadUsers),
            exhaustMap(_ =>
                this._usersService.getUsers().pipe(
                    map(
                        response =>
                            UsersActions.loadUsersSuccess({
                                users: response,
                            }),
                        catchError(error =>
                            of(
                                UsersActions.loadUsersFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                )
            )
        )
    );


}
