import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { exhaustMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

import { RootStoreState } from '../..';
import * as ServicesActions from './actions';
import { ServicesService } from '@bsuccess/services/services.service';
import { ProjectUpdateServicesDialogComponent } from 'app/studio/projects/project/dialogs/project-update-services-dialog/project-update-services-dialog.component';
import { StudioProjectStoreSelectors } from '../project-store';

@Injectable()
export class StudioServicesStoreEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _servicesService: ServicesService,
        private _actions$: Actions,
        private _matDialog: MatDialog
    ) { }

    load$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ServicesActions.load),
            exhaustMap(_ => {
                return this._servicesService.getServices().pipe(
                    map(response => ServicesActions.loadSuccess({ response })),
                    catchError(error =>
                        of(
                            ServicesActions.loadFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    updateServices$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ServicesActions.updateServices),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    ProjectUpdateServicesDialogComponent,
                    {
                        panelClass: 'project-update-services-dialog',
                    }
                );

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return ServicesActions.updateServicesConfirmed({
                                services: confirmed,
                            });
                        } else {
                            return ServicesActions.updateServicesCancelled();
                        }
                    })
                );
            })
        )
    );

    updateServicesConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ServicesActions.updateServicesConfirmed),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, project]) => {
                return this._servicesService
                    .updateProjectServices(project._id, action.services)
                    .pipe(
                        map(response =>
                            ServicesActions.updateServicesSuccess({
                                services: action.services,
                            })
                        ),
                        catchError(error =>
                            of(
                                ServicesActions.updateServicesFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );
}
