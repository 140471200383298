export const locale = {
    lang: 'ar',
    data: {
        SESSION_NEW_DIALOG_NEW_GENERAL_SESSION_NAME: 'اسم الورشة',
        SESSION_NEW_DIALOG_NEW_GENERAL_DESCRIPTION: 'الوصف',
        SESSION_NEW_DIALOG_NEW_GENERAL_OBLIGATORY: 'ورشة خاصة: يحتاج المشاركون إلى تسجيل الدخول',
        // tslint:disable-next-line: max-line-length
        SESSION_NEW_DIALOG_NEW_GENERAL_PARTICIPANTS_WITH_ACCOUNT: 'سيحتاج المشاركون الذين ليس لديهم حساب إكْسِلْوايْ بالفعل إلى إنشاء حساب قبل دخول الورشة. وبالتالي سيكون لديهم الوصول إلى مشاريع  بعد الورشة',
        SESSION_NEW_DIALOG_NEW_GENERAL_WITHOUT: 'ورشة عامة: لا يحتاج المشاركون إلى تسجيل الدخول',
        // tslint:disable-next-line: max-line-length
        SESSION_NEW_DIALOG_NEW_GENERAL_PARTICIPANTS_WITHOUT_ACCOUNT: 'يمكن للمشاركين المدعوين وأي شخص لديه الرابط الوصول إلى الورشة بدون حساب إكْسِلْوايْ. لن يتمكنوا من الوصول إلى مشاريع إكْسِلْوايْ بعد الورشة',
        SESSION_NEW_DIALOG_NEW_GENERAL_START_DATE: 'تاريخ البدء',
        SESSION_NEW_DIALOG_NEW_GENERAL_END_DATE: 'تاريخ الانتهاء',
        SESSION_NEW_DIALOG_NEW_GENERAL_SAVE: 'حفظ',
        SESSION_NEW_DIALOG_NEW_GENERAL_CANCEL: 'إلغاء',
        SESSION_NEW_DIALOG_NEW_GENERAL_ADD_TIME:'إضافة وقت',
        SESSION_NEW_DIALOG_NEW_GENERAL_START_TIME:'وقت البدء',
        SESSION_NEW_DIALOG_NEW_GENERAL_END_TIME:'وقت الانتهاء',
        VALIDATORS_START_DATE_REQUIRED: 'تاريخ البدء واجب.' ,
        VALIDATORS_END_DATE_REQUIRED: 'تاريخ الانتهاء واجب.' ,  
        VALIDATORS_END_DATE_BEFORE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء' ,
        VALIDATORS_INVALID_START_TIME: 'وقت البدء غير صحيح' ,
        VALIDATORS_INVALID_END_TIME: 'وقت الانتهاء غير صحيح' ,  
        VALIDATORS_END_TIME_BEFORE: 'يجب أن يكون وقت الانتهاء أكبر من وقت البدء' , 
    }
};
