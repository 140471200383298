import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { DocumentModel } from '@bsuccess/models/project/document.model';

export const selectDocumentState = createFeatureSelector<State>('studio-document');

export const selectCurrent = createSelector(
    selectDocumentState,
    (state: State): DocumentModel => state.current
);

export const uploadStatus = createSelector(
    selectDocumentState,
    (state: State): boolean => state.loading
);

export const selectUploadError = createSelector(
    selectDocumentState,
    (state: State): string => state.error
);

export const selectLastUpload = createSelector(
    selectDocumentState,
    (state: State): string => state.lastUpload
);
