import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import { WorkshopCardRatingStoreActions } from '.';
import * as _ from 'lodash-es';
import { WorkshopBrainstormingStoreActions } from '../brainstorming-store';
import { WorkshopCanvasStoreActions } from '../canvas-store';
import { WorkshopTimerStoreActions } from '../timer-store';
import { WorkshopSocketIOStoreActions } from '../socket-io-store';

const cardRatingReducer = createReducer(
    initialState,
    on(
        WorkshopBrainstormingStoreActions.loadBoardSuccess,
        WorkshopCanvasStoreActions.loadBoardSuccess, (state, { board }) => ({
            ...state,
            cards: board.cards.map(card => ({
                ...card,
                sum: 0,
            })),
    })),
    on(WorkshopSocketIOStoreActions.receivedCard, (state, { listId, card }) => {
        return {
            ...state,
            cards: _.uniq([{
                ...card,
                sum: 0
            }, ...state.cards]),
        };
    }),
    on(WorkshopCardRatingStoreActions.updatePoints, state => ({
        ...state,
        cards: [
            ...state.cards.map(card => {
                return {
                    ...card,
                    sum: 0,
                };
            }),
        ],
        points: state.balance,
    })),
    on(WorkshopCardRatingStoreActions.updateBalance, (state, { balance }) => ({
        ...state,
        max: balance,
        points: balance,
        balance
    })),
    on(WorkshopCardRatingStoreActions.updateMaxPerCard, (state, { max }) => ({
        ...state,
        maxPerCard: max
    })),
    on(WorkshopCardRatingStoreActions.increaseRate, (state, { cardId }) => ({
        ...state,
        cards: [
            ...state.cards.map(card => {
                if (card.id === cardId) {
                    if (state.points !== 0) {
                        card.sum += 1;
                    }
                }
                return card;
            }),
        ],
        points: state.points === 0 ? 0 : state.points - 1,
    })),
    on(WorkshopCardRatingStoreActions.decreaseRate, (state, { cardId }) => ({
        ...state,
        cards: [
            ...state.cards.map(card => {
                if (card.id === cardId) {
                    if (card.sum > 0) {
                        card.sum -= 1;
                    }
                }
                return card;
            }),
        ],
        points: state.balance > state.points ? state.points + 1 : state.points,
    })),
    on(WorkshopCardRatingStoreActions.receiveAllBrainstormingRates, (state, { payload }) => ({
        ...state,
        ratedUsers: !state.ratedUsers.map(user => user._id).includes(payload.user._id) ? [...state.ratedUsers, payload.user] : state.ratedUsers
    })),
    on(WorkshopCardRatingStoreActions.receiveAllCanvasRates, (state, { payload }) => ({
        ...state,
        ratedUsers: !state.ratedUsers.map(user => user._id).includes(payload.user._id) ? [...state.ratedUsers, payload.user] : state.ratedUsers
    })),
    on(WorkshopTimerStoreActions.start, state => ({
        ...state,
        ratedUsers: []
    }))
);

export function reducer(state: State | undefined, action: Action): any {
    return cardRatingReducer(state, action);
}
