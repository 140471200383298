export const locale = {
    lang: 'ar',
    data: {
        PROJECT_NAME: ' عنوان المشروع',
        START_DATE: 'تاريخ البدء',
        END_DATE: 'تاريخ الانتهاء',
        DESCRIPTION: 'الوصف',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
        VALIDATORS_END_DATE_REQUIRED: 'تاريخ الانتهاء واجب.' ,  
        VALIDATORS_END_DATE_BEFORE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء' ,
        VALIDATORS_INVALID_START_TIME: 'وقت البدء غير صحيح' ,
        VALIDATORS_INVALID_END_TIME: 'وقت الانتهاء غير صحيح' ,  
        VALIDATORS_END_TIME_BEFORE: 'يجب أن يكون وقت الانتهاء أكبر من وقت البدء' ,
    }
};
