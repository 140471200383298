import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { ServiceModel } from '@bsuccess/models/project/service.model';

export const selectServicesState = createFeatureSelector<State>('studio-services');

export const selectServices = createSelector(
    selectServicesState,
    (state: State): ServiceModel[] => state.others
);
