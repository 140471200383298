import { createAction, props } from '@ngrx/store';

import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { BoardCardModel } from '@bsuccess/models/board/board-card.model';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { BoardListModel } from '@bsuccess/models/board/board-list.model';

export const showAddBoardDialog = createAction(
    '[Studio][Project Board Tab] Show Add Project Dialog'
);

export const createWidgetDialogCall = createAction(
    '[Studio][Project Board Dialog] Create Widget Dialog',
);

export const createWidget = createAction(
    '[Studio][Project Board Dialog] Create Widget',
    props<{
        boardId: string;
        widget: {
            name: string,
            widgetType: string,
            dataAbscissa: string,
            widgetFilters: any[]
        };
    }>()
);

export const createWidgetSuccess = createAction(
    '[Studio][Project Board API] Create Widget Success',
    props<{
        boardId: string;
        widget: any;
    }>()
);

export const createWidgetFailure = createAction(
    '[Studio][Project Board API] Create Widget Failure',
    props<{ error: string }>()
);

export const updateWidget = createAction(
    '[Studio][Project Board Dialog] Update Widget',
    props<{
        boardId: string;
        widget: {
            _id : string;
            name: string,
            previousType: string,
            widgetType: string,
            dataAbscissa?: string,
            widgetFilters: any[]
        };
    }>()
);

export const searchCard = createAction( 
    '[Studio][Project Board API] Search Card',
    props<{ text: string }>()
);

export const updateWidgetSuccess = createAction(
    '[Studio][Project Board API] Update Widget Success',
    props<{
        boardId: string;
        previousType: any;
        response: any;
    }>()
);

export const updateWidgetFailure = createAction(
    '[Studio][Project Board API] Update Widget Failure',
    props<{ error: string }>()
);


export const moveWidget = createAction(
    '[Studio][Project Board] Move Widget',
    props<{ widgetId: string , dropIndex: number , widgetNumber : boolean }>()
);

export const moveWidgetSuccess = createAction(
    '[Studio][Document Dialog] Move Widget Success',
    props<{ widgetId: string , dropIndex: number }>()
);

export const moveWidgetFailure = createAction(
    '[Studio][Document Dialog] Move Widget Failure',
    props<{ error: string }>()
);

export const removeWidget = createAction(
    '[Studio][Project Board] Remove Widget',
    props<{ widgetId: string , widgetNumber: boolean }>()
);

export const removeWidgetSuccess = createAction(
    '[Studio][Document Dialog] Remove Widget Success',
    props<{ widgetId: string }>()
);

export const removeWidgetFailure = createAction(
    '[Studio][Document Dialog] Remove Widget Failure',
    props<{ error: string }>()
);

export const showUpdateWidgetDialog = createAction(
    '[Studio][Project Board] Show Update Widget Dialog',
    props<{ widget: any[] }>()
);

export const filterBoardCards = createAction(
    '[Studio][Project Board Tab]  Filter Cards by Member',
    props<{
        idMembers: ProjectUserModel[];
        idCategories: any[];
        selectedDate: string;
        selectedPriority: string;
        idResponsable: string[];
        resetAll: boolean;
        currentFilters: any[];
    }>()
);

export const showFilterBoardCardsDialog = createAction(
    '[Studio][Project Board Tab] Show Filter Board Cards Dialog'
);

export const showEditTagsDialog = createAction(
    '[Studio][Project Board Tab] Show Edit Tags Dialog',
    props<{
        cardId: any;
    }>()
);

export const showCardAddBoardDialog = createAction(
    '[Studio][Project Board Tab] Show Card Add Project Dialog',
    props<{
        projectName: any;
    }>()
);

export const addBoard = createAction(
    '[Studio][Project Board Dialog] Comfirm Add',
    props<{ board: BoardModel }>()
);

export const addBoardSuccess = createAction(
    '[Studio][Project API] Add Board Success',
    props<{ board: BoardModel }>()
);

export const addBoardFailure = createAction(
    '[Studio][Project API] Add Board Failure',
    props<{ error: string }>()
);

export const updateBoardUsers = createAction(
    '[Studio][Board Dialog] Update Board Users',
    props<{ users: ProjectUserModel[] }>()
);

export const updateBoardUsersSuccess = createAction(
    '[Studio][Board API] Update Board Users Success',
    props<{ users: ProjectUserModel[] }>()
);

export const updateBoardUsersFailure = createAction(
    '[Studio][Board API] Update Board Users Failure',
    props<{ error: string }>()
);

export const loadCurrent = createAction(
    '[Studio][Project Board Page] Load Current',
    props<{ boardId: string }>()
);

export const loadCurrentSuccess = createAction(
    '[Studio][Project Board API] Load Current Success',
    props<{
        board: BoardModel;
    }>()
);

export const loadCurrentFailure = createAction(
    '[Studio][Project Board API] Load Current Failure',
    props<{ error: string }>()
);

export const loadProjectsList = createAction('[Studio][Project Board API] Load Projects List');

export const loadProjectsListSuccess = createAction(
    '[Studio][Project Board API] Load Projects List Success',
    props<{
        projectLists: any;
    }>()
);

export const loadProjectsListFailure = createAction(
    '[Studio][Project Board API] Load Projects List Failure',
    props<{ error: string }>()
);


export const rename = createAction(
    '[Studio][Project Board Page] Rename',
    props<{ newName: string }>()
);

export const renameSuccess = createAction(
    '[Studio][Project Board API] Rename Success'
);

export const renameFailure = createAction(
    '[Studio][Project Board API] Rename Failure',
    props<{ error: string }>()
);

export const addCard = createAction(
    '[Studio][Project Board Page] Add Card',
    props<{ listId: string; card: BoardCardModel }>()
);

export const addCardSuccess = createAction(
    '[Studio][Project Board API] Add Card Success',
    props<{ listId: string; card: BoardCardModel }>()
);

export const addCardFailure = createAction(
    '[Studio][Project Board API] Add Card Failure',
    props<{ error: string }>()
);

export const addCardViaSocket = createAction(
    '[Studio][Project Board API] Add Card Via Socket',
    props<{ listId: string; card: BoardCardModel }>()
);

export const removeCard = createAction(
    '[Studio][Project Board Page] Remove Card',
    props<{ listId: string; cardId: string }>()
);

export const removeCardSuccess = createAction(
    '[Studio][Project Board API] Remove Card Success',
    props<{ listId: string; cardId: string }>()
);

export const removeCardFailure = createAction(
    '[Studio][Project Board API] Remove Card Failure',
    props<{ error: string }>()
);

export const removeCardFromBoard = createAction(
    '[Studio][Project Board Page] Remove Card From Board',
    props<{ cardId: string; listId: string; boardIdToRemove: string; sharedBoard?: any[] }>()
);

export const removeCardFromBoardSuccess = createAction(
    '[Studio][Project Board API] Remove Card From Board Success',
    props<{ cardId: string; listId: string; boardIdToRemove: string; sharedBoard?: any[]}>()
);

export const removeCardFromBoardFailure = createAction(
    '[Studio][Project Board API] Remove Card From Board Failure',
    props<{ error: string }>()
);

export const removeCardViaSocket = createAction(
    '[Studio][Project Board API] Remove Card Via Socket',
    props<{ listId: string; cardId: string }>()
);

export const moveCard = createAction(
    '[Studio][Project Board Page] Move Card',
    props<{
        move: { listId: string; cardId: string; dropIndex: number };
        lists: BoardListModel[];
    }>()
);

export const moveCardSuccess = createAction(
    '[Studio][Project Board API] Move Card Success',
    props<{
        move: { listId: string; cardId: string; dropIndex: number };
        lists: BoardListModel[];
    }>()
);

export const moveCardFailure = createAction(
    '[Studio][Project Board API] Move Card Failure',
    props<{ error: string }>()
);

export const moveCardViaSocket = createAction(
    '[Studio][Project Board API] Move Card Via Socket',
    props<{
        move: { listId: string; cardId: string; dropIndex: number };
        lists: BoardListModel[];
    }>()
);

export const addList = createAction(
    '[Studio][Project Board Page] Add List',
    props<{ list: BoardListModel }>()
);

export const addListSuccess = createAction(
    '[Studio][Project Board API] Add List Success',
    props<{ list: BoardListModel }>()
);

export const addListFailure = createAction(
    '[Studio][Project Board API] Add List Failure',
    props<{ error: string }>()
);

export const addListViaSocket = createAction(
    '[Studio][Project Board API] Add List Via Socket',
    props<{ list: BoardListModel }>()
);

export const removeList = createAction(
    '[Studio][Project Board Page] Remove List',
    props<{ listId: string }>()
);

export const removeListSuccess = createAction(
    '[Studio][Project Board API] Remove List Success',
    props<{ listId: string }>()
);

export const removeListFailure = createAction(
    '[Studio][Project Board API] Remove List Failure',
    props<{ error: string }>()
);

export const removeListViaSocket = createAction(
    '[Studio][Project Board API] Remove List Via Socket',
    props<{ listId: string }>()
);

export const moveList = createAction(
    '[Studio][Project Board Page] Move List',
    props<{
        lists: BoardListModel[];
        move: { listId: string; dropIndex: number };
    }>()
);

export const moveListSuccess = createAction(
    '[Studio][Project Board API] Move List Success',
    props<{
        lists: BoardListModel[];
        move: { listId: string; dropIndex: number };
    }>()
);

export const moveListFailure = createAction(
    '[Studio][Project Board API] Move List Failure',
    props<{ error: string }>()
);

export const moveListViaSocket = createAction(
    '[Studio][Project Board API] Move List Via Socket',
    props<{
        lists: BoardListModel[];
        move: { listId: string; dropIndex: number };
    }>()
);

export const renameList = createAction(
    '[Studio][Project Board Page] List Name change',
    props<{ listId: string; name: string }>()
);

export const renameListSuccess = createAction(
    '[Studio][Project Board API] Rename List Success',
    props<{ listId: string; name: string }>()
);

export const renameListFailure = createAction(
    '[Studio][Project Board API] Rename List Failure',
    props<{ error: string }>()
);

export const renameListViaSocket = createAction(
    '[Studio][Project Board API] Rename List Via Socket',
    props<{ listId: string; name: string }>()
);

export const updateCardName = createAction(
    '[Studio][Project Board Page] Update Card Name',
    props<{ cardId: string; name: string }>()
);

export const updateCardNameSuccess = createAction(
    '[Studio][Project Board Page] Update Card Name Success',
    props<{ cardId: string; name: string }>()
);

export const updateCardNameFailure = createAction(
    '[Studio][Project Board Page] Update Card Name Failure',
    props<{ error: string }>()
);

export const updateCardNameViaSocket = createAction(
    '[Studio][Project Board Page] Update Card Name Via Socket',
    props<{ cardId: string; name: string }>()
);

export const updateCardStartDate = createAction(
    '[Studio][Project Board Page] Update Card START Date',
    props<{ cardId: string; startDate: string }>()
);

export const updateCardStartDateSuccess = createAction(
    '[Studio][Project Board Page] Update Card START Date Success',
    props<{ cardId: string; startDate: string }>()
);

export const updateCardStartDateFailure = createAction(
    '[Studio][Project Board Page] Update Card START Date Failure',
    props<{ error: string }>()
);

export const deleteCardDate = createAction(
    '[Studio][Project Board Page] delete Card Date',
    props<{ cardId: string; startDate: boolean, endDate: boolean }>()
);

export const deleteCardDateSuccess = createAction(
    '[Studio][Project Board Page] delete Card Date Success',
    props<{ cardId: string }>()
);

export const deleteCardDateFailure = createAction(
    '[Studio][Project Board Page] delete Card Date Failure',
    props<{ error: string }>()
);

export const updateAsCover = createAction(
    '[Studio][Board Page] Update As Cover',
    props<{ attachmentId: any; cardId: string }>()
);

export const updateAsCoverSuccess = createAction(
    '[Studio][Board API] Update As Cover Success',
    props<{ attachmentId: any; cardId: string }>()
);

export const updateAsCoverFailure = createAction(
    '[Studio][Board API] Update As Cover Failure',
    props<{ error: string }>()
);

export const updateCardEndDate = createAction(
    '[Studio][Project Board Page] Update Card END Date',
    props<{ cardId: string; endDate: string }>()
);

export const updateCardEndDateSuccess = createAction(
    '[Studio][Project Board Page] Update Card END Date Success',
    props<{ cardId: string; endDate: string }>()
);

export const updateCardEndDateFailure = createAction(
    '[Studio][Project Board Page] Update Card END Date Failure',
    props<{ error: string }>()
);

export const updateCardStartDateViaSocket = createAction(
    '[Studio][Project Board Page] Update Card Start Date Via Socket',
    props<{ cardId: string; startDate: string }>()
);

export const updateCardEndDateViaSocket = createAction(
    '[Studio][Project Board Page] Update Card End Date Via Socket',
    props<{ cardId: string; endDate: string }>()
);

export const unsetCardResponsible = createAction(
    '[Studio][Project Board Page] Unset Card Responsible',
    props<{ cardId: string;}>()
);

export const unsetCardResponsibleSuccess = createAction(
    '[Studio][Project Board Page] Unset Card Responsible Success',
    props<{ cardId: string;}>()
);

export const unsetCardResponsibleFailure = createAction(
    '[Studio][Project Board Page] Unset Card Responsible Failure',
    props<{ error: string }>()
);

export const updateCardResponsible = createAction(
    '[Studio][Project Board Page] Update Card Responsible',
    props<{ cardId: string; idResponsible: string }>()
);

export const updateCardResponsibleSuccess = createAction(
    '[Studio][Project Board Page] Update Card Responsible Success',
    props<{ cardId: string; idResponsible: string }>()
);

export const updateCardResponsibleFailure = createAction(
    '[Studio][Project Board Page] Update Card Responsible Failure',
    props<{ error: string }>()
);

export const updateCardResponsibleViaSocket = createAction(
    '[Studio][Project Board Page] Update Card Responsible Via Socket',
    props<{ cardId: string; idResponsible: string }>()
);

export const hideCardActivities = createAction(
    '[Studio][Project Board Page] Hide Card Activities',
    props<{ cardId: string; }>()
);

export const displayCardActivities = createAction(
    '[Studio][Project Board Page] Display Card Activities',
    props<{ cardId: string; }>()
);

export const displayCardActivitiesSuccess = createAction(
    '[Studio][Project Board Page] Display Card Activities Success',
    props<{ cardId: string; activities: any }>()
);

export const displayCardActivitiesFailure = createAction(
    '[Studio][Project Board Page] Display Card Activities Failure',
    props<{ error: string }>()
);

export const updateCardDescription = createAction(
    '[Studio][Project Board Page] Update Card Description',
    props<{ cardId: string; description: string }>()
);

export const updateCardDescriptionSuccess = createAction(
    '[Studio][Project Board Page] Update Card Description Success',
    props<{ cardId: string; description: string }>()
);

export const updateCardDescriptionFailure = createAction(
    '[Studio][Project Board Page] Update Card Description Failure',
    props<{ error: string }>()
);

export const updateCardDescriptionViaSocket = createAction(
    '[Studio][Project Board Page] Update Card Description Via Socket',
    props<{ cardId: string; description: string }>()
);

export const archiveBoard = createAction(
    '[Studio][Project Board Dialog] Archive Project Board'
);

export const archiveBoardConfirmed = createAction(
    '[Studio][Project Board Dialog] Archive Project Board Confirmed',
    props<{ id: string }>()
);

export const archiveBoardCancelled = createAction(
    '[Studio][Project Board Dialog] Archive Project Board Cancelled'
);

export const archiveBoardSuccess = createAction(
    '[Studio][Document API] Archive Board Success',
    props<{ id: string }>()
);

export const archiveBoardFailure = createAction(
    '[Studio][Document API] Archive Board Failure',
    props<{ error: string }>()
);

export const showBoardDetailsDialog = createAction(
    '[Studio][Project Board Details Dialog] Show Board Details Dialog'
);
export const updateBoardDetails = createAction(
    '[Studio][Project Board Dialog] Update Details',
    props<{ boardId: string; name: string; description: string; enableBoardPrivacy: boolean }>()
);

export const updateBoardDetailsSuccess = createAction(
    '[Studio][Project API] Update Project Board Details Success',
    props<{ boardId: string; name: string; description: string; enableBoardPrivacy: boolean }>()
);

export const updateBoardDetailsViaSocket = createAction(
    '[Studio][Project API] Update Project Board Details Via Socket',
    props<{ boardId: string; name: string; description: string }>()
);

export const updateBoardDetailsFailure = createAction(
    '[Studio][Project API] Update Project Board Details Failure',
    props<{ error: string }>()
);

export const addCardComment = createAction(
    '[Studio][Project Board Card Dialog] Add Card Comment',
    props<{
        comment: {
            idMember: string;
            message: any;
            time: Date;
            cardId: string;
        };
    }>()
);

export const addCardCommentSuccess = createAction(
    '[Studio][Project Board API] Add Card Comment Success',
    props<{
        cardId: string;
        comment: {
            id: string;
            idMember: string;
            message: any;
            time: Date;
        };
    }>()
);

export const addCardCommentFailure = createAction(
    '[Studio][Project Board API] Add Card Comment Failure',
    props<{ error: string }>()
);

export const addCardCommentViaSocket = createAction(
    '[Studio][Project Board API] Add Card Comment Via Socket',
    props<{
        cardId: string;
        comment: {
            id: string;
            idMember: string;
            message: any;
            time: Date;
        };
    }>()
);

// ------------------- board new

export const toggleMember = createAction(
    '[Studio][Project Board Card Dialog] Toggle Member',
    props<{
        boardId: string;
        toggleMemberForm: {
            cardId: string;
            memberId: string;
        };
    }>()
);

export const toggleMemberSuccess = createAction(
    '[Studio][Project Board API] Toggle Member Success',
    props<{
        boardId: string;
        toggleMemberForm: {
            cardId: string;
            memberId: string;
        };
    }>()
);

export const toggleMemberFailure = createAction(
    '[Studio][Project Board API] Toggle Member Failure',
    props<{ error: string }>()
);

export const toggleMemberViaSocket = createAction(
    '[Studio][Project Board API] Toggle Member Via Socket',
    props<{
        toggleMemberForm: {
            cardId: string;
            memberId: string;
        };
    }>()
);

export const addChecklist = createAction(
    '[Studio][Project Board Card Dialog] Add Checklist',
    props<{
        boardId: string;
        cardId: string;
        checklist: {
            id: string;
            name: string;
            checkItemsChecked: number;
            checkItems: {
                id: string;
                name: string;
                checked: boolean;
            }[];
        };
    }>()
);

export const addChecklistSuccess = createAction(
    '[Studio][Project Board API] Add Checklist Success',
    props<{
        cardId: string;
        checklist: {
            id: string;
            name: string;
            checkItemsChecked: number;
            checkItems: {
                id: string;
                name: string;
                checked: boolean;
            }[];
        };
    }>()
);

export const addChecklistFailure = createAction(
    '[Studio][Project Board API] Add Checklist Failure',
    props<{ error: string }>()
);

export const addChecklistViaSocket = createAction(
    '[Studio][Project Board API] Add Checklist Via Socket',
    props<{
        cardId: string;
        checklist: {
            id: string;
            name: string;
            checkItemsChecked: number;
            checkItems: {
                id: string;
                name: string;
                checked: boolean;
            }[];
        };
    }>()
);

export const addcheckItem = createAction(
    '[Studio][Project Board Card Dialog] Add Check Item Form',
    props<{
        boardId: string;
        checkItemForm: {
            cardId: string;
            checklistId: string;
            id: string;
            name: string;
            checked: boolean;
        };
    }>()
);

export const addcheckItemSuccess = createAction(
    '[Studio][Project Board API] Add Check Item Form Success',
    props<{
        checkItemForm: {
            cardId: string;
            checklistId: string;
            id: string;
            name: string;
            checked: boolean;
        };
    }>()
);

export const addcheckItemFailure = createAction(
    '[Studio][Project Board API] Add Check Item Form Failure',
    props<{ error: string }>()
);

export const addcheckItemViaSocket = createAction(
    '[Studio][Project Board API] Add Check Item Form Via Socket',
    props<{
        checkItemForm: {
            cardId: string;
            checklistId: string;
            id: string;
            name: string;
            checked: boolean;
        };
    }>()
);

export const updateCheckListName = createAction(
    '[Studio][Project Board Card Dialog] Update Checklist Name',
    props<{
        boardId: string;
        checkListForm: {
            cardId: string;
            checklistId: string;
            checklistName: string;
        };
    }>()
);

export const updateCheckListNameSuccess = createAction(
    '[Studio][Project Board API] Update Checklist Name Success',
    props<{
        checkListForm: {
            cardId: string;
            checklistId: string;
            checklistName: string;
        };
    }>()
);

export const updateCheckListNameFailure = createAction(
    '[Studio][Project Board API] Update Checklist Name Failure',
    props<{ error: string }>()
);

export const removeCheckList = createAction(
    '[Studio][Project Board Card Dialog] Remove Check List',
    props<{
        boardId: string;
        checkListForm: {
            cardId: string;
            checklistId: string;
        };
    }>()
);

export const removeCheckListSuccess = createAction(
    '[Studio][Project Board API] Remove Check List Success',
    props<{
        checkListForm: {
            cardId: string;
            checklistId: string;
        };
    }>()
);

export const removeCheckListFailure = createAction(
    '[Studio][Project Board API] Remove Check List Failure',
    props<{ error: string }>()
);

export const removeCheckListViaSocket = createAction(
    '[Studio][Project Board API] Remove Check List Via Socket',
    props<{
        checkListForm: {
            cardId: string;
            checklistId: string;
        };
    }>()
);

export const removeCheckItem = createAction(
    '[Studio][Project Board Card Dialog] Remove Check Item',
    props<{
        boardId: string;
        checkListForm: {
            cardId: string;
            checklistId: string;
            checkItemId: string;
        };
    }>()
);

export const removeCheckItemSuccess = createAction(
    '[Studio][Project Board API] Remove Check Item Success',
    props<{
        checkListForm: {
            cardId: string;
            checklistId: string;
            checkItemId: string;
        };
    }>()
);

export const removeCheckItemFailure = createAction(
    '[Studio][Project Board API] Remove Check Item Failure',
    props<{ error: string }>()
);

export const removeCheckItemViaSocket = createAction(
    '[Studio][Project Board API] Remove Check Item Via Socket',
    props<{
        checkListForm: {
            cardId: string;
            checklistId: string;
            checkItemId: string;
        };
    }>()
);

export const updateCheckItem = createAction(
    '[Studio][Project Board Card Dialog] Update Check Item',
    props<{
        boardId: string;
        checkItemForm: {
            cardId: string;
            checklistId: string;
            checkItemId: string;
            name: string;
            checked: boolean;
        };
    }>()
);

export const updateCheckItemSuccess = createAction(
    '[Studio][Project Board API] Update Check Item Success',
    props<{
        checkItemForm: {
            cardId: string;
            checklistId: string;
            checkItemId: string;
            name: string;
            checked: boolean;
        };
    }>()
);

export const updateCheckItemFailure = createAction(
    '[Studio][Project Board API] Update Check Item Failure',
    props<{ error: string }>()
);

export const updateCheckItemViaSocket = createAction(
    '[Studio][Project Board API] Update Check Item Via Socket',
    props<{
        checkItemForm: {
            cardId: string;
            checklistId: string;
            checkItemId: string;
            name: string;
            checked: boolean;
        };
    }>()
);

export const addLabel = createAction(
    '[Studio][Project Board Card Dialog] Add Label',
    props<{
        cardId: string;
        name: string;
        color: string;
    }>()
);

export const addLabelSuccess = createAction(
    '[Studio][Project Board API] Add Label Success',
    props<{
        cardId: string;
        boardId: string;
        name: string;
        color: string;
        id: string;
    }>()
);

export const addLabelFailure = createAction(
    '[Studio][Project Board API] Add Label Failure',
    props<{ error: string }>()
);

export const addLabelViaSocket = createAction(
    '[Studio][Project Board API] Add Label Via Socket',
    props<{
        name: string;
        color: string;
        id: string;
    }>()
);

// export const addLabel = createAction(
//     '[Studio][Project Board Page] Add Label',
//     props<{ label: { id: string, name: string, color: string } }>()
// );

export const updateLbels = createAction(
    '[Studio][Project Board API] Update Label'
);

export const updateLbelsSuccess = createAction(
    '[Studio][Project Board API] Update Labels Success',
    props<{
        labels: { id: string; name: string; color: string }[];
    }>()
);

export const updateLbelsFailure = createAction(
    '[Studio][Project Board API] Update Labels Failure',
    props<{ error: string }>()
);

export const moveLabel = createAction(
    '[Studio][Project Board Card Dialog] Move Label',
    props<{
        cardId: string;
        labelId: string;
        dropIndex: number;
    }>()
);

export const moveLabelSuccess = createAction(
    '[Studio][Project Board API] Move Label Success',
    props<{
        cardId: string;
        labelId: string;
        dropIndex: number;
    }>()
);

export const moveLabelFailure = createAction(
    '[Studio][Project Board API] Move Label Failure',
    props<{ error: string }>()
);

export const updateLabel = createAction(
    '[Studio][Project Board Card Dialog] Update Label',
    props<{
        id: string;
        boardId: string;
        name: string;
        color: string;
    }>()
);

export const updateLabelSuccess = createAction(
    '[Studio][Project Board API] Update Label Success',
    props<{
        name: string;
        color: string;
        id: string;
    }>()
);

export const updateLabelFailure = createAction(
    '[Studio][Project Board API] Update Label Failure',
    props<{ error: string }>()
);

export const updateLabelViaSocket = createAction(
    '[Studio][Project Board API] Update Label Via Socket',
    props<{
        name: string;
        color: string;
        id: string;
    }>()
);

export const toggleLabel = createAction(
    '[Studio][Project Board Card Dialog] Toggle Label',
    props<{
        boardId: string;
        toggleForm: {
            cardId: string;
            labelId: string;
        };
    }>()
);

export const toggleLabelSuccess = createAction(
    '[Studio][Project Board Card Dialog] Toggle Label Success',
    props<{
        toggleForm: {
            cardId: string;
            labelId: string;
        };
    }>()
);

export const toggleLabelFailure = createAction(
    '[Studio][Project Board Card Dialog] Toggle Label Failure',
    props<{ error: string }>()
);

export const toggleLabelViaSocket = createAction(
    '[Studio][Project Board Card Dialog] Toggle Label Via Socket',
    props<{
        toggleForm: {
            cardId: string;
            labelId: string;
        };
    }>()
);

export const duplicateCard = createAction(
    '[Studio][Project Board Card Dialog] Duplicate Card',
    props<{
        duplicate: {
            cardId: string;
            listId: string;
        };
    }>()
);

export const duplicateCardSuccess = createAction(
    '[Studio][Project Board Card Dialog] Duplicate Card Success',
    props<{
        duplicate: {
            cardId: string;
            listId: string;
        };
        card: BoardCardModel;
    }>()
);

export const duplicateCardFailure = createAction(
    '[Studio][Project Board Card Dialog] Duplicate Card Failure',
    props<{ error: string }>()
);

export const duplicateCardViaSocket = createAction(
    '[Studio][Project Board Card Dialog] Duplicate Card Via Socket',
    props<{
        duplicate: {
            cardId: string;
            listId: string;
        };
        card: BoardCardModel;
    }>()
);

export const deleteComment = createAction(
    '[Studio][Project Board Card Dialog] Delete Card Comment',
    props<{
        cardId: string;
        commentId: string;
    }>()
);

export const deleteCommentSuccess = createAction(
    '[Studio][Project Board Card Dialog] Delete Card Comment Success',
    props<{
        id: string;
        cardId: string;
        commentId: string;
    }>()
);

export const deleteCommentFailure = createAction(
    '[Studio][Project Board Card Dialog] Delete Card Comment Failure',
    props<{ error: string }>()
);

export const deleteCommentViaSocket = createAction(
    '[Studio][Project Board Card Dialog] Delete Card Comment Via Socket',
    props<{
        id: string;
        cardId: string;
        commentId: string;
    }>()
);

export const uploadAttachment = createAction(
    '[Studio][Board Card Dialog] Upload Attachment',
    props<{ boardId: string; content: any; card: BoardCardModel, name: string }>()
);

export const uploadAttachmentSuccess = createAction(
    '[Studio][Board API] Upload Attachment Success',
    props<{ boardId: string; response: any; card: BoardCardModel }>()
);

export const uploadAttachmentFailure = createAction(
    '[Studio][Document API] Upload Attachment Failure',
    props<{ error: string }>()
);

export const addAttachment = createAction(
    '[Studio][Board Card Dialog] Add Attachment',
    props<{ boardId: string; card: BoardCardModel; attachment: any }>()
);

export const addAttachmentSuccess = createAction(
    '[Studio][Board Card Dialog] Add Attachment Success',
    props<{ boardId: string; card: BoardCardModel; attachment: any }>()
);

export const addAttachmentFailure = createAction(
    '[Studio][Board Card Dialog] Add Attachment Failure',
    props<{ error: any }>()
);

export const addAttachmentViaSocket = createAction(
    '[Studio][Board Card Dialog] Add Attachment Via Socket',
    props<{ boardId: string; card: BoardCardModel; attachment: any }>()
);

export const clearImages = createAction(
    '[Studio][Board Card Dialog] Clear Images Attachments',
    props<{ card: BoardCardModel }>()
);

export const LoadImages = createAction(
    '[Studio][Board Card Dialog] Load Images Attachments',
    props<{ card: BoardCardModel }>()
);

export const updateImage = createAction(
    '[Studio][Board API] Update Card Attachment Image',
    props<{ cardId: string; attachment: any }>()
);

export const updateImageSuccess = createAction(
    '[Studio][Board API] Update Card Attachment Success',
    props<{ cardId: string; attachment: any; content: any }>()
);

export const updateImageFailure = createAction(
    '[Studio][Board API] Update Card Attachment Failure',
    props<{ error: string }>()
);

export const imageRendered = createAction(
    '[Studio][Board Page] Image Rendered',
    props<{ cardId: string; attachment: any; content: any }>()
);

export const showFullScreenDisplayDialog = createAction(
    '[Studio][Project Board Tab] Show Attachment Full Screen Dialog',
    props<{ attachment: any }>()
);

export const loadImage = createAction(
    '[Studio][Project Board] Load Attachment Image',
    props<{ attachment: any }>()
);

export const loadImageSuccess = createAction(
    '[Studio][Board API] Load Attachment Image Success',
    props<{ attachment: any; content: any }>()
);

export const loadImageFailure = createAction(
    '[Studio][Board API] Load Attachment Image Failure',
    props<{ error: string }>()
);

export const renderCurrentImage = createAction(
    '[Studio][Board Page] Image Rendered',
    props<{ attachment: any; content: any }>()
);

export const downloadAttachment = createAction(
    '[Studio][Board Page] Download Attachment',
    props<{ attachment: any }>()
);

export const downloadAttachmentSuccess = createAction(
    '[Studio][Board API] Download Attachment Success',
    props<{ response: any }>()
);

export const downloadAttachmentFailure = createAction(
    '[Studio][Board API] Download Attachment Failure',
    props<{ error: string }>()
);

export const removeAttachment = createAction(
    '[Studio][Board Page] Remove Attachment',
    props<{ attachmentId: any; cardId: string }>()
);

export const removeAttachmentSuccess = createAction(
    '[Studio][Board API] Remove Attachment Success',
    props<{ attachmentId: any; cardId: string }>()
);

export const removeAttachmentFailure = createAction(
    '[Studio][Board API] Remove Attachment Failure',
    props<{ error: string }>()
);

export const removeAttachmentViaSocket = createAction(
    '[Studio][Board API] Remove Attachment Via Socket',
    props<{ attachmentId: any; cardId: string }>()
);

export const loadPreviewImage = createAction(
    '[Studio][Project Board] Load Preview Image',
    props<{ attachment: any; cardId: string }>()
);

export const loadPreviewImageSuccess = createAction(
    '[Studio][Board API] Load Preview Image Success',
    props<{ attachment: any; content: any; cardId: string }>()
);

export const loadPreviewImageFailure = createAction(
    '[Studio][Board API] Load Preview Image Failure',
    props<{ error: string }>()
);

export const renderPreviewImage = createAction(
    '[Studio][Board Page] Image Preview Rendered',
    props<{ attachment: any; content: any; cardId: string }>()
);

export const removeLabel = createAction(
    '[Studio][Project Board API] Remove Label',
    props<{
        boardId: string;
        labelId: string;
        label: {};
    }>()
);

export const removeLabelSuccess = createAction(
    '[Studio][Project Board API] Remove Label Success',
    props<{
        labelId: string;
    }>()
);

export const removeLabelFailure = createAction(
    '[Studio][Project Board API] Remove Label Failure',
    props<{ error: string }>()
);

export const removeLabelViaSocket = createAction(
    '[Studio][Project Board API] Remove Label Via Socket',
    props<{
        labelId: string;
    }>()
);

export const toggleBackgroundPanel = createAction(
    '[Studio][Project Board Panel] Toggle Background Panel'
);

export const updateBackground = createAction(
    '[Studio] Update Board Background',
    props<{ background: string }>()
);

export const updateBackgroundSuccess = createAction(
    '[Studio][Project Board API] Update Board Success',
    props<{ background: string }>()
);

export const updateBackgroundFailure = createAction(
    '[Studio][Project Board API] Update Board Failure',
    props<{ error: string }>()
);

export const updateCardPriority = createAction(
    '[Studio][Project Board Page] Update Card Priority',
    props<{ cardId: string; priority: string }>()
);

export const updateCardPrioritySuccess = createAction(
    '[Studio][Project Board Page] Update Card Priority Success',
    props<{ cardId: string; priority: string }>()
);

export const updateCardPriorityFailure = createAction(
    '[Studio][Project Board Page] Update Card Priority Failure',
    props<{ error: string }>()
);

export const updateCardIsCompleted = createAction(
    '[Studio][Project Board Page] Update Card isCompleted',
    props<{ cardId: string; isCompleted: boolean }>()
);

export const updateCardIsCompletedSuccess = createAction(
    '[Studio][Project Board Page] Update Card isCompleted Success',
    props<{
        cardId: string;
        completed: { isCompleted?: boolean; completedDate?: any };
    }>()
);

export const updateCardIsCompletedFailure = createAction(
    '[Studio][Project Board Page] Update Card isCompleted Failure',
    props<{ error: string }>()
);

export const showSubscribedDialog = createAction(
    '[Studio][Project Board Tab] Show Subscribed Board Dialog'
);

export const watchBoard = createAction(
    '[Studio][Project Board Page] Watch Board',
    props<{
        enableEmailNotifications: Boolean;
        daily: Boolean;
        weekly: Boolean;
    }>()
);

export const watchBoardSuccess = createAction(
    '[Studio][Project Board Page] Watch Board Success',
    props<{
        _id: string;
        enableEmailNotifications: Boolean;
        daily: Boolean;
        weekly: Boolean;
    }>()
);

export const watchBoardFailure = createAction(
    '[Studio][Project Board Page] Watch Board Failure',
    props<{ error: string }>()
);

export const toggleListViewOn = createAction(
    '[Studio][Project Board Panel] Toggle List View',
    props<{ viewType: string }>()
);

export const sortBoardByOwner = createAction(
    '[Studio][Project Board Panel] Toggle List View - Sort By Owner'
);

export const unsortBoardbyOwner = createAction(
    '[Studio][Project Board Panel] Toggle List View - Unsort By Owner'
);

export const sortBoardbyPriority = createAction(
    '[Studio][Project Board Panel] Toggle List View - Sort By Priority'
);

export const unsortBoardbyPriority = createAction(
    '[Studio][Project Board Panel] Toggle List View - Unsort By Priority'
);

export const sortBoardbyDate = createAction(
    '[Studio][Project Board Panel] Toggle List View - Sort By Date'
);

export const unsortBoardbyDate = createAction(
    '[Studio][Project Board Panel] Toggle List View - Unsort By Date'
);

export const linkCardToBoard = createAction(
    '[Studio][Project Board Card Dialog] Link Card to Board',
    props<{
        boardId: string;
        cardDetails: {
            cardId: string;
            linkedBoardId: string;
        };
    }>()
);

export const linkCardToBoardSuccess = createAction(
    '[Studio][Project Board API] Link Card to Board Success',
    props<{
        boardId: string;
        cardDetails: {
            cardId: string;
            linkedBoardId: string;
        };
    }>()
);

export const linkCardToBoardFailure = createAction(
    '[Studio][Project Board API] Link Card to Board Failure',
    props<{ error: string }>()
);

export const addCardToBoard = createAction(
    '[Studio][Project Board Card Dialog] Add Card to Board',
    props<{
        boardId: string;
        listId: string;
        cardDetails: {
            cardId: string;
            boardToShareWith: string;
            boardName?: string;
        };
    }>()
);

export const addCardToBoardSuccess = createAction(
    '[Studio][Project Board Card Dialog] Add Card to Board Success',
    props<{
        boardId: string;
        boardMame?: string;
        listId: string;
        cardDetails: {
            cardId: string;
            boardToShareWith: string;
            boardName?: string;
        };
    }>()
);

export const addCardToBoardFailure = createAction(
    '[Studio][Project Board Card Dialog] Add Card to Board Failure',
    props<{ error: string }>()
);

export const addCardstoBoardWithComment = createAction(
    '[Studio][Project Board Card Dialog] Add Card to Board with comment',
    props<{
        boardId: string;
        cardDetails: {
            cardId: string;
            boardToShareWith: string;
        };
    }>()
);

export const addCardstoBoardWithCommentConfirmed = createAction(
    '[Studio][Project Board Card Dialog] Add Card to Board with comment Confirmed',
    props<{
        boardId: string;
        cardDetails: {
            cardId: string;
            boardToShareWith: string;
        };
        comment: string
    }>()
);

export const addCardstoBoardWithCommentCanceled = createAction(
    '[Studio][Project Board Card Dialog] Add Card to Board with comment Canceled'
);

export const addCardstoBoardWithCommentSuccess = createAction(
    '[Studio][Project Board Card Dialog] Add Card to Board with comment Success',
    props<{
        boardId: string;
        cardDetails: {
            cardId: string;
            boardToShareWith: string;
        };
        comment: string
    }>()
);

export const addCardstoBoardWithCommentFailure = createAction(
    '[Studio][Project Board Card Dialog] Add Card to Board with comment Failure',
    props<{ error: string }>()
);

export const copyCardToBoard = createAction(
    '[Studio][Project Board Card Dialog] Copy Card to Board',
    props<{
        boardId: string;
        listId: string;
        cardDetails: {
            cardId: string;
            linkedBoardId: string;
        };
    }>()
);

export const copyCardToBoardSuccess = createAction(
    '[Studio][Project Board API] Copy Card to Board Success',
    props<{
        boardId: string;
        listId: string;
        cardDetails: {
            cardId: string;
            linkedBoardId: string;
        };
    }>()
);

export const copyCardToBoardFailure = createAction(
    '[Studio][Project Board Page] Copy Card to Board Failure',
    props<{ error: string }>()
);

export const copyCardstoBoardWithComment = createAction(
    '[Studio][Project Board Card Dialog] Copy Card to Board with comment',
    props<{
        boardId: string;
        cardDetails: {
            cardId: string;
            linkedBoardId: string;
        };
    }>()
);

export const copyCardstoBoardWithCommentConfirmed = createAction(
    '[Studio][Project Board Card Dialog] Copy Card to Board with comment Confirmed',
    props<{
        boardId: string;
        cardDetails: {
            cardId: string;
            linkedBoardId: string;
        };
        comment: string
    }>()
);

export const copyCardstoBoardWithCommentCanceled = createAction(
    '[Studio][Project Board Card Dialog] Copy Card to Board with comment Canceled'
);

export const copyCardstoBoardWithCommentSuccess = createAction(
    '[Studio][Project Board Card Dialog] Copy Card to Board with comment Success',
    props<{
        boardId: string;
        cardDetails: {
            cardId: string;
            linkedBoardId: string;
        };
        comment: string
    }>()
);

export const copyCardstoBoardWithCommentFailure = createAction(
    '[Studio][Project Board Card Dialog] Copy Card to Board with comment Failure',
    props<{ error: string }>()
);

export const unlinkCardFromBoard = createAction(
    '[Studio][Project Board Card Dialog] Unlink Card from Board',
    props<{
        boardId: string;
        cardId: string;
    }>()
);

export const unlinkCardFromBoardSuccess = createAction(
    '[Studio][Project Board API] Unlink Card from Board Success',
    props<{
        boardId: string;
        cardId: string;
    }>()
);

export const unlinkCardFromBoardFailure = createAction(
    '[Studio][Project Board API] Unlink Card from Board Failure',
    props<{ error: string }>()
);

export const getCharts = createAction(
    '[Studio][Project Board] Get Charts to Board',
    props<{
        boardId: string;
    }>()
);

export const getChartsSuccess = createAction(
    '[Studio][Project Board API] Get Charts to Board Success',
    props<{
        boardId: string;
        widgetCharts: any[];
    }>()
);

export const getChartsFailure = createAction(
    '[Studio][Project Board API] Get Charts to Board Failure',
    props<{ error: string }>()
);