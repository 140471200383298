import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Activity } from '@bsuccess/models/activities/activity.model';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootStoreState } from 'app/root-store';
import { StudioProjectStoreActions, StudioProjectStoreSelectors } from 'app/root-store/studio-store/project-store';
import { WorkshopActivitiesStoreActions, WorkshopActivitiesStoreSelectors } from 'app/root-store/workshop-store/activities-store';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/board-new-template-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/board-new-template-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/board-new-template-dialog/ar';
import { Observable } from 'rxjs';

@Component({
    selector: 'board-new-template-dialog',
    templateUrl: './board-new-template-dialog.component.html',
    styleUrls: ['./board-new-template-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BoardNewTemplateDialogComponent implements OnInit {
    canvas$: Observable<any[]>;
    filteredCanvas$: Observable<any[]>;

    detailObject: Activity;
    searchTerm: any;

    selectedType = 'all';
    selectedCategory$: Observable<string>;
    selectedCanvas: any;

    filtredCanvas: any[] = [];

    constructor(
        public matDialogRef: MatDialogRef<BoardNewTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this._store.dispatch(StudioProjectStoreActions.getProjectBoardTemplates());

        this.detailObject = {
            ...this._data,
        };

        this.canvas$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectCanvasTemplates)
        );

        this.filteredCanvas$ = this._store.pipe(
            select(StudioProjectStoreSelectors.selectFiltredTemplates)
        );

        this.selectedCategory$ = this._store.pipe(
            select(StudioProjectStoreSelectors.selectSelectedCategory)
        );

        this.filteredCanvas$.subscribe(_ => {
            this.filtredCanvas = _;
            //console.log(_);
        });

    }

    save(canva): void {
        this.matDialogRef.close(canva);

        this._store.dispatch(StudioProjectStoreActions.showAddBoardDialog({
            pickedCanvas: canva
        }));
    }

    filterCanvasByTerm(): void {
        this._store.dispatch(
            StudioProjectStoreActions.templateSearch({
                text: this.searchTerm,
            })
        );
    }

    filterCanvasByCategory(category): void {
        this._store.dispatch(
            StudioProjectStoreActions.templateFilterByCategory({
                category,
            })
        );
    }

    removeAsTemplate(): void {
        this._store.dispatch(StudioProjectStoreActions.projectSaveBoardAsTemplate(
            {
                projectTemplate: false
            }
        ));
    }

}
