export const locale = {
    lang: 'fr',
    data: {
        CHECKLIST_TITLE: 'Titre de Checklist',
        ADD_CHECKLIST: 'Checklist',
        ADD_ACTION_PLAN: 'Ajouter une action',
        TITLE: 'Titre',
        DUE_DATE: 'Date d\'échéance',
        RESPONSIBLE: 'Responsable',
        DESCRIPTION: 'Description',
        REMOVE_CHECKLIST: 'Retirer la checklist',
        NEW_CHECKLIST: 'Nouvel élément checklist',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
        CLOSE: 'Fermer',
        PREVIOUS: 'Précédent',
        NEXT: 'Suivant',
        ADD_TO_CARD_LABEL: 'Ajouter à la tâche'
    }
};
