import { FuseSidebarService } from './../../../../@fuse/components/sidebar/sidebar.service';
import { Observable } from 'rxjs';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { formatDistance } from 'date-fns';
import { fr } from 'date-fns/esm/locale';
import { enUS } from 'date-fns/esm/locale';
import { arDZ } from 'date-fns/esm/locale';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    StudioNotificationsStoreActions,
    StudioNotificationsStoreSelectors,
} from 'app/root-store/studio-store/notifications-store';
import { RootStoreState } from 'app/root-store';
import { StandbyCardModel } from '@bsuccess/models/standbycard/standbycard.model';
import { locale as english } from '../../../i18n/layout/components/quick-panel/en';
import { locale as frensh } from '../../../i18n/layout/components/quick-panel/fr';
import { locale as arabic } from '../../../i18n/layout/components/quick-panel/ar';
import { StudioProjectStoreActions } from 'app/root-store/studio-store/project-store';
import { LoginStoreSelectors, LoginStoreActions } from 'app/root-store/login-store';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class QuickPanelComponent implements OnInit {
    date: Date;
    events: any[];
    notes: any[];
    notifications$: Observable<StandbyCardModel[]>;
    inAppNotifications$: Observable<any>;
    inAppNotificationsLenght = 0;
    incomingNotifs: StandbyCardModel[];
    filteredIncomingNotifs: StandbyCardModel[];
    settings: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    page = 1;

    /**
     * Constructor
     */
    constructor(
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        private _fuseSidebarService: FuseSidebarService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true,
        };
        this.notifications$ = this._store.pipe(
            select(StudioNotificationsStoreSelectors.loadNotifications)
        );
        this.inAppNotifications$ = this._store.pipe(
            select(LoginStoreSelectors.selectInAppNotifications)
        );

        this.inAppNotifications$.subscribe(inAppNotifications => {
            this.inAppNotificationsLenght = inAppNotifications.notificationSize ;
        });

        this.notifications$.subscribe(notif => {
            this.incomingNotifs = notif;
            this.filteredIncomingNotifs = this.incomingNotifs.sort(
                (a, b) =>
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
        });


    }

    ngOnInit(): void {
    }

    openActionDialog(notification): void {
        this._store.dispatch(
            StudioNotificationsStoreActions.showNewActionDialog({
                notification,
            })
        );
    }

    onPageChange(event: PageEvent) {
        this._store.dispatch(
            LoginStoreActions.getInAppNotification(
                {
                    pageNumber: event.pageIndex + 1,
                }
            )
        );

    }

    getDateString(date): string {
        if (this.translateService.currentLang.toString() === 'fr') {
            return formatDistance(new Date(date + 'Z'), new Date(), {
                addSuffix: true,
                locale: fr,
            });
        } else {
            if (this.translateService.currentLang.toString() === 'en') {
                return formatDistance(new Date(date + 'Z'), new Date(), {
                    addSuffix: true,
                    locale: enUS,
                });
            } else {
                return formatDistance(new Date(date + 'Z'), new Date(), {
                    addSuffix: true,
                    locale: arDZ,
                });
            }
        }
    }

    toggleSidebarOpen(key: any): void {
        this._fuseSidebarService.getSidebar(key).close();
    }

    getCurrentPageSize(): number {
        console.log(this.paginator.pageSize)
        return this.paginator.pageSize;
    }

    getCurrentPageIndex(): number {
        return this.paginator.pageIndex;
    }

    openSummaryDialog(summaryType): void {
        this._store.dispatch(StudioProjectStoreActions.showSummaryDialog({
            summaryType
        }));
    }

    goToMentionedPage(link: string): void {
        window.location.href = link;
    }

    readNotification(mention: any): void {
        if(!mention.read) {
            this._store.dispatch(LoginStoreActions.readInAppNotification({
                id: mention._id
            }))
        }
    }

    getCardId(url: string ): string {
        return url.split('=')[1].split('&')[0];
    }    
    
    getListId(url: string ): string {
        return url.split('=')[2]
    }
}
