import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { StudioServicesStoreEffects } from './effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('studio-services', reducer),
        EffectsModule.forFeature([StudioServicesStoreEffects]),
    ],
    providers: [],
})
export class StudioServicesStoreModule { }
