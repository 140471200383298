import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Socket } from 'ngx-socket-io';
import { withLatestFrom, map, tap } from 'rxjs/operators';

import * as SocketIOActions from '../actions';
import { RootStoreState } from 'app/root-store';
import { StudioBoardStoreActions, StudioBoardStoreSelectors } from 'app/root-store/studio-store/board-store';
import { StudioProjectStoreActions } from 'app/root-store/studio-store/project-store';

@Injectable()
export class StudioSocketIOStoreBoardEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _socket: Socket
    ) { }

    // Catch all animator brainstorming success actions and emit them
    animatorBrainstorming$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.loadCurrentSuccess,
                ),
                tap(_ => {
                    this._store.dispatch(
                        SocketIOActions.joinBoard({
                            payload: {
                                boardId: _.board.id,
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    // Update Board Details
    updateBoardDetailsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.updateBoardDetailsSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardUpdateBoardDetailsSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    boardId: _.boardId,
                                    name: _.name,
                                    description: _.description
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateBoardDetailsSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardUpdateBoardDetailsSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.updateBoardDetailsViaSocket({
                    boardId: _.boardId,
                    name: _.name,
                    description: _.description
                })
            );
        });


    // Update members
    updateBoardMembersSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioProjectStoreActions.updateBoardMembersSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardUpdateBoardMembersSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    users: _.users
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateBoardMembersSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardUpdateBoardMembersSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioProjectStoreActions.updateBoardMembersViaSocket({
                    users: _.users
                })
            );
        });

    // Update Responsible
    updateCardResponsibleSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.updateCardResponsibleSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardUpdateResponsibleSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    cardId: _.cardId,
                                    idResponsible: _.idResponsible
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateBoardResponsibleSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardUpdateResponsibleSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.updateCardResponsibleViaSocket({
                    cardId: _.cardId,
                    idResponsible: _.idResponsible
                })
            );
        });

    // Update Add Attachment
    addAttachmentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.addAttachmentSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardAddAttachmentSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    attachment: _.attachment,
                                    card: _.card
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addAttachmentSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardAddAttachmentSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.addAttachmentViaSocket({
                    boardId: _.boardId, 
                    attachment: _.attachment,
                    card: _.card
                })
            );
        });

    // Update Remove Attachment
    removeAttachmentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.removeAttachmentSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardRemoveAttachmentSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    attachmentId: _.attachmentId,
                                    cardId: _.cardId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeAttachmentSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardRemoveAttachmentSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.removeAttachmentViaSocket({
                    attachmentId: _.attachmentId,
                    cardId: _.cardId
                })
            );
        });

    // Add Card
    addCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.addCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardAddCardSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    card: _.card,
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardAddCardSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.addCardViaSocket({
                    card: _.card,
                    listId: _.listId
                })
            );
        });

    // Remove Card
    removeCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.removeCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardRemoveCardSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    cardId: _.cardId,
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardRemoveCardSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.removeCardViaSocket({
                    listId: _.listId,
                    cardId: _.cardId
                })
            );
        });

    // Move Card
    moveCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.moveCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardMoveCardSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    move: _.move,
                                    lists: _.lists
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    moveCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardMoveCardSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.moveCardViaSocket({
                    lists: _.lists,
                    move: _.move
                })
            );
        });

    // Add List
    addListSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.addListSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardAddListSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    list: _.list
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addListSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardAddListSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.addListViaSocket({
                    list: _.list,
                })
            );
        });

    // Remove List
    removeListSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.removeListSuccess
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardRemoveListSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeListSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardRemoveListSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.removeListViaSocket({
                    listId: _.listId
                })
            );
        });

    // Move List
    moveListSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.moveListSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardMoveListSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    lists: _.lists,
                                    move: _.move
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    moveListSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardMoveListSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.moveListViaSocket({
                    lists: _.lists,
                    move: _.move
                })
            );
        });

    // List Name Change
    listNameChangeSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.renameListSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardListNameChangeSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    listId: _.listId,
                                    name: _.name
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    listNameChangeSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardListNameChangeSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.renameListViaSocket({
                    listId: _.listId,
                    name: _.name
                })
            );
        });

    // Card Name Change
    cardNameChangeSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.updateCardNameSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardCardNameChangeSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    cardId: _.cardId,
                                    name: _.name
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    cardNameChangeSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardCardNameChangeSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.updateCardNameViaSocket({
                    name: _.name,
                    cardId: _.cardId
                })
            );
        });

    // Card Description Change
    updateCardDescriptionSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.updateCardDescriptionSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardUpdateCardDescriptionSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    cardId: _.cardId,
                                    description: _.description
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateCardDescriptionSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardUpdateCardDescriptionSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.updateCardDescriptionViaSocket({
                    description: _.description,
                    cardId: _.cardId
                })
            );
        });

    // Card START Date Change
    updateCardStartDateSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.updateCardStartDateSuccess
                    ,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardUpdateCardStartDateSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    cardId: _.cardId,
                                    startDate: _.startDate,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    
    // Card END Date Change
    updateCardEndDateSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.updateCardEndDateSuccess
                    ,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardUpdateCardEndDateSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    cardId: _.cardId,
                                    endDate: _.endDate,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateCardStartDateSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardUpdateCardStartDateSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.updateCardStartDateViaSocket({
                    startDate: _.startDate,
                    cardId: _.cardId
                })
            );
        });

        updateCardEndDateSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardUpdateCardEndDateSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.updateCardEndDateViaSocket({
                    endDate: _.endDate,
                    cardId: _.cardId
                })
            );
        });

    // Card Add Comment
    addCardCommentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.addCardCommentSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardAddCardCommentSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    cardId: _.cardId,
                                    comment: _.comment
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addCardCommentSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardAddCardCommentSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.addCardCommentViaSocket({
                    cardId: _.cardId,
                    comment: _.comment
                })
            );
        });

    // Card Delete Comment
    deleteCommentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.deleteCommentSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardDeleteCommentSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    cardId: _.cardId,
                                    commentId: _.commentId,
                                    id: _.id
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    deleteCommentSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardDeleteCommentSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.deleteCommentViaSocket({
                    cardId: _.cardId,
                    commentId: _.commentId,
                    id: _.id
                })
            );
        });

    // Card Add CheckList
    addChecklistSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.addChecklistSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardAddChecklistSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    cardId: _.cardId,
                                    checklist: _.checklist
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addChecklistSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardAddChecklistSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.addChecklistViaSocket({
                    cardId: _.cardId,
                    checklist: _.checklist
                })
            );
        });

    // Card Add CheckItem
    addcheckItemSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.addcheckItemSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardAddcheckItemSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    checkItemForm: _.checkItemForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addcheckItemSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardAddcheckItemSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.addcheckItemViaSocket({
                    checkItemForm: _.checkItemForm
                })
            );
        });

    // Card Remove CheckList
    removeCheckListSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.removeCheckListSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardRemoveCheckListSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    checkListForm: _.checkListForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeCheckListSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardRemoveCheckListSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.removeCheckListViaSocket({
                    checkListForm: _.checkListForm
                })
            );
        });

    // Card Remove CheckItem
    removeCheckItemSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.removeCheckItemSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardRemoveCheckItemSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    checkListForm: _.checkListForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeCheckItemSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardRemoveCheckItemSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.removeCheckItemViaSocket({
                    checkListForm: _.checkListForm
                })
            );
        });

    // Card Update CheckItem
    updateCheckItemSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.updateCheckItemSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardUpdateCheckItemSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    checkItemForm: _.checkItemForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateCheckItemSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardUpdateCheckItemSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.updateCheckItemViaSocket({
                    checkItemForm: _.checkItemForm
                })
            );
        });

    // Card Toggle Member
    toggleMemberSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.toggleMemberSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardToggleMemberSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    toggleMemberForm: _.toggleMemberForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    toggleMemberSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardToggleMemberSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.toggleMemberViaSocket({
                    toggleMemberForm: _.toggleMemberForm
                })
            );
        });

    // Card Toggle Label
    toggleLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.toggleLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardToggleLabelSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    toggleForm: _.toggleForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    toggleLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardToggleLabelSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.toggleLabelViaSocket({
                    toggleForm: _.toggleForm
                })
            );
        });


    // Card Remove Label
    removeLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.removeLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardRemoveLabelSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    labelId: _.labelId,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardRemoveLabelSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.removeLabelViaSocket({
                    labelId: _.labelId,
                })
            );
        });

    // Card Add Label
    addLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.addLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardAddLabelSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    id: _.id,
                                    name: _.name,
                                    color: _.color,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardAddLabelSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.addLabelViaSocket({
                    color: _.color,
                    name: _.name,
                    id: _.id
                })
            );
        });

    // Card Update Label
    updateLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.updateLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardUpdateLabelSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    color: _.color,
                                    name: _.name,
                                    id: _.id
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardUpdateLabelSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.updateLabelViaSocket({
                    color: _.color,
                    name: _.name,
                    id: _.id
                })
            );
        });

    // Duplicate Card 
    duplicateCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.duplicateCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            StudioBoardStoreSelectors.selectCurrent
                        )
                    )
                ),
                map(([_, board]) => {
                    this._store.dispatch(
                        SocketIOActions.boardDuplicateCardSuccess({
                            payload: {
                                boardId: board.id,
                                data: {
                                    duplicate: _.duplicate,
                                    card: _.card
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    duplicateCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.boardDuplicateCardSuccess.type)
        .subscribe(_ => {
            this._store.dispatch(
                StudioBoardStoreActions.duplicateCardViaSocket({
                    card: _.card,
                    duplicate: _.duplicate
                })
            );
        });

}


