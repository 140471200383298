import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { HeaderStoreEffects } from './effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('header', reducer),
        EffectsModule.forFeature([HeaderStoreEffects]),
    ],
    providers: [],
})
export class HeaderStoreModule {}
