export const locale = {
    lang: 'en',
    data: {
        START: 'Start Brainstorm',
        SELECT: 'Choose one or more colors of cards for this brainstorm',
        COLOR: 'Colors are made of a card color and a label that you define.',
        ALL_CATEGORIES: 'All colors',
        CARD_CATEGORIES: 'Colors',
        CANCEL: 'Cancel',
    }
};
