import { createReducer, Action, on } from '@ngrx/store';

import { initialState, State } from './state';
import { WorkshopCardboardStoreActions } from '.';
import { CardBoardService } from '@bsuccess/services/cardboard.service';
import { WorkshopTimerStoreActions } from '../timer-store';
import { NavbarStoreActions } from '../../navbar-store';
import { LoginStoreActions } from '../../login-store';
import { WorkshopActivitiesStoreActions } from '../activities-store';

const cardboardReducer = createReducer(
    initialState,
    on(
        NavbarStoreActions.logoutConfirmed,
        NavbarStoreActions.leaveWorkshopConfirmed,
        LoginStoreActions.notAuthorized,
        () => initialState
    ),
    on(WorkshopCardboardStoreActions.updateState, (state, { newState }) => ({
        ...state,
        dimension: newState.dimension,
        chapters: newState.dimension.chapters,
        chapter: newState.chapter,
        items: newState.chapter.items,
        item: newState.item,
        articles: newState.articles,
    })),
    on(WorkshopCardboardStoreActions.flipAll, state => ({
        ...state,
        articles: CardBoardService.flipAll(state.articles),
    })),
    on(WorkshopCardboardStoreActions.flipBackAll, state => ({
        ...state,
        articles: CardBoardService.flipBackAll(state.articles),
    })),
    on(WorkshopCardboardStoreActions.hideAll, state => ({
        ...state,
        articles: CardBoardService.hideAll(state.articles),
    })),
    on(WorkshopCardboardStoreActions.showAll, state => ({
        ...state,
        articles: CardBoardService.showAll(state.articles),
    })),
    on(WorkshopCardboardStoreActions.shuffle, state => ({
        ...state,
        articles: CardBoardService.shuffle(state.articles),
    })),
    on(WorkshopCardboardStoreActions.next, state => {
        const newState = CardBoardService.next(state);
        return {
            ...state,
            ...newState
        };
    }),
    on(WorkshopCardboardStoreActions.previous, state => {
        const newState = CardBoardService.previous(state);
        return {
            ...state,
            ...newState
        };
    }),
    on(WorkshopCardboardStoreActions.selectArticle, (state, { id }) => ({
        ...state,
        selectedArticle: id,
    })),
    on(WorkshopCardboardStoreActions.updateRate, (state, { rates }) => {
        return {
            ...state,
            items: state.item
                ? [
                    ...state.items.map(_ => {
                        if (state.item._id === _._id) {
                            _.result.rates = rates;
                            _.result.avg = CardBoardService.avg(rates);
                            _.result.chart = CardBoardService.updateChatSeries(
                                rates,
                                _.result.chart
                            );
                            return _;
                        }
                        return _;
                    }),
                ]
                : state.items,
            chapters: !state.item
                ? [
                    ...state.chapters.map(_ => {
                        if (state.chapter._id === _._id) {
                            _.result.rates = rates;
                            _.result.avg = CardBoardService.avg(rates);
                            _.result.chart = CardBoardService.updateChatSeries(
                                rates,
                                _.result.chart
                            );
                            return _;
                        }
                        return _;
                    }),
                ]
                : state.chapters,
            item: state.item
                ? {
                    ...state.item,
                    result: {
                        ...state.item.result,
                        rates,
                        avg: CardBoardService.avg(rates),
                        chart: CardBoardService.updateChatSeries(
                            rates,
                            state.item.result.chart
                        ),
                    },
                }
                : state.item,
            chapter: state.item
                ? state.chapter
                : {
                    ...state.chapter,
                    result: {
                        ...state.chapter.result,
                        rates,
                        avg: CardBoardService.avg(rates),
                        chart: CardBoardService.updateChatSeries(
                            rates,
                            state.chapter.result.chart
                        ),
                    },
                },
            ecartType: CardBoardService.hasError(rates),
        };
    }),
    on(WorkshopCardboardStoreActions.updateDimension, (state, { id }) => {
        const dimension = state.dimensions.find(_ => _._id === id);
        return {
            ...state,
            dimension,
            chapters: dimension.chapters,
            chapter: dimension.chapters[0],
            items: dimension.chapters[0].hasItems
                ? dimension.chapters[0].items
                : null,
            item: dimension.chapters[0].hasItems
                ? dimension.chapters[0].items[0]
                : null,
            articles: dimension.chapters[0].hasItems
                ? dimension.chapters[0].items[0].articles
                : dimension.chapters[0].articles,
        };
    }),
    on(WorkshopCardboardStoreActions.updateChapter, (state, { id }) => {
        const chapter = state.chapters.find(_ => _._id === id);
        return {
            ...state,
            chapter,
            items: chapter.hasItems ? chapter.items : null,
            item: chapter.hasItems ? chapter.items[0] : null,
            articles: chapter.hasItems
                ? chapter.items[0].articles
                : chapter.articles,
        };
    }),
    on(WorkshopCardboardStoreActions.updateItem, (state, { id }) => {
        const item = state.items.find(_ => _._id === id);
        return {
            ...state,
            item,
            articles: item.articles,
        };
    }),
    on(WorkshopCardboardStoreActions.loadCardboardSuccess, (state, { dimensions }) => ({
        ...state,
        dimensions: dimensions,
        dimension: dimensions[0],
        chapters: dimensions[0].chapters,
        chapter: dimensions[0].chapters[0],
        items: dimensions[0].chapters[0].hasItems
            ? dimensions[0].chapters[0].items
            : [],
        item: dimensions[0].chapters[0].hasItems
            ? dimensions[0].chapters[0].items[0]
            : null,
        articles: dimensions[0].chapters[0].hasItems
            ? dimensions[0].chapters[0].items[0].articles
            : dimensions[0].chapters[0].articles,
    })),
    on(WorkshopCardboardStoreActions.addComment, (state, { comment }) => ({
        ...state,
        item: state.item
            ? {
                ...state.item,
                result: {
                    ...state.item.result,
                    comments: [...state.item.result.comments, comment],
                },
            }
            : state.item,
        chapter: state.item
            ? state.chapter
            : {
                ...state.chapter,
                result: {
                    ...state.chapter.result,
                    comments: [...state.chapter.result.comments, comment],
                },
            },
        items: state.item
            ? [
                ...state.items.map(_ => {
                    if (state.item._id === _._id) {
                        _.result.comments = [..._.result.comments, comment];
                        return _;
                    }
                    return _;
                }),
            ]
            : state.items,
        chapters: !state.item
            ? [
                ...state.chapters.map(_ => {
                    if (state.chapter._id === _._id) {
                        _.result.comments = [..._.result.comments, comment];
                        return _;
                    }
                    return _;
                }),
            ]
            : state.chapters,
    })),
    on(
        WorkshopCardboardStoreActions.loadCardboardChartSuccess,
        (state, { results }) => {
            return {
                ...state,
                globalChart: results,
            };
        }
    ),
    on(WorkshopTimerStoreActions.start, state => {
        return {
            ...state,
            articles: state.articles.map(_ => {
                return {
                    ..._,
                    flipped: true,
                };
            }),
        };
    }),
    on(WorkshopCardboardStoreActions.sendRate, (state, { value }) => ({
        ...state,
        articles: [
            ...state.articles.map(_ => {
                if (_.rank === value) {
                    return {
                        ..._,
                        selected: true,
                    };
                }
                return {
                    ..._,
                    selected: false,
                };
            }),
        ],
    })),
    on(WorkshopCardboardStoreActions.flipCard, (state, { value, card }) => {
        return {
            ...state,
            articles: state.articles.map(_ => {
                if (card._id === _._id) {
                    return {
                        ..._,
                        flipped: value,
                    };
                }
                return {
                    ..._,
                };
            }),
        };
    }),
    on(WorkshopCardboardStoreActions.drop, (state, { event }) => {
        return {
            ...state,
        };
    }),
    on(WorkshopCardboardStoreActions.downloadReport, state => {
        return {
            ...state,
            pendingReport: true
        };
    }),
    on(WorkshopActivitiesStoreActions.loadSuccess, state => (
        initialState
    )),
    on(WorkshopCardboardStoreActions.downloadReportSuccess, state => {
        return {
            ...state,
            pendingReport: false
        };
    })
);

export function reducer(state: State | undefined, action: Action): any {
    return cardboardReducer(state, action);
}
