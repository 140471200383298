import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SessionsService {
    constructor(private _httpClient: HttpClient) {}

    getSessions(): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectSessions}`
        );
    }
}
