export const locale = {
    lang: 'fr',
    data: {
        PROJECTS_DIALOGS_MEMBERS_DIALOG_MANAGE_MEMBERS: 'Gérer les membres',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_INVITAION: 'Invitez des membres à collaborer au projet. S\'ils ne disposent pas de compte Excelway, ils recevront une invitation à créer un compte.',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_MEMBERS: 'Membre',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_THE_EMAIL: 'Email',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_POSITION: 'Position',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_SPONSOR: 'Sponsor',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_ADMIN: 'Admin',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_ADD_USER_BUTTON_LABEL: 'Ajouter un utilisateur',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_RESPONSIBLE: 'Responsable',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_ROLE: 'Rôle',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_READING: 'Lecture',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_MODIFICATION: 'Modification',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_REMOVE: 'Retirer',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_SAVE: 'Enregistrer',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_CANCEL: 'Annuler',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_APPLY_CHANGES_ON_CHILDREN: 'Appliquer ces changements à tous les Ateliers, Tableaux et Sous-Projets de ce Projet.'
    }
};
