import { FuseNavigation } from '@fuse/types';

export interface State {
  product: string;
  navigation: FuseNavigation[];
  tasksPanelOpened: boolean;
  notesPanelOpened: boolean;
}

export const initialState: State = {
  product: 'studio',
  navigation: [],
  tasksPanelOpened: true,
  notesPanelOpened: true,
};
