import { UserModel } from './../models/user.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Activity } from '@bsuccess/models/activities/activity.model';

@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    constructor(private _httpClient: HttpClient) { }

    get(sessionKey: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}/activity`
        );
    }

    add(sessionKey: string, activity: Activity): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}/activity`,
            activity
        );
    }

    update(activityId: string, activity: {
        name: string,
        color: string
    }, sessionKey: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}/activity/${activityId}`,
            activity
        );
    }

    archive(activityId: string, sessionKey: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}/activity/archive/${activityId}`,
            {}
        );
    }

    getVersions(sessionKey: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.ProjectSessionContents}/${sessionKey}`
        );
    }

    toggleAnimator(user: UserModel, sessionKey: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}${environment.http.toggleAnimator}/${user._id}`, {}
        );
    }

    duplicateSessionActivity(activityId: string, sessionKey: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}/${environment.http.projectSessionDuplicateActivity}/${activityId}`, {}
        );
    }

    updateStartedBrainstormActivity(activity: Activity, sessionKey: string, brainstormStarted: boolean): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}/${environment.http.brainstormingUpdateStarted}/${activity._id}`, {
            brainstormStarted
        }
        );
    }

    updateStartedEvaluationActivity(activity: Activity, sessionKey: string, evaluationStarted: boolean): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectSession}/${sessionKey}/${environment.http.brainstormingUpdateEvaluationStarted}/${activity._id}`, {
            evaluationStarted
        }
        );
    }

    updateSessionRole(sessionKey: string, user: any, role: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.toggleRole}/${sessionKey}`,
            {
                sessionRole: role,
                userId: user._id
            });
    }

    addUsersByRole(sessionId: string, emails: any, sessionRole: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.addUsersByRole}/${sessionId}`,
            {
                emails,
                sessionRole
            });
    }

    deleteUser(sessionId: string, userId: any, sessionRole: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.removeUserByRole}/${sessionId}`,
            {
                userId,
                sessionRole
            });
    }
}
