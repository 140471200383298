import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../../src/app/i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../../src/app/i18n/@fuse/components/confirm-dialog/fr';
import { UserModel } from '@bsuccess/models/user.model';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { RootStoreState } from 'app/root-store';
import { ProfileStoreSelectors } from 'app/root-store/profile-store';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AccessStoreActions } from 'app/root-store/access-store';

@Component({
    selector: 'fuse-update-subscription-dialog',
    templateUrl: './update-subscription-dialog.component.html',
    styleUrls: ['./update-subscription-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseUpdateSubscriptionDialogComponent {

    user$: Observable<UserModel>;
    user: UserModel;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseUpdateSubscriptionDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseUpdateSubscriptionDialogComponent>,
        public translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        private _store: Store<RootStoreState.State>,
        private httpClient: HttpClient,
        private _matDialog: MatDialog,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh);
    }

    ngOnInit(): void {
        this.user$ = this._store.pipe(select(ProfileStoreSelectors.selectUser));
        this.user$.subscribe((user) => {
          this.user = user;
        });
      }

    updateAppSumoPlan(): void {
        window.open("https://appsumo.com/account/products/", "_blank");
    }

    showPricingPlans(): void {
        this._matDialog.closeAll();
        this._store.dispatch(AccessStoreActions.showPricingDialog());
    }
    
    userPortal(): void {
        const payload = new HttpParams()
        .set('customer', this.user.customer.id)
        .set('return_url', 'https://example.com/account');
        const headers_object = new HttpHeaders();
        this.httpClient.post('https://api.stripe.com/v1/billing_portal/sessions', payload).subscribe((result: any) => {
            window.open(result.url);
        })
    }
}
