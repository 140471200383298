import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';

export interface State {
    filtredFavorites: ProjectBoardModel[];
    filtredOthers: ProjectBoardModel[];
    favorites: ProjectBoardModel[];
    others: ProjectBoardModel[];
}

export const initialState: State = {
    favorites: [],
    others: [],
    filtredFavorites: [],
    filtredOthers: [],
};
