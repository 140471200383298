export const locale = {
    lang: 'ar',
    data: {
        BOARD_FILTER_CARDS_TITLE: 'فَلْتَرَة المهام',
        BOARD_FILTER_CARDS_WHERE: 'حيت',
        BOARD_FILTER_CARDS_IS:'هو',
        BOARD_FILTER_CARDS_MEMBER:'عضو',
        BOARD_FILTER_CARDS_RESPONSABLE:'المسؤول',
        BOARD_FILTER_CARDS_DATE_LIMITE:'تاريخ الاستحقاق',
        BOARD_FILTER_CARDS_PRIORITY:'الأولوية',
        BOARD_FILTER_CARDS_CATEGORIES:'العلامة',
        BOARD_FILTER_CARDS_ADD_FILTER:'+ إضافة عامل تصفية',
        BOARD_FILTER_CARDS_FILTER:'فَلْتَرَة',
        BOARD_FILTER_CARDS_CANCEL:'إلغاء',
        BOARD_FILTER_CARDS_CH_ONE : 'اليوم',
        BOARD_FILTER_CARDS_CH_TWO : 'غدا',
        BOARD_FILTER_CARDS_CH_THREE : 'الأمس',
        BOARD_FILTER_CARDS_CH_FOUR : 'السبعة أيام القادمة',
        BOARD_FILTER_CARDS_CH_FIVE : 'السبعة أيام الماضية',
        BOARD_FILTER_CARDS_CH_SIX : 'البارحة',
        BOARD_FILTER_CARDS_PR_HIGH : 'أولوية عالية',
        BOARD_FILTER_CARDS_PR_MID : 'أولوية متوسطة',
        BOARD_FILTER_CARDS_PR_LOW : 'أولوية منخفضة',
        BOARD_FILTER_RESET : 'فلترة جديدة',
    }
};
