import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Socket } from 'ngx-socket-io';
import { map, withLatestFrom } from 'rxjs/operators';

import * as SocketIOActions from '../actions';
import {
    WorkshopCardboardStoreSelectors,
    WorkshopCardboardStoreActions,
} from 'app/root-store/workshop-store/cardboard-store';
import { UserModel } from '@bsuccess/models/user.model';
import { RootStoreState } from 'app/root-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { WorkshopTimerStoreSelectors } from '../../timer-store';
import { WorkshopActivityStoreSelectors } from '../../activity-store';

@Injectable()
export class WorkshopSocketIOStoreCardboardEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _socket: Socket
    ) { }

    // Catch all animator cardboard success actions and emit them
    animatorBrainstorming$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCardboardStoreActions.loadCardboardSuccess,
                    WorkshopCardboardStoreActions.flipAll,
                    WorkshopCardboardStoreActions.flipBackAll,
                    WorkshopCardboardStoreActions.hideAll,
                    WorkshopCardboardStoreActions.showAll,
                    WorkshopCardboardStoreActions.shuffle,
                    WorkshopCardboardStoreActions.updateChapter,
                    WorkshopCardboardStoreActions.updateDimension,
                    WorkshopCardboardStoreActions.updateItem,
                    WorkshopCardboardStoreActions.next,
                    WorkshopCardboardStoreActions.previous,
                    WorkshopCardboardStoreActions.updateState
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopCardboardStoreSelectors.selectCurrentState)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopTimerStoreSelectors.selectTimerState
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopActivityStoreSelectors.selectCurrentActivity
                        )
                    )
                ),
                map(([[[[_, [sessionRole, sessionKey]], cardboard], timer], activity]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateRoom({
                                payload: {
                                    sessionKey,
                                    route: '/workshop/cardboard',
                                    state: cardboard,
                                    contentId: activity.contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    addComment$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardboardStoreActions.addComment),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopCardboardStoreSelectors.selectCurrentState)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopTimerStoreSelectors.selectTimerState
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopActivityStoreSelectors.selectCurrentActivity
                        )
                    )
                ),
                map(([[[[_, [sessionRole, sessionKey]], cardboard], timer], activity]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateRoom({
                                payload: {
                                    sessionKey,
                                    route: '/workshop/cardboard',
                                    state: cardboard,
                                    timer: timer.start,
                                    exercise: timer.exercise,
                                    balance: timer.balance,
                                    maxPerCard: timer.maxPerCard,
                                    contentId: activity.contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorReceiveCardboardRate$ = this._socket
        .fromEvent<{ sessionKey: string; rate: number; user: UserModel }>(
            SocketIOActions.sendCardboardRate.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([payload, role]: [any, string]) => {
            if (role === 'animator') {
                this._store.dispatch(
                    WorkshopCardboardStoreActions.receivedRate({
                        rate: payload.rate,
                        user: payload.user,
                    })
                );
            }
        });

    animatorReceiveCardboardComment$ = this._socket
        .fromEvent<{ sessionKey: string; comment: string; user: UserModel }>(
            SocketIOActions.sendCardboardComment.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([payload, role]: [any, string]) => {
            if (role === 'animator') {
                this._store.dispatch(
                    WorkshopCardboardStoreActions.receivedComment({
                        comment: payload.comment,
                        user: payload.user,
                    })
                );
            }
        });

    participantSendRate$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardboardStoreActions.sendRate),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoggedUser)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopActivityStoreSelectors.selectCurrentActivity
                        )
                    )
                ),
                map(([[[_, [sessionRole, sessionKey]], user], activity]) => {
                    if (sessionRole === 'participant') {
                        this._store.dispatch(
                            SocketIOActions.sendCardboardRate({
                                payload: {
                                    sessionKey: sessionKey,
                                    rate: _.value,
                                    user: user,
                                    contentId: activity.contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    participantSendComment$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardboardStoreActions.sendComment),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoggedUser)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopActivityStoreSelectors.selectCurrentActivity
                        )
                    )
                ),
                map(([[[_, [sessionRole, sessionKey]], user], activity]) => {
                    if (sessionRole === 'participant') {
                        this._store.dispatch(
                            SocketIOActions.sendCardboardComment({
                                payload: {
                                    sessionKey: sessionKey,
                                    comment: _.value,
                                    user: user,
                                    contentId: activity.contentId
                                },
                            })
                        );
                    } else {
                        this._store.dispatch(
                            WorkshopCardboardStoreActions.addComment({
                                comment: {
                                    email: user.email,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    value: _.value,
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    emit$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    SocketIOActions.sendCardboardRate,
                    SocketIOActions.sendCardboardComment
                ),
                map(action => this._socket.emit(action.type, action.payload))
            ),
        { dispatch: false }
    );
}
