export const locale = {
    lang: 'ar',
    data: {
        CHECKLIST_TITLE: 'عنوان القائمة مرجعية',
        ADD_CHECKLIST: ' قائمة مرجعية',
        ADD_ACTION_PLAN: 'أضف مُهمّة',
        TITLE: 'عنوان',
        DUE_DATE: 'تاريخ الاستحقاق',
        DESCRIPTION: 'الوصف',
        RESPONSIBLE: 'المالك',
        REMOVE_CHECKLIST: 'إزالة قائمة مرجعية',
        NEW_CHECKLIST: 'عنصر قائمة مرجعية جديد',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
        CLOSE: 'أغلق',
        PREVIOUS: 'السابق',
        NEXT: 'التالي',
        ADD_TO_CARD_LABEL: 'أضف إلى المهمة'
    }
};
