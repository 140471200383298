import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { FuseNavigation } from '@fuse/types';

export const selectNavbarState = createFeatureSelector<State>('navbar');

export const selectProduct = createSelector(
  selectNavbarState,
  (state: State): string => state.product
);

export const selectNavigation = createSelector(
  selectNavbarState,
  (state: State): FuseNavigation[] => state.navigation
);

export const selectTasksPanelOpened = createSelector(
  selectNavbarState,
  (state: State): boolean => state.tasksPanelOpened
);

export const selectNotesPanelOpened = createSelector(
  selectNavbarState,
  (state: State): boolean => state.notesPanelOpened
);
