export const locale = {
    lang: 'en',
    data: {
        PLACE: 'Where do you want to place these cards?',
        ACTIVITY: 'Activity',
        LIST: 'List',
        CANCEL: 'Cancel',
        SAVE: 'Save',
    }
};
