import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as arabic } from '../../../../../../i18n/studio/projects/board/dialogs/card/ar';
import { locale as english } from '../../../../../../i18n/studio/projects/board/dialogs/card/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/board/dialogs/card/fr';

import { fuseAnimations } from '@fuse/animations';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'app/root-store';
import { StudioProjectStoreActions } from 'app/root-store/studio-store/project-store';

@Component({
  selector: 'add-board-modal',
  templateUrl: './add-board-modal.component.html',
  styleUrls: ['./add-board-modal.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AddBoardModalComponent implements OnInit {
  boardName = '';

  constructor(
    public dialogRef: MatDialogRef<AddBoardModalComponent>,
    private translationLoaderService: FuseTranslationLoaderService,
    public translateService: TranslateService,
    private _store: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.translationLoaderService.loadTranslations(english, frensh, arabic);
  }

  ngOnInit(): void {}

  save(): void {
    this._store.dispatch(
      StudioProjectStoreActions.addBoardById({
        board: {
          name: this.boardName,
          description: '',
        },
      })
    );
    this.dialogRef.close();
  }
}
