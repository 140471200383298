import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';

export interface State {
    filtredSessions: ProjectSessionModel[];
    sessions: ProjectSessionModel[];
    pendingReportToken: string;
}

export const initialState: State = {
    filtredSessions: [],
    sessions: [],
    pendingReportToken: null
};
