import { createAction, props } from '@ngrx/store';

import { ServiceModel } from '@bsuccess/models/project/service.model';

export const load = createAction('[Studio][Services Page] Load Services');

export const loadSuccess = createAction(
    '[Studio][Services API] Load Services Success',
    props<{
        response: any[];
    }>()
);

export const loadFailure = createAction(
    '[Studio][Services API] Load Services Failure',
    props<{ error: string }>()
);

export const updateServices = createAction('[Studio][Project Services Dialog] Update',
    props<{ services: ServiceModel }>());

export const updateServicesConfirmed = createAction(
    '[Studio][Project Services] Update Confirmed',
    props<{ services: ServiceModel }>()
);

export const updateServicesCancelled = createAction(
    '[Studio][Project Services] Update Cancelled'
);

export const updateServicesSuccess = createAction(
    '[Studio][Project Services] Update Services Success',
    props<{ services: ServiceModel }>()
);

export const updateServicesFailure = createAction(
    '[Studio][Services API] Update Services Failure',
    props<{ error: string }>()
);
