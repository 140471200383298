export const locale = {
    lang: 'fr',
    data: {
        ACTIVITY_SETTINGS_SETTINGS_BUTTON: 'Paramètres de l\'activité',
        ACTIVITY_SETTINGS_TITLE_MODAL_SECTION: 'Affichage',
        ACTIVITY_SETTINGS_COLOR_MODAL_SECTION: 'Montrer le label de couleur dans les cartes',
        ACTIVITY_SETTINGS_MEMBER_MODAL_SECTION: 'Montrer le nom de l\'auteur de la carte',
        ACTIVITY_SETTINGS_SHOW_ALL_CARD: 'Les participants ne peuvent voir que les cartes qu\'ils ont rédigé.'
    }
};
