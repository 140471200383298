export const locale = {
    lang: 'ar',
    data: {
        EDIT_SERVICES: 'تحرير الخدمات',
        FILTER: 'فلْتَرَة',
        TYPE: 'النوع',
        SERVICE_NAME: 'اسم الخدمة',
        OWNER: 'المالِك',
        CREADTION_DATE: 'تاريخ البدء',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
    }
};
