export const locale = {
    lang: 'ar',
    data: {
        EXPORT_ACTIONS: 'قُمْ بِتصديرِ الأعمال إلى لوحة أخر',
        APPROVED_ACTIONS: 'يجب الموافقة على الإجراءات من قبل مسؤولي المشروع ليتم تصديرها إلى الجدول المحدد',
        EXPORT_ACTIONS_BOARD: 'تصدير إلى لوحة أخرى',
        APPROVED_ACTIONS_BOARD: 'يجب الموافقة على الإجراء من قبل مسؤولي اللوحة ليتم تصديره إلى اللوحة المحددة.',
        ADD_COMMENT: 'يمكنك إضافة تعليق لمرافقة طلب الموافقة هذا',
        COMMENT: 'تعليق',
        EXPORT: 'تصْدير',
        CANCEL: 'إلغاء',
        ADD_ACTIONS:  'قُمْ بِاضافة الأعمال إلى لوحة أخرى',
        ADDED_ACTIONS: 'يجب الموافقة على الإجراءات من قبل مسؤولي المشروع ليتم اضافتها إلى الجدول المحدد',
        ADD_ACTIONS_BOARD: 'إضافة إلى لوحة أخرى',
        ADDED_ACTIONS_BOARD: 'يجب الموافقة على الإجراء من قبل مسؤولي اللوحة ليتم اضافته إلى اللوحة المحددة.',
        ADD: 'إضافة',
    }
};
