import { SignUpUserModel } from './../../../@bsuccess/models/user-sign-up.model';
import { createAction, props } from '@ngrx/store';
import { Params } from '@angular/router';
import { UserModel } from '@bsuccess/models/user.model';
import { LoginModel } from '@bsuccess/models/login.model';

export const load = createAction(
    '[Access Page] Load',
    props<{ key: string; params: Params }>()
);

export const init = createAction(
    '[Access Page] Init',
    props<{ key: string; params: Params }>()
);

export const loadPublicSession = createAction(
    '[Access Page] Load Public Session',
    props<{ key: string; sessionKey: string; tenant: string }>()
);

export const loadPrivateSession = createAction(
    '[Access Page] Load Private Session',
    props<{ key: string; userId: string; sessionKey: string; tenant: string }>()
);

export const loadObject = createAction(
    '[Access Page] Load Object',
    props<{
        key: string;
        param: string;
        userId: string;
        object: string;
        objectId: string;
        tenant: string;
        parentId: string;
    }>()
);

export const generateUserSuccess = createAction(
    '[Access Page] Generate User Success',
    props<{ user: UserModel, newUser: boolean }>()
);

export const generateUserFailure = createAction(
    '[Access Page] Generate User Failure',
    props<{ error: string }>()
);

export const getUserbyId = createAction(
    '[Access Page] Get User by Id',
    props<{ userId: string; tenant: string }>()
);

export const getUserbyIdSuccess = createAction(
    '[Access Page] Get User by Id Success',
    props<{ user: UserModel; tenant: string }>()
);

export const getUserbyIdFailure = createAction(
    '[Access Page] Get User by Id Failure',
    props<{ error: any }>()
);

export const getUserSuccess = createAction(
    '[Access Page] Get User Success',
    props<{ user: UserModel }>()
);

export const getUserFailure = createAction(
    '[Access Page] Get User Failure',
    props<{ error: any }>()
);

export const uploadProfileImage = createAction(
    '[Studio][Project Page] Upload Profile Image',
    props<{ content: any; name: string }>()
);

export const uploadProfileImageSuccess = createAction(
    '[Studio][Project API] Upload Profile Image Success',
    props<{ response: any; name: string }>()
);

export const uploadProfileImageFailure = createAction(
    '[Studio][Project API] Upload Profile Image Failure',
    props<{ error: string }>()
);

export const updatePassword = createAction(
    '[Access Page] Update Password',
    props<{
        firstName: string;
        lastName: string;
        password: string;
        lang: string;
        profilePic?: string;
    }>()
);

export const updatePasswordSuccess = createAction(
    '[Access Page] Update Password Success',
    props<{
        password: string;
    }>()
);

export const updatePasswordFailure = createAction(
    '[Access Page] Update Password Failure',
    props<{
        error: string;
    }>()
);

export const login = createAction(
    '[Access Page] Login',
    props<{
        login: LoginModel;
    }>()
);

export const loginSuccess = createAction(
    '[Access Page] Login Success',
    props<{
        token: string;
    }>()
);

export const loginFailure = createAction(
    '[Access Page] Login Failure',
    props<{
        error: string;
    }>()
);

export const updateForm = createAction(
    '[Access Page] Update Form',
    props<{ form: string }>()
);

export const updateWelcomeType = createAction(
    '[Access Page] Update Welcome Type',
    props<{ welcome: string }>()
);

export const changePassword = createAction(
    '[Access Page] Change Password',
    props<{
        password: string;
    }>()
);

export const changePasswordSuccess = createAction(
    '[Access Page] Change Password Success',
    props<{
        password: string;
    }>()
);

export const changePasswordFailure = createAction(
    '[Access Page] Change Password Failure',
    props<{
        error: string;
    }>()
);

export const showTermsDialog = createAction('[Access Page] Show Terms Dialog');

export const showPaymentDialog = createAction('[Access] Show Payment Dialog');

export const showPaymentChangePlanDialog = createAction('[Access] Show Payment Change Plan Dialog',
    props<{
        newPlan: any;
    }>()
);

export const showPricingDialog = createAction('[Access] Show Pricing Dialog');

export const showUpdateSubscriptionDialog = createAction('[Access] Show Update Subscription Dialog');

export const showPolicyDialog = createAction('[Access Page] Show Policy Dialog');

export const showAddOnsDialog = createAction('[Access] Show Add Ons Dialog');

export const selectPricing = createAction('[Access] Select Pricing', props<{
    plan: any
}>());

export const signUpUser = createAction(
    '[Access Page] Sign Up User',
    props<{
        user: SignUpUserModel, tenant: string
    }>()
);

export const signUpUserSuccess = createAction(
    '[Access Page] Sign Up User Success',
    props<{
        token: string;
        projectId: string;
        sessionId: string;
        sessionToken: string;
        user: UserModel;
    }>()
);

export const signUpUserFailure = createAction(
    '[Access Page] Sign Up User Failure',
    props<{
        error: string;
    }>()
);

export const UserMissingName = createAction(
    '[Access Page] User FirstName & LastName Not Found',
);

export const getPlans = createAction(
    '[Access API] Get Plans',
);

export const getPlansSuccess = createAction(
    '[Access API] Get Plans Success',
    props<{
        plans: any[]
    }>()
);

export const getPlansFailure = createAction(
    '[Access API] Get Plans Failure',
    props<{
        error: string;
    }>()
);

export const attachPaymentMethodAndSubscribe = createAction(
    '[Access API] Attach Payment Method',
    props<{
        paymentMethodId: string;
        selectedPrice: string;
    }>()
);

export const attachPaymentMethodSuccess = createAction(
    '[Access API] Attach Payment Method Success',
    props<{
        response: any;
        paymentMethodId: string;
        selectedPrice: string;
    }>()
);

export const attachPaymentMethodFailure = createAction(
    '[Access API] Attach Payment Method Failure',
    props<{
        error: string;
    }>()
);

export const subscribe = createAction(
    '[Access API] Stripe Subscription',
    props<{
        price: string;
        defaultPaymentMethod: string;
    }>()
);

export const subscribeSuccess = createAction(
    '[Access API] Stripe Subscription Success',
    props<{
        response: any
    }>()
);

export const subscribeFailure = createAction(
    '[Access API] Stripe Subscription Failure',
    props<{
        error: string;
    }>()
);

export const getUserPaymentMehods = createAction(
    '[Access API] Get User Payment Mehtods',
);

export const getUserPaymentMehodsSuccess = createAction(
    '[Access API] Get User Payment Mehtods Success',
    props<{
        response: any
    }>()
);

export const getUserPaymentMehodsFailure = createAction(
    '[Access API] Get User Payment Mehtods Failure',
    props<{
        error: string;
    }>()
);

export const cancelSubscription = createAction(
    '[Access API] Cancel User Subscription',
);

export const cancelSubscriptionSuccess = createAction(
    '[Access API] Cancel User Subscription Success',
    props<{
        response: any
    }>()
);

export const cancelSubscriptionFailure = createAction(
    '[Access API] Cancel User Subscription Failure',
    props<{
        error: string;
    }>()
);

export const addAddon = createAction(
    '[Access API] Add Addon',
    props<{
        items: any[],
        defaultPaymentMethod: string,
    }>()
);

export const addAddonSuccess = createAction(
    '[Access API] Add Addon Success',
    props<{
        response: any
    }>()
);

export const addAddonFailure = createAction(
    '[Access API] Add Addon Failure',
    props<{
        error: string;
    }>()
);


export const changePlan = createAction(
    '[Access API] Change Subscription Plan',
    props<{
        items: any[]
    }>()
);

export const changePlanSuccess = createAction(
    '[Access API] Change Subscription Plan Success',
    props<{
        response: any
    }>()
);

export const changePlanFailure = createAction(
    '[Access API] Change Subscription Plan Failure',
    props<{
        error: string;
    }>()
);

export const openNewProjectDialog = createAction(
    '[Access API] Open New Project Dialog',
);

export const canCreateNewProject = createAction(
    '[Access API] Can Create New Project',
);

export const projectLimitReached = createAction(
    '[Access API] Project Limit Reached',
);

export const openUpdateMembersDialog = createAction(
    '[Access API] Open Update Members Dialog',
);

export const canUpdateMembers = createAction(
    '[Access API] Can Update members',
);

export const membersLimitReached = createAction(
    '[Access API] Members Limit Reached',
);

export const getUserInfo = createAction(
    '[Access API] Get User Info',
    props<{
        id: string;
    }>()
);

export const getUserInfoSuccess = createAction(
    '[Access API] Get User Info Success',
    props<{
        user: string;
    }>()
);

export const getUserInfoFailure = createAction(
    '[Access API] Get User Info Failure',
    props<{
        error: any;
    }>()
);

export const getTaxes = createAction(
    '[Access API] Get Taxes',
);

export const getTaxesSuccess = createAction(
    '[Access API] Get Taxes Success',
    props<{
        taxes: any[];
    }>()
);

export const getTaxesFailure = createAction(
    '[Access API] Get Taxes Failure',
    props<{
        error: any;
    }>()
);

export const checkout = createAction(
    '[Access API] Stripe Checkout',
    props<{
        id: string;
    }>()
);

export const checkoutSuccess = createAction(
    '[Access API] Stripe Checkout Success',
    props<{
        checkout: any;
    }>()
);

export const checkoutFailure = createAction(
    '[Access API] Stripe Checkout Failure',
    props<{
        error: any;
    }>()
);
