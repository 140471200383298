import { AccessDialogsModule } from './../access/dialogs/dialogs.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  StoreRouterConnectingModule,
  routerReducer,
  RouterState,
} from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';

import { ProjectDialogsModule } from 'app/studio/projects/project/dialogs/dialogs.module';
import { environment } from 'environments/environment';
import { AnimatorBoardDialogsModule } from 'app/workshop/brainstorming/animator/board/dialogs/dialogs.module';
import { SessionDialogsModule } from 'app/studio/projects/session/dialogs/dialogs.module';
import { WorkshopStoreModule } from './workshop-store/workshop-store.module';
import { WorkshopAnimatorDialogsModule } from 'app/workshop/activities/animator/dialogs/dialogs.module';
import { StudioStoreModule } from './studio-store/studio-store.module';
import { WorkshopSocketIOStoreActions } from './workshop-store/socket-io-store';
import { LoginStoreModule } from './login-store/login-store.module';
import { NavbarStoreModule } from './navbar-store/navbar-store.module';
import { HeaderStoreModule } from './header-store/header-store.module';
import { AccessStoreModule } from './access-store/access-store.module';
import { AnimatorBoardCanvasDialogsModule } from 'app/workshop/canvas/animator/board/dialogs/dialogs.module';
import { BoardDialogsModule } from 'app/studio/projects/board/dialogs/dialogs.module';
import { ProfileStoreModule } from './profile-store/profile-store.module';
import { ProfileDialogsModule } from 'app/profile/personal-info/dialogs/dialogs.module';
import { LoginDialogsModule } from 'app/login/dialogs/dialogs.module';
import { StudioProjectsStoreActions } from './studio-store/projects-store';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

export function actionPong(state: any, action: any): any {
  return (
    action.type !== WorkshopSocketIOStoreActions.pong.type &&
    action.type !== StudioProjectsStoreActions.imageRendered.type &&
    action.type !== StudioProjectsStoreActions.updateImageSuccess.type &&
    action.type !== StudioProjectsStoreActions.updateImage.type
  );
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    LoginStoreModule,
    StudioStoreModule,
    WorkshopStoreModule,

    NavbarStoreModule,
    HeaderStoreModule,
    AccessStoreModule,
    ProfileStoreModule,

    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    // Connects RouterModule with StoreModule
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'Excelway',
          maxAge: 50,
          predicate: actionPong,
        })
      : [],

    ProjectDialogsModule,
    SessionDialogsModule,
    BoardDialogsModule,
    AccessDialogsModule,
    AnimatorBoardDialogsModule,
    WorkshopAnimatorDialogsModule,
    AnimatorBoardCanvasDialogsModule,
    ProfileDialogsModule,
    LoginDialogsModule,
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
  ],
})
export class RootStoreModule {}
