export const locale = {
    lang: 'en',
    data: {
        EDIT: 'Edit information',
        OCCUPATION: 'Occupation',
        TEAM: 'Team',
        COMPANY: 'Company',
        SAVE: 'Save',
        CANCEL: 'Cancel',
    }
};
