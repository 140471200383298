import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootStoreState } from 'app/root-store';
import { Observable } from 'rxjs';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {
  PaymentMethod,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import {
  AccessStoreActions,
  AccessStoreSelectors,
} from 'app/root-store/access-store';
import { StripeCardComponent, StripeService } from 'ngx-stripe';

import { locale as arabic } from '../../../i18n/access/dialogs/payment/ar';
import { locale as english } from '../../../i18n/access/dialogs/payment/en';
import { locale as frensh } from '../../../i18n/access/dialogs/payment/fr';

@Component({
  selector: 'access-payment-dialog',
  templateUrl: 'payment-dialog.component.html',
  styleUrls: ['payment-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccessPaymentComponent implements OnInit {
  stripeCardValid: boolean = false;
  plans$: Observable<any[]>;
  taxes$: Observable<any[]>;
  selectedPlan: any;
  selectedPlan$: Observable<any>;
  paymentDone$: Observable<any>;
  paymentMethode: PaymentMethod;
  totalToPay = 0;

  pendingPayment$: Observable<boolean>;
  plan = 'option1';
  paymentType = '1';
  step = 0;

  purchaseDone: boolean = false;

  adonSelected = '0';
  addons = [
    {
      value:
        this.translateService.currentLang === 'en'
          ? 'None'
          : this.translateService.currentLang === 'fr'
          ? ''
          : 'لا شيء ',
      viewValue: '0',
    },
    {
      value:
        this.translateService.currentLang === 'en'
          ? '100 public participants (50$)'
          : this.translateService.currentLang === 'fr'
          ? ''
          : ' مئة مشارك عام - (50 دولارًا)',
      viewValue: '1',
    },
    {
      value:
        this.translateService.currentLang === 'en'
          ? '200 public participants (100$)'
          : this.translateService.currentLang === 'fr'
          ? ''
          : 'مئتي مشارك عام - (100 دولارًا)',
      viewValue: '2',
    },
    {
      value:
        this.translateService.currentLang === 'en'
          ? '300 public participants (150$)'
          : this.translateService.currentLang === 'fr'
          ? ''
          : 'ثلاثمائة مشارك عام - (300 دولارًا)',
      viewValue: '3',
    },
  ];

  @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale:
      this.translateService.currentLang === 'fr'
        ? 'fr'
        : this.translateService.currentLang === 'en'
        ? 'en'
        : 'ar',
  };

  stripeTest: UntypedFormGroup;
  billingInfo: UntypedFormGroup;
  registerForm: UntypedFormGroup;
  constructor(
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<AccessPaymentComponent>,
    private translationLoaderService: FuseTranslationLoaderService,
    private fb: UntypedFormBuilder,
    private stripeService: StripeService,
    private _store: Store<RootStoreState.State>
  ) {
    this.translationLoaderService.loadTranslations(english, frensh, arabic);
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  onChange(event) {
    console.log(event);
  }

  ngOnInit(): void {
    this.selectedPlan$ = this._store.pipe(
      select(AccessStoreSelectors.selectSelectedPlan)
    );
    this.pendingPayment$ = this._store.pipe(
      select(AccessStoreSelectors.selectPendingPayment)
    );
    this.paymentDone$ = this._store.pipe(
      select(AccessStoreSelectors.selectPaymentDone)
    );
    this.plans$ = this._store.pipe(select(AccessStoreSelectors.selectPlans));
    this.taxes$ = this._store.pipe(select(AccessStoreSelectors.selectTaxes));
    this.selectedPlan$.subscribe((selectedPlan) => {
      if (selectedPlan) {
        this.selectedPlan = selectedPlan;
        this.totalToPay = selectedPlan.amount / 100;
      }
    });
    this._store.dispatch(AccessStoreActions.getPlans());
    this._store.dispatch(AccessStoreActions.getTaxes());
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
    });
    this.billingInfo = this.fb.group({
      country: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
    });
    setTimeout(() => {
      console.log(this.card);
    }, 3000);
  }

  createToken(): void {
    this.stripeService
      .createPaymentMethod({
        type: 'card',
        card: this.card.element,
      })
      .subscribe((result) => {
        if (result) {
          this._store.dispatch(
            AccessStoreActions.attachPaymentMethodAndSubscribe({
              paymentMethodId: result.paymentMethod.id,
              selectedPrice: this.selectedPlan.id,
            })
          );
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
    this.purchaseDone = true;
  }

  selectPlan(plan): void {
    this.selectedPlan = plan;
  }

  onAddonsChange(): void {
    if (this.selectedPlan) {
      if (this.adonSelected === '1') {
        this.totalToPay = this.selectedPlan.amount / 100 + 50;
      } else {
        if (this.adonSelected === '2') {
          this.totalToPay = this.selectedPlan.amount / 100 + 100;
        } else {
          if (this.adonSelected === '3') {
            this.totalToPay = this.selectedPlan.amount / 100 + 150;
          } else {
            if (this.adonSelected === '0') {
              this.totalToPay = this.selectedPlan.amount / 100;
            }
          }
        }
      }
    }
  }

  addAddon(): void {
    this._store.dispatch(AccessStoreActions.showAddOnsDialog());
  }
}
