import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { Card } from '@bsuccess/models/brainstorming/card.model';
import { UserModel } from '@bsuccess/models/user.model';

export const selectCardratingState = createFeatureSelector<State>('workshop-cardrating');

export const selectCards = createSelector(
    selectCardratingState,
    (state: State): Card[] => state.cards
);

export const selectPoints = createSelector(
    selectCardratingState,
    (state: State): number => state.points
);

export const selectBalance = createSelector(
    selectCardratingState,
    (state: State): number => state.balance
);

export const selectMaxPerCard = createSelector(
    selectCardratingState,
    (state: State): number => state.maxPerCard
);

export const selectRatedUsers = createSelector(
    selectCardratingState,
    (state: State): UserModel[] => state.ratedUsers
);
