import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'bs-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent implements OnInit {
  imagesUrl = '';
  @Input() initials: string;
  @Input() menuFolded: boolean;
  @Input() haveImage = false;
  @Input() imageName: string;
  constructor() {}

  ngOnInit(): void {
    this.imagesUrl = environment.minio.url + '/storage/o/public/';
  }
}
