import { StudioSessionStoreSelectors } from 'app/root-store/studio-store/session-store';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../../../../i18n/workshop/session-settings/dialogs/confirm-privacy-change-dialog/en';
import { locale as frensh } from '../../../../../../i18n/workshop/session-settings/dialogs/confirm-privacy-change-dialog/fr';
import { locale as arabic } from '../../../../../../i18n/workshop/session-settings/dialogs/confirm-privacy-change-dialog/ar';

import { SessionModel } from '@bsuccess/models/session/session.model';
import { RootStoreState } from 'app/root-store';
import { Store, select } from '@ngrx/store';

@Component({
    selector: 'confirm-privacy-change-dialog',
    templateUrl: 'confirm-privacy-change-dialog.component.html',
    styleUrls: ['confirm-privacy-change-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmPrivacyChangeDialogComponent implements OnInit {
    public confirmMessage: string;
    session$: Observable<SessionModel>;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ConfirmPrivacyChangeDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<ConfirmPrivacyChangeDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.session$ = this._store.pipe(select(StudioSessionStoreSelectors.selectCurrent));
    }
}
