import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { DocumentModel } from '@bsuccess/models/project/document.model';
import {
    StudioDocumentStoreActions,
    StudioDocumentStoreSelectors,
} from 'app/root-store/studio-store/document-store';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/document-new-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/document-new-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/document-new-dialog/ar';

@Component({
    selector: 'document-new-dialog',
    templateUrl: './document-new-dialog.component.html',
    styleUrls: ['./document-new-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DocumentNewDialogComponent implements OnInit, OnDestroy {
    error$: Observable<string>;
    loading$: Observable<boolean>;
    lastUpload$: Observable<string>;
    currentDocument: DocumentModel;
    newDocumentForm: UntypedFormGroup;
    actionObject: DocumentModel;
    public disableUntilUpload = false;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public dialogRef: MatDialogRef<DocumentNewDialogComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this._unsubscribeAll = new Subject();
        this.dialogRef.disableClose = true;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

    }

    ngOnInit(): void {
        this.newDocumentForm = this.createNewDocumentForm();
        this._store
            .pipe(
                takeUntil(this._unsubscribeAll),
                select(StudioDocumentStoreSelectors.selectCurrent))
            .subscribe(currentDocument => {
                if (currentDocument) {
                    this.currentDocument = currentDocument;
                }
            });
        this.loading$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),
            select(StudioDocumentStoreSelectors.uploadStatus)
        );

        this.error$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),
            select(StudioDocumentStoreSelectors.selectUploadError)
        );

        this.lastUpload$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),
            select(StudioDocumentStoreSelectors.selectLastUpload)
        );
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    createNewDocumentForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            name: new UntypedFormControl(''),
            documentType: new UntypedFormControl(''),
        });
    }

    disableAll(): void {
        this.disableUntilUpload = true;
    }

    save(): void {
        this.actionObject = {
            name: this.newDocumentForm.value.name,
            documentType: this.newDocumentForm.value.documentType,
            attachmentId: this.currentDocument._id,
            contentType: this.currentDocument.contentType,
            filename: this.currentDocument.filename
        };
    }

    cancel(): void {
        this._store.dispatch(StudioDocumentStoreActions.addDocumentCancelled());
    }

    fileChange(event: any): void {
        const file = event.target.files[0];
        const eventObj = event;
        const target: HTMLInputElement = eventObj.target as HTMLInputElement;
        const files: FileList = target.files;

        const formData: FormData = new FormData();

        formData.append('file', files[0], files[0].name);

        this._store.dispatch(
            StudioDocumentStoreActions.uploadDocument({
                content: formData,
                name: files[0].name
            })
        );
    }
}
