import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    exhaustMap,
    map,
    catchError,
    withLatestFrom,
    tap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as ProfileActions from './actions';

import { RootStoreState } from '..';
import { AuthService } from '@bsuccess/services/auth.service';
import { LocalStorageService } from '@bsuccess/services/local-storage.service';
import { ProfileUpdateGeneralComponent } from 'app/profile/personal-info/dialogs/profile-update-general/profile-update-general.component';
import { ProfileUpdateOccupationComponent } from 'app/profile/personal-info/dialogs/profile-update-occupation/profile-update-occupation.component';
import { ProfileUpdateContactComponent } from 'app/profile/personal-info/dialogs/profile-update-contact/profile-update-contact.component';
import { LoginStoreSelectors } from '../login-store';
import { UsersService } from '@bsuccess/services/users.service';
import * as moment from 'moment';
import { NotificationService } from '@bsuccess/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProfileStoreEffects {
    constructor(
        private _actions$: Actions,
        private _store: Store<RootStoreState.State>,
        private _usersService: UsersService,
        private _localStorageService: LocalStorageService,
        private _router: Router,
        private _matDialog: MatDialog,
        private _notificationService: NotificationService,
        private _translatingService: TranslateService,
    ) { }

    showProfileGeneralDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProfileActions.showProfileGeneralDialog),
                map((_) => {
                    this._matDialog.open(ProfileUpdateGeneralComponent, {
                        panelClass: 'profile-update-general',
                    });
                })
            ),
        { dispatch: false }
    );

    showProfileOccupationDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProfileActions.showProfileOccupationDialog),
                map((_) => {
                    this._matDialog.open(ProfileUpdateOccupationComponent, {
                        panelClass: 'profile-update-occupation',
                    });
                })
            ),
        { dispatch: false }
    );

    showProfileContactDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProfileActions.showProfileContactDialog),
                map((_) => {
                    this._matDialog.open(ProfileUpdateContactComponent, {
                        panelClass: 'profile-update-contact',
                    });
                })
            ),
        { dispatch: false }
    );

    updateProfileGeneral$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProfileActions.updateProfileGeneral),
            withLatestFrom(this._store.select(LoginStoreSelectors.selectLoggedUser)),
            exhaustMap(([_, user]) =>
                this._usersService.updateGeneralInfo(user._id, _.user).pipe(
                    map((response: any) =>
                        ProfileActions.updateProfileGeneralSuccess({
                            user: _.user,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProfileActions.updateProfileGeneralFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateProfileOccupation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProfileActions.updateProfileOccupation),
            withLatestFrom(this._store.select(LoginStoreSelectors.selectLoggedUser)),
            exhaustMap(([_, user]) =>
                this._usersService.updateOccupation(user._id, _.user).pipe(
                    map((response: any) =>
                        ProfileActions.updateProfileOccupationSuccess({
                            user: _.user,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProfileActions.updateProfileOccupationFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateProfileContact$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProfileActions.updateProfileContact),
            withLatestFrom(this._store.select(LoginStoreSelectors.selectLoggedUser)),
            exhaustMap(([_, user]) =>
                this._usersService.updateContact(user._id, _.user).pipe(
                    map((response: any) =>
                        ProfileActions.updateProfileContactSuccess({
                            user: _.user,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProfileActions.updateProfileContactFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    uploadProfilePicture$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProfileActions.uploadProfilePicture),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
            ),
            exhaustMap(([action, user]) => {
                return this._usersService
                    .minioUpload(action.content, user._id + '/' + action.name)
                    .pipe(
                        map((response) =>
                            ProfileActions.uploadProfilePictureSuccess({
                                userId: user._id,
                                response: {
                                    _id: response.Metadata.token,
                                    filename: user._id + '/' + action.name,
                                    contentType: response.ContentType,
                                    uploadDate: moment(response.LastModified).unix(),
                                    size: response.ContentLength,
                                },
                            })
                        ),
                        catchError((error) =>
                            of(
                                ProfileActions.uploadProfilePictureFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    uploadAttachmentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProfileActions.uploadProfilePictureSuccess),
                tap((_) => {
                    this._store.dispatch(
                        ProfileActions.addProfilePicture({
                            userId: _.userId,
                            profilePicture: _.response.filename,
                        })
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    addAttachment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProfileActions.addProfilePicture),
            exhaustMap((_) =>
                this._usersService
                    .updateUserProfilePicture(_.userId, _.profilePicture)
                    .pipe(
                        map(response =>
                            ProfileActions.addProfilePictureSuccess({
                                userId: _.userId,
                                profilePicture: _.profilePicture,
                            })
                        ),
                        catchError((error) =>
                            of(
                                ProfileActions.addProfilePictureFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateUserPassword$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProfileActions.updateUserPassword),
            exhaustMap((action) => {
                return this._usersService
                    .updateUserPassword(action.email, action.oldPassword, action.newPassword)
                    .pipe(
                        map(() => {
                            this._notificationService.showSuccess(
                                this._translatingService.currentLang.toString() === 'fr' ?
                                    'Votre mot de passe a été changé avec succès' :
                                    this._translatingService.currentLang.toString() === 'en' ?
                                        'Your password was successfully changed' : 'تم تغيير كلمة السر بنجاح'
                            );
                            return ProfileActions.updateUserPasswordSuccess({
                                success: 'success'
                            }
                            );
                        }),
                        catchError(error =>
                            of(
                                ProfileActions.updateUserPasswordFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateUserNotice$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProfileActions.updateUserNotice),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
            ),
            exhaustMap(([_, user]) => {
                return this._usersService
                    .updateUserNotice(_.notice, user._id)
                    .pipe(
                        map((response) =>
                            ProfileActions.updateUserNoticeSuccess({
                                notice: _.notice,
                            })
                        ),
                        catchError((error) =>
                            of(
                                ProfileActions.updateUserNoticeFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateUserInformationsNoticeSuccess = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProfileActions.updateUserNoticeSuccess),
                tap(
                    _ => {
                        this._notificationService.showSuccess(
                            this._translatingService.currentLang.toString() === 'fr' ?
                                'Les informations on été mis à jour avec succès' :
                                this._translatingService.currentLang.toString() === 'en' ?
                                    'Informations have been successfully updated' :
                                    'تم تحديث المعلومات بنجاح'
                        );
                    }),
            ),
        { dispatch: false }
    );

    updateUserInformationsNoticeFailure = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProfileActions.updateUserNoticeFailure),
                tap(
                    _ => {
                        this._notificationService.showError(
                            this._translatingService.currentLang.toString() === 'fr' ?
                                'Problème d\'enregistrement' :
                                this._translatingService.currentLang.toString() === 'en' ?
                                    'Saving failed' :
                                    'فشل الحفظ'
                        );
                    }),
            ),
        { dispatch: false }
    );

    updateUserTimeZone$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProfileActions.updateUserTimeZone),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
            ),
            exhaustMap(([_, user]) => {
                return this._usersService
                    .updateTimeZone(_.timeZone, user._id)
                    .pipe(
                        map((response) =>
                            ProfileActions.updateUserTimeZoneSuccess({
                                timeZone: _.timeZone,
                            })
                        ),
                        catchError((error) =>
                            of(
                                ProfileActions.updateUserTimeZoneFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );
}
