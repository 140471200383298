import { Injectable } from '@angular/core';

import { CacheModel } from '@bsuccess/models/cache.model';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    // TODO decrypt cache
    getCache(): CacheModel {
        const cache = JSON.parse(localStorage.getItem('cache'));
        if (cache) {
            return cache;
        } else {
            return {
                product: 'studio',
                login: {
                    user: null,
                    tenant: null,
                    token: null,
                },
                cardboard: {
                    balance: 30,
                },
            };
        }
    }

    // TODO encrypt cache
    setCache(cache: CacheModel): void {
        localStorage.setItem('cache', JSON.stringify(cache));
    }

    clearCache(): void {
        this.setCache({
            ...this.getCache(),
            product: 'studio',
            login: {
                tenant: this.getCache().login.tenant ? this.getCache().login.tenant : null,
                token: null,
                user: null,
            },
            cardboard: {
                balance: 30,
            },
        });
    }
}
