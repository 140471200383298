import { createAction, props } from '@ngrx/store';

import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';

export const load = createAction('[Studio][Projects Page] Load Projects');

export const loadSuccess = createAction(
    '[Studio][Projects API] Load Projects Success',
    props<{
        response: { favorites: SubProjectModel[]; others: SubProjectModel[] };
    }>()
);

export const loadFailure = createAction(
    '[Studio][Projects API] Load Projects Failure',
    props<{ error: string }>()
);

export const updateImage = createAction(
    '[Studio][Projects API] Update Project Image',
    props<{ projectType: string; projectId: string; imageId: string }>()
);

export const updateImageSuccess = createAction(
    '[Studio][Projects API] Update Project Image Success',
    props<{ projectType: string; projectId: string; content: any }>()
);

export const updateImageFailure = createAction(
    '[Studio][Projects API] Update Project Image Failure',
    props<{ error: string }>()
);

export const imageRendered = createAction(
    '[Studio][Projects Page] Image Rendered',
    props<{ projectType: string; projectId: string; content: any }>()
);

export const addFavorite = createAction(
    '[Studio][Projects Page] Add Favorite',
    props<{ id: string }>()
);

export const addFavoriteSuccess = createAction(
    '[Studio][Projects Page] Add Favorite Success',
    props<{ id: string }>()
);

export const addFavoriteFailure = createAction(
    '[Studio][Projects Page] Add Project To Favorite Failure',
    props<{ error: string }>()
);

export const removeFavorite = createAction(
    '[Studio][Projects Page] Remove Favorite',
    props<{ id: string }>()
);

export const removeFavoriteSuccess = createAction(
    '[Studio][Projects Page] Remove Favorite Success',
    props<{ id: string }>()
);

export const removeFavoriteFailure = createAction(
    '[Studio][Projects Page] Remove Favorite Failure',
    props<{ error: string }>()
);

export const searchProject = createAction(
    '[Studio][Projects Page] Search Projects by status',
    props<{ text: string }>()
);

export const loadProjectsTree = createAction('[Studio][Projects Side Panel] Load Projects Tree');

export const loadProjectsTreeSuccess = createAction(
    '[Studio][Projects API] Load Projects Tree Success',
    props<{
        response: any;
    }>()
);

export const loadProjectsTreeFailure = createAction(
    '[Studio][Projects API] Load Projects Tree Failure',
    props<{ error: string }>()
);

export const loadWeeklySummaries = createAction('[Studio][Projects Side Panel] Load Weekly Summaries');

export const loadWeeklySummariesSuccess = createAction(
    '[Studio][Projects API] Load Weekly Summaries Success',
    props<{
        response: any;
    }>()
);

export const loadWeeklySummariesFailure = createAction(
    '[Studio][Projects API] Load Weekly Summaries Failure',
    props<{ error: string }>()
);

export const loadDailyySummaries = createAction('[Studio][Projects Side Panel] Load Daily Summaries');

export const loadDailyySummariesSuccess = createAction(
    '[Studio][Projects API] Load Daily Summaries Success',
    props<{
        response: any;
    }>()
);

export const loadDailyySummariesFailure = createAction(
    '[Studio][Projects API] Load Daily Summaries Failure',
    props<{ error: string }>()
);

export const filterSummaries = createAction(
    '[Studio][Projects Side Panel] Filter Summaries',
    props<{ projectId?: string , dueDate?: string , memberId?: string , isReset?: boolean }>()
);

export const filterSummariesSuccess = createAction(
    '[Studio][Projects API] Filter Summaries Success',
    props<{
        response: any;
    }>()
);

export const filterSummariesFailure = createAction(
    '[Studio][Projects API] Filter Summaries Failure',
    props<{ error: string }>()
);