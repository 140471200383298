import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import {
    StudioDocumentStoreSelectors
} from 'app/root-store/studio-store/document-store';
import { DocumentModel } from '@bsuccess/models/project/document.model';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/document-update-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/document-update-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/document-update-dialog/ar';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'document-update-dialog',
    templateUrl: './document-update-dialog.component.html',
    styleUrls: ['./document-update-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DocumentUpdateDialogComponent implements OnInit, OnDestroy {
    error$: Observable<string>;
    loading$: Observable<boolean>;
    editDocumentForm: UntypedFormGroup;
    currentDocument: DocumentModel;
    actionObject: DocumentModel;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public dialogRef: MatDialogRef<DocumentUpdateDialogComponent>,
        private _store: Store<RootStoreState.State>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this._unsubscribeAll = new Subject();

        this.dialogRef.disableClose = true;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.editDocumentForm = this.saveDocumentForm();
        this._store
            .pipe(
                takeUntil(this._unsubscribeAll),
                select(StudioDocumentStoreSelectors.selectCurrent))
            .subscribe(currentDocument => {
                if (currentDocument) {
                    this.currentDocument = currentDocument;
                }
            });
        this.editDocumentForm.controls['name'].setValue(this.data.name);
        this.editDocumentForm.controls['documentType'].setValue(
            this.data.documentType
        );

        this.loading$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),

            select(StudioDocumentStoreSelectors.uploadStatus)
        );

        this.error$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),

            select(StudioDocumentStoreSelectors.selectUploadError)
        );
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    saveDocumentForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            name: new UntypedFormControl(''),
            documentType: new UntypedFormControl(''),
        });
    }

    save(): void {
        this.actionObject = {
            _id: this.data._id,
            name: this.editDocumentForm.value.name,
            documentType: this.editDocumentForm.value.documentType,
            attachmentId: this.data.attachmentId,
        };
    }
}
