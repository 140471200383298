import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import {
    StudioSessionStoreSelectors,
    StudioSessionStoreActions,
} from 'app/root-store/studio-store/session-store';
import { locale as english } from '../../../../../i18n/studio/projects/session/dialogs/update-members-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/session/dialogs/update-members-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/session/dialogs/update-members-dialog/ar';

@Component({
    selector: 'session-update-members-dialog',
    templateUrl: './update-members-dialog.component.html',
    styleUrls: ['./update-members-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class SessionUpdateMembersDialogComponent implements OnInit {
    error$: Observable<string>;
    members: ProjectUserModel[];
    participants: ProjectUserModel[];
    constructor(
        public dialogRef: MatDialogRef<SessionUpdateMembersDialogComponent>,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService,
        @Inject(MAT_DIALOG_DATA)
        public data: any
    ) {
        this._store.dispatch(
            StudioSessionStoreActions.loadCurrent({
                id: this.data
            })
        );
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this._store
            .select(StudioSessionStoreSelectors.selectSessionUsers)
            .subscribe((users: ProjectUserModel[]) => {
                if (users) {
                    this.members = [...users.map(user => {
                        if (user.position === 'animator') {
                            user.isParticipant = false;
                        }
                        return user;
                    })];
                }

            });
        this._store.select(StudioSessionStoreSelectors.selectSessionParticipants)
            .subscribe((participants: ProjectUserModel[]) => {
                this.participants = participants;
            });

    }

    add(): void {
        this.members.push({
            email: null,
            position: 'member',
            role: 'write',
            isParticipant: true
        });
    }

    delete(index: number): void {
        this.members.splice(index, 1);
    }

    updateMember(index: number, member: ProjectUserModel): void {
        this.members[index] = member;
        if (this.members[index].position === 'animator') {
            this.members[index].isParticipant = false;
        } else {
            this.members[index].isParticipant = true;
        }
    }

    isParticipant(email: string): boolean {
        return this.participants.map(participant => participant.email).includes(email);
    }

    save(): void {
        this.members = this.members.map(_ => {
            if (_.email) {
                return {
                    ..._,
                    email: _.email.trim()
                };
            }
        }).filter(member => member);
        const list = this.members.filter(
            user => user.email !== null && this.validateEmail(user.email)
        );
        if (list.length !== 0) {
            this._store.dispatch(
                StudioSessionStoreActions.updateSessionUsers({
                    users: list.map(_ => {
                        return {
                            ..._,
                            email: _.email.trim().toLowerCase()
                        };
                    }),
                })
            );
        }
        this.dialogRef.close();
    }

    validateEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }


    animatorExists(): number {
        return this.members.filter(member => member.position === 'animator')
            .length;
    }


    arrayContainAnimator(): Observable<boolean> {
        return of(
            this.members.filter(user => user.position === 'animator').length > 0
        );
    }

    valueChange(member, event): void {
    }
}
