import { NgModule } from '@angular/core';

import { FuseCountdownComponent } from '@fuse/components/countdown/countdown.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        FuseCountdownComponent,
    ],
    imports: [
        TranslateModule
    ],
    exports: [
        FuseCountdownComponent
    ],
})
export class FuseCountdownModule {
}
