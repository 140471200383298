import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { StudioDocumentStoreEffects } from './effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('studio-document', reducer),
        EffectsModule.forFeature([StudioDocumentStoreEffects]),
    ],
    providers: [],
})
export class StudioDocumentStoreModule { }
