export const locale = {
    lang: 'fr',
    data: {
        EDIT: 'Modifier les informations',
        LAST: 'Nom',
        FIRST: 'Prenom',
        ABOUT: 'A propos',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
    }
};
