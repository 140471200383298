import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BoardModel } from '@bsuccess/models/board/board.model';

@Injectable({
  providedIn: 'root',
})
export class BoardsService {
  constructor(private _httpClient: HttpClient) {}

  getBoards(): Observable<any> {
    return this._httpClient.get(
      `${environment.http.url}${environment.http.projectBoards}`
    );
  }

  addFavorite(boardId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.http.url}${environment.http.userAddFavorite}/${boardId}`,
      {}
    );
  }

  removeFavorite(boardId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.http.url}${environment.http.userRemoveFavorite}/${boardId}`,
      {}
    );
  }
}
