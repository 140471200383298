export const locale = {
    lang: 'ar',
    data: {
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_EDIT_FIELD: 'تحرير المجال',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_NAME: 'اسم المجال',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_TYPE: 'نوع المجال',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_MULTIPLE: 'اختيار متعدد',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_TEXT: 'يتيح لك التحديد المتعدد تحديد خيار واحد أو أكثر من الخيارات المحددة مسبقًا المدرجة أدناه.',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_ADD_OPTION: 'أضف خيارًا',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_CANCEL: 'إلغاء',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_SAVE: 'حفظ',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_CATEGORIES: 'العلامات',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_ADD: 'إضافة',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_EDIT_CATEGORIES: 'تحرير الإشارة',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_ADD_CATEGORIES: 'إضافة إشارة',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_DELETE_CATEGORY: 'حذف الإشارة',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_NAME: 'إسم',
    }
};
