export const locale = {
    lang: 'en',
    data: {
        TASKS_TITLE: 'Tasks',
        TASK_LABEL: 'Task',
        CHECKLIST_TITLE: 'Checklist title',
        ADD: 'Add Checklist',
        TITLE: 'Title',
        RESPONSIBLE: 'Owner',
        DATE: 'Date',
        LIST_NAME: 'LIST NAME',
        DESCRIPTION: 'Description',
        REMOVE: 'Remove checklist',
        CHECKLIST:'Checklist',
        ATTACHEMENT:'Attachment',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        NEW_CHECKLIST: 'New checklist item',
        ADD_TO_TASK_LABEL: 'Add to task'
    }
};
