export const locale = {
    lang: 'ar',
    data: {
        // tslint:disable-next-line: max-line-length
        PARAGRAPH: 'قم بدعوة الأعضاء للتعاون في المشروع. إذا لم يكن لديهم حساب إكْسِلْوايْ ، فسوف يتلقون دعوة لإنشاء حساب',
        MEMBERS: 'عُضْو',
        THE_EMAIL: 'البريد الإلكتروني',
        POSITION: 'موضع',
        SPONSOR: 'الكفيل', 
        ADMIN: 'مشرف',
        ADD_USER_BUTTON_LABEL: 'إضافة مستخدم',
        RESPONSIBLE: 'المالك',
        ROLE: 'دَوْر',
        READING: 'مٌشاهِد',
        MODIFICATION: 'مُحَرّرْ',
        REMOVE: 'إزالة',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
        APPLY_CHANGES_ON_CHILDREN: 'تطبيق التغييرات على جميع اللوحات والمشاريع الفرعية المجاورة'
    }
};
