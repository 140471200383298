export const locale = {
    lang: 'fr',
    data: {
        BOARD_FILTER_CARDS_TITLE: 'Filtrer les actions',
        BOARD_FILTER_CARDS_WHERE: 'Où',
        BOARD_FILTER_CARDS_IS:'est',
        BOARD_FILTER_CARDS_MEMBER:'Membre',
        BOARD_FILTER_CARDS_RESPONSABLE:'Responsable',
        BOARD_FILTER_CARDS_DATE_LIMITE:'Date d\'échéance',
        BOARD_FILTER_CARDS_PRIORITY:'Priorité',
        BOARD_FILTER_CARDS_ADD_FILTER:'+ Ajouter un filtre',
        BOARD_FILTER_CARDS_FILTER:'Filtrer',
        BOARD_FILTER_CARDS_CATEGORIES :'Étiquette',
        BOARD_FILTER_CARDS_CANCEL:'Annuler',
        BOARD_FILTER_CARDS_CH_ONE : 'Aujourd\'hui',
        BOARD_FILTER_CARDS_CH_TWO : 'Demain',
        BOARD_FILTER_CARDS_CH_THREE : 'Hier',
        BOARD_FILTER_CARDS_CH_FOUR : 'Dans les 7 prochains jours',
        BOARD_FILTER_CARDS_CH_FIVE : 'Dans les 7 derniers jours',
        BOARD_FILTER_CARDS_CH_SIX : 'Hier',
        BOARD_FILTER_CARDS_PR_HIGH : 'Haute priorité',
        BOARD_FILTER_CARDS_PR_MID : 'Priorité moyenne',
        BOARD_FILTER_CARDS_PR_LOW : 'Priorité basse',
        BOARD_FILTER_RESET : 'Réinitialiser',
    }
};
