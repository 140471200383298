import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { StudioProjectStoreActions } from 'app/root-store/studio-store/project-store';
import { StudioBoardStoreSelectors } from 'app/root-store/studio-store/board-store';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/board-update-members-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/board-update-members-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/board-update-members-dialog/ar';

@Component({
    selector: 'board-update-members-dialog',
    templateUrl: './board-update-members-dialog.component.html',
    styleUrls: ['./board-update-members-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class BoardUpdateMembersDialogComponent implements OnInit {
    members: ProjectUserModel[] = [];
    constructor(
        public dialogRef: MatDialogRef<BoardUpdateMembersDialogComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.dialogRef.disableClose = true;

        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.members = [];
        this._store
            .select(StudioBoardStoreSelectors.selectBoardUsers)
            .subscribe((users: ProjectUserModel[]) => {
                if (this.members && users) {
                    this.members = [...users];
                }
            });
    }


    add(): void {
        this.members.push({
            email: null,
            position: 'member',
            role: 'read',
        });
    }

    delete(index): void {
        this.members.splice(index, 1);
    }

    updateMember(index: number, member: ProjectUserModel): void {
        this.members.map(old => {
            if (old._id === member._id) {
                old = member;
            }
        });
    }

    save(): void {
        this.members = this.members.map(_ => {
            return {
                ..._,
                email: _.email.trim()
            };
        });
        const list = this.members.filter(
            user => user.email !== null && this.validateEmail(user.email)
        );
        if (list.length !== 0) {
            this._store.dispatch(
                StudioProjectStoreActions.updateBoardMembers({
                    users: list.map(_ => {
                        return {
                            ..._,
                            email: _.email.trim().toLowerCase()
                        };
                    }),
                })
            );
        }
        this.dialogRef.close();
    }

    validateEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
}
