export const locale = {
    lang: 'ar',
    data: {
        PAYMENT_DIALOG_SELECTED_PLAN:'الخطة المختارة :' ,
        PAYMENT_DIALOG_Business : 'اعمال',
        PAYMENT_DIALOG_Team : 'فريق',
        PAYMENT_DIALOG_PAYMENT_SECCESSFUL: 'تم الدفع بنجاح' ,
        PAYMENT_DIALOG_COPY_SENT_TO_EMAIL: 'تم إرسال نسخة من هذه المعاملة إلى بريدك الإلكتروني.' ,
        PAYMENT_DIALOG_THANK_YOU: 'شكرًا لاختيارك Excelway!' ,
        PAYMENT_DIALOG_PLEASE_WAIT: 'المرجو الانتضار' ,
        PAYMENT_DIALOG_PAYMENT_METHOD: 'طريقة الدفع' ,
        PAYMENT_DIALOG_OTHER: ' آخر ' ,
        PAYMENT_DIALOG_CREDIT_CARD_DETAILS: 'تفاصيل بطاقة الائتمان' ,
        PAYMENT_DIALOG_ADDS_ON: 'الإضافات' ,
        PAYMENT_DIALOG_HAVE_PROMO: 'هل تمتلك قَسيمة؟' ,
        PAYMENT_DIALOG_BILLING_INFORMATION: 'معلومات الفاتورة' ,
        PAYMENT_DIALOG_COUNTRY: 'الدولة' ,
        PAYMENT_DIALOG_BILLING_MOROCCO: 'المغرب' ,
        PAYMENT_DIALOG_BILLING_USA: 'الولايات المتحدة الأمريكية' ,
        PAYMENT_DIALOG_BILLING_FRANCE: 'فرنسا' ,
        PAYMENT_DIALOG_BILLING_POSTAL_CODE: 'الرمز البريدي' ,
        PAYMENT_DIALOG_WHAT_YOU_WILL_GET: 'ما ستحصل عليه' ,
        PAYMENT_DIALOG_UNLIMITED: ' عدد غير محدود من ' ,
        PAYMENT_DIALOG_MEMBERS: ' الأعضاء ' ,
        PAYMENT_DIALOG_GB: ' غيغابايت ' ,
        PAYMENT_DIALOG_STORAGE: ' من التخزين ' ,
        PAYMENT_DIALOG_FREE_PARTICIPANTS: ' مشاركين مجانًا في ورش العمل العامة ' ,
        PAYMENT_DIALOG_ADMIN_BILLING: ' صفحة فواتير المسؤول ' ,
        PAYMENT_DIALOG_YOU_BE_CHARGE: 'ستدفع' ,
        PAYMENT_DIALOG_DOLLAR: '$' ,
        PAYMENT_DIALOG_year: 'سنويا' ,
        PAYMENT_DIALOG_month: 'شهريا' ,
        PAYMENT_DIALOG_UNTIL_CANCEL: 'حتى تقوم بإلغاء الاشتراك. لمعرفة المزيد ، راجع الأسئلة الشائعة أو راسلنا عبر البريد الإلكتروني على support@excelway.co' ,
        PAYMENT_DIALOG_TOTAL: 'المجموع:' ,
        PAYMENT_DIALOG_PURSHASE: 'شراء' ,
        PAYMENT_DIALOG_CONFIRM: 'تأكيد' ,
    }
};
