export const locale = {
    lang: 'fr',
    data: {
        EDIT: 'Modifier les informations',
        OCCUPATION: 'Métier',
        TEAM: 'Equipe',
        COMPANY: 'Entreprise',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
    }
};
