import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { Activity } from '@bsuccess/models/activities/activity.model';

export const selectActivitiesState = createFeatureSelector<State>(
    'workshop-activities'
);

export const selectPending = createSelector(
    selectActivitiesState,
    (state: State): boolean => state.pending
);

export const selectError = createSelector(
    selectActivitiesState,
    (state: State): string => state.error
);

export const selectActivities = createSelector(
    selectActivitiesState,
    (state: State): any[] => state.activities
);

export const selectBrainstormActivities = createSelector(
    selectActivitiesState,
    (state: State): any[] => state.activities.filter((_: Activity) => _.activityType === 'brainstorm')
);

export const selectBrainstormAndCanvasActivities = createSelector(
    selectActivitiesState,
    (state: State): any[] => state.activities.filter((_: Activity) => _.activityType === 'brainstorm' || _.activityType === 'canvas')
);


export const selectCanvasActivities = createSelector(
    selectActivitiesState,
    (state: State): any[] => state.activities.filter((_: Activity) => _.activityType === 'canvas')
);

export const selectVersions = createSelector(
    selectActivitiesState,
    (state: State): any[] =>
        state.versions.filter(
            version => !state.activities.map(_ => _.contentId).includes(version._id)
        )
);

export const selectCanvasTemplates = createSelector(
    selectActivitiesState,
    (state: State): any[] => state.canvas
);

export const selectFiltredCanvasTemplates = createSelector(
    selectActivitiesState,
    (state: State): any[] => state.filtredCanvas
);

export const selectSelectedCategory = createSelector(
    selectActivitiesState,
    (state: State): any => state.selectedCategory
);
