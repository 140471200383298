import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { NewParentModel } from '@bsuccess/models/project/new-parent.model';
import { LevelModel } from '@bsuccess/models/project/level.model';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(private _httpClient: HttpClient) {}

  getProjects(): Observable<any> {
    return this._httpClient.get(
      `${environment.http.url}${environment.http.project}`
    );
  }

  addProject(project: NewParentModel | SubProjectModel): Observable<any> {
    return this._httpClient.post(
      `${environment.http.url}${environment.http.project}`,
      project
    );
  }
  addChildProject(
    subProject: SubProjectModel | NewParentModel,
    projectId: string
  ): Observable<any> {
    return this._httpClient.post(
      `${environment.http.url}${environment.http.projectChild}/${projectId}`,
      subProject
    );
  }

  addLevels(projectId: string, levels: LevelModel[]): Observable<any> {
    return this._httpClient.post(
      `${environment.http.url}${environment.http.projectLevels}/${projectId}`,
      levels
    );
  }

  updateLevels(projectId: string, levels: LevelModel[]): Observable<any> {
    return this._httpClient.put(
      `${environment.http.url}${environment.http.projectLevels}/${projectId}`,
      levels
    );
  }

  addFavorite(projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.http.url}${environment.http.userAddFavorite}/${projectId}`,
      {}
    );
  }

  removeFavorite(projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.http.url}${environment.http.userRemoveFavorite}/${projectId}`,
      {}
    );
  }

  getProjectsTree(): Observable<any> {
    return this._httpClient.get(
      `${environment.http.url}${environment.http.projectTree}`,
      {}
    );
  }

  addSubProject(project: any, closeParent: string): Observable<any> {
    return this._httpClient.post(
      `${environment.http.url}${environment.http.projectTree}/${closeParent}`,
      project
    );
  }

  getWeeklySummaries(): Observable<any> {
    return this._httpClient.get(
      `${environment.http.url}${environment.http.weeklyNotifications}`
    );
  }

  getDailySummaries(): Observable<any> {
    return this._httpClient.get(
      `${environment.http.url}${environment.http.dailyNotifications}`
    );
  }

  static filterSummaries(summaries: any ,idProject : string, selectedDate: string, idMember: string, isReset : boolean): any {

    summaries.allSummaries?.map(summariesTypes => {
      summariesTypes?.map(summaries => {
      summaries?.actions?.map(action => {

        // Reset or nothing selected
        if(!idProject && !selectedDate && !idMember || isReset){
          action.isHidden = false ;
        }

        // Only project selected
        if(idProject && !selectedDate && !idMember && !isReset){
          if(idProject == action.projectInfo.id){
            action.isHidden = false ;
          }else{
            action.isHidden = true ;
          }
        }

        // Only responsible selected
        if(!idProject && !selectedDate && idMember && !isReset){
          if(idMember == action.responsible?.id){
            action.isHidden = false ;
          }else{
            action.isHidden = true ;
          }
        }

        // Only date selected
        if(!idProject && selectedDate && !idMember && !isReset){
          if (selectedDate === 'today') {
            if (action?.endDate && moment().add(-1, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment()) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'tomorrow') {
            if (action?.endDate && moment().add(1, 'days') > moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) > moment()) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'yesterday') {
            if (action?.endDate && moment().add(-2, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment().add(-1, 'days')) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'next_seven') {
            if (action?.endDate && moment().add(7, 'days') > moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) > moment()) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'previous_seven') {
            if (action?.endDate && moment().add(-7, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment()) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
        }

        // Date and project selected
        if(idProject && selectedDate && !idMember && !isReset){
          if (selectedDate === 'today') {
            if (action?.endDate && moment().add(-1, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment() && 
            idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'tomorrow') {
            if (action?.endDate && moment().add(1, 'days') > moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) > moment() &&
            idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'yesterday') {
            if (action?.endDate && moment().add(-2, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment().add(-1, 'days') &&
            idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'next_seven') {
            if (action?.endDate && moment().add(7, 'days') > moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) > moment() &&
            idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'previous_seven') {
            if (action?.endDate && moment().add(-7, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment() &&
            idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
        }

        // Date and responsible selected
        if(!idProject && selectedDate && idMember && !isReset){
          if (selectedDate === 'today') {
            if (action?.endDate && moment().add(-1, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment() && 
            idMember == action.responsible?.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'tomorrow') {
            if (action?.endDate && moment().add(1, 'days') > moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) > moment() &&
            idMember == action.responsible?.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'yesterday') {
            if (action?.endDate && moment().add(-2, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment().add(-1, 'days') &&
            idMember == action.responsible?.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'next_seven') {
            if (action?.endDate && moment().add(7, 'days') > moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) > moment() &&
            idMember == action.responsible?.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'previous_seven') {
            if (action?.endDate && moment().add(-7, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment() &&
            idMember == action.responsible?.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
        }

        // Responsible and project selected
        if(idProject && !selectedDate && idMember && !isReset){
          if(idMember == action.responsible?.id && idProject == action.projectInfo.id){
            action.isHidden = false ;
          }else{
            action.isHidden = true ;
          }
        }

        // Date and responsible and project selected
        if(idProject && selectedDate && idMember && !isReset){
          if (selectedDate === 'today') {
            if (action?.endDate && moment().add(-1, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment() && 
            idMember == action.responsible?.id && idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'tomorrow') {
            if (action?.endDate && moment().add(1, 'days') > moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) > moment() &&
            idMember == action.responsible?.id && idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'yesterday') {
            if (action?.endDate && moment().add(-2, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment().add(-1, 'days') &&
            idMember == action.responsible?.id && idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'next_seven') {
            if (action?.endDate && moment().add(7, 'days') > moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) > moment() &&
            idMember == action.responsible?.id && idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
          if (selectedDate === 'previous_seven') {
            if (action?.endDate && moment().add(-7, 'days') < moment(new Date(action?.endDate)) && moment(new Date(action?.endDate)) < moment() &&
            idMember == action.responsible?.id && idProject == action.projectInfo.id) {
                action.isHidden = false ;
            } else {
                action.isHidden = true ;
            }
          }
        }
        });
      });
    });
    return summaries ;
  }
}
