import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as ServicesActions from './actions';

const servicesReducer = createReducer(
    initialState,
    on(ServicesActions.loadSuccess, (state, { response }) => ({
        ...state,
        others: response,
    }))
);

export function reducer(state: State | undefined, action: Action): any {
    return servicesReducer(state, action);
}
