import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { StudioBoardsStoreEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('studio-boards', reducer),
    EffectsModule.forFeature([StudioBoardsStoreEffects]),
  ],
  providers: [],
})
export class StudioBoardsStoreModule { }
