export const locale = {
    lang: 'fr',
    data: {
        PROJECT_NAME: 'Nom du projet',
        START_DATE: 'Date de début',
        END_DATE: 'Date de fin',
        DESCRIPTION: 'Description',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
        VALIDATORS_START_DATE_REQUIRED: 'La date de début est obligatoire.' ,
        VALIDATORS_END_DATE_REQUIRED: 'La date de fin est obligatoire.' ,  
        VALIDATORS_END_DATE_BEFORE: 'La date de début est postérieure à la date de fin' ,
        VALIDATORS_INVALID_START_TIME: 'L\'heure de début est invalide' ,
        VALIDATORS_INVALID_END_TIME: 'L\'heure de fin est invalide' ,  
        VALIDATORS_END_TIME_BEFORE: 'L\'heure de début est supérieure à l\'heure de fin' , 
    }
};
