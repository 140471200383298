export const locale = {
    lang: 'fr',
    data: {
        TASKS_PANEL_TASKS: 'Plus d\'actions',
        TASKS_PANEL_INCLUDE_BOARD: 'Inclure les tableaux des autres projets',
        TASKS_PANEL_ADD_CHECKLIST: 'Ajouter une checkliste',
        TASKS_PANEL_SAVE_CHANGES: 'Enregistrer les modifications',
        TASKS_PANEL_CHECKLIST_TITLE:'Titre de la checklist',
        TASKS_PANEL_PROJECT_LABEL_SELECT: 'Projet',
        TASKS_PANEL_BOARD_LABEL_SELECT: 'Tableau',
        TASKS_PANEL_COMPONENT_DESCRIPTION_FIRST_PART: 'L\'atelier a produit',
        TASKS_PANEL_COMPONENT_DESCRIPTION_SECOND_PART: 'actions. Vous pouvez désormais les exporter vers les tableaux de vos projets',
        TASKS_PANEL_EXPORT: 'Envoyée vers le tableau ',
        TASKS_PANEL_BOARD_SELECTOR: {
            BOARDS: 'Tableaux',
            ADD: 'Ajouter',
            ADD_BOARD: 'Ajouter Tableau',
            ADD_U: 'AJOUTER',
            NAME: 'Nom',
        },
        TASKS_PANEL_IN_TOTAL: 'au total',
        TASKS_PANEL_SELECT_ALL: 'Sélectionner toutes les actions à envoyer vers les tableaux',
        TASKS_PANEL_DUE: 'date d\'échéance',
        TASKS_PANEL_NO_DUE_DATE: 'Pas de date d\'échéance',
        TASKS_PANEL_VIEW: 'VUE',
        TASKS_PANEL_NO_CARDS: 'Oups, il semble que vous n’avez pas encore ajouté d\'actions.',
        TASKS_PANEL_TRY_ADD: 'Essayez d\'en ajouter ci-dessous.',
        TASKS_PANEL_ADD: 'Ajouter une action',
        TASKS_PANEL_TITLE: 'Titre',
        TASKS_PANEL_DESCRIPTION: 'description',
        TASKS_PANEL_DUE_DATE: 'date d\'échéance',
        TASKS_PANEL_OWNER: 'Responsable',
        TASKS_PANEL_BOARD: 'tableau',
        TASKS_PANEL_BOARD_NAME: 'Nom du tableau',
        TASKS_PANEL_ADD_TASK_HERE: 'Écrivez ce qu\'il faut faire ici ...',
        TASKS_PANEL_DELETE_TASK: 'Supprimer l\'action',
        TASKS_PANEL_CLOSE: 'Fermer et retourner au menu',
        TASKS_PANEL_NOT_SENT_YET: 'Pas encore envoyée',
        TASKS_PANEL_NEW_CHECKLIST:'Nouvel élément checklist',
        TASKS_PANEL_REMOVE:'Supprimer la checkliste',
        TASKS_PANEL_EXPORT_TO_BOARD: 'Envoyer vers le tableau',
        TASKS_PANEL_MOVE_TO_BOARD: 'Déplacer vers un tableau',
        TASKS_PANEL_MOVE_TO_BOARD_MOVE: 'Déplacer',
    }
};
