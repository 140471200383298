import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, map, withLatestFrom } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { RootStoreState } from 'app/root-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';

@Injectable({
    providedIn: 'root',
})
export class WorkshopGuard implements CanActivate {
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _store: Store<RootStoreState.State>
    ) { }

    canLoad(): Observable<boolean> {
        return this.isAuthenticated();
    }

    canActivate(): Observable<boolean> {
        return this.isAuthenticated();
    }

    private isAuthenticated(): Observable<boolean> {
        return this._store.select(LoginStoreSelectors.selectLoginToken).pipe(
            withLatestFrom(this._store.select(LoginStoreSelectors.selectLoginSessionKey)),
            take(1),
            map(([token, sessionKey]) => {
                if (
                    !this._authService.isTokenExpired(token)
                ) {
                    if (sessionKey) {
                        return true;
                    } else {
                        this._router.navigate(['studio/projects']);
                        return false;
                    }
                } else {
                    this._router.navigate(['login']);
                    return false;
                }
            }));
    }
}
