import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';

export interface State {
    users: ProjectUserModel[];
    error?: string;
}

export const initialState: State = {
    users: [],
};
