export const locale = {
    lang: 'en',
    data: {
        SESSION_NEW_DIALOG_NEW_GENERAL_SESSION_NAME: 'Workshop name',
        SESSION_NEW_DIALOG_NEW_GENERAL_DESCRIPTION: 'Description',
        SESSION_NEW_DIALOG_NEW_GENERAL_OBLIGATORY: 'Private Workshop : participants need to sign in',
        // tslint:disable-next-line: max-line-length
        SESSION_NEW_DIALOG_NEW_GENERAL_PARTICIPANTS_WITH_ACCOUNT: 'Participants who do not already have an Excelway account will need to create one before entering the workshop. They will thus have access to Excelway projects after the workshop.',
        SESSION_NEW_DIALOG_NEW_GENERAL_WITHOUT: 'Public Workshop : participants don\'t need to sign in',
        // tslint:disable-next-line: max-line-length
        SESSION_NEW_DIALOG_NEW_GENERAL_PARTICIPANTS_WITHOUT_ACCOUNT: 'Invited participants and anyone with the link can access the workshop without an Excelway account. They will not have access to Excelway projects after the workshop.',
        SESSION_NEW_DIALOG_NEW_GENERAL_START_DATE: 'Start date',
        SESSION_NEW_DIALOG_NEW_GENERAL_END_DATE: 'End date',
        SESSION_NEW_DIALOG_NEW_GENERAL_SAVE: 'Save',
        SESSION_NEW_DIALOG_NEW_GENERAL_CANCEL: 'Cancel',
        SESSION_NEW_DIALOG_NEW_GENERAL_ADD_TIME:'Add time',
        SESSION_NEW_DIALOG_NEW_GENERAL_START_TIME:'Start time',
        SESSION_NEW_DIALOG_NEW_GENERAL_END_TIME:'End time',
        VALIDATORS_START_DATE_REQUIRED: 'The start date is obligatory.' ,
        VALIDATORS_END_DATE_REQUIRED: 'The end date is obligatory.' ,  
        VALIDATORS_END_DATE_BEFORE: 'Start date is greater than end date' ,
        VALIDATORS_INVALID_START_TIME: 'The start time is invalid' ,
        VALIDATORS_INVALID_END_TIME: 'The end time is invalid' ,  
        VALIDATORS_END_TIME_BEFORE: 'Start time is greater than end time' , 
    }
};
