export const locale = {
    lang: 'ar',
    data: {
        MANAGE: 'إدارة الأعضاء',
        ADD_TO_SESSION: 'أضف المشاركين إلى الورشة',
        EMAIL: 'أدخل البريد الإلكتروني للمشارك واضغط على مفتاح الدخول',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
    }
};
