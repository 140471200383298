export const locale = {
    lang: 'ar',
    data: { 
        CHECKLIST_TITLE: 'عنوان قائمة مرجعية',
        TASK_LABEL: 'مهمة',
        ADD: 'إضافة قائمة مرجعية',
        TITLE: 'عنوان',
        RESPONSIBLE: 'الملك',
        DATE: 'التاريخ',
        LIST_NAME: 'اسم القائمة',
        DESCRIPTION: 'الوصف ',
        REMOVE: 'إزالة قائمة مرجعية',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
        CHECKLIST:'قائمة مرجعية',
        ATTACHEMENT:'المرفق',
        NEW_CHECKLIST: 'عنصر قائمة مرجعية جديدة',
        ADD_TO_TASK_LABEL: 'أضف إلى المهمة'
    }
};
