export const locale = {
  lang: 'ar',
  data: {
    SELECT: 'اخْتَر لوحة',
    CREATE: 'خلق النشاط',
    CANCEL: 'إلغاء',
    SEARCH: '...ابحث',
    ALL_TEMPLATES:'جميع النماذج',
    SALES_CUSTOMERS: 'المبيعات والعلاقات مع العملاء',
    PROJECT_MANAGEMENT: 'ادارة مشروع',
    HUMAN_RESSOURCES: 'الموارد البشرية',
    INFORMATION_SYSTEM: 'نظام المعلومات',
    BRAINSTORMING:'العصف الذهني',
    AGIL: 'رشيقة سير العمل',
    PRODUCT_MANAGEMENT:'ادارة المنتج',
    TEAM_BUILDING:'تشكيل الفريق',
    PROSPECTIVE:'حل المشاكل',
    STRATEGY:'الإستراتيجية',
    BACK_TEMPLATES:'رجوع إلى النماذج',
    USE_TEMPLATES: 'استخدم هذا النموذج', 
    BUSINESS_MODEL_CANVAS:'نموذج العمل التجاري', 
    BUSINESS_MODEL_CANVAS_DESCRIPTION:'احصل على نظرة شاملة لعملك لترى بوضوح كيف يخلق قيمة ويقدمها.',
    BUSINESS_MODEL_CANVAS_LONG_DESCRIPTION:'نموذج العمل التجاري هو قالب إدارة إستراتيجي لتطوير نماذج أعمال جديدة بشكل مرئي وتوثيق النماذج الموجودة. استنادًا إلى تسع كتل بناء ، تساعد لوحة نموذج الأعمال التجارية على توفير نظرة شاملة لاستراتيجية الأعمال التي تتمحور حول عرض القيمة. في مستند واحد ، قم بتخطيط ومواءمة عرض القيمة للأعمال ، وشرائح العملاء وعلاقاتهم ، والقنوات ، والشركاء الرئيسيين ، والأنشطة والموارد ، وهيكل التكلفة ، وتدفقات الإيرادات. <br/><br/> يعد قالب ExcelWay Business Model Canvas مشتقًا من "The Business Model Canvas" بواسطة شركة <a target="_blank" href="https://www.strategyzer.com/">Strategyzer AG</a> ، والمستخدم بموجب <a target="_blank" href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>.',              
    SWOT_ANALYSIS:'التحليل البيئي الرباعي (SWOT)',
    SWOT_ANALYSIS_DESCRIPTION:'قم بتحليل نقاط القوة والضعف والفرص والتهديدات الخاصة بشركتك.',
    SWOT_ANALYSIS_LONG_DESCRIPTION:'تحليل SWOT هو نشاط تخطيط استراتيجي لتقييم نقاط القوة والضعف والفرص والتهديدات الأساسية للشركة. نقاط القوة والضعف هي عناصر داخلية ، مثل الموظفين أو الأنشطة أو استراتيجية العمل. من ناحية أخرى ، تشير الفرص والتهديدات إلى عوامل خارجية ، مثل المنافسين أو تقلبات السوق أو اتجاهات المستهلكين.',
    FOUR_L_RETROSPECTIVE:'نشاط (4L)',
    FOUR_L_RETROSPECTIVE_DESCRIPTION:'تأمل في ما أعجب الفريق وتعلّمه وافتقده وما يتوق إليه.',
    FOUR_L_RETROSPECTIVE_LONG_DESCRIPTION:'يعد 4L Retrospective أسلوبًا بسيطًا بأثر رجعي للفرق للتفكير في سباقات السرعة أو المشاريع السابقة وتحديد إجراءات التحسين. خلال هذا النشاط ، يشارك أعضاء الفريق ما أحبوه وتعلموه وافتقدوه وتوقوا إليه من منظور واقعي وعاطفي.',
    LEAN_CANVAS:'مخطط النموذج الليّن',
    LEAN_CANVAS_DESCRIPTION:'عصف ذهني لنماذج الأعمال الممكنة باتباع Lean Startup',
    LEAN_CANVAS_LONG_DESCRIPTION:'يعد Lean Canvas إطارًا استراتيجيًا لتفكيك فكرة إلى افتراضات رئيسية وطرح الأفكار أو تقييم نماذج الأعمال المحتملة. استنادًا إلى طريقة Lean Startup ، يشتمل Lean Canvas على 9 كتل بناء تبدأ من مشاكل العميل وتسليط الضوء على الميزة غير العادلة للمشروع. تم تصميم Lean Canvas بواسطة Ash Maurya ، وهو مشتق من لوحة نموذج الأعمال التجارية من Alex Osterwalder.',
    PROJECT_CANVAS:'مشروع اللوحة',
    PROJECT_CANVAS_DESCRIPTION:'تلخيص كافة المعلومات الضرورية حول المشروع وتصويرها ومشاركتها.',
    PROJECT_CANVAS_LONG_DESCRIPTION:'يُعد Project Canvas أداة خرائط لتحديد العناصر الرئيسية للمشروع وعلاقتها في نظرة عامة واحدة. علاوة على ذلك ، فإنه يحفز التعاون والتواصل بين جميع الأطراف المعنية ويسمح بتوقع المشاكل المحتملة.  <br/><br/> أطلق Jim Kalbach مشروع Project Canvas ، المستوحى من Business Model Canvas.',
    RETROSPECTIVE_START_STOP_CONTINUE:'نشاط ابدأ ، توقف ، تابع',
    RETROSPECTIVE_START_STOP_CONTINUE_DESCRIPTION:'حدد ما تريد أن تبدأه وتوقفه وتستمر في القيام به في السباق التالي.',
    RETROSPECTIVE_START_STOP_CONTINUE_LONG_DESCRIPTION:'يعد النشاط ابدأ ، توقف ، تابع بأثر رجعي أسلوبًا استعاديًا عملي المنحى لمراجعة نتائج آخر سباق أو دورة مشروع وتخطيط التحسينات المستقبلية. من خلال التفكير في ما يجب أن يبدأه الفريق ، ويتوقف ويستمر في القيام به ، يقود المشاركون مناقشة حول سلوكيات العمل ويختارون تلك التي يجب الاحتفاظ بها ورفضها.',
    REVERSE_BRAINSTORMING:'العصف الذهني المُعاكِس',
    REVERSE_BRAINSTORMING_DESCRIPTION:'اقلب العصف الذهني رأسًا على عقب وقم بحل المسائل من خلال رؤية التحديات قبل الحلول.',
    REVERSE_BRAINSTORMING_LONG_DESCRIPTION:'العصف الذهني العكسي هو أسلوب التفكير لتوقع المشاكل التي قد تحدث أثناء المشروع وإيجاد حلول إبداعية. إنه يعتمد على الميل الطبيعي للبشر لتحديد التحديات بسهولة أكبر من الحلول. في هذا النشاط ، يبدأ المشاركون بالتفكير في جميع المشكلات المحتملة التي قد يواجهونها. ثم يقومون بتحويل الأفكار السلبية إلى أفكار إيجابية ويجدون حلولاً تمنع حدوث المشكلة.',
    CRAZY_8: 'المَجْنونات الثَمانِيَة',
    CRAZY_8_DESCRIPTION:'قم بإجراء عصف ذهني حول 8 حلول في 8 دقائق',
    CRAZY_8_LONG_DESCRIPTION:'المَجْنونات الثَمانِيَة هو مشتق من طريقة Design Sprint لتوليد أفكار مختلفة بسرعة. في هذا النشاط ، يجب على المشاركين تقديم ثمانية حلول لمشكلة ما في ثماني دقائق ، مع تدوين حل واحد لكل قائمة. نظرًا لتفضيل الكمية على الجودة في البداية ، يختار المشاركون بعد ذلك أفكارهم الثلاثة الأولى ويقدمونها إلى المجموعة.',
    ANALYSE_SOAR:'تحليل نقاط القوة والفرص والتطلعات والنتائج',
    ANALYSE_SOAR_DESCRIPTION:'ركّز على نقاط قوتك وفرصك، وأنشئ رؤية للتطلعات والنتائج المستقبلية.',
    ANALYSE_SOAR_LONG_DESCRIPTION:'تحليل SOAR هو أسلوب تخطيط استراتيجي لتحليل نقاط القوة والفرص في المنظمة وبناء رؤية للتطلعات والنتائج المستقبلية. على عكس تحليل SWOT ، فإنه يستخدم استفسارًا تقديريًا ويركز على الروافع المحتملة للاستفادة من الفرص. في هذا النشاط ، يركز المشاركون على الوضع الحالي من خلال تبادل الأفكار حول نقاط القوة والفرص في المنظمة. بعد ذلك ، من خلال تحديد التطلعات والنتائج المستقبلية ، يخطط المشاركون ويخططون لموقع مستقبلي مناسب.',
    ANALYSE_PESTEL:'تحليل PESTEL',
    ANALYSE_PESTEL_DESCRIPTION:'قم بتحليل العوامل البيئية الكلية التي يمكن أن يكون لها تأثير على أدائك.',
    ANALYSE_PESTEL_LONG_DESCRIPTION:'يسمح تحليل PESTLE بإجراء تقييم استراتيجي ومنهجي لآفاق الأعمال التجارية ومخاطرها وفرصها في بيئة جديدة. يرمز PESTLE إلى ستة عوامل خارجية حاسمة: السياسية ، والاقتصادية ، والاجتماعية ، والتكنولوجية ، والقانونية ، والبيئية. من خلال تحديد هذه العوامل وتحليلها ، يمكن للمنظمات اكتشاف وفهم الاتجاهات الواسعة وطويلة الأجل التي تؤثر على استراتيجيات الأعمال والأفراد.',
    OBJECTIVES_BY_KEY_RESULTS: 'النتائج الرئيسية الموضوعية',
    OBJECTIVES_BY_KEY_RESULTS_DESCRIPTION: 'حدد الأهداف والنتائج الرئيسية لإنشاء التوافق والمشاركة.',
    OBJECTIVES_BY_KEY_RESULTS_LONG_DESCRIPTION: 'النتائج الرئيسية الموضوعية عبارة عن إطار عمل لتحديد الأهداف يساعد المؤسسات على تنفيذ وتنفيذ الاستراتيجيات التي تركز على النتائج والمواءمة بين الوظائف. في هذا النشاط ، يحدد المشاركون هدفًا واحدًا أو عدة أهداف ، ويحددون النتائج المتوقعة ، والأنشطة لتحقيق كل هدف ، والنتائج الرئيسية المتوقعة ، وتوضيح الأشخاص المعنيين والمواعيد النهائية.',
    HOPES_AND_FEARS:'آمال ومخاوف',
    HOPES_AND_FEARS_DESCRIPTION:'قياس موقف المشاركين تجاه مشروع أو أي مشاركة تعاونية.',
    HOPES_AND_FEARS_LONG_DESCRIPTION:'نشاط الآمال والمخاوف هو تمرين جماعي لتقييم توقعات المشاركين ومخاوفهم بشأن مشروع جديد. من خلال جعل كل مشارك يكتب آماله ومخاوفه ، يمكن للمرء تحديد الموضوعات الناشئة والبدء في حلول العصف الذهني لمعالجة المخاوف التي تم التعبير عنها.',
    STANDUP_MEETING: 'اجتماع الوقوف اليومي',
    STANDUP_MEETING_DESCRIPTION: 'اكشف عن حواجز الطرق وخطط ليوم العمل بشكل تعاوني.',
    STANDUP_MEETING_LONG_DESCRIPTION: '"يعد اجتماع اليومي نشاطًا قصيرًا حيث يجتمع الفريق معًا ، ويشارك كل عضو ما عملوا عليه أمس واليوم وما إذا كان هناك أي مانع. إنها فرصة للجميع في الفريق لتقديم ملاحظات واقتراحات لإلغاء حظر بعضهم البعض."',
    EMPATHY_CARD: 'خريطة التعاطف',
    EMPATHY_CARD_DESCRIPTION: 'احصل على نظرة أعمق لعملائك لتقديم خدمات ومنتجات أفضل لهم.',
    EMPATHY_CARD_LONG_DESCRIPTION: 'خريطة التعاطف هي أداة تصور تلتقط المعرفة حول سلوكيات العميل ومواقفه. يسمح لفرق المنتج بإنشاء فهم مشترك لاحتياجات المستخدم والمساعدة في صنع القرار. يحتوي هذا القالب على ستة أرباع ، تسرد ما يسمعه المستخدم ويراه ويفكر فيه ويفعله ويقوله ، بالإضافة إلى آلامه ومكاسبه.',
    USER_PERSONA: 'شخصية المستخدم',
    USER_PERSONA_DESCRIPTION: 'قم بإنشاء مستخدم نموذجي لفهم احتياجاته وخصائصه بشكل أفضل .',
    USER_PERSONA_LONG_DESCRIPTION: 'لوحة شخصية المستخدم هي أداة التفكير التصميمي لإنشاء شخصية شبه خيالية تعتمد على عميل مستهدف لتحسين المنتج واستراتيجية التسويق والمبيعات الخاصة به. يساعد في جمع البيانات السيكوجرافية وهيكلها مثل شخصية المستخدم وأهدافه واهتماماته وإحباطاته. يمكن ملء اللوحة ببيانات افتراضية ليتم التحقق منها أو مباشرة بالبيانات الفعلية المأخوذة من الاستطلاعات وأبحاث المستخدمين.',
    DAKI_RETROSPECTIVE: ' نشاط داكي (DAKI)',
    DAKI_RETROSPECTIVE_DESCRIPTION: 'حدد كفريق ما يجب عليك حذفه وإضافته والاحتفاظ به وتحسينه.',
    DAKI_RETROSPECTIVE_LONG_DESCRIPTION: 'يعتبر نشاط DAKI أسلوبًا رجعيًا لتقييم أساليب عمل الفريق وتحديد إجراءات التحسين. اسمها هو اختصار للعناصر الأربعة التي تمت مناقشتها خلال الجلسة: ما هي الأنشطة والمواقف التي يحتاج الفريق إلى حذفها وإضافتها والاحتفاظ بها وتحسينها للعمل معًا بشكل أفضل. هذا النشاط هو الأنسب للكشف عن التحسينات بناءً على القيمة التي يدركها كل فرد بدلاً من الحقائق.',
    FLAP_RETROSPECTIVE: 'نشاط FLAP',
    FLAP_RETROSPECTIVE_DESCRIPTION: 'فكر في الاعتبارات المستقبلية والدروس المستفادة والإنجازات والمشاكل.',
    FLAP_RETROSPECTIVE_LONG_DESCRIPTION: 'نشاط FLAP عبارة عن مزيج من المستقبل بأثر رجعي ، مع الأخذ في الاعتبار كل من الدروس المستفادة من اعتبارات الماضي والمستقبل. تتكون اللوحة من أربعة أجزاء: الاعتبارات المستقبلية ، والدروس المستفادة ، والإنجازات ، ومجالات المشكلات. بتغطية منطقة أوسع من التقنيات المعتادة بأثر رجعي ، فهي تساعد الفرق على الاحتفال بإنجازاتهم والتفكير في كيفية تطبيق الدروس المستفادة للمشاريع المستقبلية.',
    SPEED_BOAT_RETROSPECTIVE: 'نشاط المراكب الشراعية',
    SPEED_BOAT_RETROSPECTIVE_DESCRIPTION: 'السابقة وحدد أفضل طريقة للمضي قدماً sprint قيّم وحدة',
    SPEED_BOAT_RETROSPECTIVE_LONG_DESCRIPTION: 'نشاط المراكب الشراعية هو تمرين تخيل لتحديد ما يدفع المشروع والفريق إلى الأمام وما الذي يعيقهم. باستخدام استعارة المراكب الشراعية ، يدعو هذا النشاط المشاركين إلى التفكير في رؤية المشروع ، وتحديد الرياح التي تدفع الفريق إلى الأمام ، وتحديد المخاطر والعقبات المحتملة (الصخور) ، وتحليل ما يعيق الفريق (المراسي).',
    FIVE_WHY: 'الأسباب الخمسة',
    FIVE_WHY_DESCRIPTION: 'حلل و افهم السبب الجذري للمشكلة.',
    FIVE_WHY_LONG_DESCRIPTION: 'الأسباب الخمسة هي أسلوب استفهام لحل المشكلات للكشف عن الأسباب الجذرية لمشكلة معينة. وهو يتألف من السؤال عن "لماذا" خمس مرات بعد ذكر المشكلة. من الأنسب حل مشكلة بسيطة أو متوسطة التعقيد عن طريق قطع الأعراض الخارجية بسرعة والكشف عن السبب الأساسي.',
    PROS_AND_CONS: 'إيجابيات وسلبيات',
    PROS_AND_CONS_DESCRIPTION: 'ضع قائمة بمزايا وعيوب القرار أو الفكرة.',
    PROS_AND_CONS_LONG_DESCRIPTION: 'نشاط إيجابيات وسلبيات هو أسلوب مقارنة للنظر في وجهات النظر المختلفة لموضوع ما وتعزيز التفكير النقدي. إنها طريقة بسيطة وفعالة لتحديد الأسباب المؤيدة لفكرة معينة والمعارضة لها لتقرير ما إذا كنت تريد المضي قدمًا أم لا. يتكون من قائمتين ، واحدة لسرد الحجج المؤيدة والأخرى لتسجيل الأفكار ضد الموضوع أو القرار الذي يتعين اتخاذه.',
    MAD_SAD_GLAD_RETROSPECTIVE: 'نشاط غاضب ، حزين ، سعيد',
    MAD_SAD_GLAD_RETROSPECTIVE_DESCRIPTION: 'تعمق في مشاعر الفريق وحدد طرقًا لتحسين الروح المعنوية.',
    MAD_SAD_GLAD_RETROSPECTIVE_LONG_DESCRIPTION: 'نشاط غاضب ، حزين ، سعيد هو أسلوب استعادي يركز على معنويات الفريق ويجمع البيانات عن الرحلة العاطفية لكل عضو في الفريق أثناء العدو أو المشروع. من خلال مشاركة ما جعلهم غاضبين أو حزينين أو سعداء ، فإن الفريق لديه فرصة للتفكير في أي مشاكل والكشف عن إمكانيات خلق ديناميكية إيجابية للفريق.',
    BLANK_BRAINSTORM: 'تبادل الأفكار على وضع فارغ',
    BLANK_BRAINSTORM_DESCRIPTION: 'أنشئ نشاطك الخاص بقوائم غير محدودة.',
    BLANK_BRAINSTORM_LONG_DESCRIPTION: 'أنشئ نشاطك الخاص بقوائم غير محدودة.',
    SCRUM_TEAM_RADAR: 'رادار فريق سكرم',
    SCRUM_TEAM_RADAR_DESCRIPTION: 'الخاصة بك كفريق وتخطيط طرق للتحسين scrum قم بتقييم ممارسات ',
    SCRUM_TEAM_RADAR_LONG_DESCRIPTION: 'الخاصة بك كفريق وتخطيط طرق للتحسين scrum قم بتقييم ممارسات ',
    PROBLEM_SOLUTION_FIT: 'لوحة تناسب حل المشكلة',
    PROBLEM_SOLUTION_FIT_DESCRIPTION:
      'ترجمة المشاكل إلى حلول ذات فرص أكبر لاعتماد الحلول.',
    PROBLEM_SOLUTION_FIT_LONG_DESCRIPTION:
      'إن لوحة حل المشكلة هي إطار عمل استراتيجي لتحديد الحلول التي من المرجح أن يتم تبنيها من قبل العملاء المستهدفين. استنادًا إلى مبادئ Lean Startup وعلم النفس السلوكي وتصميم تجربة المستخدم ، يساعد هذا النشاط فرق الإنتاج والتسويق على تحسين عرض القيمة والرسائل. تتكون هذه اللوحة القماشية من 10 مربعات تتناول حالة العميل الملائمة ، وتوافق سلوك المشكلة ، وملاءمة قناة الاتصال ، والحل. تم إنشاء "The Problem-Solution Fit Canvas" بواسطة <a  target="_blank" href="https://www.amaltama.com/">Daria Nepriakhina</a> بموجب <a  target="_blank" href="https://creativecommons.org/licenses/by-nc-nd/4.0/">CC BY-NC-ND 4.0</a>.',
    EXPERIMENT_CANVAS: 'لوحة التجربة',
    EXPERIMENT_CANVAS_DESCRIPTION:
      'قسّم أخطر افتراضاتك إلى تجارب قابلة للقياس والملاحظة.',
    EXPERIMENT_CANVAS_LONG_DESCRIPTION:
      'لوحة التجربة هي أداة لتصميم تجربة مقنعة ومحددة جيدًا. تسمح اللوحة بتحديد أكثر الافتراضات خطورة في التجربة ، وإنتاج فرضية قابلة للدحض ، وتخطيط إعداد التجربة والتحقق من النتائج والخطوات التالية. <br/><br/> نموذج لوحة تجربة ExcelWay مشتق من "The Experiment Canvas" بواسطة <a  target="_blank" href="https://www.linkedin.com/in/ashmaurya/">Ash Maurya</a> ، يُستخدم بموجب <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',      
    PRODUCT_MARKET_FIT: 'لوحة المنتج المناسب للسوق',
    PRODUCT_MARKET_FIT_DESCRIPTION:
      'تحدد عملائك وتحقق من صحته والوصول إليه لتحقيق مصادقة السوق مع منتجك.',
    PRODUCT_MARKET_FIT_LONG_DESCRIPTION:
      'تعد اللوحة المنتج المناسب للسوق المنتج أداة ابتكار إستراتيجية تتوافق مع طلب السوق على المنتج وتحدد إستراتيجية المنتج المناسبة. <br/><br/> إنه يعكس ، من ناحية ، خصائص شريحة العملاء ، مثل الوظائف التي يتعين القيام بها ، والمشاكل ، والقنوات ، وتجربة المستخدم. من ناحية أخرى ، يقوم المشاركون بتحديد أو تقييم المنتج أو الخدمة المقدمة لهذا العميل ، مثل بدائل المنتج ، وميزات المنتج ، وقيمة القناة ، والمقاييس الرئيسية. <br/><br/> قامت مجموعة من ممارسي الابتكار بإنشاء Product-Market Fit Canvas بموجب <a  target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',
    FIVE_FORCES_RADAR: 'قوات بورتر الخمسة',
    FIVE_FORCES_DESCRIPTION:
      'تقييم المنافسين المباشرين وغير المباشرين بناءً على قوتهم.',
    FIVE_FORCES_LONG_DESCRIPTION:
      'قوى بورتر 5 هي نموذج إستراتيجية عمل لتحديد القوى التنافسية للسوق وتحليلها وصياغة استراتيجيات لمنافستها. يقسم النموذج الصناعات والأسواق من خلال فحص خمسة عناصر: <ul><li> القدرة التفاوضية للموردين </li><li> القدرة التفاوضية للمشتري </li><li> التهديد من الداخلين الجدد </li><li> التهديد من المنتجات / الخدمات البديلة </li><li> التنافس بين المنافسين الحاليين </li></ul> تم إنشاء هذا النموذج ونشره في البداية في عام 1979 بواسطة مايكل بورتر ، الأستاذ بكلية إدارة الأعمال بجامعة هارفارد.',
    PITCH_CANVAS: 'لوحة العرض التقديمي',
    PITCH_CANVAS_DESCRIPTION:
      'قم بالعصف الذهني ، وقم بتنظيم وتصور عرضك.',
    PITCH_CANVAS_LONG_DESCRIPTION:
      'يُعد مخطط العرض التقديمي إطار عمل عصف ذهني لريادة الأعمال لإنشاء عرض تقديمي مؤثر وشامل. <br/><br/> تتكون لوحة العرض التقديمي من 11 عنصرًا أساسيًا ، وتغطي جميع العناصر التي يرغب المستثمرون وأعضاء مجلس الإدارة في معرفتها عند سماع العرض التقديمي. من نقطة ألم العميل إلى أداة التمييز الفريدة للحل ، تساعد لوحة الرسم هذه رواد الأعمال على تبادل الأفكار واختيار أفضل الأفكار لتقديمها في عرض تقديمي موجز ومقنع. <br/><br/> هذا القالب مشتق من "The Pitch Canvas ©" بواسطة <a target="_blank" href="https://best3minutes.com/academy/">Best3minutes</a> ، ويستخدم تحت <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',
    VALUE_CHAIN_CANVAS: 'لوحة سلاسل القيمة',
    VALUE_CHAIN_CANVAS_DESCRIPTION:
      'تحديد أصحاب المصلحة المعنيين في سلاسل القيمة الخاصة بك وتحسين نموذج عملك.',
    VALUE_CHAIN_CANVAS_LONG_DESCRIPTION:
      'تعد لوحة سلاسل القيمة إطارًا استراتيجيًا لرسم خرائط لسلسلة التوريد الخاصة بشركة ما وتحديد فرص تحسين العمليات ونمو الأعمال. <br/><br/> يتكون القماش من 8 كتل بناء ، ويغطي المراحل الرئيسية للعملية مع تحديد الموردين والوسطاء المعنيين. نتيجة لذلك ، يمكن لأصحاب المصلحة تحليل الأنشطة المختلفة ووحداتهم التنظيمية مثل الإنتاج أو المبيعات أو التسويق أو الخدمات اللوجستية. <br/><br/> هذا القالب مشتق من " The Value Chain Canvas" بواسطة <a target="_blank" href="https://www.datentreiber.de/en/">Datentreiber</a> ، والمستخدم تحت <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',  
    PREVIEW:'معاينة',
    CUSTOM_MODELS: 'نماذج مخصصة',
    DUPLICATE_AND_EDIT: 'كررو حرر كتب',
    DELETE_MODAL: 'حذف',
    UPDATE_MODAL: 'تغيير',
    CREATE_NEW_MODEL:'إنشاء نموذج',
  },
};
