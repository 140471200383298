export const locale = {
    lang: 'ar',
    data: {
        DIALOG_BOARD_SUBSCRIBE_SUBSCRIBE_TO_BOARD : 'اشترك في اللوحة' ,
        DIALOG_BOARD_SUBSCRIBE_SEND_BY_EMAIL: 'أرسل لي عبر البريد الإلكتروني:',
        DIALOG_BOARD_SUBSCRIBE_DAILY_SUMMARIES: 'ملخصات يومية',
        DIALOG_BOARD_SUBSCRIBE_DAILY_SUMMARIES_COMPLETED: 'المهام المكتملة وتواريخ الاستحقاق القادمة والمهام المتأخرة اعتبارًا من هذا اليوم.',
        DIALOG_BOARD_SUBSCRIBE_WEEKLY_SUMMARIES: 'ملخصات أسبوعية',
        DIALOG_BOARD_SUBSCRIBE_WEEKLY_SUMMARIES_COMPLETED: 'المهام المكتملة وتواريخ الاستحقاق القادمة والمهام المتأخرة هذا الأسبوع.',
        DIALOG_BOARD_SUBSCRIBE_SAVE: 'حفظ',
    }
};
