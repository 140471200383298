import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { StudioProjectStoreSelectors } from 'app/root-store/studio-store/project-store';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { StudioSessionStoreActions } from 'app/root-store/studio-store/session-store';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';
import { RootStoreState } from 'app/root-store';
import { locale as english } from '../../../../../i18n/studio/projects/session/dialogs/tasks-export-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/session/dialogs/tasks-export-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/session/dialogs/tasks-export-dialog/ar';

@Component({
    selector: 'session-tasks-export-dialog',
    templateUrl: './tasks-export-dialog.component.html',
    styleUrls: ['./tasks-export-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SessionTasksExportDialogComponent implements OnInit, OnDestroy {
    project$: Observable<ProjectModel>;

    boards = [];
    boardId: any;
    tasksIds: SessionTaskModel[] = [];
    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public dialogRef: MatDialogRef<SessionTasksExportDialogComponent>,
        private _store: Store<RootStoreState.State>,
        private _route: ActivatedRoute,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.dialogRef.disableClose = true;
        this._unsubscribeAll = new Subject();
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.project$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),
            select(StudioProjectStoreSelectors.selectCurrent)
        );

        this.project$.subscribe(currentProject => {
            this.boards = currentProject.boards;
        });

        this._data.tasks.map(task => {
            this.tasksIds.push(task);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    onItemChange(boardId): void {
        this.boardId = boardId;
    }

    addTaskstoBoard(): void {
        this._store.dispatch(
            StudioSessionStoreActions.addCardstoBoard({
                tasks: this.tasksIds,
                boardId: this.boardId,
            })
        );
    }
}
