export const locale = {
    lang: 'ar',
    data: {
        MODIFY_STRUCTURE: 'تحرير هيكل المشروع',
        // tslint:disable-next-line: max-line-length
        PARA: 'يمثل هيكل المشروع المستويات اللاحقة المختلفة لتنظيم مشاريعك وورشاتك. حدد عددهم وتسمياتهم كما تمارس في مؤسستك.',
        LEVEL: 'المستوى',
        LABEL: 'ملصق',
        EXEMPLE: '...على سبيل المثال: برنامج ، محور استراتيجي ، مشروع',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
    }
};
