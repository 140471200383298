import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import {
    RootStoreState,
} from 'app/root-store';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { StudioBoardStoreSelectors, StudioBoardStoreActions } from 'app/root-store/studio-store/board-store';
import { locale as english } from '../../../../../i18n/studio/projects/board/dialogs/update-details-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/board/dialogs/update-details-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/board/dialogs/update-details-dialog/ar';

@Component({
    selector: 'board-update-details-dialog',
    templateUrl: './board-update-details-dialog.component.html',
    styleUrls: ['./board-update-details-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProjectBoardDetailUpdateDialogComponent implements OnInit {
    board: BoardModel;
    isLoaded = false;
    error$: Observable<string>;
    composeForm: UntypedFormGroup;
    enableBoardPrivacy: boolean;
    constructor(
        public matDialogRef: MatDialogRef<
            ProjectBoardDetailUpdateDialogComponent
        >,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        this.matDialogRef.disableClose = true;
    }
    ngOnInit(): void {
        this._store
            .select(StudioBoardStoreSelectors.selectCurrent)
            .subscribe(board => {
                if (!this.isLoaded) {
                    this.board = board;
                    this.enableBoardPrivacy = board.settings.enableBoardPrivacy;
                    this.composeForm = this.createComposeForm();
                    this.isLoaded = true;
                } else {
                    this.matDialogRef.close();
                }
            });
    }

    createComposeForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            title: new UntypedFormControl(this.board.name),
            description: new UntypedFormControl(this.board.description),
        });
    }
    Save(): void {
        this._store.dispatch(
            StudioBoardStoreActions.updateBoardDetails({
                boardId: this.board.id,
                name: this.composeForm.value.title,
                description: this.composeForm.value.description,
                enableBoardPrivacy: this.enableBoardPrivacy
            })
        );
    }
}
