import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';

export const selectTimerState = createFeatureSelector<State>('workshop-timer');

export const selectValue = createSelector(
    selectTimerState,
    (state: State): number => state.value
);

export const selectExercise = createSelector(
    selectTimerState,
    (state: State): string => state.exercise
);

export const selectStart = createSelector(
    selectTimerState,
    (state: State): boolean => state.start
);

export const selectExerciseAndStart = createSelector(
    selectTimerState,
    (state: State): [string, boolean] => [state.exercise, state.start]
);

export const selectCategories = createSelector(
    selectTimerState,
    (state: State): SessionCategoryModel[] => state.categories
);

export const selectContentId = createSelector(
    selectTimerState,
    (state: State): string => state.contentId
);
