import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as  HomeActions from './actions';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';

const accessReducer = createReducer(
    initialState,
    on(HomeActions.loadSuccess, (state, { home }) => ({
        ...state,
        boards: home.boards,
        projects: home.projects,
        sessions: home.sessions,
        filtredSessions: home.sessions
    })),
    on(HomeActions.searchSessionByStatus, (state, { status }) => {
        return {
            ...state,
            filtredSessions:
                status === 'all'
                    ? state.sessions
                    : state.sessions.filter((session: ProjectSessionModel) => {
                        if (status === 'passed') {
                            if (session.status === 'closed') {
                                return session;
                            }
                        } else {
                            if (session.status !== 'closed') {
                                return session;
                            }
                        }
                    }),
        };
    })
);

export function reducer(state: State | undefined, action: Action): any {
    return accessReducer(state, action);
}
