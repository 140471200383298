import { createAction, props } from '@ngrx/store';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';

export const start = createAction(
    '[Workshop][Timer] Start',
    props<{ exercise?: string; value: number, categories?: SessionCategoryModel[], balance: number, maxPerCard: number }>()
);
export const pause = createAction('[Workshop][Timer] Pause');

export const stop = createAction('[Workshop][Timer] Stop');

export const update = createAction(
    '[Workshop][Timer] Update',
    props<{ exercise?: string; timer: boolean; balance: number, maxPerCard: number }>()
);

export const updateCategories = createAction(
    '[Workshop][Timer] Update Categories',
    props<{ categories: SessionCategoryModel[] }>()
);

