import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
  exhaustMap,
  map,
  catchError,
  tap,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as ProjectsActions from './actions';
import { ProjectsService } from '@bsuccess/services/projects.service';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';
import { AttachmentService } from '@bsuccess/services/attachment.service';
import { RootStoreState } from 'app/root-store';
import { NavbarStoreSelectors } from 'app/root-store/navbar-store';

@Injectable()
export class StudioProjectsStoreEffects {
  constructor(
    private _store: Store<RootStoreState.State>,
    private _projectsService: ProjectsService,
    private _attachmentService: AttachmentService,
    private _actions$: Actions
  ) {}

  loadProjectsTree$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectsActions.loadProjectsTree),
      exhaustMap((action) => {
        return this._projectsService.getProjectsTree().pipe(
          map((response) => {
            return ProjectsActions.loadProjectsTreeSuccess({
              response,
            });
          }),
          catchError((error) =>
            of(
              ProjectsActions.loadProjectsTreeFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  loadProjects$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectsActions.load),
      exhaustMap((_) => {
        return this._projectsService.getProjects().pipe(
          map((response) => ProjectsActions.loadSuccess({ response })),
          catchError((error) =>
            of(
              ProjectsActions.loadFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

//   loadSuccess$ = createEffect(
//     () =>
//       this._actions$.pipe(
//         ofType(ProjectsActions.loadSuccess),
//         withLatestFrom(
//           this._store.pipe(select(NavbarStoreSelectors.selectProduct))
//         ),
//         tap(([action, product]) => {
//           if (product === 'studio') {
//             action.response.favorites.map((project: SubProjectModel) => {
//               if (project.logo) {
//                 this._store.dispatch(
//                   ProjectsActions.updateImage({
//                     projectType: 'favorites',
//                     projectId: project._id,
//                     imageId: project.logo,
//                   })
//                 );
//               }
//             });
//             action.response.others.map((project: SubProjectModel) => {
//               if (project.logo) {
//                 this._store.dispatch(
//                   ProjectsActions.updateImage({
//                     projectType: 'others',
//                     projectId: project._id,
//                     imageId: project.logo,
//                   })
//                 );
//               }
//             });
//           }
//         })
//       ),
//     { dispatch: false }
//   );

  addProjectToFavorite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectsActions.addFavorite),
      exhaustMap((action) => {
        return this._projectsService.addFavorite(action.id).pipe(
          map((_) =>
            ProjectsActions.addFavoriteSuccess({
              id: action.id,
            })
          ),
          catchError((error) =>
            of(
              ProjectsActions.addFavoriteFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  removeFavorite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectsActions.removeFavorite),
      exhaustMap((action) => {
        return this._projectsService.removeFavorite(action.id).pipe(
          map((_) =>
            ProjectsActions.removeFavoriteSuccess({
              id: action.id,
            })
          ),
          catchError((error) =>
            of(
              ProjectsActions.removeFavoriteFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  /*
   * TODO Use a better way to load images see example bellow:
   * https://medium.com/allenhwkim/simple-lazy-loading-with-angular-716dd3b174a
   */
//   updateProjectImage$ = createEffect(() =>
//     this._actions$.pipe(
//       ofType(ProjectsActions.updateImage),
//       mergeMap((action) => {
//         return this._attachmentService.get(action.imageId).pipe(
//           map((response) =>
//             ProjectsActions.updateImageSuccess({
//               projectType: action.projectType,
//               projectId: action.projectId,
//               content: response,
//             })
//           ),
//           catchError((error) =>
//             of(
//               ProjectsActions.updateImageFailure({
//                 error: error,
//               })
//             )
//           )
//         );
//       })
//     )
//   );

//   updateProjectImageSuccess$ = createEffect(() =>
//     this._actions$.pipe(
//       ofType(ProjectsActions.updateImageSuccess),
//       mergeMap((action) => {
//         return this._attachmentService.createFromBlob(action.content).pipe(
//           map((response) =>
//             ProjectsActions.imageRendered({
//               projectType: action.projectType,
//               projectId: action.projectId,
//               content: response,
//             })
//           ),
//           catchError((error) =>
//             of(
//               ProjectsActions.updateImageFailure({
//                 error: error,
//               })
//             )
//           )
//         );
//       })
//     )
//   );

  loadWeeklySummaries$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectsActions.loadWeeklySummaries),
      exhaustMap((action) => {
        return this._projectsService.getWeeklySummaries().pipe(
          map((_) =>
            ProjectsActions.loadWeeklySummariesSuccess({
              response: _,
            })
          ),
          catchError((error) =>
            of(
              ProjectsActions.loadWeeklySummariesFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  loadDailyySummaries$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectsActions.loadDailyySummaries),
      exhaustMap((action) => {
        return this._projectsService.getDailySummaries().pipe(
          map((_) =>
            ProjectsActions.loadDailyySummariesSuccess({
              response: _,
            })
          ),
          catchError((error) =>
            of(
              ProjectsActions.loadDailyySummariesFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );
}
