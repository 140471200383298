import { StandbyCardModel } from '../../../../@bsuccess/models/standbycard/standbycard.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectNotificationsState = createFeatureSelector<State>(
  'studio-notifications'
);

export const selectCurrent = createSelector(
  selectNotificationsState,
  (state: State): StandbyCardModel => state.current
);

export const loadNotifications = createSelector(
  selectNotificationsState,
  (state: State): StandbyCardModel[] => state.notifications
);

export const loadNotificationsCount = createSelector(
  selectNotificationsState,
  (state: State): number => state.notificationsCount
);
