import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { RootStoreState } from 'app/root-store';
import { StudioProjectStoreActions } from 'app/root-store/studio-store/project-store';
import { StudioBoardStoreSelectors } from 'app/root-store/studio-store/board-store';
import { locale as english } from '../../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/board-new-members-tab/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/board-new-members-tab/fr';
import { locale as arabic } from '../../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/board-new-members-tab/ar';


@Component({
    selector: 'board-new-members-tab',
    templateUrl: './board-new-members-tab.component.html',
    styleUrls: ['./board-new-members-tab.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class BoardNewMembersTabComponent implements OnInit {
    members: ProjectUserModel[] = [];
    constructor(
        public dialogRef: MatDialogRef<BoardNewMembersTabComponent>,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.dialogRef.disableClose = true;
        this._store.select(StudioBoardStoreSelectors.selectCurrent).subscribe(board => {
            if (board) {
                this.members = board.users;
            }
        });
    }

    add(): void {
        this.members.push({
            _id: FuseUtils.generateGUID(),
            email: '',
            position: 'member',
            role: 'read',
        });
    }

    delete(index): void {
        this.members.splice(index, 1);
    }

    updateMember(index: number, member: ProjectUserModel): void {
        this.members[index] = member;
    }

    save(): void {
        this._store.dispatch(
            StudioProjectStoreActions.addBoardMembers({
                users: this.members.map(user => {
                    return {
                        email: user.email.trim(),
                        position: user.position,
                        role: user.role,
                    };
                }),
            })
        );
    }
}
