import * as SessionActions from './actions';
import * as SessionSelectors from './selectors';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RootStoreSelectors, RootStoreState } from 'app/root-store';
import {
  catchError,
  exhaustMap,
  flatMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActionPlanService } from '@bsuccess/services/actions-plan.service';
import { NotificationService } from '@bsuccess/services/notification.service';
import { ProjectService } from '@bsuccess/services/project.service';
import { SessionService } from '@bsuccess/services/session.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { WorkshopActivitiesStoreSelectors } from 'app/root-store/workshop-store/activities-store';
import { SessionNewDialogComponent } from 'app/studio/projects/project/dialogs/session-new-dialog/session-new-dialog.component';
import { SessionUpdateGeneralDialogComponent } from 'app/studio/projects/project/dialogs/session-update-general-dialog/session-update-general-dialog.component';
import { SessionCommentDialogComponent } from 'app/studio/projects/session/dialogs/comment-dialog/comment-dialog.component';
import { SessionTaskContentDialogComponent } from 'app/studio/projects/session/dialogs/task-content-dialog/task-content-dialog.component';
import { SessionUpdateGuestsAndSendInvitationsDialogComponent } from 'app/studio/projects/session/dialogs/update-guests-and-send-invitation-dialog/update-guess-and-send-invitation-dialog.component';
import { SessionUpdateMembersDialogComponent } from 'app/studio/projects/session/dialogs/update-members-dialog/update-members-dialog.component';
import { SessionUpdateParticipantsAndSendInvitationsDialogComponent } from 'app/studio/projects/session/dialogs/update-participants-and-send-invitation-dialog/update-participants-and-send-invitation-dialog.component';
import { ConfirmPrivacyChangeDialogComponent } from 'app/workshop/canvas/animator/board/dialogs/confirm-privacy-change-dialog/confirm-privacy-change-dialog.component';
import { of } from 'rxjs';
import { StudioSessionStoreActions } from '.';
import { locale as english } from '../../../i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../i18n/@fuse/components/confirm-dialog/fr';
import { StudioProjectStoreSelectors } from '../project-store';
import { ReportService } from './../../../../@bsuccess/services/report.service';

@Injectable()
export class StudioSessionStoreEffects {
  confirmArchiveSession: string;
  confrimStopMonitoring: string;
  confirmCloseSession: string;
  constructor(
    private _store: Store<RootStoreState.State>,
    private _actions$: Actions,
    private _projectService: ProjectService,
    private _sessionService: SessionService,
    private _actionPlanService: ActionPlanService,
    private _notificatonService: NotificationService,
    private _matDialog: MatDialog,
    private _location: Location,
    private _router: Router,
    private translationLoaderService: FuseTranslationLoaderService,
    private translate: TranslateService,
    private _reportService: ReportService
  ) {
    this.translationLoaderService.loadTranslations(english, frensh);

    this.translate.stream('CONFIRM_ARCHIVE_SESSION').subscribe((value) => {
      this.confirmArchiveSession = value;
    });
    this.translate.stream('CONFIRM_STOP_MONITORING').subscribe((value) => {
      this.confrimStopMonitoring = value;
    });
    this.translate.stream('CONFIRM_CLOSE_SESSION').subscribe((value) => {
      this.confirmCloseSession = value;
    });
  }

  loadCurrentSession$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.loadCurrent),
      exhaustMap((action) =>
        this._sessionService.getSessionById(action.id).pipe(
          map((_) =>
            SessionActions.loadCurrentSuccess({
              session: _,
            })
          ),
          catchError((error) => {
            this._notificatonService.showError(
              this.translate.currentLang.toString() === 'fr'
                ? "Une erreur s'est produite lors de la récupération de l'atelier."
                : this.translate.currentLang.toString() === 'en'
                ? 'An error occurred while retrieving the workshop.'
                : '.حدث خطأ أثناء استرداد الورشة'
            );
            return of(
              SessionActions.loadCurrentFailure({
                error: error,
              })
            );
          })
        )
      )
    )
  );

  showAddSessionDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SessionActions.showAddSessionDialog),
        map((_) => {
          this._matDialog.open(SessionNewDialogComponent, {
            panelClass: 'session-new-dialog',
          });
        })
      ),
    { dispatch: false }
  );
  // update participants / guests and send invitations
  // participants
  showUpdateParticipantsAndSendInvitationDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SessionActions.showUpdateParticipantsAndSendInvitationDialog),
        map((_) => {
          this._matDialog.open(
            SessionUpdateParticipantsAndSendInvitationsDialogComponent,
            {
              panelClass: 'update-participants-and-send-invitation-dialog',
            }
          );
        })
      ),
    { dispatch: false }
  );

  updateParticipantsAndSendInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateParticipantsAndSendInvitation),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      exhaustMap(([action, session]) => {
        return this._sessionService
          .updateParticipantsAndSendInvitaions(session._id, action.emails)
          .pipe(
            map((_) =>
              SessionActions.updateParticipantsAndSendInvitationSuccess({
                participants: action.emails.map((email) => {
                  return {
                    email: email,
                    position: '',
                    role: '',
                  };
                }),
              })
            ),
            catchError((error) =>
              of(
                SessionActions.updateParticipantsAndSendInvitationFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  // guests
  showUpdateGuestsAndSendInvitationDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SessionActions.showUpdateGuestsAndSendInvitationDialog),
        map((_) => {
          this._matDialog.open(
            SessionUpdateGuestsAndSendInvitationsDialogComponent,
            {
              panelClass: 'update-guests-and-send-invitation-dialog',
            }
          );
        })
      ),
    { dispatch: false }
  );

  updateGuestsAndSendInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateGuestsAndSendInvitation),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      exhaustMap(([action, session]) => {
        return this._sessionService
          .updateGuestsAndSendInvitaions(session._id, action.emails)
          .pipe(
            map((_) =>
              SessionActions.updateGuestsAndSendInvitationSuccess({
                guests: action.emails.map((email) => ({ email })),
              })
            ),
            catchError((error) =>
              of(
                SessionActions.updateGuestsAndSendInvitationFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  sessionShowUsersDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SessionActions.sessionShowUsersDialog),
        map((_) => {
          this._matDialog.open(SessionUpdateMembersDialogComponent, {
            panelClass: 'session-update-members-dialog',
            data: _.sessionId,
          });
        })
      ),
    { dispatch: false }
  );

  addSession$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.addSession),
      withLatestFrom(
        this._store.select(StudioProjectStoreSelectors.selectCurrent)
      ),
      exhaustMap(([action, project]) =>
        this._projectService.addSession(project._id, action.session).pipe(
          map((response: any) => {
            this._store.dispatch(
              SessionActions.updateDetail({
                sessionDetail: {
                  line1: '',
                  line2: '',
                  postcode: 0,
                  city: '',
                  startDate: action.session.startDate,
                  endDate: action.session.endDate,
                  startTime: action.session.startTime,
                  endTime: action.session.endTime,
                  description: '',
                },
                sessionId: response._id,
              })
            );
            return SessionActions.addSessionSuccess({
              session: {
                ...action.session,
                _id: response._id,
                users: response.users,
                token: response.token,
                status: response.status,
              },
            });
          }),
          catchError((error) =>
            of(
              SessionActions.addSessionFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  updateSubProjectUsers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateSessionUsers),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      withLatestFrom(
        this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
      ),
      withLatestFrom(this._store.pipe(select(RootStoreSelectors.selectUrl))),
      exhaustMap(([[[action, session], project], url]) =>
        this._projectService
          .addSessionUsers(project._id, {
            sessionId: session._id,
            members: action.users,
          })
          .pipe(
            map((_) => {
              return SessionActions.updateSessionUsersSuccess({
                users: action.users,
              });
            }),
            catchError((error) =>
              of(
                SessionActions.updateSessionUsersFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  updateDetail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateDetail),
      exhaustMap((_) =>
        this._sessionService.updateDetails(_.sessionId, _.sessionDetail).pipe(
          map((_) => {
            return SessionActions.updateDetailSuccess({
              sessionDetail: _.sessionDetail,
            });
          }),
          catchError((error) =>
            of(
              SessionActions.updateDetailFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  duplicateSession$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.duplicateSession),
      exhaustMap((_) =>
        this._sessionService.duplicateSession(_.projectId, _.sessionId).pipe(
          map((_) => {
            return SessionActions.duplicateSessionSuccess({
              projectId: _.projectId,
              sessionId: _.sessionId,
              session: {
                ..._,
              },
            });
          }),
          catchError((error) =>
            of(
              SessionActions.duplicateSessionFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  addCardstoBoard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.addCardstoBoard),
      withLatestFrom(
        this._store.select(StudioProjectStoreSelectors.selectCurrent)
      ),
      withLatestFrom(this._store.select(SessionSelectors.selectCurrent)),
      withLatestFrom(
        this._store.select(SessionSelectors.selectSelectedProject)
      ),
      exhaustMap(([[[action, project], session], selectedProject]) => {
        return this._sessionService
          .exportTasks(
            true,
            session._id,
            action.boardId,
            action.tasks.map((_) => _._id),
            new Date().toString(),
            ''
          )
          .pipe(
            map((response: any) => {
              if (response.message == 'copied_successfully') {
                if (action.tasks.length === 1) {
                  this._notificatonService.showSuccess(
                    this.translate.currentLang.toString() === 'fr'
                      ? 'La carte a été envoyée avec succès'
                      : this.translate.currentLang.toString() === 'en'
                      ? 'The card has been sent successfully'
                      : 'تم إرسال البطاقة بنجاح'
                  );
                } else {
                  this._notificatonService.showSuccess(
                    this.translate.currentLang.toString() === 'fr'
                      ? 'Les cartes ont été exportées avec succès'
                      : this.translate.currentLang.toString() === 'en'
                      ? 'The cards were successfully moved'
                      : 'تم تصدير البطاقات بنجاح'
                  );
                }
                return SessionActions.addCardstoBoardSuccess({
                  tasks: response.tasks,
                });
              }
              if (response.message == 'require_admin') {
                return SessionActions.addCardstoBoardWithComment({
                  boardId: action.boardId,
                  tasks: action.tasks,
                });
              }
            }),
            catchError((error) =>
              of(
                SessionActions.addCardstoBoardFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  addCardstoBoardWithComment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.addCardstoBoardWithComment),
      exhaustMap((action) => {
        const matDialogRef = this._matDialog.open(
          SessionCommentDialogComponent,
          {
            panelClass: 'comment-dialog',
          }
        );
        return matDialogRef.afterClosed().pipe(
          map((data) => {
            if (data) {
              return SessionActions.addCardstoBoardWithCommentConfirmed({
                tasks: action.tasks,
                boardId: action.boardId,
                comment: data,
              });
            } else {
              return SessionActions.addCardstoBoardWithCommentCanceled();
            }
          })
        );
      })
    )
  );

  addCardstoBoardWithCommentConfirmed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.addCardstoBoardWithCommentConfirmed),
      withLatestFrom(this._store.select(SessionSelectors.selectCurrent)),
      exhaustMap(([action, session]) => {
        return this._sessionService
          .exportTasks(
            false,
            session._id,
            action.boardId,
            action.tasks.map((_) => _._id),
            new Date().toString(),
            action.comment
          )
          .pipe(
            map((response: any) => {
              this._notificatonService.showSuccess(
                this.translate.currentLang.toString() === 'fr'
                  ? "L'action est en attente d'approbation par les administrateurs du tableau"
                  : this.translate.currentLang.toString() === 'en'
                  ? 'The action is waiting for approval by board administrators'
                  : 'الإجراء في طور الموافقة من قبل مسؤولي اللوحة'
              );
              return SessionActions.addCardstoBoardSuccess({
                tasks: action.tasks,
              });
            }),
            catchError((error) =>
              of(
                SessionActions.addCardstoBoardFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );
  // archive session
  archive$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.archiveSession),
      exhaustMap((_) => {
        const matDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
          panelClass: 'fuse-confirm-dialog',
          disableClose: false,
        });

        matDialogRef.componentInstance.confirmMessage =
          this.confirmArchiveSession;
        return matDialogRef.afterClosed().pipe(
          map((confirmed) => {
            if (confirmed) {
              return SessionActions.archiveSessionConfirmed({
                id: _.id,
              });
            } else {
              return SessionActions.archiveSessionCancelled();
            }
          })
        );
      })
    )
  );

  archiveSessionConfirmed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.archiveSessionConfirmed),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      withLatestFrom(
        this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
      ),
      exhaustMap(([[action, session], project]) => {
        return this._sessionService.archive(project._id, action.id).pipe(
          map((response) =>
            SessionActions.archiveSessionSuccess({
              id: action.id,
            })
          ),
          catchError((error) =>
            of(
              SessionActions.archiveSessionFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  //  end session monitoring
  endMonitoring$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.endMonitoring),
      exhaustMap((_) => {
        const matDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
          panelClass: 'fuse-confirm-dialog',
          disableClose: false,
        });

        matDialogRef.componentInstance.confirmMessage =
          this.confirmCloseSession;
        return matDialogRef.afterClosed().pipe(
          map((confirmed) => {
            if (confirmed) {
              return SessionActions.endMonitoringConfirmed();
            } else {
              return SessionActions.endMonitoringCancelled();
            }
          })
        );
      })
    )
  );
  endMonitoringConfirmed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.endMonitoringConfirmed),
      map((action) =>
        SessionActions.updateStatus({
          status: 'monitored',
        })
      )
    )
  );

  //  close  session
  closeSession$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.closeSession),
      exhaustMap((_) => {
        const matDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
          panelClass: 'fuse-confirm-dialog',
          disableClose: false,
        });

        matDialogRef.componentInstance.confirmMessage =
          this.confirmCloseSession;
        return matDialogRef.afterClosed().pipe(
          map((confirmed) => {
            if (confirmed) {
              return SessionActions.closeSessionConfirmed();
            } else {
              return SessionActions.closeSessionCancelled();
            }
          })
        );
      })
    )
  );
  closeSessionConfirmed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.closeSessionConfirmed),
      map((action) =>
        SessionActions.updateStatus({
          status: 'closed',
        })
      )
    )
  );

  // update session status
  updateStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateStatus),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      exhaustMap(([action, session]) =>
        this._sessionService.updateStatus(session._id, action.status).pipe(
          map((_) =>
            SessionActions.updateStatusSuccess({
              status: action.status,
            })
          ),
          catchError((error) =>
            of(
              SessionActions.updateStatusFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  // Step 1 : update session participants / guests
  updateParticipants$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateParticipants),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      exhaustMap(([action, session]) => {
        return this._sessionService
          .updateParticipants(session._id, action.emails)
          .pipe(
            map((_) =>
              SessionActions.updateParticipantsSuccess({
                participants: action.emails.map((email) => {
                  return {
                    email: email,
                    position: '',
                    role: '',
                  };
                }),
              })
            ),
            catchError((error) =>
              of(
                SessionActions.updateParticipantsFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );
  updateGuests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateGuests),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      exhaustMap(([action, session]) => {
        return this._sessionService
          .updateGuests(session._id, action.emails)
          .pipe(
            map((_) =>
              SessionActions.updateGuestsSuccess({
                guests: action.emails.map((email) => ({ email })),
              })
            ),
            catchError((error) =>
              of(
                SessionActions.updateGuestsFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  // Step 2-2 : Confirm dialog for sending invitations  ( two ws calls  )
  confirmInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.confirmInvitation),
      withLatestFrom(
        this._store.pipe(select(SessionSelectors.selectSessionId))
      ),
      exhaustMap(([action, sessionId]) => {
        return this._sessionService
          .updateDetails(sessionId, action.sessionDetail)
          .pipe(
            flatMap((response) =>
              this._sessionService.sendInvitation(sessionId).pipe(
                map((_) =>
                  SessionActions.sendInvitationsSuccess({
                    sessionDetail: action.sessionDetail,
                  })
                ),
                catchError((error) =>
                  of(
                    SessionActions.sendInvitationsFailure({
                      error: error,
                    })
                  )
                )
              )
            ),
            catchError((error) =>
              of(
                SessionActions.sendInvitationsFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  // listining on 'sendInvitationsSuccess' and updating status to  planned
  sendInvitationsSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.sendInvitationsSuccess),
      map((_) =>
        SessionActions.updateStatus({
          status: 'planned',
        })
      )
    )
  );

  showSessionDetailsDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SessionActions.showUpdateSessionDetailsDialog),
        map((_) => {
          this._matDialog.open(SessionUpdateGeneralDialogComponent, {
            panelClass: 'session-update-general-dialog',
          });
        })
      ),
    { dispatch: false }
  );

  updateSessionDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateSessionDetails),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      exhaustMap(([action, session]) =>
        this._sessionService
          .updateSessionDetails(session._id, {
            name: action.session.name,
            description: action.session.description,
            isPrivate: action.session.isPrivate,
            startDate: action.session.startDate,
            endDate: action.session.endDate,
          })
          .pipe(
            map((_) =>
              SessionActions.updateSessionDetailsSuccess({
                session: action.session,
              })
            ),
            catchError((error) =>
              of(
                SessionActions.updateSessionDetailsFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  showSessionTaskContentDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SessionActions.sessionShowTaskContentDialog),
        tap((action) => {
          const matDialogRef = this._matDialog.open(
            SessionTaskContentDialogComponent,
            {
              panelClass: 'session-task-content-dialog',
              width: '650px',
              data: action.id,
            }
          );
        })
      ),
    { dispatch: false }
  );

  updateSessionActionPlan$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateSessionActionPlan),
      withLatestFrom(
        this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
      ),
      exhaustMap(([action, project]) =>
        this._actionPlanService
          .updateSessionTask(project._id, action.actionPlan)
          .pipe(
            map((_) =>
              SessionActions.updateSessionActionPlanSuccess({
                actionPlan: action.actionPlan,
              })
            ),
            catchError((error) =>
              of(
                SessionActions.updateSessionActionPlanFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  loadSelectedProject$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.loadSelectedProject),
      exhaustMap((action) =>
        this._projectService.getProjectById(action.id).pipe(
          map(
            (_) => {
              return SessionActions.loadSelectedProjectSuccess({
                project: _,
              });
            },
            catchError((error) =>
              of(
                SessionActions.loadSelectedProjectFailure({
                  error: error,
                })
              )
            )
          )
        )
      )
    )
  );

  updateSessionSettingsInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(StudioSessionStoreActions.updateSessionSettingsInfo),
      switchMap((action) =>
        this._sessionService
          .updateSessionSettingsInfo(action.sessionId, {
            name: action.name,
            description: action.description,
            startDate: action.startDate,
            endDate: action.endDate,
            startTime: action.startTime,
            endTime: action.endTime,
          })
          .pipe(
            map((_) =>
              StudioSessionStoreActions.updateSessionSettingsInfoSuccess({
                sessionId: action.sessionId,
                name: action.name,
                description: action.description,
                startDate: action.startDate,
                endDate: action.endDate,
                startTime: action.startTime,
                endTime: action.endTime,
              })
            ),
            catchError((error) =>
              of(
                StudioSessionStoreActions.updateSessionSettingsInfoFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  updateSessionSettingsPrivacy$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateSessionSettingsPrivacy),
      exhaustMap((action) =>
        this._sessionService
          .updateSessionSettingsPrivacy(action.sessionId, action.isPrivate)
          .pipe(
            map((_) =>
              SessionActions.updateSessionSettingsPrivacySuccess({
                sessionId: action.sessionId,
                isPrivate: action.isPrivate,
              })
            ),
            catchError((error) =>
              of(
                SessionActions.updateSessionSettingsPrivacyFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  showConfirmPrivacyChangeDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SessionActions.confirmPrivacyChangeDialog),
        map((_) => {
          this._matDialog.open(ConfirmPrivacyChangeDialogComponent, {
            panelClass: 'confirm-privacy-change-dialog',
          });
        })
      ),
    { dispatch: false }
  );

  // close session
  closeSessionByStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.closeSessionByStatus),
      exhaustMap((_) => {
        const matDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
          panelClass: 'fuse-confirm-dialog',
          disableClose: false,
        });

        matDialogRef.componentInstance.confirmMessage =
          this.confirmCloseSession;
        return matDialogRef.afterClosed().pipe(
          map((confirmed) => {
            if (confirmed) {
              return SessionActions.closeSessionByStatusConfirmed({
                id: _.id,
              });
            } else {
              return SessionActions.closeSessionByStatusCancelled();
            }
          })
        );
      })
    )
  );

  closeSessionByStatusConfirmed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.closeSessionByStatusConfirmed),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      exhaustMap(([action, session]) => {
        return this._sessionService.updateStatus(action.id, 'closed').pipe(
          map((response) =>
            SessionActions.closeSessionByStatusSuccess({
              id: action.id,
            })
          ),
          catchError((error) =>
            of(
              SessionActions.closeSessionByStatusFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  openSessionByStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.openSessionByStatus),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      exhaustMap(([action, session]) => {
        return this._sessionService.updateStatus(action.id, 'declared').pipe(
          map((response) =>
            SessionActions.openSessionByStatusSuccess({
              id: action.id,
            })
          ),
          catchError((error) =>
            of(
              SessionActions.openSessionByStatusFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  updateRemoveSessionTask$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SessionActions.updateRemoveSessionTask),
      withLatestFrom(this._store.pipe(select(SessionSelectors.selectCurrent))),
      exhaustMap(([action, session]) => {
        return this._sessionService
          .updateRemoveSessionTask(action.sessionId, action.taskId)
          .pipe(
            map((response) =>
              SessionActions.updateRemoveSessionTaskSuccess({
                taskId: action.taskId,
              })
            ),
            catchError((error) =>
              of(
                SessionActions.updateRemoveSessionTaskFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  getSessionDocxReport$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SessionActions.getSessionDocxReport),
        withLatestFrom(
          this._store.pipe(select(SessionSelectors.selectCurrent))
        ),
        withLatestFrom(
          this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectActivities)
          )
        ),
        withLatestFrom(
          this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
        ),
        tap(([[[action, session], activity], user]) => {
          this._reportService.getDocxReport(
            session,
            activity,
            user.lang,
            action.format
          );
        })
      ),
    { dispatch: false }
  );
}
