export const locale = {
    lang: 'en',
    data: {
        CHANGE_WORKSPACE_MAIN: 'Change Workspace',
        CLOSE: 'Close',
        WORKSPACE: 'Workspace',
        WORKSPACE_REQUIRED: 'Workspace is required.',
        CHANGE: 'Change',
    }
};
