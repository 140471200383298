import { UserModel } from '@bsuccess/models/user.model';

export interface State {
    pending?: boolean;
    error?: string;
    success?: string;
    user: UserModel;
}

export const initialState: State = {
    pending: false,
    error: null,
    success: null,
    user: null
};

