import { FuseUtils } from '@fuse/utils';

import { List } from './list.model';
import { Card } from './card.model';

export class Board {
    name: string;
    uri: string;
    id: string;
    token?: string;
    settings: {
        color: string;
        subscribed: boolean;
        cardCoverImages: boolean;
        showAuthors?: boolean;
        showLabels?: boolean;
        hideAllCards?: boolean;
    };
    lists: List[];
    cards: Card[];
    members: {
        id: string;
        name: string;
        avatar: string;
    }[];
    labels: {
        id: string;
        name: string;
        color: string;
    }[];
    brainstormStarted?: boolean;
    evaluationStarted?: boolean;
    timer: boolean;
    categories: any[];
    balance: number;
    maxPerCard: number;
    timerType: string;
    workflowStep: number;

    constructor(board: any) {
        this.name = board.name || 'Tableau sans titre';
        this.uri = board.uri || 'untitled-board';
        this.id = board.id || FuseUtils.generateGUID();
        this.token = board.token || 'no token';
        this.settings = board.settings || {
            color: '',
            subscribed: true,
            cardCoverImages: true,
        };
        this.lists = [new List({ id: '0', name: 'Nouvelles Cartes' })];
        this.cards = [];
        this.members = board.members || [];
        this.labels = board.labels || [];
    }
}
