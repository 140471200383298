export const locale = {
    lang: 'ar',
    data: {
        ASSESSEMENT: 'ابدأ التصويت',
        ALL_CATEGORIES: 'جميع الألوان',
        CARD_CATEGORIES: 'الألوان',
        BUDGET_POINTS: 'نقاط لكل مشارك',
        MAX: 'أقصى نقاط لكل بطاقة',
        START: 'ابدأ التقييم',
        CANCEL: 'إلغاء',
    }
};
