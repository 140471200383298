import { Observable } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { Activity } from '@bsuccess/models/activities/activity.model';
import { locale as english } from '../../../../../i18n/workshop/activities/animator/dialogs/new-canvas-template/en';
import { locale as frensh } from '../../../../../i18n/workshop/activities/animator/dialogs/new-canvas-template/fr';
import { locale as arabic } from '../../../../../i18n/workshop/activities/animator/dialogs/new-canvas-template/ar';
import {
    WorkshopActivitiesStoreActions,
    WorkshopActivitiesStoreSelectors,
} from 'app/root-store/workshop-store/activities-store';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { StudioSessionStoreSelectors } from 'app/root-store/studio-store/session-store';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { WorkshopCanvasStoreActions } from 'app/root-store/workshop-store/canvas-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { UserModel } from '@bsuccess/models/user.model';

@Component({
    selector: 'app-new-canvas-template',
    templateUrl: './new-canvas-template-metronic.component.html',
    styleUrls: ['./new-canvas-template.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class NewCanvasTemplateComponent implements OnInit {
    canvas$: Observable<any[]>;
    filtredCanvas$: Observable<any[]>;
    currentProject$: Observable<ProjectModel>;
    user$: Observable<UserModel>;
    currentProject: ProjectModel;
    detailObject: Activity;
    searchTerm: any;
    studioTemplates: any;
    studioTemplates$: any[];
    selectedType = 'all';
    imagesUrl = '';
    selectedCategory$: Observable<string>;
    selectedTemplate = 'all';
    selectedCanvas: any;
    constructor(
        public matDialogRef: MatDialogRef<NewCanvasTemplateComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        private _router: Router
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.imagesUrl = environment.minio.url + '/storage/o/public/';

        this.detailObject = {
            ...this._data,
        };

        this.canvas$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectCanvasTemplates)
        );

        this.filtredCanvas$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectFiltredCanvasTemplates)
        );

        this.user$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoggedUser)
        );

        this.currentProject$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectSelectedProject)
        );

        this.currentProject$.subscribe(currentProject => {
            this.currentProject = currentProject;
            this.studioTemplates = currentProject?.studioTemplates ;
            this.studioTemplates$ = currentProject.studioTemplates
        });

        this.selectedCategory$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectSelectedCategory)
        );
    }

    redirectToTemplateCreation(){
        this._store.dispatch(
            WorkshopCanvasStoreActions.saveTemplate({
                body: {
                    name: this.translateService.currentLang == 'en' ?
                    'New template' : this.translateService.currentLang == 'fr' ? 
                    'Nouveau modèle' : 'نموذج جديد',
                    description: '',
                    longDescription: '',
                    vignette: '',
                    category: 'personalizedTemplate',
                    inProjects: [this.currentProject._id],
                    layout: [],
                },
            })
        );
        this.matDialogRef.close();
        this._router.navigate(['workshop/template']);    
    }

    redirectToTemplateUpdate(template : any){
        this.matDialogRef.close();
        this._store.dispatch(
            WorkshopCanvasStoreActions.updateTemplateSettingsInfo({
                templateId: template._id,
                    body: {
                        name: template.name ? template.name : '',
                        description: template.description ? template.description : '',
                        longDescription: template.longDescription ? template.longDescription : '',
                        vignette: template.vignette ? template.vignette : '',
                        category: template.category ? template.category : 'personalizedTemplate',
                        inProjects: template.inProjects ? template.inProjects :'',
                        layout: template.layout,
                    }
            })
        );
        this._router.navigate(['workshop/template']);
    }

    duplicateTemplate(template : any){
        this._store.dispatch(
            WorkshopCanvasStoreActions.duplicateTemplate({
                body: {
                    studioTemplateIdToDuplicate : template._id,
                }
            })
        );
    }

    deleteTemplate(template : any){
        this._store.dispatch(
            WorkshopCanvasStoreActions.deleteTemplate({
                templates: this.studioTemplates,
                id: template._id,
            })
        );
    }

    saveTemplate(template : any): void {
                this.detailObject = {
                    ...this.detailObject,
                    name: template.name,
                    canvasType: 99,
                    activityType: 'canvas',
                    color: 'activity-color-2',
                    studioTemplateId: template._id,
                };
        this.matDialogRef.close(this.detailObject);
    } 

    save(canva): void {
        if (this.selectedCanvas.activityType === 'brainstorm') {
            this._store.dispatch(
                WorkshopActivitiesStoreActions.addActivity({
                    activity: {
                        name:
                            this.translateService.currentLang.toString() === 'fr'
                                ? 'Brainstorm sans nom'
                                : this.translateService.currentLang.toString() === 'en'
                                    ? 'Unnamed Brainstorm'
                                    : 'عصف ذهني بدون عنوان',
                        activityType: 'brainstorm',
                        color: 'activity-color-1',
                    },
                })
            );
            this.matDialogRef.close(false);
        } else {
            if (this.selectedCanvas.activityType === 'canvas') {
                this.detailObject = {
                    ...this.detailObject,
                    name: this.translateService.instant(this.selectedCanvas.name),
                    canvasType: this.selectedCanvas.type,
                };
                this.matDialogRef.close(this.detailObject);
            } else {
                if (this.selectedCanvas.activityType === 'cardboard') {
                    this._store.dispatch(
                        WorkshopActivitiesStoreActions.showActivityContentDialog({
                            activity: {
                                name:
                                    this.translateService.currentLang.toString() === 'fr'
                                        ? 'Tableau de cartes sans nom'
                                        : this.translateService.currentLang.toString() === 'en'
                                            ? 'Untitled Card deck'
                                            : 'لوحة بطاقات بدون إسم',
                                activityType: 'cardboard',
                                color: 'activity-color-7',
                            },
                        })
                    );
                    this.matDialogRef.close(false);
                }
            }
        }
    }

    filterCanvasByTerm(): void {
        this._store.dispatch(
            WorkshopActivitiesStoreActions.activitySearch({
                text: this.searchTerm,
            })
        );
        this.currentProject$.subscribe(currentProject => {

            this.studioTemplates$ = currentProject.studioTemplates.filter( template=>  template.name.toLowerCase().includes(this.searchTerm) || template.description.toLowerCase().includes(this.searchTerm)   
            );
        });
    }

    filterTemplateByCategory(category): void {
        this.selectedTemplate = category;
    }

    filterCanvasByCategory(category): void {
        this._store.dispatch(
            WorkshopActivitiesStoreActions.activityFilterByCategory({
                category,
            })
        );
    }
}
