export const locale = {
    lang: 'fr',
    data: {
        CLOSE: 'Fermer',
        CONDITIONS: 'Conditions d\'utlisations',
        DEFINITIONS: '1. DEFINITIONS.',
        AGREEMENT: 'Accord de licence de logiciel de pré-lancement Excelway',
        // tslint:disable-next-line: max-line-length
        AGREE_TO_BE_BOUND_BY_THIS_AGREEMENT: 'LE PRÉSENT CONTRAT DE LICENCE DE LOGICIEL DE PRÉ-LANCEMENT (LE "CONTRAT") RÉGIT VOTRE UTILISATION DU LOGICIEL DE PRÉ-LANCEMENT (DÉFINI CI-DESSOUS) FOURNI PAR EXCELWAY ET/OU SES FILIALES ("EXCELWAY"). EN ACCEDANT AU LOGICIEL DE PRÉLANCEMENT, VOUS ACCEPTEZ D\'ÊTRE LIÉ PAR LE PRÉSENT ACCORD.',
        // tslint:disable-next-line: max-line-length
        ACCEPTING_THESE_TERMS: 'SI VOUS ACCEPTEZ CES CONDITIONS AU NOM D\'UNE ENTREPRISE, VOUS DÉCLAREZ ET GARANTISSEZ QUE VOUS AVEZ LE POUVOIR ET L\'AUTORITÉ DE LIER CETTE ENTREPRISE AU PRÉSENT ACCORD, ET VOTRE ACCEPTATION DE CES CONDITIONS SERA CONSIDÉRÉE COMME L\'ACCORD DE L\'ENTREPRISE. DANS CE CAS, "VOUS" ET "VOTRE" FONT RÉFÉRENCE À CETTE ENTREPRISE.',
        // tslint:disable-next-line: max-line-length
        YOU_ARE_NOT_WILLING_TO_ACCEPT: 'SI VOUS N\'ÊTES PAS DISPOSÉ À ACCEPTER LE PRÉSENT ACCORD, OU SI VOUS N\'AVEZ PAS LE DROIT, LE POUVOIR ET L\'AUTORITÉ D\'AGIR AU NOM DE VOTRE ENTREPRISE ET DE LA LIER, N\'ACCÉDEZ PAS OU N\'UTILISEZ PAS DE TOUTE AUTRE MANIÈRE LE LOGICIEL DE PRÉLANCEMENT.',
        // tslint:disable-next-line: max-line-length
        PRE_LAUNCH_SOFTWARE: '"Logiciel de pré-lancement" désigne la version de pré-lancement du produit, service ou technologie de Excelway identifié sur la page d\'accueil du logiciel ou dans le message d\'invitation (la "page de couverture"), qu\'elle soit étiquetée comme alpha, bêta, pré-lancement, aperçu ou autre, qui vous est fournie par Excelway dans le cadre du présent contrat. Le logiciel de prélancement peut inclure toutes les améliorations, mises à jour, mises à niveau, dérivés ou corrections de bogues de ce produit, service ou technologie et toute documentation, add-ons, modèles, échantillons de données ou de dispositifs matériels tels que fournis par Excelway.',
        // tslint:disable-next-line: max-line-length
        DATA: '"Données" signifie les données brutes que vous téléchargez ou soumettez au logiciel de prélancement et le résultat traité des données brutes générées par vous en utilisant le logiciel de prélancement.',
        // tslint:disable-next-line: max-line-length
        FEED_BACK: '"Feedback" signifie toutes les suggestions, commentaires, opinions, codes, apports, idées, rapports, informations, savoir-faire ou autres réactions que vous fournissez (que ce soit sous forme orale, électronique ou écrite) à Excelway en relation avec votre utilisation du logiciel de prélancement. Le retour d\'information n\'inclut pas les données, sauf si vous les avez soumises ou communiquées à Excelway dans le cadre du retour d\'information.',
        // tslint:disable-next-line: max-line-length
        INTERNAL_PURPOSES: 'Les " Fins internes " désignent l\'utilisation interne de vos systèmes, réseaux, dispositifs et données à des fins de tests, d’évaluation internes ou dans le cadre de services que vous fournissez au profit d’un tiers du logiciel de pré-lancement afin de fournir un retour d\'information à Excelway concernant le logiciel de pré-lancement.',
        THE_PRE_LAUNCH_SOFTWARE: '2. LICENCE DU LOGICIEL DE PRÉ-LANCEMENT.',
        // tslint:disable-next-line: max-line-length
        SUBJECT_TO_YOUR_COMPLIANCE: 'Sous réserve de votre respect des termes et conditions du présent contrat, EXCELWAY vous accorde une licence non exclusive, non sous-licenciable, non transférable, révocable et limitée pendant la durée du contrat.',
        LICENSE_RESTRICTIONS: '3. RESTRICTIONS DE LA LICENCE.',
        // tslint:disable-next-line: max-line-length
        EXCEPT_AS_EXPRESSLY: 'Sauf autorisation expresse dans le présent contrat ou par EXCELWAY, vous ne devez pas, et ne devez pas permettre à un tiers de le faire : (i) d\'accéder ou d\'utiliser le logiciel de pré-livraison à des fins autres que les fins internes (y compris pour toute analyse concurrentielle, commerciale, professionnelle, ou autres fins à but lucratif) ; (ii) de copier le logiciel de pré-livraison; (iii) modifier, adapter ou créer des travaux dérivés du logiciel de pré-lancement ; (iv) louer, prêter, revendre, transférer, sous-licencier ou distribuer le logiciel de pré-lancement à un tiers ; (v) utiliser ou offrir toute fonctionnalité du logiciel de pré-lancement sur une base de bureau de services, d\'hébergement, de logiciel en tant que service ou de partage de temps ; (vi) décompiler, désassembler ou faire de l\'ingénierie inverse du logiciel de pré-lancement ou tenter de toute autre manière de dériver le code source du logiciel de pré-lancement, les algorithmes, méthodes ou techniques utilisés ou incorporés dans le logiciel de pré-lancement ; (vii) divulguer à tout tiers les résultats de tout test d\'évaluation ou autre évaluation du logiciel de pré-lancement, ou (viii) supprimer, altérer, masquer, couvrir ou modifier toute marque commerciale, droit d\'auteur ou autres avis de propriété, étiquettes ou marquages de ou sur le logiciel de pré-lancement ; (ix) interférer avec ou perturber les serveurs ou réseaux connectés à tout site Internet par lequel le logiciel de pré-lancement a été fourni ;',
    }
};
