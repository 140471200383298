import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'app/root-store';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { WorkshopActivitiesStoreActions } from 'app/root-store/workshop-store/activities-store';
import { locale as english } from '../../app/i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../app/i18n/@fuse/components/confirm-dialog/fr';

@Injectable({
    providedIn: 'root',
})
export class ServicesService {
    confirmEmport: string;

    constructor(
        private _httpClient: HttpClient,
        public dialog: MatDialog,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh);

        this.translate.stream('CONFIRM_EMPORT').subscribe(
            value => { this.confirmEmport = value; });
    }

    getServices(): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.service}`
        );
    }

    updateProjectServices(servicesIds, id): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectServices}/${id}`,
            servicesIds
        );
    }

    import(file: any, sessionId: string): Observable<any> {
        return this._httpClient.post(
            environment.http.url + environment.http.brainstormingServiceUpload + '/' + sessionId,
            file
        );
    }

    fileChange(event: any): void {
        const file = event.target.files[0];
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        const confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            panelClass: 'fuse-confirm-dialog',
            disableClose: false,
        });
        confirmDialogRef.componentInstance.confirmMessage = this.confirmEmport;

        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._store.dispatch(WorkshopActivitiesStoreActions.uploadService({
                    file
                }));
            }
        });
    }
}
