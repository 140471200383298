import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { DetailModel } from '@bsuccess/models/project/detail.model';
import { locale as english } from '../../../../i18n/workshop/notes/dialogs/note-new-dialog/en';
import { locale as frensh } from '../../../../i18n/workshop/notes/dialogs/note-new-dialog/fr';
import { locale as arabic } from '../../../../i18n/workshop/notes/dialogs/note-new-dialog/ar';

@Component({
    selector: 'note-new-dialog',
    templateUrl: './note-new-dialog.component.html',
    styleUrls: ['./note-new-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NoteNewDialogComponent implements OnInit {
    detailObject: DetailModel;
    error$: Observable<string>;
    composeForm: UntypedFormGroup;
    constructor(
        public matDialogRef: MatDialogRef<NoteNewDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        this.composeForm = this.createComposeForm();
        this.matDialogRef.disableClose = true;
    }

    createComposeForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            title: new UntypedFormControl(''),
            description: new UntypedFormControl(''),
        });
    }
    Save(): void {
        this.detailObject = {
            title: this.composeForm.value.title,
            description: this.composeForm.value.description,
        };
    }
    ngOnInit(): void { }
}
