import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/session-new-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/session-new-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/session-new-dialog/ar';

@Component({
    selector: 'session-new-dialog',
    templateUrl: './session-new-dialog.component.html',
    styleUrls: ['./session-new-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SessionNewDialogComponent implements OnInit {
    error$: Observable<string>;

    constructor(
        public dialogRef: MatDialogRef<SessionNewDialogComponent>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.dialogRef.disableClose = true;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void { }
}
