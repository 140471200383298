import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';

export const selectHomeState = createFeatureSelector<State>('studio-home');

export const selectProjects = createSelector(
    selectHomeState,
    (state: State): number => state.projects
);

export const selectBoards = createSelector(
    selectHomeState,
    (state: State): number => state.boards
);

export const selectSessions = createSelector(
    selectHomeState,
    (state: State): ProjectSessionModel[] => state.filtredSessions.sort((a, b) => {
        return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
    })
);
