export const locale = {
    lang: 'ar',
    data: {
        // tslint:disable-next-line: max-line-length
        SESSION_NEW_DIALOG_NEW_MEMBERS_PARAGRAPH: 'عيّن ميسّرًا وادعُ الأعضاء لمساعدتك في تنظيم الورشة. إذا كانوا مشاركين أيضًا ، فسيكون بإمكانهم الوصول إلى محضر الاجتماع وتعديله.',
        SESSION_NEW_DIALOG_NEW_MEMBERS_NOTE: 'ملاحظة: يرجى إضافة على الأقل',
        SESSION_NEW_DIALOG_NEW_MEMBERS_AN_ANIMATOR: ' مُيَسّرْ',
        SESSION_NEW_DIALOG_NEW_MEMBERS_END_NOTE: 'لاستمرار',
        SESSION_NEW_DIALOG_NEW_MEMBERS_PARTICIPANT: 'تضمين كمشارك؟',
        SESSION_NEW_DIALOG_NEW_MEMBERS_MEMBERS: 'الأعضاء',
        SESSION_NEW_DIALOG_NEW_MEMBERS_THE_EMAIL: 'البريد الإلكتروني',
        SESSION_NEW_DIALOG_NEW_MEMBERS_POSITION: 'موضع',
        SESSION_NEW_DIALOG_NEW_MEMBERS_SPONSOR: 'الكفيل', 
        SESSION_NEW_DIALOG_NEW_MEMBERS_ADMIN: 'مشرف',
        SESSION_NEW_DIALOG_NEW_MEMBERS_RESPONSIBLE: 'المالك',
        SESSION_NEW_DIALOG_NEW_MEMBERS_ANIMATOR: 'مُيَسّرْ',
        SESSION_NEW_DIALOG_NEW_MEMBERS_ROLE: 'دَوْر',
        SESSION_NEW_DIALOG_NEW_MEMBERS_READING: 'مٌشاهِد',
        SESSION_NEW_DIALOG_NEW_MEMBERS_MODIFICATION: 'مُحَرّرْ',
        SESSION_NEW_DIALOG_NEW_MEMBERS_REMOVE: 'إزالة',
        SESSION_NEW_DIALOG_NEW_MEMBERS_SAVE: 'حفظ',
        SESSION_NEW_DIALOG_NEW_MEMBERS_CANCEL: 'إلغاء',
    }
};
