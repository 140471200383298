export const locale = {
    lang: 'ar',
    data: {
        CATEGORIES: 'الألوان',
        ADD: 'أضف',
        EDIT_CATEGORY: 'تحرير لون',
        DELETE_CATEGORY: 'حذف لون',
        ADD_CATEGORY: 'إضافة لون',
        ADD_U: 'إضافة',
        NAME: 'تَسْمِيَة',
    }
};
