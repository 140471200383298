import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { Activity } from '@bsuccess/models/activities/activity.model';

export const selectActivityState = createFeatureSelector<State>(
    'workshop-activity'
);

export const selectCurrentActivity = createSelector(
    selectActivityState,
    (state: State): Activity => state.currentActivity
);
