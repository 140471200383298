export const locale = {
    lang: 'en',
    data: {
        PROJECT_NAME: 'Project name',
        START_DATE: 'Start date',
        END_DATE: 'End date',
        DESCRIPTION: 'Description',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        VALIDATORS_START_DATE_REQUIRED: 'The start date is obligatory.' ,
        VALIDATORS_END_DATE_REQUIRED: 'The end date is obligatory.' ,  
        VALIDATORS_END_DATE_BEFORE: 'Start date is greater than end date' ,
        VALIDATORS_INVALID_START_TIME: 'The start time is invalid' ,
        VALIDATORS_INVALID_END_TIME: 'The end time is invalid' ,  
        VALIDATORS_END_TIME_BEFORE: 'Start time is greater than end time' , 
    }
};
