import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

import { FuseNavigationModule, FuseConfirmDialogModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarVerticalStyle1Component } from 'app/layout/components/navbar/vertical/style-1/style-1.component';
import { BsuccessModule } from '@bsuccess/bsuccess.module';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { TruncateModule } from 'ng2-truncate';

@NgModule({
    declarations: [
        NavbarVerticalStyle1Component
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatBadgeModule,
        MatTooltipModule,
        MatMenuModule,
        TranslateModule,

        FuseSharedModule,
        FuseNavigationModule,
        FuseConfirmDialogModule,
        RouterModule,
        TruncateModule,

        BsuccessModule
    ],
    exports: [
        NavbarVerticalStyle1Component
    ]
})
export class NavbarVerticalStyle1Module {
}
