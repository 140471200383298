import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { RootStoreState } from '../..';
import * as BoardsActions from './actions';
import { BoardsService } from '@bsuccess/services/boards.service';

@Injectable()
export class StudioBoardsStoreEffects {
  constructor(
    private _store: Store<RootStoreState.State>,
    private _boardsService: BoardsService,
    private _actions$: Actions
  ) { }

  loadBoards$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardsActions.load),
      exhaustMap(_ => {
        return this._boardsService.getBoards().pipe(
          map(response => BoardsActions.loadSuccess({ response })),
          catchError(error =>
            of(
              BoardsActions.loadFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  addFavorite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardsActions.addFavorite),
      exhaustMap(action => {
        return this._boardsService.addFavorite(action.id).pipe(
          map(_ =>
            BoardsActions.addFavoriteSuccess({
              id: action.id,
            })
          ),
          catchError(error =>
            of(
              BoardsActions.addFavoriteFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  removeFavorite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardsActions.removeFavorite),
      exhaustMap(action => {
        return this._boardsService.removeFavorite(action.id).pipe(
          map(_ =>
            BoardsActions.removeFavoriteSuccess({
              id: action.id,
            })
          ),
          catchError(error =>
            of(
              BoardsActions.removeFavoriteFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );
}
