export const locale = {
    lang: 'ar',
    data: {
        NEW: 'عمل جديد',
        TITLE: 'عنوان',
        DUE_DATE: 'تاريخ الاستحقاق',
        COMMENT: 'تعليق',
        RESPONSIBLE: 'المالك',
        DESCRIPTION: 'الوصف',
        ACCEPT: 'وافق',
        REFUSE: 'إرفض',
    }
};
