import { UserModel } from './../../../../@bsuccess/models/user.model';
import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    Input,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import {
    StudioProjectStoreActions,
    StudioProjectStoreSelectors,
} from 'app/root-store/studio-store/project-store';
import { RootStoreState } from 'app/root-store';
import {
    StudioSessionStoreActions,
    StudioSessionStoreSelectors,
} from 'app/root-store/studio-store/session-store';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { ActionName } from '@bsuccess/models/action-permission.model';
import { SessionModel } from '@bsuccess/models/session/session.model';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { StudioNotificationsStoreSelectors } from 'app/root-store/studio-store/notifications-store';
import {
    StudioBoardStoreSelectors,
    StudioBoardStoreActions,
} from 'app/root-store/studio-store/board-store';
import { locale as english } from '../../../i18n/layout/components/toolbar/en';
import { locale as frensh } from '../../../i18n/layout/components/toolbar/fr';
import { locale as arabic } from '../../../i18n/layout/components/toolbar/ar';
import { HelpHeroService } from '@bsuccess/services/helphero.service';
import { NavbarStoreActions } from 'app/root-store/navbar-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { StandbyCardModel } from '@bsuccess/models/standbycard/standbycard.model';
import { environment } from 'environments/environment';
import { NotificationService } from '@bsuccess/services/notification.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    imagesUrl = '';
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    badge: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    status: string;
    membersList: any[];
    connectedUser$: Observable<UserModel>;
    toggleListView$: Observable<any>;
    @Input() headerState: any;
    // OWNER VARIABLES
    originalStateOwner = true;
    sortOwnerSwitchOn = false;
    sortOwnerSwitchOff = false;

    // PRIO VARIABLES
    originalStatePrio = true;
    sortPrioSwitchOn = false;
    sortPrioSwitchOff = false;

    // DATE VARIABLES
    originalStateDate = true;
    sortDateSwitchOn = false;
    sortDateSwitchOff = false;

    currentProject$: Observable<ProjectModel>;
    currentSession$: Observable<SessionModel>;
    currentNotificationsCount$: Observable<number>;
    currentBoard$: Observable<BoardModel>;
    currentBoard: BoardModel;
    notifications$: Observable<StandbyCardModel[]>;
    inAppNotification$: Observable<any[]>;
    inAppNotification = [];
    viewType$: Observable<string>;

    ActionName = ActionName;
    currentSession: SessionModel;
    fuseConfig: any;
    searchTerm: string;

    overviewClicked = false;
    kanbanClicked = false;
    listClicked = true;
    dashboardClicked = false;
    calendarClicked = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        public translateService: TranslateService,
        private _helpheroService: HelpHeroService,
        private _store: Store<RootStoreState.State>,
        private _router: Router,
        private translationLoaderService: FuseTranslationLoaderService,
        private _notificatonService: NotificationService,
        private translate: TranslateService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.searchTerm = '';

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50',
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107',
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336',
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD',
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161',
            },
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'fr',
                title: 'Français',
                flag: 'fr',
            },
            {
                id: 'ar',
                title: 'العربية',
                flag: 'ar',
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.toggleListView$ = this._store.pipe(
            select(StudioBoardStoreSelectors.selectView)
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.imagesUrl = environment.minio.url + '/storage/o/public/';
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings: any) => {
                this.fuseConfig = settings;
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this.translateService.currentLang,
        });
        // update  status for session
        this._store
            .select(StudioSessionStoreSelectors.selectCurrentStatus)
            .subscribe((status) => {
                if (status) {
                    this.status = status;
                }
            });
        // get current project
        this.currentProject$ = this._store.pipe(
            select(StudioProjectStoreSelectors.selectCurrent)
        );
        // get current session
        this.currentSession$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectCurrent)
        );
        // get current board
        this.currentBoard$ = this._store.pipe(
            select(StudioBoardStoreSelectors.selectCurrent)
        );
        this.currentBoard$.subscribe(_ => {
            this.currentBoard = _;
            if(_?.filterIsOn){
                this.searchTerm = '';
            }
        })
        this.viewType$ = this._store.pipe(
            select(StudioBoardStoreSelectors.selectView)
        );

        // get notifications badge
        this.currentNotificationsCount$ = this._store.pipe(
            select(StudioNotificationsStoreSelectors.loadNotificationsCount)
        );
        this.currentNotificationsCount$.subscribe((count) => {
            this.badge = count;
        });

        this.currentSession$.subscribe((currentSession) => {
            setTimeout(() => {
                this.currentSession = currentSession;
            }, 0);
        });

        this.membersList = ['Abdeljaouad Benhaddou', 'Sophia Benhaddou'];

        this.connectedUser$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoggedUser)
        );

        this.notifications$ = this._store.pipe(
            select(StudioNotificationsStoreSelectors.loadNotifications)
        );

        this.inAppNotification$ = this._store.pipe(
            select(LoginStoreSelectors.selectInAppNotifications)
        );

        this.inAppNotification$.subscribe(inAppNotification => {
            if (inAppNotification.length > 0) {
                this.inAppNotification = inAppNotification.filter(_ => _.read === false);
            }
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key: any): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value: string): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang: any): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        this._store.dispatch(
            NavbarStoreActions.updateLang({
                lang: lang,
            })
        );
        // Use the selected language for translations
        this.translateService.use(lang);

        HelpHeroService.lang = lang;
    }

    updateProjectMembers(): void {
        this._store.dispatch(StudioProjectStoreActions.projectShowUsersDialog());
    }

    updateProjectDetails(): void {
        this._store.dispatch(StudioProjectStoreActions.showProjectGeneralDialog());
    }

    updateProjectLevels(): void {
        this._store.dispatch(StudioProjectStoreActions.showLevelsDialog());
    }

    archiveProject(): void {
        this._store.dispatch(StudioProjectStoreActions.archiveProject());
    }

    navigate(link: any): void {
        this._router.navigate([link.root]);
        this._store.dispatch(
            StudioProjectStoreActions.loadCurrent({
                id: link.id,
            })
        );
    }
    
    filterCardsByTerm(): void {
        this._store.dispatch(
            StudioBoardStoreActions.searchCard({
                text: this.searchTerm,
            })
        );
    }

    updateProjectServices(): void {
        this._store.dispatch(StudioProjectStoreActions.showServicesDialog());
    }

    updateSessionMembers(): void {
        // this._store.dispatch(StudioSessionStoreActions.sessionShowUsersDialog());
    }

    updateSessionParticipantsAndSendInvitation(): void {
        this._store.dispatch(
            StudioSessionStoreActions.showUpdateParticipantsAndSendInvitationDialog()
        );
    }

    updateSessionGuestsAndSendInvitation(): void {
        this._store.dispatch(
            StudioSessionStoreActions.showUpdateGuestsAndSendInvitationDialog()
        );
    }

    updateBoardMembers(): void {
        this._store.dispatch(StudioProjectStoreActions.showBoardMembersDialog());
    }
    createWidget(): void {
        this._store.dispatch(StudioProjectStoreActions.showCreateWidgetDialog());
    }
    updateBoardDetails(): void {
        this._store.dispatch(StudioBoardStoreActions.showBoardDetailsDialog());
    }
    archiveSession(): void {
        // this._store.dispatch(StudioSessionStoreActions.archiveSession());
    }
    updateSessionDetails(): void {
        this._store.dispatch(
            StudioSessionStoreActions.showUpdateSessionDetailsDialog()
        );
    }
    archiveBoard(): void {
        this._store.dispatch(StudioBoardStoreActions.archiveBoard());
    }
    openHelp(): void {
        HelpHeroService.open();
    }

    toggleTasksPanel(): void {
        this._store.dispatch(NavbarStoreActions.toggleTasksPanel());
    }

    signOut(): void {
        this._store.dispatch(NavbarStoreActions.logout());
    }

    showNavbar(): void {
        this._fuseConfigService.setConfig({
            ...this.fuseConfig,
            layout: {
                ...this.fuseConfig.layout,
                navbar: {
                    ...this.fuseConfig.layout.navbar,
                    hidden: false,
                },
            },
        });
    }

    supportChat(): void {
        // tslint:disable-next-line: no-eval
        eval(`$crisp.push(['do', 'chat:show'])`);
        // tslint:disable-next-line: no-eval
        eval(`$crisp.push(['do', 'chat:open'])`);
    }

    onNavigate(): void {
        if (this.translateService.currentLang == 'fr') {
            window.open("https://www.excelway.co/centre-daide/", "_blank");
        } else {
            window.open("https://www.excelway.co/help-center/", "_blank");
        }
    }

    toggleProjectsOpened(): void {
        this._fuseSidebarService.getSidebar('projectsPanel').toggleOpen();
    }

    toggleBackgroundPanel(): void {
        this._store.dispatch(StudioBoardStoreActions.toggleBackgroundPanel());
    }

    changeViewToOverview(): void {
        this._store.dispatch(
            StudioBoardStoreActions.toggleListViewOn({
                viewType: 'overview',
            })
        );
        this.overviewClicked = true;
        this.kanbanClicked = false;
        this.listClicked = false;
        this.calendarClicked = false;
        this.dashboardClicked = false;
    }

    changeViewToKanban(): void {
        this._store.dispatch(
            StudioBoardStoreActions.toggleListViewOn({
                viewType: 'kanban',
            })
        );
        this.kanbanClicked = true;
        this.overviewClicked = false;
        this.listClicked = false;
        this.calendarClicked = false;
        this.dashboardClicked = false;
    }
    changeViewToList(): void {
        this._store.dispatch(
            StudioBoardStoreActions.toggleListViewOn({
                viewType: 'list',
            })
        );
        this.listClicked = true;
        this.overviewClicked = false;
        this.kanbanClicked = false;
        this.calendarClicked = false;
        this.dashboardClicked = false;
    }
    changeViewToCalendar(): void {
        this._store.dispatch(
            StudioBoardStoreActions.toggleListViewOn({
                viewType: 'calendar',
            })
        );
        this.calendarClicked = true;
        this.overviewClicked = false;
        this.kanbanClicked = false;
        this.listClicked = false;
        this.dashboardClicked = false;
    }
    changeViewToDash(): void {
        this._store.dispatch(
            StudioBoardStoreActions.toggleListViewOn({
                viewType: 'dashboard',
            })
        );
        this.calendarClicked = false;
        this.overviewClicked = false;
        this.kanbanClicked = false;
        this.listClicked = false;
        this.dashboardClicked = true;
    }

    sortByOwner(): void {
        this._store.dispatch(StudioBoardStoreActions.sortBoardByOwner());
        if (!this.sortOwnerSwitchOn) {
            this._store.dispatch(StudioBoardStoreActions.unsortBoardbyOwner());
        }
    }

    sortByPriority(): void {
        this._store.dispatch(StudioBoardStoreActions.sortBoardbyPriority());
        if (!this.sortPrioSwitchOn) {
            this._store.dispatch(StudioBoardStoreActions.unsortBoardbyPriority());
        }
    }

    sortByDate(): void {
        this._store.dispatch(StudioBoardStoreActions.sortBoardbyDate());
        if (!this.sortDateSwitchOn) {
            this._store.dispatch(StudioBoardStoreActions.unsortBoardbyDate());
        }
    }

    saveAsTemplate(): void {
        this._store.dispatch(StudioProjectStoreActions.projectSaveBoardAsTemplate(
            {
                projectTemplate: true
            }
        ))

        this._notificatonService.showSuccess(
            this.translate.currentLang.toString() === 'fr' ?
                'Le tableau a été enregistré comme modèle.' :
                this.translate.currentLang.toString() === 'en' ?
                    'The board has been saved as a template.' : 'تم حفظ اللوحة كنموذج'
        );
    }

    removeAsTemplate(): void {
        this._store.dispatch(StudioProjectStoreActions.projectSaveBoardAsTemplate(
            {
                projectTemplate: false
            }
        ));

        this._notificatonService.showSuccess(
            this.translate.currentLang.toString() === 'fr' ?
                'Le tableau a été supprimé comme modèle.' :
                this.translate.currentLang.toString() === 'en' ?
                    'The board has been removed as a template.' : 'تم إزالة هذا المنتدى كقالب'
        );
    }
}
