export const locale = {
    lang: 'ar',
    data: {
        MANAGE_MEMBERS: 'دعوة أعضاء',
        // tslint:disable-next-line: max-line-length
        PARAGRAPH: 'عيّن ميسّرًا وادعُ الأعضاء لمساعدتك في تنظيم الورشة. إذا كانوا مشاركين أيضًا ، فسيكون بإمكانهم الوصول إلى محضر الاجتماع وتعديله.',
        INCLUDE_PARTICIPANT: 'تضمين كمشارك؟',
        MEMBERS: 'عُضْو',
        THE_EMAIL: 'البريد الإلكتروني',
        POSITION: 'موضع',
        SPONSOR: 'الكفيل',
        ADMIN: 'مشرف',
        ADD_USER_BUTTON_LABEL: 'إضافة مستخدم',
        RESPONSIBLE: 'المالك',
        ANIMATOR: 'المُيَسّر',
        ROLE: 'دَوْر',
        READING: 'مُشاهد',
        MODIFICATION: 'مُحَرّر',
        REMOVE: 'إزالة',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
        PARTICIPANT: 'المشارك',
    }
};
