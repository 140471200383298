export const locale = {
    lang: 'en',
    data: {
        COMPONENTS_TOOLBAR_HI: 'Hi,',
        COMPONENTS_TOOLBAR_ONLINE: 'Online',
        COMPONENTS_TOOLBAR_PROFILE: 'Profile',
        COMPONENTS_TOOLBAR_LANGUAGE: 'Language',
        COMPONENTS_TOOLBAR_MEMBERS: 'Members',
        COMPONENTS_TOOLBAR_LOGOUT: 'Logout',
        COMPONENTS_TOOLBAR_INVITE: 'INVITE',
        COMPONENTS_TOOLBAR_ADD_WIDGET: 'Add widget',
        COMPONENTS_TOOLBAR_PROJECTS: 'Projects',
        COMPONENTS_TOOLBAR_BOARDS: 'Boards',
        COMPONENTS_TOOLBAR_SESSIONS: 'Workshops',
        COMPONENTS_TOOLBAR_EDIT_PROJECT: 'Edit project details',
        COMPONENTS_TOOLBAR_MANAGE: 'Manage members',
        COMPONENTS_TOOLBAR_EDIT_PROJECT_STRUCTURE: 'Edit project structure',
        COMPONENTS_TOOLBAR_ARCHIVE: 'Delete',
        COMPONENTS_TOOLBAR_EDIT_BOARD: 'Board settings  ',
        COMPONENTS_TOOLBAR_EDIT_BOARD_BACKGROUND: 'Change background',
        COMPONENTS_TOOLBAR_EDIT_SESSION: 'Edit workshop details',
        COMPONENTS_TOOLBAR_MANAGE_PARTICIPANTS: 'Manage members',
        COMPONENTS_TOOLBAR_TUTORIALS: 'Tutorials',
        COMPONENTS_TOOLBAR_CREATE_PROJECT_ONE: 'Create project',
        COMPONENTS_TOOLBAR_NAVIGATE_THE_PROJECT: 'Navigate the project',
        COMPONENTS_TOOLBAR_CREATE_SESSION_ONE: 'Create workshop',
        COMPONENTS_TOOLBAR_MANAGE_ACTIONS: 'Manage actions after a workshop',
        COMPONENTS_TOOLBAR_MANAGE_WORK: 'Manage your work in board',
        COMPONENTS_TOOLBAR_CHAT_SUPPORT: 'Chat with support',
        COMPONENTS_TOOLBAR_ADMIN: 'Admin',
        COMPONENTS_TOOLBAR_EDITOR: 'Editor',
        COMPONENTS_TOOLBAR_VIEWER: 'Viewer',
        COMPONENTS_TOOLBAR_HELP_CENTER: 'Help Center',
        COMPONENTS_TOOLBAR_TASK_TITLE: 'Task Title',
        COMPONENTS_TOOLBAR_PRIORITY: 'Priority',
        COMPONENTS_TOOLBAR_DATE: 'Date',
        COMPONENTS_TOOLBAR_KANBAN: 'Kanban',
        COMPONENTS_TOOLBAR_DASHBOARD: 'Dashboard',
        COMPONENTS_TOOLBAR_LIST: 'List',
        COMPONENTS_TOOLBAR_SAVE_AS_TEMPLATE: 'Save as template',
        COMPONENTS_TOOLBAR_REMOVE_AS_TEMPLATE: 'Remove from templates',
        COMPONENTS_TOOLBAR_BOARD_KEYWORD: 'Enter a keyword ...',
    }
};
