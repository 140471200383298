export const locale = {
    lang: 'fr',
    data: {
        COMPONENTS_TOOLBAR_HI: 'Bienvenue,',
        COMPONENTS_TOOLBAR_ONLINE: 'Online',
        COMPONENTS_TOOLBAR_PROFILE: 'Profil',
        COMPONENTS_TOOLBAR_LANGUAGE: 'Langue',
        COMPONENTS_TOOLBAR_MEMBERS: 'Membres',
        COMPONENTS_TOOLBAR_LOGOUT: 'Se déconnecter',
        COMPONENTS_TOOLBAR_INVITE: 'INVITER',
        COMPONENTS_TOOLBAR_ADD_WIDGET: 'Ajouter un widget',
        COMPONENTS_TOOLBAR_PROJECTS: 'Projets',
        COMPONENTS_TOOLBAR_BOARDS: 'Tableaux',
        COMPONENTS_TOOLBAR_SESSIONS: 'Ateliers',
        COMPONENTS_TOOLBAR_EDIT_PROJECT: 'Modifier les détails du projet',
        COMPONENTS_TOOLBAR_MANAGE: 'Gérer les membres',
        COMPONENTS_TOOLBAR_EDIT_PROJECT_STRUCTURE: 'Modifier la structure du projet',
        COMPONENTS_TOOLBAR_ARCHIVE: 'Supprimer',
        COMPONENTS_TOOLBAR_EDIT_BOARD: 'Paramètres du tableau',
        COMPONENTS_TOOLBAR_EDIT_BOARD_BACKGROUND: 'Changer le fond d\'écran',
        COMPONENTS_TOOLBAR_EDIT_SESSION: 'Modifier les détails de l\'atelier',
        COMPONENTS_TOOLBAR_MANAGE_PARTICIPANTS: 'Gérer les participants',
        COMPONENTS_TOOLBAR_TUTORIALS: 'Tutoriels',
        COMPONENTS_TOOLBAR_CREATE_PROJECT_ONE: 'Créer un projet',
        COMPONENTS_TOOLBAR_NAVIGATE_THE_PROJECT: 'Naviguer dans le projet',
        COMPONENTS_TOOLBAR_CREATE_SESSION_ONE: 'Créer un atelier',
        COMPONENTS_TOOLBAR_MANAGE_ACTIONS: 'Gérer les actions après un atelier',
        COMPONENTS_TOOLBAR_MANAGE_WORK: 'Gérer son travail dans un tableau',
        COMPONENTS_TOOLBAR_CHAT_SUPPORT: 'Chatter avec le support',
        COMPONENTS_TOOLBAR_ADMIN: 'Admin',
        COMPONENTS_TOOLBAR_EDITOR: 'Modification',
        COMPONENTS_TOOLBAR_VIEWER: 'Lecture',
        COMPONENTS_TOOLBAR_HELP_CENTER: 'Centre d\'aide',
        COMPONENTS_TOOLBAR_TASK_TITLE: 'Titre de l\'action',
        COMPONENTS_TOOLBAR_PRIORITY: 'Priorité',
        COMPONENTS_TOOLBAR_DATE: 'Date d\'échéance',
        COMPONENTS_TOOLBAR_KANBAN: 'Kanban',
        COMPONENTS_TOOLBAR_DASHBOARD: 'Tableau de bord',
        COMPONENTS_TOOLBAR_LIST: 'Liste',
        COMPONENTS_TOOLBAR_SAVE_AS_TEMPLATE: 'Enregistrer comme modèle',
        COMPONENTS_TOOLBAR_REMOVE_AS_TEMPLATE: 'Retirer des modèles',
        COMPONENTS_TOOLBAR_BOARD_KEYWORD: 'Entrer un mot-clé...',
    }
};
