export const locale = {
    lang: 'fr',
    data: {
        PROJECT_DIALOGS_DOCUMENT_NEW_ADD_DOCUMENT: 'Ajouter un document',
        PROJECT_DIALOGS_DOCUMENT_NEW_TITLE_DOCUMENT: 'Titre du Document',
        PROJECT_DIALOGS_DOCUMENT_NEW_REPORT: 'Compte-Rendu',
        PROJECT_DIALOGS_DOCUMENT_NEW_TYPE: 'Type',
        PROJECT_DIALOGS_DOCUMENT_NEW_PRESENTATION: 'Présentation',
        PROJECT_DIALOGS_DOCUMENT_NEW_OTHER: 'Autre',
        PROJECT_DIALOGS_DOCUMENT_NEW_BROWSE: 'Parcourir vos fichiers',
        PROJECT_DIALOGS_DOCUMENT_NEW_SAVE: 'Enregistrer',
        PROJECT_DIALOGS_DOCUMENT_NEW_CANCEL: 'Annuler',
    }
};
