import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';

import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';

import { locale as english } from '../../../../../i18n/workshop/activities/animator/dialogs/participant-informations/en';
import { locale as frensh } from '../../../../../i18n/workshop/activities/animator/dialogs/participant-informations/fr';
import { locale as arabic } from '../../../../../i18n/workshop/activities/animator/dialogs/participant-informations/ar';
import { Observable } from 'rxjs';
import { WorkshopActivitiesStoreActions } from 'app/root-store/workshop-store/activities-store';
import { StudioSessionStoreSelectors } from 'app/root-store/studio-store/session-store';

@Component({
    selector: 'animator-participant-informations-dialog',
    templateUrl: 'participant-informations.component.html',
    styleUrls: ['participant-informations.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AnimatorParticipantInformationsComponent implements OnInit {
    sessionUsers$: Observable<ProjectUserModel[]>;
    sessionParticipants$: Observable<ProjectUserModel[]>;
    animator: ProjectUserModel;
    sessionParticipantsEmails: string[];
    constructor(
        private translationLoaderService: FuseTranslationLoaderService,
        public matDialogRef: MatDialogRef<AnimatorParticipantInformationsComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        this.matDialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.sessionUsers$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectSessionUsers)
        );
        this.sessionUsers$.subscribe(users => {
            this.animator = users.filter(_ => _.position === 'animator')[0];
        });
        this.sessionParticipants$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectSessionParticipants)
        );
        this.sessionParticipants$.subscribe(participants => {
            this.sessionParticipantsEmails = participants.map(_ => _.email);
        });

    }

    getInitials(user: ProjectUserModel): string {
        if (user) {
            if (user.firstName && user.lastName) {
                return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
            } else {
                return `${user.email.charAt(0)}${user.email.charAt(1)}`;
            }
        } else {
            return 'NA';
        }
    }

    switchToAnimator(): void {
        this._store.dispatch(
            WorkshopActivitiesStoreActions.toggleAnimator({
                user: this._data
            })
        );
    }

    switchToParticipant(): void {
        
    }

}
