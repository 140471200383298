import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { WorkshopTimerStoreActions } from 'app/root-store/workshop-store/timer-store';
import { WorkshopCanvasStoreSelectors, WorkshopCanvasStoreActions } from 'app/root-store/workshop-store/canvas-store';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { locale as english } from '../../../../../../i18n/workshop/canvas/animator/board/dialogs/start-brainstorm/en';
import { locale as frensh } from '../../../../../../i18n/workshop/canvas/animator/board/dialogs/start-brainstorm/fr';
import { locale as arabic } from '../../../../../../i18n/workshop/canvas/animator/board/dialogs/start-brainstorm/ar';
import { WorkshopActivityStoreActions, WorkshopActivityStoreSelectors } from 'app/root-store/workshop-store/activity-store';
import { Activity } from '@bsuccess/models/activities/activity.model';

@Component({
    selector: 'animator-canvas-start',
    templateUrl: './start-brainstorm.component.html',
    styleUrls: ['./start-brainstorm.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class StartCanvasComponent implements OnInit {
    allComplete  :boolean =false;
    categories$: Observable<SessionCategoryModel[]>;
    categories: SessionCategoryModel[] = [];
    currentActivity$: Observable<Activity>;
    currentActivity: Activity;
    allCategoriesChecked$: Observable<boolean>;
    allCategoriesChecked: boolean;
    isAnyCategoryChecked: boolean;
    constructor(
        private _store: Store<RootStoreState.State>,
        public matDialogRef: MatDialogRef<StartCanvasComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.categories$ = this._store.pipe(
            select(LoginStoreSelectors.selectSessionCategories)
        );
        this.allCategoriesChecked$ = this._store.pipe(
            select(WorkshopCanvasStoreSelectors.selectAllCategoriesChecked)
        );
        this._store.dispatch(
            WorkshopCanvasStoreActions.updateAllCategoriesChecked({
                checked: true
            })
        );
        this.currentActivity$ = this._store.pipe(
            select(WorkshopActivityStoreSelectors.selectCurrentActivity)
        );
        this.categories$.subscribe(categories => {
            this.categories = categories;
            if (this.categories.filter(_ => _.checked).length === 0) {
                this.isAnyCategoryChecked = false;
            } else {
                this.isAnyCategoryChecked = true;
            }
        });

        this.currentActivity$.subscribe(_ => {
            this.currentActivity = _;
        });

        this.allCategoriesChecked$.subscribe(checked => {
            this.allCategoriesChecked = checked;
        });
        this.allCategoriesChecked =false;
    }

    someComplete(): boolean {
        if (this.categories == null) {
          return false;
        }
        return this.categories.filter(t => t.checked).length > 0 && !this.allComplete; 
    }
    startBrainstorm(value: number): void {
        if(this.allCategoriesChecked){
            this.categories.filter(_ => _.checked = true);
        }
        this._store.dispatch(
            WorkshopTimerStoreActions.start({
                value: 9999,
                exercise: 'canvas',
                balance: 0,
                maxPerCard:  0
            })
        );

        if (this.currentActivity.evaluationStarted) {
            this._store.dispatch(WorkshopActivityStoreActions.updateStartedEvaluation({
                activity: this.currentActivity,
                evaluationStarted: false
            }));
        }
        this._store.dispatch(WorkshopActivityStoreActions.updateStartedBrainstorm({
            activity: this.currentActivity,
            brainstormStarted: true
        }));

        this._store.dispatch(
            WorkshopCanvasStoreActions.updateTimer({
                timer: {
                    balance: 0,
                    maxPerCard: 0,
                    timerType: 'brainstorm',
                    categories: [],
                    timer: true
                }
            })
        );

        this._store.dispatch(
            WorkshopCanvasStoreActions.updateWorkFlowStep({
                step: 2
            })
        );
    }

    updateAllSelectedCategories(allCategoriesChecked,completed): void {
        this._store.dispatch(
            WorkshopCanvasStoreActions.updateAllCategoriesChecked({
                checked: this.allCategoriesChecked
            })
        );
        this.allComplete = completed;
        if (this.categories == null) {
          return;
        }
        this.categories.forEach(t => (t.checked = completed));
    }

}
