import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { DimensionModel } from '@bsuccess/models/cardboard/dimension.model';
import { ChapterModel } from '@bsuccess/models/cardboard/chapiter.model';
import { ItemModel } from '@bsuccess/models/cardboard/item.model';
import { ItemArticleModel } from '@bsuccess/models/cardboard/item-article.model';
import { ResultCommentModel } from '@bsuccess/models/cardboard/result-comment.model';
import { AttachmentModel } from '@bsuccess/models/attachment.model';
import { CardboardStateModel } from '@bsuccess/models/socket-io/cardboard-state.model';
import { ResultChartModel } from '@bsuccess/models/cardboard/result-chart.model';
import { ResultRateModel } from '@bsuccess/models/cardboard/result-rate.model';
import { ResultGlobalChartModel } from '@bsuccess/models/cardboard/result-global-chart.model';

export const selectCardboardState = createFeatureSelector<State>('workshop-cardboard');

export const selectDimensions = createSelector(
    selectCardboardState,
    (state: State): DimensionModel[] => state.dimensions
);

export const selectDimension = createSelector(
    selectCardboardState,
    (state: State): DimensionModel => state.dimension
);

export const selectChapters = createSelector(
    selectCardboardState,
    (state: State): ChapterModel[] => state.chapters
);

export const selectChapter = createSelector(
    selectCardboardState,
    (state: State): ChapterModel => state.chapter
);

export const selectItems = createSelector(
    selectCardboardState,
    (state: State): ItemModel[] => state.items
);

export const selectItem = createSelector(
    selectCardboardState,
    (state: State): ItemModel => state.item
);

export const selectArticles = createSelector(
    selectCardboardState,
    (state: State): ItemArticleModel[] => state.articles
);

export const selectCurrentItemId = createSelector(
    selectCardboardState,
    (state: State): string => (state.item ? state.item._id : state.chapter._id)
);

export const selectComments = createSelector(
    selectCardboardState,
    (state: State): ResultCommentModel[] =>
        state.item ? state.item.result.comments : state.chapter.result.comments
);

export const selectRates = createSelector(
    selectCardboardState,
    (state: State): ResultRateModel[] =>
        state.item ? state.item.result.rates : state.chapter.result.rates
);

export const selectAvg = createSelector(
    selectCardboardState,
    (state: State): number =>
        state.item ? state.item.result.avg : state.chapter.result.avg
);

export const selectPending = createSelector(
    selectCardboardState,
    (state: State): boolean => state.pending
);

export const selectPendingReport = createSelector(
    selectCardboardState,
    (state: State): boolean => state.pendingReport
);

export const selectError = createSelector(
    selectCardboardState,
    (state: State): string => state.error
);

export const selectAttachments = createSelector(
    selectCardboardState,
    (state: State): AttachmentModel[] =>
        state.item ? state.item.attachments : state.chapter.attachments
);

export const selectItemChart = createSelector(
    selectCardboardState,
    (state: State): ResultChartModel =>
        state.item ? state.item.result.chart : state.chapter.result.chart
);

export const selectGlobalChart = createSelector(
    selectCardboardState,
    (state: State): ResultGlobalChartModel => state.globalChart
);

export const selectCurrentState = createSelector(
    selectCardboardState,
    (state: State): CardboardStateModel => ({
        dimension: state.dimension,
        chapter: state.chapter,
        item: state.item,
        articles: state.articles,
    })
);

export const selectSelectedArticle = createSelector(
    selectCardboardState,
    (state: State): string => state.selectedArticle
);

export const selectVoteNumber = createSelector(
    selectCardboardState,
    (state: State): number =>
        state.item
            ? state.item.result.rates ? state.item.result.rates.length : 0
            : state.chapter.result.rates ? state.chapter.result.rates.length : 0
);

export const selectEcartType = createSelector(
    selectCardboardState,
    (state: State): boolean => state.ecartType
);
