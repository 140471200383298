export const locale = {
    lang: 'fr',
    data: {
        TASKS_TITLE: 'Actions',
        TASK_LABEL: 'Action',
        CHECKLIST_TITLE: 'Titre de Checklist',
        ADD: 'Ajouter une Checklist',
        TITLE: 'Titre',
        RESPONSIBLE: 'Responsable',
        DATE: 'Date',
        LIST_NAME: 'LIST NAME',
        DESCRIPTION: 'Description',
        REMOVE: 'Retirer la checklist',
        CHECKLIST:'Checklist',
        ATTACHEMENT:'Pièces jointes',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
        NEW_CHECKLIST: 'Nouvel élément checklist',
        ADD_TO_TASK_LABEL: 'Ajouter à l\'action'
    }
};
