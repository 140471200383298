import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CardboardModel } from '@bsuccess/models/cardboard/cardboard.model';
import { DimensionModel } from '@bsuccess/models/cardboard/dimension.model';
import { ChapterModel } from '@bsuccess/models/cardboard/chapiter.model';
import { ItemModel } from '@bsuccess/models/cardboard/item.model';
import { ItemArticleModel } from '@bsuccess/models/cardboard/item-article.model';
import { environment } from 'environments/environment';
import { ResultRateModel } from '@bsuccess/models/cardboard/result-rate.model';
import { SessionItemModel } from '@bsuccess/models/session/session-item.model';
import { ResultChartModel } from '@bsuccess/models/cardboard/result-chart.model';
import * as _ from 'lodash-es';
import { CardboardStateModel } from '@bsuccess/models/socket-io/cardboard-state.model';

@Injectable({
    providedIn: 'root',
})
export class CardBoardService {
    constructor(private _httpClient: HttpClient) { }

    static shuffle(array: ItemArticleModel[]): ItemArticleModel[] {
        const oldArray = [...array];
        let newArray = new Array<ItemArticleModel>();
        while (oldArray.length) {
            const i = Math.floor(Math.random() * oldArray.length);
            newArray = newArray.concat(oldArray.splice(i, 1));
        }
        return newArray;
    }

    static flipAll(array: ItemArticleModel[]): ItemArticleModel[] {
        return array.map(_ => {
            return { ..._, flipped: true };
        });
    }

    static flipBackAll(array: ItemArticleModel[]): ItemArticleModel[] {
        return array.map(_ => {
            return { ..._, flipped: false };
        });
    }

    static hideAll(array: ItemArticleModel[]): ItemArticleModel[] {
        return array.map(_ => {
            return { ..._, hidden: true };
        });
    }

    static showAll(array: ItemArticleModel[]): ItemArticleModel[] {
        return array.map(_ => {
            return { ..._, hidden: false };
        });
    }

    static avg(rates: ResultRateModel[]): number {
        if (rates.length > 0) {
            let sum = 0;
            rates.forEach(element => {
                sum += element.value;
            });
            return Math.round((sum / rates.length) * 100) / 100;
        } else {
            return 0;
        }
    }

    static ecartType(rates: ResultRateModel[]): number {
        let sum = 0;
        let s2 = 0;
        let m = 0;
        let v = 0;
        rates.forEach(element => {
            sum += element.value;
            s2 += element.value * element.value;
        });
        m = sum / rates.length;
        v = s2 / rates.length - m * m;
        return Math.sqrt(v);
    }

    static hasError(rates: ResultRateModel[]): boolean {
        let hasError: boolean;
        if (rates.length > 1) {
            if (this.ecartType(rates) > this.avg(rates) / 2) {
                hasError = true;
            } else {
                hasError = false;
            }
        } else {
            hasError = false;
        }
        return hasError;
    }

    // ! Next functions
    private static getNextItem(
        curent: ItemModel,
        items: ItemModel[]
    ): ItemModel {
        return items.find(
            (_, i) => i === items.findIndex(item => item._id === curent._id) + 1
        );
    }

    private static findIndexbyId(
        element: ItemModel | ChapterModel | DimensionModel,
        list: ItemModel[] | ChapterModel[] | DimensionModel[]
    ): number {
        return list.findIndex(e => e._id === element._id);
    }

    private static getNextChapter(
        curent: ChapterModel,
        chapters: ChapterModel[]
    ): ChapterModel {
        return chapters.find(
            (_, i) =>
                i ===
                chapters.findIndex(chapter => chapter._id === curent._id) + 1
        );
    }

    private static getNextDemension(
        curent: DimensionModel,
        dimensions: DimensionModel[]
    ): DimensionModel {
        return dimensions.find(
            (_, i) =>
                i ===
                dimensions.findIndex(chapter => chapter._id === curent._id) + 1
        );
    }

    static next(cardboardState: CardboardModel): CardboardModel {
        const chaptersMaxIndex: number = cardboardState.chapters.length - 1;

        if (cardboardState.items && cardboardState.item) {
            const itemsMaxIndex: number = cardboardState.items.length - 1;
            // * items !null
            if (
                this.findIndexbyId(
                    cardboardState.item,
                    cardboardState.items
                ) === itemsMaxIndex
            ) {
                // * items !null
                // * item is at max position
                return this.nextChapter(cardboardState);
            } else {
                // * items !null
                // ! item is not at max position
                return this.nextItem(cardboardState);
            }
        } else {
            // ! items null || item null
            if (
                this.findIndexbyId(
                    cardboardState.chapter,
                    cardboardState.chapters
                ) === chaptersMaxIndex
            ) {
                // ! items null || item null
                // * next chapter is at max position
                return this.nextDimension(cardboardState);
            } else {
                // ! items null
                // ! next chapter is not at max position
                return this.nextChapter(cardboardState);
            }
        }
    }

    // ! Next functions
    private static nextItem(cardboardState: CardboardModel): CardboardModel {
        return {
            ...cardboardState,
            item: this.getNextItem(cardboardState.item, cardboardState.items),
            articles: this.getNextItem(
                cardboardState.item,
                cardboardState.items
            ).articles,
        };
    }

    private static nextChapter(cardboardState: CardboardModel): CardboardModel {
        const chaptersMaxIndex: number = cardboardState.chapters.length - 1;
        if (
            this.findIndexbyId(
                cardboardState.chapter,
                cardboardState.chapters
            ) !== chaptersMaxIndex
        ) {
            // * chapter is not at max position
            if (
                this.getNextChapter(
                    cardboardState.chapter,
                    cardboardState.chapters
                ).hasItems
            ) {
                // * chapter is not at max position
                // * next chapter has items
                return {
                    ...cardboardState,
                    chapter: this.getNextChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ),
                    items: this.getNextChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ).items,
                    item: this.getNextChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ).items[0],
                    articles: this.getNextChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ).items[0].articles,
                };
            } else {
                // * chapter is not at max position
                // ! next chapter has no items
                return {
                    ...cardboardState,
                    chapter: this.getNextChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ),
                    items: null,
                    item: null,
                    articles: this.getNextChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ).articles,
                };
            }
        } else {
            // ! chapter is  at max position
            return this.nextDimension(cardboardState);
        }
    }

    private static nextDimension(
        cardboardState: CardboardModel
    ): CardboardModel {
        const diemensionsMaxIndex: number =
            cardboardState.dimensions.length - 1;
        if (
            this.findIndexbyId(
                cardboardState.dimension,
                cardboardState.dimensions
            ) !== diemensionsMaxIndex
        ) {
            return {
                ...cardboardState,
                dimension: this.getNextDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ),
                chapters: this.getNextDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters,
                chapter: this.getNextDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters[0],
                items: this.getNextDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters[0].hasItems
                    ? this.getNextDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters[0].items
                    : null,
                item: this.getNextDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters[0].hasItems
                    ? this.getNextDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters[0].items[0]
                    : null,
                articles: this.getNextDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters[0].hasItems
                    ? this.getNextDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters[0].items[0].articles
                    : this.getNextDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters[0].articles,
            };
        } else {
            return { ...cardboardState };
        }
    }
    // ! Next functions

    // ! Previous functions
    private static getPreviousItem(
        curent: ItemModel,
        items: ItemModel[]
    ): ItemModel {
        return items.find(
            (_, i) => i === items.findIndex(item => item._id === curent._id) - 1
        );
    }

    private static getPreviousChapter(
        curent: ChapterModel,
        chapters: ChapterModel[]
    ): ChapterModel {
        return chapters.find(
            (_, i) =>
                i ===
                chapters.findIndex(chapter => chapter._id === curent._id) - 1
        );
    }

    private static getPreviousDemension(
        curent: DimensionModel,
        dimensions: DimensionModel[]
    ): DimensionModel {
        return dimensions.find(
            (_, i) =>
                i ===
                dimensions.findIndex(chapter => chapter._id === curent._id) - 1
        );
    }

    static previous(cardboardState: CardboardModel): CardboardModel {
        if (cardboardState.items && cardboardState.item) {
            // * items !null item !null
            if (
                this.findIndexbyId(
                    cardboardState.item,
                    cardboardState.items
                ) === 0
            ) {
                // * items !null
                // * item is at min position
                return this.previousChapter(cardboardState);
            } else {
                // * items !null
                // ! item is not at min position
                return this.previousItem(cardboardState);
            }
        } else {
            // ! items null || item null
            if (
                this.findIndexbyId(
                    cardboardState.chapter,
                    cardboardState.chapters
                ) === 0
            ) {
                // ! items null || item null
                // * next chapter is at min position
                return this.previousDimension(cardboardState);
            } else {
                // ! items null
                // ! next chapter is not at min position
                return this.previousChapter(cardboardState);
            }
        }
    }

    private static previousItem(
        cardboardState: CardboardModel
    ): CardboardModel {
        return {
            ...cardboardState,
            item: this.getPreviousItem(
                cardboardState.item,
                cardboardState.items
            ),
            articles: this.getPreviousItem(
                cardboardState.item,
                cardboardState.items
            ).articles,
        };
    }

    private static previousChapter(
        cardboardState: CardboardModel
    ): CardboardModel {
        if (
            this.findIndexbyId(
                cardboardState.chapter,
                cardboardState.chapters
            ) !== 0
        ) {
            // * chapter is not at min position
            if (
                this.getPreviousChapter(
                    cardboardState.chapter,
                    cardboardState.chapters
                ).hasItems
            ) {
                // * chapter is not at min position
                // * next chapter has items
                return {
                    ...cardboardState,
                    chapter: this.getPreviousChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ),
                    items: this.getPreviousChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ).items,
                    item: this.getPreviousChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ).items[
                        this.getPreviousChapter(
                            cardboardState.chapter,
                            cardboardState.chapters
                        ).items.length - 1
                    ],
                    articles: this.getPreviousChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ).items[
                        this.getPreviousChapter(
                            cardboardState.chapter,
                            cardboardState.chapters
                        ).items.length - 1
                    ].articles,
                };
            } else {
                // * chapter is not at min position
                // ! next chapter has no items
                return {
                    ...cardboardState,
                    chapter: this.getPreviousChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ),
                    items: null,
                    item: null,
                    articles: this.getPreviousChapter(
                        cardboardState.chapter,
                        cardboardState.chapters
                    ).articles,
                };
            }
        } else {
            // ! chapter is  at min position
            return this.previousDimension(cardboardState);
        }
    }

    private static previousDimension(
        cardboardState: CardboardModel
    ): CardboardModel {
        if (
            this.findIndexbyId(
                cardboardState.dimension,
                cardboardState.dimensions
            ) !== 0
        ) {
            return {
                ...cardboardState,
                dimension: this.getPreviousDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ),
                chapters: this.getPreviousDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters,
                chapter: this.getPreviousDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters[
                    this.getPreviousDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters.length - 1
                ],
                items: this.getPreviousDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters[
                    this.getPreviousDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters.length - 1
                ].hasItems
                    ? this.getPreviousDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters[
                        this.getPreviousDemension(
                            cardboardState.dimension,
                            cardboardState.dimensions
                        ).chapters.length - 1
                    ].items
                    : null,
                item: this.getPreviousDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters[
                    this.getPreviousDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters.length - 1
                ].hasItems
                    ? this.getPreviousDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters[
                        this.getPreviousDemension(
                            cardboardState.dimension,
                            cardboardState.dimensions
                        ).chapters.length - 1
                    ].items[
                    this.getPreviousDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters[
                        this.getPreviousDemension(
                            cardboardState.dimension,
                            cardboardState.dimensions
                        ).chapters.length - 1
                    ].items.length - 1
                    ]
                    : null,
                articles: this.getPreviousDemension(
                    cardboardState.dimension,
                    cardboardState.dimensions
                ).chapters[
                    this.getPreviousDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters.length - 1
                ].hasItems
                    ? this.getPreviousDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters[
                        this.getPreviousDemension(
                            cardboardState.dimension,
                            cardboardState.dimensions
                        ).chapters.length - 1
                    ].items[
                        this.getPreviousDemension(
                            cardboardState.dimension,
                            cardboardState.dimensions
                        ).chapters[
                            this.getPreviousDemension(
                                cardboardState.dimension,
                                cardboardState.dimensions
                            ).chapters.length - 1
                        ].items.length - 1
                    ].articles
                    : this.getPreviousDemension(
                        cardboardState.dimension,
                        cardboardState.dimensions
                    ).chapters[
                        this.getPreviousDemension(
                            cardboardState.dimension,
                            cardboardState.dimensions
                        ).chapters.length - 1
                    ].articles,
            };
        } else {
            return { ...cardboardState };
        }
    }

    static updateChatSeries(
        rates: ResultRateModel[],
        resultChart: ResultChartModel
    ): ResultChartModel {
        resultChart.series.map(serie => {
            serie.data.map(data => {
                data.y = 0;
            });
        });
        _.chain(rates)
            .groupBy('value')
            .map((value, key) => ({ y: key, list: value }))
            .value()
            .map(result => {
                resultChart.series.map(serie => {
                    serie.data.map((_, index) => {
                        if (index === parseInt(result.y, 10) - 1) {
                            serie.data[parseInt(result.y, 10) - 1].y =
                                result.list.length;
                        }
                    });
                });
            });
        return resultChart;
    }

    averageRate(rates: ResultRateModel[]): number {
        if (rates.length > 0) {
            let sum = 0;
            rates.forEach(element => {
                sum += element.value;
            });
            return Math.round((sum / rates.length) * 100) / 100;
        } else {
            return 0;
        }
    }

    addRate(
        rate: ResultRateModel,
        rates: ResultRateModel[]
    ): ResultRateModel[] {
        const newArray = rates.filter(
            currentRate => currentRate.email !== rate.email
        );
        newArray.push(rate);
        return newArray;
    }

    getSessionResults(token: string, activityId: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectSessionResults}/${token}/${activityId}`
        );
    }

    getSessionResultsChart(token: string, activityId: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectSessionResultsChart}/${token}/${activityId}`
        );
    }

    saveSessionItem(sessionItem: SessionItemModel): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.result}`,
            sessionItem
        );
    }

    getItemWithoutRates(dimensions: DimensionModel[]): CardboardStateModel {
        let cardboardStateModel: CardboardStateModel;
        dimensions.forEach(dimension => {
            dimension.chapters.forEach(chapter => {
                if (chapter.hasItems) {
                    // * chapter has items
                    chapter.items.forEach(item => {
                        if (item.result.rates.length === 0) {
                            if (!cardboardStateModel) {
                                cardboardStateModel = {
                                    dimension: dimension,
                                    chapter: chapter,
                                    item: item,
                                    articles: chapter.articles,
                                };
                            }
                        }
                    });
                } else {
                    if (!chapter.hasItems) {
                        if (chapter.result.rates.length === 0) {
                            if (!cardboardStateModel) {
                                cardboardStateModel = {
                                    dimension: dimension,
                                    chapter: chapter,
                                    articles: chapter.articles,
                                };
                            }
                        }
                    } else {
                        cardboardStateModel = null;
                    }
                }
            });
        });
        // *  returns null if no item without result found
        return cardboardStateModel;
    }
}
