export const locale = {
    lang: 'fr',
    data: {
        PROJECTS_BOARD_NEW_GENERAL_BOARD_NAME: 'Nom du tableau',
        PROJECTS_BOARD_NEW_GENERAL_DESCRIPTION: 'Description',
        PROJECTS_BOARD_NEW_GENERAL_SAVE: 'Enregistrer',
        PROJECTS_BOARD_NEW_GENERAL_CANCEL: 'Annuler',
        PROJECTS_NEW_DIALOG_NEW_GENERAL_MEMBERS_CAN_SEE_OTHERS_CARDS: 'Tous les membres du tableau peuvent voir toutes les actions.',
        PROJECTS_NEW_DIALOG_NEW_GENERAL_MEMBERS_CANT_SEE_OTHERS_CARDS:'Seuls les membres Admin du tableau peuvent voir toutes les cartes.',

    }
};
