import { createAction, props } from '@ngrx/store';

import { BoardListItemModel } from '@bsuccess/models/canvas/board-list-item.model';
import { Board } from '@bsuccess/models/canvas/board.model';
import { Card } from '@bsuccess/models/canvas/card.model';
import { List } from '@bsuccess/models/canvas/list.model';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';
import { Activity } from '@bsuccess/models/activities/activity.model';
import { UserModel } from '@bsuccess/models/user.model';

export const loadBoards = createAction('[Workshop][Canvas Page] Load Boards');

export const loadBoardsSuccess = createAction(
    '[Workshop][Canvas API] Load Boards Success',
    props<{
        boards: BoardListItemModel[];
    }>()
);

export const loadBoardsFailure = createAction(
    '[Workshop][Canvas API] Load Boards Failure',
    props<{ error: string }>()
);

export const clearBoard = createAction(
    '[Workshop][Canvas Board Page] Clear Board',
);

export const loadBoard = createAction(
    '[Workshop][Canvas Board Page] Load Board',
    props<{ boardId: string }>()
);

export const loadBoardSuccess = createAction(
    '[Workshop][Canvas Board API] Load Board Success',
    props<{
        board: Board;
    }>()
);

export const loadBoardFailure = createAction(
    '[Workshop][Canvas Board API] Load Board Failure',
    props<{ error: string }>()
);

export const addBoard = createAction('[Workshop][Canvas Board Page] Add Board');

export const addBoardSuccess = createAction(
    '[Workshop][Canvas Board API] Add Board Success',
    props<{ board: Board }>()
);

export const addBoardFailure = createAction(
    '[Workshop][Board API] Add Board Failure',
    props<{ error: string }>()
);

export const saveBoard = createAction('[Workshop][Canvas Board Page] Save Board');

export const saveBoardSuccess = createAction('[Workshop][Canvas Board API] Save Board Success');

export const saveBoardFailure = createAction(
    '[Workshop][Canvas Board API] Save Board Failure',
    props<{ error: string }>()
);

export const renameBoard = createAction(
    '[Workshop][Canvas Board Page] Rename Board',
    props<{ newName: string }>()
);

export const addNewLabel = createAction(
    '[Workshop][Canvas Board Page] Add new label',
    props<{ newLabel: any }>()
);

export const addActionPlan = createAction('[Workshop][Canvas Board Page] Add Action Plan');

export const addActionPlanConfirmed = createAction(
    '[Workshop][Canvas Board Page] Add Action Plan Confirmed',
    props<{ actionPlan: SessionTaskModel }>()
);

export const addActionPlanCancelled = createAction(
    '[Workshop][Canvas Board Page] Add Action Plan Cancelled'
);

export const addActionPlanSuccess = createAction(
    '[Workshop][Canvas Board API] Add Action Plan Success',
    props<{ actionPlan: SessionTaskModel }>()
);

export const addActionPlanFailure = createAction(
    '[Workshop][Canvas Board API] Add Action Plan Failure',
    props<{ error: string }>()
);

export const sortCardsBySum = createAction('[Workshop][Canvas Board Page] Sort Cards By Sum');

export const showFilterDialog = createAction('[Workshop][Canvas Board Page] Show Filter Dialog');

export const filterAllCards = createAction(
    '[Workshop][Canvas Board Filter Dialog] Filter All Cards'
);

export const filterCardsbyIdLists = createAction(
    '[Workshop][Canvas Board Filter Dialog] Filter Cards By Id Lists',
    props<{ idLists: string[] }>()
);

export const filterCardsbyIdCategories = createAction(
    '[Workshop][Canvas Board Filter Dialog] Filter Cards By Id Categories',
    props<{ idCategories: string[] }>()
);

export const filterCardsbyIdTags = createAction(
    '[Workshop][Canvas Board Filter Dialog] Filter Cards By Id Tags',
    props<{ idTags: string[] }>()
);

export const filterCardsbyScore = createAction(
    '[Workshop][Canvas Board Filter Dialog] Filter Cards By Score',
    props<{ score: number; operator: string }>()
);

export const filterCardsbyId = createAction(
    '[Workshop][Canvas Board Filter Dialog] Filter Cards By Id',
    props<{ idCards: string[] }>()
);

export const showCardsToColumnsDialog = createAction(
    '[Workshop][Canvas Board Page] Show Cards To Columns Dialog'
);

export const showCardsToActionPlansDialog = createAction(
    '[Workshop][Canvas Board Page] Show Cards To Action plans Dialog'
);

export const showExportCardsDialog = createAction(
    '[Workshop][Canvas Board Page] Show Export Cards Dialog'
);

// find Actions

export const cardToColumnsbyIdCards = createAction(
    '[Workshop][Canvas Board Cards to Columns Dialog] Cards By Card Id',
    props<{ idCards: string[] }>()
);

export const cardToColumnsbyIdLists = createAction(
    '[Workshop][Canvas Board Cards to Columns Dialog] Cards By List Id',
    props<{ idLists: string[] }>()
);

export const cardToColumnsbyIdCategories = createAction(
    '[Workshop][Canvas Board Cards to Columns Dialog] Cards By Category Id',
    props<{ idCategories: string[] }>()
);

export const cardToColumnsbyScore = createAction(
    '[Workshop][Canvas Board Cards to Columns Dialog] Cards By Score',
    props<{ score: number; operator: string }>()
);

export const saveCardsToColumns = createAction(
    '[Workshop][Canvas Board Cards to Columns Dialog] Save Cards to Columns',
    props<{ boardId: string, activity: Activity }>()
);

export const saveCardsToColumnsSuccess = createAction(
    '[Workshop][Canvas Board Cards to Columns Dialog] Save Cards to Columns Success',
    props<{ boardId: string, activity: Activity, lists: any[] }>()
);

export const saveCardsToColumnsFailure = createAction(
    '[Workshop][Canvas API] Save Cards to Columns Failure',
    props<{ error: string }>()
);

// Import Actions
export const importAllCards = createAction(
    '[Workshop][Canvas Board Export Cards Dialog] Import All Cards'
);

export const importCardsbyIdLists = createAction(
    '[Workshop][Canvas Board Export Cards Dialog] Import Cards By Id Lists',
    props<{ idLists: string[] }>()
);

export const importCardsbyId = createAction(
    '[Workshop][Canvas Board Export Cards Dialog] Import Cards By Id',
    props<{ idCards: string[] }>()
);

export const canvasCardsToBoard = createAction(
    '[Workshop][Canvas API] Import Cards To Board',
    props<{ listId: string, cardsId: string[] }>()
);

export const canvasCardsToBoardSuccess = createAction(
    '[Workshop][Canvas API] Import Cards To Board Success',
    props<{ response: any, listId: string }>()
);

export const canvasCardsToBoardViaSocket = createAction(
    '[Workshop][Canvas API] Import Cards To Board Via Socket',
    props<{ response: any, listId: string }>()
);

export const canvasCardsToBoardFailure = createAction(
    '[Workshop][Canvas API] Import Cards To Board Failure',
    props<{ error: string }>()
);

// Export Actions

export const exportAllCards = createAction(
    '[Workshop][Canvas Board Export Cards Dialog] Export All Cards'
);

export const exportCardsbyIdLists = createAction(
    '[Workshop][Canvas Board Export Cards Dialog] Export Cards By Id Lists',
    props<{ idLists: string[] }>()
);

export const exportCardsbyIdCategories = createAction(
    '[Workshop][Canvas Board Export Cards Dialog] Export Cards By Id Categories',
    props<{ idCategories: string[] }>()
);

export const exportCardsbyScore = createAction(
    '[Workshop][Canvas Board Export Cards Dialog] Export Cards By Score',
    props<{ score: number; operator: string }>()
);

export const exportCardsbyId = createAction(
    '[Workshop][Canvas Board Export Cards Dialog] Export Cards By Id',
    props<{ idCards: string[] }>()
);

// cards to actionplan

export const allCardsToActionplans = createAction(
    '[Workshop][Canvas Cards To Actionplans] All Cards To Actionplans'
);

export const cardsToActionplanByIdLists = createAction(
    '[Workshop][Canvas Cards To Actionplans] Cards To Actionplans By Id Lists',
    props<{ idLists: string[] }>()
);

export const cardsToActionplanByIdLabels = createAction(
    '[Workshop][Canvas Cards To Actionplans] Cards To Actionplans By Id Labels',
    props<{ idLabels: string[] }>()
);

export const cardsToActionplanByScore = createAction(
    '[Workshop][Canvas Cards To Actionplans] Cards To Actionplans By Score',
    props<{ score: number; operator: string }>()
);

export const cardsToActionplanById = createAction(
    '[Workshop][Canvas Cards To Actionplans] Cards To Actionplans By Id',
    props<{ idCards: string[] }>()
);

export const cardsToActionplanSuccess = createAction(
    '[Workshop][Canvas Api] Cards To Actionplans Success',
    props<{ tasks: SessionTaskModel[] }>()
);

export const cardsToActionplanFailure = createAction(
    '[Workshop][Canvas Api] Cards To Actionplans Failure',
    props<{ error: string }>()
);
export const saveExportCards = createAction(
    '[Workshop][Canvas API] Save Export Cards',
    props<{ boardId: string; listId: string }>()
);

export const saveExportCardsSuccess = createAction(
    '[Workshop][Canvas API] Save Export Cards Success'
);

export const saveExportCardsFailure = createAction(
    '[Workshop][Canvas API] Save Export Cards Failure',
    props<{ error: string }>()
);

export const downloadPNG = createAction(
    '[Workshop][Canvas Page] Download Board PNG'
);

export const downloadSVG = createAction(
    '[Workshop][Canvas Page] Download Board SVG'
);

export const showCardResultDialog = createAction(
    '[Workshop][Canvas Board Page] Show Card Result Dialog',
    props<{ card: Card }>()
);

export const showStartCanvasDialog = createAction(
    '[Workshop][Canvas Board Page] Show Start Brainstorm Dialog'
);

export const showSessionSettingsDialog = createAction(
    '[Workshop][Canvas Board Page] Show Session Settings Dialog'
);

export const addCardCategory = createAction(
    '[Workshop][Canvas Board Page] Add Card Category',
    props<{ category: SessionCategoryModel }>()
);

export const updateCategories = createAction(
    '[Workshop][Canvas Board Page] Update Categories',
);

export const updateCategoriesSuccess = createAction(
    '[Workshop][Canvas Board Page] Update Categories Success'
);

export const updateCategoriesFailure = createAction(
    '[Workshop][Canvas Board Page] Update Categories Failure',
    props<{ error: any }>()
);

export const updateAllCategoriesChecked = createAction(
    '[Workshop][Canvas Board Page] Update All Categories Checkbox',
    props<{ checked: boolean }>()
);

export const deleteCategory = createAction(
    '[Workshop][Canvas Board Page] Delete Category',
    props<{ category: SessionCategoryModel }>()
);

export const addListLink = createAction(
    '[Workshop][Canvas Board Page] Add List Link',
    props<{ activity: Activity, listId: string }>()
);

export const removeListLink = createAction(
    '[Workshop][Canvas Board Page] Remove List Link',
    props<{ listId: string }>()
);

// ADD CARD
export const addCard = createAction(
    '[Workshop][Canvas Board Page] Add Card',
    props<{ listId: string; card: Card }>()
);

export const addCardSuccess = createAction(
    '[Workshop][Canvas Board API] Add Card Success',
    props<{ listId: string; card: Card }>()
);

export const addCardFailure = createAction(
    '[Workshop][Canvas Board API] Add Card Failure',
    props<{ error: string }>()
);

export const socketAddCard = createAction(
    '[Workshop][Canvas Board API] Add Card Via Socket',
    props<{ listId: string; card: Card }>()
);
// COPY CARD
export const copyCard = createAction(
    '[Workshop][Canvas Board Page] Copy Card',
    props<{ id: string }>()
);

export const copyCardSuccess = createAction(
    '[Workshop][Canvas Board API] Copy Card Success',
    props<{ id: string }>()
);

export const copyCardFailure = createAction(
    '[Workshop][Canvas Board API] Copy Card Failure',
    props<{ error: string }>()
);

// REMOVE CARD
export const removeCard = createAction(
    '[Workshop][Canvas Board Page] Remove Card',
    props<{ cardId: string; listId: string }>()
);

export const removeCardSuccess = createAction(
    '[Workshop][Canvas Board API] Remove Card Success',
    props<{ cardId: string; listId: string }>()
);

export const removeCardFailure = createAction(
    '[Workshop][Canvas Board API] Remove Card Failure',
    props<{ error: string }>()
);

export const socketRemoveCard = createAction(
    '[Workshop][Canvas Board API] Remove Card Via Socket',
    props<{ cardId: string; listId: string }>()
);

// MOVE CARD
export const moveCard = createAction(
    '[Workshop][Canvas Board Page] Move Card',
    props<{ move: { listId: string; cardId: string; dropIndex: number }; lists: List[] }>()
);

export const moveCardSuccess = createAction(
    '[Workshop][Canvas Board API] Move Card Success',
    props<{ move: { listId: string; cardId: string; dropIndex: number }; lists: List[] }>()
);

export const moveCardFailure = createAction(
    '[Workshop][Canvas Board API] Move Card Failure',
    props<{ error: string }>()
);

export const socketMoveCard = createAction(
    '[Workshop][Canvas Board API] Move Card Via Socket',
    props<{ move: { listId: string; cardId: string; dropIndex: number }; lists: List[] }>()
);

// ADD LIST
export const addList = createAction(
    '[Workshop][Canvas Board Page] Add List',
    props<{ list: List }>()
);

export const addListSuccess = createAction('[Workshop][Canvas Board API] Add List Success',
    props<{ list: List }>()
);

export const addListFailure = createAction(
    '[Workshop][Canvas Board API] Add List Failure',
    props<{ error: string }>()
);

// REMOVE LIST
export const removeList = createAction(
    '[Workshop][Canvas Board Page] Remove List',
    props<{ listId: string }>()
);

export const removeListSuccess = createAction('[Workshop][Canvas Board API] Remove List Success');

export const removeListFailure = createAction(
    '[Workshop][Canvas Board API] Remove List Failure',
    props<{ error: string }>()
);

// MOVE LIST
export const moveList = createAction(
    '[Workshop][Canvas Board Page] Move List',
    props<{ lists: List[]; move: { listId: string; dropIndex: number }; }>()
);

export const moveListSuccess = createAction('[Workshop][Canvas Board API] Move List Success');

export const moveListFailure = createAction(
    '[Workshop][Canvas Board API] Move List Failure',
    props<{ error: string }>()
);

// RENAME LIST
export const listNameChange = createAction(
    '[Workshop][Canvas Board Page] List Name change',
    props<{ listId: string; name: string }>()
);

export const listNameChangeSuccess = createAction(
    '[Workshop][Canvas Board Page] List Name change Success',
    props<{ listId: string; name: string }>()
);

export const listNameChangeFailure = createAction(
    '[Workshop][Canvas Board API] List Name change Failure',
    props<{ error: string }>()
);

export const listSubtitleChange = createAction(
    '[Workshop][Canvas Board Page] List Subtitle change',
    props<{ listId: string; description: string }>()
);

export const listSubtitleChangeSuccess = createAction(
    '[Workshop][Canvas Board Page] List Subtitle change Success',
    props<{ listId: string; description: string }>()
);

export const listSubtitleChangeFailure = createAction(
    '[Workshop][Canvas Board API] List Subtitle change Failure',
    props<{ error: string }>()
);

export const listSubtitleRemove = createAction(
    '[Workshop][Canvas Board Page] List Subtitle Remove',
    props<{ listId: string;}>()
);

export const listSubtitleRemoveSuccess = createAction(
    '[Workshop][Canvas Board Page] List Subtitle Remove Success',
    props<{ listId: string; }>()
);

export const listSubtitleRemoveFailure = createAction(
    '[Workshop][Canvas Board API] List Subtitle Remove Failure',
    props<{ error: string }>()
);

export const socketListNameChange = createAction(
    '[Workshop][Canvas Board Page] List Name change Via Socket',
    props<{ listId: string; name: string }>()
);

// CARD COLOR CHANGE
export const cardColorChange = createAction(
    '[Workshop][Canvas Board API] Card Color change',
    props<{ cardId: string; color: string, typeCard: string }>()
);

export const cardsColorChange = createAction(
    '[Workshop][Canvas Board API] Card Color change',
    props<{ cardId: string; color: string, typeCard: string }>()
);

export const cardColorChangeSuccess = createAction(
    '[Workshop][Canvas Board API] Update Card Note Success',
    props<{ cardId: string; color: string, typeCard: string }>()
);

export const cardColorChangeFailure = createAction(
    '[Workshop][Canvas Board API] Update Card Note Failure',
    props<{ error: string }>()
);

export const socketCardColorChange = createAction(
    '[Workshop][Canvas Board API] Update Card Note Via Socket',
    props<{ cardId: string; color: string, typeCard: string }>()
);

// CARD NAME CHANGE
export const cardNameChange = createAction(
    '[Workshop][Canvas Board Page] Card name change',
    props<{ cardId: string; name: string }>()
);

export const cardNameChangeSuccess = createAction(
    '[Workshop][Canvas Board API] Card name change Success',
    props<{ cardId: string, name: string }>()
);

export const cardNameChangeFailure = createAction(
    '[Workshop][Canvas Board API] Card name change Failure',
    props<{ error: string }>()
);

export const socketCardNameChangeSuccess = createAction(
    '[Workshop][Canvas Board API] Card name change Via Socket',
    props<{ cardId: string, name: string }>()
);

// CARD DATE CHANGE
export const updateCardDate = createAction(
    '[Workshop][Canvas Board Page] Card date change',
    props<{ cardId: string, due: string }>()
);

export const updateCardDateSuccess = createAction(
    '[Workshop][Canvas Board API] Card date change Success',
    props<{ cardId: string, due: string }>()
);

export const updateCardDateFailure = createAction(
    '[Workshop][Canvas Board API] Card date change Failure',
    props<{ error: string }>()
);

export const socketUpdateCardDate = createAction(
    '[Workshop][Canvas Board API] Card date change Via Socket',
    props<{ cardId: string, due: string }>()
);


// CARD DESCRIPTION
export const updateCardDescription = createAction(
    '[Workshop][Canvas Board Page] Update Card Description',
    props<{ cardId: string, description: string }>()
);

export const updateCardDescriptionSuccess = createAction(
    '[Workshop][Canvas Board API] Update Card Description Success',
    props<{ cardId: string, description: string }>()
);

export const updateCardDescriptionFailure = createAction(
    '[Workshop][Canvas Board API] Update Card Description Failure',
    props<{ error: string }>()
);

export const socketUpdateCardDescription = createAction(
    '[Workshop][Canvas Board API] Update Card Description Via Socket',
    props<{ cardId: string, description: string }>()
);

// CARD Note
export const updateCardNote = createAction(
    '[Workshop][Canvas Board Page] Update Card Note',
    props<{ cardId: string, note: number }>()
);

export const updateCardNoteSuccess = createAction(
    '[Workshop][Canvas Board API] Update Card Note Success'
);

export const updateCardNoteFailure = createAction(
    '[Workshop][Canvas Board API] Update Card Note Failure',
    props<{ error: string }>()
);

export const socketUpdateCardNote = createAction(
    '[Workshop][Canvas Board API] Update Card Note Via Socket'
);

// OTHER ACTIONS
export const addChecklist = createAction(
    '[Workshop][Canvas Board Card Dialog] Add Checklist',
    props<{
        boardId: string,
        cardId: string,
        checklist: {
            id: string,
            name: string,
            checkItemsChecked: number,
            checkItems: {
                id: string,
                name: string,
                checked: boolean
            }[]
        }
    }>()
);

export const addChecklistSuccess = createAction(
    '[Workshop][Canvas Board API] Add Checklist Success',
    props<{
        cardId: string,
        checklist: {
            id: string,
            name: string,
            checkItemsChecked: number,
            checkItems: {
                id: string,
                name: string,
                checked: boolean
            }[]
        }
    }>()
);

export const addChecklistFailure = createAction(
    '[Workshop][Canvas Board API] Add Checklist Failure',
    props<{ error: string }>()
);

export const socketAddChecklist = createAction(
    '[Workshop][Canvas Board API] Add Checklist Via Socket',
    props<{
        cardId: string,
        checklist: {
            id: string,
            name: string,
            checkItemsChecked: number,
            checkItems: {
                id: string,
                name: string,
                checked: boolean
            }[]
        }
    }>()
);

export const addcheckItem = createAction(
    '[Workshop][Canvas Board Card Dialog] Add Check Item Form',
    props<{
        boardId: string,
        checkItemForm: {
            cardId: string,
            checklistId: string,
            id: string,
            name: string,
            checked: boolean
        }
    }>()
);

export const addcheckItemSuccess = createAction(
    '[Workshop][Canvas Board API] Add Check Item Form Success',
    props<{
        checkItemForm: {
            cardId: string,
            checklistId: string,
            id: string,
            name: string,
            checked: boolean
        }
    }>()
);

export const addcheckItemFailure = createAction(
    '[Workshop][Canvas Board API] Add Check Item Form Failure',
    props<{ error: string }>()
);

export const socketAddcheckItem = createAction(
    '[Workshop][Canvas Board API] Add Check Item Form Via Socket',
    props<{
        checkItemForm: {
            cardId: string,
            checklistId: string,
            id: string,
            name: string,
            checked: boolean
        }
    }>()
);


export const removeCheckList = createAction(
    '[Workshop][Canvas Board Card Dialog] Remove Check List',
    props<{
        boardId: string,
        checkListForm: {
            cardId: string,
            checklistId: string
        }
    }>()
);

export const removeCheckListSuccess = createAction(
    '[Workshop][Canvas Board API] Remove Check List Success',
    props<{
        checkListForm: {
            cardId: string,
            checklistId: string
        }
    }>()
);

export const removeCheckListFailure = createAction(
    '[Workshop][Canvas Board API] Remove Check List Failure',
    props<{ error: string }>()
);

export const socketRemoveCheckList = createAction(
    '[Workshop][Canvas Board API] Remove Check List Via Socket',
    props<{
        checkListForm: {
            cardId: string,
            checklistId: string
        }
    }>()
);

export const removeCheckItem = createAction(
    '[Workshop][Canvas Board Card Dialog] Remove Check Item',
    props<{
        boardId: string,
        checkListForm: {
            cardId: string,
            checklistId: string,
            checkItemId: string
        }
    }>()
);

export const removeCheckItemSuccess = createAction(
    '[Workshop][Canvas Board API] Remove Check Item Success',
    props<{
        checkListForm: {
            cardId: string,
            checklistId: string,
            checkItemId: string
        }
    }>()
);

export const removeCheckItemFailure = createAction(
    '[Workshop][Canvas Board API] Remove Check Item Failure',
    props<{ error: string }>()
);

export const socketRemoveCheckItem = createAction(
    '[Workshop][Canvas Board API] Remove Check Item Via Socket',
    props<{
        checkListForm: {
            cardId: string,
            checklistId: string,
            checkItemId: string
        }
    }>()
);

export const updateCheckItem = createAction(
    '[Workshop][Canvas Board Card Dialog] Update Check Item',
    props<{
        boardId: string,
        checkItemForm: {
            cardId: string,
            checklistId: string,
            checkItemId: string,
            name: string,
            checked: boolean
        }
    }>()
);

export const updateCheckItemSuccess = createAction(
    '[Workshop][Canvas Board API] Update Check Item Success',
    props<{
        checkItemForm: {
            cardId: string,
            checklistId: string,
            checkItemId: string,
            name: string,
            checked: boolean
        }
    }>()
);

export const updateCheckItemFailure = createAction(
    '[Workshop][Canvas Board API] Update Check Item Failure',
    props<{ error: string }>()
);

export const socketUpdateCheckItem = createAction(
    '[Workshop][Canvas Board API] Update Check Item Via Socket',
    props<{
        checkItemForm: {
            cardId: string,
            checklistId: string,
            checkItemId: string,
            name: string,
            checked: boolean
        }
    }>()
);

export const updateLbels = createAction(
    '[Workshop][Canvas Board Card Dialog] Update Labels',
);

export const updateLbelsSuccess = createAction(
    '[Workshop][Canvas Board API] Update Labels Success',
    props<{
        labels: { id: string, name: string, color: string }[]
    }>()
);

export const updateLbelsFailure = createAction(
    '[Workshop][Canvas Board API] Update Labels Failure',
    props<{ error: string }>()
);

export const toggleLabel = createAction(
    '[Workshop][Canvas Board Card Dialog] Toggle Label',
    props<{
        boardId: string,
        toggleForm: {
            cardId: string,
            labelId: string
        }
    }>()
);

export const toggleLabelSuccess = createAction(
    '[Workshop][Canvas Board API] Toggle Label Success',
    props<{
        toggleForm: {
            cardId: string,
            labelId: string
        }
    }>()
);

export const toggleLabelFailure = createAction(
    '[Workshop][Canvas Board API] Toggle Label Failure',
    props<{ error: string }>()
);

export const socketToggleLabel = createAction(
    '[Workshop][Canvas Board API] Toggle Label Via Socket',
    props<{
        toggleForm: {
            cardId: string,
            labelId: string
        }
    }>()
);

export const duplicateCard = createAction(
    '[Workshop][Canvas Board Card Dialog] Duplicate Card',
    props<{
        duplicate: {
            cardId: string,
            listId: string
        }
    }>()
);

export const duplicateCardSuccess = createAction(
    '[Workshop][Canvas Board API] Duplicate Card Success',
    props<{
        duplicate: {
            cardId: string,
            listId: string
        },
        card: Card
    }>()
);

export const duplicateCardFailure = createAction(
    '[Workshop][Canvas Board API] Duplicate Card Failure',
    props<{ error: string }>()
);

export const socketDuplicateCard = createAction(
    '[Workshop][Canvas Board API] Duplicate Card Via Socket',
    props<{
        duplicate: {
            cardId: string,
            listId: string
        },
        card: Card
    }>()
);

export const addCardComment = createAction(
    '[Workshop][Canvas Board Card Dialog] Add Card Comment',
    props<{
        cardId: string,
        comment: {
            idMember: string
            message: any,
            time: Date
        }
    }>()
);

export const addCardCommentSuccess = createAction(
    '[Workshop][Canvas Board API] Add Card Comment Success',
    props<{
        cardId: string, comment: {
            id: string,
            idMember: string
            message: any,
            time: Date
        }
    }>()
);

export const addCardCommentFailure = createAction(
    '[Workshop][Canvas Board API] Add Card Comment Failure',
    props<{ error: string }>()
);

export const socketAddCardComment = createAction(
    '[Workshop][Canvas Board API] Add Card Comment Via Socket',
    props<{
        cardId: string, comment: {
            id: string,
            idMember: string
            message: any,
            time: Date
        }
    }>()
);

export const deleteComment = createAction(
    '[Workshop][Canvas Board Card Dialog] Delete Card Comment',
    props<{
        cardId: string;
        commentId: string;
    }>()
);

export const deleteCommentSuccess = createAction(
    '[Workshop][Canvas Board API] Delete Card Comment Success',
    props<{
        id: string;
        cardId: string;
        commentId: string;
    }>()
);

export const deleteCommentFailure = createAction(
    '[Workshop][Canvas Board API] Delete Card Comment Failure',
    props<{ error: string }>()
);

export const socketDeleteComment = createAction(
    '[Workshop][Canvas Board API] Delete Card Comment Via Socket',
    props<{
        id: string;
        cardId: string;
        commentId: string;
    }>()
);

export const saveCardsRates = createAction('[Workshop][Canvas Board Page] Save Cards Rates',
    props<{ cards: Card[], user: UserModel }>());

export const saveCardsRatesSuccess = createAction('[Workshop][Canvas Board Page] Save Cards Rates Success',
    props<{ lists: any, cards: any }>());

export const saveCardsRatesFailure = createAction(
    '[Workshop][Canvas Board Page] Save Cards Rates Failure',
    props<{ error: string }>()
);

export const showSendVotesDialog = createAction(
    '[Workshop][Canvas Page] Show Send Votes Dialog'
);

export const refreshBoard = createAction(
    '[Workshop][Canvas Page] Refresh Board'
);

export const toggleShowAllCards = createAction(
    '[Workshop][Canvas Page] Toggle Show All cards',
    props<{ enabled: boolean }>()
);

export const toggleShowAllCardsSuccess = createAction(
    '[Workshop][Canvas Page] Toggle Show All cards Success',
    props<{ enabled: boolean }>()
);

export const toggleShowAllCardsFailure = createAction(
    '[Workshop][Canvas Page] Toggle Show All cards Failure',
    props<{ error: any }>()
);

export const toggleShowAllCardsViaSocket = createAction(
    '[Workshop][Canvas Page] Toggle Show All cards Via Socket',
    props<{ enabled: boolean }>()
);

export const toggleShowMembersName = createAction(
    '[Workshop][Canvas Page] Toggle Show Members Names',
    props<{ enabled: boolean }>()
);

export const toggleShowMembersNameSuccess = createAction(
    '[Workshop][Canvas Page] Toggle Show Members Names Success',
    props<{ enabled: boolean }>()
);

export const toggleShowMembersNameFailure = createAction(
    '[Workshop][Canvas Page] Toggle Show Members Names Failure',
    props<{ error: any }>()
);

export const toggleShowMembersNameViaSocket = createAction(
    '[Workshop][Canvas Page] Toggle Show Members Names Via Socket',
    props<{ enabled: boolean }>()
);


export const addLabel = createAction(
    '[Workshop][Canvas Page] Add Label',
    props<{
        name: string,
        color: string
    }>()
);

export const addLabelSuccess = createAction(
    '[Workshop][Canvas API] Add Label Success',
    props<{
        name: string,
        color: string,
        id: string,
    }>()
);

export const addLabelFailure = createAction(
    '[Workshop][Canvas API] Add Label Failure',
    props<{ error: string }>()
);

export const socketAddLabel = createAction(
    '[Workshop][Canvas API] Add Label Via Socket',
    props<{
        name: string,
        color: string,
        id: string,
    }>()
);

export const updateLabel = createAction(
    '[Workshop][Canvas Page] Update Label',
    props<{
        id: string,
        name: string,
        color: string
    }>()
);

export const updateLabelSuccess = createAction(
    '[Workshop][Canvas API] Update Label Success',
    props<{
        name: string,
        color: string,
        id: string,
    }>()
);

export const updateLabelFailure = createAction(
    '[Workshop][Canvas API] Update Label Failure',
    props<{ error: string }>()
);

export const socketUpdateLabel = createAction(
    '[Workshop][Canvas API] Update Label Via Socket',
    props<{
        name: string,
        color: string,
        id: string,
    }>()
);

export const removeLabel = createAction(
    '[Workshop][Canvas API] Remove Label',
    props<{
        labelId: string, label: {}
    }>()
);

export const removeLabelSuccess = createAction(
    '[Workshop][Canvas API] Remove Label Success',
    props<{
        labelId: string
    }>()
);

export const removeLabelFailure = createAction(
    '[Workshop][Canvas API] Remove Label Failure',
    props<{ error: string }>()
);

export const socketRemoveLabel = createAction(
    '[Workshop][Canvas API] Remove Label Via Socket',
    props<{
        labelId: string
    }>()
);

export const cardRating = createAction(
    '[Workshop][Canvas API] Card Rating',
    props<{
        cardId: string;
        user: UserModel;
        rate: number;
    }>()
);

export const cardRatingSuccess = createAction(
    '[Workshop][Canvas API] Card Rating Success',
    props<{
        cardId: string;
        user: UserModel;
        rate: number;
    }>()
);

export const socketcardRating = createAction(
    '[Workshop][Canvas API] Card Rating Via Socket',
    props<{
        cardId: string;
        user: UserModel;
        rate: number;
    }>()
);

export const cardRatingFailure = createAction(
    '[Workshop][Canvas API] Card Rating Failure',
    props<{ error: string }>()
);

export const saveTemplate = createAction(
    '[Workshop][Canvas API] Save Template',
    props<{
        body: {
            name: string,
            description: string,
            longDescription: string,
            vignette: string,
            category: string,
            inProjects: any[],
            layout: any,
        }
    }>()
);

export const saveTemplateSuccess = createAction(
    '[Workshop][Canvas API] Save Template Success',
    props<{
        id: string,
        body: {
            name: string,
            description: string,
            longDescription: string,
            vignette: string,
            category: string,
            inProjects: any[],
            layout: any,
        }
    }>()
);

export const saveTemplateFailure = createAction(
    '[Workshop][Canvas API] Save Template Failure',
    props<{ error: string }>()
);

export const updateTemplate = createAction(
    '[Workshop][Canvas API] Update Template',
    props<{
        templates: any;
        id: any,
        body: {
            name: string,
            description: string,
            longDescription: string,
            vignette: string,
            category: string,
            inProjects: any[],
            layout: any,
        }
    }>()
);

export const updateTemplateSuccess = createAction(
    '[Workshop][Canvas API] Update Template Success',
    props<{
        templates: any;
        id: any,
        body: {
            name: string,
            description: string,
            longDescription: string,
            vignette: string,
            category: string,
            inProjects: any[],
            layout: any,
        }
    }>()
);

export const updateTemplateFailure = createAction(
    '[Workshop][Canvas API] Update Template Failure',
    props<{ error: string }>()
);

export const duplicateTemplate = createAction(
    '[Workshop][Canvas API] Duplicate Template',
    props<{
        body: {
            studioTemplateIdToDuplicate: any,
        }
    }>()
);

export const duplicateTemplateSuccess = createAction(
    '[Workshop][Canvas API] Duplicate Template Success',
    props<{
        id: any,
        body: {
            name: string,
            description: string,
            longDescription: string,
            vignette: string,
            category: string,
            inProjects: any[],
            layout: any,
        }
    }>()
);

export const duplicateTemplateFailure = createAction(
    '[Workshop][Canvas API] Duplicate Template Failure',
    props<{ error: string }>()
);

export const deleteTemplate = createAction(
    '[Workshop][Canvas API] Delete Template',
    props<{
        id: any, templates: any, redirectToActivities?: boolean;
    }>()
);

export const deleteTemplateSuccess = createAction(
    '[Workshop][Canvas API] Delete Template Success',
    props<{
        id: any, templates: any, redirectToActivities?: boolean;
    }>()
);

export const deleteTemplateFailure = createAction(
    '[Workshop][Canvas API] Delete Template Failure',
    props<{ error: string }>()
);

export const archiveTemplateConfirmed = createAction(
    '[Workshop][Canvas API] Delete Template Confirmed',
    props<{
        id: any, templates: any, redirectToActivities?: boolean;
    }>()
);

export const archiveTemplateCancelled = createAction(
    '[Workshop][Canvas API] Delete Template Cancelled'
);

export const uploadImage = createAction(
    '[Workshop][Canvas API] Upload Project Image',
    props<{ content: any; name: string; template: any; }>()
);

export const uploadImageSuccess = createAction(
    '[Workshop][Canvas API] Upload Project Image Success',
    props<{ response: any; name: string; template: any; }>()
);

export const uploadImageFailure = createAction(
    '[Workshop][Canvas API] Upload Project Image Failure',
    props<{ error: string }>()
);

export const updateTemplateSettingsInfo = createAction(
    '[Workshop][Canvas API] Update Template Settings Info',
    props<{ 
        templateId: string,
        body: {
            name: string,
            description: string,
            longDescription: string,
            vignette: string,
            category: string,
            inProjects: any[],
            layout: any,
        }
    }>()
);

export const updateTemplateSettingsInfoSuccess = createAction(
    '[Workshop][Canvas API] Update Template Settings Info Success',
    props<{ 
        templateId: string,
        body: {
            name: string,
            description: string,
            longDescription: string,
            vignette: string,
            category: string,
            inProjects: any[],
            layout: any,
        }
    }>()
);

export const updateTemplateSettingsInfoFailure = createAction(
    '[Workshop][Canvas API] Update Template Settings Info Failure',
    props<{ error: string }>()
);

export const showStudioCanvasSettingsDialog = createAction('[Workshop][Canvas] Show Settings Studio Canvas Dialog');

export const updateTimer = createAction(
    '[Workshop][Canvas API] Update Timer',
    props<{
        timer: {
            timer: boolean,
            categories: SessionCategoryModel[],
            balance: number,
            maxPerCard: number,
            timerType: string
        }
    }>()
);

export const updateTimerSuccess = createAction(
    '[Workshop][Canvas API] Update Timer Success',
    props<{
        timer: {
            timer: boolean,
            categories: SessionCategoryModel[],
            balance: number,
            maxPerCard: number,
            timerType: string
        }
    }>()
);

export const socketUpdateTimer = createAction(
    '[Workshop][Canvas API] Update Timer Via Socket',
    props<{
        timer: {
            timer: boolean,
            categories: SessionCategoryModel[],
            balance: number,
            maxPerCard: number,
            timerType: string
        }
    }>()
);

export const updateTimerFailure = createAction(
    '[Workshop][Canvas API] Update Timer Failure',
    props<{ error: string }>()
);

export const substractUserPoints = createAction(
    '[Workshop][Canvas] Substract User Points',
    props<{ sumRates: number }>()
);

export const sort = createAction(
    '[Workshop][Canvas API] Sort Board',
);

export const sortSuccess = createAction(
    '[Workshop][Canvas API] Sort Board Success',
    props<{
        lists: any[];
    }>()
);

export const socketSort = createAction(
    '[Workshop][Canvas API] Sort Board Via Socket',
    props<{
        lists: any[];
    }>()
);

export const sortFailure = createAction(
    '[Workshop][Canvas API] Sort Board Failure',
    props<{ error: string }>()
);

export const showImportCardsDialog = createAction(
    '[Workshop][Canvas API] Show Import Cards Dialog'
);

export const updateWorkFlowStep = createAction(
    '[Workshop][Canvas API] Update WorkFlow Step',
    props<{ step: number }>()
);

export const updateWorkFlowStepSuccess = createAction(
    '[Workshop][Canvas API] Update WorkFlow Step Success',
    props<{
        step: number
    }>()
);

export const socketUpdateWorkFlowStep = createAction(
    '[Workshop][Canvas API] Update WorkFlow Step Via Socket',
    props<{
        step: number
    }>()
);

export const updateWorkFlowStepFailure = createAction(
    '[Workshop][Canvas API] Update WorkFlow Step Failure',
    props<{ error: string }>()
);

export const showCanvasSettingsDialog = createAction('[Workshop][Canvas] Show Settings Canvas Dialog');

export const updateDisplayLabel = createAction(
    '[Workshop][Canvas API] Update Display Label via Settings',
    props<{
        label: boolean
    }>()
);

export const updateDisplayLabelSuccess = createAction(
    '[Workshop][Canvas API] Update Display Label via Settings Success',
    props<{
        label: boolean
    }>()
);

export const updateDisplayLabelFailure = createAction(
    '[Workshop][Canvas API] Update Display Label via Settings Failure',
    props<{ error: any }>()
);

export const updateDisplayLabelViaSocket = createAction(
    '[Workshop][Canvas API] Update Display Label Via Settings Via Socket',
    props<{
        label: boolean
    }>()
);
