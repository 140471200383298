export const locale = {
    lang: 'fr',
    data: {
        MANAGE_MEMBERS: 'Inviter des membres',
        INVITAION_ONE: 'Invitez des membres à collaborer au tableau.',
        INVITAION_TWO: 'S\'ils ne disposent pas de compte Excelway, ils recevront une invitation à créer un compte.',
        MEMBERS: 'Membre',
        THE_EMAIL: 'Email',
        POSITION: 'Position',
        SPONSOR: 'Sponsor',
        ADMIN: 'Admin',
        ADD_USER_BUTTON_LABEL: 'Ajouter un utilisateur',
        RESPONSIBLE: 'Responsable',
        ROLE: 'Rôle',
        READING: 'Lecture',
        MODIFICATION: 'Modification',
        REMOVE: 'Retirer',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
        ADMIN_DESCIPTION        :'Peut entièrement configurer le tableau.',
        MODIFICATION_DESCIPTION:'Peut gérer les actions et les membres.',
        READING_DESCIPTION:'Ne peut pas éditer les actions.',
    }
};
