export const locale = {
    lang: 'ar',
    data: {
        START: 'ابدأ العصف الذهني',
        SELECT: 'اختر لون واحد أو أكثر من البطاقات لهذا العصف الذهني',
        COLOR: 'الألوان مصنوعة من لون البطاقة والملصق الذي تحدده',
        ALL_CATEGORIES: 'جميع الألوان',
        CARD_CATEGORIES: 'الألوان',
        CANCEL: 'إلغاء',
    }
};
