export const locale = {
    lang: 'ar',
    data: {
        COMPONENTS_PROJECTS_PANEL_MY_PROJECTS:'مشاريعي',
        COMPONENTS_PROJECTS_PANEL_ADD_PROJECT:'مشروع جديد',
        COMPONENTS_PROJECTS_PANEL_SEE_ALL:'كل مشاريعي',
        COMPONENTS_PROJECTS_PANEL_ADD_SUB_PROJECTS:'اضافة مشروع فرعي',
        COMPONENTS_PROJECTS_PANEL_NOT_AUTHORIZED:'ليس لديك إذن للوصول إلى هذا المشروع',
    }
};
