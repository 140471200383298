export const locale = {
 lang: 'ar',
 data: {
     PARTICIPANT_INFORMATIONS: 'معلومات المشارك',
     PARTICIPANT: 'مشارك',
     ANIMATOR: 'مُيَسّرْ',
     REMOVE_BUTTON_LABEL: 'إزالة المشارك من هذه الورشة',
     CLOSE: 'أغلق',
    }
};
