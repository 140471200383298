import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, Subscriber } from 'rxjs';
import * as moment from 'moment';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { BoardCardModel } from '@bsuccess/models/board/board-card.model';
import { BoardListModel } from '@bsuccess/models/board/board-list.model';
import { AttachmentService } from './attachment.service';
import { saveAs as importedSaveAs } from 'file-saver-es';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';

@Injectable({
    providedIn: 'root',
})
export class BoardService {
    constructor(private _httpClient: HttpClient, private _attachmentService: AttachmentService) { }

    getBoard(boardId: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}`
        );
    }

    getProjectsList(): Observable<any> {
        return this._httpClient.get(
          `${environment.http.url}${environment.http.projectList}`
        );
    }

    updateBoardDetails(
        boardId: string,
        boardDetails: { name: string; description: string; enableBoardPrivacy: boolean }
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardDetails}/${boardId}`,
            boardDetails
        );
    }

    addComment(
        comment: {
            idMember: string;
            message: any;
            time: Date;
            cardId: string;
        },
        boardId
    ): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectBoardAddCardComment}/${boardId}`,
            comment
        );
    }

    getStandbyCards(): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.standbyCards}`
        );
    }

    updateIsRead(boardId: string, standbyCardId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.standbyCardsIsRead}/${boardId}/${standbyCardId}`,
            {}
        );
    }

    updateStatus(standbyCardId: string, status): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.standbyCardsStatus}/${standbyCardId}`,
            status
        );
    }

    rename(boardId: string, name: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardRename}/${boardId}`,
            {
                name
            }
        );
    }

    removeCard(boardId: string, listId: string, cardId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardRemoveCard}/${boardId}`,
            {
                listId,
                cardId
            }
        );
    }

    removeCardFromBoard(boardId: string, cardId: string, boardIdToRemove: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardRemoveCardFromBoard}/${boardId}`,
            {
                cardId,
                boardIdToRemove
            }
        );
    }

    static filterBoardCardsIsOn(resetAll: boolean): boolean {
        if (resetAll) {
            return false;
        } else {
            return true;
        }
    }

    static filterBoardState(idMembers: ProjectUserModel[], selectedDate: string, idResponsable: string[], idCategories: any[], selectedPriority: string, currentFilters: any[]): any[] {
        return [idMembers, selectedDate, idResponsable[0], idCategories, selectedPriority, currentFilters];
    }

    static filterBoardCards(cards: BoardCardModel[], idMembers: ProjectUserModel[], idCategories: any[], selectedDate: string, selectedPriority: string, idResponsable: string[], resetAll: boolean): BoardCardModel[] {

        // Reset or Nothing selected
        if (resetAll || selectedDate === undefined && selectedPriority === undefined && !idMembers[0] && !idCategories[0] && !idResponsable[0]) {
            return cards.map(card => {
                return { ...card, isVisible: true, filterIsOn: false };
            });
        }

        // All Selected
        if (selectedDate !== undefined && idCategories[0] && !resetAll && idMembers[0] && idResponsable[0] && selectedPriority !== undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card?.priority === selectedPriority &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card?.priority === selectedPriority &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card?.priority === selectedPriority &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card?.priority === selectedPriority &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card?.priority === selectedPriority &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Only Date Selected
        if (selectedDate !== undefined && !resetAll && !idMembers[0] && !idResponsable[0] && selectedPriority == undefined && !idCategories[0]) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card?.endDate && moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card?.endDate && moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card?.endDate && moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card?.endDate && moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card?.endDate && moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Only Category selected
        if (selectedDate === undefined && !resetAll && !idMembers[0] && !idResponsable[0] && selectedPriority == undefined && idCategories[0]) {
            return cards.map(card => {
                if (card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Only Member selected
        if (selectedDate === undefined && !resetAll && idMembers[0] && !idResponsable[0] && selectedPriority == undefined && !idCategories[0]) {
            return cards.map(card => {
                if (card.idMembers && idMembers.every(member => card.idMembers.includes(member._id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Responsable Selected
        if (selectedDate === undefined && !idCategories[0] && !resetAll && !idMembers[0] && idResponsable[0] && selectedPriority == undefined) {
            return cards.map(card => {
                if (card.idResponsible && card.idResponsible == idResponsable[0]) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Priority Selected
        if (selectedDate == undefined && !idCategories[0] && !resetAll && !idMembers[0] && !idResponsable[0] && selectedPriority !== undefined) {
            return cards.map(card => {
                if (card?.priority === selectedPriority) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Date and Member Selected
        if (selectedDate !== undefined && !resetAll && idMembers[0] && !idResponsable[0] && selectedPriority == undefined && !idCategories[0]) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card?.endDate && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card?.endDate && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card?.endDate && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card?.endDate && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card?.endDate && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Member and Category Selected
        if (selectedDate === undefined && idCategories[0] && !resetAll && idMembers[0] && !idResponsable[0] && selectedPriority == undefined) {
            return cards.map(card => {
                if (card.idMembers && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false )
                    && idMembers.every(member => card.idMembers.includes(member._id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Priority and Responsable Selected
        if (selectedDate === undefined && !idCategories[0] && !resetAll && !idMembers[0] && idResponsable[0] && selectedPriority != undefined) {
            return cards.map(card => {
                if (card.idResponsible && card.idResponsible == idResponsable[0] && card?.priority === selectedPriority) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Category and Responsable Selected
        if (selectedDate === undefined && idCategories[0] && !resetAll && !idMembers[0] && idResponsable[0] && selectedPriority == undefined) {
            return cards.map(card => {
                if (card.idResponsible && card.idResponsible == idResponsable[0] && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Category and Priority Selected
        if (selectedDate === undefined && idCategories[0] && !resetAll && !idMembers[0] && !idResponsable[0] && selectedPriority != undefined) {
            return cards.map(card => {
                if (card?.priority === selectedPriority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Member and Responsable Selected
        if (selectedDate === undefined && !idCategories[0] && !resetAll && idMembers[0] && idResponsable[0] && selectedPriority == undefined) {
            return cards.map(card => {
                if (card.idMembers && card.idResponsible
                    && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idResponsible == idResponsable[0]) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Priority and Date Selected
        if (selectedDate !== undefined && !idCategories[0] && !resetAll && !idMembers[0] && !idResponsable[0] && selectedPriority != undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card?.endDate && card?.priority === selectedPriority &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card?.endDate && card?.priority === selectedPriority &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card?.endDate && card?.priority === selectedPriority &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card?.endDate && card?.priority === selectedPriority &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card?.endDate && card?.priority === selectedPriority &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Category and Date Selected
        if (selectedDate !== undefined && idCategories[0] && !resetAll && !idMembers[0] && !idResponsable[0] && selectedPriority == undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Responsable and Date Selected
        if (selectedDate !== undefined && !idCategories[0] && !resetAll && !idMembers[0] && idResponsable[0] && selectedPriority == undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Priority and Member Selected
        if (selectedDate == undefined && !idCategories[0] && !resetAll && idMembers[0] && !idResponsable[0] && selectedPriority !== undefined) {
            return cards.map(card => {
                if (card?.priority === selectedPriority && idMembers.every(member => card.idMembers.includes(member._id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Responsible and Date and Priority Selected 
        if (selectedDate !== undefined && !idCategories[0] && !resetAll && !idMembers[0] && idResponsable[0] && selectedPriority != undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idResponsible == idResponsable[0] &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Category and Date and Priority Selected
        if (selectedDate !== undefined && idCategories[0] && !resetAll && !idMembers[0] && !idResponsable[0] && selectedPriority != undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card?.priority === selectedPriority && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Category and Date and Responsible Selected
        if (selectedDate !== undefined && idCategories[0] && !resetAll && !idMembers[0] && idResponsable[0] && selectedPriority == undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card.idResponsible == idResponsable[0] && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card.idResponsible == idResponsable[0] && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card.idResponsible == idResponsable[0] && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card.idResponsible == idResponsable[0] && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card.idResponsible == idResponsable[0] && card?.endDate && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Priority and Category and Member Selected
        if (selectedDate == undefined && idCategories[0] && !resetAll && idMembers[0] && !idResponsable[0] && selectedPriority != undefined) {
            return cards.map(card => {
                if (card?.priority === selectedPriority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false )
                    && idMembers.every(member => card.idMembers.includes(member._id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Category and Responsible and Member Selected
        if (selectedDate == undefined && idCategories[0] && !resetAll && idMembers[0] && idResponsable[0] && selectedPriority == undefined) {
            return cards.map(card => {
                if (card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Priority and Responsible and Member Selected
        if (selectedDate == undefined && !idCategories[0] && !resetAll && idMembers[0] && idResponsable[0] && selectedPriority !== undefined) {
            return cards.map(card => {
                if (card?.priority === selectedPriority && card.idResponsible == idResponsable[0]
                    && idMembers.every(member => card.idMembers.includes(member._id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Category and Responsible and Priority Selected
        if (selectedDate == undefined && idCategories[0] && !resetAll && !idMembers[0] && idResponsable[0] && selectedPriority !== undefined) {
            return cards.map(card => {
                if (card?.priority === selectedPriority && card.idResponsible == idResponsable[0]
                    && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Priority and Date and Member Selected
        if (selectedDate !== undefined && !idCategories[0] && !resetAll && idMembers[0] && !idResponsable[0] && selectedPriority !== undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Category and Date and Member Selected
        if (selectedDate !== undefined && idCategories[0] && !resetAll && idMembers[0] && !idResponsable[0] && selectedPriority == undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Member and Date and Responsible Selected
        if (selectedDate !== undefined && !idCategories[0] && !resetAll && idMembers[0] && idResponsable[0] && selectedPriority == undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idResponsible == idResponsable[0] &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idResponsible == idResponsable[0] && moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idResponsible == idResponsable[0] &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idResponsible == idResponsable[0] &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idResponsible == idResponsable[0] &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Category and Responsible and Priority and Member Selected
        if (selectedDate == undefined && idCategories[0] && !resetAll && idMembers[0] && idResponsable[0] && selectedPriority !== undefined) {
            return cards.map(card => {
                if (card?.priority === selectedPriority && card.idResponsible == idResponsable[0]
                    && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false )) {
                    return { ...card, isVisible: true };
                } else {
                    return { ...card, isVisible: false };
                }
            });
        }

        // Priority and Date and Responsible and Member Selected
        if (selectedDate !== undefined && !idCategories[0] && !resetAll && idMembers[0] && idResponsable[0] && selectedPriority !== undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] && moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Category and Date and Responsible and Member Selected
        if (selectedDate !== undefined && idCategories[0] && !resetAll && idMembers[0] && idResponsable[0] && selectedPriority == undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] && idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Category and Date and Responsible and Priority Selected
        if (selectedDate !== undefined && idCategories[0] && !resetAll && !idMembers[0] && idResponsable[0] && selectedPriority != undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        card.idResponsible == idResponsable[0] &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }

        // Category and Date and Member and Priority Selected
        if (selectedDate !== undefined && idCategories[0] && !resetAll && idMembers[0] && !idResponsable[0] && selectedPriority != undefined) {
            if (selectedDate === 'today') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-1, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'tomorrow') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(1, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'yesterday') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-2, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment().add(-1, 'days')) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'next_seven') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(7, 'days') > moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) > moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
            if (selectedDate === 'previous_seven') {
                return cards.map(card => {
                    if (selectedPriority == card?.priority && card.idLabels && 
                        idCategories.every(category => card.idLabels.includes(category.id) ? true : false ) &&
                        idMembers.every(member => card.idMembers.includes(member._id) ? true : false ) &&
                        moment().add(-7, 'days') < moment(new Date(card?.endDate)) && moment(new Date(card?.endDate)) < moment()) {
                        return { ...card, isVisible: true };
                    } else {
                        return { ...card, isVisible: false };
                    }
                });
            }
        }
    }

    addCard(boardId: string, listId: string, card: BoardCardModel): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardAddCard}/${boardId}`,
            {
                listId,
                cardName : card.name
            }
        );
    }

    moveCard(boardId: string, move: { cardId: string; listId: string; dropIndex: number }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardMoveCard}/${boardId}`,
            {
                ...move
            }
        );
    }

    updateCard(boardId: string, payload: any): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardUpdateCard}/${boardId}`,
            payload
        );
    }

    unsetCardResponsible(boardId: string, payload: any): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardUnsetResponsible}/${boardId}`,
            payload
        );
    }
    
    displayCardActivities(boardId: string, payload: any): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectBoardCardActivities}/${payload.cardId}`,
            {}
        );
    }

    deleteCardDate(boardId: string, payload: any): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardDeleteCardDate}/${boardId}`,
            payload
        );
    }

    updateAsCover(id: string, cover: string, boardId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.updateAsCover}/${boardId}`,
            JSON.stringify({
                id,
                cover
            })
        );
    }

    addList(boardId: string, list: BoardListModel): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardAddList}/${boardId}`,
            {
                list
            }
        );
    }

    removeList(boardId: string, listId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardRemoveList}/${boardId}`,
            {
                listId
            }
        );
    }

    moveList(boardId: string, lists: BoardListModel[], move: { listId: string; dropIndex: number }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardMoveList}/${boardId}`,
            {
                ...move
            }
        );
    }

    renameList(boardId: string, listId: string, name: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardRenameList}${boardId}`,
            {
                listId,
                name
            }
        );
    }

    toggleMember(boardId: string, toggleMemberForm: { cardId: string, memberId: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}${environment.http.projectBoardToggleMember}`,
            {
                cardId: toggleMemberForm.cardId,
                memberId: toggleMemberForm.memberId
            }
        );
    }

    updateCardDescription(boardId: string, card: { id: string, description: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.ProjectBoardCardDescription}/${boardId}`,
            {
                id: card.id,
                description: card.description
            }
        );
    }

    addCheckList(boardId: string, cardId: string, checklist: { id: string, name: string, checkItemsChecked: number, checkItems: { id: string, name: string, checked: boolean }[] }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}/card/${cardId}${environment.http.projectBoardAddCheckList}`,
            {
                id: checklist.id,
                name: checklist.name,
                checkItemsChecked: checklist.checkItemsChecked,
                checkItems: checklist.checkItems
            }
        );
    }

    addcheckItem(boardId: string, checkItemForm: { cardId: string, checklistId: string, id: string, name: string, checked: boolean }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}${environment.http.projectBoardAddcheckItem}`,
            {
                cardId: checkItemForm.cardId,
                checklistId: checkItemForm.checklistId,
                id: checkItemForm.id,
                name: checkItemForm.name,
                checked: checkItemForm.checked
            }
        );
    }

    updateChecklistName(boardId: string, checkListForm: { cardId: string, checklistId: string, checklistName: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}${environment.http.projectBoardUpdateChecklistName}/${boardId}`,
            {
                cardId: checkListForm.cardId,
                checklistId: checkListForm.checklistId,
                name: checkListForm.checklistName
            }
        );
    }

    removeCheckList(boardId: string, checkListForm: { cardId: string, checklistId: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}${environment.http.projectBoardRemoveCheckList}`,
            {
                cardId: checkListForm.cardId,
                checklistId: checkListForm.checklistId
            }
        );
    }

    removeCheckItem(boardId: string, checkListForm: { checklistId: string, checkItemId: string, cardId: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}${environment.http.projectBoardRemoveCheckItem}`,
            {
                cardId: checkListForm.cardId,
                checklistId: checkListForm.checklistId,
                checkItemId: checkListForm.checkItemId
            }
        );
    }

    updateCheckItem(boardId: string, checkItemForm: { cardId: string, checklistId: string, checkItemId: string, name: string, checked: boolean }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}${environment.http.ProjectBoardCheckItemUpdate}`,
            {
                cardId: checkItemForm.cardId,
                checklistId: checkItemForm.checklistId,
                checkItemId: checkItemForm.checkItemId,
                name: checkItemForm.name,
                checked: checkItemForm.checked
            }
        );
    }

    updateLabels(boardId: string, labels: { id: string, name: string, color: string, }[]): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.ProjectBoardLabelsUpdate}/${boardId}`,
            labels
        );
    }

    toggleLabel(boardId: string, toggleForm: { cardId: string, labelId: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}${environment.http.ProjectBoardToggleLabel}`,
            toggleForm
        );
    }

    duplicateCard(boardId: string, duplicate: { cardId: string, listId: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.ProjectBoardDuplicateCard}/${boardId}`,
            duplicate
        );
    }

    deleteComment(cardId: string, commentId: string, boardId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.ProjectBoardCardComment}/${boardId}`,
            {
                cardId,
                commentId
            }
        );
    }

    removeAttachment(cardId: string, attachmentId: string, boardId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.removeAttachment}/${boardId}`,
            JSON.stringify({
                cardId,
                attachmentId
            })
        );
    }

    addLabel(boardId: string, label: { name: string, color: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}${environment.http.ProjectBoardAddLabel}`,
            label
        );
    }

    updateLabel(boardId: string, label: { id: string, name: string, color: string }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.ProjectBoardLabelUpdate}/${boardId}`,
            label
        );
    }

    moveLabel(boardId: string, label: { cardId: string, labelId: string, dropIndex: number }): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.ProjectBoardLabelMove}/${boardId}`,
            label
        );
    }

    addAttachment(boardId: string, addAttachmentForm: { cardId: string; attachment: any }): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.ProjectBoardAttachment}/${boardId}`,
            JSON.stringify(addAttachmentForm)
        );
    }

    upload(file: any): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.attachment}`,
            file
        );
    }

    minioUpload(file: any, name): Observable<any> {
        return this._httpClient.post(
            `${environment.minio.url}/storage/o/public/` + name,
            file
        );
    }

    createFromBlob(blob: Blob): Observable<any> {
        return new Observable((sub: Subscriber<any>): void => {
            const reader = new FileReader();

            reader.onloadend = (ev: ProgressEvent) => {
                sub.next(reader.result);
                sub.complete();
            };

            reader.onerror = (ev): void => {
                sub.error(ev);
            };

            if (blob) {
                reader.readAsDataURL(blob);
            }
        });
    }

    downloadDocument(attachment: any): Observable<any> {
        return new Observable((sub: Subscriber<any>): void => {
            this._attachmentService.getFileStream(attachment.filename).subscribe(
                data => {
                    const blob = new Blob([data], {
                        type: document.contentType,
                    });
                    sub.next(importedSaveAs(blob, attachment.filename));
                    sub.complete();
                },
                error => {
                    sub.error(error);
                }
            );
        });
    }

    removeLabel(boardId: string, labelId: string, label: {}): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoard}/${boardId}/${environment.http.ProjectBoardRemoveLabels}/${labelId}`,
            label
        );
    }

    updateBoardBackground(boardId: string, background: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.ProjectBoardUpdateBackground}/${boardId}`,
            { background }
        );
    }

    watchBoard(userId: string, watch: any): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.watchBoard}`,
            { ...watch }
        );
    }

    linkCardToBoard(
        boardId: string,
        cardDetails: {
            cardId: string,
            linkedBoardId: string
        }
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectCardtoBoard}/${boardId}`,
            { ...cardDetails }
        );
    }

    copyCardToBoard(
        boardId: string,
        cardDetails: {
            cardId: string,
            boardToMoveTo: string
            comment?: string,
            createdAt: string,
            shouldShowModal: boolean,
        },
    ): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectMoveCardtoBoard}/${boardId}`,
            { ...cardDetails }
        );
    }

    addCardToBoard(
        boardId: string,
        cardDetails: {
            cardId: string,
            boardToShareWith: string
            comment?: string,
            inListId: string,
            createdAt: string,
            shouldShowModal: boolean,
        },
    ): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectAddCardtoBoard}/${boardId}`,
            { ...cardDetails }
        );
    }

    unlinkCardFromBoard(
        boardId: string,
        cardId: string
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectUnlinkCardFromBoard}/${boardId}`,
            { cardId }
        );
    }

    getProjectBoardCharts(
        boardId: string,
    ): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectBoardCharts}/${boardId}`
        );
    }

    createWidget(
        boardId: string,
        widget: { name: string, widgetType: string, dataAbscissa: string, widgetFilters: any[] }
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardAddWidget}/${boardId}`,
            {
                ...widget,
            }
        );
    }

    updateWidget(
        boardId: string,
        widget: { _id : string ,name: string, widgetType: string, dataAbscissa: string, widgetFilters: any[] }
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardUpdateWidget}/${boardId}`,
            {
                ...widget,
            }
        );
    }

    moveWidget(
        boardId: string,
        widgetId: string,
        dropIndex: number,
        widgetNumber: boolean,
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardMoveWidget}/${boardId}`,
            {
                widgetId,
                dropIndex,
                widgetNumber
            }
        );
    }

    removeWidget(
        boardId: string,
        widgetId: string,
        widgetNumber: boolean,
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardRemoveWidget}/${boardId}`,
            {
                widgetId,
                widgetNumber,
            }
        );
    }
}
