export const locale = {
    lang: 'ar',
    data: {
        EDIT: 'تعديل المعلومات',
        ADDRESS: 'عنوان',
        PHONE: 'هاتف',
        WEBSITE: 'موقع الكتروني',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
    }
};
