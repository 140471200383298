export const locale = {
    lang: 'fr',
    data: {
        MODIFY_STRUCTURE: 'Modifier la structure du projet',
        // tslint:disable-next-line: max-line-length
        PARA: 'La structure du projet représente les différents niveaux subséquents pour organiser vos projets et ateliers. Déterminez leur nombre et labels tels que pratiqué dans votre organisation.',
        LEVEL: 'Niveau',
        LABEL: 'Label du sous-projet',
        EXEMPLE: 'Par exemple: engagement, programme, enjeu, axe stratégique...',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',

    }
};
