import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as TimerActions from './actions';
import { NavbarStoreActions } from '../../navbar-store';
import { LoginStoreActions } from '../../login-store';
import { WorkshopActivitiesStoreActions } from '../activities-store';
import { WorkshopActivityStoreActions } from '../activity-store';

const timerReducer = createReducer(
    initialState,
    on(
        NavbarStoreActions.logoutConfirmed,
        NavbarStoreActions.leaveWorkshopConfirmed,
        LoginStoreActions.notAuthorized,
        () => initialState
    ),
    on(TimerActions.start, (state, { value, exercise, categories }) => ({
        ...state,
        start: true,
        value,
        exercise,
        categories
    })),
    on(TimerActions.pause, state => ({
        ...state,
        start: false,
    })),
    on(TimerActions.stop, state => ({
        ...state,
        value: 0,
        start: false,
        exercise: null
    })),
    on(TimerActions.update, (state, { exercise, timer }) => ({
        ...state,
        exercise,
        start: timer,
    })),
    on(WorkshopActivitiesStoreActions.loadSuccess, state => (
        initialState
    )),
    on(WorkshopActivityStoreActions.joinActivity, WorkshopActivityStoreActions.updateCurrentActivity, (state, { activity }) => ({
        ...state,
        contentId: activity.contentId
    })),
    on(TimerActions.updateCategories, (state, { categories }) => {
        return {
            ...state,
            categories
        };
    }),
    on(LoginStoreActions.loadSessionSuccess, (state, { session }) => {
        return {
            ...state,
            categories: session.categories ? session.categories : []
        };
    })
);

export function reducer(state: State | undefined, action: Action): any {
    return timerReducer(state, action);
}
