export const locale = {
    lang: 'en',
    data: {
        CLOSE: 'Close',
        CONDITIONS: 'Conditions of use',
        DEFINITIONS: '1. DEFINITIONS.',
        AGREEMENT: 'Excelway pre-launch software license agreement',
        // tslint:disable-next-line: max-line-length
        AGREE_TO_BE_BOUND_BY_THIS_AGREEMENT: 'THIS PRE-LAUNCH SOFTWARE LICENSE AGREEMENT (THE "AGREEMENT") GOVERNS YOUR USE OF THE PRE-LAUNCH SOFTWARE (DEFINED BELOW) PROVIDED BY EXCELWAY AND/OR ITS SUBSIDIARIES ("EXCELWAY"). BY ACCESSING THE PRE-LAUNCH SOFTWARE, YOU AGREE TO BE BOUND BY THIS AGREEMENT.',
        // tslint:disable-next-line: max-line-length
        ACCEPTING_THESE_TERMS: 'IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE POWER AND AUTHORITY TO BIND SUCH COMPANY TO THIS AGREEMENT, AND YOUR ACCEPTANCE OF THESE TERMS SHALL BE DEEMED THE COMPANY\'S AGREEMENT. IN THIS CASE, "YOU" AND "YOUR" REFER TO THAT COMPANY.',
        // tslint:disable-next-line: max-line-length
        YOU_ARE_NOT_WILLING_TO_ACCEPT: 'IF YOU ARE NOT WILLING TO ACCEPT THIS AGREEMENT, OR IF YOU DO NOT HAVE THE RIGHT, POWER AND AUTHORITY TO ACT ON BEHALF OF AND BIND YOUR COMPANY, DO NOT ACCESS OR OTHERWISE USE THE PRE-LAUNCH SOFTWARE.',
        // tslint:disable-next-line: max-line-length
        PRE_LAUNCH_SOFTWARE: '"Pre-launch Software" means the pre-launch version of the product, service or technology of Excelway identified on the home page of the software or in the invitation message (the "cover page"), whether it is labelled as alpha, beta, pre-launch, preview or other, which is provided to you by Excelway within the framework of the present contract. The pre-launch software may include all improvements, updates, upgrades, derivatives or bug fixes of this product, service or technology and any documentation, add-ons, models, data samples or hardware devices as provided by Excelway.',
        // tslint:disable-next-line: max-line-length
        DATA: '"Data" means the raw data that you download or submit to the pre-launch software and the processed result of the raw data generated by you using the pre-launch software.',
        // tslint:disable-next-line: max-line-length
        FEED_BACK: '"Feedback" means all suggestions, comments, opinions, codes, contributions, ideas, reports, information, know-how or other reactions that you provide (whether in oral, electronic or written form) to Excelway in connection with your use of the pre-launch software. The feedback does not include the data, unless you have submitted or communicated them to Excelway in the context of the feedback.',
        // tslint:disable-next-line: max-line-length
        INTERNAL_PURPOSES: 'Internal purposes" means the internal use of your systems, networks, devices and data for internal tests, evaluation or within the framework of services that you provide to a third party of the pre-launch software in order to provide feedback to Excelway concerning the pre-launch software.',
        THE_PRE_LAUNCH_SOFTWARE: '2. LICENSE OF THE PRE-LAUNCH SOFTWARE.',
        // tslint:disable-next-line: max-line-length
        SUBJECT_TO_YOUR_COMPLIANCE: 'Subject to your compliance with the terms and conditions of this contract, EXCELWAY grants you a non-exclusive, non-sublicenseable, non-transferable, revocable and limited license for the duration of the contract.',
        LICENSE_RESTRICTIONS: '3. LICENSE RESTRICTIONS.',
        // tslint:disable-next-line: max-line-length
        EXCEPT_AS_EXPRESSLY: 'Except as expressly authorized in this Agreement or by EXCELWAY, you shall not, and shall not permit any third party to do so: (i) access or use the Pre-Release Software for any purpose other than internal purposes (including for any competitive, business, professional, or other for-profit analysis); (ii) copy the Pre-Release Software; (iii) modify, adapt or create derivative works from the Pre-Release Software; (iv) rent, lease, loan, resell, transfer, sublicense or distribute the Pre-Release Software to any third party; (v) use or offer any functionality of the Pre-Release Software on a service bureau, hosting, software-as-a-service or timesharing basis; (vi) decompile, disassemble or reverse engineer the Pre-Release Software or otherwise attempt to derive the Pre-Release Software\'s source code, algorithms, methods or techniques used or incorporated into the Pre-Release Software; (vii) disclose to any third party the results of any evaluation test or other evaluation of the Pre-Release Software, or (viii) remove, alter, obscure, cover up or modify any trademarks, copyrights or other proprietary notices, labels or markings of or on the Pre-Release Software; (ix) interfere with or disrupt servers or networks connected to any website through which the Pre-Release Software was provided; ',
    }
};
