export const locale = {
    lang: 'fr',
    data: {
        START: 'Lancer Brainstorm',
        SELECT: 'Choisissez une ou plusieurs couleurs de cartes pour ce brainstorm',
        COLOR: 'Les couleurs sont composées d’une couleur de carte et d’un nom que vous définissez',
        ALL_CATEGORIES: 'Toutes couleurs',
        CARD_CATEGORIES: 'Couleurs de cartes',
        CANCEL: 'Annuler',
    }
};
