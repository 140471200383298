export const locale = {
    lang: 'fr',
    data: {
        NOTES_PANEL_NOTES:'Notes',
        NOTES_PANEL_TOTAL:'au total',
        NOTES_PANEL_ADD_NOTE:'Ajouter une note',
        NOTES_PANEL_EDIT:'Éditer',
        NOTES_PANEL_DELETE: 'Supprimer',
        NOTES_PANEL_NOTES_TITILE:'Le titre de votre note',
    }
};
