import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as UsersActions from './actions';

const usersReducer = createReducer(
    initialState,
    on(UsersActions.loadUsersSuccess, (state, { users }) => ({
        ...state,
        users,
    })),
    on(UsersActions.loadUsersFailure, (state, { error }) => ({
        ...state,
        error: error,
    }))
);

export function reducer(state: State | undefined, action: Action): any {
    return usersReducer(state, action);
}
