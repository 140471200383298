import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as ActivitiesActions from './actions';

const activitiesReducer = createReducer(
    initialState,
    on(ActivitiesActions.load, (state) => ({
        ...state,
        pending: true,
        error: null,
        activities: [],
    })),
    on(ActivitiesActions.loadSuccess, (state, { activities }) => ({
        ...state,
        pending: false,
        activities
    })),
    on(ActivitiesActions.loadFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error
    })),
    on(ActivitiesActions.loadVersions, (state) => ({
        ...state,
        pending: true,
        error: null
    })),
    on(ActivitiesActions.loadVersionsSuccess, (state, { versions }) => ({
        ...state,
        pending: false,
        versions
    })),
    on(ActivitiesActions.loadVersionsFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error
    })),
    on(ActivitiesActions.addActivitySuccess, (state, { activity }) => ({
        ...state,
        pending: false,
        activities: [...state.activities, activity]
    })),
    on(ActivitiesActions.addActivityViaSocket, (state, { activity }) => ({
        ...state,
        pending: false,
        activities: [...state.activities, activity]
    })),
    on(ActivitiesActions.updateActivitySuccess, (state, { id, color, name }) => {
        return {
            ...state,
            activities: [
                ...state.activities.map(_activity => {
                    if (_activity._id === id) {
                        _activity.color = color;
                        _activity.name = name;
                    }
                    return _activity;
                }),
            ],
        };
    }),
    on(ActivitiesActions.duplicateActivitySuccess, (state, { activity }) => ({
        ...state,
        pending: false,
        activities: [...state.activities, activity]
    })),
    on(ActivitiesActions.duplicateActivityViaSocket, (state, { activity }) => ({
        ...state,
        pending: false,
        activities: [...state.activities, activity]
    })),
    on(ActivitiesActions.UpdateCurrent, (state, { id }) => {
        return {
            ...state,
            currentId: id
        };
    }),
    on(ActivitiesActions.deleteActivitySuccess, (state, { activity }) => {
        return {
            ...state,
            activities: [...state.activities.filter(_ => _._id !== activity._id)],
        };
    }),
    on(ActivitiesActions.deleteActivityViaSocket, (state, { activity }) => {
        return {
            ...state,
            activities: [...state.activities.filter(_ => _._id !== activity._id)],
        };
    }),
    on(ActivitiesActions.uploadServiceSuccess, (state, { response }) => {
        return {
            ...state,
            versions: [...state.versions, ...response.versions.map(_ => {
                return {
                    _id: _._id,
                    title: _.title,
                    createdAt: _.createdAt,
                };
            })],
        };
    }),
    on(ActivitiesActions.activityApdated, (state, { activity }) => {
        return {
            ...state,
            activities: [
                ...state.activities.map(_activity => {
                    if (_activity._id === activity._id) {
                        if (activity.evaluationStarted !== undefined || activity.brainstormStarted !== undefined) {
                            _activity = {
                                ...activity
                            };
                        } else {
                            _activity.color = activity.color;
                            _activity.name = activity.name;
                        }
                    }
                    return _activity;
                }),
            ],
        };
    }),
    on(ActivitiesActions.activitySearch, (state, { text }) => {
        return {
            ...state,
            filtredCanvas:
                text.toLowerCase() === ''
                    ? state.canvas
                    : state.canvas.filter(canva => {
                        if (canva.i18n.toLowerCase().includes(text.toLowerCase())) {
                            return canva;
                        }
                    }),
            selectedCategory: 'all'
        };
    }),
    on(ActivitiesActions.activityFilterByCategory, (state, { category }) => {
        return {
            ...state,
            filtredCanvas:
                category === 'all'
                    ? state.canvas
                    : state.canvas.filter(canva => {
                        if (canva.canvasType === category || canva.activityType === 'brainstorm') {
                            return canva;
                        }
                    }),
            selectedCategory: category
        };
    }),
);

export function reducer(state: State | undefined, action: Action): any {
    return activitiesReducer(state, action);
}
