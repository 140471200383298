import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bs-avatar2',
    templateUrl: './avatar2.component.html',
    styleUrls: ['./avatar2.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class Avatar2Component implements OnInit {
    @Input() initials: string;
    @Input() menuFolded: Boolean;
    colors = [
        'yellowSticky',
        'blueSticky',
        'redSticky',
        'greenSticky',
        'bali__2',
        'bora__1',
        'bora__2',
        'blue__1',
        'pink__1',
        'purple__1',
        'blue__2',
        'red__2',
        'gray__2'
    ];
    class = '';
    constructor() {
        this.class = this.getRandomColorClass();
    }

    ngOnInit() {}

    getRandomColorClass(): string {
        return 'orangeSticky';
    }
}
