import { UserModel } from '@bsuccess/models/user.model';

export interface State {
    socketId: string;
    connected: boolean;
    joined: boolean;
    pong: number;
    users: UserModel[];
    contentId: string;
    session: any;
}

export const initialState: State = {
    socketId: null,
    connected: false,
    joined: false,
    pong: 999,
    users: [],
    contentId: null,
    session: null,
};
