export const locale = {
    lang: 'ar',
    data: {
        PROJECTS_BOARD_NEW_GENERAL_BOARD_NAME: 'إسم اللوحة',
        PROJECTS_BOARD_NEW_GENERAL_DESCRIPTION: 'وصف',
        PROJECTS_BOARD_NEW_GENERAL_SAVE: 'حِفْظ',
        PROJECTS_BOARD_NEW_GENERAL_CANCEL: 'إلغاء',
        PROJECTS_NEW_DIALOG_NEW_GENERAL_MEMBERS_CAN_SEE_OTHERS_CARDS:'يمكن لجميع أعضاء اللوحة رؤية جميع المهام',
        PROJECTS_NEW_DIALOG_NEW_GENERAL_MEMBERS_CANT_SEE_OTHERS_CARDS:'يمكن فقط للأعضاء الذين لهم دور مشرف في اللوحة رؤية جميع المهام',
    }
};
