export const locale = {
    lang: 'fr',
    data: {
        EDIT_DETAILS: 'Modifier les détails de l\'atelier',
        SESSION_NAME: 'Nom de L\'atelier',
        DESCRIPTION: 'Description',
        OBLIGATORY: 'Atelier avec compte Excelway obligatoire',
        WITHOUT: 'Atelier sans compte Excelway',
        START_DATE: 'Date de début',
        END_DATE: 'Date de fin',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
    }
};
