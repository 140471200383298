export const locale = {
    lang: 'ar',
    data: {
        CHANGE_WORKSPACE_MAIN: 'تغيير مساحة العمل',
        CLOSE: 'أغلق',
        WORKSPACE: 'مساحة العمل',
        WORKSPACE_REQUIRED: 'مطلوب مساحة عمل.',
        CHANGE: 'تغيير',
    }
};
