import { createAction, props } from '@ngrx/store';

import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';

export const loadUsers = createAction('[Studio][Users Page] Load Users');

export const loadUsersSuccess = createAction(
    '[Studio][Users API] Load Users Success',
    props<{ users: ProjectUserModel[] }>()
);

export const loadUsersFailure = createAction(
    '[Studio][Users API] Load Users Failure',
    props<{ error: string }>()
);

