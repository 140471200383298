import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/board-create-widget-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/board-create-widget-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/board-create-widget-dialog/ar';
import { StudioBoardStoreActions, StudioBoardStoreSelectors } from 'app/root-store/studio-store/board-store';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BoardModel } from '@bsuccess/models/board/board.model';


@Component({
    selector: 'board-create-widget-dialog',
    templateUrl: './board-create-widget-dialog.component.html',
    styleUrls: ['./board-create-widget-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class BoardCreateWidgetDialogComponent implements OnInit {
    filters = [];
    members: ProjectUserModel[] = [];
    selectedMembers: ProjectUserModel[] = [];
    composeForm: UntypedFormGroup;
    categories = [];
    selectedCategories = [];
    selectedFilters : [
        filter?: any,
        state?: any,
        responsableId?: any,
        membersId?: any[],
        labelsId?:  any[],
    ] = [];
    selectedResponsables = [];
    numberFilterSelected = [];
    member = this.members[0];
    category: string;
    selectedMember;
    selectedResponsable;
    selectedDate;
    selectedPriority;
    selectedStatus;
    selectedCategory;
    currentFilter;
    isDateSelected;
    isPrioritySelected;
    isCategorySelected;
    isResponsableSelected;
    isMemberSelected;
    widgetType;
    widgetTypeChart;
    widgetName;

    selectedMembersControl = new UntypedFormControl(this.selectedMembers);
    selectedCategoriesControl = new UntypedFormControl(this.selectedMembers);


    currentBoard$: Observable<BoardModel>;
    board: BoardModel;

    selectedField: any;

    constructor(
        public dialogRef: MatDialogRef<BoardCreateWidgetDialogComponent>,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {

        this._store
            .select(StudioBoardStoreSelectors.selectBoardUsers)
            .subscribe((users: ProjectUserModel[]) => {
                if (this.members && users) {
                    this.members = [...users];
                }
            });

        this._store
            .select(StudioBoardStoreSelectors.selectBoardLabels)
            .subscribe(labels => {
                this.categories = labels;
            });

        this.currentBoard$ = this._store.select(
            StudioBoardStoreSelectors.selectCurrent
        );

        this.currentBoard$.subscribe(_ => {
            if (_) {
                this.board = _;
            }
        })

        this.category = this.categories[0];


        this.filters = [
            { name: 'members', id: 1 },
        ]

        this.composeForm = this.createComposeForm();
    }

    createComposeForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            widgetName: new UntypedFormControl(this.widgetName),
            widgetType: new UntypedFormControl(this.widgetType),
            widgetTypeChart: new UntypedFormControl(this.widgetTypeChart),
        });
    }

    updateMember(array, object: any): void {
        array.shift();
        array.push(object);
    }

    delete(index: number): void {
        if (index > 0) {
            this.currentFilter = this.filters;
            if (this.currentFilter[index].name == 'members') {
                this.selectedMembers = [];
                this.selectedMember = undefined;
            }
            if (this.currentFilter[index].name == 'category') {
                this.selectedCategories = [];
                this.selectedCategory = undefined;
            }
            if (this.currentFilter[index].name == 'due_date') {
                this.selectedDate = undefined;
            }
            if (this.currentFilter[index].name == 'priority') {
                this.selectedPriority = undefined;
            }
            if (this.currentFilter[index].name == 'status') {
                this.selectedStatus = undefined;
            }
            if (this.currentFilter[index].name == 'responsable') {
                this.selectedResponsables = [];
                this.selectedResponsable = undefined;
            }
            this.filters.splice(index, 1);
        }
    }

    checkFilter(filterName): boolean {
        this.numberFilterSelected = [];
        this.filters.map(filter => {
            if(filter.name == filterName){
                this.numberFilterSelected.push(filter);
            };
        });
        if(this.numberFilterSelected.length > 0){
            return true ;
        }
    }

    add(): void {
        if(this.filters.every((filter) => filter.name != 'members')){
            this.filters.push({ name: 'members', });
        }
        else if(this.filters.every((filter) => filter.name != 'responsable')){
            this.filters.push({ name: 'responsable', });
        }
        else if(this.filters.every((filter) => filter.name != 'category')){
            this.filters.push({ name: 'category', });
        }
        else if(this.filters.every((filter) => filter.name != 'due_date')){
            this.filters.push({ name: 'due_date', });
        }
        else if(this.filters.every((filter) => filter.name != 'priority')){
            this.filters.push({ name: 'priority', });
        }
        else if(this.filters.every((filter) => filter.name != 'status')){
            this.filters.push({ name: 'status', });
        }
    }

    createWidget(): void { 
        this.filters.map(filter => {
            if (filter.name == 'members') {
                this.selectedFilters.push({
                    filter: 'member_filter',
                    membersId: this.selectedMembersControl.value.map( member =>  member._id ),
                });
            }
            if (filter.name == 'category') {
                this.selectedFilters.push({
                    filter: 'tag_filter',
                    labelsId: this.selectedCategoriesControl.value.map( category =>  category.id ),
                });
            }
            if (filter.name == 'due_date'){
                this.selectedFilters.push({
                    filter: 'due_date_filter',
                    state: this.selectedDate,
                });
            }
            if (filter.name == 'priority'){
                this.selectedFilters.push({
                    filter: 'priority_filter',
                    state: this.selectedPriority,
                });
            }
            if (filter.name == 'status'){
                this.selectedFilters.push({
                    filter: 'status_filter',
                    state: this.selectedStatus,
                });
            }
            if (filter.name == 'responsable'){
                this.selectedFilters.push({
                    filter: 'owner_filter',
                    responsableId: this.selectedResponsable._id,
                });
            }
        });

        this._store.dispatch(StudioBoardStoreActions.createWidget({
            boardId: this.board.id,
            widget: {
                name: this.widgetName ? this.widgetName : '',
                widgetType: this.widgetType,
                dataAbscissa: this.widgetType != 'number' ? this.widgetTypeChart : null,
                widgetFilters: this.selectedFilters ? this.selectedFilters : [],
            }
        }));
    }
}
