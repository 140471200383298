import { MatDialogRef } from '@angular/material/dialog';
import {
    Component,
    OnInit,
    ViewEncapsulation,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import {
    StudioSessionStoreActions,
    StudioSessionStoreSelectors,
} from 'app/root-store/studio-store/session-store';
import { StudioUsersStoreSelectors } from 'app/root-store/studio-store/users-store';
import { locale as english } from '../../../../../i18n/studio/projects/session/dialogs/update-guests-and-send-invitation-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/session/dialogs/update-guests-and-send-invitation-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/session/dialogs/update-guests-and-send-invitation-dialog/ar';

@Component({
    selector: 'update-guests-and-send-invitation-dialog',
    templateUrl:
        './update-guests-and-send-invitation-dialog.component.html',
    styleUrls: [
        './update-guests-and-send-invitation-dialog.component.scss',
    ],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class SessionUpdateGuestsAndSendInvitationsDialogComponent implements OnInit {
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    isEmpty = true;
    isLoaded = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    emailCtrl = new UntypedFormControl();
    filteredEmails: Observable<string[]>;
    emails: string[] = [];
    allEmails: string[] = [];
    animators: string[] = [];

    @ViewChild('emailInput') emailInput: ElementRef<
        HTMLInputElement
    >;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor(
        public dialogRef: MatDialogRef<
            SessionUpdateGuestsAndSendInvitationsDialogComponent
        >,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.dialogRef.disableClose = true;
        this._store
            .select(StudioSessionStoreSelectors.selectSessionGuests)
            .subscribe(guests => {
                if (!this.isLoaded) {
                    if (guests) {
                        this.emails = guests.map(guest => guest.email);
                        if (this.emails.length > 0) {
                            this.isEmpty = false;
                        } else {
                            this.isEmpty = true;
                        }
                        this.isLoaded = true;
                    }
                } else {
                    this.dialogRef.close();
                }
            });
        this._store
            .select(StudioSessionStoreSelectors.selectSessionUsers)
            .subscribe(users => {
                if (users) {
                    this.animators = users
                        .filter(user => user.position === 'animator')
                        .map(user => user.email);
                }
            });
        this.filteredEmails = this.emailCtrl.valueChanges.pipe(
            startWith(null),
            map((email: string | '') =>
                email
                    ? this._filter(email).filter(
                        mail => !this.animators.includes(mail)
                    )
                    : this.allEmails
                        .slice()
                        .filter(mail => !this.animators.includes(mail))
            )
        );

    }
    ngOnInit(): void {
        this._store.select(StudioUsersStoreSelectors.selectUsers).subscribe(users => {
            this.allEmails = users
                .filter(user => !this.emails.includes(user.email))
                .map(user => user.email);
        });
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if (this.validateEmail(value) && !this.emails.includes(value.trim())) {
            this.emails.push(value.trim());
            this.allEmails.splice(this.allEmails.indexOf(value.trim()), 1);
            this.emailCtrl.setValue(null);
            input.value = '';
        }
    }

    remove(index: number, email: string): void {
        this.emails.splice(index, 1);
        this.allEmails.push(email.trim());
        this.emailCtrl.setValue(null);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.emails.push(event.option.viewValue);
        this.allEmails.splice(
            this.allEmails.indexOf(event.option.viewValue.trim()),
            1
        );
        this.emailInput.nativeElement.value = null;
        this.emailCtrl.setValue(null);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allEmails
            .filter(email => !this.emails.includes(email))
            .filter(email => email.toLowerCase().indexOf(filterValue) === 0);
    }

    updateGuestsAndSendInvites(): void {
        this._store.dispatch(
            StudioSessionStoreActions.updateGuestsAndSendInvitation({
                emails: this.emails,
            })
        );
    }

    validateEmail(email: string): boolean {
        if (this.animators.includes(email)) {
            return false;
        } else {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }

    onInputChange(): void {
        if (this.emails.length > 0) {
            this.isEmpty = false;
        } else {
            this.isEmpty = true;
        }
    }
}
