import { createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { RootStoreState, RootStoreSelectors } from '..';
import { tap } from 'rxjs/operators';
import { HeaderStoreActions } from '.';

@Injectable()
export class HeaderStoreEffects {
    constructor(private _store: Store<RootStoreState.State>) { }

    updateHeader$ = createEffect(
        () =>
            this._store.pipe(
                select(RootStoreSelectors.selectUrl),
                tap(url => {
                    if (url) {
                        if (url.includes('/studio/projects/one')) {
                            this._store.dispatch(
                                HeaderStoreActions.updateHeaderType({
                                    menuType: 'project',
                                })
                            );
                        } else {
                            if (url.includes('/sessions/')) {
                                this._store.dispatch(
                                    HeaderStoreActions.updateHeaderType({
                                        menuType: 'session',
                                    })
                                );
                            } else {
                                if (url.includes('/boards/')) {
                                    this._store.dispatch(
                                        HeaderStoreActions.updateHeaderType({
                                            menuType: 'board',
                                        })
                                    );
                                } else {
                                    if (url === '/studio/projects') {
                                        this._store.dispatch(
                                            HeaderStoreActions.updateHeaderType({
                                                menuType: 'projects',
                                            })
                                        );
                                    } else {
                                        if (url === '/studio/home') {
                                            this._store.dispatch(
                                                HeaderStoreActions.updateHeaderType({
                                                    menuType: 'none',
                                                })
                                            );
                                        } else {
                                            if (url === '/studio/sessions') {
                                                this._store.dispatch(
                                                    HeaderStoreActions.updateHeaderType({
                                                        menuType: 'sessions',
                                                    })
                                                );
                                            } else {
                                                if (url === '/studio/boards') {
                                                    this._store.dispatch(
                                                        HeaderStoreActions.updateHeaderType({
                                                            menuType: 'boards',
                                                        })
                                                    );
                                                } else {
                                                    if (url === '/studio/projects/new-parent') {
                                                        this._store.dispatch(
                                                            HeaderStoreActions.newProjectHeader()
                                                        );
                                                    } else {
                                                        this._store.dispatch(
                                                            HeaderStoreActions.hideHeader()
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
            ),
        {
            dispatch: false,
        }
    );
}
