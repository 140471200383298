export const locale = {
    lang: 'en',
    data: {
        PROJECTS_BOARD_NEW_GENERAL_BOARD_NAME: 'Board name',
        PROJECTS_BOARD_NEW_GENERAL_DESCRIPTION: 'Description',
        PROJECTS_BOARD_NEW_GENERAL_SAVE: 'Save',
        PROJECTS_BOARD_NEW_GENERAL_CANCEL: 'Cancel',
        PROJECTS_NEW_DIALOG_NEW_GENERAL_MEMBERS_CAN_SEE_OTHERS_CARDS: 'All board members can see all tasks.',
        PROJECTS_NEW_DIALOG_NEW_GENERAL_MEMBERS_CANT_SEE_OTHERS_CARDS:'Only members with an Admin role can see all tasks.',
    }
};
