import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


import { FuseSharedModule } from '@fuse/shared.module';

import { ProjectsPanelComponent } from 'app/layout/components/projects-panel/projects-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { BsuccessModule } from '@bsuccess/bsuccess.module';

@NgModule({
  declarations: [ProjectsPanelComponent],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatTreeModule,
    RouterModule,
    MatTooltipModule,
    BsuccessModule,

    FuseSharedModule,
  ],
  exports: [ProjectsPanelComponent],
})
export class ProjectsPanelModule { }
