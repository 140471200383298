export const locale = {
    lang: 'en',
    data: {
        MANAGE_MEMBERS: 'Invite members',
        // tslint:disable-next-line: max-line-length
        PARAGRAPH: 'Designate a facilitator and invite members to help you organize the workshop. If they are also participants, they will be able to access and edit the meeting minutes.',
        INCLUDE_PARTICIPANT: 'Include as a participant?',
        MEMBERS: 'Member',
        THE_EMAIL: 'Email',
        POSITION: 'Position',
        SPONSOR: 'Sponsor',
        ADMIN: 'Admin',
        ADD_USER_BUTTON_LABEL: 'Add user',
        RESPONSIBLE: 'Owner',
        ANIMATOR: 'Facilitator',
        ROLE: 'Role',
        READING: 'Viewer',
        MODIFICATION: 'Editor',
        REMOVE: 'Remove',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        PARTICIPANT: 'Participant',
    }
};
