export const locale = {
    lang: 'en',
    data: {
        PROJECT_DIALOGS_DOCUMENT_EDIT_EDIT_DOCUMENT: 'Edit a document',
        PROJECT_DIALOGS_DOCUMENT_EDIT_TITLE_DOCUMENT: 'Document title',
        PROJECT_DIALOGS_DOCUMENT_EDIT_REPORT: 'Report',
        PROJECT_DIALOGS_DOCUMENT_EDIT_TYPE: 'Type',
        PROJECT_DIALOGS_DOCUMENT_EDIT_PRESENTATION: 'Presentation',
        PROJECT_DIALOGS_DOCUMENT_EDIT_OTHER: 'Other',
        PROJECT_DIALOGS_DOCUMENT_EDIT_SAVE: 'Save',
        PROJECT_DIALOGS_DOCUMENT_EDIT_CANCEL: 'Cancel',
    }
};
