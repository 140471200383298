import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { of, interval } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AnimatorTimer2Service {
    timeLeft: any;
    paused = false;
    timer: any;

    constructor() {}

    initTimer(eventDate): any {
        this.timeLeft = moment.duration(eventDate * 1000);
        let seconds = this.timeLeft.seconds() + '';
        if (this.timeLeft.seconds() < 10) {
            seconds = '0' + seconds;
        }
        this.timer = {
            minutes: this.timeLeft.minutes(),
            seconds: seconds,
        };
        return this.timer;
    }

    startTimer(seconds): any {
        if (this.paused) {
            this.paused = false;
            const eventDate = this.timeLeft;

            let diff = eventDate;

            return interval(1000).pipe(
                map(value => {
                    if (diff > 0) {
                        return (diff = diff - 1);
                    }
                }),
                map(value => {
                    const timeLeft = moment.duration(value, 'seconds');
                    let secondes = timeLeft.seconds() + '';
                    if (timeLeft.seconds() < 10) {
                        secondes = '0' + secondes;
                    }
                    this.timer = {
                        minutes: timeLeft.minutes(),
                        seconds: secondes,
                    };
                    return this.timer;
                })
            );
        } else {
            const eventDate = seconds;

            let diff = eventDate;

            return interval(1000).pipe(
                map(value => {
                    if (diff > 0) {
                        return (diff = diff - 1);
                    }
                }),
                map(value => {
                    const timeLeft = moment.duration(value, 'seconds');
                    let secondes = timeLeft.seconds() + '';
                    if (timeLeft.seconds() < 10) {
                        secondes = '0' + secondes;
                    }
                    if (timeLeft.minutes() === 0 && seconds === '00') {
                    }
                    this.timer = {
                        minutes: timeLeft.minutes(),
                        seconds: secondes,
                    };
                    return this.timer;
                })
            );
        }
    }

    stopTimer(): any {
        this.paused = false;
        this.timer = {
            minutes: 0,
            seconds: '00',
        };
        return of(this.timer);
    }

    pauseTimer(): any {
        this.paused = true;
        this.timeLeft = moment.duration(this.timer).asSeconds();
    }

    getSeconds(timer): any {
        return moment.duration(timer).asSeconds();
    }

    getMinutes(seconds): any {
        return moment.duration(seconds, 'seconds').minutes();
    }
}
