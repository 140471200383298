export const locale = {
    lang: 'fr',
    data: {
        ALL_CARDS: 'Toute les cartes',
        LIST_CARDS: 'Cartes des listes',
        TYPE: 'Type',
        POINTS: 'Points',
        CATEGORIES: 'Couleurs',
        CARDS_HAVE: 'Cartes qui ont',
        OF: 'de',
        CHOOSE_CARDS: 'Choisir les cartes',
        PLUS: 'plus',
        MOINS: 'moins',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
    }
};
