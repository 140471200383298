export const locale = {
    lang: 'en',
    data: {
        BOARD_FILTER_CARDS_TITLE: 'Filter tasks',
        BOARD_FILTER_CARDS_WHERE: 'Where',
        BOARD_FILTER_CARDS_IS:'is',
        BOARD_FILTER_CARDS_MEMBER:'Member',
        BOARD_FILTER_CARDS_RESPONSABLE:'Owner',
        BOARD_FILTER_CARDS_DATE_LIMITE:'Due date',
        BOARD_FILTER_CARDS_PRIORITY:'Priority',
        BOARD_FILTER_CARDS_CATEGORIES:'Tag',
        BOARD_FILTER_CARDS_ADD_FILTER:' + Add filter',
        BOARD_FILTER_CARDS_FILTER:'Filter',
        BOARD_FILTER_CARDS_CANCEL:'Cancel',
        BOARD_FILTER_CARDS_CH_ONE : 'Today',
        BOARD_FILTER_CARDS_CH_TWO : 'Tomorrow',
        BOARD_FILTER_CARDS_CH_THREE : 'Before today',
        BOARD_FILTER_CARDS_CH_FOUR : 'In the next 7 days',
        BOARD_FILTER_CARDS_CH_FIVE : 'In the last 7 days',
        BOARD_FILTER_CARDS_CH_SIX : 'Yesterday',
        BOARD_FILTER_CARDS_PR_HIGH : 'High Priority',
        BOARD_FILTER_CARDS_PR_MID : 'Medium Priority',
        BOARD_FILTER_CARDS_PR_LOW : 'Low Priority',
        BOARD_FILTER_RESET : 'Reset',
    }
};
