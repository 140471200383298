export const locale = {
    lang: 'ar',
    data: {
        PROJECT_DIALOGS_DOCUMENT_NEW_ADD_DOCUMENT: 'إضافة وَتيقَة',
        PROJECT_DIALOGS_DOCUMENT_NEW_TITLE_DOCUMENT : 'عنوان الوتيقة',
        PROJECT_DIALOGS_DOCUMENT_NEW_REPORT: 'تقْرير',
        PROJECT_DIALOGS_DOCUMENT_NEW_TYPE: 'النوع',
        PROJECT_DIALOGS_DOCUMENT_NEW_PRESENTATION: 'عَرْض',
        PROJECT_DIALOGS_DOCUMENT_NEW_OTHER: 'آخر',
        PROJECT_DIALOGS_DOCUMENT_NEW_BROWSE: 'تصَفّحْ ملفاتك',
        PROJECT_DIALOGS_DOCUMENT_NEW_SAVE: 'حفظ',
        PROJECT_DIALOGS_DOCUMENT_NEW_CANCEL: 'إلغاء',
    }
};
