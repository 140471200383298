export const locale = {
    lang: 'ar',
    data: {
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_EDIT_DETAILS: ' إعدادات اللوحة',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_BOARD_NAME: 'إسم اللوحة',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_DESCRIPTION: 'الوصف',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_SAVE: 'حفظ',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_CANCEL: 'إلغاء',
        PROJECTS_NEW_DIALOG_NEW_GENERAL_MEMBERS_CAN_SEE_OTHERS_CARDS:'يمكن لجميع أعضاء اللوحة رؤية جميع المهام',
        PROJECTS_NEW_DIALOG_NEW_GENERAL_MEMBERS_CANT_SEE_OTHERS_CARDS:'يمكن فقط للأعضاء الذين لهم دور مشرف في اللوحة رؤية جميع المهام',
    }
};
