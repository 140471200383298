export const locale = {
    lang: 'ar',
    data: {
        TRANSFORM: 'تحويل البطاقات إلى قوائم',
        ALL_CARDS: 'جميع البطاقات',
        TYPE: 'النوع',
        POINTS: 'نقاط',
        LIST_CARDS: 'بطاقات من القوائم',
        CATEGORIES: 'الألوان',
        CARDS_HAVE: 'البطاقات التي لديها',
        OF: 'من',
        CHOOSE_CARDS: 'اختر البطاقات',
        PLUS: 'أكثر',
        MOINS: 'أقل',
        SAVE: 'حِفْظْ',
        CANCEL: 'إلغاء',
    }
};
