import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as SessionActions from './actions';
import { StudioProjectStoreActions } from '../project-store';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { LoginStoreActions } from 'app/root-store/login-store';
import { WorkshopBrainstormingStoreActions } from 'app/root-store/workshop-store/brainstorming-store';
import { WorkshopCanvasStoreActions } from 'app/root-store/workshop-store/canvas-store';
import { WorkshopCardboardStoreActions } from 'app/root-store/workshop-store/cardboard-store';
import { WorkshopActivitiesStoreActions } from 'app/root-store/workshop-store/activities-store';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';

const sessionReducer = createReducer(
    initialState,
    on(SessionActions.addSessionSuccess, (state, { session }) => ({
        ...state,
        current: {
            ...session,
            status: 'declared',
        },
    })),
    on(SessionActions.showAddSessionDialog, state => ({
        ...state,
        current: {
            users: [],
            participants: [],
            isPrivate: null,
        },
        error: null,
        stepperPosition: null,
        versions: [],
    })),
    on(SessionActions.loadCurrentSuccess,
        LoginStoreActions.loadSessionSuccess, (state, { session }) => ({
            ...state,
            current: session,
            stepperPosition:
                session.status === 'planned'
                    ? 'animation'
                    : session.status === 'animated'
                        ? 'monitoring'
                        : session.status === 'monitored'
                            ? 'closing'
                            : session.status === 'closed'
                                ? 'closing'
                                : 'content',
        })),
    on(SessionActions.addCardstoBoardSuccess, (state, { tasks }) => ({
        ...state,
        current: {
            ...state.current,
            tasks: [...state.current.tasks.map(task => {
                let task2 = tasks.find(newTask => newTask._id === task._id);
                return task2 ? { ...task, ...task2}: task
            })],
        },
    })),
    on(SessionActions.sendInvitations, state => ({
        ...state,
        error: null,
    })),
    on(SessionActions.sendInvitationsSuccess, (state, { sessionDetail }) => ({
        ...state,
        current: {
            ...state.current,
            detail: sessionDetail,
        },
    })),
    on(SessionActions.sendInvitationsFailure, (state, { error }) => ({
        ...state,
        error,
        current: { ...state.current, status: 'planned' },
    })),
    on(SessionActions.addSessionSuccess, (state, { session }) => ({
        ...state,
        current: session,
    })),
    // ! 'status' to 'stepperPosition' mapping,
    on(SessionActions.updateParticipantsSuccess, (state, { participants }) => ({
        ...state,
        current: {
            ...state.current,
            participants: participants,
        },
        stepperPosition: 'invitation',
    })),
    on(SessionActions.updateGuestsSuccess, (state, { guests }) => ({
        ...state,
        current: {
            ...state.current,
            guests: guests,
        },
        stepperPosition: 'invitation',
    })),
    on(SessionActions.updateStatusSuccess, (state, { status }) => ({
        ...state,
        current: {
            ...state.current,
            status,
        },
        // TODO more conditions if we want to handle all values of 'status'
        stepperPosition:
            status === 'planned'
                ? 'animation'
                : status === 'animated'
                    ? 'monitoring'
                    : status === 'monitored'
                        ? 'closing'
                        : status === 'closed'
                            ? 'closing'
                            : 'content',
    })),
    on(SessionActions.updateSessionUsers, (state, { users }) => ({
        ...state,
        current: {
            ...state.current,
            users: users,
        },
    })),
    on(SessionActions.updateSessionDetailsSuccess, (state, { session }) => ({
        ...state,
        current: {
            ...state.current,
            name: session.name,
            description: session.description,
            startDate: session.startDate,
            endDate: session.endDate,
        },
    })),
    on(
        SessionActions.updateParticipantsAndSendInvitationSuccess,
        (state, { participants }) => ({
            ...state,
            current: {
                ...state.current,
                participants: participants,
            },
        })
    ),
    on(
        SessionActions.updateGuestsAndSendInvitationSuccess,
        (state, { guests }) => ({
            ...state,
            current: {
                ...state.current,
                guests: guests,
            },
        })
    ),
    on(
        SessionActions.updateSessionActionPlanSuccess,
        SessionActions.updateSessionActionPlanSuccessViaSocket,
        (state, { actionPlan }) => ({
            ...state,
            current: {
                ...state.current,
                tasks: [
                    ...state.current.tasks.map(task => {
                        if (task._id === actionPlan._id) {
                            task = actionPlan;
                        }
                        return task;
                    }),
                ],
            },
        })
    ),
    on(SessionActions.loadSessionVersionsSuccess, (state, { versions }) => ({
        ...state,
        versions,
    })),
    on(SessionActions.loadSelectedProjectSuccess, (state, { project }) => ({
        ...state,
        selectedProject: project,
    })),
    on(StudioProjectStoreActions.addBoardByIdSuccess, (state, { board }) => ({
        ...state,
        selectedProject: {
            ...state.selectedProject,
            boards: [...state.selectedProject.boards, board],
        },
    })),
    on(WorkshopBrainstormingStoreActions.addActionPlanSuccess,
        WorkshopBrainstormingStoreActions.addActionPlanSuccessViaSocket,
        WorkshopCanvasStoreActions.addActionPlanSuccess,
        WorkshopCardboardStoreActions.addActionPlanSuccess, (state, { actionPlan }) => ({
            ...state,
            current: {
                ...state.current,
                tasks: [...state.current.tasks, actionPlan],
            },
        })),
    on(WorkshopBrainstormingStoreActions.cardsToActionplanSuccess,
        WorkshopCanvasStoreActions.cardsToActionplanSuccess, (state, { tasks }) => ({
            ...state,
            current: {
                ...state.current,
                tasks: [...state.current.tasks, ...tasks],
            },
        })),
    on(SessionActions.loadCurrent, (state, { id }) => ({
        ...state,
        current: {
            _id: id,
            users: [],
            participants: [],
            isPrivate: null,
        },
        error: null,
        stepperPosition: null,
        versions: [],
    })),
    on(SessionActions.updateSessionUsersSuccess, (state, { users }) => ({
        ...state,
        current: {
            ...state.current,
            participants: state.current.participants ? [
                ...(state.current.participants as ProjectUserModel[]).filter(_ => !users.filter(user => user.position === 'animator').map(user => user.email).includes(_.email))
            ] : []
        },
    })),
    on(SessionActions.updateSessionSettingsPrivacySuccess, (state, { isPrivate }) => ({
        ...state,
        current: {
            ...state.current,
            isPrivate
        },
    })),
    on(SessionActions.updateSessionSettingsInfoSuccess, (state, { name, description, startDate, endDate, startTime, endTime }) => ({
        ...state,
        current: {
            ...state.current,
            name,
            description,
            startDate,
            endDate,
            detail: {
                startTime,
                endTime
            }
        },
    })),
    on(WorkshopActivitiesStoreActions.toggleRoleSuccess, (state, { user, role, users, participants }) => {
        return {
            ...state,
            current: {
                ...state.current,
                users,
                participants
            },
        };
    }),
    on(WorkshopActivitiesStoreActions.addUsersByRoleSuccess, (state, { users, participants }) => {
        return {
            ...state,
            current: {
                ...state.current,
                users,
                participants
            },
        };
    }),
    on(WorkshopActivitiesStoreActions.addUsersByRoleViaSocket, (state, { users, participants }) => {
        return {
            ...state,
            current: {
                ...state.current,
                users,
                participants
            },
        };
    }),
    on(WorkshopActivitiesStoreActions.deleteUserSuccess, (state, { sessionRole, userId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                users: [...(state.current.users as ProjectUserModel[]).filter(
                    user => user._id !== userId
                )],
                participants: [...(state.current.participants as ProjectUserModel[]).filter(
                    user => user._id !== userId
                )],
            },
        };
    }),
    on(SessionActions.updateRemoveSessionTaskSuccess, SessionActions.updateRemoveSessionTaskSuccessViaSocket, (state, { taskId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                tasks: [...(state.current.tasks as SessionTaskModel[]).filter(
                    task => task._id !== taskId
                )]
            },
        };
    }),
    on(WorkshopActivitiesStoreActions.deleteUserViaSocket, (state, { sessionRole, userId }) => {
        return {
            ...state,
            current: {
                ...state.current,
                users: [...(state.current.users as ProjectUserModel[]).filter(
                    user => user._id !== userId
                )],
                participants: [...(state.current.participants as ProjectUserModel[]).filter(
                    user => user._id !== userId
                )],
            },
        };
    }),
);

export function reducer(state: State | undefined, action: Action): any {
    return sessionReducer(state, action);
}
