import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../../../../i18n/workshop/canvas/animator/board/dialogs/export-cards-dialog/en';
import { locale as frensh } from '../../../../../../i18n/workshop/canvas/animator/board/dialogs/export-cards-dialog/fr';
import { locale as arabic } from '../../../../../../i18n/workshop/canvas/animator/board/dialogs/export-cards-dialog/ar';

@Component({
    selector: 'board-export-cards-dialog',
    templateUrl: './export-cards-dialog.component.html',
    styleUrls: ['./export-cards-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BoardExportCardsDialogComponent {
    constructor(
        public matDialogRef: MatDialogRef<BoardExportCardsDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }
}
