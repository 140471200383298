export const locale = {
    lang: 'ar',
    data: {
        PRICING_DIALOG_UPDATE_ACCOUNT: 'ترقية حسابك',
        PRICING_DIALOG_PAY_MONTHLY: 'ادفع شهريا',
        PRICING_DIALOG_PAY_YEARLY: 'ادفع سنويًا',
        PRICING_DIALOG_SAVE_20:'وفر 20٪',
        PRICING_DIALOG_TEAM_HEADING:'فريق',
        PRICING_DIALOG_TEAM_SUBTITLE:'للفرق الصغيرة التي تحتاج إلى تعزيز التعاون',
        PRICING_DIALOG_TEAM_PRICING_VALUE:'5',
        PRICING_DIALOG_TEAM_PRICING_CURRENCY:'€',
        PRICING_DIALOG_TEAM_PRICING_MONTH:'ش/',
        PRICING_DIALOG_TEAM_LIST_ITEM_ONE:'مشروع واحد',
        PRICING_DIALOG_TEAM_LIST_ITEM_TWO:'10 أعضاء',
        PRICING_DIALOG_TEAM_LIST_ITEM_THREE:'ورش عمل غير محدودة',
        PRICING_DIALOG_TEAM_LIST_ITEM_FOUR:'لوحات غير محدودة',
        PRICING_DIALOG_TEAM_LIST_ITEM_FIVE:'قوالب المكتبة',
        PRICING_DIALOG_TEAM_LIST_ITEM_SIX:'إضافة اجتماعات كبيرة (50 دولارًا إضافيًا لكل 300 مشارك)',
        PRICING_DIALOG_TEAM_LIST_ITEM_SEVEN:'مساحة تخزين 2 جيجا',
        PRICING_DIALOG_TEAM_LIST_ITEM_EIGHT:'10 مشارك في ورشة عمل عامة',
        PRICING_DIALOG_TEAM_BUTTON:'ترقية',
        PRICING_DIALOG_BUSINESS_HEADING:'اعمال',
        PRICING_DIALOG_BUSINESS_SUBTITLE:'للشركات التي تحتاج إلى إدارة التعاون عبر الفرق',
        PRICING_DIALOG_BUSINESS_PRICING_VALUE:'50',
        PRICING_DIALOG_BUSINESS_PRICING_CURRENCY:'€',
        PRICING_DIALOG_BUSINESS_PRICING_MONTH:'ش/',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_ONE:'3 مشاريع',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_TWO:'عدد غير محدود من الأعضاء',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_THREE:'ورش عمل غير محدودة',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_FOUR:'لوحات غير محدودة',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_FIVE:'قوالب المكتبة',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_SIX:'إضافة اجتماعات كبيرة (50 دولارًا إضافيًا لكل 300 مشارك)',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_SEVEN:'10 جيجا بايت مساحة تخزين',
        PRICING_DIALOG_BUSINESS_LIST_ITEM_EIGHT:'10 مشارك في ورشة عمل عامة',
        PRICING_DIALOG_BUSINESS_BUTTON:'ترقية',
        PRICING_DIALOG_ENTREPRISE_HEADING:'مؤسسة',
        PRICING_DIALOG_ENTREPRISE_SUBTITLE:'للمنظمات التي تحتاج إلى التحول على نطاق واسع ولها متطلبات خاصة',
        PRICING_DIALOG_ENTREPRISE_CUSTOM_PRICING:'سعر مخصص',
        PRICING_DIALOG_ENTREPRISE_PRICING_CURRENCY:'€',
        PRICING_DIALOG_ENTREPRISE_PRICING_MONTH:'ش/',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_ONE:'وصول غير محدود إلى جميع الميزات',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_TWO:'تسجيل دخول فردي فريد (SAML / oAuth2)',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_THREE:'مدير مخصص',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_FOUR:'الأمن المتقدم',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_FIVE:'المجال المدار',
        PRICING_DIALOG_ENTREPRISE_BUTTON:'اتصل بنا',
        PRICING_DIALOG_NOTICE:'الأسعار المعروضة لا تشمل أي ضرائب مبيعات سارية مثل ضريبة القيمة المضافة.',
        PRICING_DIALOG_TEAM_BUTTON_SELECTED: 'الحالي',
        PRICING_DIALOG_TEAM_BUTTON_CHOOSE:  'إختيار',
        PRICING_DIALOG_PLAN_METADATA_ONE_NAME : 'فريق',
        PRICING_DIALOG_PLAN_METADATA_ONE_PLAN_DESCRIPTION : 'للفرق الصغيرة لتعزيز تعاونهم وسير العمل',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_ONE : 'مشروع واحد',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_TWO : 'عدد المستخدمين : 10 مستخدمين',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_THREE : '25 جيجا بايت تخزين',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_FOUR : 'ورشات عمل غير محدودة',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_FIVE : 'لوحات غير محدودة',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_SIX : 'مكتبة القوالب',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_SEVEN : 'إضافة اجتماعات كبيرة (50 € إضافيًا لكل 100 مشارك)',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_EIGHT : '10 مشاركين مجانيين في ورش العمل العامة',
        PRICING_DIALOG_PLAN_METADATA_TWO_NAME : 'اعمال',
        PRICING_DIALOG_PLAN_METADATA_TWO_PLAN_DESCRIPTION : 'للفرق لتمكين التعاون بين الفرق',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_ONE : '3 مشاريع',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_TWO : 'عدد المستخدمين : غير محدود',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_THREE : '25 جيجا بايت تخزين',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_FOUR : 'ورشات عمل غير محدودة',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_FIVE : 'لوحات غير محدودة',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_SIX : 'مكتبة القوالب',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_SEVEN : 'إضافة اجتماعات كبيرة (50 € إضافيًا لكل 100 مشارك)',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_EIGHT : '10 مشاركين مجانيين في ورش العمل العامة',
    }
};
