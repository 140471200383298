import { Observable } from 'rxjs';
import { RootStoreState } from 'app/root-store';
import { select, Store } from '@ngrx/store';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AccessStoreActions, AccessStoreSelectors } from 'app/root-store/access-store';

import { locale as english } from '../../../i18n/access/dialogs/pricing-plans/en';
import { locale as arabic } from '../../../i18n/access/dialogs/pricing-plans/ar';
import { locale as frensh } from '../../../i18n/access/dialogs/pricing-plans/fr';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginStoreSelectors } from 'app/root-store/login-store';


@Component({
  selector: 'access-pricing-plans-dialog',
  templateUrl: './pricing-plans.component.html',
  styleUrls: ['./pricing-plans.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PricingPlansComponent implements OnInit {
    plans$: Observable<any[]>;
    isYearlyPlan : boolean;
    plans: any[];
    yearlyPlans: any[];
    monthlyPlans: any[];
    tenant$: Observable<any>;
    tenant: any;

    constructor(
        public translateService: TranslateService,
        public dialogRef: MatDialogRef<PricingPlansComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        private _store: Store<RootStoreState.State>,
    ) {
        this.translationLoaderService.loadTranslations(english, arabic, frensh);
    }

    check() {
        if(this.isYearlyPlan){
            this.plans = this.monthlyPlans ; 
        }else{
            this.plans = this.yearlyPlans ;
        }
    }

    ngOnInit(): void {
        this._store.dispatch(AccessStoreActions.getPlans());
        this.plans$ = this._store.pipe(select(AccessStoreSelectors.selectYearlyPlans));
        this.plans$.subscribe(plans => {
            this.yearlyPlans = plans ;
        }) ;
        this.plans$ = this._store.pipe(select(AccessStoreSelectors.selectPlans));
        this.plans$.subscribe(plans => {
            this.monthlyPlans = plans ;
            this.plans = plans ;
        });
        this.tenant$ = this._store.pipe(select(LoginStoreSelectors.selectLoginTenant));
        this.tenant$.subscribe(tenant => {
            this.tenant = tenant;
        });
        this.check() ;
    }

    selectPlanOne(): void {
        if(this.tenant == 'excelway'){
            if(this.isYearlyPlan){
                this._store.dispatch(AccessStoreActions.checkout({
                    id: 'price_1KgWT9IASQYBUqwR0d5dcxyx'
                }));
            }else{
                this._store.dispatch(AccessStoreActions.checkout({
                    id: 'price_1KtwZMIASQYBUqwRvO2ahfFQ'
                }));
            }
        }else{
            if(this.isYearlyPlan){
                this._store.dispatch(AccessStoreActions.checkout({
                    id: 'price_1KqzQtIASQYBUqwR7ei0QQBX'
                }));
            }else{
                this._store.dispatch(AccessStoreActions.checkout({
                    id: 'price_1KfnsCIASQYBUqwRQgNhONAa'
                }));
            }
        }
    }

    selectPlanTwo(): void {
        if(this.tenant == 'excelway'){
            if(this.isYearlyPlan){
                this._store.dispatch(AccessStoreActions.checkout({
                    id: 'price_1KgWSLIASQYBUqwRkTMYloWe'
                }));
            }else{
                this._store.dispatch(AccessStoreActions.checkout({
                    id: 'price_1KgWSLIASQYBUqwRzvAKDUy6'
                }));
            }
        }else{
            if(this.isYearlyPlan){
                this._store.dispatch(AccessStoreActions.checkout({
                    id: 'price_1KfnuZIASQYBUqwRC8o1sMJn'
                }));
            }else{
                this._store.dispatch(AccessStoreActions.checkout({
                    id: 'price_1KfnuZIASQYBUqwRujqtDF9J'
                }));
            }
        }
    }
}
