import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';

export const selectSessionsState = createFeatureSelector<State>('studio-sessions');

export const selectSessions = createSelector(
    selectSessionsState,
    (state: State): ProjectSessionModel[] => state.filtredSessions
);

export const selectPendingReportToken = createSelector(
    selectSessionsState,
    (state: State): string => state.pendingReportToken
);
