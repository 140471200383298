import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(private _http: HttpClient) {}

    getUsers(): Observable<any> {
        return this._http.get(
            `${environment.http.url}${environment.http.user}`
        );
    }

    updateGeneralInfo(_id: string, user): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.userInformations}/${_id}`,
            user
        );
    }

    updateOccupation(_id: string, user): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.userOccupation}/${_id}`,
            user
        );
    }

    updateContact(_id: string, user): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.userContact}/${_id}`,
            user
        );
    }

    updateUserLang(_id: string, lang: string): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.userLang}/${_id}`,
            {
                lang
            }
        );
    }

    minioUpload(file: any, name): Observable<any> {
        return this._http.post(
            `${environment.minio.url}/storage/o/public/` + name,
            file
        );
    }

    updateUserProfilePicture(_id: string, profilePic: string): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.userProfilePic}/${_id}`,
            {
                profilePic
            }
        );
    }

    updateUserPassword(email: string, oldPassword: string, newPassword: string) {
        return this._http.patch(
            `${environment.http.url}${environment.http.userChangePasword}`,
            {
                email, oldPassword, newPassword
            }
        );
    }

    updateUserNotice(notice: any, id: any): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.userNotice}/${id}`,
            notice
        )
    }
    
    getInAppNotifications(PageNumber: number) {
        return this._http.get(
            `${environment.http.url}${environment.http.userInAppNotifications}/${PageNumber}`
        );
    }

    readInAppNotifications(id: String) {
        return this._http.patch(
            `${environment.http.url}${environment.http.readInAppNotification}/${id}`, {
                read: true
            }
        );
    }

    updateTimeZone(timeZone: String, userId: string) {
        return this._http.patch(
            `${environment.http.url}${environment.http.userTimezone}/${userId}`, {
                timeZone
            }
        );
    }
}
