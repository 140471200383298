export const locale = {
    lang: 'ar',
    data: {
        SELECT: 'حدّد المحتوى',
        CONTENT_ID: 'معرف المحتوى',
        CONTENT_NAME: 'اسم المحتوى',
        UPLOAD: 'استيراد',
        SAVE: 'حِفْظْ',
        CANCEL: 'إلغاء',
        FILTER: 'فَلْتَرْ',
    }
};
