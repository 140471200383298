export const locale = {
    lang: 'ar',
    data: {
        START: 'ابدأ العصف الذهني',
        SELECT: 'اختر لون واحدة أو أكثر من البطاقات لهذه العصف الذهني',
        COLOR: 'الألوان مصنوعة من لون البطاقة والملصق الذي تحدده',
        ALL_CATEGORIES: 'جميع الفئات',
        CARD_CATEGORIES: 'الألوان',
        CANCEL: 'إلغاء',
    }
};
