import { StripeEffectsEffects } from './stripe-effects';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { AccessStoreEffects } from './effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('access', reducer),
        EffectsModule.forFeature([AccessStoreEffects, StripeEffectsEffects]),
    ],
    providers: [],
})
export class AccessStoreModule { }
