import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { StudioNotificationsStoreActions } from 'app/root-store/studio-store/notifications-store';
import { locale as english } from '../../../../../i18n/layout/components/quick-panel/dialogs/new-action-dialog/en';
import { locale as frensh } from '../../../../../i18n/layout/components/quick-panel/dialogs/new-action-dialog/fr';
import { locale as arabic } from '../../../../../i18n/layout/components/quick-panel/dialogs/new-action-dialog/ar';

@Component({
    selector: 'new-action-dialog',
    templateUrl: 'new-action-dialog.component.html',
    styleUrls: ['new-action-dialog.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class NewActionDialogComponent implements OnInit {
    eventForm: UntypedFormGroup;
    taskInfo: any;
    responsible: string;

    constructor(
        public matDialogRef: MatDialogRef<NewActionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _store: Store<RootStoreState.State>,
        private snackBar: MatSnackBar,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.responsible = this._data.task.responsible;
        this.eventForm = this.createEventForm();
        if (this._data.isRead === false) {
            this._store.dispatch(
                StudioNotificationsStoreActions.updateIsRead({
                    boardId: this._data.boardId,
                    standbyCardId: this._data._id,
                })
            );
        }
    }

    createEventForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            name: new UntypedFormControl(
                { value: this._data.task.name, disabled: true },
                Validators.required
            ),
            responsible: new UntypedFormControl(
                { value: this._data.task.responsible, disabled: true },
                Validators.required
            ),
            endDate: new UntypedFormControl({
                value: this._data.task.endDate,
                disabled: true,
            }),
            description: new UntypedFormControl({
                value: this._data.task.description,
                disabled: true,
            }),
            comment: new UntypedFormControl({
                value: this._data.comment,
                disabled: true,
            }),
        });
    }

    acceptAction(): void {
        this._store.dispatch(
            StudioNotificationsStoreActions.updateStatus({
                standbyCardId: this._data._id,
                status: 'accepted',
            })
        );
    }

    refuseAction(): void {
        this._store.dispatch(
            StudioNotificationsStoreActions.updateStatus({
                standbyCardId: this._data._id,
                status: 'refused',
            })
        );
        this.snackBar.open(
            this.translateService.currentLang==='fr' ? 'Cette action a été refusée' : 
            this.translateService.currentLang==='en' ?  'This action was denied' : 'تم رفض هذا الإجراء',
            this.translateService.currentLang==='fr' ? 'Fermer' : 
            this.translateService.currentLang==='en' ?  'Close' : 'أغلق' , {
            duration: 3000,
        });
    }
}
