import { createAction, props } from '@ngrx/store';

import { DocumentModel } from '@bsuccess/models/project/document.model';

export const addDocument = createAction('[Studio][Project Document Tab] Add Document');

export const addDocumentConfirmed = createAction(
    '[Studio][Document Dialog] Add Document Confirmed',
    props<{ document: DocumentModel }>()
);

export const addDocumentCancelled = createAction(
    '[Studio][Document Dialog] Add Document Cancelled'
);

export const addDocumentSuccess = createAction(
    '[Studio][Document API] Add Document Success',
    props<{ document: DocumentModel }>()
);

export const addDocumentFailure = createAction(
    '[Studio][Document API] Add Document Failure',
    props<{ error: string }>()
);

export const updateDocument = createAction(
    '[Studio][Project Document Tab] Update Document',
    props<{ document: DocumentModel }>()
);

export const updateDocumentConfirmed = createAction(
    '[Studio][Document Dialog] Update Document Confirmed',
    props<{ document: DocumentModel }>()
);

export const updateDocumentCancelled = createAction(
    '[Studio][Document Dialog] Update Document Cancelled'
);

export const updateDocumentSuccess = createAction(
    '[Studio][Document API] Update Document Success',
    props<{ document: DocumentModel }>()
);

export const updateDocumentFailure = createAction(
    '[Studio][Document API] Update Document Failure',
    props<{ error: string }>()
);

export const deleteDocument = createAction(
    '[Studio][Project Document Tab] Delete Document',
    props<{ id: string; attachmentId: string }>()
);

export const deleteDocumentConfirmed = createAction(
    '[Studio][Document Dialog] Delete Document Confirmed',
    props<{ id: string; attachmentId: string }>()
);

export const deleteDocumentCancelled = createAction(
    '[Studio][Document Dialog] Delete Document Cancelled'
);

export const deleteDocumentSuccess = createAction(
    '[Studio][Document API] Delete Document Success',
    props<{ id: string; attachmentId: string }>()
);

export const deleteDocumentFailure = createAction(
    '[Studio][Document API] Delete Document Failure',
    props<{ error: string }>()
);

export const uploadDocument = createAction(
    '[Studio][Document Dialog] Upload Document',
    props<{ content: any; name: string }>()
);

export const uploadDocumentSuccess = createAction(
    '[Studio][Document API] Upload Document Success',
    props<{ response: any; name: string }>()
);

export const uploadDocumentFailure = createAction(
    '[Studio][Document API] Upload Document Failure',
    props<{ error: string }>()
);

export const downloadDocument = createAction(
    '[Studio][Project Document Tab] Download Document',
    props<{ document: DocumentModel }>()
);

export const downloadDocumentSuccess = createAction(
    '[Studio][Document API] Download Document Success',
    props<{ response: any }>()
);

export const downloadDocumentFailure = createAction(
    '[Studio][Document API] Download Document Failure',
    props<{ error: string }>()
);

export const readDocument = createAction(
    '[Studio][Project Document Tab] Read Document',
    props<{ document: DocumentModel }>()
);

export const deleteAttachment = createAction(
    '[Studio][Attachment API] Delete Attachment',
    props<{ id: string }>()
);

export const deleteAttachmentSuccess = createAction(
    '[Studio][Attachment API] Delete Attachment Success',
    props<{ response: any }>()
);

export const deleteAttachmentFailure = createAction(
    '[Studio][Attachment API] Delete Attachment Failure',
    props<{ error: string }>()
);
