import { CanLoad, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { RootStoreState } from 'app/root-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { NavbarStoreSelectors } from 'app/root-store/navbar-store';

@Injectable({
    providedIn: 'root',
})
export class LoginGuard implements CanLoad, CanActivate {
    constructor(
        private _authService: AuthService,
        private _store: Store<RootStoreState.State>,
        private _router: Router
    ) { }

    canLoad(): Observable<boolean> {
        return this.isNotAuthenticated();
    }

    canActivate(): Observable<boolean> {
        return this.isNotAuthenticated();
    }

    private isNotAuthenticated(): Observable<boolean> {
        return this._store.select(LoginStoreSelectors.selectLoginToken).pipe(
            withLatestFrom(this._store.select(NavbarStoreSelectors.selectProduct)),
            withLatestFrom(this._store.select(LoginStoreSelectors.selectLoginSessionKey)),
            take(1),
            map(([[token, product], sessionKey]) => {
                if (
                    this._authService.isTokenExpired(token)
                ) {
                    return true;
                } else {
                    if (product === 'workshop' && sessionKey) {
                        this._router.navigate(['workshop/activities']);
                    } else if (product === 'notes') {
                        this._router.navigate(['workshop/notes']);
                    } else {
                        this._router.navigate(['studio/projects']);
                    }
                    return false;
                }
            }));
    }
}
