import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';
import { RootStoreState } from 'app/root-store';
import { StudioProjectStoreActions, StudioProjectStoreSelectors } from 'app/root-store/studio-store/project-store';
import { BoardNewDialogComponent } from '../board-new-dialog.component';
import { locale as english } from '../../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/board-new-general-tab/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/board-new-general-tab/fr';
import { locale as arabic } from '../../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/board-new-general-tab/ar';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { Observable } from 'rxjs';
import { E } from '@angular/cdk/keycodes';

@Component({
    selector: 'board-new-general-tab',
    templateUrl: './board-new-general-tab.component.html',
    styleUrls: ['./board-new-general-tab.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})

export class BoardNewGeneralTabComponent implements OnInit {
    newBoardForm: UntypedFormGroup;
    board: ProjectBoardModel;
    currentProject$: Observable<ProjectModel>;
    currentProject: ProjectModel;
    membersCanSeeOthersCards = 'can';

    constructor(
        public dialogRef: MatDialogRef<BoardNewDialogComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        //console.log(this._data);
        this.newBoardForm = this.createNewBoardForm();
        this.newBoardForm.controls['name'].setValue(this._data.name);
        this.newBoardForm.controls['description'].setValue(this._data.description);

        this.currentProject$ = this._store.pipe(
            select(StudioProjectStoreSelectors.selectCurrent)
        );

        this.currentProject$.subscribe(_ => {
            this.currentProject = _;
        })
    }

    createNewBoardForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            name: new UntypedFormControl(''),
            description: new UntypedFormControl(''),
        });
    }

    createBoard(): void {
        if (this._data.id === 'blank') {
            this.board = {
                name: this.newBoardForm.value.name,
                description: this.newBoardForm.value.description,
            };
            this._store.dispatch(
                StudioProjectStoreActions.addBoard({
                    board: this.board,
                    enableBoardPrivacy: this.membersCanSeeOthersCards === 'can' ? true : false
                })
            );
        } else {
            this._store.dispatch(StudioProjectStoreActions.projectAddBoardTemplate(
                {
                    projectId: this.currentProject._id,
                    boardTemplateId: this._data.id,
                    name: this.newBoardForm.value?.name,
                    description: this.newBoardForm.value?.description,
                    enableBoardPrivacy: this.membersCanSeeOthersCards === 'cant' ? true : false
                }
            ));
        }
    }
}
