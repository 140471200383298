import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';

import { environment } from 'environments/environment';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';

@Injectable({
    providedIn: 'root',
})
export class ActionPlanService {
    constructor(private _httpClient: HttpClient) { }

    add(actionPlan: SessionTaskModel, sessionKey: string): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectSessionActionplan}` +
            '/' +
            sessionKey,
            actionPlan
        );
    }

    addNewTasks(token: string, tasks: SessionTaskModel[]): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectSessionActionplans}/${token}`,
            tasks
        );
    }

    updateSessionTask(projectId: string, task: SessionTaskModel): Observable<any> {
        return this._httpClient.put(
            `${environment.http.url}${environment.http.projectSessionActionplan}` +
            '/' + projectId + '/' + task._id,
            JSON.stringify(task)
        );
    }
}
