export const locale = {
    lang: 'fr',
    data: {
        MANAGE_MEMBERS: 'Inviter des membres',
        // tslint:disable-next-line: max-line-length
        PARAGRAPH: 'Désignez un animateur d\'atelier et invitez des collègues pour vous aider à organiser l\'atelier. S\'ils sont également participants, ils disposeront des droits pour rédiger le compte rendu de réunion.',
        INCLUDE_PARTICIPANT: 'Inclure comme participant?',
        MEMBERS: 'Membre',
        THE_EMAIL: 'Email',
        POSITION: 'Position',
        SPONSOR: 'Sponsor',
        ADMIN: 'Admin',
        ADD_USER_BUTTON_LABEL: 'Ajouter un utilisateur',
        RESPONSIBLE: 'Responsable',
        ANIMATOR: 'Animateur',
        ROLE: 'Rôle',
        READING: 'Lecture',
        MODIFICATION: 'Modification',
        REMOVE: 'Retirer',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
        PARTICIPANT: 'Participant',
    }
};
