export const locale = {
    lang: 'en',
    data: {
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_EDIT_FIELD: 'Edit Field',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_NAME: 'Field Name',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_TYPE: 'Field Type',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_MULTIPLE: 'Multiple Select',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_TEXT: 'Multiple select allows you to select one or more predefined options listed below.',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_ADD_OPTION: 'Add an option',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_CANCEL: 'Cancel',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_SAVE: 'Save',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_CATEGORIES: 'Tags',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_ADD: 'Add',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_EDIT_CATEGORIES: 'Edit tag',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_ADD_CATEGORIES: 'Add tag',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_DELETE_CATEGORY: 'Delete tag',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_NAME: 'Name',
    }
};
