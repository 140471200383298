export const locale = {
    lang: 'en',
    data: {
        NEW: 'New Action',
        TITLE: 'Title',
        DUE_DATE: 'Due date',
        COMMENT: 'Comment',
        RESPONSIBLE: 'Owner',
        DESCRIPTION: 'Description',
        ACCEPT: 'Accept',
        REFUSE: 'Refuse',
    }
};
