import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as ProjectsActions from './actions';
import * as _ from 'lodash-es';
import { ProjectsService } from '@bsuccess/services/projects.service';

const projectsReducer = createReducer(
    initialState,
    on(ProjectsActions.loadSuccess, (state, { response }) => ({
        ...state,
        favorites: response.favorites,
        others: response.others,
        filteredOthers: response.others,
        filteredFavorites: response.favorites
    })),
    on(ProjectsActions.addFavoriteSuccess, (state, { id }) => {
        const favorite = state.others.find(project => project._id === id);
        return {
            ...state,
            favorites: [favorite, ...state.favorites],
            others: state.others.filter(project => project._id !== id),
            filteredFavorites: [favorite, ...state.favorites],
            filteredOthers: state.others.filter(project => project._id !== id),
        };
    }),
    on(ProjectsActions.searchProject, (state, { text }) => {
        return {
            ...state,
            filteredFavorites:
                text.toLowerCase() === ''
                    ? state.favorites || state.favorites
                    : state.favorites.filter(
                        favoriteProject => {
                            if (favoriteProject.name.toLowerCase().includes(text.toLowerCase())) {
                                return favoriteProject;
                            }
                        }
                    ),
            filteredOthers:
                text.toLowerCase() === ''
                    ? state.others || state.others
                    : state.others.filter(
                        otherProject => {
                            if (otherProject.name.toLowerCase().includes(text.toLowerCase())) {
                                return otherProject;
                            }
                        }
                    ),
        };
    }),
    on(ProjectsActions.removeFavoriteSuccess, (state, { id }) => {
        const favorite = state.favorites.find(project => project._id === id);
        return {
            ...state,
            others: [favorite, ...state.others],
            favorites: state.favorites.filter(project => project._id !== id),
            filteredOthers: [favorite, ...state.others],
            filteredFavorites: state.favorites.filter(project => project._id !== id)
        };
    }),
    on(ProjectsActions.loadProjectsTreeSuccess, (state, { response }) => {
        return {
            ...state,
            projectsTree: response
        }
    }),
    on(ProjectsActions.loadDailyySummaries, state => {
        return {
            ...state,
            summaries: null
        }
    }),
    on(ProjectsActions.loadWeeklySummaries, state => {
        return {
            ...state,
            summaries: null
        }
    }),
    on(ProjectsActions.loadDailyySummariesSuccess, (state, { response }) => {
        return {
            ...state,
            summaries: response ? { allSummaries : [response.delayedActions , response.imminentActions , response.todayTerminatedActions , response.inProgressActions] , summaries : response } : { allSummaries : [] , summaries :  []}
        }
    }),
    on(ProjectsActions.loadWeeklySummariesSuccess, (state, { response }) => {
        return {
            ...state,
            summaries: response ? { allSummaries : [response.thisWeekTerminatedActions , response.nextWeekActions , response.delayedActions , response.inProgressActions] , summaries : response } : { allSummaries : [] , summaries :  []}
        }
    }),
    on(
        ProjectsActions.filterSummaries,
        (
            state,
            { projectId, dueDate, memberId , isReset }
        ) => {
            return {
                ...state,
                summaries: ProjectsService.filterSummaries(
                        state.summaries,
                        projectId,
                        dueDate,
                        memberId,
                        isReset,
                ),
            };
        }
    ),
);

export function reducer(state: State | undefined, action: Action): any {
    return projectsReducer(state, action);
}
