import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Socket } from 'ngx-socket-io';
import { TranslateService } from '@ngx-translate/core';
import { withLatestFrom, map } from 'rxjs/operators';

import * as SocketIOActions from '../actions';
import { TimerModel } from '@bsuccess/models/socket-io/timer.model';
import { NotificationService } from '@bsuccess/services/notification.service';
import { RootStoreState, RootStoreSelectors } from 'app/root-store';
import { WorkshopTimerStoreActions, WorkshopTimerStoreSelectors } from 'app/root-store/workshop-store/timer-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { WorkshopBrainstormingStoreSelectors } from '../../brainstorming-store';
import { WorkshopCanvasStoreSelectors } from '../../canvas-store';
import { WorkshopCardRatingStoreActions } from '../../cardrating-store';

@Injectable()
export class WorkshopSocketIOStoreTimerEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _socket: Socket,
        private _notificationService: NotificationService,
        private translate: TranslateService,
    ) { }

    // Catch animator start timer and emit it
    animatorStartTimer$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopTimerStoreActions.start),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectSessionCategories)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopBrainstormingStoreSelectors.selectAllCategoriesChecked)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopCanvasStoreSelectors.selectAllCategoriesChecked)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopTimerStoreSelectors.selectContentId)
                    )
                ),
                map(([[[[[action, [sessionRole, sessionKey]], categories], allBrainstormingCategoriesChecked], allCanvasCategoriesChecked], contentId]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateTimer({
                                payload: {
                                    sessionKey,
                                    type: 'start',
                                    value: action.value,
                                    exercise: action.exercise,
                                    categories: allBrainstormingCategoriesChecked || allCanvasCategoriesChecked ? categories : categories.filter(_ => _.checked === true),
                                    balance: action.balance,
                                    maxPerCard: action.maxPerCard,
                                    contentId
                                },
                            })
                        );
                        
                        this._store.dispatch(
                            WorkshopCardRatingStoreActions.updateBalance({
                                balance: action.balance,
                            })
                        );
                        this._store.dispatch(
                            WorkshopCardRatingStoreActions.updateMaxPerCard({
                                max: action.maxPerCard,
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    // Catch animator pause timer and emit it
    animatorPauseTimer$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopTimerStoreActions.pause),
                withLatestFrom(
                    this._store.pipe(select(WorkshopTimerStoreSelectors.selectValue))
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectSessionCategories)
                    )
                ),                
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopTimerStoreSelectors.selectContentId)
                    )
                ),
                map(([[[[action, value], [sessionRole, sessionKey]], categories], contentId]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateTimer({
                                payload: {
                                    sessionKey,
                                    type: 'pause',
                                    value,
                                    categories,
                                    maxPerCard: 0,
                                    balance: 0,
                                    contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    // Catch animator stop timer and emit it
    animatorStopTimer$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopTimerStoreActions.stop),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectSessionCategories)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopTimerStoreSelectors.selectContentId)
                    )
                ),
                map(([[[action, [sessionRole, sessionKey]], categories], contentId]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateTimer({
                                payload: {
                                    sessionKey,
                                    type: 'stop',
                                    value: 0,
                                    categories,
                                    maxPerCard: 0,
                                    balance: 0,
                                    contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    participantReceiveUpdateTimer$ = this._socket
        .fromEvent<TimerModel>(SocketIOActions.updateTimer.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
            withLatestFrom(
                this._store.pipe(select(RootStoreSelectors.selectUrl))
            )
        )
        .subscribe(([[timer, sessionRole], url]) => {
            if (sessionRole === 'participant') {
                if (timer.type === 'start') {
                    this._store.dispatch(
                        WorkshopTimerStoreActions.start({
                            value: timer.value,
                            exercise: timer.exercise,
                            categories: timer.categories,
                            balance: timer.balance,
                            maxPerCard: timer.maxPerCard
                        })
                    );
                    if (url === '/workshop/cardboard') {
                        this._notificationService.showSuccess(
                            this.translate.currentLang.toString() === 'fr' ?
                                'Veuillez sélectionner une carte pour voter.' :
                                this.translate.currentLang.toString() === 'en' ?
                                'Please select a card to vote.' : 
                                'الرجاء تحديد بطاقة للتصويت.'
                        );
                    }
                } else if (timer.type === 'stop') {
                    this._store.dispatch(WorkshopTimerStoreActions.stop());
                } else if (timer.type === 'pause') {
                    this._store.dispatch(WorkshopTimerStoreActions.pause());
                }
            }
        });

    emit$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(SocketIOActions.updateTimer),
                map(action => {
                    this._socket.emit(action.type, action.payload);
                })
            ),
        { dispatch: false }
    );
}
