import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/ar';

@Component({
    selector: 'board-new-dialog',
    templateUrl: './board-new-dialog.component.html',
    styleUrls: ['./board-new-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BoardNewDialogComponent implements OnInit {
    error$: Observable<string>;

    constructor(
        public dialogRef: MatDialogRef<BoardNewDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void { }
}
