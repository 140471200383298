export const locale = {
 lang: 'fr',
 data: {
     PARTICIPANT_INFORMATIONS: 'les informations du participant',
     PARTICIPANT: 'Participant',
     ANIMATOR: 'Animateur',
     REMOVE_BUTTON_LABEL: 'Retirer le participant de cet atelier',
     CLOSE: 'Fermer',
    }
};
