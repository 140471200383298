export const locale = {
    lang: 'en',
    data: {
        EDIT_SERVICES: 'Edit services',
        FILTER: 'Filter',
        TYPE: 'Type',
        SERVICE_NAME: 'Name of the service',
        OWNER: 'Owner',
        CREADTION_DATE: 'Creation date',
        SAVE: 'Save',
        CANCEL: 'Cancel',
    }
};
