import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { exhaustMap, map, catchError, withLatestFrom, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { saveAs as importedSaveAs } from 'file-saver-es';
import { format } from 'date-fns';

import * as SessionsActions from './actions';
import { SessionsService } from '@bsuccess/services/sessions.service';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { ReportService } from '@bsuccess/services/report.service';
import { RootStoreState } from 'app/root-store';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class StudioSessionsStoreEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _sessionsService: SessionsService,
        private _reportService: ReportService,
        private _actions$: Actions,
        private translate: TranslateService,
    ) { }

    load$ = createEffect(() =>
        this._actions$.pipe(
            ofType(SessionsActions.load),
            exhaustMap(_ => {
                return this._sessionsService.getSessions().pipe(
                    map(response => SessionsActions.loadSuccess({ response })),
                    catchError(error =>
                        of(
                            SessionsActions.loadFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    getGlobalReport$ = createEffect(() =>
        this._actions$.pipe(
            ofType(SessionsActions.getGlobalReport),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginToken))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginTenant))
            ),
            exhaustMap(([[_, authToken], tenant]) => {
                return this._reportService.getGlobalReport(authToken, _.token, tenant, this.translate.currentLang).pipe(
                    map(response => {
                        return SessionsActions.getGlobalReportSuccess({
                            report: response,
                        });
                    }),
                    catchError(error =>
                        of(
                            SessionsActions.getGlobalReportFailure({
                                error,
                            })
                        )
                    )
                );
            })
        )
    );

    getGlobalReportSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(SessionsActions.getGlobalReportSuccess),
                tap(_ => {
                    importedSaveAs(_.report, 'RAPPORT_GLOBAL_' + format(new Date(), 'MMddyyyy_HH:mm:ss'));
                })
            ),
        { dispatch: false }
    );
}
