export const locale = {
    lang: 'fr',
    data: {
        SESSION_NEW_DIALOG_NEW_GENERAL_SESSION_NAME: 'Nom de L\'atelier',
        SESSION_NEW_DIALOG_NEW_GENERAL_DESCRIPTION: 'Description',
        SESSION_NEW_DIALOG_NEW_GENERAL_OBLIGATORY: 'Atelier avec compte Excelway obligatoire',
        // tslint:disable-next-line: max-line-length
        SESSION_NEW_DIALOG_NEW_GENERAL_PARTICIPANTS_WITH_ACCOUNT: 'Les participants qui ne disposent pas déjà d’un compte Excelway devront en créer un avant d’accéder à l\'atelier. Ils pourront ainsi avoir accès aux projets Excelway après l\'atelier. ',
        SESSION_NEW_DIALOG_NEW_GENERAL_WITHOUT: 'Atelier sans compte Excelway',
        // tslint:disable-next-line: max-line-length
        SESSION_NEW_DIALOG_NEW_GENERAL_PARTICIPANTS_WITHOUT_ACCOUNT: 'Les participants invités et toute personne disposant du lien peut accéder à l\'atelier sans compte Excelway. Ils n’auront pas accès aux projets Excelway après l\'atelier. ',
        SESSION_NEW_DIALOG_NEW_GENERAL_START_DATE: 'Date de début',
        SESSION_NEW_DIALOG_NEW_GENERAL_END_DATE: 'Date de fin',
        SESSION_NEW_DIALOG_NEW_GENERAL_SAVE: 'Enregistrer',
        SESSION_NEW_DIALOG_NEW_GENERAL_CANCEL: 'Annuler',
        SESSION_NEW_DIALOG_NEW_GENERAL_ADD_TIME:'Ajouter horaire',
        SESSION_NEW_DIALOG_NEW_GENERAL_START_TIME:'Heure de début',
        SESSION_NEW_DIALOG_NEW_GENERAL_END_TIME:'Heure de fin',
        VALIDATORS_START_DATE_REQUIRED: 'La date de début est obligatoire.' ,
        VALIDATORS_END_DATE_REQUIRED: 'La date de fin est obligatoire.' ,  
        VALIDATORS_END_DATE_BEFORE: 'La date de début est postérieure à la date de fin' ,
        VALIDATORS_INVALID_START_TIME: 'L\'heure de début est invalide' ,
        VALIDATORS_INVALID_END_TIME: 'L\'heure de fin est invalide' ,  
        VALIDATORS_END_TIME_BEFORE: 'L\'heure de début est supérieure à l\'heure de fin' , 
    }
};
