import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as uuid from 'uuid';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { LevelModel } from '@bsuccess/models/project/level.model';
import {
    StudioProjectStoreActions,
    StudioProjectStoreSelectors,
} from 'app/root-store/studio-store/project-store';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/project-update-sub-projects-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/project-update-sub-projects-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/project-update-sub-projects-dialog/ar';

@Component({
    selector: 'project-update-sub-projects-dialog',
    templateUrl: './project-update-sub-projects-dialog.component.html',
    styleUrls: ['./project-update-sub-projects-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProjectUpdateSubProjectsDialogComponent implements OnInit, OnDestroy {
    levels: LevelModel[];
    error: string;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public dialogRef: MatDialogRef<ProjectUpdateSubProjectsDialogComponent>,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this._unsubscribeAll = new Subject();
        this.dialogRef.disableClose = true;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this._store
            .pipe(
                takeUntil(this._unsubscribeAll),
                select(StudioProjectStoreSelectors.selectProjectLevels))
            .subscribe((levels: LevelModel[]) => {
                if (this.levels) {
                    this.dialogRef.close();
                } else {
                    this.levels = [...levels];
                }
            });
        this._store
            .pipe(
                takeUntil(this._unsubscribeAll),
                select(StudioProjectStoreSelectors.selectLevelProjectFound))
            .subscribe((level: { id: string; exists: boolean }) => {
                if (level) {
                    if (level.exists === true) {
                        this.error =
                            'Ce niveau contient un ou plusieurs projets.';
                        setTimeout(() => {
                            this.error = null;
                        }, 3000);
                    } else {
                        this.levels = this.levels.filter(
                            l => l._id !== level.id
                        );
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    add(): void {
        this.levels.push({
            _id: uuid.v4(),
            rank: this.levels.length + 1,
            label: '',
        });
    }

    updateLabel(level: LevelModel): void {
        this.levels.map(old => {
            if (old.rank === level.rank) {
                old.label = level.label;
            }
        });
    }

    delete(level: LevelModel, index: number): void {
        if (level._id) {
            this._store.dispatch(
                StudioProjectStoreActions.deleteLevel({ levelId: level._id })
            );
        } else {
            this.levels.splice(index, 1);
        }
    }

    save(): void {
        this._store.dispatch(
            StudioProjectStoreActions.updateLevels({ levels: this.levels })
        );
    }
}
