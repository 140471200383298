import { createAction, props } from '@ngrx/store';

import { DetailModel } from '@bsuccess/models/project/detail.model';

export const addDetail = createAction('[Studio][Project Detail Tab] Add Detail');

export const confirmAddDetail = createAction(
    '[Studio][Project Detail Dialog] Confirm Add Detail',
    props<{ detail: DetailModel }>()
);

export const cancelAddDetail = createAction(
    '[Studio][Project Detail Dialog] Cancel Add Detail'
);

export const addDetailSuccess = createAction(
    '[Studio][Project API] Add Detail Success',
    props<{ detail: DetailModel }>()
);

export const addDetailFailure = createAction(
    '[Studio][Project API] Add Detail Failure',
    props<{ error: string }>()
);

export const updateDetail = createAction(
    '[Studio][Project Detail Tab] Update Detail',
    props<{ detail: DetailModel }>()
);

export const confirmUpdateDetail = createAction(
    '[Studio][Project Detail Dialog] Comfirm Update Detail',
    props<{ detail: DetailModel }>()
);

export const cancelUpdateDetail = createAction(
    '[Studio][Project Detail Dialog] Cancel Update Detail'
);

export const updateDetailSuccess = createAction(
    '[Studio][Project API] Update Detail Success',
    props<{ detail: DetailModel }>()
);

export const updateDetailFailure = createAction(
    '[Studio][Project API] Update Detail Failure',
    props<{ error: string }>()
);

export const deleteDetail = createAction(
    '[Studio][Project Detail Tab] Delete Detail',
    props<{ id: string }>()
);

export const confirmDeleteDetail = createAction(
    '[Studio][Project Detail Dialog] Comfirm Delete Detail',
    props<{ id: string }>()
);

export const cancelDeleteDetail = createAction(
    '[Studio][Project Detail Dialog] Cancel Delete Detail'
);

export const deleteDetailSuccess = createAction(
    '[Studio][Project API] Delete Detail Success',
    props<{ id: string }>()
);

export const deleteDetailFailure = createAction(
    '[Studio][Project API] Delete Detail Failure',
    props<{ error: string }>()
);
