import { createAction, props } from '@ngrx/store';

import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';

export const load = createAction('[Studio][Boards Page] Load Boards');

export const loadSuccess = createAction(
  '[Studio][Boards API] Load Boards Success',
  props<{
    response: { favorites: SubProjectModel[]; others: SubProjectModel[] };
  }>()
);

export const loadFailure = createAction(
  '[Studio][Boards API] Load Boards Failure',
  props<{ error: string }>()
);

export const addFavorite = createAction(
  '[Studio][Boards Page] Add Favorite',
  props<{ id: string }>()
);

export const addFavoriteSuccess = createAction(
  '[Studio][Boards Page] Add Favorite Success',
  props<{ id: string }>()
);

export const addFavoriteFailure = createAction(
  '[Studio][Boards Page] Add Project To Favorite Failure',
  props<{ error: string }>()
);

export const removeFavorite = createAction(
  '[Studio][Boards Page] Remove Favorite',
  props<{ id: string }>()
);

export const removeFavoriteSuccess = createAction(
  '[Studio][Boards Page] Remove Favorite Success',
  props<{ id: string }>()
);

export const removeFavoriteFailure = createAction(
  '[Studio][Boards Page] Remove Favorite Failure',
  props<{ error: string }>()
);

export const searchBoard = createAction(
  '[Studio][Boards Page] Search Board',
  props<{ text: string }>()
);
