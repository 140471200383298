export const locale = {
  lang: 'fr',
  data: {
    SELECT: 'Séléctionner un modèle',
    CREATE: "Créer l'activité",
    CANCEL: 'Annuler',
    SEARCH: 'Rechercher...',
    ALL_TEMPLATES: 'Tous les modèles',
    SALES_CUSTOMERS: 'Vente et relation client',
    PROJECT_MANAGEMENT: 'Gestion de projets',
    HUMAN_RESSOURCES: 'Ressources Humaines',
    INFORMATION_SYSTEM: 'Information system',
    BRAINSTORMING: 'Idéation',
    AGIL: 'Processus agiles',
    PRODUCT_MANAGEMENT: 'Gestion de produit',
    TEAM_BUILDING: "Renforcement d'équipe",
    PROSPECTIVE: 'Résolution de problèmes',
    STRATEGY: 'Stratégie',
    BACK_TEMPLATES: 'Retour aux modèles',
    USE_TEMPLATES: 'Utilisez ce modèle',
    BUSINESS_MODEL_CANVAS: 'Business Model Canvas',
    BUSINESS_MODEL_CANVAS_DESCRIPTION:
      'Développez une vue globale de votre entreprise pour voir clairement comment elle créé et délivre de la valeur. ',
    BUSINESS_MODEL_CANVAS_LONG_DESCRIPTION:
      'Le Business Model Canvas est une activité de planification stratégique qui permet de développer de nouveaux modèles d\'entreprise et de documenter les modèles existants. Cette activité permet d\'obtenir une vision globale de la stratégie d\'entreprise centrée sur la proposition de valeur, de façon visuelle et structurée. <br/><br/> Sur un seul document, cartographiez et alignez la proposition de valeur de votre offre, les segments clients et les relations avec eux, les canaux de communication, les partenaires, activités et ressources clefs, ainsi que la structure des coûts et les flux de revenus. <br/><br/> Le modèle ExcelWay Business Model Canvas est un dérivé de "The Business Model Canvas" de <a target="_blank" href="https://www.strategyzer.com/">Strategyzer AG</a>, utilisé sous la license <a target="_blank" href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>',
    SWOT_ANALYSIS: 'Analyse SWOT',
    SWOT_ANALYSIS_DESCRIPTION:
      'Analyser les forces, faiblesses, opportunités et menaces de votre entreprise.',
    SWOT_ANALYSIS_LONG_DESCRIPTION:
      'L\'analyse SWOT est une activité de planification stratégique visant à évaluer les forces, les faiblesses, les opportunités et les menaces d\'une entreprise. Les forces et les faiblesses sont des facteurs internes, tels que les employés, les activités ou la stratégie commerciale. Par ailleurs, les opportunités et les menaces font référence à des facteurs externes, tels que les concurrents, les fluctuations du marché ou les tendances de consommation.',
    FOUR_L_RETROSPECTIVE: 'Rétrospective 4L',
    FOUR_L_RETROSPECTIVE_DESCRIPTION:
      "Discutez ce que l'équipe a aimé, appris, manqué et désiré. ",
    FOUR_L_RETROSPECTIVE_LONG_DESCRIPTION:
      'La rétrospective 4L est une technique simple permettant aux équipes de revenir sur les sprints ou projets passés et d\'identifier les actions d\'amélioration. Au cours de cette activité, les membres de l\'équipe partagent ce qu\'ils ont aimé, appris, manqué et désiré d\'un point de vue factuel et émotionnel. <br/><br/> La rétrospective 4L a été conçue par Mary Gorman et Ellen Gottesdiener et décrite dans leur billet de blog <a target="_blank" href="https://www.ebgconsulting.com/blog/the-4ls-a-retrospective-technique/">The 4L’s: A Retrospective Technique</a>',
    LEAN_CANVAS: 'Lean Canvas',
    LEAN_CANVAS_DESCRIPTION:
      'Déterminez des business models possibles en suivant la méthode Lean Startup.',
    LEAN_CANVAS_LONG_DESCRIPTION:
      'Le Lean Canvas est une méthode permettant de déconstruire une idée en hypothèses clés et d\'évaluer les modèles commerciaux possibles. Basé sur la méthode Lean Startup, le Lean Canvas comprend 9 blocs partant des problèmes du client et mettant en évidence l\'avantage concurrentiel de l\'entreprise. . Créé par Ash Maurya, le Lean Canvas est un dérivé du Business Model Canvas d\'Alex Osterwalder.',
    PROJECT_CANVAS: 'Projet Canvas',
    PROJECT_CANVAS_DESCRIPTION:
      "Synthétisez, visualisez et partagez toutes les informations importantes d'un projet. ",
    PROJECT_CANVAS_LONG_DESCRIPTION:
      "Le Project Canvas est un outil permettant de définir les principaux éléments d'un projet et leurs relations en une seule vue d'ensemble. En outre, il stimule la collaboration et la communication entre toutes les parties concernées et permet d'anticiper les problèmes éventuels. Jim Kalbach a lancé le Project Canvas, inspiré du Business Model Canvas.",
    RETROSPECTIVE_START_STOP_CONTINUE:
      'Rétrospective Commencer, Arrêter, Continuer',
    RETROSPECTIVE_START_STOP_CONTINUE_DESCRIPTION:
      'Définissez ce que vous voulez commencer, arrêter et continuer de faire lors du prochain sprint.',
    RETROSPECTIVE_START_STOP_CONTINUE_LONG_DESCRIPTION:
      'La rétrospective Start Stop Continue est un style de rétrospective orienté vers l\'action pour examiner les résultats du dernier sprint ou cycle de projet et planifier les améliorations futures. En réfléchissant à ce que l\'équipe devrait commencer, arrêter et continuer à faire, les participants mènent une discussion sur les comportements de travail et définissent ceux à conserver et ceux à écarter.',
    REVERSE_BRAINSTORMING: 'Brainstorming Inversé',
    REVERSE_BRAINSTORMING_DESCRIPTION:
      'Résolvez créativement des problèmes en vous concentrant sur les défis plutôt que les solutions.',
    REVERSE_BRAINSTORMING_LONG_DESCRIPTION:
      'Le Brainstorming inversé est une technique d\'idéation permettant d\'anticiper les problèmes qui peuvent survenir au cours d\'un projet et de trouver des solutions créatives. Elle s\'appuie sur la tendance naturelle des humains à identifier plus facilement les défis que les solutions. Dans cette activité, les participants commencent par envisager tous les problèmes possibles qu\'ils peuvent rencontrer. Ensuite, ils convertissent les idées négatives en idées positives et trouvent des solutions qui empêcheraient le problème de se produire.',
    CRAZY_8: 'Crazy 8',
    CRAZY_8_DESCRIPTION: 'Brainstormez 8 solutions en 8 minutes.',
    CRAZY_8_LONG_DESCRIPTION: 'Crazy Eights est un dérivé de la méthode Design Sprint pour générer rapidement diverses idées. Au cours de cette activité, les participants doivent proposer huit solutions à un problème en huit minutes, en notant une solution par liste. La quantité étant d\'abord privilégiée par rapport à la qualité, les participants sélectionnent ensuite leurs trois meilleures idées et les présentent au groupe.',
    ANALYSE_SOAR: 'Analyse SOAR',
    ANALYSE_SOAR_DESCRIPTION:
      'Concentrez-vous sur vos forces et opportunités, et visualisez vos aspirations et résultats futurs.',
    ANALYSE_SOAR_LONG_DESCRIPTION:
      'L\'analyse SOAR est une technique de planification stratégique permettant d\'analyser les forces et les opportunités d\'une organisation et de construire une vision des aspirations et des résultats futurs. Contrairement à l\'analyse SWOT, elle utilise la méthode appréciative et se concentre sur les leviers potentiels pour tirer parti des opportunités. Durant cette activité, les participants se concentrent sur la situation actuelle en faisant un brainstorming sur les forces et les opportunités de l\'organisation. Ensuite, en identifiant les aspirations et les résultats futurs, les participants projettent et planifient une position future favorable.',
    ANALYSE_PESTEL: 'Analyse PESTEL',
    ANALYSE_PESTEL_DESCRIPTION:
      'Analysez les facteurs macro-environnementaux qui peuvent avoir un impact sur votre performance.',
    ANALYSE_PESTEL_LONG_DESCRIPTION:
      'Une analyse PESTLE permet une évaluation stratégique et systématique des perspectives, risques et opportunités d\'une entreprise dans un nouvel environnement. PESTLE est l\'acronyme des six facteurs externes critiques : Politique, économique, social, technologique, juridique et environnemental. En identifiant et en analysant ces facteurs, les organisations peuvent détecter et comprendre les grandes tendances qui ont un impact sur leurs stratégies commerciales et humaines.',
    OBJECTIVES_BY_KEY_RESULTS: 'Objectifs par Résultats Clés',
    OBJECTIVES_BY_KEY_RESULTS_DESCRIPTION:
      'Définissez des objectifs et résultats clés pour aligner vos efforts.',
    OBJECTIVES_BY_KEY_RESULTS_LONG_DESCRIPTION:
      'Les objectifs par résultats clés est un cadre de définition des objectifs qui aide les organisations à mettre en œuvre et à exécuter des stratégies axées sur les résultats et \'alignement transversal. Durant cette activité, les participants définissent un ou plusieurs objectifs, identifient les résultats attendus, les activités pour atteindre chaque objectif, les résultats clés attendus, et précisent les personnes impliquées et les délais.',
    HOPES_AND_FEARS: 'Espoirs et craintes',
    HOPES_AND_FEARS_DESCRIPTION:
      "Évaluez l'attitude des participants à l'égard d'un projet ou de tout engagement collaboratif.",
    HOPES_AND_FEARS_LONG_DESCRIPTION:
      "L'activité Espoirs et craintes est un exercice de groupe visant à évaluer les attentes et les préoccupations des participants concernant un nouveau projet. En demandant à chaque participant de noter ses espoirs et ses craintes, on peut déceler les thèmes émergents et commencer à réfléchir à des solutions pour répondre aux préoccupations exprimées.",
    STANDUP_MEETING: 'Standup meeting',
    STANDUP_MEETING_DESCRIPTION:
      'Révélez les problématiques et planifiez collaborativement la journée de travail.',
    STANDUP_MEETING_LONG_DESCRIPTION:
      'Le Standup Meeting est une courte activité au cours de laquelle une équipe se réunit, et chaque membre partage ce sur quoi il a travaillé hier et aujourd\'hui et s\'il y a des blocages. C\'est l\'occasion pour les membres de l\'équipe de partager leur retour et faire des suggestions pour s\'entraider.',
    EMPATHY_CARD: 'La carte d`empathie',
    EMPATHY_CARD_DESCRIPTION:
      'Mettez-vous dans la peau de vos clients pour leur offrir de meilleurs services et produits.',
    EMPATHY_CARD_LONG_DESCRIPTION:
      'La carte d\'empathie est un outil de visualisation qui rassemble les connaissances sur les comportements et les attitudes d\'un client. Elle permet aux équipes produit de créer une compréhension commune des besoins d\'un utilisateur et d\'aider à la prise de décision. Ce modèle comporte six quadrants, énumérant ce que l\'utilisateur entend, voit, pense, fait et dit, ainsi que ses points de douleurs et motivations.',
    USER_PERSONA: 'Persona utilisateur',
    USER_PERSONA_DESCRIPTION:
      'Représentez un utilisateur-type pour mieux comprendre ses besoins et caractéristiques.',
    USER_PERSONA_LONG_DESCRIPTION:
      'Le persona utilisateur est un outil de design thinking permettant de créer un personnage semi-fictif basé sur un client cible afin d\'améliorer un produit ainsi que sa stratégie marketing et commerciale. Ce canevas permet de collecter et de structurer des données psychographiques telles que la personnalité de l\'utilisateur, ses objectifs, ses intérêts et ses frustrations. Le canevas peut être rempli avec des données hypothétiques à vérifier ou directement avec des données réelles tirées d\'enquêtes et de recherches utilisateurs.',
    DAKI_RETROSPECTIVE: 'Rétrospective DAKI',
    DAKI_RETROSPECTIVE_DESCRIPTION:
      'Identifiez en équipe ce que vous devez arrêter, ajouter, garder et améliorer.',
    DAKI_RETROSPECTIVE_LONG_DESCRIPTION:
      'La rétrospective DAKI est une technique de réflexion pour évaluer les méthodes de travail d\'une équipe et identifier les actions d\'amélioration. Son nom est une abréviation des quatre éléments discutés pendant la session : quelles activités et attitudes l\'équipe doit arrêter (drop), ajouter (add), garder (keep) et améliorer (improve) pour mieux travailler ensemble. Cette activité est la plus adaptée pour découvrir des améliorations basées sur la valeur perçue par chaque individu plutôt que sur des faits.',
    FLAP_RETROSPECTIVE: 'Rétrospective FLAP',
    FLAP_RETROSPECTIVE_DESCRIPTION:
      'Réfléchissez en équipe aux considérations futures, lessons apprises, réalisations et problématiques.',
    FLAP_RETROSPECTIVE_LONG_DESCRIPTION:
      'L\'activité FLAP est un mélange de prospective et de rétrospective, prenant en compte à la fois les leçons tirées du passé et les considérations futures. Le canevas est composé de quatre quadrants : Considérations futures, Leçons apprises, Réalisations et Problèmatiques. Couvrant une zone plus large que les techniques habituelles de rétrospective, elle aide les équipes à célébrer leurs réalisations et à réfléchir à la manière d\'appliquer les leçons apprises pour les projets futurs.',
    SPEED_BOAT_RETROSPECTIVE: 'Rétrospective Speed Boat',
    SPEED_BOAT_RETROSPECTIVE_DESCRIPTION:
      "Évaluez votre sprint précédent et déterminez la meilleure façon d'aller de l'avant.",
    SPEED_BOAT_RETROSPECTIVE_LONG_DESCRIPTION:
      "La Rétrospective Speed Boat est un exercice de visualisation permettant d'identifier ce qui fait avancer un projet et une équipe et ce qui les retient. En utilisant la métaphore du voilier, cette activité invite les participants à réfléchir à la vision du projet, à identifier les vents qui poussent l'équipe vers l'avant, à repérer les risques et les obstacles potentiels (les rochers) et à analyser ce qui retient l'équipe (les ancres).",
    FIVE_WHY: '5 pourquoi',
    FIVE_WHY_DESCRIPTION: "Analysez les causes profondes d'un problème. ",
    FIVE_WHY_LONG_DESCRIPTION: "Les cinq pourquoi est une technique de résolution de problèmes interrogative permettant de découvrir les causes profondes d'un problème particulier. Elle consiste à demander itérativement cinq fois \"pourquoi\". Elle est la plus appropriée pour résoudre un problème simple ou modérément complexe en en révélant la cause sous-jacente.",
    PROS_AND_CONS: 'Pour et Contre',
    PROS_AND_CONS_DESCRIPTION:
      "Énumérez les avantages et les inconvénients d'une décision ou d'une idée.",
    PROS_AND_CONS_LONG_DESCRIPTION:
      "L'activité \"Pour et Contre\" est une technique de comparaison permettant d'examiner les différentes perspectives d'un sujet et de promouvoir la pensée critique. Il s'agit d'un moyen simple et efficace d'identifier les raisons pour et contre une idée particulière afin de décider s'il faut aller de l'avant ou non. Elle se compose de deux listes, l'une pour répertorier les arguments en faveur et l'autre pour enregistrer les idées contre le sujet ou la décision à prendre.",
    MAD_SAD_GLAD_RETROSPECTIVE: 'Rétrospective Mad, Sad, Glad',
    MAD_SAD_GLAD_RETROSPECTIVE_DESCRIPTION:
      "Discutez des émotions ressenties et identifiez des façons d'améliorer l'ambiance d'équipe. ",
    MAD_SAD_GLAD_RETROSPECTIVE_LONG_DESCRIPTION:
      "L'activité Mad Sad Glad est une technique de rétrospective qui se concentre sur le moral de l'équipe et recueille des données sur le parcours émotionnel de chaque membre pendant un sprint ou un projet. En partageant ce qui les a rendus furieux, tristes ou heureux, l'équipe a l'opportunité de réfléchir ensemble à comment créer une dynamique d'équipe positive.",
    BLANK_BRAINSTORM: 'Brainstorm vide',
    BLANK_BRAINSTORM_DESCRIPTION:
      'Créez votre propre activité avec des listes illimitées.',
    BLANK_BRAINSTORM_LONG_DESCRIPTION:
      'Créez votre propre activité avec des listes illimitées.',
    SCRUM_TEAM_RADAR: "Radar de l'équipe Scrum",
    SCRUM_TEAM_RADAR_DESCRIPTION:
      "Auto-évaluez vos pratiques scrum en équipe et planifiez des actions d'amélioration.",
    SCRUM_TEAM_RADAR_LONG_DESCRIPTION:
      "Auto-évaluez vos pratiques scrum en équipe et planifiez des actions d'amélioration.",
    PROBLEM_SOLUTION_FIT: 'Canvas d\'Adéquation Problème-Solution',
    PROBLEM_SOLUTION_FIT_DESCRIPTION:
      'Traduire les problèmes en solutions pour une probabilité plus élevée d\'adoption de la solution.',
    PROBLEM_SOLUTION_FIT_LONG_DESCRIPTION:
      'Le Canvas d\'Adéquation Problème-Solution est un cadre stratégique pour identifier les solutions les plus susceptibles d\'être adoptées par les clients cibles. Basée sur les principes du Lean Startup, de la psychologie comportementale et de la conception de l\'expérience utilisateur, cette activité aide les équipes Produit et Marketing à affiner et améliorer la proposition de valeur et la communication autour d\'une offre. Ce canevas se compose de 10 quadrants qui traitent de l\'analyse client, de l\'adéquation entre le problème et le comportement et de l\'adéquation entre le canal de communication et la solution. <br/> "The Problem-Solution Fit Canvas" a été créé par <a  target="_blank" href="https://www.amaltama.com/">Daria Nepriakhina</a> sous <a  target="_blank" href="https://creativecommons.org/licenses/by-nc-nd/4.0/">CC BY-NC-ND 4.0</a>.',
    EXPERIMENT_CANVAS: 'Canvas d\'Expérimentation',
    EXPERIMENT_CANVAS_DESCRIPTION:
      'Décomposez vos hypothèses les plus risquées en expériences mesurables et observables.',
    EXPERIMENT_CANVAS_LONG_DESCRIPTION:
      'Le Canvas d\'Expérimentation est un outil permettant de bien définir le cadre et la méthode d\'une expérimentation rigoureuse. Le canevas permet d\'identifier l\'hypothèse la plus risquée, de produire une hypothèse falsifiable, de planifier le déroulement de l\'expérience et de noter les résultats et prochaines étapes. <br/><br/> Le modèle ExcelWay de Canvas d\'Expérimentation est un dérivé de "The Experiment Canvas" par <a  target="_blank" href="https://www.linkedin.com/in/ashmaurya/">Ash Maurya</a>, utilisé sous <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',
    PRODUCT_MARKET_FIT: 'Canvas d\'Adéquation Produit-Marché',
    PRODUCT_MARKET_FIT_DESCRIPTION:
      'Définir, valider et atteindre vos clients pour obtenir la validation du marché pour votre produit.',
    PRODUCT_MARKET_FIT_LONG_DESCRIPTION:
      'Le Canevas d\'Adéquation Produit-Marché est un outil d\'innovation stratégique qui permet de faire correspondre la demande du marché à un produit ou service et de définir une stratégie de produit appropriée. <br/><br/> Il reflète, d\'une part, les caractéristiques du segment de clientèle, telles que les tâches à accomplir, les problèmes, les canaux et l\'expérience des utilisateurs. D\'autre part, les participants définissent ou évaluent le produit ou le service offert à ce client, comme les alternatives au produit, les caractéristiques du produit, la valeur pour le canal et les indicateurs clés.<br/><br/> Un groupe de praticiens de l\'innovation a créé le Product-Market Fit Canvas sous <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',
    FIVE_FORCES_RADAR: 'Les 5 forces de Porter',
    FIVE_FORCES_DESCRIPTION:
      'Évaluer les concurrents directs et indirects en fonction de leurs forces.',
    FIVE_FORCES_LONG_DESCRIPTION:
      'Le modèle des 5 forces de Porter est un modèle permettant d\'identifier et d\'analyser les forces concurrentielles d\'un marché et de formuler des stratégies pour rivaliser avec elles. Le modèle décompose les industries et les marchés en examinant cinq éléments : <br/><ul><li> Le pouvoir de négociation des fournisseurs </li><li> Le pouvoir de négociation des acheteurs </li><li> La menace de nouveaux entrants </li><li> La menace de produits de substitution </li><li> La rivalité entre concurrents existants </li></ul> Ce modèle a été initialement créé et publié en 1979 par Michael Porter, professeur à la Harvard Business School.',
    PITCH_CANVAS: 'Pitch Canvas',
    PITCH_CANVAS_DESCRIPTION:
      'Brainstormez, structurez et visualisez votre pitch.',
    PITCH_CANVAS_LONG_DESCRIPTION:
      'Le Pitch Canvas est un outil de brainstorming permettant de créer un pitch pertinent et exhaustif.<br/><br/> Composé de 11 modules, le Pitch Canvas couvre tous les éléments que les investisseurs veulent connaître lorsqu\'ils écoutent un pitch. Du problème du client au différentiateur unique d\'une solution, ce canevas aide les entrepreneurs à réfléchir et sélectionner les meilleures idées à présenter dans un discours concis.<br/><br/> Le modèle ExcelWay Pitch Canvas est un dérivé de "<a target="_blank" href="https://best3minutes.com/the-pitch-canvas/">The Pitch Canvas©</a>" par <a target="_blank" href="https://best3minutes.com/academy/">Best3minutes</a>, utilisé sous <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',
    VALUE_CHAIN_CANVAS: 'Canvas des chaînes de valeur',
    VALUE_CHAIN_CANVAS_DESCRIPTION:
      'Identifiez les acteurs pertinents de vos chaînes de valeur et optimisez votre modèle économique.',
    VALUE_CHAIN_CANVAS_LONG_DESCRIPTION:
      'Le canevas des chaînes de valeur est un cadre stratégique permettant de cartographier la chaîne d\'approvisionnement d\'une entreprise et d\'identifier les possibilités d\'optimisation des processus et de croissance de l\'activité.<br/><br/> Composé de 8 blocs, le canevas couvre les principales étapes d\'un processus tout en identifiant les fournisseurs et les intermédiaires impliqués. Ainsi, les parties prenantes peuvent analyser diverses activités et leurs unités organisationnelles telles que la production, les ventes, le marketing ou la logistique.<br/><br/> Le modèle ExcelWay Value Chains Canvas est un dérivé de "The Value Chains Canvas" par <a target="_blank" href="https://www.datentreiber.de/en/">Datentreiber</a>, utilisé sous <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',                   
    PREVIEW: 'Aperçu',
    CUSTOM_MODELS: 'Modèles personnalisés',
    DUPLICATE_AND_EDIT: 'Dupliquer et modifier',
    DELETE_MODAL: 'Supprimer',
    UPDATE_MODAL: 'Modifier',
    CREATE_NEW_MODEL:'Créer un modèle',
  },
};
