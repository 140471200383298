export const locale = {
    lang: 'en',
    data: {
        CONFIRMATION: 'Confirmation',
        CONFIRM: 'Confirm',
        UPGRADE: 'Upgrade',
        UPGRADE_MESSAGE_ONE_APPSUMO: 'You have reached your AppSumo deal limit.',
        UPGRADE_MESSAGE_ONE_EXCELWAY: 'You have reached your Excelway deal limit.',
        UPGRADE_MESSAGE_TWO: 'Upgrade your deal now to add more projects and members to your subscription',
        UPGRADE_NOW: 'Upgrade now',
        CANCEL: 'Cancel',
        CONFIRM_CONNECTION: 'Are you sure you want to logout?',
        CONFIRM_SESSION: 'Are you sure you want to leave the workshop?',
        CONFIRM_DELETE_DETAIL: 'Are you sure you want to permanently delete the detail?',
        CONFIRM_ARCHIVE: 'Are you sure you want to delete the project once and for all?',
        CONFIRM_ARCHIVE_BOARD: 'Are you sure you want to delete this board once and for all?',
        CONFIRM_DELETE_BOARD: 'Are you sure you want to permanently delete the document?',
        CONFIRM_ARCHIVE_SESSION: 'Are you sure you want to permanently archive the workshop?',
        CONFIRM_STOP_MONITORING: 'Are you sure you want to permanently stop monitoring the workshop?',
        CONFIRM_CLOSE_SESSION: 'Are you sure you want to permanently close the workshop?',
        CONFIRM_ARCHIVE_ACTIVITY: 'Are you sure you want to delete this activity once and for all?',
        CONFIRM_ARCHIVE_TEMPLATE: 'Are you sure you want to delete this template? It will no longer be available in any projects.',
        CONFIRM_DELETE_NOTE: 'Are you sure you want to permanently delete the note?',
        CONFIRM_EMPORT: 'Are you sure you want to import this file? (This operation will overwrite this service if existing)',
        REMOVE_COLOR: 'Remove color',
        SELECT_COLOR: 'Select a Color',
    }
};
