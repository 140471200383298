export const locale = {
  lang: 'en',
  data: {
    SELECT: 'Choose a template',
    CREATE: 'Create activity',
    CANCEL: 'Cancel',
    SEARCH: 'Search...',
    ALL_TEMPLATES: 'All templates',
    SALES_CUSTOMERS: 'Sales and customer relations',
    PROJECT_MANAGEMENT: 'Project management',
    HUMAN_RESSOURCES: 'Human ressources',
    INFORMATION_SYSTEM: 'IT',
    BRAINSTORMING: 'Brainstorming',
    AGIL: 'Agile workflows',
    PRODUCT_MANAGEMENT: 'Product management',
    TEAM_BUILDING: 'Team building',
    PROSPECTIVE: 'Problem solving',
    STRATEGY: 'Strategy',
    BACK_TEMPLATES: 'Back to templates',
    USE_TEMPLATES: 'Use this template',
    BUSINESS_MODEL_CANVAS: 'Business Model Canvas',
    BUSINESS_MODEL_CANVAS_DESCRIPTION:
      'Get a holistic view of your business to clearly see how it creates and delivers value.',
    BUSINESS_MODEL_CANVAS_LONG_DESCRIPTION:
      'The Business Model Canvas is a strategic management template for visually developing new business models and documenting existing ones. Based on nine building blocks, the Business Model Canvas helps provide a holistic view of the business strategy centered around the value proposition. In a single document, map and align the business\' value proposition, customers\' segments and relationships, channels, key partners, activities and resources, cost structure, and revenue streams. The ExcelWay Business Model Canvas template is a derivative of "The Business Model Canvas" by <a target="_blank" href="https://www.strategyzer.com/">Strategyzer AG</a>, used under <a target="_blank" href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>',  
    SWOT_ANALYSIS: 'SWOT Analysis',
    SWOT_ANALYSIS_DESCRIPTION:
      "Analyze your company's strengths, weaknesses, opportunities, and threats.",
    SWOT_ANALYSIS_LONG_DESCRIPTION:
      "A SWOT analysis is a strategic planning activity to assess a business' core strengths, weaknesses, opportunities, and threats. Strengths and weaknesses are internal elements, such as employees, activities, or business strategy. On the other hand, opportunities and threats refer to external factors, such as competitors, market fluctuations, or consumer trends.",  
    FOUR_L_RETROSPECTIVE: '4L Retrospective',
    FOUR_L_RETROSPECTIVE_DESCRIPTION:
      'Reflect on what the team liked, learned, lacked, and longed for.',
    FOUR_L_RETROSPECTIVE_LONG_DESCRIPTION:
      'The 4L Retrospective is a simple retrospective technique for teams to reflect on past sprints or projects and identify improvement actions. During this activity, team members share what they liked, learned, lacked, and longed for from a factual and emotional perspective.<br/><br/> The Four L’s Retrospective was designed by Mary Gorman and Ellen Gottesdiener and described in their blog post <a target="_blank" href="https://www.ebgconsulting.com/blog/the-4ls-a-retrospective-technique/">The 4L’s: A Retrospective Technique</a>',  
    LEAN_CANVAS: 'Lean Canvas',
    LEAN_CANVAS_DESCRIPTION:
      'Brainstorm possible business models following the Lean Startup Methodology',
    LEAN_CANVAS_LONG_DESCRIPTION:
      'The Lean Canvas is a strategic framework to deconstruct an idea into key assumptions and brainstorm or assess possible business models. Based on the Lean Startup method, the Lean Canvas includes 9 building blocks starting from the customer\'s problems and highlighting the venture\'s unfair advantage. Created by Ash Maurya, the Lean Canvas is a derivative of the Business Model Canvas from Alex Osterwalder.',  
    PROJECT_CANVAS: 'Project Canvas',
    PROJECT_CANVAS_DESCRIPTION:
      'Summarize, visualize and share all necessary information about a project.',
    PROJECT_CANVAS_LONG_DESCRIPTION:
      'The Project Canvas is a mapping tool to define a project\'s main elements and their relationship in a single overview. Furthermore, it stimulates the collaboration and communication between all parties involved and allows to anticipate possible problems. Jim Kalbach launched the <a target="_blank" href="https://experiencinginformation.com/2012/08/05/the-project-canvas/">Project Canvas</a>, inspired by the Business Model Canvas.',  
    RETROSPECTIVE_START_STOP_CONTINUE: 'Retrospective Start, Stop, Continue',
    RETROSPECTIVE_START_STOP_CONTINUE_DESCRIPTION:
      'See what the team wants to start, stop and continue doing in the next sprint.',
    RETROSPECTIVE_START_STOP_CONTINUE_LONG_DESCRIPTION:
      'The Start Stop Continue Retrospective is an action-oriented retrospective style to review the outcomes of the last sprint or project cycle and plan future improvements. By reflecting on what the team should start, stop and continue doing, the participants lead a discussion on working behaviors and choose those to keep and dismiss.',
    REVERSE_BRAINSTORMING: 'Reverse Brainstorming',
    REVERSE_BRAINSTORMING_DESCRIPTION:
      'Flip brainstorming upside down and solve problems by seeing challenges before solutions.',
    REVERSE_BRAINSTORMING_LONG_DESCRIPTION:
      'Reverse Brainstorming is an ideation technique to anticipate problems that may occur during a project and find creative solutions. It builds on humans\' natural tendency to identify challenges more easily than solutions. In this activity, participants start by considering all the possible problems they can encounter. Then, they convert the negative ideas into positive ideas and find solutions that would prevent the problem from happening.',  
    CRAZY_8: 'Crazy Eights',
    CRAZY_8_DESCRIPTION: 'Brainstorm 8 solutions in 8 minutes.',
    CRAZY_8_LONG_DESCRIPTION: 'Crazy Eights is a derivative of the Design Sprint method to generate various ideas quickly. In this activity, participants must provide eight solutions to a problem in eight minutes, writing down one solution per list. As quantity is favored over quality at first, participants then select and present their top three ideas to the group.',
    ANALYSE_SOAR: 'SOAR Analysis',
    ANALYSE_SOAR_DESCRIPTION:
      'Focus on your strengths and opportunities, and create a vision of future aspirations and results.',
    ANALYSE_SOAR_LONG_DESCRIPTION:
      'The SOAR Analysis is a strategic planning technique to analyze an organization\'s strengths and opportunities and build a vision for future aspirations and results. Unlike the SWOT Analysis, it uses appreciative inquiry and focuses on potential leverages to take advantage of opportunities. In this activity, the participants focus on the present situation by brainstorming the organization\'s strengths and opportunities. Then, by identifying future aspirations and results, participants project and plan a favorable future position.',
    ANALYSE_PESTEL: 'PESTLE Analysis',
    ANALYSE_PESTEL_DESCRIPTION:
      'Analyze the macro-environmental factors that can have an impact on your performance.',
    ANALYSE_PESTEL_LONG_DESCRIPTION:
      'A PESTLE analysis allows a strategic and systematic evaluation of a business\'s prospects, risks, and opportunities in a new environment. PESTLE stands for six critical external factors: Political, Economic, Social, Technological, Legal, and Environmental. By identifying and analyzing these factors, organizations can detect and understand broad, long-term trends impacting their business and people strategies.',
    OBJECTIVES_BY_KEY_RESULTS: 'Objectives and Key Results',
    OBJECTIVES_BY_KEY_RESULTS_DESCRIPTION:
      'Set objectives and key results to create alignment and engagement.',
    OBJECTIVES_BY_KEY_RESULTS_LONG_DESCRIPTION:
      'Objectives and Key Results is a goal-setting framework that helps organizations implement and execute strategies focusing on results and cross-functional alignment. In this activity, participants define one or several objectives, identify the expected outcomes, the activities to achieve each goal, the key results expected, and clarify the people involved and the deadlines.',
    HOPES_AND_FEARS: 'Hopes and fears',
    HOPES_AND_FEARS_DESCRIPTION:
      "Gauge participants' attitude towards a project or any collaborative engagement. ",
    HOPES_AND_FEARS_LONG_DESCRIPTION:
      "The Hopes and Fears activity is a group exercise to assess participants' expectations and concerns about a new project. By having each participant write down their hopes and fears, one can identify emerging themes and start brainstorming solutions to address the concerns expressed.",
    STANDUP_MEETING: 'Daily Standup',
    STANDUP_MEETING_DESCRIPTION:
      'Reveal roadblocks and plan the day of work collaboratively.',
    STANDUP_MEETING_LONG_DESCRIPTION:
      'The Daily Standup is a short activity where a team gets together, and each member shares what they have worked on yesterday and today and if there are any blockers. It is an opportunity for everyone on the team to give feedback and make suggestions to unblock each other.',
    EMPATHY_CARD: 'Empathy Map',
    EMPATHY_CARD_DESCRIPTION:
      'Gain a deeper insight into your customers to offer them better services and products.',
    EMPATHY_CARD_LONG_DESCRIPTION:
      'The Empathy Map is a visualization tool that captures knowledge about a client\'s behaviors and attitudes. It allows product teams to create a shared understanding of a user\'s needs and aid decision-making. This template has six quadrants, listing what the user hears, sees, thinks, does, and says, as well as his pains and gains.',
    USER_PERSONA: 'User Persona',
    USER_PERSONA_DESCRIPTION:
      'Create a target user profile to better understand their needs and characteristics.',
    USER_PERSONA_LONG_DESCRIPTION:
      'The User Persona canvas is a design thinking tool to create a semi-fictional character based on a target customer to improve a product and its marketing and sales strategy. It helps collect and structure psychographic data such as the user\'s personality, goals, interests, and frustrations. The canvas can be filled with hypothetical data to be verified or directly with actual data pulled from surveys and user research.',
    DAKI_RETROSPECTIVE: 'DAKI retrospective',
    DAKI_RETROSPECTIVE_DESCRIPTION:
      'Identify as a team what you should drop, add, keep, and improve.',
    DAKI_RETROSPECTIVE_LONG_DESCRIPTION:
      'The DAKI retrospective is a reflection technique to assess a team\'s working methods and identify improvement actions. Its name is an abbreviation of the four elements discussed during the session: what activities and attitudes the team needs to drop, add, keep, and improve to work better together. This activity is best suited to uncover improvements based on the value perceived by each individual rather than facts.',
    FLAP_RETROSPECTIVE: 'FLAP retrospective',
    FLAP_RETROSPECTIVE_DESCRIPTION:
      'Reflect on future considerations, lessons learned, accomplishments, and problems.',
    FLAP_RETROSPECTIVE_LONG_DESCRIPTION:
      'The FLAP activity is a mix of futurespective and retrospective, considering both lessons learned from the past and future considerations. The canvas consists of four quadrants: Future Considerations, Lessons Learned, Accomplishments, and Problem Areas. Covering a broader area than usual retrospective techniques, it helps teams celebrate their achievements and reflect on how to apply the lessons learned for future projects.',
    SPEED_BOAT_RETROSPECTIVE: 'Sailboat Retrospective',
    SPEED_BOAT_RETROSPECTIVE_DESCRIPTION:
      'Evaluate your previous sprint and determine the best way forward.',
    SPEED_BOAT_RETROSPECTIVE_LONG_DESCRIPTION:
      'The Sailboat Retrospective is a visualization exercise to identify what pushes a project and a team forward and what is holding them back. Using the sailboat metaphor, this activity invites participants to reflect on the project\'s vision, identify the winds propelling the team forward, spot the potential risks and obstacles (the rocks), and analyze what is holding the team back (the anchors).',
    FIVE_WHY: "5 Why's",
    FIVE_WHY_DESCRIPTION: 'Analyze and understand the root cause of a problem.',
    FIVE_WHY_LONG_DESCRIPTION: 'The Five Why\'s is an interrogative problem-solving technique to uncover the root causes of a particular problem. It consists in asking "why" five times after stating a problem. It is most suitable to resolve a simple or moderately complex problem by cutting quickly through its outwards symptoms and revealing the underlying cause.',
    PROS_AND_CONS: 'Pros and cons',
    PROS_AND_CONS_DESCRIPTION:
      'List the advantages and disadvantages of a decision or idea. ',
    PROS_AND_CONS_LONG_DESCRIPTION:
      'The Pros and Cons activity is a comparison technique to consider the different perspectives of a topic and promote critical thinking. It is a simple and effective way of identifying the reasons for and against a particular idea to decide whether or not to go forward. It consists of two lists, one to list the arguments in favor and the other to record the ideas against the topic or decision to be made.',
    MAD_SAD_GLAD_RETROSPECTIVE: 'Mad, Sad, Glad retrospective',
    MAD_SAD_GLAD_RETROSPECTIVE_DESCRIPTION:
      "Dive into the team's feelings and identify ways to improve morale.",
    MAD_SAD_GLAD_RETROSPECTIVE_LONG_DESCRIPTION:
      "The Mad Sad Glad activity is a retrospective technique that focuses on team morale and collects data on the emotional journey of each team member during a sprint or a project. By sharing what made them mad, sad, or glad, the team has an opportunity to reflect on any issues and to uncover the possibilities to create a positive team dynamic.",
    BLANK_BRAINSTORM: 'Blank Brainstorm',
    BLANK_BRAINSTORM_DESCRIPTION:
      'Create your own activity with unlimited lists.',
    BLANK_BRAINSTORM_LONG_DESCRIPTION:
      'Create your own activity with unlimited lists.',
    SCRUM_TEAM_RADAR: 'Scrum Team Radar',
    SCRUM_TEAM_RADAR_DESCRIPTION:
      'Self-assess your scrum practices as a team and plan ways to improve',
    SCRUM_TEAM_RADAR_LONG_DESCRIPTION:
      'Self-assess your scrum practices as a team and plan ways to improve',
    PROBLEM_SOLUTION_FIT: 'Problem-Solution Fit Canvas',
    PROBLEM_SOLUTION_FIT_DESCRIPTION:
      'Translate problems into solutions with higher chances of solution adoption probability.',
    PROBLEM_SOLUTION_FIT_LONG_DESCRIPTION:
      'The Problem-Solution Fit Canvas is a strategic framework for identifying solutions that are more likely to be adopted by the target clients. Based on the principles of Lean Startup, behavioral psychology, and user experience design, this activity helps Product and Marketing teams refine and improve the value proposition and the messaging. This canvas consists of 10 quadrants that address the Customer State fit, the Problem-Behavior fit, the Communication-Channel fit, and the solution.<br/> "The Problem-Solution Fit Canvas" was created by <a  target="_blank" href="https://www.amaltama.com/">Daria Nepriakhina</a> under <a  target="_blank" href="https://creativecommons.org/licenses/by-nc-nd/4.0/">CC BY-NC-ND 4.0</a>',
    EXPERIMENT_CANVAS: 'Experiment Canvas',
    EXPERIMENT_CANVAS_DESCRIPTION:
      'Break down your riskiest assumptions into measurable and observable experiments.',
    EXPERIMENT_CANVAS_LONG_DESCRIPTION:
      'The Experiment Canvas is a tool for designing a compelling and well-defined experiment. The canvas allows to identify the experiment\'s riskiest assumption, produce a falsifiable hypothesis, plan the experiment setup and check the results and next steps. The ExcelWay Experiment Canvas template is a derivative of "The Experiment Canvas" by <a  target="_blank" href="https://www.linkedin.com/in/ashmaurya/">Ash Maurya</a>, used under <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',
    PRODUCT_MARKET_FIT: 'Product-Market Fit Canvas',
    PRODUCT_MARKET_FIT_DESCRIPTION:
      'Define, validate and reach your customers to achieve market validation with your product.',
    PRODUCT_MARKET_FIT_LONG_DESCRIPTION:
      'The Product-Market Fit Canvas is a strategic innovation tool that matches the market\'s demand for a product and defines an appropriate product strategy. It mirrors, on the one hand, the customer segment\'s characteristics, such as jobs to be done, problems, channels, and user experience. On the other hand, participants define or assess the product or service offered to this customer, such as the alternatives to the product, the product\'s features, the value for the channel, and the key metrics. A group of innovation practitioners has created the Product-Market Fit Canvas under <a  target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',
    FIVE_FORCES_RADAR: 'Porter\'s 5 Forces',
    FIVE_FORCES_DESCRIPTION:
      'Assess direct and indirect competitors based on their strength.',
    FIVE_FORCES_LONG_DESCRIPTION:
      'Porter\'s 5 forces is a business strategy model to identify and analyze a market\'s competitive forces and formulate strategies to rival them. The model breaks down industries and markets by examining five elements:<ul><li>Suppliers\' bargaining power</li><li>Buyer\'s bargaining power</li><li>The threat of new competitors</li><li>The threat of substitutes</li><li>Intra-industry competitiveness</li></ul>This model was initially created and published in 1979 by Michael Porter, a Harvard Business School professor.',
    PITCH_CANVAS: 'Pitch Canvas',
    PITCH_CANVAS_DESCRIPTION:
      'Brainstorm, structure and visualize your pitch.',
    PITCH_CANVAS_LONG_DESCRIPTION:
      'The Pitch Canvas is an entrepreneurial brainstorming framework for creating an impactful and exhaustive pitch.<br/><br/>Made of 11 building blocks, the Pitch Canvas covers all elements investors and board members want to know when hearing a pitch. From the customer pain point to the unique differentiator of a solution, this canvas helps entrepreneurs brainstorm and select the best ideas to present in a concise and compelling pitch.<br/><br/>The ExcelWay Pitch Canvas template is a derivative of <a target="_blank" href="https://best3minutes.com/the-pitch-canvas/">The Pitch Canvas©</a> by <a target="_blank" href="https://best3minutes.com/academy/">Best3minutes</a>, used under <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',
    VALUE_CHAIN_CANVAS: 'Value Chain Canvas',
    VALUE_CHAIN_CANVAS_DESCRIPTION:
      'Identify the relevant stakeholders in your value chains and optimize your business model.',
    VALUE_CHAIN_CANVAS_LONG_DESCRIPTION:
      'The Value Chain Canvas is a strategic framework for mapping a company\'s supply chain and identifying process optimization and business growth opportunities.<br/><br/> Made of 8 building blocks, the canvas covers a process\'s main stages while identifying suppliers and intermediaries involved. As a result, stakeholders can analyze various activities and their organizational units such as production, sales, marketing, or logistics.<br/><br/> The ExcelWay Value Chain Canvas template is a derivative of "The Value Chain Canvas" by <a target="_blank" href="https://www.datentreiber.de/en/">Datentreiber</a>, used under <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>.',                  
    PREVIEW:'Preview',
    CUSTOM_MODELS: 'Custom templates',
    DUPLICATE_AND_EDIT: 'Duplicate and edit',
    DELETE_MODAL: 'Delete',
    UPDATE_MODAL: 'Edit',
    CREATE_NEW_MODEL:'Create template',
  },
};
