export const locale = {
    lang: 'en',
    data: {
        MODIFY_STRUCTURE: 'Edit project structure',
        // tslint:disable-next-line: max-line-length
        PARA: 'The project structure represents the different subsequent levels to organize your projects and workshops. Determine their number and labels as practiced in your organization.',
        LEVEL: 'Level',
        LABEL: 'Label',
        EXEMPLE: 'For example: program, strategic axis, project...',
        SAVE: 'Save',
        CANCEL: 'Cancel',
    }
};
