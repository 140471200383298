import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './reducer';
import { WorkshopActivityStoreEffects } from './effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('workshop-activity', reducer),
        EffectsModule.forFeature([WorkshopActivityStoreEffects]),
    ],
})
export class WorkshopActivityStoreModule { }
