import { WorkshopActivitiesStoreSelectors } from 'app/root-store/workshop-store/activities-store';
import { Component, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    RootStoreState
} from 'app/root-store';
import { WorkshopCanvasStoreActions } from 'app/root-store/workshop-store/canvas-store';
import { Activity } from '@bsuccess/models/activities/activity.model';
import { locale as english } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/export-cards-dialog/tables-step/en';
import { locale as frensh } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/export-cards-dialog/tables-step/fr';
import { locale as arabic } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/export-cards-dialog/tables-step/ar';

@Component({
    selector: 'export-cards-dialog-tables-step',
    templateUrl: './tables-step.component.html',
    styleUrls: ['./tables-step.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ExportCardsDialogTablesStepComponent {
    listId: any;
    selectedActivity: any;
    activities$: Observable<Activity[]>;
    constructor(
        public matDialogRef: MatDialogRef<ExportCardsDialogTablesStepComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.activities$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectActivities)
        );
    }

    save(): void {
        this._store.dispatch(
            WorkshopCanvasStoreActions.saveExportCards({
                boardId: this.selectedActivity.contentId,
                listId: this.listId
            })
        );
    }
}
