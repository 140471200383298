import { createAction, props } from '@ngrx/store';

import { UserModel } from '@bsuccess/models/user.model';
import { LoginModel } from '@bsuccess/models/login.model';
import { CacheModel } from '@bsuccess/models/cache.model';
import { ActionPermissionModel } from '@bsuccess/models/action-permission.model';
import { SessionModel } from '@bsuccess/models/session/session.model';

export const notAuthorized = createAction('[Auth] Not Authorized');

export const updateTenant = createAction(
    '[Auth] Update Tenant',
    props<{
        tenant: string;
    }>());

export const loadCacheSuccess = createAction(
    '[Cache] Load Login Cache Success',
    props<{
        cache: CacheModel;
    }>()
);

export const loadCacheFailure = createAction(
    '[Cache] Load Login Cache Failure'
);

export const login = createAction(
    '[Login Page] Login',
    props<{
        credentials: LoginModel;
    }>()
);

export const loginSuccess = createAction(
    '[Auth API] Login Success',
    props<{ token: string }>()
);

export const loginFailure = createAction(
    '[Auth API] Login Failure',
    props<{ error: string }>()
);

export const switchForm = createAction(
    '[Login Page] Switch form',
    props<{ form: string }>()
);

export const loadSession = createAction(
    '[Login Page] Load Session',
    props<{ sessionKey: string }>()
);

export const loadSessionSuccess = createAction(
    '[Session API] Load Session Success',
    props<{ session: SessionModel; sessionRole: string; isUserReporter: boolean ; user : any }>()
);

export const loadSessionFailure = createAction(
    '[Session API] Load Session Failure',
    props<{ error: string }>()
);

export const loadActionPermissions = createAction(
    '[Auth API] Load Action Permissions'
);

export const loadActionPermissionsSuccess = createAction(
    '[Auth API] Load Action Permissions Success',
    props<{ actionPermissions: ActionPermissionModel[] }>()
);

export const loadActionPermissionsError = createAction(
    '[Auth API] Load Action Permissions Failure',
    props<{ error: string }>()
);

export const sendPasswordResetEmail = createAction(
    '[Login API] Send Reset Password Email',
    props<{ email: string; }>()
);

export const sendPasswordResetEmailSuccess = createAction(
    '[Login API] Send Reset Password Email Success',
    props<{ workspace: string }>()
);

export const sendPasswordResetEmailFailure = createAction(
    '[Login API] Send Reset Password Email Failure',
    props<{ error: string }>()
);

export const whoami = createAction(
    '[Auth API] Get The Curent User'
);

export const whoamiSuccess = createAction(
    '[Auth API] Get The Curent User Success',
    props<{ user: UserModel }>()
);

export const whoamiFailure = createAction(
    '[Auth API] Get The Curent User Failure',
    props<{ error: string }>()
);

export const showWorkspaceDialog = createAction(
    '[Login Page] Show Change Workspace Dialog'
);

export const searchParticipant = createAction(
    '[Workshop][Workshop Page] Search Participant',
    props<{ text: string }>()
);

export const getInAppNotification = createAction(
    '[In App Notifications] Get In App Notifications',
    props<{ pageNumber: number }>()
);

export const getInAppNotificationSuccess = createAction(
    '[In App Notifications] Get In App Notifications Success',
    props<{ notifications: any }>()
);

export const getInAppNotificationFailure = createAction(
    '[In App Notifications] Get In App Notifications Failure',
    props<{ error: any }>()
);

export const readInAppNotification = createAction(
    '[In App Notifications] Read In App Notifications',
    props<{ id: string }>()
);

export const readInAppNotificationSuccess = createAction(
    '[In App Notifications] Read In App Notifications Success',
    props<{ id: string  }>()
);

export const readInAppNotificationFailure = createAction(
    '[In App Notifications] Read In App Notifications Failure',
    props<{ error: any }>()
);
