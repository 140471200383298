import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    RootStoreState
} from 'app/root-store';
import { WorkshopBrainstormingStoreActions, WorkshopBrainstormingStoreSelectors } from 'app/root-store/workshop-store/brainstorming-store';
import { Board } from '@bsuccess/models/brainstorming/board.model';
import { locale as english } from '../../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/list-step/en';
import { locale as frensh } from '../../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/list-step/fr';
import { locale as arabic } from '../../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/list-step/ar';

@Component({
    selector: 'import-cards-dialog-list-step',
    templateUrl: './list-step.component.html',
    styleUrls: ['./list-step.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ImportCardsDialogListStepComponent implements OnDestroy {
    listId: any;
    selectedList: any;
    board$: Observable<Board>;
    private _unsubscribeAll: Subject<any>;
    constructor(
        public matDialogRef: MatDialogRef<ImportCardsDialogListStepComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.board$ = this._store.pipe(
            select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard)
        );

        this._unsubscribeAll = new Subject();
    }

    save(): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.importCardsToBoard({
                listId: this.selectedList.id,
                cardsId: []
            })
        );
    }
    
    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }
}
