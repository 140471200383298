import { FuseNavigation } from '@fuse/types';
import { HelpHeroService } from '@bsuccess/services/helphero.service';

export const workshopAnimatorNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        type: 'group',
        children: [
            {
                id: 'activities',
                title: 'Activités',
                icon: 'account-group',
                type: 'item',
                url: 'workshop/activities',
                translate: 'ACTIVITIES',
            },
            {
                id: 'notes',
                title: 'Carnet de notes',
                icon: 'file-document-edit-outline',
                type: 'item',
                url: 'workshop/notes',
                translate: 'NOTEBOOK',
            },
            {
                id: 'action-plans',
                title: 'Plans d\'action',
                icon: 'check-box-outline',
                type: 'item',
                url: 'workshop/action-plans',
                translate: 'NAVIGATION_TASKS'
            },
            {
                id: 'session-settings',
                title: 'Paramètres',
                icon: 'settings',
                type: 'item',
                url: 'workshop/session-settings',
                translate: 'SESSION_SETTINGS'
            }
            // {
            //     id: 'video-chat',
            //     title: 'Chat Video',
            //     icon: 'video',
            //     type: 'item',
            //     url: 'workshop/video-chat',
            // }
        ],
    },
    {
        id: 'help',
        title: '',
        type: 'group',
        children: [
            {
                id: 'tutorials',
                title: 'Tutorials',
                type: 'collapsable',
                translate: 'TUTORIALS',
                icon: 'book-open-page-variant',
                children: [
                    {
                        id: 'TUTO_NAVIGATE_SESSION',
                        title: 'Naviguer dans l\'atelier',
                        type: 'item',
                        translate: 'TUTO_NAVIGATE_SESSION',
                        icon: 'book-open-page-variant',
                        function: function(): void {
                            if (HelpHeroService.lang === 'fr') {
                                HelpHeroService.startTourById('WTjBx3oNDyB');
                            } else {
                                HelpHeroService.startTourById('sA7lV6EKNRM');
                            }
                        },
                    },
                ]
            },
            {
                id: 'chat_support',
                title: 'Chat with support',
                icon: 'chat',
                translate: 'CHAT_SUPPORT',
                type: 'item',
                function: function(): void {
                    eval(`$crisp.push(['do', 'chat:show'])`);
                    eval(`$crisp.push(['do', 'chat:open'])`);
                },
            },
        ],
    },
];
