export const locale = {
    lang: 'ar',
    data: {
        BOARD_CREATE_WIDGET_TITLE: 'إنشاء ودجات',
        BOARD_CREATE_WIDGET_INFORMATIONS: 'معلومات الودجات',
        BOARD_CREATE_WIDGET_TEXT_TITLE: 'اسم الودجات',
        BOARD_CREATE_WIDGET_TYPE: 'نوع الودجات',
        BOARD_CREATE_WIDGET_NUMBER: 'عدد',
        BOARD_CREATE_WIDGET_BAR_CHART: 'رسم بياني بالأعمدة',
        BOARD_CREATE_WIDGET_DONUT_CHART: 'رسم تخطيطي دائري',
        BOARD_CREATE_WIDGET_BAR_CHART_TITLE: 'الإحداثيات',
        BOARD_CREATE_WIDGET_BAR_CHART_SECTION: 'مادة',
        BOARD_CREATE_WIDGET_BAR_CHART_OWNER: 'مسؤول',
        BOARD_CREATE_WIDGET_BAR_CHART_MEMBER: 'عضو',
        BOARD_CREATE_WIDGET_BAR_CHART_STATUS: 'حالة',
        BOARD_CREATE_WIDGET_BAR_CHART_PRIORITY: 'الأولوية',
        BOARD_CREATE_WIDGET_BAR_CHART_TAG: 'العلامة',
        BOARD_CREATE_WIDGET_BAR_CHART_DATE: 'تاريخ الاستحقاق',
        BOARD_CREATE_WIDGET_DONUT_CHART_TITLE: 'سلسلة البيانات',
        BOARD_CREATE_WIDGET_FILTERS: 'فَلْتَرَة',
        BOARD_CREATE_WIDGET_WHERE: 'حيت',
        BOARD_CREATE_WIDGET_IS:'هو',
        BOARD_CREATE_WIDGET_MEMBER:'عضو',
        BOARD_CREATE_WIDGET_RESPONSABLE:'المسؤول',
        BOARD_CREATE_WIDGET_DATE_LIMITE:'تاريخ الاستحقاق',
        BOARD_CREATE_WIDGET_PRIORITY:'الأولوية',
        BOARD_CREATE_WIDGET_CATEGORIES:'العلامة',
        BOARD_CREATE_WIDGET_ADD_FILTER:'+ إضافة عامل تصفية',
        BOARD_CREATE_WIDGET_SAVE: 'حفظ',
        BOARD_CREATE_WIDGET_CANCEL:'إلغاء',
        BOARD_CREATE_WIDGET_CH_ONE : 'اليوم',
        BOARD_CREATE_WIDGET_CH_TWO : 'غدا',
        BOARD_CREATE_WIDGET_CH_THREE : 'الأمس',
        BOARD_CREATE_WIDGET_CH_FOUR : 'السبعة أيام القادمة',
        BOARD_CREATE_WIDGET_CH_FIVE : 'السبعة أيام الماضية',
        BOARD_CREATE_WIDGET_CH_SIX : 'البارحة',
        BOARD_CREATE_WIDGET_PR_HIGH : 'أولوية عالية',
        BOARD_CREATE_WIDGET_PR_MID : 'أولوية متوسطة',
        BOARD_CREATE_WIDGET_PR_LOW : 'أولوية منخفضة',
        BOARD_CREATE_WIDGET_COMPLETED : 'منجَزة',
        BOARD_CREATE_WIDGET_INCOMPLETED : 'غيرمنجَزة',
        BOARD_FILTER_RESET : 'فلترة جديدة',
        BOARD_UPDATE_TITLE : 'تحرير القطعة',
        BOARD_UPDATE_EDIT : 'تحرير',
    }
};
