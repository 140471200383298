import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'bsuccess-ping',
    templateUrl: './ping.component.html',
    styleUrls: ['./ping.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BsuccessPingComponent implements OnInit {
    @Input() socketIOPong: Observable<any>;
    @Input() socketIOConnect: Observable<any>;
    constructor() {}

    ngOnInit(): void {}
}
