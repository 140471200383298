import { SignUpUserModel } from './../models/user-sign-up.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserModel } from '@bsuccess/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class AccessService {
    constructor(private http: HttpClient) { }

    getUserById(tenant: string, userId: string): Observable<any> {
        return this.http.get(
            environment.http.url + environment.http.user + '/' + userId,
            {
                headers: {
                    'X-Skip-Interceptor': '',
                    'X-Tenant': tenant,
                },
            }
        );
    }

    updateUser(
        tenant: string,
        firstName: string,
        lastName: string,
        password: string,
        lang: string,
        profilePic: string,
        userId: string
    ): Observable<any> {
        const user = {
            firstName: firstName,
            lastName: lastName,
            password: password,
            lang,
            profilePic: profilePic,
        };
        return this.http.put(
            environment.http.url + environment.http.user + '/' + userId,
            user,
            {
                headers: {
                    'X-Skip-Interceptor': '',
                    'X-Tenant': tenant,
                    'Content-Type': 'text/plain',
                },
            }
        );
    }

    updatePassword(
        email: string,
        password: string,
        tenant: string
    ): Observable<any> {
        return this.http
            .patch(
                environment.http.url + environment.http.userResetPassword,
                {
                    email: email,
                    password: password,
                },
                {
                    headers: {
                        'X-Skip-Interceptor': '',
                        'X-Tenant': tenant,
                        'Content-Type': 'text/plain',
                    },
                }
            );
    }

    generateUser(
        email: string,
        tenant: string,
        sessionKey: string
    ): Observable<any> {
        return this.http.post(
            `${environment.http.url}${environment.http.projectSessionNewUser}` +
            '/' +
            sessionKey,
            JSON.stringify(email),
            {
                headers: {
                    'X-Skip-Interceptor': '',
                    'X-Tenant': tenant,
                    'Content-Type': 'text/plain',
                },
            }
        );
    }

    signUpUser(
        user: SignUpUserModel,
        tenant: string
    ): Observable<any> {
        return this.http.post(
            `${environment.http.url}${environment.http.userSignUp}`,
            JSON.stringify(user),
            {
                headers: {
                    'X-Skip-Interceptor': '',
                    'X-Tenant': tenant,
                    'Content-Type': 'text/plain',
                },
            }
        );
    }

    getPlans(tenant: string): Observable<any> {
        return this.http.get(
            `${environment.http.url}${environment.http.stripePlans}`,
            {
                headers: {
                    'X-Skip-Interceptor': '',
                    'X-Tenant': tenant,
                    'Content-Type': 'text/plain',
                },
            }
        );
    }

    attachPaymentMethod(customerId: string, paymentMethodId: string): Observable<any> {
        return this.http.post(
            `${environment.http.url}${environment.http.stripeAttachPaymentMethod}`,
            {
                customerId,
                paymentMethodId
            }
        );
    }

    subscription(customerId: string, price: string, defaultPaymentMethod: string): Observable<any> {
        return this.http.post(
            `${environment.http.url}${environment.http.stripeSubscription}`,
            {
                customerId,
                price,
                defaultPaymentMethod
            }
        );
    }

    getUserPaymentMethods(): Observable<any> {
        return this.http.get(
            `${environment.http.url}${environment.http.stripeCustomerPaymentMethods}`,
        );
    }

    cancelSubscription(): Observable<any> {
        return this.http.get(
            `${environment.http.url}${environment.http.stripeRemoveSubscription}`,
        );
    }

    addAddon(subscriptionId: string, items: any, defaultPaymentMethod: string): Observable<any> {
        return this.http.post(
            `${environment.http.url}${environment.http.stripeAddAddon}`, {
                subscriptionId,
                items,
                defaultPaymentMethod
            }
        );
    }

    changeSubscriptionPlan(subscriptionId: string, items: any): Observable<any> {
        return this.http.post(
            `${environment.http.url}${environment.http.stripeUpdatePlan}`, {
                subscriptionId,
                items
            }
        );
    }

    getTaxes(): Observable<any> {
        return this.http.get(
            `${environment.http.url}${environment.http.stripeTaxes}`
        );
    }

    checkout(payload: any): Observable<any> {
        return this.http.post(
            `${environment.http.url}${environment.http.stripeCheckout}`, payload
        );
    }
}
