import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { StudioProjectsStoreEffects } from './effects';

@NgModule({
    imports: [
        CommonModule,

        StoreModule.forFeature('studio-projects', reducer),
        EffectsModule.forFeature([StudioProjectsStoreEffects]),
    ],
    providers: [],
})
export class StudioProjectsStoreModule { }
