export const locale = {
    lang: 'fr',
    data: {
        BOARD_CREATE_WIDGET_TITLE: 'Créer un widget',
        BOARD_CREATE_WIDGET_INFORMATIONS: 'Informations du widget',
        BOARD_CREATE_WIDGET_TEXT_TITLE: 'Titre du widget',
        BOARD_CREATE_WIDGET_TYPE: 'Type de widget',
        BOARD_CREATE_WIDGET_NUMBER: 'Nombre',
        BOARD_CREATE_WIDGET_BAR_CHART: 'Histogramme',
        BOARD_CREATE_WIDGET_DONUT_CHART: 'Graphique en anneau',
        BOARD_CREATE_WIDGET_BAR_CHART_TITLE: 'Abscisses',
        BOARD_CREATE_WIDGET_BAR_CHART_SECTION: 'Section',
        BOARD_CREATE_WIDGET_BAR_CHART_OWNER: 'Responsable',
        BOARD_CREATE_WIDGET_BAR_CHART_MEMBER: 'Membre',
        BOARD_CREATE_WIDGET_BAR_CHART_STATUS: 'Statut',
        BOARD_CREATE_WIDGET_BAR_CHART_PRIORITY: 'Priorité',
        BOARD_CREATE_WIDGET_BAR_CHART_TAG: 'Étiquette',
        BOARD_CREATE_WIDGET_BAR_CHART_DATE: 'Échéance',
        BOARD_CREATE_WIDGET_DONUT_CHART_TITLE: 'Secteurs',
        BOARD_CREATE_WIDGET_FILTERS: 'Filtres',
        BOARD_CREATE_WIDGET_WHERE: 'Où',
        BOARD_CREATE_WIDGET_IS:'est',
        BOARD_CREATE_WIDGET_MEMBER:'Membre',
        BOARD_CREATE_WIDGET_RESPONSABLE:'Responsable',
        BOARD_CREATE_WIDGET_DATE_LIMITE:'Date d\'échéance',
        BOARD_CREATE_WIDGET_PRIORITY:'Priorité',
        BOARD_CREATE_WIDGET_ADD_FILTER:'+ Ajouter un filtre',
        BOARD_CREATE_WIDGET_SAVE: 'Enregistrer',
        BOARD_CREATE_WIDGET_CATEGORIES :'Étiquette',
        BOARD_CREATE_WIDGET_CANCEL:'Annuler',
        BOARD_CREATE_WIDGET_CH_ONE : 'Aujourd\'hui',
        BOARD_CREATE_WIDGET_CH_TWO : 'Demain',
        BOARD_CREATE_WIDGET_CH_THREE : 'Hier',
        BOARD_CREATE_WIDGET_CH_FOUR : 'Dans les 7 prochains jours',
        BOARD_CREATE_WIDGET_CH_FIVE : 'Dans les 7 derniers jours',
        BOARD_CREATE_WIDGET_COMPLETED : 'Terminé',
        BOARD_CREATE_WIDGET_INCOMPLETED : 'Non terminé',
        BOARD_CREATE_WIDGET_CH_SIX : 'Hier',
        BOARD_CREATE_WIDGET_PR_HIGH : 'Haute priorité',
        BOARD_CREATE_WIDGET_PR_MID : 'Priorité moyenne',
        BOARD_CREATE_WIDGET_PR_LOW : 'Priorité basse',
        BOARD_FILTER_RESET : 'Réinitialiser',
        BOARD_UPDATE_TITLE : 'Modifier le widget',
        BOARD_UPDATE_EDIT : 'Modifier',
    }
};
