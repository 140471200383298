import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoardCardModel } from '@bsuccess/models/board/board-card.model';
import { RootStoreState } from 'app/root-store';
import {
  StudioBoardStoreActions,
  StudioBoardStoreSelectors,
} from 'app/root-store/studio-store/board-store';
import { locale as arabic } from '../../../../../../i18n/studio/projects/board/dialogs/card/label-selector/ar';
import { locale as english } from '../../../../../../i18n/studio/projects/board/dialogs/card/label-selector/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/board/dialogs/card/label-selector/fr';

@Component({
  selector: 'project-board-label-selector',
  templateUrl: './label-selector.component.html',
  styleUrls: ['./label-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ProjectBoardLabelSelectorComponent implements OnInit, OnDestroy {
  card: BoardCardModel;

  @Output()
  cardLabelsChanged: EventEmitter<any>;

  board$: Observable<any>;
  board: any;
  labelsMenuView: string;
  FieldName = 'Tags';
  FieldType = 'multiple';
  selectedLabel: any;
  newLabel: any;
  createNewLabel: boolean;
  toggleInArray: (item: any, array: any) => any;

  private _unsubscribeAll: Subject<any>;

  constructor(
    public dialogRef: MatDialogRef<ProjectBoardLabelSelectorComponent>,
    private _store: Store<RootStoreState.State>,
    private translationLoaderService: FuseTranslationLoaderService,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.translationLoaderService.loadTranslations(english, frensh, arabic);
    this.board$ = this._store.pipe(
      select(StudioBoardStoreSelectors.selectCurrent)
    );
    this.cardLabelsChanged = new EventEmitter();
    this.labelsMenuView = 'labels';
    this.newLabel = {
      id: '',
      name: 'Label',
      color: 'blue-400',
    };
    this.toggleInArray = FuseUtils.toggleInArray;

    (this.FieldName =
      this.translateService.currentLang.toString() === 'fr'
        ? 'Étiquettes'
        : this.translateService.currentLang.toString() === 'en'
        ? 'Tags'
        : 'العلامات'),
      (this._unsubscribeAll = new Subject());
  }

  ngOnInit(): void {
    this.board$.subscribe((board) => {
      this.board = board;

      this.card = this.board.cards.find((_card) => {
        return this._data === _card.id;
      });

      if (this.createNewLabel) {
        this.selectedLabel = this.card?.labels[this.card?.labels.length-1];
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  onCardLabelsChanged(): void {
    this.cardLabelsChanged.next(true);
    this.createNewLabel = false;
  }

  onLabelChange(name, color, selectedLabel): void {
    this._store.dispatch(
      StudioBoardStoreActions.updateLabel({
        boardId: selectedLabel.boardId,
        color: color.class ? color.class : this.selectedLabel.color,
        id: this.selectedLabel.id,
        name: this.selectedLabel.name
          ? this.selectedLabel.name
          : this.translateService.currentLang.toString() === 'fr'
          ? 'Étiquette'
          : this.translateService.currentLang.toString() === 'en'
          ? 'Label'
          : 'علامة',
      })
    );
    this.createNewLabel = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.card?.labels, event.previousIndex, event.currentIndex);
    this._store.dispatch(
      StudioBoardStoreActions.moveLabel({
        cardId: this.card.id,
        labelId: event.item.data.id,
        dropIndex: event.currentIndex,
      })
    );
    this.createNewLabel = false;
  }

  addNewLabel(name, color): void {
    this.newLabel.id = FuseUtils.generateGUID();
    this.createNewLabel = true;

    this._store.dispatch(
      StudioBoardStoreActions.addLabel({
        cardId: this.card.id,
        color: 'blue-400',
        name:
          this.translateService.currentLang.toString() === 'fr'
            ? 'Étiquette'
            : this.translateService.currentLang.toString() === 'en'
            ? 'Label'
            : 'علامة',
      })
    );

    this.newLabel.name = '';
    this.labelsMenuView = 'labels';
  }

  toggleLabel(label): void {
    this._store.dispatch(
      StudioBoardStoreActions.toggleLabel({
        boardId: this.board.id,
        toggleForm: {
          cardId: this.card.id,
          labelId: label.id,
        },
      })
    );
    this.createNewLabel = false;
  }

  deleteLabel(label): void {
    this._store.dispatch(
      StudioBoardStoreActions.removeLabel({
        boardId: label.boardId,
        label: label,
        labelId: label.id,
      })
    );

    this.labelsMenuView = 'labels';
    this.createNewLabel = false;
  }
}
