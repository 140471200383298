import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { RootStoreState } from 'app/root-store';
import {
    StudioSessionStoreActions,
    StudioSessionStoreSelectors,
} from 'app/root-store/studio-store/session-store';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../../../../i18n/studio/projects/project/dialogs/session-new-dialog/session-new-members-tab/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/project/dialogs/session-new-dialog/session-new-members-tab/fr';
import { locale as arabic } from '../../../../../../i18n/studio/projects/project/dialogs/session-new-dialog/session-new-members-tab/ar';

@Component({
    selector: 'session-new-members-tab',
    templateUrl: './session-new-members-tab.component.html',
    styleUrls: ['./session-new-members-tab.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class SessionNewMembersTabComponent implements OnInit {
    members: ProjectUserModel[] = [];

    constructor(
        public dialogRef: MatDialogRef<SessionNewMembersTabComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.dialogRef.disableClose = true;
        this._store
            .select(StudioSessionStoreSelectors.selectCurrent)
            .subscribe(session => {
                this.members = session.users;
                if (this.members[0]) {
                    this.members[0].position = 'animator';
                }
            });
    }

    arrayContainAnimator(): Observable<boolean> {
        return of(
            this.members.filter(user => user.position === 'animator').length > 0
        );
    }

    add(): void {
        this.members.push({
            _id: FuseUtils.generateGUID(),
            email: '',
            position: 'member',
            role: 'read',
            isParticipant: true
        });
    }

    delete(index: number): void {
        this.members.splice(index, 1);
    }

    updateMember(member: ProjectUserModel): void {
        this.members.map(old => {
            if (old._id === member._id) {
                old = member;
                if (old.position === 'animator') {
                    old.isParticipant = false;
                }
            }
        });

    }

    save(): void {
        this._store.dispatch(
            StudioSessionStoreActions.updateSessionUsers({
                users: this.members.map(_ => {
                    return {
                        ..._,
                        email: _.email.trim().toLowerCase()
                    };
                }),
            })
        );
    }

    animatorExists(): number {
        return this.members.filter(member => member.position === 'animator').length;
    }
}
