export const locale = {
    lang: 'ar',
    data: {
        ALL_CARDS: 'جميع البطاقات',
        LIST_WHERE_TO_PLACE_TWO:'ما البطاقات التي تريد استيرادها؟',
        CARDS_LIST: 'بطاقات من القوائم',
        CHOOSE_CARDS: 'اختر البطاقات',
        CANCEL: 'إلغاء',
        SAVE: 'حِفْظْ',
    }
};
