import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SortablejsModule } from 'ngx-sortablejs';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';

import { SessionTasksExportDialogComponent } from './tasks-export-dialog/tasks-export-dialog.component';
import { SessionUpdateMembersDialogComponent } from './update-members-dialog/update-members-dialog.component';
import { SessionUpdateParticipantsAndSendInvitationsDialogComponent } from './update-participants-and-send-invitation-dialog/update-participants-and-send-invitation-dialog.component';
import { SessionTaskContentDialogComponent } from './task-content-dialog/task-content-dialog.component';
import { FuseMaterialColorPickerModule } from '@fuse/components';
import { SessionTaskLabelSelectorComponent } from './task-content-dialog/label-selector/label-selector.component';
import { SessionUpdateGuestsAndSendInvitationsDialogComponent } from './update-guests-and-send-invitation-dialog/update-guess-and-send-invitation-dialog.component';
import { SessionCommentDialogComponent } from './comment-dialog/comment-dialog.component';

@NgModule({
    declarations: [
        SessionTasksExportDialogComponent,
        SessionUpdateMembersDialogComponent,
        SessionUpdateParticipantsAndSendInvitationsDialogComponent,
        SessionUpdateGuestsAndSendInvitationsDialogComponent,
        SessionTaskContentDialogComponent,
        SessionTaskLabelSelectorComponent,
        SessionCommentDialogComponent,
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatTabsModule,
        MatToolbarModule,
        MatStepperModule,
        MatDatepickerModule,
        MatRadioModule,
        MatCheckboxModule,
        MatCardModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatTableModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatProgressBarModule,
        TranslateModule,
        FuseMaterialColorPickerModule,
        SortablejsModule,
    ]
})
export class SessionDialogsModule { }
