export const locale = {
    lang: 'en',
    data: {
        DIALOG_BOARD_SUBSCRIBE_SUBSCRIBE_TO_BOARD : 'Subscribe to the board' ,
        DIALOG_BOARD_SUBSCRIBE_SEND_BY_EMAIL: 'Send me by email:',
        DIALOG_BOARD_SUBSCRIBE_DAILY_SUMMARIES: 'Daily summaries',
        DIALOG_BOARD_SUBSCRIBE_DAILY_SUMMARIES_COMPLETED: 'Completed tasks, upcoming due dates and tasks that are late as of this day.',
        DIALOG_BOARD_SUBSCRIBE_WEEKLY_SUMMARIES: 'Weekly summaries',
        DIALOG_BOARD_SUBSCRIBE_WEEKLY_SUMMARIES_COMPLETED: 'Completed tasks, upcoming due dates and tasks that are late this week.',
        DIALOG_BOARD_SUBSCRIBE_SAVE: 'Save',
    }
};
