import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../i18n/access/dialogs/terms-dialog/en';
import { locale as frensh } from '../../../i18n/access/dialogs/terms-dialog/fr';
import { locale as arabic } from '../../../i18n/access/dialogs/terms-dialog/ar';

@Component({
    selector: 'access-sign-up-terms-dialog',
    templateUrl: 'terms-dialog.component.html',
    styleUrls: ['terms-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AccessSignUpTermsDialogComponent {
    constructor(
        public translateService: TranslateService,
        public dialogRef: MatDialogRef<AccessSignUpTermsDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

}
