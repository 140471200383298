export const locale = {
    lang: 'fr',
    data: {
        DIALOG_BOARD_SUBSCRIBE_SUBSCRIBE_TO_BOARD : 'Souscrire au tableau' ,
        DIALOG_BOARD_SUBSCRIBE_SEND_BY_EMAIL: 'Recevoir par email :',
        DIALOG_BOARD_SUBSCRIBE_DAILY_SUMMARIES: 'Des résumés quotidiens',
        DIALOG_BOARD_SUBSCRIBE_DAILY_SUMMARIES_COMPLETED: 'Actions terminées, en cours et en retard à ce jour.',
        DIALOG_BOARD_SUBSCRIBE_WEEKLY_SUMMARIES: 'Des résumés hebdomadaires',
        DIALOG_BOARD_SUBSCRIBE_WEEKLY_SUMMARIES_COMPLETED: 'Actions terminées, en cours et en retard de la semaine.',
        DIALOG_BOARD_SUBSCRIBE_SAVE: 'Enregistrer',
    }
};
