import { WorkshopCardboardStoreActions } from 'app/root-store/workshop-store/cardboard-store';
import { WorkshopCanvasStoreActions } from 'app/root-store/workshop-store/canvas-store';
import { WorkshopBrainstormingStoreActions } from 'app/root-store/workshop-store/brainstorming-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, withLatestFrom, exhaustMap, tap, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import * as NavbarActions from './actions';
import { LoginStoreSelectors, LoginStoreActions } from '../login-store';
import { LocalStorageService } from '@bsuccess/services/local-storage.service';
import { AuthService } from '@bsuccess/services/auth.service';
import { RootStoreState } from '..';
import { NavbarStoreSelectors, NavbarStoreActions } from '.';
import { workshopAnimatorNavigation } from 'app/navigation/workshop-animator-navigation';
import { workshopReporterNavigation } from 'app/navigation/workshop-reporter-navigation';
import { navigation } from 'app/navigation/navigation';
import { studioAdminNavigation } from 'app/navigation/studio-admin-navigation';
import { studioNavigation } from 'app/navigation/studio-navigation';
import { locale as english } from '../../i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../i18n/@fuse/components/confirm-dialog/fr';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@bsuccess/services/users.service';
import { of } from 'rxjs';
import { HelpHeroService } from '@bsuccess/services/helphero.service';
import { StudioProjectStoreSelectors } from '../studio-store/project-store';

@Injectable()
export class NavbarStoreEffects {
    confirmConnection: any;
    confirmSession: any;
    constructor(
        private _store: Store<RootStoreState.State>,
        private _authService: AuthService,
        private _localStorageService: LocalStorageService,
        private _actions$: Actions,
        private _router: Router,
        private _matDialog: MatDialog,
        private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private _usersService: UsersService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh);

        this.translate.stream('CONFIRM_CONNECTION').subscribe(
            value => { this.confirmConnection = value; }
        );
        this.translate.stream('CONFIRM_SESSION').subscribe(
            value => { this.confirmSession = value; }
        );
    }

    updateProduct$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NavbarActions.updateProduct),
            withLatestFrom(this._store.pipe(select(LoginStoreSelectors.selectLoginToken))),
            withLatestFrom(this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))),
            withLatestFrom(this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))),
            map(([[[action, token], project], user]) => {
                // On logout product will be studio, so we need to check if token is valid before going to login page
                if (action.product === 'studio' && !this._authService.isTokenExpired(token)) {
                    if (project._id) {
                        this._router.navigate(['studio/projects/one/sessions/' + project._id], { replaceUrl: true });
                    } else {
                        if (user) {
                            if (user.recentProject) {
                                this._router.navigate(['studio/projects/one/sessions/' + user.recentProject], { replaceUrl: true });
                            } else {
                                this._router.navigate(['studio/projects'], { replaceUrl: true });
                            }
                        } else {
                            this._router.navigate(['studio/projects'], { replaceUrl: true });
                        }
                    }
                } else if (action.product === 'notes') {
                    this._router.navigate(['workshop/notes'], { replaceUrl: true });
                } else if (action.product === 'workshop') {
                    this._router.navigate(['workshop/activities'], { replaceUrl: true });
                } else {
                    this._router.navigate(['login']);
                }
                return NavbarActions.updateProductSuccess({
                    product: action.product
                });
            })
        )
    );


    leaveSession$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(NavbarActions.leaveWorkshop),
                withLatestFrom(this._store.pipe(select(LoginStoreSelectors.selectSessionIsPrivate))),
                withLatestFrom(this._store.pipe(select(LoginStoreSelectors.selectLoginSessionRole))),
                withLatestFrom(this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))),
                exhaustMap(([[[_, isPrivate], role], user]) => {
                    const matDialogRef = this._matDialog.open(
                        FuseConfirmDialogComponent,
                        {
                            panelClass: 'fuse-confirm-dialog',
                            disableClose: false,
                        }
                    );

                    matDialogRef.componentInstance.confirmMessage = this.confirmSession;

                    return matDialogRef.afterClosed().pipe(
                        map(confirmed => {
                            if (confirmed) {
                                if (isPrivate) {
                                    this._store.dispatch(NavbarActions.leaveWorkshopConfirmed());
                                } else {
                                    if (role === 'animator') {
                                        this._store.dispatch(NavbarActions.leaveWorkshopConfirmed());
                                    } else {
                                        if (!user.email.includes('excelway-dummy.co')) {
                                            this._store.dispatch(NavbarActions.leaveWorkshopConfirmed());
                                        } else {
                                            this._store.dispatch(NavbarActions.logoutConfirmed());
                                        }
                                    }
                                }
                            } else {
                                this._store.dispatch(NavbarActions.leaveWorkshopCanceled());
                            }
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    // TODO Remove update product navigation
    switchToStudio$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                NavbarActions.leaveWorkshopConfirmed,
                NavbarActions.logoutConfirmed,
                LoginStoreActions.notAuthorized
            ),
            map(_ => NavbarActions.updateProduct({ product: 'studio' }))
        )
    );

    updateCache$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(NavbarActions.updateProductSuccess),
                map(action => {
                    const cache = this._localStorageService.getCache();
                    this._localStorageService.setCache({
                        ...cache,
                        product: action.product,
                    });
                })
            ),
        { dispatch: false }
    );

    updateCache2$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LoginStoreActions.loadSessionSuccess),
                map(_ => {
                    const cache = this._localStorageService.getCache();
                    this._localStorageService.setCache({
                        ...cache,
                        product: 'workshop',
                    });
                })
            ),
        { dispatch: false }
    );

    navigation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                LoginStoreActions.loadCacheSuccess,
                LoginStoreActions.loadSessionSuccess,
                NavbarActions.updateProductSuccess
            ),
            map(() => NavbarActions.updateNavigation())
        )
    );

    updateNavigation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NavbarActions.updateNavigation),
            withLatestFrom(
                this._store.pipe(select(NavbarStoreSelectors.selectProduct))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginSessionRole))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectIsUserAdmin))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectIsUserReporter))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectTasksCount))
            ),
            map(([[[[[_, product], sessionRole], IsUserAdmin], IsUserReporter], tasksCount]) => {
                if (product === 'studio') {
                    if (IsUserAdmin) {
                        return NavbarActions.updateNavigationSuccess({
                            navigation: studioAdminNavigation,
                        });
                    } else {
                        return NavbarActions.updateNavigationSuccess({
                            navigation: studioNavigation,
                        });
                    }

                } else {
                    if (product === 'notes') {
                        return NavbarActions.goToNotes();
                    }
                    // else product is workshop
                    else {
                        if (sessionRole) {
                            if (sessionRole === 'animator') {
                                const reworkedWorkshopAnimatorNavigation = workshopAnimatorNavigation;
                                reworkedWorkshopAnimatorNavigation[0].children[2].badge = {
                                    title: tasksCount.toString(),
                                    bg: 'red',
                                    fg: 'white'
                                };
                                return NavbarActions.updateNavigationSuccess({
                                    navigation: reworkedWorkshopAnimatorNavigation,
                                });
                            } else if (IsUserReporter) {
                                return NavbarActions.updateNavigationSuccess({
                                    navigation: workshopReporterNavigation,
                                });
                            } else {
                                return NavbarActions.updateNavigationSuccess({
                                    navigation: navigation,
                                });
                            }
                        } else {
                            return NavbarActions.updateNavigationFailure();
                        }
                    }
                }
            })
        )
    );

    goToNotes$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(NavbarActions.goToNotes),
                tap(() =>
                    this._store.dispatch(
                        NavbarActions.updateNavigationSuccess({
                            navigation: navigation,
                        })
                    )
                )
            ),
        {
            dispatch: false,
        }
    );

    logoutConfirmation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NavbarStoreActions.logout),
            exhaustMap(() => {
                const matDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                    disableClose: false,
                    panelClass: 'fuse-confirm-dialog'
                });

                matDialogRef.componentInstance.confirmMessage = this.confirmConnection;

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return NavbarStoreActions.logoutConfirmed();
                        } else {
                            return NavbarStoreActions.logoutCanceled();
                        }
                    })
                );
            })
        )
    );

    updateLang$ = createEffect(() =>
        this._actions$.pipe(
            ofType(NavbarActions.updateLang),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoggedUser)
            ),
            exhaustMap(([_, user]) =>
                this._usersService
                    .updateUserLang(user._id, _.lang)
                    .pipe(
                        map(_response => {
                            const cache = this._localStorageService.getCache();
                            this._localStorageService.setCache({
                                ...cache,
                                login: {
                                    ...cache.login,
                                    user: {
                                        ...cache.login.user,
                                        lang: _.lang
                                    }
                                },
                            });
                            HelpHeroService.helphero.identify(user._id, {
                                language: user.lang
                            });
                            HelpHeroService.lang = _.lang;

                            return NavbarActions.updateLangSuccess({
                                lang: _.lang,
                            });
                        }),
                        catchError(error =>
                            of(
                                NavbarActions.updateLangFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    goToHome$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(NavbarActions.goToHome),
                withLatestFrom(this._store.pipe(select(NavbarStoreSelectors.selectProduct))),
                tap(([_, product]) => {
                    if (product === 'studio') {
                        this._router.navigate(['studio/projects']);
                    } else if (product === 'workshop') {
                        this._router.navigate(['workshop/activities']);
                    }
                }
                )
            ),
        {
            dispatch: false,
        }
    );

    updateNavigationAfterTask$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                WorkshopBrainstormingStoreActions.addActionPlanSuccess,
                WorkshopCanvasStoreActions.addActionPlanSuccess,
                WorkshopCardboardStoreActions.addActionPlanSuccess,
                WorkshopBrainstormingStoreActions.cardsToActionplanSuccess,
                WorkshopCanvasStoreActions.cardsToActionplanSuccess,
            ),
            withLatestFrom(
                this._store.pipe(select(NavbarStoreSelectors.selectProduct))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginSessionRole))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectIsUserAdmin))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectIsUserReporter))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectTasksCount))
            ),
            map(([[[[[_, product], sessionRole], IsUserAdmin], IsUserReporter], tasksCount]) => {
                if (product === 'studio') {
                    if (IsUserAdmin) {
                        return NavbarActions.updateNavigationSuccess({
                            navigation: studioAdminNavigation,
                        });
                    } else {
                        return NavbarActions.updateNavigationSuccess({
                            navigation: studioNavigation,
                        });
                    }

                } else {
                    if (product === 'notes') {
                        return NavbarActions.goToNotes();
                    }
                    // else product is workshop
                    else {
                        if (sessionRole) {
                            if (sessionRole === 'animator') {
                                const reworkedWorkshopAnimatorNavigation = workshopAnimatorNavigation;
                                reworkedWorkshopAnimatorNavigation[0].children[2].badge = {
                                    title: tasksCount.toString(),
                                    bg: 'red',
                                    fg: 'white'
                                };
                                return NavbarActions.updateNavigationSuccess({
                                    navigation: reworkedWorkshopAnimatorNavigation,
                                });
                            } else if (IsUserReporter) {
                                return NavbarActions.updateNavigationSuccess({
                                    navigation: workshopReporterNavigation,
                                });
                            } else {
                                return NavbarActions.updateNavigationSuccess({
                                    navigation: navigation,
                                });
                            }
                        } else {
                            return NavbarActions.updateNavigationFailure();
                        }
                    }
                }
            })
        )
    );

    changeNavbar$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    NavbarActions.checkNavbar,
                ),
                withLatestFrom(
                    this._store.pipe(select(NavbarStoreSelectors.selectProduct))
                ),
                tap(([_, product]) => {
                    if (product !== 'studio') {
                        this._store.dispatch(NavbarActions.updateProduct({ product: 'studio' }));
                    }
                })
            ),
        {
            dispatch: false,
        }
    );
}
