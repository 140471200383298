export const locale = {
    lang: 'en',
    data: {
        // tslint:disable-next-line: max-line-length
        PARAGRAPH: 'Invite members to collaborate on the project. If they don\'t have an Excelway account, they will receive an invitation to create one.',
        MEMBERS: 'Member',
        THE_EMAIL: 'Email',
        POSITION: 'Position',
        SPONSOR: 'Sponsor',
        ADMIN: 'Admin',
        ADD_USER_BUTTON_LABEL: 'Add user',
        RESPONSIBLE: 'Owner',
        ROLE: 'Role',
        READING: 'Viewer',
        MODIFICATION: 'Editor',
        REMOVE: 'Remove',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        APPLY_CHANGES_ON_CHILDREN: 'Apply changes to all boards and adjoining sub-projects'
    }
};
