export const locale = {
    lang: 'en',
    data: {
        PROJECTS_BOARD_NEW_MEMBERS_INVITAION: 'Invite members to collaborate on the board. If they do not have an Excelway account, they will receive an invitation to create one.',
        PROJECTS_BOARD_NEW_MEMBERS_MEMBERS: 'Members',
        PROJECTS_BOARD_NEW_MEMBERS_THE_EMAIL: 'Email',
        PROJECTS_BOARD_NEW_MEMBERS_POSITION: 'Position',
        PROJECTS_BOARD_NEW_MEMBERS_SPONSOR: 'Sponsor',
        PROJECTS_BOARD_NEW_MEMBERS_ADMIN: 'Admin',
        PROJECTS_BOARD_NEW_MEMBERS_ADD_USER_BUTTON_LABEL: 'Add user',
        PROJECTS_BOARD_NEW_MEMBERS_RESPONSIBLE: 'Owner',
        PROJECTS_BOARD_NEW_MEMBERS_ROLE: 'Role',
        PROJECTS_BOARD_NEW_MEMBERS_READING: 'Viewer',
        PROJECTS_BOARD_NEW_MEMBERS_MODIFICATION: 'Editor',
        PROJECTS_BOARD_NEW_MEMBERS_REMOVE: 'Remove',
        PROJECTS_BOARD_NEW_MEMBERS_SAVE: 'Save',
        PROJECTS_BOARD_NEW_MEMBERS_CANCEL: 'Cancel',
    }
};
