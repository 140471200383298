import { AccessStoreActions, AccessStoreSelectors } from 'app/root-store/access-store';
import { Observable } from 'rxjs';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootStoreState } from 'app/root-store';

import { locale as english } from '../../../i18n/access/dialogs/add-ons-dialog/en';
import { locale as arabic } from '../../../i18n/access/dialogs/add-ons-dialog/ar';
import { locale as frensh } from '../../../i18n/access/dialogs/add-ons-dialog/fr';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { UserModel } from '@bsuccess/models/user.model';

@Component({
    selector: 'access-add-ons-dialog',
    templateUrl: './add-ons-dialog.component.html',
    styleUrls: ['./add-ons-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccessAddOnsDialogComponent implements OnInit {
    selectedAddon: any;
    addons$: Observable<any[]>;
    firstFormGroup: UntypedFormGroup;
    secondFormGroup: UntypedFormGroup;
    isEditable = false;
    quantity = 1;
    totalXQuantity = 0;

    paymentAddonDone$: Observable<any>;
    pendingPaymentAddon$: Observable<boolean>;

    tenant$: Observable<any>;
    tenant: any;

    user$: Observable<UserModel>;
    user: UserModel;

    paymentMehtods$: Observable<any[]>;
    paymentMehtods = [];
    selectedPaymentMethod: any;
    constructor(
        public dialogRef: MatDialogRef<AccessAddOnsDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        private _store: Store<RootStoreState.State>,
        private _formBuilder: UntypedFormBuilder,
    ) { this.translationLoaderService.loadTranslations(english, arabic, frensh); }

    ngOnInit(): void {
        this._store.dispatch(AccessStoreActions.getPlans());
        this._store.dispatch(AccessStoreActions.getUserPaymentMehods());
        this.addons$ = this._store.pipe(select(AccessStoreSelectors.selectAddons));
        this.paymentMehtods$ = this._store.pipe(select(AccessStoreSelectors.selectUserPaymentMethods));

        this.tenant$ = this._store.pipe(select(LoginStoreSelectors.selectLoginTenant));
        this.tenant$.subscribe(tenant => {
            this.tenant = tenant;
        });

        this.user$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoggedUser)
        );

        this.user$.subscribe(user => {
            this.user = user;
        });
        
        this.pendingPaymentAddon$ = this._store.pipe(select(AccessStoreSelectors.selectPendingPaymentAddon));
        this.paymentAddonDone$ = this._store.pipe(select(AccessStoreSelectors.selectPaymentAddonDone));
        this.firstFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });

        this.secondFormGroup = this._formBuilder.group({
            secondCtrl: ['', Validators.required]
        });

        this.paymentMehtods$.subscribe(paymentMethods => {
            this.paymentMehtods = paymentMethods;
        })
    }

    selectAddon(addon): void {
        if(!this.user.stripeInfo?.sumoLing){
            if(this.tenant == 'excelway'){
                if(addon == 'month'){
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1KgWgkIASQYBUqwRGIqOewsW'
                    }));
                }else{
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1KgWPOIASQYBUqwR5aB1GlME'
                    }));
                }
            }else{
                if(addon == 'month'){
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1Kfo89IASQYBUqwRWb4QSqL9'
                    }));
                }else{
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1Kfnx4IASQYBUqwR5TJL8VE0'
                    }));
                }
            }
        }else{
            if(this.tenant == 'excelway'){
                if(this.user.subscription?.plan?.id == 'price_1KixbtIASQYBUqwRZG5gZAyj'){
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1KxqrMIASQYBUqwRWxEqP0Zz'
                    }));
                }
                if(this.user.subscription?.plan?.id == 'price_1KixcFIASQYBUqwREc5kxaxm'){
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1KyHKaIASQYBUqwR0HdfLI4C'
                    }));
                }
                if(this.user.subscription?.plan?.id == 'price_1KmHDmIASQYBUqwRcLPiVqYk'){
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1KyHWBIASQYBUqwRrQCzMNjN'
                    }));
                } 
            }else{
                if(this.user.subscription?.plan?.id == 'price_1KpucZIASQYBUqwR21qUVaEz'){
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1KyGlYIASQYBUqwRHVSNG4w8'
                    }));
                }
                if(this.user.subscription?.plan?.id == 'price_1KpucQIASQYBUqwRIBckaom6'){
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1KyH3QIASQYBUqwR3dllBJAt'
                    }));
                }
                if(this.user.subscription?.plan?.id == 'price_1KpucKIASQYBUqwRdEmaqh74'){
                    this._store.dispatch(AccessStoreActions.checkout({
                        id: 'price_1KyHBwIASQYBUqwRai0OPeAq'
                    }));
                }
            }
        }
    }

    addAddon(): void {
        this._store.dispatch(AccessStoreActions.addAddon({
            items: [
                {
                    price: this.selectedAddon.id,
                    quantity: this.quantity,
                }
            ],
            defaultPaymentMethod: this.selectedPaymentMethod
        }));
    }

    getTotalParticipants(participants: string): void {
        this.totalXQuantity = parseInt(participants) * this.quantity;
    }

    updateParticipant(participants: string): void {
        this.totalXQuantity = parseInt(participants) * this.quantity;
    }

}
