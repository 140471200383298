export const locale = {
    lang: 'fr',
    data: {
        SELECT: 'Séléctionner un contenu',
        CONTENT_ID: 'Id du contenu',
        CONTENT_NAME: 'Nom du contenu',
        UPLOAD: 'Importer',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
        FILTER: 'Filtre',
    }
};
