import { createAction, props } from '@ngrx/store';

import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';

export const load = createAction('[Studio][Sessions Page] Load Sessions');

export const loadSuccess = createAction(
    '[Studio][Sessions API] Load Sessions Success',
    props<{
        response: { sessions: ProjectSessionModel[] };
    }>()
);

export const loadFailure = createAction(
    '[Studio][Sessions API] Load Sessions Failure',
    props<{ error: string }>()
);

export const searchSession = createAction(
    '[Studio][Studio][Sessions Page] Search Session',
    props<{ text: string }>()
);

export const searchSessionByStatus = createAction(
    '[Studio][Sessions Page] Search Session By Status',
    props<{ status: string }>()
);

export const getGlobalReport = createAction(
    '[Studio][Sessions Page] Download Global Report',
    props<{ token: string }>()
);

export const getGlobalReportSuccess = createAction(
    '[Studio][Sessions Page] Download Global Report Success',
    props<{
        report: Blob;
    }>()
);

export const getGlobalReportFailure = createAction(
    '[Studio][Sessions Page] Download Global Report Failure',
    props<{ error: string }>()
);
