export const locale = {
    lang: 'fr',
    data: {
        PROJECT_DIALOGS_DOCUMENT_EDIT_EDIT_DOCUMENT: 'Modifier un document',
        PROJECT_DIALOGS_DOCUMENT_EDIT_TITLE_DOCUMENT: 'Titre du Document',
        PROJECT_DIALOGS_DOCUMENT_EDIT_REPORT: 'Compte-Rendu',
        PROJECT_DIALOGS_DOCUMENT_EDIT_TYPE: 'Type',
        PROJECT_DIALOGS_DOCUMENT_EDIT_PRESENTATION: 'Présentation',
        PROJECT_DIALOGS_DOCUMENT_EDIT_OTHER: 'Autre',
        PROJECT_DIALOGS_DOCUMENT_EDIT_SAVE: 'Enregistrer',
        PROJECT_DIALOGS_DOCUMENT_EDIT_CANCEL: 'Annuler',
    }
};
