import { FuseNavigation } from '@fuse/types';
import { HelpHeroService } from '@bsuccess/services/helphero.service';


export const studioAdminNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        type: 'group',
        children: [
            {
                id: 'sessions',
                title: 'NAVIGATION_SESSIONS',
                icon: 'account-group',
                type: 'item',
                url: 'studio/projects/one/sessions/',
                baseUrl: 'studio/projects/one/sessions/',
            },
            {
                id: 'boards',
                title: 'NAVIGATION_BOARDS',
                icon: 'apps',
                type: 'item',
                url: 'studio/projects/one/boards/',
                baseUrl: 'studio/projects/one/boards/',
            },
            {
                id: 'documents',
                title: 'NAVIGATION_DOCUMENTS',
                icon: 'file-document',
                type: 'item',
                url: 'studio/projects/one/documents/',
                baseUrl: 'studio/projects/one/documents/',
            },
            {
                id: 'details',
                title: 'NAVIGATION_DETAILS',
                icon: 'information',
                type: 'item',
                url: 'studio/projects/one/details/',
                baseUrl: 'studio/projects/one/details/',
            },
        ],
    },
];
