export const locale = {
    lang: 'fr',
    data: {
     HEADER_TITLE: 'Paramètres d\'atelier',
     FIRST_SECTION_TITLE: 'Informations sur l\'atelier',
     COMPOSE_FORM_NAME: 'Nom d\'atelier',
     COMPOSE_FORM_START_DATE: 'Date de début',
     DESCRIPTION: 'Description',
     COMPOSE_FORM_END_DATE: 'Date de fin',
     SECOND_SECTION_TITLE: 'Confidentialité d\'atelier',
     PRIVATE_SESSION_BUTTON: 'Atelier privé',
     PUBLIC_SESSION_BUTTON: 'Atelier public',
     PRIVATE_SESSION_DESCRIPTION: 'Uniquement accessible aux personnes invitées par email. Compte Excelway requis.',
     PUBLIC_SESSION_DESCRIPTION: 'Accessible à toute personne disposant du lien. Pas de compte Excelway requis.',
     INVITATION_SECTION_TITLE: 'Invitation des participants',
     INVITATION_SECTION_DESCRIPTION: 'Invitez des personnes à rejoindre cet atelier',
     INVITATION_DIALOG_BUTTON: 'Inviter',
     LAST_SECTION_TITLE: 'Clôturer l\'atelier',
     LAST_SECTION_DESCRIPTION: 'La clôture de votre atelier changera son statut en clôturé',
     DELETE_SESSION_BUTTON: 'Clôturer l\'atelier',
     SNACKBAR_MESSAGE: 'Cet atelier a été clôturé',
     SNACKBAR_ACTION: 'Fermer',
     SESSION_ADD_TIME:'Ajouter horaire',
     SESSION_START_TIME:'Heure de début',
     SESSION_END_TIME:'Heure de fin',
     VALIDATORS_START_DATE_REQUIRED: 'La date de début est obligatoire.' ,
     VALIDATORS_END_DATE_REQUIRED: 'La date de fin est obligatoire.' ,  
     VALIDATORS_END_DATE_BEFORE: 'La date de début est postérieure à la date de fin' ,
     VALIDATORS_INVALID_START_TIME: 'L\'heure de début est invalide' ,
     VALIDATORS_INVALID_END_TIME: 'L\'heure de fin est invalide' ,  
     VALIDATORS_END_TIME_BEFORE: 'L\'heure de début est supérieure à l\'heure de fin' ,
     TEMPLATE_BRAINSTORMING: 'Idéation',
     TEMPLATE_AGIL: 'Processus agiles',
     TEMPLATE_PRODUCT_MANAGEMENT: 'Gestion de produit',
     TEMPLATE_TEAM_BUILDING: "Renforcement d'équipe",
     TEMPLATE_PROSPECTIVE: 'Résolution de problèmes',
     TEMPLATE_STRATEGY: 'Stratégie',
     TEMPLATE_TEMPLATE_SETTINGS:'Paramètres du modèle',
     TEMPLATE_TEMPLATE_INFORMATIONS:'Informations sur le modèle',
     TEMPLATE_TEMPLATE_NAME:'Nom du modèle',
     TEMPLATE_TEMPLATE_SHORT_DESCRIPTION:'Description courte',
     TEMPLATE_TEMPLATE_LONG_DESCRIPTION:'Description longue',
     TEMPLATE_TEMPLATE_THUMBNAIL:'Charger une vignette',
     TEMPLATE_TEMPLATE_THUMBNAIL_INFORMATIONS:'L\'image doit avoir un format d\'image 4:3 et ne pas dépasser 1 Mo.',
     TEMPLATE_TEMPLATE_REMOVE:'Retirer',
     TEMPLATE_TEMPLATE_CATEGORY:'Catégorie',
     TEMPLATE_TEMPLATE_PROJECTS:'Projets',
     TEMPLATE_TEMPLATE_PROJECTS_DESCRIPTION:'Sélectionner les projets où le modèle sera disponible',
     TEMPLATE_TEMPLATE_DELETE_TEMPLATE:'Supprimer le modèle',
     TEMPLATE_TEMPLATE_DELETE_TEMPLATE_DESCRIPTION:'Supprimer le modèle de tous les projets',
    }
   };
