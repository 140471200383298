export const locale = {
    lang: 'fr',
    data: {
        CATEGORIES: 'Couleurs',
        ADD: 'Ajouter',
        EDIT_CATEGORY: 'Modifier',
        DELETE_CATEGORY: 'Supprimer',
        ADD_CATEGORY: 'Ajouter couleur',
        ADD_U: 'AJOUTER',
        NAME: 'Nom'
    }
};
