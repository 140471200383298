import { Activity } from '@bsuccess/models/activities/activity.model';

export interface State {
    currentActivity: Activity;
    error: string;
}

export const initialState: State = {
    currentActivity: null,
    error: null,
};
