import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { WorkshopCardRatingStoreEffects } from './effects';
import { BsuccessModule } from '@bsuccess/bsuccess.module';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('workshop-cardrating', reducer),
        EffectsModule.forFeature([WorkshopCardRatingStoreEffects]),

        BsuccessModule,
    ],
    providers: [],
})
export class WorkshopCardRatingStoreModule { }
