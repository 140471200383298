export const locale = {
    lang: 'ar',
    data: {
        EDIT_DETAILS: 'تحرير تفاصيل الورشة',
        SESSION_NAME: 'اسم الورشة',
        DESCRIPTION: 'الوصف',
        OBLIGATORY: 'ورشة خاصة: يحتاج المشاركون إلى تسجيل الدخول',
        WITHOUT: 'ورشة عامة: لا يحتاج المشاركون إلى تسجيل الدخول',
        START_DATE: 'تاريخ البدء',
        END_DATE: 'تاريخ الانتهاء',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
    }
};
