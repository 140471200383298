import {
  AccessStoreActions,
  AccessStoreSelectors,
} from 'app/root-store/access-store';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ProfileStoreSelectors } from 'app/root-store/profile-store';
import { RootStoreState } from 'app/root-store';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from '@bsuccess/models/user.model';
import { locale as arabic } from '../../../i18n/access/dialogs/pricing-plans/ar';
import { locale as english } from '../../../i18n/access/dialogs/pricing-plans/en';
import { locale as frensh } from '../../../i18n/access/dialogs/pricing-plans/fr';

@Component({
  selector: 'update-subscription-dialog',
  templateUrl: './update-subscription.component.html',
  styleUrls: ['./update-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateSubscriptionComponent implements OnInit {
  plans$: Observable<any[]>;
  isYearlyPlan: boolean;
  plans: any[];
  yearlyPlans: any[];
  monthlyPlans: any[];
  user$: Observable<UserModel>;
  userPaymentMehtods$: Observable<any>;
  subscription$: Observable<any>;
  subscriptionItems = [];
  subscriptionItemsIds = [];

  constructor(
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<UpdateSubscriptionComponent>,
    private translationLoaderService: FuseTranslationLoaderService,
    private _store: Store<RootStoreState.State>
  ) {
    this.translationLoaderService.loadTranslations(english, arabic, frensh);
  }

  check() {
    if (this.isYearlyPlan) {
      this.plans = this.monthlyPlans;
    } else {
      this.plans = this.yearlyPlans;
    }
  }

  ngOnInit(): void {
    this._store.dispatch(AccessStoreActions.getPlans());
    this.plans$ = this._store.pipe(
      select(AccessStoreSelectors.selectYearlyPlans)
    );
    this.subscription$ = this._store.pipe(
      select(LoginStoreSelectors.selectSubscription)
    );
    this.plans$.subscribe((plans) => {
      this.yearlyPlans = plans;
    });
    this.plans$ = this._store.pipe(select(AccessStoreSelectors.selectPlans));
    this.plans$.subscribe((plans) => {
      this.monthlyPlans = plans;
      this.plans = plans;
    });
    this.check();
    this._store.dispatch(AccessStoreActions.getUserPaymentMehods());
    this.userPaymentMehtods$ = this._store.pipe(
      select(AccessStoreSelectors.selectUserPaymentMethods)
    );
    this.user$ = this._store.pipe(select(ProfileStoreSelectors.selectUser));
    this.subscription$.subscribe((subscription) => {
      if (subscription) {
        if (subscription.items) {
          if (subscription.items.data) {
            if (subscription.status !== 'canceled') {
              this.subscriptionItems = subscription.items.data;
              this.subscriptionItemsIds = subscription.items.data.map(_ => _.plan.id);
            }
          }
        }
      }
    });
  }

  showPayment(): void {
    this._store.dispatch(AccessStoreActions.showPaymentDialog());
    this.dialogRef.close(false);
  }

  selectPlan(plan): void {
    this._store.dispatch(
      AccessStoreActions.selectPricing({
        plan,
      })
    );
  }

  showPaymentChangePlanDialog(newPlan): void {
    this._store.dispatch(
        AccessStoreActions.showPaymentChangePlanDialog({
          newPlan,
        })
      );
  }
}
