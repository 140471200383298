export const locale = {
    lang: 'ar',
    data: {
           SESSION_SETTINGS: 'إعدادات الورشة',
           HEADER_TITLE: 'إعدادات الورشة',
           FIRST_SECTION_TITLE: 'معلومات الورشة:',
           COMPOSE_FORM_NAME: 'اسم الورشة',
           COMPOSE_FORM_START_DATE: 'تاريخ البدء',
           DESCRIPTION: 'الوصف',
           COMPOSE_FORM_END_DATE: 'تاريخ الانتهاء',
           SECOND_SECTION_TITLE: 'حالة خصوصية الورشة:',
           PRIVATE_SESSION_BUTTON: 'ورشة خاصة',
           PUBLIC_SESSION_BUTTON: 'ورشة عامة',
           PRIVATE_SESSION_DESCRIPTION: 'متاح فقط لأولئك المدعوين عن طريق البريد الإلكتروني. التسجيل مطلوب.',
           PUBLIC_SESSION_DESCRIPTION: 'متاح للجميع مع الرابط. لا يلزم الاشتراك.',
           INVITATION_SECTION_TITLE: 'استضافة المشاركين',
           INVITATION_SECTION_DESCRIPTION: 'ادعُ اشخاص للانضمام إلى هذه الورشة',
           INVITATION_DIALOG_BUTTON: 'ادعُ',
           LAST_SECTION_TITLE: 'إغلاق الورشة',
           LAST_SECTION_DESCRIPTION: 'إغلاق هذه الورشة سيؤدي إلى تغيير وضعها إلى مغلقة',
           DELETE_SESSION_BUTTON: 'إغلاق الورشة',
           SNACKBAR_MESSAGE: 'تم إغلاق هذه الورشة',
           SNACKBAR_ACTION: ' أغلق',
           SESSION_ADD_TIME:'إضافة وقت',
           SESSION_START_TIME:'وقت البدء',
           SESSION_END_TIME:'وقت الانتهاء', 
           VALIDATORS_END_DATE_REQUIRED: 'تاريخ الانتهاء واجب.' ,  
           VALIDATORS_END_DATE_BEFORE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء' ,
           VALIDATORS_INVALID_START_TIME: 'وقت البدء غير صحيح' ,
           VALIDATORS_INVALID_END_TIME: 'وقت الانتهاء غير صحيح' ,  
           VALIDATORS_END_TIME_BEFORE: 'يجب أن يكون وقت الانتهاء أكبر من وقت البدء' ,
           TEMPLATE_BRAINSTORMING:'العصف الذهني',
           TEMPLATE_AGIL: 'رشيقة سير العمل',
           TEMPLATE_PRODUCT_MANAGEMENT:'ادارة المنتج',
           TEMPLATE_TEAM_BUILDING:'تشكيل الفريق',
           TEMPLATE_PROSPECTIVE:'حل المشاكل',
           TEMPLATE_STRATEGY:'الإستراتيجية',
           TEMPLATE_TEMPLATE_SETTINGS:'إعدادات النموذج',
           TEMPLATE_TEMPLATE_INFORMATIONS:'معلومات النموذج',
           TEMPLATE_TEMPLATE_NAME:'اسم النموذج',
           TEMPLATE_TEMPLATE_SHORT_DESCRIPTION:'وصف مختصر',
           TEMPLATE_TEMPLATE_LONG_DESCRIPTION:'وصف طويل',
           TEMPLATE_TEMPLATE_THUMBNAIL:'رفع صورة مصغرة',
           TEMPLATE_TEMPLATE_THUMBNAIL_INFORMATIONS: 'يجب أن يكون للصورة نسبة عرض إلى ارتفاع 4: 3 ولا تتجاوز1 ميغابايت.',
           TEMPLATE_TEMPLATE_REMOVE:'إزالة',
           TEMPLATE_TEMPLATE_CATEGORY:'فئة',
           TEMPLATE_TEMPLATE_PROJECTS:'مشاريع',
           TEMPLATE_TEMPLATE_PROJECTS_DESCRIPTION:'حدد المشاريع التي سيتوفر بها النموذج',
           TEMPLATE_TEMPLATE_DELETE_TEMPLATE:'حذف النموذج',
           TEMPLATE_TEMPLATE_DELETE_TEMPLATE_DESCRIPTION:'احذف النموذج من جميع المشاريع',    
       }
   };   