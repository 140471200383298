export const locale = {
    lang: 'fr',
    data: {
        // tslint:disable-next-line: max-line-length
        SESSION_NEW_DIALOG_NEW_MEMBERS_PARAGRAPH: 'Désignez un animateur d\'atelier et invitez des collègues pour vous aider à organiser l\'atelier. S\'ils sont également participants, ils disposeront des droits pour rédiger le compte rendu de réunion.',
        SESSION_NEW_DIALOG_NEW_MEMBERS_NOTE: 'Note: Merci d\'ajouter au moins',
        SESSION_NEW_DIALOG_NEW_MEMBERS_AN_ANIMATOR: 'un animateur',
        SESSION_NEW_DIALOG_NEW_MEMBERS_END_NOTE: 'pour continuer.',
        SESSION_NEW_DIALOG_NEW_MEMBERS_PARTICIPANT: 'Inclure comme participant?',
        SESSION_NEW_DIALOG_NEW_MEMBERS_MEMBERS: 'Membre',
        SESSION_NEW_DIALOG_NEW_MEMBERS_THE_EMAIL: 'Email',
        SESSION_NEW_DIALOG_NEW_MEMBERS_POSITION: 'Position',
        SESSION_NEW_DIALOG_NEW_MEMBERS_SPONSOR: 'Sponsor',
        SESSION_NEW_DIALOG_NEW_MEMBERS_ADMIN: 'Admin',
        SESSION_NEW_DIALOG_NEW_MEMBERS_RESPONSIBLE: 'Responsable',
        SESSION_NEW_DIALOG_NEW_MEMBERS_ANIMATOR: 'Animateur',
        SESSION_NEW_DIALOG_NEW_MEMBERS_ROLE: 'Rôle',
        SESSION_NEW_DIALOG_NEW_MEMBERS_READING: 'Lecture',
        SESSION_NEW_DIALOG_NEW_MEMBERS_MODIFICATION: 'Modification',
        SESSION_NEW_DIALOG_NEW_MEMBERS_REMOVE: 'Retirer',
        SESSION_NEW_DIALOG_NEW_MEMBERS_SAVE: 'Enregistrer',
        SESSION_NEW_DIALOG_NEW_MEMBERS_CANCEL: 'Annuler',
    }
};
