export const locale = {
    lang: 'ar',
    data: {
        ACTIVITY_SETTINGS_SETTINGS_BUTTON: 'إعدادات النشاط' ,
        ACTIVITY_SETTINGS_TITLE_MODAL_SECTION: 'عرض',
        ACTIVITY_SETTINGS_COLOR_MODAL_SECTION: 'إظهار مفتاح اللون على البطاقة',
        ACTIVITY_SETTINGS_MEMBER_MODAL_SECTION: 'عرض من أضاف البطاقة',
        ACTIVITY_SETTINGS_SHOW_ALL_CARD: 'يمكن للمشاركين فقط رؤية البطاقات التي كتبوها'
    }
};
