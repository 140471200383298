export const locale = {
    lang: 'fr',
    data: {
        PAYMENT_DIALOG_SELECTED_PLAN: 'Plan sélectionné:' ,
        PAYMENT_DIALOG_Business : 'Business',
        PAYMENT_DIALOG_Team : 'Équipe',
        PAYMENT_DIALOG_UNLIMITED: 'Illimité' ,
        PAYMENT_DIALOG_PAYMENT_SECCESSFUL: 'Paiement réussi' ,
        PAYMENT_DIALOG_COPY_SENT_TO_EMAIL: 'Une copie de cette transaction a été envoyée à votre adresse e-mail.' ,
        PAYMENT_DIALOG_THANK_YOU: 'Merci d\'avoir choisi Excelway!' ,
        PAYMENT_DIALOG_PLEASE_WAIT: 'Attendez un moment, s\'il vous plaît. ' ,
        PAYMENT_DIALOG_PAYMENT_METHOD: 'Mode de paiement' ,
        PAYMENT_DIALOG_OTHER: ' Autre ' ,
        PAYMENT_DIALOG_CREDIT_CARD_DETAILS: 'Détails de la carte de crédit' ,
        PAYMENT_DIALOG_ADDS_ON: 'Add-ons' ,
        PAYMENT_DIALOG_HAVE_PROMO: 'Avez vous un code de réduction?' ,
        PAYMENT_DIALOG_BILLING_INFORMATION: 'Détails de facturation' ,
        PAYMENT_DIALOG_COUNTRY: 'Pays' ,
        PAYMENT_DIALOG_BILLING_MOROCCO: 'Maroc' ,
        PAYMENT_DIALOG_BILLING_USA: 'Etats-Unis' ,
        PAYMENT_DIALOG_BILLING_FRANCE: 'France' ,
        PAYMENT_DIALOG_BILLING_POSTAL_CODE: 'Code postal' ,
        PAYMENT_DIALOG_WHAT_YOU_WILL_GET: 'Ce que vous obtiendrez' ,
        PAYMENT_DIALOG_MEMBERS: 'membres' ,
        PAYMENT_DIALOG_GB: 'GB' ,
        PAYMENT_DIALOG_STORAGE: 'de stockage' ,
        PAYMENT_DIALOG_FREE_PARTICIPANTS: 'participants gratuits aux ateliers publics' ,
        PAYMENT_DIALOG_ADMIN_BILLING: 'Page de facturation de l\'administrateur' ,
        PAYMENT_DIALOG_YOU_BE_CHARGE: 'Vous serez facturé' ,
        PAYMENT_DIALOG_DOLLAR: '$' ,
        PAYMENT_DIALOG_year: 'annuelle' ,
        PAYMENT_DIALOG_month: 'mensuelle' ,
        PAYMENT_DIALOG_UNTIL_CANCEL: 'jusqu\'à ce que vous annuliez l\'abonnement. Pour en savoir plus, consultez notre FAQ ou envoyez-nous un e-mail à support@excelway.co' ,
        PAYMENT_DIALOG_TOTAL: 'Total:' ,
        PAYMENT_DIALOG_PURSHASE: 'Acheter' ,
        PAYMENT_DIALOG_CONFIRM: 'Confirmer' ,
    }
};
