import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActionName } from '@bsuccess/models/action-permission.model';

import { environment } from 'environments/environment';

import { RootStoreState } from 'app/root-store';
import { WorkshopActivitiesStoreActions } from 'app/root-store/workshop-store/activities-store';

import { locale as english } from '../../../../i18n/workshop/activities/animator/dialogs/session-settings/en';
import { locale as frensh } from '../../../../i18n/workshop/activities/animator/dialogs/session-settings/fr';
import { locale as arabic } from '../../../../i18n/workshop/activities/animator/dialogs/session-settings/ar';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StudioSessionStoreActions } from 'app/root-store/studio-store/session-store';
import { DatePipe } from '@angular/common';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AccessStoreActions } from 'app/root-store/access-store';
import { StudioProjectStoreActions } from 'app/root-store/studio-store/project-store';
import {
  StudioProjectsStoreActions,
  StudioProjectsStoreSelectors,
} from 'app/root-store/studio-store/projects-store';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {
  WorkshopCanvasStoreActions,
  WorkshopCanvasStoreSelectors,
} from 'app/root-store/workshop-store/canvas-store';
import { Template } from '@bsuccess/models/canvas/template.model';
import { SelectionModel } from '@angular/cdk/collections';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface ProjectNode {
  _id: string;
  name?: string;
  role?: string;
  subProjects?: ProjectNode[];
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  _id: string;
  subProjects?: ProjectNode[];
}

@Component({
  selector: 'studio-template-settings',
  templateUrl: './studio-template-settings.component.html',
  styleUrls: ['./studio-template-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [DatePipe],
})
export class StudioTemplateSettingsComponent implements OnInit {
  imagesUrl = '';
  composeForm: UntypedFormGroup;
  typeForm: UntypedFormGroup;
  template$: Observable<Template>;
  template: Template;
  sessionObjet: Template;
  selectedCategory: any;
  options2: string[] = [];
  projectsTree$: Observable<any[]>;
  currentProject$: Observable<ProjectModel>;
  currentProject: ProjectModel;
  image: any;
  loading: boolean;
  category = 'personalizedTemplate';

  private _transformer = (node: ProjectNode, level: number) => {
    return {
      expandable: !!node.subProjects && node.subProjects.length > 0,
      name: node.name,
      role: node.role,
      level: level,
      _id: node._id,
    };
  };

  getLevel = (node: ExampleFlatNode) => node.level;
  // tree

  hasNoContent = (_: number, _nodeData: any) => _nodeData.text === '';

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.subProjects
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  checklistSelection = new SelectionModel<ExampleFlatNode>(true /* multiple */);

  constructor(
    public matDialogRef: MatDialogRef<StudioTemplateSettingsComponent>,
    private _store: Store<RootStoreState.State>,
    private translationLoaderService: FuseTranslationLoaderService,
    private _fuseSidebarService: FuseSidebarService,
    public _matSnackBar: MatSnackBar,
    public translateService: TranslateService,
    private _dateAdapter: DateAdapter<Date>
  ) {
    this.translationLoaderService.loadTranslations(english, frensh, arabic);
  }

  ngOnInit(): void {
    this.imagesUrl = environment.minio.url + '/storage/o/public/';

    this.composeForm = this.createComposeForm();
    this.typeForm = this.createTypeForm();
    this.translateService.currentLang === 'ar'
      ? this._dateAdapter.setLocale('ar-MA')
      : this._dateAdapter.setLocale(this.translateService.currentLang);
    this.template$ = this._store.pipe(
      select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentTemplate)
    );

    this._store.dispatch(StudioProjectsStoreActions.loadProjectsTree());
    this.projectsTree$ = this._store.pipe(
      select(StudioProjectsStoreSelectors.selectProjectsTree)
    );

    this.projectsTree$.subscribe((projects) => {
      this.dataSource.data = projects;
      this.treeControl.dataNodes.map((project) => {
        if (this.template?.body?.inProjects.includes(project._id)) {
          this.checklistSelection.toggle(project);
        }
      });
    });

    this.template$.subscribe((template) => {
      this.template = template;
      if (this.template?.body?.name) {
        this.composeForm.controls['name'].setValue(this.template.body.name);
        this.category = this.template?.body?.category;
      }
      if (this.template?.body?.description) {
        this.composeForm.controls['description'].setValue(
          this.template.body.description
        );
      }
      if (this.template?.body?.longDescription) {
        this.composeForm.controls['longDescription'].setValue(
          this.template.body.longDescription
        );
      }
      if (this.template?.body?.vignette) {
        this.loading = false;
        this.image = this.imagesUrl + this.template.body.vignette;
      }
    });
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  toggleProjectsOpened(): void {
    this._fuseSidebarService.getSidebar('projectsPanel').toggleOpen();
  }

  showSubProjectDialog(parent): void {
    this._store.dispatch(
      StudioProjectStoreActions.showSubProjectDialog({
        parent,
      })
    );
  }

  expand(data: any[], _id: string): any {
    data.forEach((node, index) => {
      if (node.subProjects && node.subProjects.find((c) => c._id === _id)) {
        const indexOfNode = this.treeControl.dataNodes.findIndex(
          (x) => x._id === node._id
        );
        this.treeControl.expand(this.treeControl.dataNodes[indexOfNode]);
        this.expand(this.dataSource.data, node._id);
      } else if (
        node.subProjects &&
        node.subProjects.find((c) => c.subProjects)
      ) {
        this.expand(node.subProjects, _id);
      }
    });
  }

  newProject(): void {
    this._store.dispatch(AccessStoreActions.openNewProjectDialog());
  }

  createComposeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      longDescription: new UntypedFormControl(''),
      vignette: new UntypedFormControl(''),
      category: new UntypedFormControl(''),
      inProjects: new UntypedFormControl(''),
    });
  }

  createTypeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      type: new UntypedFormControl(''),
    });
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ExampleFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ExampleFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ExampleFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }
  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: ExampleFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ExampleFlatNode): void {
    let parent: ExampleFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ExampleFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: any): ExampleFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  fileChange(event: any): void {
    const file = event.target.files[0];
    if (file.size > 1048576) {
      alert('Image is too big!');
    } else {
      const eventObj = event;
      const target: HTMLInputElement = eventObj.target as HTMLInputElement;
      const files: FileList = target.files;

      const formData: FormData = new FormData();

      formData.append('file', files[0], files[0].name);

      this.loading = true;

      this._store.dispatch(
        WorkshopCanvasStoreActions.uploadImage({
          content: formData,
          name: files[0].name,
          template: this.template,
        })
      );
    }
  }

  save(): void {
    this._store.dispatch(
      WorkshopCanvasStoreActions.updateTemplateSettingsInfo({
        templateId: this.template.templateId ? this.template.templateId : '',
        body: {
          name: this.composeForm.value.name,
          description: this.composeForm.value.description,
          longDescription: this.composeForm.value.longDescription,
          vignette: this.image ? this.template?.body.vignette : '',
          category: this.category ? this.category : 'personalizedTemplate',
          inProjects: this.checklistSelection.selected
            ? this.checklistSelection.selected.map((selected) => selected._id)
            : [this.currentProject._id],
          layout: this.template?.body?.layout
            ? this.template?.body?.layout
            : [],
        },
      })
    );
  }

  deleteImage(): void {
    this.image = '';
    this.save();
  }

  deleteTemplate(): void {
    this._store.dispatch(
      WorkshopCanvasStoreActions.deleteTemplate({
        redirectToActivities: true,
        templates: this.currentProject?.studioTemplates,
        id: this.template.templateId,
      })
    );
  }
}
