export const locale = {
    lang: 'en',
    data: {
        ACTIVITY_SETTINGS_SETTINGS_BUTTON: 'Activity settings',
        ACTIVITY_SETTINGS_TITLE_MODAL_SECTION: 'Display',
        ACTIVITY_SETTINGS_COLOR_MODAL_SECTION: 'Show color label in cards',
        ACTIVITY_SETTINGS_MEMBER_MODAL_SECTION: 'Show who added the card',
        ACTIVITY_SETTINGS_SHOW_ALL_CARD: 'Participants can only see their own cards'
    }
};
