export const locale = {
    lang: 'ar',
    data: {
        PROJECTS_DIALOGS_MEMBERS_DIALOG_MANAGE_MEMBERS: 'إدارة الأعضاء',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_INVITAION: 'قم بدعوة الأعضاء للتعاون في المشروع. إذا لم يكن لديهم حساب إكْسلْوايْ ، فسوف يتلقون دعوة لإنشاء حساب.',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_MEMBERS: 'الأعضاء',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_THE_EMAIL: 'البريد الإلكتروني',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_POSITION: 'موضع',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_SPONSOR: 'الكفيل', 
        PROJECTS_DIALOGS_MEMBERS_DIALOG_ADMIN: 'مشرف',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_ADD_USER_BUTTON_LABEL: 'إضافة مستخدم',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_RESPONSIBLE: 'المالِك',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_ROLE: 'دَوْر',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_READING: 'مُشاهِد',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_MODIFICATION: 'مُحَرّر',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_REMOVE: 'إزالة',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_SAVE: 'حفظ',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_CANCEL: 'إلغاء',
        PROJECTS_DIALOGS_MEMBERS_DIALOG_APPLY_CHANGES_ON_CHILDREN: 'قم بتطبيق هذه التغييرات على جميع ورش العمل واللوحات والمشاريع الفرعية لهذا المشروع.'
    }
};
