import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { UserModel } from '@bsuccess/models/user.model';

export const selectProfileState = createFeatureSelector<State>('profile');

export const selectUser = createSelector(
    selectProfileState,
    (state: State): UserModel => state.user
);

export const selectError = createSelector(
    selectProfileState,
    (state: State): any => state.error
);


export const selectSuccess = createSelector(
    selectProfileState,
    (state: State): any => state.success
);
