import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SortablejsModule } from 'ngx-sortablejs';
import { BsuccessModule } from '@bsuccess/bsuccess.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BackgroundPanelComponent } from './background-panel.component';

@NgModule({
  declarations: [BackgroundPanelComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatButtonModule,
    SortablejsModule,
    BsuccessModule,
    TranslateModule,
    EditorModule,
  ],
  exports: [BackgroundPanelComponent],
})
export class BackgroundPanelModule {}
