import { FuseSidebarService } from './../../../../@fuse/components/sidebar/sidebar.service';
import {
    AfterContentChecked,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseConfigService } from '@fuse/services/config.service';

import { locale as english } from '../../../i18n/layout/components/navbar/vertical/style-1/en';
import { locale as frensh } from '../../../i18n/layout/components/navbar/vertical/style-1/fr';
import { locale as arabic } from '../../../i18n/layout/components/navbar/vertical/style-1/ar';

import { navigation } from 'app/navigation/navigation';
import { HeaderStoreSelectors } from 'app/root-store/header-store';
import { RootStoreState, RootStoreSelectors } from 'app/root-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { UserModel } from '@bsuccess/models/user.model';
import { NavbarStoreSelectors } from 'app/root-store/navbar-store';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { StudioProjectStoreSelectors } from 'app/root-store/studio-store/project-store';
import { StudioBoardStoreSelectors, StudioBoardStoreActions } from 'app/root-store/studio-store/board-store';
import { Actions, ofType } from '@ngrx/effects';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';

@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component
    implements OnInit, OnDestroy, AfterContentChecked {
    url$: Observable<string>;
    user$: Observable<UserModel>;
    fuseConfig: any;
    navigation: any;
    header$: Observable<any>;
    tasksPanelOpened$: Observable<any>;
    notesPanelOpened$: Observable<any>;
    backgroundPanelOpened$: Observable<any>;
    showHeader = false;
    user: UserModel;
    product$: Observable<string>;
    currentProject$: Observable<ProjectModel>;
    @ViewChild(FusePerfectScrollbarDirective, { static: false })
    listScroll: FusePerfectScrollbarDirective;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _fuseConfigService: FuseConfigService,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService,
        public updates$: Actions
    ) {
        // Set the defaults
        this.navigation = navigation;

        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.translateService.addLangs(['en', 'fr', 'ar']);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.header$ = this._store.pipe(
            select(HeaderStoreSelectors.selectHeaderState)
        );
        this.url$ = this._store.pipe(select(RootStoreSelectors.selectUrl));

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        this.user$ = this._store.pipe(select(LoginStoreSelectors.selectLoggedUser));

        this.tasksPanelOpened$ = this._store.pipe(
            select(NavbarStoreSelectors.selectTasksPanelOpened)
        );

        this.notesPanelOpened$ = this._store.pipe(
            select(NavbarStoreSelectors.selectNotesPanelOpened)
        );

        this.backgroundPanelOpened$ = this._store.pipe(
            select(StudioBoardStoreSelectors.selectBackgroundPanelOpened)
        );

        this.user$.subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });
        this.product$ = this._store.pipe(
            select(NavbarStoreSelectors.selectProduct)
        );

        this.currentProject$ = this._store.pipe(
            select(StudioProjectStoreSelectors.selectCurrent)
        );

        this.updates$.pipe(
            ofType(StudioBoardStoreActions.addListSuccess),
        )
            .subscribe(() => {

                this.listScroll.scrollToBottom(-100, 600);
            });
    }

    ngAfterContentChecked(): void {
        // Called after every check of the component's or directive's content.
        // Add 'implements AfterContentChecked' to the class.
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }


    getSidebarStateOpened(): boolean {
        if (this._fuseSidebarService.getSidebar('navbar')) {
            return this._fuseSidebarService.getSidebar('navbar').opened;
        } else {
            return false;
        }

    }
}
