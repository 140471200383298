export const locale = {
    lang: 'fr',
    data: {
        PRICING_DIALOG_UPDATE_ACCOUNT: 'Ameliorez votre compte',
        PRICING_DIALOG_PAY_MONTHLY: 'Payez mensuellement',
        PRICING_DIALOG_PAY_YEARLY: 'Payez annuellement',
        PRICING_DIALOG_SAVE_20:'Économisez 20%',
        PRICING_DIALOG_TEAM_PRICING_CURRENCY:'€',
        PRICING_DIALOG_TEAM_PRICING_MONTH:'/mo',
        PRICING_DIALOG_TEAM_BUTTON:'Améliorer',
        PRICING_DIALOG_ENTREPRISE_HEADING:'Entreprise',
        PRICING_DIALOG_ENTREPRISE_SUBTITLE:'Pour les organisations qui veulent se transformer à l\'échelle et ont des exigences spécifiques',
        PRICING_DIALOG_ENTREPRISE_CUSTOM_PRICING:'Prix personnalisé',
        PRICING_DIALOG_ENTREPRISE_PRICING_CURRENCY:'€',
        PRICING_DIALOG_ENTREPRISE_PRICING_MONTH:'/mo',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_ONE:'Accès illimité à toutes les fonctionnalités',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_TWO:'Authentification unique (SAML / oAuth2)',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_THREE:'Chargé de compte dédié',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_FOUR:'Sécurité avancée',
        PRICING_DIALOG_ENTREPRISE_LIST_ITEM_FIVE:'Hébergement dédié',
        PRICING_DIALOG_ENTREPRISE_BUTTON:'Nous contacter',
        PRICING_DIALOG_NOTICE:'Tous les prix indiqués sont hors TVA. ',
        PRICING_DIALOG_TEAM_BUTTON_SELECTED: 'Actuel',
        PRICING_DIALOG_TEAM_BUTTON_CHOOSE: 'Choisir',
        PRICING_DIALOG_PLAN_METADATA_ONE_NAME : 'Équipe',
        PRICING_DIALOG_PLAN_METADATA_ONE_PLAN_DESCRIPTION : 'Pour les petites équipes qui veulent booster leur collaboration',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_ONE : '1 projet',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_TWO : '10 membres',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_THREE : '25 GB de stockage',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_FOUR : 'Ateliers illimités',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_FIVE : 'Tableaux illimités',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_SIX : 'Bibliothèque de modèles',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_SEVEN : 'Option Grands Ateliers en supplément (50€ pour 100 participants)',
        PRICING_DIALOG_PLAN_METADATA_ONE_FEATURE_EIGHT : 'Jusqu\'à 10 participants gratuits dans les ateliers publics',
        PRICING_DIALOG_PLAN_METADATA_TWO_NAME : 'Business',
        PRICING_DIALOG_PLAN_METADATA_TWO_PLAN_DESCRIPTION : 'Pour les entreprises qui veulent gérer la collaboration au sein de différentes équipes',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_ONE : '3 projets',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_TWO : 'Nombre illimité de membres',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_THREE : '25 GB de stockage',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_FOUR : 'Ateliers illimités',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_FIVE : 'Tableaux illimités',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_SIX : 'Bibliothèque de modèles',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_SEVEN : 'Option Grands Ateliers en supplément (50€ pour 100 participants)',
        PRICING_DIALOG_PLAN_METADATA_TWO_FEATURE_EIGHT : 'Jusqu\'à 10 participants gratuits dans les ateliers publics',
    }
};
