export const locale = {
    lang: 'fr',
    data: {
        COMPONENTS_NAVBAR_EXPAND: 'Elargir le menu',
        COMPONENTS_NAVBAR_PROFIL: 'Profile',
        COMPONENTS_NAVBAR_MY_PROFILE : 'Mon Profil',
        COMPONENTS_NAVBAR_LOGOUT: 'Déconnexion',
        COMPONENTS_NAVBAR_MAIL: 'Mail',
        COMPONENTS_NAVBAR_LANGUAGE: 'Language',
        COMPONENTS_NAVBAR_RETURN: 'Revenir à l\'atelier',
        COMPONENTS_NAVBAR_LEAVE: 'Quitter l\'atelier',
        COMPONENTS_NAVBAR_STATUS: 'Statut',
        COMPONENTS_NAVBAR_PROJECT_SETTINGS:'RÉGLAGES DU PROJET',
        COMPONENTS_NAVBAR_PROJECT_MEMBERS:'MEMBRES DU PROJET',
        COMPONENTS_NAVBAR_SETTINGS:'Paramètres',
        COMPONENTS_NAVBAR_UNLIMITED:'Passez la collaboration à l’échelle dans votre entreprise',
        COMPONENTS_NAVBAR_GET_ACCESS:'Passez la collaboration à l\'échelle dans votre entreprise avec des accès illimités.',
        COMPONENTS_NAVBAR_SEE_PRICING:'Mettre à niveau',
        COMPONENTS_NAVBAR_EDIT_PROJECT:'Modifier le projet',
        COMPONENTS_NAVBAR_MANAGE:'Gérer',
        COMPONENTS_NAVBAR_ARCHIVE:'Supprimer',
        COMPONENTS_NAVBAR_ADD_MORE_USERS:'Ajouter plus d\'utilisateurs',
        COMPONENTS_NAVBAR_VIEW_ALL:'Voir tout',
        COMPONENTS_NAVBAR_INVITE_MEMBERS:'+ Inviter des membres',
    }
};
