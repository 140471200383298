import { CanLoad, Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { RootStoreState } from 'app/root-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';

@Injectable({
    providedIn: 'root',
})
export class StudioGuard implements CanLoad, CanActivate {

    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _store: Store<RootStoreState.State>
    ) { }

    canLoad(): Observable<boolean> {
        return this.isAuthenticated();
    }

    canActivate(): Observable<boolean> {
        return this.isAuthenticated();
    }

    private isAuthenticated(): Observable<boolean> {
        return this._store.select(LoginStoreSelectors.selectLoginToken).pipe(
            take(1),
            map(token => {
                if (
                    this._authService.isTokenExpired(token)
                ) {
                    this._router.navigate(['login']);
                    return false;
                } else {
                    return true;
                }
            }));
    }
}
