export const locale = {
    lang: 'en',
    data: {
        MANAGE: 'Manage members',
        ADD_TO_SESSION: 'Add participants to the workshop',
        EMAIL: 'Enter the participant\'s email and press the Enter key',
        SAVE: 'Save',
        CANCEL: 'Cancel',
    }
};
