export const locale = {
    lang: 'en',
    data: {
        PROJECTS_DIALOGS_PROJECT_SUMMARY_YOUR: 'Your',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_SUMMARY: 'Summary',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DAILY: 'Daily',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_WEEKLY: 'Weekly',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_COMPLETED_TASKS: 'Completed tasks',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_IN_PROGRESS: 'Tasks in progress',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_LATE: 'Overdue tasks',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DUE_SOON: 'Tasks due soon',
        PROJECTS_DIALOGS_FILTER:'Filter',
        PROJECTS_DIALOGS_CANCEL:'Cancel',
        PROJECTS_DIALOGS_CH_ONE : 'Today',
        PROJECTS_DIALOGS_CH_TWO : 'Tomorrow',
        PROJECTS_DIALOGS_CH_THREE : 'Before today',
        PROJECTS_DIALOGS_CH_FOUR : 'In the next 7 days',
        PROJECTS_DIALOGS_CH_FIVE : 'In the last 7 days',
        PROJECTS_DIALOGS_CH_SIX : 'Yesterday',
        PROJECTS_DIALOGS_RESET : 'Reset',
        PROJECTS_DIALOGS_RESPONSABLE:'Owner',
        PROJECTS_DIALOGS_DATE_LIMITE:'Due date',
        PROJECTS_DIALOGS_PROJECTS:'Projects',
    }
};
