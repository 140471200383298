import { FuseNavigation } from '@fuse/types';

export const workshopReporterNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        type: 'group',
        children: [
            {
                id: 'notes',
                title: 'Carnet de notes',
                icon: 'file-document-edit-outline',
                type: 'item',
                url: 'workshop/notes',
                translate: 'NOTEBOOK',
            },
        ]
    },
    {
        id: 'help',
        title: '',
        type: 'group',
        children: [
            {
                id: 'chat_support',
                title: 'Chat with support',
                icon: 'chat',
                type: 'item',
                translate: 'CHAT_SUPPORT',
                function: function(): void {
                    eval(`$crisp.push(['do', 'chat:show'])`);
                    eval(`$crisp.push(['do', 'chat:open'])`);
                },
            },
        ],
    },
];
