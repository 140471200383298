import { BoardCardModel } from '@bsuccess/models/board/board-card.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { ProjectBoards } from '@bsuccess/models/project/project-boards.model';

export const selectBoardState = createFeatureSelector<State>('studio-board');

export const selectCurrent = createSelector(
    selectBoardState,
    (state: State): BoardModel => state.current
);

export const selectBoardId = createSelector(
    selectCurrent,
    (board: BoardModel): string => board && board.id
);

export const selectProjectsList = createSelector(
    selectBoardState,
    (state: State): any => state.projectsList
);

export const selectBoardUsers = createSelector(
    selectBoardState,
    (state: State): ProjectUserModel[] => state.current ? state.current.users : []
);

export const selectBoardLabels = createSelector(
    selectBoardState,
    (state: State): any[] => state.current ? state.current.labels : []
);

export const selectCurrentImage = createSelector(
    selectBoardState,
    (state: State): any => state.currentImage
);

export const selectPendingUpload = createSelector(
    selectBoardState,
    (state: State): boolean => state.pendingUpload
);

export const selectBackgroundPanelOpened = createSelector(
    selectBoardState,
    (state: State): boolean => state.backgroundPanelOpened
  );

  export const selectView = createSelector(
    selectBoardState,
    (state: State): string => state.viewType
  );

export const selectFilterState = createSelector(
    selectBoardState,
    (state: State): any[] => state.filterState
);

export const selectChartsFirstRow = createSelector(
    selectBoardState,
    (state: State): any => state.current ? state.current.widgetCharts : []
);

export const selectBoardWidgets = createSelector(
    selectBoardState,
    (state: State): any => state.current ? state.current.widgets : []
);

export const selectBoardNumberWidgets = createSelector(
    selectBoardState,
    (state: State): any => state.current ? state.current.numberWidgets : []
);
