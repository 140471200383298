import { StandbyCardModel } from '../../../../@bsuccess/models/standbycard/standbycard.model';

export interface State {
  current: StandbyCardModel;
  notifications: StandbyCardModel[];
  notificationsCount: number;
}

export const initialState: State = {
  current: {},
  notifications: [],
  notificationsCount: null,
};
