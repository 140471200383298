import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from 'app/i18n/studio/projects/board/dialogs/board-subscribe-dialog/en';
import { locale as frensh } from 'app/i18n/studio/projects/board/dialogs/board-subscribe-dialog/fr';
import { locale as arabic } from 'app/i18n/studio/projects/board/dialogs/board-subscribe-dialog/ar';
import { RootStoreState } from 'app/root-store';
import { Store } from '@ngrx/store';
import { StudioBoardStoreActions, StudioBoardStoreSelectors } from 'app/root-store/studio-store/board-store';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { LoginStoreSelectors } from 'app/root-store/login-store';

@Component({
    selector: 'app-board-subscribe-dialog',
    templateUrl: './board-subscribe-dialog.component.html',
    styleUrls: ['./board-subscribe-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BoardSubscribeDialogComponent implements OnInit {
    board: BoardModel;
    daily = false;
    weekly = false;
    constructor(
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        public matDialogRef: MatDialogRef<BoardSubscribeDialogComponent>,
        private _store: Store<RootStoreState.State>
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this._store
            .select(StudioBoardStoreSelectors.selectCurrent)
            .subscribe(board => {
                this.board = board;
            });

        this._store.select(LoginStoreSelectors.selectUserWatchedBaords).subscribe(watchedBaord => {
            if (this.board) {
                if (watchedBaord.filter(_ => _._id === this.board.id)[0]) {
                    this.daily = watchedBaord.filter(_ => _._id === this.board.id)[0].daily;
                    this.weekly = watchedBaord.filter(_ => _._id === this.board.id)[0].weekly;
                }
            }
        });
    }

    save(): void {
        this._store.dispatch(
            StudioBoardStoreActions.watchBoard({
                daily: this.daily,
                weekly: this.weekly,
                enableEmailNotifications: true,
            })
        );
    }
}
