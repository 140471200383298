export const locale = {
    lang: 'en',
    data: {
        CHECKLIST_TITLE: 'Checklist title',
        ADD_CHECKLIST: 'Checklist',
        ADD_ACTION_PLAN: 'Add a task',
        TITLE: 'Title',
        DUE_DATE: 'Due date',
        RESPONSIBLE: 'Owner',
        DESCRIPTION: 'Description',
        REMOVE_CHECKLIST: 'Remove checklist',
        NEW_CHECKLIST: 'New checklist item',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        CLOSE: 'Close',
        PREVIOUS: 'Previous',
        NEXT: 'Next',
        ADD_TO_CARD_LABEL: 'Add to task'
    }
};
