import { SessionCategoryModel } from '@bsuccess/models/session/session-category';
import { FuseUtils } from '@fuse/utils';

export interface State {
    value: number;
    start: boolean;
    exercise?: string;
    categories?: SessionCategoryModel[];
    balance: number;
    maxPerCard: number;
    contentId: string;
}

export const initialState: State = {
    value: 0,
    start: false,
    categories: [{
        id: FuseUtils.generateGUID(),
        color: 'red-100',
        name: 'red'
    }, {
        id: FuseUtils.generateGUID(),
        color: 'green-100',
        name: 'green'
    }, {
        id: FuseUtils.generateGUID(),
        color: 'lime-100',
        name: 'lime'
    }, {
        id: FuseUtils.generateGUID(),
        color: 'orange-100',
        name: 'orange'
    }],
    balance: 0,
    maxPerCard: 0,
    contentId: null
};
