import { UntypedFormControl } from '@angular/forms';
import {
    Component,
    Inject,
    ViewEncapsulation,
    OnInit,
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { RootStoreState } from 'app/root-store';
import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '../../../../../i18n/workshop/activities/animator/dialogs/action-plans/en';
import { locale as frensh } from '../../../../../i18n/workshop/activities/animator/dialogs/action-plans/fr';
import { locale as arabic } from '../../../../../i18n/workshop/activities/animator/dialogs/action-plans/ar';

import {
    LoginStoreSelectors,
    LoginStoreActions,
} from 'app/root-store/login-store';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import {
    StudioSessionStoreSelectors,
} from 'app/root-store/studio-store/session-store';
import { SessionModel } from '@bsuccess/models/session/session.model';
import { NotificationService } from '@bsuccess/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { WorkshopActivitiesStoreActions } from 'app/root-store/workshop-store/activities-store';
import { UserModel } from '@bsuccess/models/user.model';
import { AccessStoreActions } from 'app/root-store/access-store';
import { WorkshopSocketIOStoreSelectors } from 'app/root-store/workshop-store/socket-io-store';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ProfileStoreSelectors } from 'app/root-store/profile-store';

@Component({
    selector: 'animator-participants-invitations-dialog',
    templateUrl: 'participants-invitations-dialog.component.html',
    styleUrls: ['participants-invitations-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class AnimatorParticipantsInvitationsDialogComponent implements OnInit {
    user$: Observable<UserModel>;
    participantsLimit$: Observable<number>;
    connectedPublicParticipants$: Observable<UserModel[]>;
    sessionOwnerId$: Observable<string>;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    selected = 'Participant';
    userProfile$: Observable<UserModel>;
    userProfile: UserModel;
    users$: Observable<{ _id: string; invited: boolean }[] | ProjectUserModel[]>;
    animators$: Observable<
        { _id: string; invited: boolean }[] | ProjectUserModel[]
    >;

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    emails = [];
    inputControl = new UntypedFormControl();
    existedemails = [];
    searchTerm: string;
    allParticipants$: Observable<
        { _id: string; invited: boolean }[] | ProjectUserModel[]
    >;
    currentSession$: Observable<SessionModel>;
    workspace$: Observable<string>;
    currentSession: SessionModel;
    workspace: string;

    role = 'Participant';

    constructor(
        public matDialogRef: MatDialogRef<AnimatorParticipantsInvitationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private _notificationService: NotificationService,
        private translationLoaderService: FuseTranslationLoaderService,
        private httpClient: HttpClient,
        private _matDialog: MatDialog,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        this.matDialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.searchTerm = '';
        this.users$ = this._store.pipe(
            select(LoginStoreSelectors.selectSessionFiltredParticipants)
        );        
        this.participantsLimit$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoginSessionParticipantsLimit)
        );
        this.sessionOwnerId$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoginSessionOwnerId)
        );
        this.allParticipants$ = this._store.pipe(
            select(LoginStoreSelectors.selectSessionParticipants)
        );
        this.currentSession$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectCurrent)
        );
        this.workspace$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoginTenant)
        );
        this.users$.subscribe((users) => {
            if (users) {
                this.existedemails = (users as ProjectUserModel[]).map(
                    (user: ProjectUserModel) => {
                        return user.email;
                    }
                );
            }
        });
        this.currentSession$.subscribe((currentSession) => {
            this.currentSession = currentSession;
        });
        this.workspace$.subscribe((workspace) => {
            this.workspace = workspace;
        });
        this.animators$ = this._store.pipe(
            select(LoginStoreSelectors.selectSessionAnimators)
        );        
        this.connectedPublicParticipants$ = this._store.pipe(
            select(WorkshopSocketIOStoreSelectors.selectConnectedPublicUsers)
        );
        this.user$ = this._store.pipe(select(LoginStoreSelectors.selectLoggedUser));
        this.userProfile$ = this._store.pipe(select(ProfileStoreSelectors.selectUser));
        this.userProfile$.subscribe((userProfile) => {
            this.userProfile = userProfile;
        });
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            this.emails.push({ email: value.trim() });
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    getInitials(user: UserModel): string {
        if (user) {
            if (user.firstName) {
                return (
                    user.firstName
                        .charAt(0)
                        .toString()
                        .toUpperCase() +
                    user.lastName
                        .charAt(0)
                        .toString()
                        .toUpperCase()
                );
            }
            else {
                return ('NA');
            }
        } else {
            return ('NA');
        }
    }

    remove(fruit): void {
        const index = this.emails.indexOf(fruit);

        if (index >= 0) {
            this.emails.splice(index, 1);
        }
    }

    search(): void {
        // Do your search here...
        this._store.dispatch(
            LoginStoreActions.searchParticipant({
                text: this.searchTerm,
            })
        );
    }

    send(): void {
        this._store.dispatch(
            WorkshopActivitiesStoreActions.addUsersByRole({
                emails: [...this.emails.map((elementEmail) => elementEmail.email)],
                sessionRole: this.role,
            })
        );
        this.emails = [];
    }

    sendWithAnimatorRole(): void {
        this._store.dispatch(
            WorkshopActivitiesStoreActions.addUsersByRole({
                emails: [...this.emails.map((elementEmail) => elementEmail.email)],
                sessionRole: 'Animator',
            })
        );
        this.emails = [];
    }

    copyLink(): void {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData(
                'text/plain',
                'https://app.excelway.co/access/2/' +
                this.workspace +
                '/' +
                this.currentSession.token
            );
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        this._notificationService.showSuccess(
            this.translateService.currentLang.toString() === 'fr'
                ? 'Url copié dans le presse-papier'
                : this.translateService.currentLang.toString() === 'en'
                    ? 'Link copied to clipboard'
                    : 'تم نسخ عنوان الموقع إلى الحافظة'
        );
    }

    switchRole(event, user): void {
        this._store.dispatch(
            WorkshopActivitiesStoreActions.toggleRole({
                role: event.value,
                user,
            })
        );
    }

    deleteUser(userId: string, sessionRole: string): void {
        this._store.dispatch(
            WorkshopActivitiesStoreActions.deleteUser({
                userId,
                sessionRole,
            })
        );
    }

    addAddon(): void {
        if(!this.userProfile.stripeInfo?.sumoLing){
            if(this.userProfile.subscription == 'Free'){
                this._matDialog.closeAll();
                this._store.dispatch(AccessStoreActions.showPricingDialog());
            }else{
                this._matDialog.closeAll();
                this._store.dispatch(AccessStoreActions.showAddOnsDialog());
            }
        }else{
            this._matDialog.closeAll();
            this._store.dispatch(AccessStoreActions.showAddOnsDialog());
        }
    }
}
