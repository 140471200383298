import { createAction, props } from '@ngrx/store';
import { Params } from '@angular/router';
import { UserModel } from '@bsuccess/models/user.model';
import { LoginModel } from '@bsuccess/models/login.model';

export const showProfileGeneralDialog = createAction(
  '[Profile][Profile General Dialog] Show Dialog'
);

export const updateProfileGeneral = createAction(
  '[Profile][Profile General Dialog] Update Profile General',
  props<{ user: { lastName: string; firstName: string; timeZone: string } }>()
);

export const updateProfileGeneralSuccess = createAction(
  '[Profile][Profile API] Update Profile General Success',
  props<{ user: { lastName: string; firstName: string; timeZone: string } }>()
);

export const updateProfileGeneralFailure = createAction(
  '[Profile][Profile API] Update Profile General Failure',
  props<{ error: string }>()
);

export const showProfileOccupationDialog = createAction(
  '[Profile][Profile Occupation Dialog] Show Dialog'
);

export const updateProfileOccupation = createAction(
  '[Profile][Profile General Dialog] Update Profile Occupation',
  props<{ user: { occupation: string; team: string; enterprise: string } }>()
);

export const updateProfileOccupationSuccess = createAction(
  '[Profile][Profile API] Update Profile Occupation Success',
  props<{ user: { occupation: string; team: string; enterprise: string } }>()
);

export const updateProfileOccupationFailure = createAction(
  '[Profile][Profile API] Update Profile Occupation Failure',
  props<{ error: string }>()
);

export const showProfileContactDialog = createAction(
  '[Profile][Profile Contact Dialog] Show Dialog'
);

export const updateProfileContact = createAction(
  '[Profile][Profile General Dialog] Update Profile Contact',
  props<{ user: { address: string; phone: string; website: string } }>()
);

export const updateProfileContactSuccess = createAction(
  '[Profile][Profile API] Update Profile Contact Success',
  props<{ user: { address: string; phone: string; website: string } }>()
);

export const updateProfileContactFailure = createAction(
  '[Profile][Profile API] Update Profile Contact Failure',
  props<{ error: string }>()
);

export const uploadProfilePicture = createAction(
  '[Studio][Profile] Upload Profile',
  props<{ userId: string; content: any; name: string }>()
);

export const uploadProfilePictureSuccess = createAction(
  '[Profile][Profile API] Upload Profile Success',
  props<{ userId: string; response: any; }>()
);

export const uploadProfilePictureFailure = createAction(
  '[Profile][Profile API] Upload Profile Failure',
  props<{ error: string }>()
);

export const addProfilePicture = createAction(
  '[Profile][Profile] Add Profile',
  props<{ userId: string; profilePicture: string }>()
);

export const addProfilePictureSuccess = createAction(
  '[Profile][Profile] Add Profile Success',
  props<{ userId: string; profilePicture: string }>()
);

export const addProfilePictureFailure = createAction(
  '[Profile][Profile] Add Profile Failure',
  props<{ error: any }>()
);

export const updateUserPassword = createAction(
    '[Studio][Users API] Update User Password',
    props<{ email: string; oldPassword: string, newPassword: string }>()
);

export const updateUserPasswordSuccess = createAction(
    '[Studio][Users API] Update User Password Success',
    props<{ success: string }>()
);

export const updateUserPasswordFailure = createAction(
    '[Studio][Users API] Update User Password Failure',
    props<{ error: string }>()
);

export const updateUserNotice = createAction(
    '[Studio][Users API] Update User Notice',
    props<{ notice: any }>()
);

export const updateUserNoticeSuccess = createAction(
    '[Studio][Users API] Update User Notice Success',
    props<{ notice: any }>()
);

export const updateUserNoticeFailure = createAction(
    '[Studio][Users API] Update User Notice Failure',
    props<{ error: string }>()
);

export const updateUserTimeZone = createAction(
    '[Studio][Users API] Update User Time Zone',
    props<{ timeZone: any }>()
);

export const updateUserTimeZoneSuccess = createAction(
    '[Studio][Users API] Update User Time Zone Success',
    props<{ timeZone: any }>()
);

export const updateUserTimeZoneFailure = createAction(
    '[Studio][Users API] Update User Time Zone Failure',
    props<{ error: string }>()
);