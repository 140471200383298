import {
    Component,
    OnInit,
    Inject,
    ViewEncapsulation,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AttachmentService } from '../../services/attachment.service';
import { PDFDocumentProxy } from 'pdfjs-dist';

import { locale as english } from 'app/i18n/@bsuccess/en';
import { locale as frensh } from 'app/i18n/@bsuccess/fr';
import { locale as arabic } from 'app/i18n/@bsuccess/ar';

@Component({
    selector: 'bs-attachment-dialog',
    templateUrl: './attachment-dialog.component.html',
    styleUrls: ['./attachment-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BsAttachmentDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('videoTag') video: any;
    pdfSrc: String;
    dialogData: any;
    page = 1;
    isLoaded: boolean;
    pdf: PDFDocumentProxy;
    zoom = 0.6;

    constructor(
        public dialogRef: MatDialogRef<BsAttachmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        public dialog: MatDialog,
        private service: AttachmentService,
        private translationLoaderService: FuseTranslationLoaderService
        ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        this.dialogData = data;
        this.isLoaded = false;
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this.data.attachment.contentType === 'application/pdf') {
            // window.open(this.data.url);._id
            if (this.data.attachment.filename) {
                this.service
                    .get(this.data.attachment.filename)
                    .subscribe(data => {
                        const blob = new Blob([data], {
                            type: this.data.attachment.contentType.toString(),
                        });
                        const url = window.URL.createObjectURL(blob);
                        this.pdfSrc = url;
                    });
            } else {
                this.service.get(this.data.attachment.filename).subscribe(data => {
                    const blob = new Blob([data], {
                        type: this.data.attachment.contentType.toString(),
                    });
                    const url = window.URL.createObjectURL(blob);
                    this.pdfSrc = url;
                });
            }
        }
    }

    nextPage(): void {
        if (this.page < this.pdf.numPages) {
            this.page++;
        }
    }

    prevPage(): void {
        if (this.page > 1) {
            this.page--;
        }
    }

    zoomIn(): void {
        this.zoom += 0.1;
    }

    zoomOut(): void {
        this.zoom -= 0.1;
    }

    afterLoadComplete(pdf: PDFDocumentProxy): void {
        this.pdf = pdf;
        this.isLoaded = true;
    }

    close(): void {
        this.dialogRef.close();
    }
}
