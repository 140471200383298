import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as HeaderActions from './actions';
import { StudioProjectStoreActions } from '../studio-store/project-store';
import { StudioSessionStoreActions } from '../studio-store/session-store';
import { StudioBoardStoreActions } from '../studio-store/board-store';
import { StudioHomeStoreActions } from '../studio-store/home-store';
import { StudioProjectsStoreActions } from '../studio-store/projects-store';
import { StudioSessionsStoreActions } from '../studio-store/sessions-store';
import { StudioBoardsStoreActions } from '../studio-store/boards-store';

const headerReducer = createReducer(
    initialState,
    on(HeaderActions.showHeader, state => ({
        ...state,
        hidden: false,
    })),
    on(HeaderActions.hideHeader, state => ({
        ...state,
        hidden: true,
    })),
    on(HeaderActions.newProjectHeader, state => ({
        ...state,
        hidden: false,
        menuType: 'newProject'
    })),
    on(HeaderActions.updateHeaderType, (state, { menuType }) => ({
        ...state,
        menuType,
        hidden: false,
    })),
    on(StudioProjectStoreActions.loadCurrentSuccess, (state, { project }) => {
        const links = [];
        if (project.level) {
            if (project.closeParent._id === project.parent._id) {
                links.push({
                    title:
                        project.closeParent.name.length > 30
                            ? project.closeParent.name.substring(0, 30) + '...'
                            : project.closeParent.name,
                    root: '/studio/projects/one/' + project.closeParent._id,
                    id: project.closeParent._id,
                });
            } else {
                links.push({
                    title:
                        project.parent.name.length > 30
                            ? project.parent.name.substring(0, 30) + '...'
                            : project.parent.name,
                    root: '/studio/projects/one/' + project.parent._id,
                    id: project.parent._id,
                });
                if (project.closeParent.closeParent !== project.parent._id) {
                    links.push({
                        title: '..',
                    });
                }
                links.push({
                    title:
                        project.closeParent.name.length > 30
                            ? project.closeParent.name.substring(0, 30) + '...'
                            : project.closeParent.name,
                    root: '/studio/projects/one/' + project.closeParent._id,
                    id: project.closeParent._id,
                });
            }
        }
        links.push({
            title:
                project.name.length > 30
                    ? project.name.substring(0, 30) + '...'
                    : project.name,
            root: '/studio/projects/one/' + project._id,
            id: project._id,
        });
        return {
            ...state,
            members: project.users,
            projectLinks: links,
        };
    }),
    on(StudioSessionStoreActions.loadCurrentSuccess, (state, { session }) => ({
        ...state,
        // TODO override members list by using session users instead of project users
        sessionLink: { title: session.name },
        sessionMembers: [...session.users],
    })),
    on(
        StudioProjectStoreActions.updateProjectGeneralSuccess,
        (state, { project }) => {
            const projectLinks = state.projectLinks;
            if (projectLinks[projectLinks.length - 1]) {
                projectLinks[projectLinks.length - 1].title = project.name;
            }
            return { ...state, projectLinks };
        }
    ),
    on(StudioBoardStoreActions.updateBoardDetailsSuccess, (state, { name }) => {
        return { ...state, boardLink: { ...state.boardLink, title: name } };
    }),
    on(StudioBoardStoreActions.updateBoardDetailsViaSocket, (state, { name }) => {
        return { ...state, boardLink: { ...state.boardLink, title: name } };
    }),
    on(StudioBoardStoreActions.loadCurrentSuccess, (state, { board }) => ({
        ...state,
        boardLink: { title: board.name },
        boardMembers: [...board.users.filter(_ => !_.email.includes('excelway-dummy.co'))]
    })),
    on(StudioBoardStoreActions.rename, (state, { newName }) => ({
        ...state,
        boardLink: { ...state.boardLink, title: newName },
    })),
    on(StudioProjectStoreActions.updateUsersSuccess, (state, { users }) => ({
        ...state,
        members: [...users],
    })),
    on(StudioSessionStoreActions.updateSessionUsersSuccess, (state, { users }) => ({
        ...state,
        sessionMembers: [...users],
    })),
    on(StudioProjectStoreActions.updateBoardMembersSuccess, (state, { users }) => ({
        ...state,
        boardMembers: [...users.filter(_ => !_.email.includes('excelway-dummy.co'))],
    })),
    on(StudioProjectStoreActions.updateBoardMembersViaSocket, (state, { users }) => ({
        ...state,
        boardMembers: [...users.filter(_ => !_.email.includes('excelway-dummy.co'))],
    })),
    on(StudioSessionStoreActions.updateSessionDetails, (state, { session }) => ({
        ...state,
        sessionLink: { title: session.name },
    })),
    on(
        StudioHomeStoreActions.loadSuccess,
        StudioProjectsStoreActions.loadSuccess,
        StudioSessionsStoreActions.loadSuccess,
        StudioBoardsStoreActions.loadSuccess, state => ({
            ...state,
            projectLinks: [],
            members: [],
            sessionMembers: [],
            boardMembers: [],
            boardLink: null,
            sessionLink: null
        })),
);

export function reducer(state: State | undefined, action: Action): any {
    return headerReducer(state, action);
}
