import { Card } from '@bsuccess/models/brainstorming/card.model';
import { UserModel } from '@bsuccess/models/user.model';

export interface State {
    cards: Card[];
    showPoints: boolean;
    points: number;
    balance: number;
    maxPerCard: number;
    ratedUsers: UserModel[];
}

export const initialState: State = {
    cards: [],
    showPoints: false,
    points: 15,
    balance: 15,
    maxPerCard: 15,
    ratedUsers: []
};
