import { WorkshopActivityStoreActions } from 'app/root-store/workshop-store/activity-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { exhaustMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { RootStoreState } from 'app/root-store';
import { ActivityService } from '@bsuccess/services/activities.service';
import { WorkshopActivitiesStoreActions } from '../activities-store';
import { WorkshopSocketIOStoreActions } from '../socket-io-store';

@Injectable()
export class WorkshopActivityStoreEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _activityService: ActivityService
    ) { }

    updateStartedBrainstorm$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivityStoreActions.updateStartedBrainstorm),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([action, sessionKey]) => {
                return this._activityService.updateStartedBrainstormActivity(action.activity, sessionKey, action.brainstormStarted).pipe(
                    map(response =>
                        {
                            return WorkshopActivityStoreActions.updateStartedBrainstormSuccess({
                            activity: {
                                ...action.activity,
                                brainstormStarted: action.brainstormStarted
                            }
                        })}
                    ),
                    catchError(error =>
                        of(
                            WorkshopActivityStoreActions.updateStartedBrainstormFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    updateStartedEvaluation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivityStoreActions.updateStartedEvaluation),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([action, sessionKey]) => {
                return this._activityService.updateStartedEvaluationActivity(action.activity, sessionKey, action.evaluationStarted).pipe(
                    map(response =>
                        WorkshopActivityStoreActions.updateStartedEvaluationSuccess({
                            activity: {
                                ...action.activity,
                                evaluationStarted: action.evaluationStarted
                            }
                        })
                    ),
                    catchError(error =>
                        of(
                            WorkshopActivityStoreActions.updateStartedEvaluationFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    animatorUpdateActivity$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivityStoreActions.updateStartedBrainstormSuccess,
                    WorkshopActivityStoreActions.updateStartedEvaluationSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            WorkshopSocketIOStoreActions.updateActivity({
                                payload: {
                                    sessionKey,
                                    activity: _.activity
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorAddActivity$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivitiesStoreActions.addActivitySuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            WorkshopSocketIOStoreActions.addActivity({
                                payload: {
                                    sessionKey,
                                    activity: _.activity
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorUpdateActivitySuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivitiesStoreActions.updateActivitySuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            WorkshopSocketIOStoreActions.updateActivity({
                                payload: {
                                    sessionKey,
                                    activity: {
                                        _id: _.id,
                                        name: _.name,
                                        color: _.color
                                    }
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorDuplicateActivity$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivitiesStoreActions.duplicateActivitySuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            WorkshopSocketIOStoreActions.duplicateActivity({
                                payload: {
                                    sessionKey,
                                    activity: _.activity
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorDeleteActivity$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivitiesStoreActions.deleteActivitySuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            WorkshopSocketIOStoreActions.deleteActivity({
                                payload: {
                                    sessionKey,
                                    activity: _.activity
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );
}
