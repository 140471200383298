import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as ActivitiesActions from './actions';
import { WorkshopBrainstormingStoreActions } from '../brainstorming-store';
import { WorkshopActivityStoreActions } from '.';

const activityReducer = createReducer(
    initialState,
    on(ActivitiesActions.updateCurrentActivity, (state, { activity }) => {
        return {
            ...state,
            currentActivity: activity
        };
    }),
    on(ActivitiesActions.joinActivity, (state, { activity }) => {
        return {
            ...state,
            currentActivity: activity
        };
    }),
    on(WorkshopBrainstormingStoreActions.saveCardsToColumns, (state, { activity }) => {
        return {
            ...state,
            currentActivity: activity
        };
    }),    
    on(WorkshopActivityStoreActions.updateStartedBrainstormSuccess, (state, { activity }) => {
        return {
            ...state,
            currentActivity: {
                ...state.currentActivity,
                brainstormStarted: activity.brainstormStarted
            }
        };
    }),   
    on(WorkshopActivityStoreActions.updateStartedEvaluationSuccess, (state, { activity }) => {
        return {
            ...state,
            currentActivity: {
                ...state.currentActivity,
                evaluationStarted: activity.evaluationStarted
            }
        };
    }),

);

export function reducer(state: State | undefined, action: Action): any {
    return activityReducer(state, action);
}
