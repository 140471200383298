export const locale = {
    lang: 'fr',
    data: {
        NEW: 'Nouvelle Action',
        TITLE: 'Titre',
        DUE_DATE: 'Date d\'échéance',
        COMMENT: 'Commentaire',
        RESPONSIBLE: 'Responsable',
        DESCRIPTION: 'Description',
        ACCEPT: 'Accepter',
        REFUSE: 'Refuser',
    }
};
