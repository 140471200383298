export const locale = {
    lang: 'fr',
    data: {
        // tslint:disable-next-line: max-line-length
        PARAGRAPH: 'Invitez des membres à collaborer sur le projet. S\'ils ne disposent pas de compte Excelway, ils recevront une invitation à créer un compte.',
        MEMBERS: 'Membre',
        THE_EMAIL: 'Email',
        POSITION: 'Position',
        SPONSOR: 'Sponsor',
        ADMIN: 'Admin',
        ADD_USER_BUTTON_LABEL: 'Ajouter un utilisateur',
        RESPONSIBLE: 'Responsable',
        ROLE: 'Rôle',
        READING: 'Lecture',
        MODIFICATION: 'Modification',
        REMOVE: 'Retirer',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
        APPLY_CHANGES_ON_CHILDREN: 'Appliquer les changements à tous les tableaux et sous-projets attenants'
    }
};
