export const locale = {
    lang: 'fr',
    data: {
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_EDIT_FIELD: 'Modifier le champ',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_NAME: 'Nom du champ',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_TYPE: 'Type de champ',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_MULTIPLE: 'Sélection multiple',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_TEXT: 'La sélection multiple vous permet de sélectionner une ou plusieurs options prédéfinies listées ci-dessous.',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_ADD_OPTION: 'Ajouter une option',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_CANCEL: 'Annuler',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_FIELD_SAVE: 'Enregistrer', 
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_CATEGORIES: 'Étiquettes',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_ADD: 'Ajouter',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_EDIT_CATEGORIES: 'Modifier étiquette',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_ADD_CATEGORIES: 'Ajouter étiquette',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_DELETE_CATEGORY: 'Supprimer étiquette',
        PROJECTS_BOARD_DIALOGS_LABEL_SELECTOR_NAME: 'Nom',
    }
};
