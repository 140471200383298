import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as AccessActions from './actions';

const accessReducer = createReducer(
    initialState,
    on(AccessActions.init, (state, { key }) => ({
        ...state,
        form: key !== '2' ? 'welcome' : '',
    })),
    on(AccessActions.updateForm, (state, { form }) => ({
        ...state,
        form,
    })),
    on(AccessActions.updateWelcomeType, (state, { welcome }) => ({
        ...state,
        welcomeType: welcome,
    })),
    on(AccessActions.signUpUser, (state, { tenant }) => ({
        ...state,
        tenant,
        pending: true,
        pendingSignUp: true
    })),
    on(AccessActions.uploadProfileImageSuccess, (state, { name }) => ({
        ...state,
        profilePic: name,
    })),
    on(AccessActions.signUpUserFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
        pendingSignUp: false
    })),
    on(AccessActions.signUpUserSuccess, (state, { projectId }) => ({
        ...state,
        pendingSignUp: false
    })),
    on(
        AccessActions.loadPrivateSession,
        AccessActions.loadPublicSession,
        (state, { tenant, sessionKey, key }) => ({
            ...state,
            tenant,
            sessionKey,
            key,
        })
    ),
    on(
        AccessActions.loadObject,
        (state, { tenant, object, objectId, key, param, parentId }) => ({
            ...state,
            tenant,
            object,
            objectId,
            key,
            param,
            parentId,
        })
    ),
    on(AccessActions.getUserSuccess, (state, { user }) => {
        return {
            ...state,
            user,
            form: 'register',
        };
    }),
    on(AccessActions.generateUserSuccess, (state, { user, newUser }) => {
        return {
            ...state,
            user,
            form: newUser ? 'register-public' : '',
        };
    }),
    on(
        AccessActions.login,
        AccessActions.updatePassword, (state) => ({
            ...state,
            error: null,
            pending: true,
        })),
    on(
        AccessActions.loginFailure,
        AccessActions.getUserFailure, (state, { error }) => ({
            ...state,
            error,
            pending: false,
        })),
    on(AccessActions.getUserbyIdSuccess, (state, { user, tenant }) => {
        return {
            ...state,
            user: user,
            tenant
        };
    }),
    on(AccessActions.changePassword, state => {
        return {
            ...state,
            pending: true
        };
    }),
    on(AccessActions.changePasswordSuccess, state => {
        return {
            ...state,
            pending: false
        };
    }),
    on(AccessActions.getPlansSuccess, (state, { plans }) => {
        return {
            ...state,
            plans,
            paymentDone: false,
            paymentAddonDone: false,
            pendingPaymentAddon: false,
            pendingPayment: false
        };
    }),
    on(AccessActions.attachPaymentMethodAndSubscribe, state => {
        return {
            ...state,
            pendingPayment: true
        };
    }),
    on(AccessActions.subscribeSuccess, state => {
        return {
            ...state,
            pendingPayment: false,
            paymentDone: true
        };
    }),
    on(AccessActions.getUserPaymentMehodsSuccess, (state, { response }) => {
        return {
            ...state,
            userPaymentMethods: response
        };
    }),
    on(AccessActions.selectPricing, (state, { plan }) => {
        return {
            ...state,
            selectedPlan: plan
        };
    }),
    on(AccessActions.addAddon, (state, { items, defaultPaymentMethod }) => {
        return {
            ...state,
            pendingPaymentAddon: true
        };
    }),
    on(AccessActions.addAddonSuccess, (state, { response }) => {
        return {
            ...state,
            pendingPaymentAddon: false,
            paymentAddonDone: true
        };
    }),
    on(AccessActions.getTaxesSuccess, (state, { taxes }) => {
        return {
            ...state,
            taxes
        };
    }),
    on(AccessActions.getUserInfoSuccess, (state, { user }) => {
        return {
            ...state,
            userInfo: user
        };
    }),
    on(AccessActions.signUpUser, state => {
        return {
            ...state,
            signUpError: null
        };
    }),
    on(AccessActions.signUpUserFailure, (state, { error }) => {
        return {
            ...state,
            signUpError: error
        };
    }),
);

export function reducer(state: State | undefined, action: Action): any {
    return accessReducer(state, action);
}
