import { createAction, props } from '@ngrx/store';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { SessionDetailModel } from '@bsuccess/models/session/session-detail.model';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';
import { ServiceVersionModel } from '@bsuccess/models/service/service-version.model';
import { ProjectModel } from '@bsuccess/models/project/project.model';

export const loadCurrent = createAction(
    '[Studio][Session Page] Load Current Session',
    props<{ id: string }>()
);

export const loadCurrentSuccess = createAction(
    '[Studio][Session API] Load Current Session Success',
    props<{ session: ProjectSessionModel }>()
);

export const loadCurrentFailure = createAction(
    '[Studio][Session API] Load Current Session Failure',
    props<{ error: string }>()
);

// Session actions
export const showAddSessionDialog = createAction(
    '[Studio][Project Sessions Tab] Show Add Session Dialog'
);

export const addSession = createAction(
    '[Studio][Project Session Dialog] Comfirm Add',
    props<{ session: ProjectSessionModel }>()
);

export const addSessionSuccess = createAction(
    '[Studio][Project API] Add Session Success',
    props<{ session: ProjectSessionModel }>()
);

export const addSessionFailure = createAction(
    '[Studio][Project API] Add Session Failure',
    props<{ error: string }>()
);

export const showUpdateSessionDetailsDialog = createAction(
    '[Studio][Project Sessions Tab] Show Update Session Details Dialog'
);

export const updateSessionDetails = createAction(
    '[Studio][Project Session Dialog] Confirm Update Session Details',
    props<{ session: ProjectSessionModel }>()
);

export const updateSessionDetailsSuccess = createAction(
    '[Studio][Project API] Update Session Details Success',
    props<{ session: ProjectSessionModel }>()
);

export const updateSessionDetailsFailure = createAction(
    '[Studio][Project API] Update Session Details Failure',
    props<{ error: string }>()
);

export const duplicateSession = createAction(
    '[Studio][Project Session] Project Duplicate Session',
    props<{ projectId: string, sessionId: string }>()
);

export const duplicateSessionSuccess = createAction(
    '[Studio][Project API] Project Duplicate Session Success',
    props<{ sessionId: string, projectId: string, session: ProjectSessionModel }>()
);

export const duplicateSessionFailure = createAction(
    '[Studio][Project API] Project Duplicate Session Failure',
    props<{ error: string }>()
);

/////////////

export const updateSessionUsers = createAction(
    '[Studio][Session Dialog] Update Session Users',
    props<{ users: ProjectUserModel[] }>()
);

export const updateSessionUsersSuccess = createAction(
    '[Studio][Session API] Update Session Users Success',
    props<{ users: ProjectUserModel[] }>()
);

export const updateSessionUsersFailure = createAction(
    '[Studio][Session API] Update Session Users Failure',
    props<{ error: string }>()
);
// Session users actions

export const sessionShowUsersDialog = createAction(
    '[Studio][Session Users Dialog] Show Dialog',
    props<{ sessionId: string }>()
);

// Update Participants Actions

export const updateParticipants = createAction(
    '[Studio][Session Page] Update Participants',
    props<{ emails: string[] }>()
);

export const updateParticipantsSuccess = createAction(
    '[Studio][Session API] Update Participants Success',
    props<{ participants: ProjectUserModel[] }>()
);

export const updateParticipantsFailure = createAction(
    '[Studio][Session API] Update Participants Failure',
    props<{ error: string }>()
);

// Update Guests Actions

export const updateGuests = createAction(
    '[Studio][Session Page] Update Guests',
    props<{ emails: string[] }>()
);

export const updateGuestsSuccess = createAction(
    '[Studio][Session API] Update Guests Success',
    props<{ guests: { email: string }[] }>()
);

export const updateGuestsFailure = createAction(
    '[Studio][Session API] Update Guests Failure',
    props<{ error: string }>()
);

// Add new participants / guests  to ongoing Session
// participants
export const showUpdateParticipantsAndSendInvitationDialog = createAction(
    '[Studio][Session Update Participants Dialog] Show Dialog'
);

export const updateParticipantsAndSendInvitation = createAction(
    '[Studio][Session Page] Update Participants And Send Invitation',
    props<{ emails: string[] }>()
);

export const updateParticipantsAndSendInvitationSuccess = createAction(
    '[Studio][Session API] Update Participants And Send Invitation Success',
    props<{ participants: ProjectUserModel[] }>()
);

export const updateParticipantsAndSendInvitationFailure = createAction(
    '[Studio][Session API] Update Participants And Send Invitation Failure',
    props<{ error: string }>()
);
// guests
export const showUpdateGuestsAndSendInvitationDialog = createAction(
    '[Studio][Session Update Guests Dialog] Show Dialog'
);

export const updateGuestsAndSendInvitation = createAction(
    '[Studio][Session Page] Update Guests And Send Invitation',
    props<{ emails: string[] }>()
);

export const updateGuestsAndSendInvitationSuccess = createAction(
    '[Studio][Session API] Update Guests And Send Invitation Success',
    props<{ guests: { email: string }[] }>()
);

export const updateGuestsAndSendInvitationFailure = createAction(
    '[Studio][Session API] Update Guests And Send Invitation Failure',
    props<{ error: string }>()
);
///////////

export const showInvitationDialog = createAction(
    '[Studio][Session Invitation Dialog] Show Invitation Dialog',
    props<{
        tenant: string;
        sessionName: string;
        sessionToken: string;
        sessionDetail: SessionDetailModel;
    }>()
);

export const confirmInvitation = createAction(
    '[Studio][Session Invitation Dialog] Confirm Invitation',
    props<{
        sessionDetail: SessionDetailModel;
    }>()
);

export const cancelInvitation = createAction(
    '[Studio][Session Invitation Dialog] Cancel Invitation'
);

export const sendInvitations = createAction(
    '[Studio][Session Page] Send Session Invitations',
    props<{ sessionId: string }>()
);

export const sendInvitationsSuccess = createAction(
    '[Studio][Session API] Send Session Invitations Success',
    props<{ sessionDetail: SessionDetailModel }>()
);

export const sendInvitationsFailure = createAction(
    '[Studio][Session API] Send Session Invitations Failure',
    props<{ error: string }>()
);

export const updateStatus = createAction(
    '[Studio][Session Page] Update Session Status',
    props<{ status: string }>()
);

export const updateStatusSuccess = createAction(
    '[Studio][Session API] Update Session Status Success',
    props<{ status: string }>()
);

export const updateStatusFailure = createAction(
    '[Studio][Session API] Update Session Status Failure',
    props<{ error: string }>()
);

export const addCardstoBoard = createAction(
    '[Studio][Session Dialog] Add tasks as cards to board',
    props<{ tasks: SessionTaskModel[]; boardId: string }>()
);

export const addCardstoBoardWithComment = createAction(
    '[Studio][Session Dialog] Add tasks as cards to board with comment',
    props<{ tasks: SessionTaskModel[]; boardId: string }>()
);

export const addCardstoBoardWithCommentConfirmed = createAction(
    '[Studio][Session Dialog] Add tasks as cards to board with comment Confirmed',
    props<{ tasks: SessionTaskModel[]; boardId: string; comment: string }>()
);

export const addCardstoBoardWithCommentCanceled = createAction(
    '[Studio][Session Dialog] Add tasks as cards to board with comment Canceled'
);

export const addCardstoBoardWithCommentSuccess = createAction(
    '[Studio][Session Dialog] Add tasks as cards to board with comment Success',
    props<{ tasks: SessionTaskModel[] }>()
);

export const addCardstoBoardWithCommentFailure = createAction(
    '[Studio][Session Dialog] Add tasks as cards to board with comment Failure',
    props<{ error: string }>()
);

export const addCardstoBoardSuccess = createAction(
    '[Studio][Session API] Add tasks as cards to board Success',
    props<{ tasks: SessionTaskModel[] }>()
);

export const addCardstoBoardFailure = createAction(
    '[Studio][Session API] Add tasks as cards to board Failure',
    props<{ error: string }>()
);

// archive  actions
export const archiveSession = createAction(
    '[Studio][Session Page] Archive Session',
    props<{ id: string }>()
);

export const archiveSessionSuccess = createAction(
    '[Studio][Session API] Archive Session Success',
    props<{ id: string }>()
);

export const archiveSessionFailure = createAction(
    '[Studio][Session API] Archive Session Failure',
    props<{ error: string }>()
);

export const archiveSessionConfirmed = createAction(
    '[Studio][Session Page] Archive Session Confirmed',
    props<{ id: string }>()
);

export const archiveSessionCancelled = createAction(
    '[Studio][Session Page] Archive Session Cancelled'
);

// End monitoring actions
export const endMonitoring = createAction('[Studio][Session Page] End Monitoring ');

export const endMonitoringConfirmed = createAction(
    '[Studio][Session Page] End Monitoring Confirmed'
);

export const endMonitoringCancelled = createAction(
    '[Studio][Session Page] End Monitoring Cancelled'
);

// End monitoring actions
export const closeSession = createAction('[Studio][Session Page] Close Session ');

export const closeSessionConfirmed = createAction(
    '[Studio][Session Page] Close Session Confirmed'
);

export const closeSessionCancelled = createAction(
    '[Studio][Session Page] Close Session Cancelled'
);
// TODO Move to separate Store
export const loadSessionVersions = createAction(
    '[Studio][Session Page] Load Session Versions'
);

export const loadSessionVersionsSuccess = createAction(
    '[Studio][Session API] Load Session Versions Success',
    props<{
        versions: ServiceVersionModel[];
    }>()
);

export const loadSessionVersionsFailure = createAction(
    '[Studio][Session API] Load Versions Failure',
    props<{ error: string }>()
);

export const sessionShowTaskContentDialog = createAction(
    '[Studio][Session Task Content Dialog] Show Task Content Dialog',
    props<{ id: string }>()
);

export const updateSessionActionPlan = createAction(
    '[Studio][Session Page] Update Session Action Plan',
    props<{ actionPlan: SessionTaskModel }>()
);

export const updateSessionActionPlanSuccess = createAction(
    '[Studio][Session API] Update Session Action Plan Success',
    props<{ actionPlan: SessionTaskModel }>()
);

export const updateSessionActionPlanSuccessViaSocket = createAction(
    '[Studio][Session API] Update Session Action Plan Success Via Socket',
    props<{ actionPlan: SessionTaskModel }>()
);

export const updateSessionActionPlanFailure = createAction(
    '[Studio][Session API] Update Session ActionPlan Failure',
    props<{ error: string }>()
);

export const loadSelectedProject = createAction(
    '[Studio][Session Page] Load Selected Project',
    props<{ id: string }>()
);

export const loadSelectedProjectSuccess = createAction(
    '[Studio][Session API] Load Selected Project Success',
    props<{ project: ProjectModel }>()
);

export const loadSelectedProjectFailure = createAction(
    '[Studio][Session API] Load Selected Project Failure',
    props<{ error: string }>()
);

export const showWarningDialog = createAction(
    '[Studio][Project Sessions Tab] Show Update Session Warning Dialog'
);

// session settings

export const updateSessionSettingsInfo = createAction(
    '[Workshop][Session Settings Page] Update Session Settings Info',
    props<{ sessionId: string; name: string; description: string, startDate: string, endDate: string, startTime: string, endTime: string }>()
);

export const updateSessionSettingsInfoSuccess = createAction(
    '[Workshop][Session API] Update Session Settings Info Success',
    props<{ sessionId: string; name: string; description: string, startDate: string, endDate: string, startTime: string, endTime: string }>()
);

export const updateSessionSettingsInfoFailure = createAction(
    '[Workshop][Session API] Update Session Settings Info Failure',
    props<{ error: string }>()
);

export const updateSessionSettingsPrivacy = createAction(
    '[Workshop][Session Settings Page] Update Session Settings Privacy',
    props<{ sessionId: string; isPrivate: boolean }>()
);

export const updateSessionSettingsPrivacySuccess = createAction(
    '[Workshop][Session API] Update Session Settings Privacy Success',
    props<{ sessionId: string; isPrivate: boolean }>()
);

export const updateSessionSettingsPrivacyFailure = createAction(
    '[Workshop][Session API] Update Session Settings Privacy Failure',
    props<{ error: string }>()
);

export const updateDetail = createAction(
    '[Studio][Session Page] Update Session Detail',
    props<{
        sessionId: string,
        sessionDetail: SessionDetailModel;
    }>()
);

export const updateDetailSuccess = createAction(
    '[Studio][Session Page] Update Session Detail Success',
    props<{ sessionDetail: SessionDetailModel; }>()
);

export const updateDetailFailure = createAction(
    '[Studio][Session Page] Update Session Detail Failure',
    props<{ error: string }>()
);

export const confirmPrivacyChangeDialog = createAction(
    '[Workshop][Session Settings Page] Show Confirm Privacy Change Dialog'
);

export const closeSessionByStatus = createAction(
    '[Studio][Session Page] Close Session By Status',
    props<{ id: string }>()
);

export const closeSessionByStatusSuccess = createAction(
    '[Studio][Session API] Close Session By Status Success',
    props<{ id: string }>()
);

export const closeSessionByStatusFailure = createAction(
    '[Studio][Session API] Close Session By Status Failure',
    props<{ error: string }>()
);

export const closeSessionByStatusConfirmed = createAction(
    '[Studio][Session Page] Close Session By Status Confirmed',
    props<{ id: string }>()
);

export const closeSessionByStatusCancelled = createAction(
    '[Studio][Session Page] Close Session By Status Cancelled'
);

export const openSessionByStatus = createAction(
    '[Studio][Session Page] Open Session By Status',
    props<{ id: string }>()
);

export const openSessionByStatusSuccess = createAction(
    '[Studio][Session API] Open Session By Status Success',
    props<{ id: string }>()
);

export const openSessionByStatusFailure = createAction(
    '[Studio][Session API] Open Session By Status Failure',
    props<{ error: string }>()
);


export const updateRemoveSessionTask = createAction(
    '[Workshop][Task Panel] Remove Session Task',
    props<{ sessionId: string, taskId: string }>()
);

export const updateRemoveSessionTaskSuccess = createAction(
    '[Workshop][Session API] Remove Session Task Success',
    props<{ taskId: string }>()
);

export const updateRemoveSessionTaskSuccessViaSocket = createAction(
    '[Workshop][Session API] Remove Session Task Success Via Socket',
    props<{ taskId: string }>()
);

export const updateRemoveSessionTaskFailure = createAction(
    '[Workshop][Session API] Remove Session Task Failure',
    props<{ error: string }>()
);

export const getSessionDocxReport= createAction(
    '[Report][Session] Get Session Docx Report',
    props<{ format: string }>()
);

export const getSessionDocxReportSuccess = createAction(
    '[Report][Session] Get Session Docx Report Success',
);

export const getSessionDocxReportFailure = createAction(
    '[Report][Session] Get Session Docx Report Failure',
);