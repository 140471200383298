import { createReducer, Action, on } from '@ngrx/store';

import { initialState, State } from './state';
import * as DocumentActions from './actions';

const documentReducer = createReducer(
    initialState,
    on(DocumentActions.uploadDocument, (state, { content }) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(DocumentActions.addDocumentSuccess, (state, { document }) => ({
        ...state,
        loading: false,
        error: null,
        lastUpload: null,
    })),
    on(DocumentActions.uploadDocumentSuccess, (state, { response }) => ({
        ...state,
        current: {
            _id: response._id,
            contentType: response.contentType,
            filename: response.filename,
        },
        loading: false,
        lastUpload: response._id,
    })),
    on(DocumentActions.deleteDocument, state => ({
        ...state,
        pending: true,
        error: null,
    })),
    on(DocumentActions.deleteDocumentSuccess, (state, { id }) => ({
        ...state,
        pending: false,
        error: null,
    })),
    on(DocumentActions.deleteDocumentFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error,
    }))
);

export function reducer(state: State | undefined, action: Action): any {
    return documentReducer(state, action);
}
