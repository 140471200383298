export const locale = {
    lang: 'en',
    data: {
        EDIT: 'Edit information',
        LAST: 'Last name',
        FIRST: 'First name',
        ABOUT: 'About',
        SAVE: 'Save',
        CANCEL: 'Cancel',
    }
};
