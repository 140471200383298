import { HeaderLinkModel } from '@bsuccess/models/header-link.model';

export interface State {
    hidden: boolean;
    projectLinks: HeaderLinkModel[];
    sessionLink?: HeaderLinkModel;
    boardLink?: HeaderLinkModel;
    menuType: string; // 'project', 'session', 'board'
    members?: any[];
    sessionMembers?: any[];
    boardMembers?: any[];
}

export const initialState: State = {
    hidden: true,
    projectLinks: [],
    menuType: null,
    members: [],
    sessionMembers: [],
    boardMembers: []
};
