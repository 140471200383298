import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../../src/app/i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../../src/app/i18n/@fuse/components/confirm-dialog/fr';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseConfirmDialogComponent {
    public confirmMessage: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        public  translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh);
    }

}
