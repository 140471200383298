import { StandbyCardModel } from '../../../../@bsuccess/models/standbycard/standbycard.model';
import { createAction, props } from '@ngrx/store';

// Notifications actions
export const load = createAction('[Studio][Notifications API] Load');

export const loadSuccess = createAction(
    '[Studio][Notifications API] Load Success',
    props<{ notifications: StandbyCardModel[] }>()
);

export const loadFailure = createAction(
    '[Studio][Notifications API] Load Failure',
    props<{ error: string }>()
);

export const showNewActionDialog = createAction(
    '[Studio][Notifications Dialog] Show New Action Dialog',
    props<{ notification: StandbyCardModel }>()
);

export const updateIsRead = createAction(
    '[Studio][Notifications Page] Update Standby Card Is Read',
    props<{ boardId: string; standbyCardId: string }>()
);

export const updateIsReadSuccess = createAction(
    '[Studio][Notifications Page] Update Standby Card Is Read Success',
    props<{ _id: string }>()
);

export const updateIsReadFailure = createAction(
    '[Studio][Notifications Page] Update Standby Card Is Read Failure',
    props<{ error: string }>()
);

export const updateStatus = createAction(
    '[Studio][Notifications Page] Update Standby Card Status',
    props<{ standbyCardId: string; status: string }>()
);

export const updateStatusSuccess = createAction(
    '[Studio][Notifications Page] Update Standby Card Status Success',
    props<{ id: string; status: string }>()
);

export const updateStatusFailure = createAction(
    '[Studio][Notifications Page] Update Standby Card Status Failure',
    props<{ error: string }>()
);
