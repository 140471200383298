export const locale = {
    lang: 'fr',
    data: {
        EXPORT_ACTIONS: 'Exporter des actions vers un autre projet',
        APPROVED_ACTIONS: 'Les actions doivent être approuvées par les administrateurs du projet pour être exportées vers le tableau sélectionné.',
        EXPORT_ACTIONS_BOARD: 'Exporter l\'action vers un autre tableau',
        APPROVED_ACTIONS_BOARD: 'L\'action doit être approuvée par les administrateurs du tableau pour être exportée vers le tableau sélectionné.',
        ADD_COMMENT: 'Ajoutez un commentaire pour accompagner cette demande d’approbation',
        COMMENT: 'Commentaire',
        EXPORT: 'Exporter',
        CANCEL: 'Annuler',
        ADD_ACTIONS: 'Ajouter des actions à un autre projet',
        ADDED_ACTIONS: 'Les actions doivent être approuvées par les administrateurs du projet pour être ajoutées au tableau sélectionné.',
        ADD_ACTIONS_BOARD: 'Ajouter une action à un autre tableau',
        ADDED_ACTIONS_BOARD: 'L\'action doit être approuvée par les administrateurs du tableau pour être ajoutée au tableau sélectionné.',
        ADD: 'Ajouter',
    }
};
