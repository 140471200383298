export const locale = {
    lang: 'fr',
    data: {
        TRANSFORM: 'Transformer cartes en actions',
        ALL_CARDS: 'Toute les cartes',
        TYPE: 'Type',
        POINTS: 'Points',
        LIST_CARDS: 'Cartes des listes',
        CATEGORIES: 'Couleurs',
        CARDS_HAVE: 'Cartes qui ont',
        OF: 'de',
        CHOOSE_CARDS: 'Choisir les cartes',
        PLUS: 'plus',
        MOINS: 'moins',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
        NEXT: 'Suivant'
    }
};
