export const locale = {
    lang: 'ar',
    data: {
        CATEGORIES: 'الألوان',
        ADD: 'أضف',
        EDIT: 'تحرير اللون',
        ADD_CATEGORIE: 'إضافة اللون',
        ADD_U: 'إضافة',
        NAME: 'إسم',
    }
};
