export const locale = {
    lang: 'en',
    data: {
        ALL_CARDS: 'All cards',
        CARDS_LIST: 'Cards from lists',
        LIST_WHERE_TO_PLACE_TWO:'Which cards do you want to import?',
        CHOOSE_CARDS: 'Choose cards',
        CANCEL: 'Cancel',
        SAVE: 'Save',
    }
};
