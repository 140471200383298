export const locale = {
    lang: 'fr',
    data: {
        ASSESSEMENT: 'Lancer un vote',
        ALL_CATEGORIES: 'Toutes couleurs',
        CARD_CATEGORIES: 'Couleurs de cartes',
        BUDGET_POINTS: 'Budget de points',
        MAX: 'Points max par carte',
        START: 'Lancer vote',
        CANCEL: 'Annuler',
    }
};
