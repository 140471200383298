import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';

export const selectUsersState = createFeatureSelector<State>('studio-users');

export const selectUsers = createSelector(
    selectUsersState,
    (state: State): ProjectUserModel[] => state.users
);
