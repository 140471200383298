export const locale = {
    lang: 'ar',
    data: {
        EDIT: 'تعديل المعلومات',
        OCCUPATION: 'مهنة',
        TEAM: 'فريق',
        COMPANY: 'شركة',
        SAVE: 'حفظ',
        CANCEL: 'إلغاء',
    }
};
