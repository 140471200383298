import { createAction, props } from '@ngrx/store';

import { FuseNavigation } from '@fuse/types';

export const updateNavigation = createAction('[Navbar] Update Navigation');

export const updateNavigationSuccess = createAction(
  '[Navbar] Update Navigation Success',
  props<{ navigation: FuseNavigation[] }>()
);

export const updateNavigationFailure = createAction(
  '[Navbar] Update Navigation Failure'
);

export const updateProduct = createAction(
  '[Navbar] Update Product',
  props<{ product: string }>()
);

export const updateProductSuccess = createAction(
  '[Navbar] Update Product Success',
  props<{ product: string }>()
);

export const updateProductFailure = createAction(
  '[Navbar] Update Product Failure'
);

export const logout = createAction('[Navbar] Logout');

export const logoutConfirmed = createAction('[Navbar] Logout Confirmed');

export const logoutCanceled = createAction('[Navbar] Logout Canceled');

export const leaveWorkshop = createAction('[Navbar] Leave Workshop');

export const leaveWorkshopConfirmed = createAction(
  '[Navbar] Leave Workshop Confirmed'
);

export const leaveWorkshopCanceled = createAction(
  '[Navbar] Leave Workshop Canceled'
);

export const leaveWorkshopWithoutConfirmation = createAction(
  '[Navbar] Leave Workshop Without Confirmed'
);

export const goToNotes = createAction('[Navbar] Go To Notes');

export const goToHome = createAction('[Navbar] Go To Home');

export const updateLang = createAction(
  '[Navbar] Update Lang',
  props<{ lang: string }>()
);

export const updateLangSuccess = createAction(
  '[Navbar] Update Lang Success',
  props<{ lang: string }>()
);

export const updateLangFailure = createAction(
  '[Navbar] Update Lang Failure',
  props<{ error: string }>()
);

export const checkNavbar = createAction('[Navbar] Check Navbar');

export const toggleTasksPanel = createAction('[Navbar] Toggle Tasks Panel');

export const toggleNotesPanel = createAction('[Navbar] Toggle Notes Panel');
