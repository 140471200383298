import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { CacheModel } from '@bsuccess/models/cache.model';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { LoginStoreActions } from 'app/root-store/login-store';
import { RootStoreState } from 'app/root-store';
import { Store } from '@ngrx/store';
import { catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private _localStorageService: LocalStorageService,
        private _store: Store<RootStoreState.State>
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const cache: CacheModel = this._localStorageService.getCache();
        if (request.url.includes('api.stripe.com')) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': "Bearer " + 'sk_test_51HMC2aIASQYBUqwR3PaTRAfm2nz3C7MbOZCNgwLXbUI6iV9j8Pqq7TW5MXVGGRA98Fl4vNSNCeaz1uLH41jyLLhF00Z5EJS3a1',
                },
            });
        }else {
            if (request.url.includes(environment.minio.url) || request.url.includes('/access/sign-up') || request.url.includes('/convert') || request.url.includes('report-fixer')) {
                request = request.clone();
            } else if (
                request.url.includes('attachment') &&
                cache.login.token &&
                cache.login.tenant
            ) {
                request = request.clone({
                    setHeaders: {
                        'X-Auth-Token': cache.login.token,
                        'X-Tenant': cache.login.tenant,
                    },
                });
            } else if (cache.login.token && cache.login.tenant) {
                request = request.clone({
                    setHeaders: {
                        'X-Auth-Token': cache.login.token,
                        'X-Tenant': cache.login.tenant,
                        'Content-Type': 'text/plain',
                    },
                });
            } else if (cache.login.tenant) {
                request = request.clone({
                    setHeaders: {
                        'X-Tenant': cache.login.tenant,
                        'Content-Type': 'text/plain',
                    },
                });
            }
        }


        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && !request.url.includes('signIn') && !request.url.includes('user/password')) {
                    this._store.dispatch(LoginStoreActions.notAuthorized());
                }
                return throwError(error);
            })
        );
    }
}
