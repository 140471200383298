import { Board } from '@bsuccess/models/brainstorming/board.model';
import { Card } from '@bsuccess/models/brainstorming/card.model';
import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { BoardCardModel } from '@bsuccess/models/board/board-card.model';

export interface State {
    boards: ProjectBoardModel[];
    currentBoard: Board;
    pending?: boolean;
    error?: string;
    visibility: string;
    cardsToColumns: string[];
    exportCards: Card[];
    allCategoriesChecked?: boolean;
    selectedImportBoard: BoardModel;
    importCards: BoardCardModel[];
    showMembersNames: boolean;
    hideAllCards: boolean;    
    points: number;
    showLabels: boolean;
}

export const initialState: State = {
    boards: [],
    currentBoard: null,
    pending: false,
    error: null,
    visibility: 'all_cards',
    cardsToColumns: [],
    exportCards: [],
    allCategoriesChecked: false,
    selectedImportBoard: null,
    importCards: [],
    showMembersNames: false,
    hideAllCards: false,       
    points: 0,
    showLabels: false
};
