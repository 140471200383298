export const locale = {
    lang: 'en',
    data: {
        PAYMENT_DIALOG_SELECTED_PLAN: 'Selected Plan :' ,
        PAYMENT_DIALOG_Business : 'Business',
        PAYMENT_DIALOG_Team : 'Team',
        PAYMENT_DIALOG_UNLIMITED: 'Unlimited' ,
        PAYMENT_DIALOG_PAYMENT_SECCESSFUL: 'Payment successful' ,
        PAYMENT_DIALOG_COPY_SENT_TO_EMAIL: 'A copy of this transaction has been sent to your email.' ,
        PAYMENT_DIALOG_THANK_YOU: 'Thank you for choosing Excelway!' ,
        PAYMENT_DIALOG_PLEASE_WAIT: 'Please Wait...' ,
        PAYMENT_DIALOG_PAYMENT_METHOD: 'Payment method' ,
        PAYMENT_DIALOG_OTHER: ' Other ' ,
        PAYMENT_DIALOG_CREDIT_CARD_DETAILS: 'Credit card details' ,
        PAYMENT_DIALOG_ADDS_ON: 'Add-ons' ,
        PAYMENT_DIALOG_HAVE_PROMO: 'Have a promo code?' ,
        PAYMENT_DIALOG_BILLING_INFORMATION: 'Billing information' ,
        PAYMENT_DIALOG_COUNTRY: 'Country' ,
        PAYMENT_DIALOG_BILLING_MOROCCO: 'Morocco' ,
        PAYMENT_DIALOG_BILLING_USA: 'USA' ,
        PAYMENT_DIALOG_BILLING_FRANCE: 'France' ,
        PAYMENT_DIALOG_BILLING_POSTAL_CODE: 'Postal Code' ,
        PAYMENT_DIALOG_WHAT_YOU_WILL_GET: 'What you\'ll get' ,
        PAYMENT_DIALOG_MEMBERS: 'members' ,
        PAYMENT_DIALOG_GB: 'GB' ,
        PAYMENT_DIALOG_STORAGE: 'of storage' ,
        PAYMENT_DIALOG_FREE_PARTICIPANTS: 'participants free in public workshops' ,
        PAYMENT_DIALOG_ADMIN_BILLING: 'Admin billing page' ,
        PAYMENT_DIALOG_YOU_BE_CHARGE: 'You\'ll be charged' ,
        PAYMENT_DIALOG_DOLLAR: '$' ,
        PAYMENT_DIALOG_year: 'yearly' ,
        PAYMENT_DIALOG_month: 'monthly' ,
        PAYMENT_DIALOG_UNTIL_CANCEL: 'until you cancel the subscription. To learn more see our FAQ or email us at support@excelway.co' ,
        PAYMENT_DIALOG_TOTAL: 'Total:' ,
        PAYMENT_DIALOG_PURSHASE: 'Purchase' ,
        PAYMENT_DIALOG_CONFIRM: 'Confirm' ,
    }
};
