import { createAction, props } from '@ngrx/store';
import { Activity } from '@bsuccess/models/activities/activity.model';

export const updateCurrentActivity = createAction(
    '[Workshop][Activity Page] Update Current Activity',
    props<{
        activity: Activity;
    }>()
);

export const joinActivity = createAction(
    '[Workshop][Activity Page] Join Activity',
    props<{
        activity: Activity;
    }>()
);

export const joinActivityRoom = createAction(
    '[Workshop][Activity Page] Join Activity Room',
    props<{
        id: string;
    }>()
);

export const updateStartedBrainstorm = createAction(
    '[Workshop][Activity Page] Update Brainstorm Activity Started',
    props<{ activity: Activity, brainstormStarted: boolean }>()
);

export const updateStartedBrainstormSuccess = createAction(
    '[Workshop][Activity API] Update Brainstorm Activity Started Success',
    props<{ activity: Activity }>()
);

export const updateStartedBrainstormFailure = createAction(
    '[Workshop][Activity API] Update Brainstorm Activity Started Failure',
    props<{ error: string }>()
);

export const updateStartedEvaluation = createAction(
    '[Workshop][Activity Page] Update Evaluation Activity Started',
    props<{ activity: Activity, evaluationStarted: boolean }>()
);

export const updateStartedEvaluationSuccess = createAction(
    '[Workshop][Activity API] Update Evaluation Activity Started Success',
    props<{ activity: Activity }>()
);

export const updateStartedEvaluationFailure = createAction(
    '[Workshop][Activity API] Update Evaluation Activity Started Failure',
    props<{ error: string }>()
);

