import { FuseUtils } from '@fuse/utils';

export class Card {
    id: string;
    name: string;
    description: string;
    idAttachmentCover: string;
    idMembers: string[];
    idLabels: string[];
    attachments: any[];
    blamer?: any;
    checklists: any[];
    checkItems: number;
    checkItemsChecked: number;
    comments: any[];
    activities: any[];
    due: string;
    color?: string;
    typeCard?: string;
    isVisible?: boolean;
    rates: any[];
    sum: number;

    constructor(card: any) {
        this.id = card.id || FuseUtils.generateGUID();
        this.name = card.name || '';
        this.description = card.description || '';
        this.idAttachmentCover = card.idAttachmentCover || '';
        this.idMembers = card.idMembers || [];
        this.idLabels = card.idLabels || [];
        this.attachments = card.attachments || [];
        this.blamer = card.blamer || null;
        this.checklists = card.checklists || [];
        this.checkItems = card.checkItems || 0;
        this.checkItemsChecked = card.checkItemsChecked || 0;
        this.comments = card.comments || [];
        this.activities = card.activities || [];
        this.due = card.due || '2019-01-01';
        this.color = card.color || 'yellow-100';
        this.typeCard = card.typeCard || '';
        this.isVisible = card.isVisible || true;
        this.rates = card.rates || [];
        this.sum = card.sum || 0;
    }
}
