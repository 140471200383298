import { DocumentModel } from '@bsuccess/models/project/document.model';

export interface State {
    current: DocumentModel;
    loading: boolean;
    error?: string;
    lastUpload?: string;
}

export const initialState: State = {
    current: null,
    loading: false,
    error: null,
    lastUpload: null,
};
