import { Board } from '@bsuccess/models/canvas/board.model';
import { BoardListItemModel } from '@bsuccess/models/canvas/board-list-item.model';
import { Card } from '@bsuccess/models/canvas/card.model';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';
import { Template } from '@bsuccess/models/canvas/template.model';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { BoardCardModel } from '@bsuccess/models/board/board-card.model';

export interface State {
    boards: BoardListItemModel[];
    currentBoard: Board;
    currentTemplate: Template;
    pending?: boolean;
    error?: string;
    selectedImportBoard: BoardModel;
    importCards: BoardCardModel[];
    visibility: string;
    cardsToColumns: string[];
    exportCards: Card[];
    allCategoriesChecked?: boolean;
    showMembersNames: boolean;    
    hideAllCards: boolean;    
    points: number;
    showLabels: boolean;
}

export const initialState: State = {
    boards: [],
    currentBoard: null,
    currentTemplate: null,
    selectedImportBoard: null,
    importCards: [],
    pending: false,
    error: null,
    visibility: 'all_cards',
    cardsToColumns: [],
    exportCards: [],
    allCategoriesChecked: false,
    showMembersNames: false,    
    hideAllCards: false,       
    points: 0,
    showLabels: false,
};
