import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as SocketIOActions from './actions';
import { WorkshopActivityStoreActions } from '../activity-store';

const socketIOReducer = createReducer(
    initialState,
    on(SocketIOActions.connected, (state, { socketId, connected }) => ({
        ...state,
        socketId: connected ? socketId : state.socketId,
        connected,
    })),
    on(SocketIOActions.pong, (state, { payload }) => ({
        ...state,
        pong: payload,
    })),
    on(SocketIOActions.joinRoom, state => ({
        ...state,
        joined: true,
    })),
    on(SocketIOActions.leaveRoom, state => ({
        ...state,
        joined: false,
        users: [],
    })),
    on(SocketIOActions.updateUsers, (state, { payload }) => ({
        ...state,
        users: payload,
    })),
    on(SocketIOActions.sessionLoaded, (state, { session }) => ({
        ...state,
        session: session,
    })),
    on(WorkshopActivityStoreActions.joinActivity, WorkshopActivityStoreActions.updateCurrentActivity, (state, { activity }) => ({
        ...state,
        contentId: activity.contentId
    })),
);

export function reducer(state: State | undefined, action: Action): any {
    return socketIOReducer(state, action);
}
