import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioGroup } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { WorkshopActivitiesStoreSelectors } from 'app/root-store/workshop-store/activities-store';
import { Activity } from '@bsuccess/models/activities/activity.model';
import { locale as english } from '../../../../../i18n/workshop/activities/animator/dialogs/new-content-cardboard/en';
import { locale as frensh } from '../../../../../i18n/workshop/activities/animator/dialogs/new-content-cardboard/fr';
import { locale as arabic } from '../../../../../i18n/workshop/activities/animator/dialogs/new-content-cardboard/ar';
import { ServicesService } from '@bsuccess/services/services.service';

@Component({
    selector: 'app-new-content-cardboard',
    templateUrl: './new-content-cardboard.component.html',
    styleUrls: ['./new-content-cardboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class NewContentCardboardComponent implements OnInit {
    contents$: Observable<any[]>;
    showFilter: any;
    rowData = [];

    selectedServiceId: string;
    displayedColumns: string[] = ['select', /*'_id',*/ 'name'];
    dataSource: MatTableDataSource<any>;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatSort) radioGroup: MatRadioGroup;

    detailObject: Activity;
    constructor(
        public matDialogRef: MatDialogRef<NewContentCardboardComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        private _studioServicesService: ServicesService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    save(): void {
        this.detailObject = {
            ...this.detailObject,
            contentId: this.selectedServiceId
        };
    }

    ngOnInit(): void {
        this.detailObject = {
            ...this._data
        };
        this.showFilter = false;

        this.contents$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectVersions)
        );

        this.contents$.subscribe(versions => {
            this.dataSource = new MatTableDataSource(versions);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        });

    }

    applyFilter(filterValue: string): void {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    enableFilter(): void {
        if (this.showFilter === false) {
            this.showFilter = true;
        } else {
            this.showFilter = false;
        }
    }

    getSelectedStatus(rowId, selected): boolean {
        return rowId === selected;
    }

    fileChange(event: any): void {
        this._studioServicesService.fileChange(event);
    }
}
