import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(public snackBar: MatSnackBar, private _translateService: TranslateService) { }

    showSuccess(message: string): void {
        if(this._translateService.currentLang !== 'ar'){
            this.snackBar.open(message, '', {
                panelClass: ['success'],
                duration: 3000,
            });
        }else{
            this.snackBar.open(message, '', {
                panelClass: ['success-rtl'],
                duration: 3000,
            });
        }
    }

    downloadReport(message: string): void {
        if(this._translateService.currentLang !== 'ar'){
            this.snackBar.open(message, '', {
                panelClass: ['download'],
                duration: 3000,
            });
        }else{
            this.snackBar.open(message, '', {
                panelClass: ['download-rtl'],
                duration: 3000,
            });
        }
    }

    showError(message: string): void {
        if(this._translateService.currentLang !== 'ar'){
        this.snackBar.open(message, this._translateService.currentLang === 'fr' ? 'Fermer' : 
        this._translateService.currentLang === 'en' ? 'Close' : 'أغلق', {
            panelClass: ['error'],
            duration: 3000,
            });
        }else{
            this.snackBar.open(message, 'أغلق', {
            panelClass: ['error-rtl'],
            duration: 3000,
            });
        }
    }

    showRightError(message: string): void {
        if(this._translateService.currentLang !== 'ar'){
            this.snackBar.dismiss();
            this.snackBar.open(message, this._translateService.currentLang === 'fr' ? 'Fermer' : 
            this._translateService.currentLang === 'en' ? 'Close' : 'أغلق', {
                panelClass: ['error'],
                horizontalPosition: 'end',
                verticalPosition: 'bottom'
            });
        }else{
            this.snackBar.dismiss();
            this.snackBar.open(message, 'أغلق', {
                panelClass: ['error-rtl'],
                horizontalPosition: 'end',
                verticalPosition: 'bottom'
            });
        }
    }

    showRightSuccess(message: string): void {
        if(this._translateService.currentLang !== 'ar'){
            this.snackBar.dismiss();
            this.snackBar.open(message, this._translateService.currentLang === 'fr' ? 'Fermer' : 
            this._translateService.currentLang === 'en' ? 'Close' : 'أغلق', {
                panelClass: ['success-100'],
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom'
            });
        }else{
            this.snackBar.dismiss();
            this.snackBar.open(message, 'أغلق', {
                panelClass: ['success-100-rtl'],
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom'
            });
        }
    }
}
