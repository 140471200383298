import { toggleBackgroundPanel } from './../../../root-store/studio-store/board-store/actions';
import { StudioBoardStoreActions } from 'app/root-store/studio-store/board-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'app/root-store';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import {
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';

import { locale as english } from '../../../i18n/layout/components/background-panel/en';
import { locale as frensh } from '../../../i18n/layout/components/background-panel/fr';
import { locale as arabic } from '../../../i18n/layout/components/background-panel/ar';

@Component({
    selector: 'background-panel',
    templateUrl: './background-panel.component.html',
    styleUrls: ['./background-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BackgroundPanelComponent implements OnInit {

    backgrounds: any = [
        "https://i.ibb.co/XCL9Q0y/background1.jpg",
        "assets/images/backgrounds/background1.jpg",
        "assets/images/backgrounds/background2.jpg",
        "assets/images/backgrounds/background3.jpg",
        "assets/images/backgrounds/background4.jpg",
        "assets/images/backgrounds/background5.jpg",
        "assets/images/backgrounds/background6.jpg",
        "assets/images/backgrounds/background7.jpg",
        "assets/images/backgrounds/background8.jpg",
        "assets/images/backgrounds/background9.jpg",
        "assets/images/backgrounds/background10.jpg",
        "assets/images/backgrounds/background11.jpg",
        "assets/images/backgrounds/background12.jpg",
        "https://i.ibb.co/rd6svtn/background13.jpg",
        "https://i.ibb.co/y5NnBpV/background14.jpg",
        "https://i.ibb.co/zPcK6XC/background15.jpg",
    ];

    constructor(
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService
    ) { this.translationLoaderService.loadTranslations(english, frensh, arabic); }

    ngOnInit(): void {

    }

    addBackground(background: string): void {
        this._store.dispatch(StudioBoardStoreActions.updateBackground({
            background
        }))
    }

    toggleBackgroundPanel(): void {
        this._store.dispatch(StudioBoardStoreActions.toggleBackgroundPanel());
    }
}
