import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { UserModel } from '@bsuccess/models/user.model';

export const selectAccessState = createFeatureSelector<State>('access');

export const selecKey = createSelector(
    selectAccessState,
    (state: State): string => state.key
);

export const selecPending = createSelector(
    selectAccessState,
    (state: State): boolean => state.pending
);

export const selectError = createSelector(
    selectAccessState,
    (state: State): any => state.error
);

export const selectForm = createSelector(
    selectAccessState,
    (state: State): string => state.form
);

export const selecTenant = createSelector(
    selectAccessState,
    (state: State): string => state.tenant
);

export const selectUser = createSelector(
    selectAccessState,
    (state: State): UserModel => state.user
);

export const selectSessionKey = createSelector(
    selectAccessState,
    (state: State): string => state.sessionKey
);

export const selectObject = createSelector(
    selectAccessState,
    (state: State): string => state.object
);

export const selectObjectId = createSelector(
    selectAccessState,
    (state: State): string => state.objectId
);

export const selectPendingSignUp = createSelector(
    selectAccessState,
    (state: State): boolean => state.pendingSignUp
);

export const selectParam = createSelector(
    selectAccessState,
    (state: State): string => state.param
);

export const selectParentId = createSelector(
    selectAccessState,
    (state: State): string => state.parentId
);

export const selectWelcomeType = createSelector(
    selectAccessState,
    (state: State): string => state.welcomeType
);

export const selectProfilePic = createSelector(
    selectAccessState,
    (state: State): string => state.profilePic
);

export const selectPlans = createSelector(
    selectAccessState,
    (state: State): any[] => state.plans.filter(plan => plan.metadata.planType === 'monthPlan').reverse()
);

export const selectYearlyPlans = createSelector(
    selectAccessState,
    (state: State): any[] => state.plans.filter(plan => plan.metadata.planType === 'yearPlan')
);

export const selectAddons = createSelector(
    selectAccessState,
    (state: State): any[] => state.plans.filter(plan => plan.metadata.planType === 'addon')
);

export const selectSelectedPlan = createSelector(
    selectAccessState,
    (state: State): any => state.selectedPlan
);

export const selectUserPaymentMethods = createSelector(
    selectAccessState,
    (state: State): any => state.userPaymentMethods
);

export const selectPaymentDone = createSelector(
    selectAccessState,
    (state: State): boolean => state.paymentDone
);

export const selectPendingPayment = createSelector(
    selectAccessState,
    (state: State): boolean => state.pendingPayment
);

export const selectPendingPaymentAddon = createSelector(
    selectAccessState,
    (state: State): boolean => state.pendingPaymentAddon
);

export const selectPaymentAddonDone = createSelector(
    selectAccessState,
    (state: State): boolean => state.paymentAddonDone
);

export const selectTaxes = createSelector(
    selectAccessState,
    (state: State): any[] => state.taxes
);

export const selectUserInfo = createSelector(
    selectAccessState,
    (state: State): any => state.userInfo
);

export const selectSignUpError = createSelector(
    selectAccessState,
    (state: State): string => state.signUpError
);
