export const locale = {
    lang: 'en',
    data: {
        PROJECTS_DIALOGS_UPDATE_EDIT_DETAILS: 'Project Settings',
        PROJECTS_DIALOGS_UPDATE_PROJECT_NAME: 'Project name',
        PROJECTS_DIALOGS_UPDATE_START_DATE: 'Start date',
        PROJECTS_DIALOGS_UPDATE_END_DATE: 'End date',
        PROJECTS_DIALOGS_UPDATE_ADD_IMAGE: 'Add an image',
        PROJECTS_DIALOGS_UPDATE_ARCHIVE: 'Delete',
        PROJECTS_DIALOGS_UPDATE_SAVE: 'Save',
        PROJECTS_DIALOGS_UPDATE_CANCEL: 'Cancel',
        VALIDATORS_START_DATE_REQUIRED: 'The start date is obligatory.' ,
        VALIDATORS_END_DATE_REQUIRED: 'The end date is obligatory.' ,  
        VALIDATORS_END_DATE_BEFORE: 'Start date is greater than end date' ,
        VALIDATORS_INVALID_START_TIME: 'The start time is invalid' ,
        VALIDATORS_INVALID_END_TIME: 'The end time is invalid' ,  
        VALIDATORS_END_TIME_BEFORE: 'Start time is greater than end time' , 
    }
};
