import { DimensionModel } from '@bsuccess/models/cardboard/dimension.model';
import { ChapterModel } from '@bsuccess/models/cardboard/chapiter.model';
import { ItemModel } from '@bsuccess/models/cardboard/item.model';
import { ItemArticleModel } from '@bsuccess/models/cardboard/item-article.model';
import { ResultGlobalChartModel } from '@bsuccess/models/cardboard/result-global-chart.model';

export interface State {
    dimensions: DimensionModel[];
    dimension: DimensionModel;
    chapters: ChapterModel[];
    chapter: ChapterModel;
    items?: ItemModel[];
    item?: ItemModel;
    articles: ItemArticleModel[];
    pending?: boolean;
    pendingReport?: boolean;
    error?: string;
    selectedArticle: string;
    globalChart: ResultGlobalChartModel;
    ecartType: boolean;
}

export const initialState: State = {
    dimensions: [],
    dimension: {
        _id: '1',
        rank: 1,
        title: 'Dimension',
        description: '',
        chapters: [],
        avg: 0,
    },
    chapters: [],
    chapter: {
        _id: '1',
        rank: 1,
        title: 'Chapitre',
        description: '',
        hasItems: false,
        result: {
            avg: 0,
        },
    },
    articles: [
        {
            _id: '1',
            rank: 1,
            title: '',
            description: '',
        },
        {
            _id: '2',
            rank: 2,
            title: '',
            description: '',
        },
        {
            _id: '3',
            rank: 3,
            title: '',
            description: '',
        },
        {
            _id: '4',
            rank: 4,
            title: '',
            description: '',
        },
        {
            _id: '5',
            rank: 5,
            title: '',
            description: '',
        },
    ],
    globalChart: null,
    selectedArticle: null,
    ecartType: false
};
