import { createAction, props } from '@ngrx/store';

import { NoteModel } from '@bsuccess/models/project/note.model';
import { UserModel } from '@bsuccess/models/user.model';

export const loadNotes = createAction('[Workshop][Notes Page] Load Notes');

export const loadNotesSuccess = createAction(
    '[Workshop][Notes API] Load Notes Success',
    props<{
        notes: NoteModel[];
    }>()
);

export const loadNotesFailure = createAction(
    '[Workshop][Notes API] Load Notes Failure',
    props<{ error: string }>()
);

export const addNote = createAction('[Workshop][Notes Page] Add Note');

export const addNoteConfirmed = createAction(
    '[Workshop][Notes Dialog] Add Note Confirmed',
    props<{ note: NoteModel }>()
);

export const addNoteCanceled = createAction(
    '[Workshop][Notes Dialog] Add Note Canceled'
);

export const addNoteSuccess = createAction(
    '[Workshop][Notes API] Add Note Success',
    props<{ note: NoteModel }>()
);

export const addNoteFailure = createAction(
    '[Workshop][Notes API] Add Note Failure',
    props<{ error: string }>()
);

export const updateNote = createAction(
    '[Workshop][Notes Page] Update Note',
    props<{ note: NoteModel }>()
);

export const updateNoteConfirmed = createAction(
    '[Workshop][Notes Dialog] Update Note Confirmed',
    props<{ note: NoteModel }>()
);

export const updateNoteCanceled = createAction(
    '[Workshop][Notes Dialog] Update Note Canceled'
);

export const updateNoteSuccess = createAction(
    '[Workshop][Notes API] Update Note Success',
    props<{ note: NoteModel }>()
);

export const updateNoteFailure = createAction(
    '[Workshop][Notes API] Update Note Failure',
    props<{ error: string }>()
);

export const getNoteUser = createAction(
    '[Workshop][Notes Page] Get Note User',
    props<{ noteId: string, userId: string }>()
);

export const updateNoteUser = createAction(
    '[Workshop][Notes Page] Update Note User',
    props<{ noteId: string, user: UserModel }>()
);

export const deleteNote = createAction(
    '[Workshop][Notes Page] Delete Note',
    props<{ id: string }>()
);

export const deleteNoteConfirmed = createAction(
    '[Workshop][Notes Delete Dialog] Delete Note Confirmed',
    props<{ id: string }>()
);

export const deleteNoteCanceled = createAction(
    '[Workshop][Notes Delete Dialog] Delete Note Canceled'
);

export const deleteNoteSuccess = createAction(
    '[Workshop][Notes API] Delete Note Success',
    props<{ id: string }>()
);

export const deleteNoteFailure = createAction(
    '[Workshop][Notes API] Delete Note Failure',
    props<{ error: string }>()
);

export const downloadReport = createAction('[Workshop][Notes Page] Download Report');

export const downloadReportSuccess = createAction(
    '[Workshop][Notes API] Download Report Success',
    props<{
        report: Blob;
    }>()
);

export const downloadReportFailure = createAction(
    '[Workshop][Notes API] Download Report Failure',
    props<{ error: string }>()
);
