import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { StudioNotificationsStoreEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('studio-notifications', reducer),
    EffectsModule.forFeature([StudioNotificationsStoreEffects]),
  ],
  providers: [],
})
export class StudioNotificationsStoreModule { }
