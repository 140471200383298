export const locale = {
    lang: 'fr',
    data: {
        COMPONENTS_PROJECTS_PANEL_MY_PROJECTS:'MES PROJETS',
        COMPONENTS_PROJECTS_PANEL_ADD_PROJECT:'Nouveau projet',
        COMPONENTS_PROJECTS_PANEL_SEE_ALL:'Voir tous mes projets',
        COMPONENTS_PROJECTS_PANEL_ADD_SUB_PROJECTS:'Ajouter un sous-projet',
        COMPONENTS_PROJECTS_PANEL_NOT_AUTHORIZED:'Vous n\'avez pas la permission d\'accéder à ce projet',
    }
};
