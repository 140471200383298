import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';

export const selectProjectsState = createFeatureSelector<State>('studio-projects');

export const selectFavorites = createSelector(
    selectProjectsState,
    (state: State): SubProjectModel[] => state.favorites
);

export const selectFilteredFavorites = createSelector(
    selectProjectsState,
    (state: State): SubProjectModel[] => state.filteredFavorites
);

export const selectFilteredOthers = createSelector(
    selectProjectsState,
    (state: State): SubProjectModel[] => state.filteredOthers
);

export const selectOthers = createSelector(
    selectProjectsState,
    (state: State): SubProjectModel[] => state.others
);

export const selectAllProjects = createSelector(
    selectProjectsState,
    (state: State): SubProjectModel[] => [...state.others, ...state.favorites]
);

export const selectProjectsTree = createSelector(
    selectProjectsState,
    (state: State): any[] => state.projectsTree
);

export const selectDailySammaries = createSelector(
    selectProjectsState,
    (state: State): any[] => state.dailySummaries
);

export const selectWeeklySammaries = createSelector(
    selectProjectsState,
    (state: State): any[] => state.weeklySummaries
);

export const selectSummaries = createSelector(
    selectProjectsState,
    (state: State): any => state.summaries
);