import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Socket } from 'ngx-socket-io';
import { withLatestFrom, map } from 'rxjs/operators';

import * as SocketIOActions from '../actions';
import { Card } from '@bsuccess/models/canvas/card.model';
import { UserModel } from '@bsuccess/models/user.model';
import { RootStoreState } from 'app/root-store';
import { WorkshopCanvasStoreActions, WorkshopCanvasStoreSelectors } from 'app/root-store/workshop-store/canvas-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { WorkshopCardRatingStoreActions } from 'app/root-store/workshop-store/cardrating-store';
import { WorkshopTimerStoreSelectors } from '../../timer-store';
import { WorkshopSocketIOStoreSelectors } from '..';

@Injectable()
export class WorkshopSocketIOStoreCanvasEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _socket: Socket
    ) { }

    // Catch all animator canvas success actions and emit them
    animatorCanvas$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.loadBoardSuccess,
                    WorkshopCanvasStoreActions.renameBoard,
                    WorkshopCanvasStoreActions.removeCardSuccess,
                    WorkshopCanvasStoreActions.moveCardSuccess,
                    WorkshopCanvasStoreActions.addListSuccess,
                    WorkshopCanvasStoreActions.removeListSuccess,
                    WorkshopCanvasStoreActions.moveListSuccess,
                    WorkshopCanvasStoreActions.cardColorChangeSuccess,
                    WorkshopCanvasStoreActions.cardNameChangeSuccess,
                    WorkshopCanvasStoreActions.listNameChangeSuccess,
                    WorkshopCanvasStoreActions.duplicateCardSuccess,
                    WorkshopCanvasStoreActions.updateLbelsSuccess,
                    WorkshopCanvasStoreActions.toggleLabelSuccess,
                    WorkshopCanvasStoreActions.refreshBoard,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopTimerStoreSelectors.selectTimerState
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            LoginStoreSelectors.selectSessionCategories
                        )
                    )
                ),
                map(([[[[_, [sessionRole, sessionKey]], board], timer], categories]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateRoom({
                                payload: {
                                    sessionKey,
                                    route: '/workshop/canvas' + '/' + board.id,
                                    state: board,
                                    contentId: board.id,
                                    categories
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    addCard$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.addCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopTimerStoreSelectors.selectTimerState
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopSocketIOStoreSelectors.selectContentId)
                    )
                ),
                map(([[[[_, [sessionRole, sessionKey]], board], timer], contentId]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.addCard({
                                payload: {
                                    contentId,
                                    sessionKey,
                                    id: board.id,
                                    card: _.card,
                                    listId: _.listId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorReceiveCanvasRates$ = this._socket
        .fromEvent<{ sessionKey: string; cards: Card[]; user: UserModel }>(
            SocketIOActions.sendCanvasRates.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([payload, role]: [any, string]) => {
            if (role === 'animator') {
                this._store.dispatch(
                    WorkshopCardRatingStoreActions.receiveAllCanvasRates({
                        payload,
                    })
                );
            }
        });

    animatorReceiveCanvasCard$ = this._socket
        .fromEvent<{ card: Card; listId: string }>(
            SocketIOActions.sendCanvasCard.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([payload, sessionRole]) => {
            if (sessionRole === 'animator') {
                this._store.dispatch(
                    WorkshopCanvasStoreActions.addCard({
                        listId: payload.listId,
                        card: payload.card,
                    })
                );
            }
        });

    animatorUpdateBalance$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.updateBalance),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopSocketIOStoreSelectors.selectContentId)
                    )
                ),
                map(([[action, [sessionRole, sessionKey]], contentId]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateCanvasBalance({
                                payload: {
                                    sessionKey,
                                    balance: action.balance,
                                    contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorUpdateMaxPerCard$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.updateMaxPerCard),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(WorkshopSocketIOStoreSelectors.selectContentId)
                    )
                ),
                map(([[action, [sessionRole, sessionKey]], contentId]) => {
                    if (sessionRole === 'animator') {
                        this._store.dispatch(
                            SocketIOActions.updateCanvasMaxPerCard({
                                payload: {
                                    sessionKey,
                                    max: action.max,
                                    contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    // Import Board Actions
    canvasCardsToBoardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.canvasCardsToBoardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.importCardsToBoardSuccess({
                            payload: {
                                sessionKey,
                                contentId: board.id,
                                data: {
                                    response: _.response,
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    canvasCardsToBoardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasCardsToBoardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.canvasCardsToBoardViaSocket({
                    response: _.response,
                    listId: _.listId
            })
        );
    });


    participantUpdateBalance$ = this._socket
        .fromEvent<{ sessionKey: string; balance: number }>(
            SocketIOActions.updateCanvasBalance.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([cardrating, sessionRole]) => {
            if (sessionRole === 'participant') {
                this._store.dispatch(
                    WorkshopCardRatingStoreActions.updateBalance({
                        balance: cardrating.balance,
                    })
                );
            }
        });

    participantUpdateMaxPerCard$ = this._socket
        .fromEvent<{ sessionKey: string; max: number }>(
            SocketIOActions.updateCanvasMaxPerCard.type
        )
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([cardrating, sessionRole]) => {
            if (sessionRole === 'participant') {
                this._store.dispatch(
                    WorkshopCardRatingStoreActions.updateMaxPerCard({
                        max: cardrating.max,
                    })
                );
            }
        });

    emit$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    SocketIOActions.sendCanvasCard,
                    SocketIOActions.sendCanvasRates,
                    SocketIOActions.updateCanvasBalance,
                    SocketIOActions.updateCanvasMaxPerCard,
                ),
                map(action => {
                    this._socket.emit(action.type, action.payload);
                })
            ),
        { dispatch: false }
    );

    // Add Card
    addCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.addCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasAddCardSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    card: _.card,
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasAddCardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketAddCard({
                    card: _.card,
                    listId: _.listId
                })
            );
        });

    // Remove Card
    removeCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.removeCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasRemoveCardSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    listId: _.listId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasRemoveCardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketRemoveCard({
                    listId: _.listId,
                    cardId: _.cardId
                })
            );
        });

    // Move Card
    moveCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.moveCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasMoveCardSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    move: _.move,
                                    lists: _.lists
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    moveCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasMoveCardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketMoveCard({
                    lists: _.lists,
                    move: _.move
                })
            );
        });


    // List Name Change
    listNameChangeSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.listNameChangeSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasListNameChangeSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    listId: _.listId,
                                    name: _.name
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    listNameChangeSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasListNameChangeSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketListNameChange({
                    listId: _.listId,
                    name: _.name
                })
            );
        });

    // Card Name Change
    cardNameChangeSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.cardNameChangeSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasCardNameChangeSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    name: _.name
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    cardNameChangeSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasCardNameChangeSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketCardNameChangeSuccess({
                    name: _.name,
                    cardId: _.cardId
                })
            );
        });

    // Card Color Change
    cardColorChangeSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.cardColorChangeSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasCardColorChangeSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    color: _.color,
                                    typeCard: _.typeCard
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    cardColorChangeSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasCardColorChangeSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketCardColorChange({
                    color: _.color,
                    typeCard: _.typeCard,
                    cardId: _.cardId
                })
            );
        });

    // Card Description Change
    updateCardDescriptionSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.updateCardDescriptionSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasUpdateCardDescriptionSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    description: _.description
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateCardDescriptionSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasUpdateCardDescriptionSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketUpdateCardDescription({
                    description: _.description,
                    cardId: _.cardId
                })
            );
        });

    // Card Date Change
    updateCardDateSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.updateCardDateSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasUpdateCardDateSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    due: _.due
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateCardDateSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasUpdateCardDateSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketUpdateCardDate({
                    due: _.due,
                    cardId: _.cardId
                })
            );
        });

    // Card Add Comment
    addCardCommentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.addCardCommentSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasAddCardCommentSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    comment: _.comment
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addCardCommentSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasAddCardCommentSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketAddCardComment({
                    cardId: _.cardId,
                    comment: _.comment
                })
            );
        });

    // Card Delete Comment
    deleteCommentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.deleteCommentSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasDeleteCommentSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    commentId: _.commentId,
                                    id: _.id
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    deleteCommentSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasDeleteCommentSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketDeleteComment({
                    cardId: _.cardId,
                    commentId: _.commentId,
                    id: _.id
                })
            );
        });

    // Card Add CheckList
    addChecklistSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.addChecklistSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasAddChecklistSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    cardId: _.cardId,
                                    checklist: _.checklist
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addChecklistSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasAddChecklistSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketAddChecklist({
                    cardId: _.cardId,
                    checklist: _.checklist
                })
            );
        });

    // Card Add CheckItem
    addcheckItemSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.addcheckItemSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasAddcheckItemSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    checkItemForm: _.checkItemForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addcheckItemSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasAddcheckItemSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketAddcheckItem({
                    checkItemForm: _.checkItemForm
                })
            );
        });

    // Card Remove CheckList
    removeCheckListSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.removeCheckListSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasRemoveCheckListSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    checkListForm: _.checkListForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeCheckListSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasRemoveCheckListSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketRemoveCheckList({
                    checkListForm: _.checkListForm
                })
            );
        });

    // Card Remove CheckItem
    removeCheckItemSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.removeCheckItemSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasRemoveCheckItemSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    checkListForm: _.checkListForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeCheckItemSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasRemoveCheckItemSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketRemoveCheckItem({
                    checkListForm: _.checkListForm
                })
            );
        });

    // Card Update CheckItem
    updateCheckItemSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.updateCheckItemSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasUpdateCheckItemSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    checkItemForm: _.checkItemForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateCheckItemSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasUpdateCheckItemSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketUpdateCheckItem({
                    checkItemForm: _.checkItemForm
                })
            );
        });

    // Card Toggle Label
    toggleLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.toggleLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasToggleLabelSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    toggleForm: _.toggleForm
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    toggleLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasToggleLabelSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketToggleLabel({
                    toggleForm: _.toggleForm
                })
            );
        });


    // Card Remove Label
    removeLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.removeLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasRemoveLabelSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    labelId: _.labelId,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removeLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasRemoveLabelSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketRemoveLabel({
                    labelId: _.labelId,
                })
            );
        });

    // Card Add Label
    addLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.addLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasAddLabelSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    id: _.id,
                                    name: _.name,
                                    color: _.color,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasAddLabelSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketAddLabel({
                    color: _.color,
                    name: _.name,
                    id: _.id
                })
            );
        });

    // Card Update Label
    updateLabelSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.updateLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasUpdateLabelSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    color: _.color,
                                    name: _.name,
                                    id: _.id
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateLabelSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasUpdateLabelSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketUpdateLabel({
                    color: _.color,
                    name: _.name,
                    id: _.id
                })
            );
        });

    // Card Rating 
    cardRatingSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.cardRatingSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasCardRatingSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    rate: _.rate,
                                    user: _.user,
                                    cardId: _.cardId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    cardRatingSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasCardRatingSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketcardRating({
                    rate: _.rate,
                    user: _.user,
                    cardId: _.cardId
                })
            );
        });

    // Update Timer 
    updateTimerSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.updateTimerSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasUpdateTimerSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    timer: _.timer
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateTimerSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasUpdateTimerSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketUpdateTimer({
                    timer: _.timer
                })
            );
        });

    // Duplicate Card
    duplicateCardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.duplicateCardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasDuplicateCardSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    duplicate: _.duplicate,
                                    card: _.card
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    duplicateCardSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasDuplicateCardSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketDuplicateCard({
                    card: _.card,
                    duplicate: _.duplicate
                })
            );
        });

    // Sort Board
    sortSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.sortSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasSortSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    lists: _.lists
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    sortSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasSortSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.socketSort({
                    lists: _.lists
                })
            );
        });

    // Update WorkFlowStep
    updateWorkFlowStepSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.updateWorkFlowStepSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.updateWorkFlowStepSuccess({
                            payload: {
                                contentId: board.id,
                                sessionKey,
                                data: {
                                    step: _.step,
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    updateWorkFlowStepSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.updateWorkFlowStepSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
        )
        .subscribe(([[_, sessionRole], board]) => {
            if (board) {
                if (board.id === _.boardId) {
                    this._store.dispatch(
                        WorkshopCanvasStoreActions.socketUpdateWorkFlowStep({
                            step: _.step,
                        })
                    );
                }
            }
        });


    updateDisplayLabels$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.updateDisplayLabelSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasShowLabelsSuccess({
                            payload: {
                                sessionKey,
                                contentId: board.id,
                                data: {
                                    enabled: _.label
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    showLabelsSuccessSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasShowLabelsSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.updateDisplayLabelViaSocket({
                    label: _.enabled
                })
            );
        });


    toggleShowMembersName$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.toggleShowMembersNameSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasShowMembersSuccess({
                            payload: {
                                sessionKey,
                                contentId: board.id,
                                data: {
                                    enabled: _.enabled
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    showMembersSuccess$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasShowMembersSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.toggleShowMembersNameViaSocket({
                    enabled: _.enabled
                })
            );
        });

    toggleShowAllCards$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopCanvasStoreActions.toggleShowAllCardsSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], board]) => {
                    this._store.dispatch(
                        SocketIOActions.canvasShowAllCardsSuccess({
                            payload: {
                                sessionKey,
                                contentId: board.id,
                                data: {
                                    enabled: _.enabled
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    showAllCardsSuccess$ = this._socket
        .fromEvent<any>(SocketIOActions.canvasShowAllCardsSuccess.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            ),
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopCanvasStoreActions.toggleShowAllCardsViaSocket({
                    enabled: _.enabled
                })
            );
    });

}
