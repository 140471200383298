import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AccessStoreActions } from 'app/root-store/access-store';
import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash-es';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseNavigation } from '@fuse/types';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { UserModel } from '@bsuccess/models/user.model';
import { SessionModel } from './../../../../../../@bsuccess/models/session/session.model';
import {
    RootStoreState
} from 'app/root-store';
import { WorkshopSocketIOStoreSelectors } from 'app/root-store/workshop-store/socket-io-store';
import { LoginStoreSelectors, LoginStoreActions } from 'app/root-store/login-store';
import { StudioSessionStoreSelectors } from 'app/root-store/studio-store/session-store';
import { NavbarStoreSelectors, NavbarStoreActions } from 'app/root-store/navbar-store';
import { ActionName } from '@bsuccess/models/action-permission.model';

import { locale as english } from '../../../../../i18n/layout/components/navbar/vertical/style-1/en';
import { locale as frensh } from '../../../../../i18n/layout/components/navbar/vertical/style-1/fr';
import { locale as arabic } from '../../../../../i18n/layout/components/navbar/vertical/style-1/ar';

import { TranslateService } from '@ngx-translate/core';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { StudioProjectStoreActions, StudioProjectStoreSelectors } from 'app/root-store/studio-store/project-store';
import { environment } from 'environments/environment';


@Component({
    selector: 'navbar-vertical-style-1',
    templateUrl: './style-1.component.html',
    styleUrls: ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    imagesUrl = '';
    languages: any;
    selectedLanguage: any;
    socketIOPong$: Observable<any>;
    socketIOConnect$: Observable<boolean>;
    sessionToken$: Observable<string>;
    sessionToken: string;
    session$: Observable<SessionModel>;
    user$: Observable<UserModel>;
    user: UserModel;
    navigation$: Observable<FuseNavigation[]>;
    product$: Observable<string>;
    currentProject$: Observable<ProjectModel>;

    ActionName = ActionName;
    fuseConfig: any;
    navigation: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private _store: Store<RootStoreState.State>,
        private _matDialog: MatDialog,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        private _dateAdapter: DateAdapter<Date>,
        // To Refactor
        private httpClient: HttpClient
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'fr',
                title: 'Français',
                flag: 'fr',
            },
            {
                id: 'ar',
                title: 'العربية',
                flag: 'ar',
            },
        ];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fusePerfectScrollbar.scrollToElement(
                        'navbar .nav-link.active',
                        -120
                    );
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // loadStripe('pk_test_51HMC2aIASQYBUqwRN1ay7HfZ442mV0ZaP4wTu3KnsWCLTBt8yxnd2Vd1Z4FUyaaoNFpAbUeZcY6QJr0Y8sk2OlEv00N2KtvJmP').then(stripe => {
        //     this.stripe = stripe;
        // });
        this.socketIOConnect$ = this._store.pipe(
            select(WorkshopSocketIOStoreSelectors.selectSocketIOConnected)
        );
        this.socketIOPong$ = this._store.pipe(
            select(WorkshopSocketIOStoreSelectors.selectSocketIOPong)
        );
        this.sessionToken$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoginSessionKey)
        );
        this.session$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectCurrent)
        );
        this.navigation$ = this._store.pipe(
            select(NavbarStoreSelectors.selectNavigation)
        );
        this.user$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoggedUser)
        );
        this.product$ = this._store.pipe(
            select(NavbarStoreSelectors.selectProduct)
        );

        this.selectedLanguage = _.find(this.languages, {
            id: this.translateService.currentLang,
        });

        this.currentProject$ = this._store.pipe(
            select(StudioProjectStoreSelectors.selectCurrent)
        );

        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar('navbar')) {
                    this._fuseSidebarService.getSidebar('navbar').close();
                }
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.fuseConfig = config;
            });


        this.sessionToken$.subscribe(token => {
            this.sessionToken = token;
        });

        this.user$.subscribe(user => {
            this.user = user;
            if (user) {
                this.selectedLanguage = _.find(this.languages, {
                    id: user.lang,
                });
                if (this.selectedLanguage) {
                    this.setLanguage(this.selectedLanguage);
                }
            }
        });
        this.imagesUrl = environment.minio.url + '/storage/o/public/';
    }

    setLanguage(lang: any): void {
        // Set the selected language for the toolbar

        this.selectedLanguage = lang;

        // Use the selected language for translations
        this.translateService.use(lang.id);

        this._store.dispatch(NavbarStoreActions.updateLang({
            lang: lang.id
        }));

        this.selectedLanguage === 'ar' ?
            this._dateAdapter.setLocale('ar-MA') :
            this._dateAdapter.setLocale(lang.id);

    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }

    /**
     * Get Sidebar Folded state
     */
    getSidebarState(): boolean {
        return this._fuseSidebarService.getSidebar('navbar').folded;
    }

    signOut(): void {
        this._store.dispatch(NavbarStoreActions.logout());
    }

    leaveWorkshop(): void {
        this._store.dispatch(NavbarStoreActions.leaveWorkshop());
    }

    loadSession(): void {
        this._store.dispatch(LoginStoreActions.loadSession({
            sessionKey: this.sessionToken
        }));
    }

    goToHome(): void {
        this._store.dispatch(NavbarStoreActions.goToHome());
    }

    getInitials(user): string {
        if (user) {
            if (user.firstName && user.lastName) {
                return `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`;
            } else {
                return 'NA';
            }
        } else {
            return 'NA';
        }
    }

    setUpPayment(): void {
        // this.stripe.redirectToCheckout({
        //     lineItems: [{ price: 'price_1HMWM6IASQYBUqwRJsVZNbI8', quantity: 1 }],
        //     mode: 'subscription',
        //     // Do not rely on the redirect to the successUrl for fulfilling
        //     // purchases, customers may not always reach the success_url after
        //     // a successful payment.
        //     // Instead use one of the strategies described in
        //     // https://stripe.com/docs/payments/checkout/fulfillment
        //     successUrl: window.location.protocol + '//excelway.co/success',
        //     cancelUrl: window.location.protocol + '//excelway.co/canceled',
        // })
        //     .then(function (result) {
        //         if (result.error) {
        //             // If `redirectToCheckout` fails due to a browser or network
        //             // error, display the localized error message to your customer.
        //             const displayError = document.getElementById('error-message');
        //             displayError.textContent = result.error.message;
        //         }
        //     });
    }

    toggleProjectsOpened(): void {
        this._fuseSidebarService.getSidebar('projectsPanel').toggleOpen();
    }

    hideNavBar(): void {
        this._fuseConfigService.setConfig({
            ...this.fuseConfig,
            layout: {
                ...this.fuseConfig.layout,
                navbar: {
                    ...this.fuseConfig.layout.navbar,
                    hidden: true
                }
            }
        })
    }

    updateProjectMembers(): void {
        this._store.dispatch(StudioProjectStoreActions.projectShowUsersDialog());
    }

    updateProjectDetails(): void {
        this._store.dispatch(StudioProjectStoreActions.showProjectGeneralDialog());
    }

    updateProjectLevels(): void {
        this._store.dispatch(StudioProjectStoreActions.showLevelsDialog());
    }

    archiveProject(): void {
        this._store.dispatch(StudioProjectStoreActions.archiveProject());
    }

    showPayment(): void {
        this._store.dispatch(AccessStoreActions.showPaymentDialog());
    }

    showPricing(): void {
        const payload = new HttpParams()
        .set('customer', this.user.customer.id)
        .set('return_url', 'https://example.com/account');
        const headers_object = new HttpHeaders();
        this.httpClient.post('https://api.stripe.com/v1/billing_portal/sessions', payload).subscribe(result => {
            console.log(result);
        })
        this._store.dispatch(AccessStoreActions.showPricingDialog());
    }

    showAddOnsModal(): void {
        this._store.dispatch(AccessStoreActions.showAddOnsDialog());
    }
}
