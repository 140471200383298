import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'board-update-general-dialog',
    templateUrl: './board-update-general-dialog.component.html',
    styleUrls: ['./board-update-general-dialog.component.scss'],
})
export class BoardUpdateGeneralDialogComponent implements OnInit {
    error$: Observable<string>;

    constructor(
        public translateService: TranslateService,
        public dialogRef: MatDialogRef<BoardUpdateGeneralDialogComponent>,
    ) { }

    ngOnInit(): void { }
}
