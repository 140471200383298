export const locale = {
    lang: 'en',
    data: {
        CATEGORIES: 'Colors',
        ADD: 'Add',
        EDIT_CATEGORY: 'Edit color',
        DELETE_CATEGORY: 'Delete color',
        ADD_CATEGORY: 'Add color',
        ADD_U: 'ADD',
        NAME: 'Name',
    }
};
