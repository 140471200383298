export const locale = {
    lang: 'fr',
    data: {
        EDIT: 'Modifier les informations',
        ADDRESS: 'Adresse',
        PHONE: 'Tél.',
        WEBSITE: 'Site Internet',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
    }
};
