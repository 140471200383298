import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './reducer';
import { WorkshopSocketIOStoreCommonEffects } from './effects/common-effects';
import { WorkshopSocketIOStoreTimerEffects } from './effects/timer-effects';
import { WorkshopSocketIOStoreBrainstormingEffects } from './effects/brainstorming-effects';
import { WorkshopSocketIOStoreCardboardEffects } from './effects/cardboard-effects';
import { WorkshopSocketIOStoreCanvasEffects } from './effects/canvas-effects';
import { StudioSocketIOStoreBoardEffects } from './effects/board-effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('workshop-socketIO', reducer),
        EffectsModule.forFeature([
            WorkshopSocketIOStoreCommonEffects,
            WorkshopSocketIOStoreTimerEffects,
            WorkshopSocketIOStoreBrainstormingEffects,
            WorkshopSocketIOStoreCanvasEffects,
            WorkshopSocketIOStoreCardboardEffects,
            StudioSocketIOStoreBoardEffects,
        ]),
    ],
    providers: [],
})
export class WorkshopSocketIOStoreModule { }
