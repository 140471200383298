import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { NoteModel } from '@bsuccess/models/project/note.model';

export const selectNotesState = createFeatureSelector<State>('workshop-notes');

export const selectNotes = createSelector(
  selectNotesState,
  (state: State): NoteModel[] => state.notes
);

export const selectCurrentNote = createSelector(
  selectNotesState,
  (state: State): NoteModel => state.currentNote
);

export const selectPending = createSelector(
  selectNotesState,
  (state: State): boolean => state.pending
);
