import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/en';
import { locale as frensh } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/fr';
import { locale as arabic } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/ar';

@Component({
    selector: 'board-import-cards-dialog',
    templateUrl: './import-cards-dialog.component.html',
    styleUrls: ['./import-cards-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BoardImportCardsDialogComponent {
    constructor(
        public matDialogRef: MatDialogRef<BoardImportCardsDialogComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }
}
