export const locale = {
    lang: 'en',
    data: {
        SELECT: 'Select content',
        CONTENT_ID: 'Content Id',
        CONTENT_NAME: 'Content name',
        UPLOAD: 'Upload',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        FILTER: 'Filter',
    }
};
