import { NgModule } from '@angular/core';

import { WorkshopActivitiesStoreModule } from './activities-store/activities-store.module';
import { WorkshopNotesStoreModule } from './notes-store/notes-store.module';
import { WorkshopTimerStoreModule } from './timer-store/timer-store.module';
import { WorkshopSocketIOStoreModule } from './socket-io-store/socket-io-store.module';
import { WorkshopBrainstormingStoreModule } from './brainstorming-store/brainstorming-store.module';
import { WorkshopCardboardStoreModule } from './cardboard-store/cardboard-store.module';
import { WorkshopCardRatingStoreModule } from './cardrating-store/cardrating-store.module';
import { WorkshopActivityStoreModule } from './activity-store/activity-store.module';
import { WorkshopCanvasStoreModule } from './canvas-store/canvas-store.module';

@NgModule({
    imports: [
        WorkshopActivitiesStoreModule,
        WorkshopActivityStoreModule,
        WorkshopTimerStoreModule,
        WorkshopNotesStoreModule,
        WorkshopSocketIOStoreModule,
        WorkshopBrainstormingStoreModule,
        WorkshopCardboardStoreModule,
        WorkshopCardRatingStoreModule,
        WorkshopCanvasStoreModule
    ],
})
export class WorkshopStoreModule { }
