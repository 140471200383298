export const locale = {
 lang: 'en',
 data: {
     PARTICIPANT_INFORMATIONS: 'Participant informations',
     PARTICIPANT: 'Participant',
     ANIMATOR: 'Animator',
     REMOVE_BUTTON_LABEL: 'Remove participant from this workshop',
     CLOSE: 'Close',
    }
};
