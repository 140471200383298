export const locale = {
    lang: 'en',
    data: {
        CLOSE: 'Close',
        PRIVACY_POLICY: 'Privacy policy',
        EXCELWAY_PRIVACY_POLICY: 'EXCELWAY PRIVACY POLICY',
        EFFECTIVE_DATE: 'EFFECTIVE DATE : May 3, 2020',
        // tslint:disable-next-line: max-line-length
        FIRST_PARAGRAPH: 'Excelway offers a variety of team productivity, collaboration and organization tools available online (together, the "Service"), and websites, including but not limited to www.excelway.co, meeting-designer.excelway.co, app.excelway.co (the "Websites"). When you use the Service and interact with the Websites, Excelway collects and processes personal data about you in order to provide you with access to the Service, to improve your user experience of the Service and to interact with you. This privacy policy (the "Policy") describes how Excelway collects, uses and shares the Personal Data collected through the Service and the Websites, and what choices you have regarding this data. The first section below explains which confidentiality conditions are applicable to you according to the type of user you are.',
        // tslint:disable-next-line: max-line-length
        REFERENCES_TO_EXCELWAY: 'References to "Excelway" throughout the present Policy designate the Excelway entity which acts as the person in charge of the treatment or subcontractor concerning your data, as explained in more detail below. If you do not agree with this Policy, do not access or use the Service, the Websites, or any other part of the activities of Excelway. If you have any questions concerning this Privacy Policy, please contact Excelway at : 11, rue Léon Frot, 75011, or by email at support@excelway.co.',
        TYPE_OF_USER: 'I. What type of User am I and what are the Conditions of Confidentiality that apply to me?',
        // tslint:disable-next-line: max-line-length
        EXCELWAY_DIFFERENTIATES_USERS: 'Excelway differentiates users into two different categories according to the Excelway products used. Please consult the paragraphs below to determine which type of user you are. It is possible that you can use Excelway in different ways. If so, please take note of all the applicable conditions of confidentiality. Also, do not forget to consult Section IV, which contains the confidentiality conditions that apply to all users.',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBERS: 'Subscribers. We call "Subscribers" the users who use the Service within the framework of an Excelway subscription plan, whether paid or free. The characteristics and functionalities of the Service made available to the Subscribers are determined by the particular conditions agreed between Excelway and the organization (for example, your employer or another entity or person, called the "Client") which concluded a separate agreement governing the delivery, the access and the use of the Service (for the purposes of this Policy, the "Client Agreement"). The Client controls its use of the Service and is responsible for the processing of the data collected through the Service on the Subscribers, and Excelway is the subcontractor concerning these data.',
        // tslint:disable-next-line: max-line-length
        VISITORS: 'Visitors of the Websites. We call the users of the Websites the "Visitors of the Websites". The Visitors of the Websites can be individuals who simply browse on the Websites but who do not use the Excelway Service; or, the Visitors of the Websites can be Free Users or Subscribers who visit the Websites to obtain additional information on Excelway. Excelway is responsible for the processing of the data collected through the Website on the Visitors of the Websites.',
        SUBSCRIBERS_CONDITIONS_CONFIDENTIALITY: 'II. Conditions of Confidentiality applicable to the Subscribers',
        A_GENERAL_OVERVIEW: 'A. General overview',
        POLICY_APPLIES_TO_SUBSCRIBERS: 'Section II of this Policy applies only to Subscribers.',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBERS_GENERAL_OVERVIEW_PARAGRAPH: 'If you are a Subscriber, the "Client Agreement" governs the collection and processing of data collected from you through the use of the Client Service (for example, a Client\'s organization or workspace, but for purposes of this Policy referred to as the "Workspace"), including all associated messages, attachments, files, tasks, projects and project names, workshops, boards, documents and other content submitted through the Service ("Workspace Content"). In the event of any conflict between this Policy and the Customer Agreement, the Customer Agreement shall prevail. Because the Customer controls the Workspace used by Subscribers, if you have any questions about the specific settings of the Workspace and the Customer\'s privacy practices, please contact the Customer whose Workspace you are using directly. If you are a Subscriber located in the European Union, please note that the Customer is the controller regarding the processing of your Workspace Content in accordance with the EU General Data Protection Regulation ("GDPR"). When processing the Workspace Contents of the European data subjects to which the Client Contract applies, Excelway is the subcontractor, which means that we collect and process these data only on behalf of the Client.',
        B_COLLECTION_AND_USE_OF_SUBSCRIBER_DATA: 'B. Collection and Use of Subscriber Data',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_DATA_FIRST_PARAGRAPH: 'This section explains the data we collect from Subscribers. We do not require Subscribers to provide us with this data. However, certain data, such as account login information, is necessary to enable you to access the Service, and other data may be collected automatically when you use the Service.',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_DATA_SECOND_PARAGRAPH: 'Project Content. The Project Content is collected, used and shared by Excelway in accordance with the instructions of the Client, including all the applicable conditions provided by the Client Contract, or as required by the applicable law. The Client, and not Excelway, determines its own internal policies concerning the storage, access, modification, suppression, sharing and conservation of the Project Content which can apply to your use of the Service. For example, a Client can provide or remove access to the Service, activate or deactivate integrated third party programs, manage permissions, retention and export parameters, transfer or assign teams, or share projects. Please check with the Customer to see what policies and settings it has in place regarding the Workspace Content that you provide when using the Service.',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_DATA_THIRD_PARAGRAPH: 'Account Data. To create your Excelway account, you or the Client will provide us with basic data about you which may include your name, address, telephone number, e-mail address and password. You will then have the possibility to provide optional information on your profile, such as a photograph or basic demographic data. If you submit payment data in connection with your use of the Service, we use a third-party credit card processing company to collect payment data, including your credit card number, billing address and telephone number. In such circumstances, the third party service provider, and not Excelway, stores your payment data on our behalf.',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_DATA_FOURTH_PARAGRAPH: 'Data on the Use of the Service. When you use the Service, we collect data on the way you use and interact with the Service ("Data on the Use of the Service"). Such data includes :',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_FOURTH_PARAGRAPH_FIRST_PART: 'Device Data - when you access the Service using a mobile device, we collect certain Device Data, including the type of device you use, its operating system and mobile network information. We may also collect your MAC address and other unique device identifiers.',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_FOURTH_PARAGRAPH_SECOND_PART: 'Log Files - when you use the Service, our servers automatically record data in server log files. These log files may contain data such as your web request, IP address, browser type and settings, referring/exit pages and URLs, number of clicks, date and time, language preferences, cookie data and similar technology and other similar data.',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_FOURTH_PARAGRAPH_THIRD_PART: 'Location Data - we collect and process general data about the location of the device from which you access the Service (for example, the approximate geographic location inferred from an IP address).',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_FOURTH_PARAGRAPH_FOURTH_PART: 'Project Usage Metadata - when you interact with the Service, metadata is generated that provides macro-level data (without content) about how you work in your Project. For example, we may record the number of projects you are a member of, the number of workshops you attend or facilitate, and, where applicable, the integrated third-party services and programs you use.',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_FOURTH_PARAGRAPH_FIFTH_PART: 'Other Data. You may provide us with data when you interact with us by other means, such as when you submit requests or questions to us through forms or by e-mail (e.g., support forms, sales forms, user research participation forms); data you provide in connection with sweepstakes, contests or studies in which you choose to participate; beta testing; and customer and technical support requests (together, "Other Data").',
        // tslint:disable-next-line: max-line-length
        SUBSCRIBER_FOURTH_PARAGRAPH_SIXTH_PART: 'Data Collected from Third Party Program Integration. If you choose to use or connect to Third Party Embedded Programs via the Service, or if you are required or permitted to do so by a Customer, such third parties may provide us with access to and allow us to store additional data about your interaction with such services in connection with your use of the Service. If you make these connections, you also understand that we will share data about you that is necessary to enable your use of the third party programs through the Service. If you do not want this data to be shared, do not make these connections. By activating these connections, you authorize us to connect and access the data provided by these connections, and you understand that the privacy policies of these third parties govern these connections.',
        C_HOW_EXCELWAY_USE_SUBSCRIBERS_DATA: 'C. How does Excelway use the Subscribers\' Data ?',
        SECTION_EXPLAINS_DATA_USE: 'This section explains how Excelway uses the Data collected from Subscribers.',
        // tslint:disable-next-line: max-line-length
        CONTENTS_OF_THE_PROJECTS: 'Contents of the Projects. Excelway may view and use the Projects’ Content collected from and concerning Subscribers, only if necessary for the following purposes:',
        DATA_USE_FIRST_PART: 'Maintain, provide and improve the Service',
        DATA_USE_SECOND_PART: 'Preventing or resolving technical or security problems and resolving requests for assistance',
        // tslint:disable-next-line: max-line-length
        DATA_USE_THIRD_PART: 'Investigate when we believe in good faith, or have received a complaint alleging, that Workspace Content is in violation of the Customer Agreement or our Terms of Service',
        // tslint:disable-next-line: max-line-length
        DATA_USE_FOURTH_PART: 'Comply with a valid subpoena or legal request, or other lawful process that meets the requirements of the Customer Agreement and our Enforcement Guidelines',
        DATA_USE_FIFTH_PART: 'If provided for in our Customer Contract or as expressly authorized in writing by the Customer',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA: 'Account Data, Service Usage Data, Data Collected from Third Party Program Integration and Other Data. Excelway can use these categories of data collected from Subscribers and concerning them for the following purposes:',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_FIRST_PART: 'Maintain, provide and improve the Service',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_SECOND_PART: 'Responding to your requests for information',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_THIRD_PART: 'Preventing or resolving technical or security problems and resolving requests for assistance',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_FOURTH_PART: 'Investigating in good faith alleged violations of our Terms of Service Terms of Use',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_FIFTH_PART: 'Comply with a valid legal subpoena or request, or other lawful process that meets the requirements of the Customer Agreement.',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_SIXTH_PART: 'Help us to better understand the interests and needs of users and to customize the Service for our users',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_SEVENTH_PART: 'Conducting analysis, research and reporting on the use of the Service.',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_EIGHTH_PART: 'Protect the Service and our Users',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_NINTH_PART: 'Communicating with you by email and through the Service about important notices and updates regarding the Service, so that you are aware of changes to the Service, our service offerings and important service-related notices, such as those regarding security and fraud. Because these communications are an important part of the Service, you cannot opt out of receiving them.',
        // tslint:disable-next-line: max-line-length
        ACCOUNT_DATA_TENTH_PART_FIRST: 'In accordance with the applicable legal obligations, communicate with you about Excelway\'s promotions, offers and news.',
        ACCOUNT_DATA_TENTH_PART_SECOND: 'You have the possibility to unsubscribe from these promotional communications.',
        SHARE_THE_DATA: 'In accordance with the applicable Client Contract, we can be brought to share the data that we collect from the Subscribers in the following way:',
        // tslint:disable-next-line: max-line-length
        SHARE_THE_DATA_FIRST_PART: 'Service providers. We may provide access to your data or share it with selected third parties who use the data only to provide services on our behalf. These third parties provide us with a variety of services, including, but not limited to, analysis, data storage, security, fraud prevention and other services.',
        // tslint:disable-next-line: max-line-length
        SHARE_THE_DATA_SECOND_PART: 'Business Transactions. If the ownership of all or substantially all of our business changes, we may transfer your data to the new owner so that the Service can continue to operate. In such a case, your data will remain subject to the promises and commitments in this Policy until the new owner changes them. If such a transfer is subject to additional mandatory restrictions under the applicable laws, Excelway will comply with these restrictions.',
        // tslint:disable-next-line: max-line-length
        SHARE_THE_DATA_THIRD_PART: 'In accordance with your parameters within the Service. Please note that the Project Content that you submit through the Service can be consulted by other users in your Workspace and in your organization, according to the specific parameters that you and your organization have selected.',
        D_AGGREGATED_AND_DEPERSONALIZED_DATA: 'D. Aggregated and Depersonalized Data',
        // tslint:disable-next-line: max-line-length
        AGGREGATED_DEPERSONALIZED_DATA_PARAGRAPH: 'We may aggregate and/or de-identify data collected through the Service so that such data can no longer be linked to you or your device ("Aggregated/Depersonalized Data"). We may use Aggregated/Depersonalized Data for any purpose, including, but not limited to, research and analysis, and we may also share such data with third parties, including partners, affiliates, service providers and others.',
        E_COMBINATION_OF_DATA: 'E. Combination of Data',
        // tslint:disable-next-line: max-line-length
        COMBINATION_OF_DATA_PARAGRAPH: 'We may combine data we collect through the Service with data we receive from other sources, both online and offline, and use such combined data in accordance with this Policy and the Customer Agreement.',
        F_DATA_RETENTION: 'F. Data Retention',
        // tslint:disable-next-line: max-line-length
        DATA_RETENTION_PARAGRAPH: 'We will retain your personal data for the period of time necessary to fulfill the purposes set out in this Policy, unless a longer retention period is required or permitted by law or the Customer Agreement requires or permits specific retention or deletion periods.',
        G_RIGHTS_OF_AFFECTED_PERSONS: 'G. Rights of Affected Persons',
        // tslint:disable-next-line: max-line-length
        RIGHTS_OF_AFFECTED_PERSONS_PARAGRAPH: 'Please contact the owner(s) or administrator(s) of your Project to exercise any rights you may have under applicable local laws, including your right to access, delete, correct, transfer or object under the DCP.',
        III_PRIVACY_TO_VISITORS: 'III. Privacy Terms Applicable to Website Visitors',
        VISITORS_A_GENERAL_OVERVIEW: 'A. General overview',
        VISITORS_A_GENERAL_OVERVIEW_FIRST_PART: 'Section III of this Policy applies only to Visitors to the Websites.',
        // tslint:disable-next-line: max-line-length
        VISITORS_A_GENERAL_OVERVIEW_SECOND_PART: 'If you visit the Websites, whether or not you are also a user of the Service, the following rules apply to you. In order to avoid any confusion, please note that the provisions of this section apply only to the use of the Excelway Websites and not to the use of the Service.',
        // tslint:disable-next-line: max-line-length
        VISITORS_A_GENERAL_OVERVIEW_THIRD_PART: 'If you are a Visitor of the Websites located in the European Union, Excelway is the person in charge of the processing of your personal data under the terms of the General Data Protection Regulation of the EU ("GDPR").',
        B_COLLECTION_AND_USE_OF_SITE_VISITORS: 'B. Collection and Use of Site Visitors\' Data',
        DATA_COLLECTED_FROM_WEBSITE_VISITORS: 'Data Collected from Website Visitors When you use the Websites, we collect the following data about you:',
        // tslint:disable-next-line: max-line-length
        VISITORS_COLLECTION_AND_USE_FIRST_PART: 'Contact Information - If you submit an inquiry or question through the Websites, you may be asked to provide us with essential information, including your name, email address, telephone number and mailing address. We will also keep track of our communication with you, the question or request you raise and how it was resolved. If you choose to take part in a draw, a contest or a research study of Excelway proposed by the Web sites, we will also collect your basic contact information within the framework of this activity.',
        // tslint:disable-next-line: max-line-length
        VISITORS_COLLECTION_AND_USE_SECOND_PART: 'Data on the Use of the Websites - when you browse the Websites, we and our service providers (which are third party companies working on our behalf to provide and improve the Websites) use a variety of technologies, including cookies and similar tools, to help you collect data on how you use the Websites. For example, our servers automatically record certain data in server logs. These server logs may include data such as your web request, IP address, browser type and settings, referring and exit pages and URLs, number of clicks and your interaction with links on the Web Sites, domain names, landing pages, page views, mobile operators, mobile device identifiers, data about the device you use to access the Web Sites, date and time information, and other similar data.',
        // tslint:disable-next-line: max-line-length
        VISITORS_COLLECTION_AND_USE_THIRD_PART: 'Location Data - We collect and process general data about the location of the device from which you access the Service (for example, the approximate geographic location derived from an IP address).',
        COOKIES_AND_SIMILAR_TECHNOLOGIES: 'Cookies and Similar Technologies',
        // tslint:disable-next-line: max-line-length
        COOKIES_AND_SIMILAR_TECHNOLOGIES_PARAGRAPH: 'In order to collect the Website Usage Data referred to above, we and our service providers use Internet server logs, cookies, tags, SDKs, tracking pixels and other similar tracking technologies. A web server log is a file in which website activity is stored. An SDK is a section of code that we embed in our applications and software to enable third parties to collect data about how users interact with the Web Sites. A cookie is a small text file that is placed on your computer or mobile device when you visit a site and allows us to: (i) recognize your computer and login session; (ii) store your preferences and settings; (iii) understand which pages of the Websites you have visited; (iv) improve your user experience by providing and measuring the effectiveness of content and advertising tailored to your interests; (v) perform analytics; and (vi) assist with security and administrative functions. Web beacons (sometimes referred to as web beacons) are tiny electronic tags with a unique identifier that are embedded in Web Sites, online advertisements and/or emails, and are designed to provide usage data such as ad impressions or clicks, email opening rates, measurement of the popularity of the Web Sites and associated advertising, and to access user cookies. As we adopt other technologies, we may also collect data by other methods. Please note that you can change your settings to be notified when a cookie is set or updated, or to block cookies altogether. Please consult the "Help" section of your browser for more information (e.g. Internet Explorer; Google Chrome; Mozilla Firefox; or Apple Safari).',
        DATA_VISITORS_PURPOSES: 'Use of Data Collected from Website Visitors',
        DATA_VISITORS_PURPOSES_USE: 'We use the data collected from Website Visitors for a variety of purposes, including the following:',
        DATA_VISITORS_PURPOSES_FIRST_PART: 'Maintaining, providing and improving the Websites and the Service.',
        DATA_VISITORS_PURPOSES_SECOND_PART: 'Responding to your requests for information',
        // tslint:disable-next-line: max-line-length
        DATA_VISITORS_PURPOSES_THIRD_PART: 'In accordance with the applicable legal obligations, communicate with you about Excelway\'s promotions, offers and news. You have the possibility to unsubscribe from these promotional communications.',
        DATA_VISITORS_PURPOSES_FOURTH_PART: 'Preventing or solving technical or security problems',
        DATA_VISITORS_PURPOSES_FIFTH_PART: 'Investigating in good faith alleged violations of our Terms of Service',
        // tslint:disable-next-line: max-line-length
        DATA_VISITORS_PURPOSES_SIXTH_PART: 'Help us to better understand the interests and needs of Website Visitors, and customize the advertising and content you see on the Websites',
        DATA_VISITORS_PURPOSES_NINTH_PART: 'Conduct analysis and research regarding the use of the Websites and the Service',
        C_LEGAL_BASIS: 'C. Legal Basis',
        // tslint:disable-next-line: max-line-length
        LEGAL_BASIS_PARAGRAPH: 'If you are a resident of the EU, please note that the legal basis of the GDPR for the use of the data we collect through your use of the Websites as a Visitor to the Websites is as follows:',
        // tslint:disable-next-line: max-line-length
        LEGAL_BASIS_FIRST_PART: 'When the use of your data is necessary to perform our obligations under a contract with you (for example, to comply with the Terms of Service that you agree to when you browse the Websites)',
        // tslint:disable-next-line: max-line-length
        LEGAL_BASIS_SECOND_PART: 'When the use of your data is necessary for our legitimate interests or the legitimate interests of others (for example, to ensure the security of our Websites, to operate our Websites, to prevent fraud, to analyse usage and improve our Websites, and for similar purposes).',
        LEGAL_BASIS_THIRD_PART: 'When the use of your data is necessary to comply with a legal obligation',
        LEGAL_BASIS_FOURTH_PART: 'When we have your consent to process the data in a certain way',
        D_AGGREGATED_DEPERSONALIZED_DATA: 'D. Aggregated/Depersonalized Data',
        // tslint:disable-next-line: max-line-length
        VISITORS_AGGREGATED_DEPERSONALIZED_DATA_PARAGRAPH: 'We may aggregate and/or de-identify data collected through the Service so that such data can no longer be linked to you or your device ("Aggregated/Depersonalized Data"). We may use Aggregated/Depersonalized Data for any purpose, including but not limited to research and analysis, and we may also share such data with third parties, including advertising and promotional partners, sponsors, event promoters and/or other persons.',
        VISITORS_COMBINING_DATA: 'E. Combining Data',
        // tslint:disable-next-line: max-line-length
        VISITORS_COMBINING_DATA_PARAGRAPH: 'You agree that, for the purposes described in this Policy, we may combine data we collect through the Websites with data we receive from other sources, both online and offline, and use such combined data in accordance with this Policy. However, if the collection of data about you is governed by a Customer Agreement, the data will only be combined and used in accordance with that Customer Agreement and the sections of this Policy applicable to Subscribers.',
        WEBSITE_ANALYSIS_AND_ADVERTISING: 'F. Website Analysis and Advertising',
        WEB_SITE_ANALYSIS: 'Web Site Analysis',
        // tslint:disable-next-line: max-line-length
        WEBSITE_ANALYSIS_AND_ADVERTISING_FIRST_PART: 'We may use third party web analytics services on our Web Sites to collect and analyze usage data using cookies and similar tools, to perform audits, research or reports, and to provide you with certain features. To prevent Google Analytics from using your Data for analytical purposes, you can install the Google Analytics Opt-out Browser add-on.',
        ONLINE_ADVERTISING: 'Online Advertising',
        // tslint:disable-next-line: max-line-length
        WEBSITE_ANALYSIS_AND_ADVERTISING_SECOND_PART: 'The Websites may incorporate third-party advertising technologies that enable the delivery of relevant content and advertising on the Websites, as well as on other websites you visit. Advertisements may be based on various factors such as the content of the page you visit, the data you enter such as your age and gender, your searches, demographics, and other data we collect from you. These advertisements may be based on your current activity or your activity over time and on other websites and online services and may be tailored to your interests.',
        // tslint:disable-next-line: max-line-length
        WEBSITE_ANALYSIS_AND_ADVERTISING_THIRD_PART: 'Third parties, whose products or services are accessible or advertised through the Websites, may also place cookies or other tracking technologies on your computer, mobile phone or other devices to collect data about you, as described above. We also allow other third parties (e.g., ad agencies and ad servers such as Google Analytics, DoubleClick and others) to serve you personalized advertisements on our Websites and other websites and to access their own cookies or other tracking technologies on your computer, mobile phone or other device you use to access the Websites.',
        // tslint:disable-next-line: max-line-length
        WEBSITE_ANALYSIS_AND_ADVERTISING_FOURTH_PART: 'We do not have access to cookies or other tracking technologies that may be placed by unaffiliated third parties on the device you use to access the Websites, and this Policy does not govern the use of such cookies or other tracking technologies. If you would like more information about personalized advertising and how you can generally prevent cookies from being placed on your computer to serve personalized advertising, you can visit www.optout.networkadvertising.org/ and www.optout.aboutads.info/ to opt-out of receiving personalized advertising from companies that participate in these programs. To opt-out of having Google Analytics display ads on the Internet or to set up ads on the Google display network, visit https://adssettings.google.com/authenticated . We do not check the above links for opt-outs; nor do we check whether a specific company chooses to participate in these opt-out programs. We are not responsible for the choices you make using these mechanisms or for the availability or continued accuracy of these mechanisms.',
        // tslint:disable-next-line: max-line-length
        WEBSITE_ANALYSIS_AND_ADVERTISING_FIFTH_PART: 'Please note that if you opt out above, you will still see advertising when you use the Websites, but it will not be tailored to your online behaviour over time.',
        // tslint:disable-next-line: max-line-length
        WEBSITE_ANALYSIS_AND_ADVERTISING_SIXTH_PART: 'Notice Regarding the "Do Not Track" Mechanism The Do Not Track mechanism ("DNT", or literally "do not track") is a privacy preference that users can set in certain web browsers. We are committed to providing you with meaningful choices regarding the data collected on our Websites for purposes related to third parties, and therefore offer you the plurality of opt-out mechanisms listed above. However, at this time, we do not recognize or respond to DNT signals from a browser.',
        G_SHARING_WEB_SITE_VISITOR_DATA: 'G. Sharing Web Site Visitor Data',
        SHARE_FOLLOWING_PEOPLE: 'We share the data we collect through the Websites with the following people:',
        // tslint:disable-next-line: max-line-length
        SHARING_VISITOR_DATA_FIRST_PART: 'Service Providers. We may provide access to your data or share it with selected third parties who use the data only to provide services on our behalf. These third parties provide us with a variety of services, including, but not limited to, sales, marketing, provision of content and features, analysis, data storage, security, fraud prevention and other services.',
        // tslint:disable-next-line: max-line-length
        SHARING_VISITOR_DATA_SECOND_PART: 'Business Transfers. If the ownership of all or substantially all of our business changes, we may transfer your personal data to the new owner so that the Websites can continue to operate. In such a case, your data will remain subject to the promises and undertakings contained in this Policy until this Policy is updated or amended by the purchaser upon notice to you. If such a transfer is subject to additional mandatory restrictions under the applicable laws, Excelway will comply with these restrictions.',
        // tslint:disable-next-line: max-line-length
        SHARING_VISITOR_DATA_THIRD_PART: 'Public Forums. The Websites allow you to download and share comments or reactions publicly with other users. No information that you submit with public settings is confidential, and Excelway can use it for any purpose (including in testimonials or other marketing documents of Excelway). Any information that you openly post in this way will be accessible to the general public and potentially accessible by third party search engines. This data may be read, collected and/or used by other users and may be used to send you unsolicited messages. Therefore, we urge you to exercise caution when using these features of the Websites.',
        SHARING_VISITOR_DATA_FOURTH_PART: 'Consent. We may also disclose your personal data to third parties with your consent.',
        H_RETENTION_OF_YOUR_DATA: 'H. Retention of your Data',
        // tslint:disable-next-line: max-line-length
        RETENTION_OF_YOUR_DATA_PARAGRAPH: 'We will retain your data for as long as necessary to fulfill the purposes set out in this Policy, unless a longer retention period is required or permitted by law.',
        I_RIGHTS_OF_CONCERNED_PERSONS: 'I. Rights of Concerned Persons',
        // tslint:disable-next-line: max-line-length
        RIGHTS_OF_CONCERNED_PERSONS_PARAGRAPH: 'Local legal requirements (such as those of the EU) grant you additional rights. If you wish to obtain further information concerning your legal rights under applicable law or if you wish to exercise any of them, please contact us at any time by referring to the information in the "Excelway Contact Information" section below. Your local laws (such as those of the EU) give you the right to request that we :',
        RIGHTS_OF_CONCERNED_PERSONS_FIRST_PART: 'provide you with access and/or a copy of certain data we hold about you',
        RIGHTS_OF_CONCERNED_PERSONS_SECOND_PART: 'prevent the processing of your data for direct marketing purposes (including any direct marketing processing based on profiling)',
        RIGHTS_OF_CONCERNED_PERSONS_THIRD_PART: 'updating obsolete or incorrect data',
        RIGHTS_OF_CONCERNED_PERSONS_FOURTH_PART: 'delete some of the data we hold about you',
        RIGHTS_OF_CONCERNED_PERSONS_FIFTH_PART: 'restrict how we process and disclose certain of your personal data',
        RIGHTS_OF_CONCERNED_PERSONS_SIXTH_PART: 'transfer your data to a third party service provider',
        RIGHTS_OF_CONCERNED_PERSONS_SEVENTH_PART: 'revoke your consent for the processing of your personal data',
        // tslint:disable-next-line: max-line-length
        RIGHTS_OF_CONCERNED_PERSONS_SECOND_PARAGRAPH: 'We will consider all requests and provide our response within the time limits prescribed by applicable law. Please note, however, that certain data may be conditionally exempted from such requests, in particular if we need to continue processing your data for our legitimate interests or to comply with a legal obligation. We may ask you to provide us with the information necessary to confirm your identity before responding to your request.',
        J_THIRD_PARTY_LINKS_AND_SERVICES: 'J. Third Party Links And Services',
        // tslint:disable-next-line: max-line-length
        THIRD_PARTY_LINKS_AND_SERVICES_PARAGRAPH: 'The Websites may contain links to third-party websites and functionalities. If you choose to use these third party services, you may disclose your information not just to those third-parties, but also to their users and the public more generally depending on how their services function. Because these third-party websites and services are not operated by Asana, Asana is not responsible for the content or practices of those websites or services. The collection, use, and disclosure of your information will be subject to the privacy policies of the third party websites or services, and not this Policy. We urge you to read the privacy and security policies of these third-parties.',
        ADDITIONAL_TERMS_TO_ALL_USERS: 'IV. Additional Privacy Terms Applicable to All Users',
        // tslint:disable-next-line: max-line-length
        ADDITIONAL_TERMS_TO_ALL_USERS_PARAGRAPH: 'The following additional conditions related to the privacy practices of Excelway apply to all the users of Excelway (Subscribers and Visitors of the Websites).',
        A_MODIFICATIONS_OF_OUR_CONFIDENTIALITY_POLICY: 'A. Modifications of Our Confidentiality Policy',
        // tslint:disable-next-line: max-line-length
        MODIFICATIONS_CONFIDENTIALITY_POLICY_PARAGRAPH: 'We reserve the right to change this policy at any time to reflect changes in the law, our data collection and use practices, the features of our Service or Web Sites, or advances in technology. We will make the amended Policy available through the Service and the Websites so that you can periodically review it. If we make a material change to the Policy, we will comply with applicable legal requirements for notice and/or consent.',
        B_HOW_WE_PROTECT_YOUR_DATA: 'B. How We Protect Your Data',
        // tslint:disable-next-line: max-line-length
        PROTECT_DATA_PARAGRAPH: 'Excelway takes technical and organizational measures to protect your data against accidental or illegal destruction, accidental loss, alteration, unauthorized disclosure or access. However, no method of transmission over the Internet, nor any electronic or physical storage means, is absolutely secure, and we cannot therefore ensure or guarantee the security of this data.',
        C_MARKETING_PRACTICES_AND_CHOICES: 'C. Marketing Practices and Choices',
        // tslint:disable-next-line: max-line-length
        MARKETING_PRACTICES_AND_CHOICES_PARAGRAPH: 'If you receive emails from us, we may use certain analytics tools, such as pixels, to capture data, such as when you open our message or click on links or banners in our email. This data allows us to evaluate the effectiveness of our communication and marketing campaigns. You can ask us not to use your contact information to contact you by e-mail, mail or telephone about products, services, promotions and special events that could interest you by contacting us in the section "Excelway\'s contact information" below. In commercial e-mails, you can also unsubscribe by following the instructions at the bottom of these e-mails. Please note that, whatever your request, it is possible that we use and share certain information as permitted by this Policy or as required by the applicable law. For example, you may not be able to opt out of certain operational or service-related emails, such as those regarding our relationship or transactions with you.',
        CONTACT_INFORMATION_EXCELWAY: 'V. Contact information of Excelway',
        // tslint:disable-next-line: max-line-length
        CONTACT_INFORMATION_PARAGRAPH: 'Excelway is located at 11 rue Léon Frot, 75011, Paris. If you wish to contact us or if you have any questions or complaints about this policy, please contact us at support@excelway.co.',
    }
};
