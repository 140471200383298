import { createAction, props } from '@ngrx/store';

export const showHeader = createAction(
    '[Header] Show Header',
);

export const hideHeader = createAction(
    '[Header] Hide Header',
);

export const newProjectHeader = createAction(
    '[Header] New Project Header',
);


export const updateHeaderType = createAction(
    '[Header] Update Header Type',
    props<{ menuType: string }>()
);
