import { RouterReducerState } from '@ngrx/router-store';

import { LoginStoreState } from './login-store';
import { NavbarStoreState } from './navbar-store';
import { HeaderStoreState } from './header-store';
import { ProfileStoreState } from './profile-store';

import { StudioProjectStoreState } from './studio-store/project-store';
import { StudioProjectsStoreState } from './studio-store/projects-store';
import { StudioHomeStoreState } from './studio-store/home-store';
import { StudioSessionsStoreState } from './studio-store/sessions-store';
import { StudioNotificationsStoreState } from './studio-store/notifications-store';
import { StudioBoardsStoreState } from './studio-store/boards-store';
import { StudioServicesStoreState } from './studio-store/services-store';
import { StudioBoardStoreState } from './studio-store/board-store';
import { StudioDetailStoreState } from './studio-store/detail-store';
import { StudioDocumentStoreState } from './studio-store/document-store';
import { StudioUsersStoreState } from './studio-store/users-store';
import { StudioSessionStoreState } from './studio-store/session-store';

import { WorkshopActivitiesStoreState } from './workshop-store/activities-store';
import { WorkshopNotesStoreState } from './workshop-store/notes-store';
import { WorkshopTimerStoreState } from './workshop-store/timer-store';
import { WorkshopSocketIOStoreState } from './workshop-store/socket-io-store';
import { WorkshopBrainstormingStoreState } from './workshop-store/brainstorming-store';
import { WorkshopCardboardStoreState } from './workshop-store/cardboard-store';
import { WorkshopCardRatingStoreState } from './workshop-store/cardrating-store';



export interface State {
    router: RouterReducerState;
    login: LoginStoreState.State;
    navbar: NavbarStoreState.State;
    header: HeaderStoreState.State;
    profile: ProfileStoreState.State;

    'studio-users': StudioUsersStoreState.State;
    'studio-detail': StudioDetailStoreState.State;
    'studio-document': StudioDocumentStoreState.State;
    'studio-board': StudioBoardStoreState.State;
    'studio-boards': StudioBoardsStoreState.State;
    'studio-services': StudioServicesStoreState.State;
    'studio-home': StudioHomeStoreState.State;
    'studio-notifications': StudioNotificationsStoreState.State;
    'studio-project': StudioProjectStoreState.State;
    'studio-projects': StudioProjectsStoreState.State;
    'studio-session': StudioSessionStoreState.State;
    'studio-sessions': StudioSessionsStoreState.State;

    'workshop-activities': WorkshopActivitiesStoreState.State;
    'workshop-notes': WorkshopNotesStoreState.State;
    'workshop-timer': WorkshopTimerStoreState.State;
    'workshop-socketIO': WorkshopSocketIOStoreState.State;
    'workshop-brainstorming': WorkshopBrainstormingStoreState.State;
    'workshop-cardboard': WorkshopCardboardStoreState.State;
    'workshop-cardrating': WorkshopCardRatingStoreState.State;



}
