export const locale = {
    lang: 'en',
    data: {
        // tslint:disable-next-line: max-line-length
        SESSION_NEW_DIALOG_NEW_MEMBERS_PARAGRAPH: 'Designate a facilitator and invite members to help you organize the workshop. If they are also participants, they will be able to access and edit the meeting minutes.',
        SESSION_NEW_DIALOG_NEW_MEMBERS_NOTE: 'Note: Please add at least',
        SESSION_NEW_DIALOG_NEW_MEMBERS_AN_ANIMATOR: 'a facilitator',
        SESSION_NEW_DIALOG_NEW_MEMBERS_END_NOTE: 'to continue.',
        SESSION_NEW_DIALOG_NEW_MEMBERS_PARTICIPANT: 'Include as a participant?',
        SESSION_NEW_DIALOG_NEW_MEMBERS_MEMBERS: 'Members',
        SESSION_NEW_DIALOG_NEW_MEMBERS_THE_EMAIL: 'Email',
        SESSION_NEW_DIALOG_NEW_MEMBERS_POSITION: 'Position',
        SESSION_NEW_DIALOG_NEW_MEMBERS_SPONSOR: 'Sponsor',
        SESSION_NEW_DIALOG_NEW_MEMBERS_ADMIN: 'Admin',
        SESSION_NEW_DIALOG_NEW_MEMBERS_RESPONSIBLE: 'Owner',
        SESSION_NEW_DIALOG_NEW_MEMBERS_ANIMATOR: 'Facilitator',
        SESSION_NEW_DIALOG_NEW_MEMBERS_ROLE: 'Role',
        SESSION_NEW_DIALOG_NEW_MEMBERS_READING: 'Viewer',
        SESSION_NEW_DIALOG_NEW_MEMBERS_MODIFICATION: 'Editor',
        SESSION_NEW_DIALOG_NEW_MEMBERS_REMOVE: 'Remove',
        SESSION_NEW_DIALOG_NEW_MEMBERS_SAVE: 'Save',
        SESSION_NEW_DIALOG_NEW_MEMBERS_CANCEL: 'Cancel',
    }
};
