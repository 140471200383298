import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';

export const selectBoardsState = createFeatureSelector<State>('studio-boards');

export const selectFavorites = createSelector(
    selectBoardsState,
    (state: State): ProjectBoardModel[] => state.filtredFavorites
);

export const selectOthers = createSelector(
    selectBoardsState,
    (state: State): ProjectBoardModel[] => state.filtredOthers
);
