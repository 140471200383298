export const locale = {
    lang: 'en',
    data: {
        PROJECT_DIALOGS_DOCUMENT_NEW_ADD_DOCUMENT: 'Add a document',
        PROJECT_DIALOGS_DOCUMENT_NEW_TITLE_DOCUMENT: 'Document title',
        PROJECT_DIALOGS_DOCUMENT_NEW_REPORT: 'Report',
        PROJECT_DIALOGS_DOCUMENT_NEW_TYPE: 'Type',
        PROJECT_DIALOGS_DOCUMENT_NEW_PRESENTATION: 'Presentation',
        PROJECT_DIALOGS_DOCUMENT_NEW_OTHER: 'Other',
        PROJECT_DIALOGS_DOCUMENT_NEW_BROWSE: 'Browse your files',
        PROJECT_DIALOGS_DOCUMENT_NEW_SAVE: 'Save',
        PROJECT_DIALOGS_DOCUMENT_NEW_CANCEL: 'Cancel',
    }
};
