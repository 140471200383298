export const locale = {
    lang: 'fr',
    data: {
        CATEGORIES: 'Couleurs',
        ADD: 'Ajouter',
        EDIT: 'Modifier couleur',
        ADD_CATEGORIE: 'Ajouter couleur',
        ADD_U: 'AJOUTER',
        NAME: 'Nom',
    }
};
